const LandingPage = {
  // *

  // A
  about_us: 'About Us',
  about_us_page_header: 'Welcome to Labby – where innovation meets impact, and where your success is our top priority.',
  about_us_page_subheader: 'At Labby, we\'re more than just a software provider – we\'re your trusted partner on the journey to success. Whether you\'re a small independent laboratory or a large healthcare institution, we\'re here to support you every step of the way. From seamless implementation to ongoing support and training, we\'re dedicated to helping you unlock the full potential of your laboratory.',
  about_us_section_header: 'About Us',
  about_us_section_description_1: 'Experience unparalleled control with Labby\'s intuitive dashboard. Real-time analytics and key performance indicators are readily accessible, enhancing efficiency in laboratory management.',
  about_us_section_description_2: 'Experience the ease of managing appointments, patient records, and billing in one central hub',

  // B

  // C

  // D

  // E

  // F
  features_section_header: 'Features',
  features_section_subheader: 'Explore all the features in Labby, it\'s all about making your business grow!',
  features_title_1: 'Lab Control Dashboard',
  features_title_2: 'Inventory Management',
  features_title_3: 'Customizable Lab Packages',
  features_description_1: 'Experience the ease of managing appointments, patient records, and billing in one central hub',
  features_description_2: 'Efficient management of supplies, accurate forecasting, and waste minimization ensure smooth laboratory operations',
  features_description_3: 'Engage customers with Labby\'s customizable lab packages for special occasions like National Day and Mother\'s Day and relevant promotions.',
  faq_section_header: 'FAQ(s)',
  faq_question_1: 'What is Labby, and how does it benefit medical laboratories?',
  faq_question_2: 'What key features does Labby offer for managing medical laboratories?',
  faq_question_3: 'Can Labby integrate with existing systems and machines?',
  faq_question_4: 'How does Labby provide support and training to its users?',
  faq_question_5: 'How can laboratories get started with Labby?',
  faq_question_6: 'How does Labby ensure the security and privacy of sensitive medical data?',
  faq_answer_1: 'Labby is a cloud-based SaaS platform designed specifically for managing medical laboratories efficiently. It helps streamline laboratory processes, improve workflow, manage inventory and enhance overall operational efficiency.',
  faq_answer_2: 'Labby offers a comprehensive suite of features including users managing, result reporting, inventory management, scheduling, sample tracking, and analytics. It also provides customizable workflows and customer’s website to suit the specific needs of different types of laboratories.',
  faq_answer_3: 'Yes, Labby is designed to seamlessly integrate with existing systems like Husun systems. Beside the seamless integration with the lab’s machines This integration helps minimize disruptions and allows for smooth data exchange between different systems and machines.',
  faq_answer_4: 'Labby offers 24/7 on premise support options including documentation, online resources, and customer service channels for user assistance. Additionally, training sessions and tutorials are available to help users onboard quickly and maximize the platform\'s capabilities.',
  faq_answer_5: 'Laboratories can get started with Labby by contacting the sales team or signing up for a trial on the Labby website. The onboarding process typically involves setting up the platform, configuring settings, and providing training to users to ensure a smooth transition.',
  faq_answer_6: 'Labby manages the data efficiently and ensure the full access and ownership to labs to their own data.',

  // G
  get_started: 'Get Started',
  get_in_touch_section_description: 'Questions, comments, or suggestions? Simply fill in the form and we’ll be in touch shortly.',

  // H
  hero_section_title_patient: 'Get tests results through Email/SMS',
  hero_section_title_underline_patient: 'Notifications',
  hero_section_subtitle_patient: 'Streamline your lab operations, enhance patient care and elevate your practice to new summits',
  hiw_section_header: 'How it works',
  hiw_section_1_card_1_title: 'Build your lab profile',
  hiw_section_1_card_2_title: 'Manage your lab',
  hiw_section_1_card_3_title: 'Engage with your lab statists',
  hiw_section_1_card_1_description: 'We provide the ability for you to sync with Ministry of Health through HESN. You can expect humanly and fast support when you need us.',
  hiw_section_1_card_2_description: 'Register clinics and collection centers under your lab and get patient booking from them',
  hiw_section_1_card_3_description: 'Gain insights into patient demographics and appointments to drive the success of your lab',
  hiw_section_2_card_1_title: 'Centralized patient management',
  hiw_section_2_card_2_title: 'Optimize your lab operations',
  hiw_section_2_card_3_title: 'Streamline System',
  hiw_section_2_card_1_description: 'Handle a large volume of patients effortlessly with Labby',
  hiw_section_2_card_2_description: 'Reduce costs, and maximize efficiency with Labby\'s comprehensive solutions.',
  hiw_section_2_card_3_description: 'Manage over a million samples with ease and efficiency using Labby.',

  // I

  // J

  // K
  keep_in_touch_section_header: 'Keep in Touch',
  keep_in_touch_section_description: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.',

  // L

  // M

  // N

  // O
  our_mission_title: 'Our Mission',
  our_mission_description_1: 'At Labby, we\'re more than just a software provider – we\'re your trusted partner on the journey to success. Whether you\'re a small independent laboratory or a large healthcare institution, we\'re here to support you every step of the way. From seamless implementation to ongoing support and training, we\'re dedicated to helping you unlock the full potential of your laboratory.',
  our_mission_description_2: 'What sets Labby apart is our unwavering commitment to excellence, innovation, and customer satisfaction. We collaborate closely with laboratory professionals to understand their pain points, challenges, and aspirations, ensuring that our solutions are not just technically robust but also deeply aligned with the real-world needs of our users.',
  our_mission_description_3: 'Our mission is to empower laboratories of all sizes and specialties with the tools they need to excel. We believe that by leveraging the latest advancements in technology, data analytics, and automation, laboratories can not only optimize their operations but also drive better outcomes for patients.',

  // P

  // Q

  // R
  request_demo: 'Request a Demo',

  // S
  services_section_header: 'Our Services',
  services_title_1: 'PCR Test',
  services_title_2: 'Packages',
  services_title_3: 'Routine Tests',
  services_description_1: 'A test to detect genetic material from a specific organism, such as a virus. The test detects the presence of a virus if you have the virus at the time of the test.',
  services_description_2: 'Help determine a deficiency condition or problem. Medicover’s laboratory test packages may help you ,whether your goal is to recover as soon as possible or to prevent a disease.',
  services_description_3: 'A test to detect genetic material from a specific organism, such as a virus. The test detects the presence of a virus if you have the virus at the time of the test.',

  // T

  // U

  // V

  // W

  // X

  // Y

  // Z
};

export default LandingPage;

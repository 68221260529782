import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getSpecimenTypes} from 'redux/admin/actionCreators';

import {Card} from 'atoms';
import SpecimenTypeTable from './SpecimenTypeTable';
import SpecimenTypeFilters from './SpecimenTypeFilters';

const SpecimenTypeList = () => {
  const dispatch = useDispatch();
  const { specimen_types } = useSelector((state) => state.admin);
  const [filters, setFilters] = useState({});

  const fetchSpecimenTypes = (params = filters) => {
    dispatch(getSpecimenTypes(params));
  };

  const handleSearch = (value) => {
    fetchSpecimenTypes({ search: value });
    setFilters({ search: value });
  };

  useEffect(() => {
    fetchSpecimenTypes();
  }, []);

  return (
    <Card styles={{margin:'2rem'}}>
      <SpecimenTypeFilters onSearch={handleSearch} reload={fetchSpecimenTypes}/>
      <SpecimenTypeTable list={specimen_types} refetch={fetchSpecimenTypes}/>
    </Card>
  );
};

export default SpecimenTypeList;
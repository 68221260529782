import Table from './Table';
import Row from './components/Row';
import Cell from './components/Cell';
import Tbody from './components/Tbody';
import Thead from './components/Thead';

Table.Row = Row;
Table.Cell = Cell;
Table.Tbody = Tbody;
Table.Thead = Thead;

export default Table;

import { Grid } from '@mui/material';
import {Button, Text} from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';
import { MASTER_RESERVATION_TEST_STATUSES, PRIORITY } from 'models';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import i18n from 'translation/i18n';
import React from 'react';

const selectFilterConfigs = [
  {
    key: 'status',
    options: MASTER_RESERVATION_TEST_STATUSES,
    placeholderKey: i18n.t('status'),
  },
  {
    key: 'priority',
    options: Object.values(PRIORITY).map((p) => ({
      value: p,
      label: i18n.t(p),
    })),
    placeholderKey: i18n.t('priority'),
  },
];

export const ReservationTestFilters = ({
  generateOtherFilters,
  generateSelectFilters,
  selectedTab,
  setSelectedTab,
  tabs,
  hideFilters,
  showVerifyAll,
  onVerifyAll
}) => {
  return (
    <Grid container py={2} px={2} alignItems="center" className="section-title">
      <Grid item xs={8} lg={4}>
        <nav
          style={{
            display: 'flex',
            position: 'relative',
            zIndex: '0',
            padding: '0 8px',
          }}
        >
          {tabs.map((tab) => {
            const selected = tab.index === selectedTab;
            return (
              <a
                key={tab.label}
                style={{
                  ...(selected ? { color: '#111827' } : { color: '#6B7280' }),
                  overflow: 'hidden',
                  position: 'relative',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  fontWeight: '500',
                  textAlign: 'center',
                  flex: '1 1 0%',
                  minWidth: '0',
                  cursor: 'pointer',
                }}
                onClick={() => setSelectedTab(tab.index)}
              >
                <Text
                  typography={isMobile ? 'caption13' : 'subtitle'}
                  weight="bold"
                >
                  {tab.label}
                </Text>

                <span
                  style={{
                    ...(selected
                      ? { backgroundColor: '#4b5bbc' }
                      : { backgroundColor: 'transparent' }),
                    position: 'absolute',
                    right: '0',
                    left: '0',
                    bottom: '0',
                    height: '0.125rem',
                  }}
                />
              </a>
            );
          })}
        </nav>
      </Grid>

      {!hideFilters && (
        <>
          <Grid item xs={12} lg={4}>
            <SearchInput
              placeholder={i18n.t(
                'reservation_test_list_search_input_placeholder',
              )}
              onChange={(value) => generateOtherFilters('search', value)}
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <SelectFilter
              selectFilterConfigs={selectFilterConfigs}
              onApply={generateSelectFilters}
              onCancel={generateSelectFilters}
            />
          </Grid>
          <Grid item xs={2}>
            {showVerifyAll && (
              <Button
                label={i18n.t('verify_all')}
                onClick={onVerifyAll}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

ReservationTestFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
  selectedTab: PropTypes.number,
  setSelectedTab: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape()),
  hideFilters: PropTypes.bool,
  showVerifyAll: PropTypes.bool,
  onVerifyAll: PropTypes.func,
};

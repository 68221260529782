import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Menu, MenuItem} from '@mui/material';
import i18n from 'translation/i18n';
import {Flex, Button, Spacer} from 'atoms';
import {ReactComponent as MenuIcon} from 'assets/icons/common/more-vertical.svg';
import {refundLabSubscriptionInvoice, printLabSubscriptionInvoice} from 'redux/admin/actionCreators';
import { printPDF } from 'utils/labUtils';
import styles from './labs.module.css';

const LabInvoiceActions = (props) => {
  const {onDone, data} = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [printing, setPrinting] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefundClick = () => {
    dispatch(refundLabSubscriptionInvoice(data.id))
      .then(() => onDone());
  };

  const handlePrintClick = () => {
    setPrinting(true);
    dispatch(printLabSubscriptionInvoice(data.id))
      .then(resp => resp.value.data)
      .then(blob => {
        setPrinting(false);
        printPDF(blob);
      })
      .then(() => onDone());
  };

  return (
    <div>
      <MenuIcon
        id="more-pic"
        aria-label="more"
        aria-controls={open ? 'more-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{padding: '12px 36px'}}
      />
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'more-pic'}}
      >
        <MenuItem className={styles.hideItemActions}>
          <Flex justifyCenter style={{width: '100%', padding: '1px 8px'}}>
            <Button 
              size="small"
              label={i18n.t(printing ? 'printing' : 'print')}
              variant="secondary"
              disabled={printing}
              onClick={handlePrintClick}
            />
          </Flex>
          <Spacer height={16}/>
        </MenuItem>
        <MenuItem className={styles.hideItemActions}>
          <Flex justifyCenter style={{width: '100%', padding: '1px 8px'}}>
            <Button 
              size="small"
              label={i18n.t('refund')}
              variant="secondary"
              onClick={handleRefundClick}
            />
          </Flex>
          <Spacer height={16}/>
        </MenuItem>
      </Menu>
    </div>
  );
};

LabInvoiceActions.propTypes = {
  data: PropTypes.shape(),
  onDone: PropTypes.func,
};

export default LabInvoiceActions;

import { Card } from 'atoms';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import { LAB_ACTIONS, LAB_STATUS } from 'models';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAdminServiceProviders,
  updateAdminServiceProviders,
} from 'redux/admin/actionCreators';
import { LabFilters } from '.';
import LabTable from './LabTable';

const LabList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { service_providers } = useSelector((state) => state.admin);
  const { query, setQuery } = useURLQuery();

  const filterParams = { search: query?.search };

  const loadServiceProviders = (params = {}) =>
    dispatch(getAdminServiceProviders(params));

  const handleRowClick = (values, inputTarget) => {
    const { target } = inputTarget;
    if (target.id === 'more-pic' || ['path', 'svg'].includes(target.tagName)) {
      return null;
    }
    return push(`/labs/${values.id}`);
  };

  const handleActivationRequest = (data, id) => {
    dispatch(updateAdminServiceProviders(data, id)).then(() =>
      loadServiceProviders(filterParams),
    );
  };

  const handleAcceptanceRequest = (id) => {
    dispatch(
      updateAdminServiceProviders({ status: LAB_STATUS.ACTIVATED }, id),
    ).then(() => loadServiceProviders(filterParams));
  };

  const handleRejectionRequest = (id) => {
    dispatch(
      updateAdminServiceProviders({ status: LAB_STATUS.REJECTED }, id),
    ).then(() => loadServiceProviders(filterParams));
  };

  const handleSearch = (value) => {
    loadServiceProviders({ search: value });
    setQuery({ search: value });
  };

  const handleDeletionRequest = () => {};

  const onActionClick = (data, type) => {
    switch (type) {
      case LAB_ACTIONS.ACTIVATION: {
        const status = data.activated
          ? LAB_STATUS.ACTIVATED
          : LAB_STATUS.DEACTIVATED;
        return handleActivationRequest({ status }, data.id);
      }
      case LAB_ACTIONS.ACCEPT:
        return handleAcceptanceRequest(data.id);
      case LAB_ACTIONS.REJECT:
        return handleRejectionRequest(data.id);
      case LAB_ACTIONS.DELETE:
        return handleDeletionRequest(data.id);
      default:
        return null;
    }
  };

  React.useEffect(() => {
    loadServiceProviders(filterParams);
  }, []);

  const handlePagination = useHandelPagination({
    filterParams,
    onPageChange: loadServiceProviders,
  });

  return (
    <Card styles={{ margin: '2rem' }}>
      <LabFilters reload={loadServiceProviders} onSearch={handleSearch} />
      <LabTable
        list={service_providers}
        onRowClick={handleRowClick}
        onActionClick={onActionClick}
        noData="No service providers added yet"
        handlePagination={handlePagination}
      />
    </Card>
  );
};

export default LabList;

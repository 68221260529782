import { get } from 'lodash';

import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';

import * as actions from './actions';
import { CLINIC_LIST_FULFILLED } from '../lab/actions';

export const initialState = {
  fetching: false,
  billings: [],
  invoices: [],
  batches: [],
  summary: null,
  invoice: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.BILLINGS_PENDING:
    case actions.INVOICES_PENDING:
    case actions.INVOICE_PENDING:
    case actions.B2B_INVOICES_PENDING:
    case actions.BILLING_VERIFY_PENDING:
    case actions.GET_INVOICE_PENDING:
    case actions.BILLING_REFUND_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case CLINIC_LIST_FULFILLED:
      return {
        ...initialState,
      };

    case actions.BILLINGS_FULFILLED:
      return {
        ...state,
        batches: [get(action, 'payload.data.batch')].filter(Boolean),
        billings: get(action, 'payload.data.orders', []),
        summary: get(action, 'payload.data.billing_info'),
        invoice: get(action, 'payload.data.invoice'),
        fetching: false,
      };

    case actions.RESET_BILLINGS:
      return {
        ...initialState,
      };

    case actions.INVOICES_FULFILLED:
      return {
        ...state,
        invoices: get(action, 'payload.data.invoices', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.INVOICE_FULFILLED:
      return {
        ...state,
        invoice: get(action, 'payload.data.invoice', []),
        fetching: false,
      };

    case actions.B2B_INVOICES_FULFILLED:
      return {
        ...state,
        invoices: get(action, 'payload.data.b2b_invoices', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.BILLING_VERIFY_FULFILLED:
    case actions.GET_INVOICE_FULFILLED:
    case actions.BILLING_REFUND_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        fetching: false,
      };

    case actions.BILLINGS_REJECTED:
    case actions.INVOICES_REJECTED:
    case actions.INVOICE_REJECTED:
    case actions.B2B_INVOICES_REJECTED:
    case actions.BILLING_VERIFY_REJECTED:
    case actions.GET_INVOICE_REJECTED:
    case actions.BILLING_REFUND_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

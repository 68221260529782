/* eslint-disable indent */
import { get } from 'lodash';
import { handleResponseErr } from 'utils/apiHelperUtils';
import { Toastr } from 'utils/Toastr';
import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  packages: [],
  onePackage: undefined,
  pagination: {},
  message: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PACKAGES_CREATE_PENDING:
    case actions.PACKAGES_LOAD_PENDING:
    case actions.PACKAGES_SHOW_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case actions.PACKAGE_DELETE_PENDING:
      return {
        ...state,
        submitting: true,
      };

    case actions.PACKAGES_UPDATE_PENDING:
      return {
        ...state,
        submitting: true,
      };

    case actions.PACKAGES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        onePackage: action.payload.data.package,
        submitting: false,
      };

    case actions.PACKAGE_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        submitting: false,
      };

    case actions.PACKAGES_LOAD_FULFILLED: {
      return {
        ...state,
        ...action.payload.data,
        packages: get(action, 'payload.data.packages', []),
        fetching: false,
      };
    }

    case actions.PACKAGES_CREATE_FULFILLED:
    case actions.PACKAGES_SHOW_FULFILLED: {
      return {
        ...state,
        onePackage: action.payload.data.package,
        fetching: false,
      };
    }

    case actions.PACKAGES_CREATE_REJECTED:
    case actions.PACKAGES_UPDATE_REJECTED:
    case actions.PACKAGE_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload));

      return {
        ...state,
        submitting: false,
      };

    case actions.PACKAGES_LOAD_REJECTED:
    case actions.PACKAGES_SHOW_REJECTED:
      Toastr.error(handleResponseErr(action.payload));

      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
}

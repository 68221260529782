import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { CLIENT_TYPE } from 'models';

import { Grid } from '@mui/material';
import { Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';

const InvoiceFilters = ({
  client,
  generateSelectFilters,
  generateOtherFilters,
}) => {
  const [clinicOptions, setClinicOptions] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [selectFilterConfigs, setSelectFilterConfigs] = useState([]);
  const { clinics, collectionCenters } = useSelector((state) => state.lab);
  const dateFilterConfigs = [
    { key: 'from_date', placeholderKey: 'from_date' },
    { key: 'to_date', placeholderKey: 'to_date' },
  ];

  useEffect(() => {
    const configs = [];
    if (client === CLIENT_TYPE.CLINICS) {
      configs.push({
        key: 'clinic',
        options: clinicOptions,
        placeholderKey: 'clinic',
      });
    } else {
      configs.push({
        key: 'collection_center',
        options: centerOptions,
        placeholderKey: 'collection_center',
      });
    }
    setSelectFilterConfigs(configs);
  }, [client, clinicOptions, centerOptions]);

  useEffect(() => {
    const options = collectionCenters.map((center) => ({
      value: valueGetter(center, 'secondary.id'),
      label: valueGetter(center, 'secondary.name_i18n'),
    }));
    setCenterOptions(options);
  }, [collectionCenters]);

  useEffect(() => {
    const options = clinics.map((clinic) => ({
      value: valueGetter(clinic, 'secondary.id'),
      label: valueGetter(clinic, 'secondary.name_i18n'),
    }));
    setClinicOptions(options);
  }, [clinics]);

  const selectFilter = (
    <Grid item xs={3} lg={2}>
      <SelectFilter
        selectFilterConfigs={selectFilterConfigs}
        dateFilterConfigs={dateFilterConfigs}
        onApply={generateSelectFilters}
        onCancel={generateSelectFilters}
      />
    </Grid>
  );

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={isBrowser ? 2 : 0}
      px={isBrowser ? 3 : 2}
      alignItems="center"
    >
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('invoice_list_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t('invoice_list_subtitle')}
        </Text>
      </Grid>
      {isMobile && selectFilter}
      <Grid item xs={12} lg={7}>
        <SearchInput
          placeholder={i18n.t('invoice_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && selectFilter}
    </Grid>
  );
};

InvoiceFilters.propTypes = {
  client: PropTypes.string,
  generateSelectFilters: PropTypes.func,
  generateOtherFilters: PropTypes.func,
};

export default InvoiceFilters;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { getListForSelect, isAdminUser } from 'utils/labUtils';
import {PACKAGE_STATUSES, PRIORITIES} from 'utils/options';

import { Grid } from '@mui/material';
import { Text } from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';
import { CreateTest } from './CreateTest';
import TestUpload from './Admin/TestUpload';

const TestFilters = ({
  generateOtherFilters,
  generateSelectFilters,
}) => {
  const isAdmin = isAdminUser();
  const [selectFilterConfigs, setSelectFilterConfigs] = useState([]);
  const { categories } = useSelector((state) =>
    isAdmin ? state.admin : state.patient,
  );

  useEffect(() => {
    const configs = [
      { key: 'category', options: getListForSelect(categories), placeholderKey: 'category' },
      { key: 'status', options: PACKAGE_STATUSES, placeholderKey: 'status' },
      { key: 'priority', options: PRIORITIES, placeholderKey: 'priority' },
    ];
    setSelectFilterConfigs(configs);
  }, [categories]);

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={isMobile ? 2 : 3}
      alignItems="center"
    >
      <Grid item xs={12} lg={3}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
            {i18n.t('all_tests')}
          </Text>
        </div>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t('tests_list_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={10} lg={4}>
        <SearchInput
          placeholder={i18n.t('test_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {!isAdmin && <Grid item lg={2}/>}
      <Grid item xs={2} lg={1}>
        <SelectFilter
          className="ml-8"
          selectFilterConfigs={selectFilterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <TestUpload />
      </Grid>
      {isAdmin && (
        <Grid item xs={10} lg={2}>
          <CreateTest />
        </Grid>
      )}
    </Grid>
  );
};

TestFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
};

export default TestFilters;

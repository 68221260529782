import { get } from 'lodash';

import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';
import { isPathIncludes } from 'utils/uiHelpers';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  reservations: [],
  overdueOrders: [],
  overdueOrdersPagination: {},
  reservationInfo: {},
  users: [],
  userRoles: [],
  userStatuses: [],
  clients: [],
  clientInfo: {},
  clientTests: [],
  clinics: [],
  collectionCenters: [],
  reservationTests: [],
  reservationsPagination: {},
  roles: [],
  plans: [],
  service_provider_subscriptions: [],
};

export default function reducer(state = initialState, action) {
  const client = isPathIncludes('clinics') ? 'clinic' : 'collection_center';

  switch (action.type) {
    case actions.SERVICE_UPDATE_PENDING:
    case actions.OVERVIEW_STATS_PENDING:
    case actions.RESERVATION_INFO_PENDING:
    case actions.MASTER_RESERVATION_INFO_PENDING:
    case actions.MASTER_RESERVATION_TESTS_PENDING:
    case actions.MASTER_RESERVATION_TEST_INFO_PENDING:
    case actions.USER_LIST_PENDING:
    case actions.USER_OPTIONS_PENDING:
    case actions.CREATE_USER_PENDING:
    case actions.GET_USER_PENDING:
    case actions.UPDATE_USER_PENDING:
    case actions.DELETE_USER_PENDING:
    case actions.CLINIC_LIST_PENDING:
    case actions.CLINIC_INFO_PENDING:
    case actions.UPDATE_CLINIC_PENDING:
    case actions.INDEX_MASTER_ORDER_ORDERS_PENDING:
    case actions.GET_ROLES_PENDING:
    case actions.GET_PLANS_PENDING:
    case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case actions.OVERDUE_TESTS_LIST_PENDING:
      return {
        ...state,
        overdueOrders: [],
        overdueOrdersPagination: {},
        fetching: true,
      };

    case actions.MASTER_RESERVATION_LIST_PENDING:
      return {
        ...state,
        reservations: get(action, 'payload.data.master_orders', []),
        fetching: true,
      };

    case actions.UPDATE_MASTER_RESERVATION_INFO_PENDING:
    case actions.UPDATE_MASTER_ORDER_TEST_INFO_PENDING:
      return {
        ...state,
        submitting: true,
      };

    case actions.ACTIVE_CLINICS_PENDING:
      return {
        ...state,
        clinics: [],
        fetching: true,
      };

    case actions.ACTIVE_CENTERS_PENDING:
      return {
        ...state,
        collectionCenters: [],
      };

    case actions.OVERVIEW_STATS_FULFILLED:
      return {
        ...state,
        statistics: get(action, 'payload.data.statistics', {}),
        fetching: false,
      };

    case actions.OVERDUE_TESTS_LIST_FULFILLED:
      return {
        ...state,
        overdueOrders: get(action, 'payload.data.overdue_orders', []),
        overdueOrdersPagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.INDEX_MASTER_ORDER_ORDERS_FULFILLED:
      return {
        ...state,
        reservationTests:
          get(action, 'payload.data.orders', undefined) ||
          get(action, 'payload.data.patients', []),
        fetching: false,
      };

    case actions.MASTER_RESERVATION_LIST_FULFILLED:
      return {
        ...state,
        reservations: get(action, 'payload.data.master_orders', []),
        reservationsPagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.RESERVATION_INFO_FULFILLED:
      return {
        ...state,
        reservationInfo: get(action, 'payload.data.patient_detail', {}),
        fetching: false,
      };

    case actions.UPDATE_MASTER_RESERVATION_INFO_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        reservationInfo: get(action, 'payload.data.master_order', {}),
        submitting: false,
      };

    case actions.SYNC_CLINIC_TESTS_PENDING:
      return {
        ...state,
        submitting: true,
      };
    case actions.SYNC_CLINIC_TESTS_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        submitting: false,
      };

    case actions.UPDATE_MASTER_ORDER_TEST_INFO_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        reservationTestInfo: get(action, 'payload.data.order', {}),
        submitting: false,
      };

    case actions.MASTER_RESERVATION_INFO_FULFILLED:
      return {
        ...state,
        reservationInfo: get(action, 'payload.data.master_order', {}),
        fetching: false,
      };

    case actions.MASTER_RESERVATION_TEST_INFO_FULFILLED:
      return {
        ...state,
        reservationTestInfo: get(action, 'payload.data.patient', {}),
        fetching: false,
      };

    case actions.MASTER_RESERVATION_TESTS_FULFILLED:
      return {
        ...state,
        reservationTests: get(action, 'payload.data.patients', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.USER_LIST_FULFILLED:
      return {
        ...state,
        users: get(action, 'payload.data.users', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.USER_OPTIONS_FULFILLED:
      return {
        ...state,
        userRoles: get(action, 'payload.data.roles', []),
        userStatuses: get(action, 'payload.data.statuses', []),
        fetching: false,
      };

    case actions.CLINIC_LIST_FULFILLED:
      return {
        ...state,
        clients: get(action, `payload.data.${client}s`, []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.CLINIC_INFO_FULFILLED:
      return {
        ...state,
        clientInfo: get(action, `payload.data.${client}`, {}),
        clientTests: get(
          action,
          `payload.data.${client}.clinic_to_lab_pricing`,
          [],
        ),
        fetching: false,
      };

    case actions.FILTER_CLIENT_TEST: {
      const {
        search = '',
        status = '',
        priority = '',
        category = '',
      } = action.payload.filters;
      let tests = get(state, 'clientInfo.clinic_to_lab_pricing', []);
      if (search) {
        const regex = new RegExp(search, 'i');
        tests = tests.filter((test) => regex.test(test.service.name));
      }
      if (status) {
        tests = tests.filter((test) => test.status === status);
      }
      if (priority) {
        tests = tests.filter((test) => test.priority === priority);
      }
      if (category) {
        tests = tests.filter(
          (test) => test.service?.category_type === category,
        );
      }
      return {
        ...state,
        clientTests: tests,
      };
    }

    case actions.ACTIVE_CLINICS_FULFILLED:
      return {
        ...state,
        clinics: get(action, 'payload.data.clinics', []),
        fetching: false,
      };

    case actions.ACTIVE_CENTERS_FULFILLED:
      return {
        ...state,
        collectionCenters: get(action, 'payload.data.collection_centers', []),
        pagination: get(action, 'payload.data.pagination', {}),
      };

    case actions.SERVICE_UPDATE_FULFILLED:
    case actions.CREATE_USER_FULFILLED:
    case actions.UPDATE_USER_FULFILLED:
    case actions.DELETE_USER_FULFILLED:
    case actions.UPDATE_CLINIC_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        fetching: false,
      };

    case actions.GET_USER_FULFILLED:
      return {
        ...state,
        fetching: false,
      };

    case actions.GET_ROLES_FULFILLED:
      return {
        ...state,
        roles: get(action, 'payload.data.roles', []),
        fetching: false,
      };

    case actions.GET_PLANS_FULFILLED: {
      return {
        ...state,
        plans: action.payload.data.subscriptions,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }

    case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED: {
      return {
        ...state,
        service_provider_subscriptions:
        action.payload.data.service_provider_subscriptions,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }

    case actions.SERVICE_UPDATE_REJECTED:
    case actions.SYNC_CLINIC_TESTS_REJECTED:
    case actions.OVERVIEW_STATS_REJECTED:
    case actions.OVERDUE_TESTS_LIST_REJECTED:
    case actions.MASTER_RESERVATION_LIST_REJECTED:
    case actions.RESERVATION_INFO_REJECTED:
    case actions.MASTER_RESERVATION_INFO_REJECTED:
    case actions.MASTER_RESERVATION_TESTS_REJECTED:
    case actions.MASTER_RESERVATION_TEST_INFO_REJECTED:
    case actions.USER_LIST_REJECTED:
    case actions.USER_OPTIONS_REJECTED:
    case actions.CREATE_USER_REJECTED:
    case actions.GET_USER_REJECTED:
    case actions.UPDATE_USER_REJECTED:
    case actions.DELETE_USER_REJECTED:
    case actions.CLINIC_LIST_REJECTED:
    case actions.CLINIC_INFO_REJECTED:
    case actions.UPDATE_CLINIC_REJECTED:
    case actions.ACTIVE_CLINICS_REJECTED:
    case actions.ACTIVE_CENTERS_REJECTED:
    case actions.INDEX_MASTER_ORDER_ORDERS_REJECTED:
    case actions.GET_ROLES_REJECTED:
    case actions.GET_PLANS_REJECTED:
    case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED:
      Toastr.error(handleResponseErr(action.payload));

      return {
        ...state,
        fetching: false,
      };

    case actions.UPDATE_MASTER_RESERVATION_INFO_REJECTED:
    case actions.UPDATE_MASTER_ORDER_TEST_INFO_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
}

import React, {useEffect, useState} from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { MASTER_RESERVATION_RESULTS } from 'models';
import { MASTER_ORDER_STATUSES } from 'utils/options';

import { Grid } from '@mui/material';
import { Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';

export const MasterOrderFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const [selectFilterConfigs, setSelectFilterConfigs] = useState([]);

  const dateFilterConfigs = [
    {key: 'from_date', placeholderKey: 'from_date'},
    {key: 'to_date', placeholderKey: 'to_date'}
  ];

  useEffect(() => {
    const configs = [
      {key: 'status', options: MASTER_ORDER_STATUSES, placeholderKey: 'status'},
      {
        key: 'result',
        options: MASTER_RESERVATION_RESULTS,
        placeholderKey: 'result',
      }
    ];
    setSelectFilterConfigs(configs);
  }, []);

  const selectFilter = (
    <Grid item xs={4} lg={1}>
      <SelectFilter
        className="ml-8"
        selectFilterConfigs={selectFilterConfigs}
        dateFilterConfigs={dateFilterConfigs}
        onApply={generateSelectFilters}
        onCancel={generateSelectFilters}
      />
    </Grid>
  );

  return (
    <Grid container spacing={isMobile ? 2 : 4} py={2} px={2} alignItems="center">
      <Grid item xs={8} lg={4}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">{i18n.t('master_order_list_title')}</Text>
        <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
          {i18n.t('master_order_list_subtitle')}
        </Text>
      </Grid>
      {isMobile && selectFilter}
      <Grid item xs={12} lg={5}>
        <SearchInput
          placeholder={i18n.t('orders_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && (
        <>
          <Grid item lg={2}/>
          {selectFilter}
        </>
      )}
    </Grid>
  );
};

MasterOrderFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

const Overview = {
  // *
      
  // A
  additional_info: 'معلومات إضافية',

  // B
  bank_name: 'اسم البنك',
  
  // C
  change_number: 'تغيير الرقم',
  current_password: 'كلمة المرور الحالية', 
  change_password: 'تغيير كلمة المرور',
  change_email: 'تغيير البريد الالكتروني',
  clinic_profile: 'الملف الشخصي للعيادة',
  collection_center_profile: 'الملف الشخصي لمركز التحصيل',

  // D
  director_signature: 'توقيع المدير',

  // E
  edit_profile_info: 'تعديل الملف الشخصي',
  edit_lab_info: 'تعديل معلومات المختبر',
  edit_clinic_info: 'تعديل معلومات العيادة',
  edit_collection_center: 'تعديل معلومات مركز التحصيل',
  edit_collection_center_info: 'تعديل معلومات مركز التحصيل',
  enter_your_new_number: 'أدخل رقمك الجديد',
  enter_your_password: 'أدخل كلمة المرور الخاصة بك',
  enter_your_email: 'أدخل بريدك الإلكتروني',

  // F
      
  // G
  general_info: 'معلومات عامة',
  
  // H
      
  // I
      
  // J
      
  // K
      
  // L
  lab_director_name: 'اسم مدير المختبر',
  location_info: 'معلومات الموقع',
  lab_profile: 'الملف الشخصي للمختبر',
  lab_stamp: 'ختم المختبر',

  // M
  mobile_number: 'رقم الهاتف',
      
  // N
  new_number: 'رقم جديد',

  // O

  // P
  privacy_info:  'معلومات خاصة',
  profile: 'الملف الشخصي',

  // Q
      
  // R
  re_enter_new_password: 'أعد إدخال كلمة المرور الجديدة',
  
  // S
  sample_of_printed_paper: 'عينة من الورق المطبوع',
  subscription_info: 'معلومات الاشتراك',

  // T
      
  // U
  upload_signature: 'رفع التوقيع',

  // V
      
  // W
      
  // X
      
  // Y
      
  // Z
      
};
      
export default Overview;
      
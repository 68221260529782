import React from 'react';
import {Form} from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {required} from 'utils/FormValidations';

import {Button, Spacer} from 'atoms';
import {ModalContent} from 'templates';
import {TextInputField} from 'components/global/final-form';

const PasswordForm = ({onSubmit}) => {
  return (
    <Form onSubmit={onSubmit}>
      {({handleSubmit, values}) => (
        <form onSubmit={handleSubmit}>
          <ModalContent>
            <TextInputField
              name="password"
              type="password"
              hideShowPasswordOption
              label={i18n.t('new_password')}
              placeholder={i18n.t('password')}
              validate={required}
            />
            <Spacer height={20}/>
            <TextInputField
              name="password_confirmation"
              type="password"
              hideShowPasswordOption
              label={i18n.t('re_enter_new_password')}
              placeholder={i18n.t('password')}
              validate={required}
            />
          </ModalContent>
          <Spacer height={20}/>
          <Spacer height={1} width="100%" style={{backgroundColor: 'var(--brand-divider-white)'}}/>
          <ModalContent>
            <Button
              label={i18n.t('change_password')}
              onClick={() => onSubmit(values)}
            />
          </ModalContent>
        </form>
      )}
    </Form>
  );
};

PasswordForm.propTypes = {
  onSubmit: PropTypes.func
};

export default PasswordForm;
import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {useForm} from 'react-final-form';
import {get} from 'lodash';
import {goNextFieldByKeyUp} from 'utils/uiHelpers';

import {
  Button,
  Spacer,
  Flex,
  Text,
} from 'atoms';

import {ModalContent } from 'templates';
import {TextInputField} from 'components/global/final-form';
import {requiredWithNoMessage} from 'utils/FormValidations';
import {getAuthUser} from 'redux/auth/actionCreators';

import styles from 'components/public/styles.module.css';

const VerifyContentFields = (props) => {
  const [mobile, setMobile] = useState('');
  const {data, submitting} = useSelector(state => state.profile);
  const {change} = useForm();
  const {activeCount, countDown, onResendClick} = props;

  useEffect(() => {
    const otpToken = get(data, 'otp.token', '');
    if (otpToken) {
      [...Array(6)].map((_, i) => change(`code-${i}`, otpToken[i]));
    }
    const user = getAuthUser();
    setMobile(get(user, 'mobile', ''));
    change('mobile', mobile);
    change('otp', otpToken);
  });

  return (
    <ModalContent>
      <Spacer height={40}/>
      <Flex>
        {[...Array(6)].map((_, ind) => (
          <Fragment key={ind}>
            <TextInputField
              name={`code-${ind}`}
              className={styles.textInput}
              maxLength={1}
              validate={requiredWithNoMessage}
              onKeyUp={goNextFieldByKeyUp}
            />
            <Spacer width={14}/>
          </Fragment>
        ))}
      </Flex>

      <Spacer height={50}/>

      <Flex flexCol itemsCenter>
        <Text typography="caption10" color="var(--brand-text-gray-light)">
          {activeCount && countDown}
        </Text>
        <Spacer height={5}/>
        {!activeCount && <Text
          typography="caption10"
          color="var(--brand-text-primary)"
          onClick={() => onResendClick(mobile)}
        >
          {i18n.t('resend_code')}
        </Text>}
      </Flex>

      <Spacer height={50}/>
      <Button label={i18n.t('verify')} disabled={submitting} type="submit"/>
    </ModalContent>
  );
};

VerifyContentFields.propTypes = {
  activeCount: PropTypes.bool,
  countDown: PropTypes.string,
  onResendClick: PropTypes.func,
};

export default VerifyContentFields;

const useHandelPagination = ({ filterParams, onPageChange }) => {
  const handlePagination = (page) => {
    const params = {
      ...(filterParams || {}),
      page,
    };
    onPageChange(params);
  };

  return handlePagination;
};

export default useHandelPagination;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Styles from '../Table.module.css';

const Row = ({
  children,
  error,
  className,
  ...props
}) => (
  <tr
    className={clsx(
      className,
      error && Styles.errorRow,
    )}
    {...props}
  >
    {children}
  </tr>
);

Row.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
  className: PropTypes.string,
};

export default Row;

import React from 'react';
import PropTypes from 'prop-types';
import {PlanStep, LabStep1, LabStep2} from '.';

const CurrentStep = ({activeStep, onPlanChoosed}) => {
  const step = {
    0: <PlanStep onPlanChoosed={onPlanChoosed}/>,
    1: <LabStep1/>,
    2: <LabStep2/>,
  };
    
  return step[activeStep];
};
  
CurrentStep.propTypes = {
  activeStep: PropTypes.number,
  onPlanChoosed: PropTypes.func,
};

export default CurrentStep;

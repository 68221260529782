import i18n from 'translation/i18n';
import {
  BATCH_ORDER_STATUS,
  ORDER_TYPE,
  PRIORITY,
  TEST_RESULT,
  USER_STATUS,
  ACTIVE_STATUS,
  CLIENT_TYPE,
  REPORT,
  MODULE,
  PAYMENT_MODE,
  INVOICE_TYPE, USER_ROLE, GENDER, MASTER_RESERVATION_STATUS,
} from 'models';
import { TEST_CATEGORY } from 'models/Enums';

export const ORDER_TYPES = [
  {
    value: ORDER_TYPE.SINGLE,
    label: i18n.t(`order_type_${ORDER_TYPE.SINGLE}`),
  },
  { value: ORDER_TYPE.BATCH, label: i18n.t(`order_type_${ORDER_TYPE.BATCH}`) },
];

export const INVOICE_TYPES = [
  { value: INVOICE_TYPE.B2C, label: i18n.t('B2C') },
  { value: INVOICE_TYPE.B2B, label: i18n.t('B2B') },
];

export const CLIENT_TYPES = [
  { value: CLIENT_TYPE.CLINICS, label: i18n.t('clinics') },
  {
    value: CLIENT_TYPE.COLLECTION_CENTERS,
    label: i18n.t('collection_centers'),
  },
];

export const MASTER_ORDER_STATUSES = [
  {
    value: 'all',
    label: i18n.t('all'),
  },
  {
    value: MASTER_RESERVATION_STATUS.PENDING,
    label: i18n.t(`status_${MASTER_RESERVATION_STATUS.PENDING}`),
  },
  {
    value: MASTER_RESERVATION_STATUS.PAID,
    label: i18n.t(`status_${MASTER_RESERVATION_STATUS.PAID}`),
  },
  {
    value: MASTER_RESERVATION_STATUS.DONE,
    label: i18n.t(`status_${MASTER_RESERVATION_STATUS.DONE}`),
  },
  {
    value: MASTER_RESERVATION_STATUS.CANCELED,
    label: i18n.t(`status_${MASTER_RESERVATION_STATUS.CANCELED}`),
  },
];

export const BATCH_ORDER_STATUSES = [
  {
    value: BATCH_ORDER_STATUS.PROCESS,
    label: i18n.t(`status_${BATCH_ORDER_STATUS.PROCESS}`),
  },
  {
    value: BATCH_ORDER_STATUS.DONE,
    label: i18n.t(`status_${BATCH_ORDER_STATUS.DONE}`),
  },
];

export const USER_STATUSES = [
  { value: USER_STATUS.ACTIVATED, label: i18n.t(USER_STATUS.ACTIVATED) },
  { value: USER_STATUS.DEACTIVATED, label: i18n.t(USER_STATUS.DEACTIVATED) },
];
export const PACKAGE_STATUSES = [
  { value: USER_STATUS.ACTIVE, label: i18n.t(USER_STATUS.ACTIVE) },
  { value: USER_STATUS.INACTIVE, label: i18n.t(USER_STATUS.INACTIVE) },
];

export const PRIORITIES = [
  { value: PRIORITY.NORMAL, label: i18n.t(PRIORITY.NORMAL) },
  { value: PRIORITY.STAT, label: i18n.t(PRIORITY.STAT) },
  { value: PRIORITY.URGENT, label: i18n.t(PRIORITY.URGENT) },
];

export const CATEGORIES = [
  { value: TEST_CATEGORY.CHEMISTRY, label: i18n.t(TEST_CATEGORY.CHEMISTRY) },
  { value: TEST_CATEGORY.HEMATOLOGY, label: i18n.t(TEST_CATEGORY.HEMATOLOGY) },
  { value: TEST_CATEGORY.IMMUNO, label: i18n.t(TEST_CATEGORY.IMMUNO) },
  { value: TEST_CATEGORY.MOLECULAR, label: i18n.t(TEST_CATEGORY.MOLECULAR) },
  { value: TEST_CATEGORY.SEROLOGY, label: i18n.t(TEST_CATEGORY.SEROLOGY) },
];

export const SYMPTOMS = [
  { value: 'signs', label: i18n.t('signs') },
  { value: 'no_signs', label: i18n.t('no_signs') },
];

export const RESULT_STATUSES = [
  { value: 'all', label: i18n.t('all') },
  { value: 'normal', label: i18n.t('normal') },
  { value: 'high', label: i18n.t('high') },
  { value: 'low', label: i18n.t('low') },
];

export const TEST_RESULTS = [
  { value: TEST_RESULT.NEGATIVE, label: i18n.t(TEST_RESULT.NEGATIVE) },
  { value: TEST_RESULT.PENDING, label: i18n.t(TEST_RESULT.PENDING) },
  { value: TEST_RESULT.POSITIVE, label: i18n.t(TEST_RESULT.POSITIVE) },
];

export const RESULT_TYPES = [
  { value: TEST_RESULT.POSITIVE, label: i18n.t(TEST_RESULT.POSITIVE) },
  { value: TEST_RESULT.NEGATIVE, label: i18n.t(TEST_RESULT.NEGATIVE) },
];

export const languages = [
  { label: i18n.t('en'), value: 'en' },
  { label: i18n.t('ar'), value: 'ar' },
];

export const ACTIVE_STATUSES = [
  { value: ACTIVE_STATUS.ACTIVE, label: i18n.t(ACTIVE_STATUS.ACTIVE) },
  { value: ACTIVE_STATUS.INACTIVE, label: i18n.t(ACTIVE_STATUS.INACTIVE) },
];

export const REPORTS = [
  { value: REPORT.USER_PERFORMANCE, label: i18n.t('report_user_performance') },
  { value: REPORT.SALES, label: i18n.t('report_sales') },
  { value: REPORT.INVOICES, label: i18n.t('report_invoices') },
  { value: REPORT.RESULTS, label: i18n.t('report_results') },
  { value: REPORT.SOURCE_REPORTS, label: i18n.t('report_source_reports') },
  { value: REPORT.TOTAL_AMOUNTS, label: i18n.t('report_total_amounts') },
  { value: REPORT.INCOME_FINANCE, label: i18n.t('report_income_finance') },
  // { value: REPORT.INVOICE_FINANCE, label: i18n.t('report_invoice_finance') },
  // { value: REPORT.B2B_FINANCE, label: i18n.t('report_b2b_finance') },
];

export const PATIENT_SOURCES = [
  { value: 'all', label: i18n.t('all') },
  { value: MODULE.LAB, label: i18n.t(MODULE.LAB) },
  { value: MODULE.CLINIC, label: i18n.t(MODULE.CLINIC) },
  { value: MODULE.COLLECTION_CENTER, label: i18n.t(MODULE.COLLECTION_CENTER) },
];

export const PAYMENT_MODES = [
  { value: PAYMENT_MODE.CASH, label: i18n.t(PAYMENT_MODE.CASH) },
  { value: PAYMENT_MODE.CARD, label: i18n.t(PAYMENT_MODE.CARD) },
  { value: PAYMENT_MODE.MADA, label: i18n.t(PAYMENT_MODE.MADA) },
  { value: PAYMENT_MODE.APPLE_PAY, label: i18n.t(PAYMENT_MODE.APPLE_PAY) },
];

export const BOOLEAN_OPTIONS = [
  { value: true, label: i18n.t('yes') },
  { value: false, label: i18n.t('no') },
];

export const USER_ROLES = [
  { value: USER_ROLE.LAB_OPERATOR, label: i18n.t(USER_ROLE.LAB_OPERATOR) },
  { value: USER_ROLE.LAB_TECHNICIAN, label: i18n.t(USER_ROLE.LAB_TECHNICIAN) },
  { value: USER_ROLE.LAB_TECHNOLOGIST, label: i18n.t(USER_ROLE.LAB_TECHNOLOGIST) },
  { value: USER_ROLE.LAB_SUPERVISOR, label: i18n.t(USER_ROLE.LAB_SUPERVISOR) },
  { value: USER_ROLE.LAB_DOCTOR, label: i18n.t(USER_ROLE.LAB_DOCTOR) },
  { value: USER_ROLE.LAB_ACCOUNTANT, label: i18n.t(USER_ROLE.LAB_ACCOUNTANT) },
  {
    value: USER_ROLE.LAB_ACCOUNTANT_MANAGER,
    label: i18n.t(USER_ROLE.LAB_ACCOUNTANT_MANAGER)
  },
  { value: USER_ROLE.LAB_STORE_KEEPER, label: i18n.t(USER_ROLE.LAB_STORE_KEEPER) },
  { value: USER_ROLE.PATIENT, label: i18n.t(USER_ROLE.PATIENT) },
  { value: USER_ROLE.CLINIC, label: i18n.t(USER_ROLE.CLINIC) },
  { value: USER_ROLE.COLLECTION_CENTER, label: i18n.t(USER_ROLE.COLLECTION_CENTER) },
];

export const GENDERS = [
  { value: 'all', label: i18n.t('all') },
  { value: GENDER.MALE, label: i18n.t(GENDER.MALE) },
  { value: GENDER.FEMALE, label: i18n.t(GENDER.FEMALE) },
];

export const INVOICE_FILTERS = [
  { value: 'all', label: i18n.t('all') },
  { value: 'b2b', label: i18n.t('b2b') },
  { value: 'refunded_b2b', label: i18n.t('refunded_b2b') },
  { value: 'b2c', label: i18n.t('b2c') },
  { value: 'refunded_b2c', label: i18n.t('refunded_b2c') },
];

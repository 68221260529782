import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAuthUser } from 'redux/auth/actionCreators';
import { ACCESS_TOKEN } from 'utils/constants';
import { retryImport } from 'utils/retry';
import { MODULE } from 'models';

const LandingPage = retryImport(() => import('container/public/LandingPage'));
const LoginAsAdmin = retryImport(() => import('container/public/Admin/Login'));
const LoginAsLab = retryImport(() => import('container/public/Lab/Login'));
const LoginSlugAsLab = retryImport(() => import('container/public/Lab/LoginSlug'));
const LoginAsPatient = retryImport(() => import('container/public/Patient/Login'));

const SignupAsPatient = retryImport(() => import('container/public/Patient/Signup'));
const RegisterAsClinic = retryImport(() => import('container/public/Clinic/Register'));
const RegisterAsCollectionCenter = retryImport(() => import('container/public/CollectionCenter/Register'));

const VerifyLoginAsAdmin = retryImport(() => import('container/public/Admin/VerifyLogin'));
const VerifyLoginAsLab = retryImport(() => import('container/public/Lab/VerifyLogin'));
const VerifyLoginAsClinic = retryImport(() => import('container/public/Clinic/VerifyLogin'));
const VerifyLoginAsCollectionCenter = retryImport(() => import('container/public/CollectionCenter/VerifyLogin'));
const VerifyLoginAsPatient = retryImport(() => import('container/public/Patient/VerifyLogin'));
const VerifySignupAsPatient = retryImport(() => import('container/public/Patient/Signup/VerifySignup'));

const ForgotPassword = retryImport(() => import('container/public/Shared/ForgotPassword'));

const ResetPasswordAsAdmin = retryImport(() => import('container/public/Admin/ResetPassword'));
const ResetPasswordAsLab = retryImport(() => import('container/public/Lab/ResetPassword'));
const ResetPasswordAsClinic = retryImport(() => import('container/public/Clinic/ResetPassword'));
const ResetPasswordAsCollectionCenter = retryImport(() => import('container/public/CollectionCenter/ResetPassword'));

const PublicRoutes = () => {
  const token = !!localStorage.getItem(ACCESS_TOKEN);
  const userModule = getAuthUser()?.module || MODULE.LAB;

  const redirectPage = {
    lab: <Redirect to='/overview' />,
    clinic: <Redirect to='/overview' />,
    collection_center: <Redirect to='/overview' />,
    patient: <Redirect to='/tests' />,
    super_admin: <Redirect to='/labs' />
  };

  const redirectLoginPage = {
    lab: <Redirect to='/lab/login' />,
    clinic: <Redirect to='/clinic/login' />,
    collection_center: <Redirect to='/collection-center/login' />,
    patient: <Redirect to='/patient/login' />,
    super_admin: <Redirect to='/admin/login' />
  };

  return (
    <Switch>
      <Route path="/lab/login-slug" exact component={LoginSlugAsLab} />
      <Route path="/lab/login" exact component={LoginAsLab} />
      <Route path="/clinic/login" exact component={LoginAsLab} />
      <Route path="/collection-center/login" component={LoginAsLab} />
      <Route path="/admin/login" component={LoginAsAdmin} />
      <Route path="/patient/login" component={LoginAsPatient} />

      <Route path="/patient/signup" component={SignupAsPatient} />
      <Route path="/clinic/register" component={RegisterAsClinic} />
      <Route path="/collection-center/register" component={RegisterAsCollectionCenter} />

      <Route path="/admin/verify-login" component={VerifyLoginAsAdmin} />
      <Route path="/lab/verify-login" component={VerifyLoginAsLab} />
      <Route path="/clinic/verify-login" component={VerifyLoginAsClinic} />
      <Route path="/collection-center/verify-login" component={VerifyLoginAsCollectionCenter} />
      <Route path="/patient/verify-login" component={VerifyLoginAsPatient} />
      <Route path="/patient/verify-signup" component={VerifySignupAsPatient} />

      <Route path="/forgot-password" component={ForgotPassword} />

      <Route path="/admin/reset-password" component={ResetPasswordAsAdmin} />
      <Route path="/lab/reset-password" component={ResetPasswordAsLab} />
      <Route path="/clinic/reset-password" component={ResetPasswordAsClinic} />
      <Route path="/collection-center/reset-password" component={ResetPasswordAsCollectionCenter} />

      <Route path="/" component={LandingPage} />
      <Redirect to="/" />

      {token ? redirectPage[userModule] : redirectLoginPage[userModule]}
    </Switch>
  );
};

export default PublicRoutes;

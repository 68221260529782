import React, {useState} from 'react';

import i18n from 'translation/i18n';

import {Button, Modal} from 'atoms';
import {ModalHeader} from 'templates';
import CreateBatchForm from './CreateBatchForm';

const CreateBatch = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button label={i18n.t('create_batch')} onClick={() => setShowModal(true)}/>
      <Modal
        header={<ModalHeader title={i18n.t('create_batch')}/>}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        width={400}
        disableCloseOut
      >
        <CreateBatchForm onDone={() => setShowModal(false)}/>
      </Modal>
    </>
  );
};

export default CreateBatch;
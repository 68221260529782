import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DataTable } from 'atoms';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { useTablePagination } from 'hooks';
import { activateAllPrioritiesInTest } from 'redux/tests/actionCreators';
import { Toastr } from 'utils/Toastr';
import { TestsMenu } from './TestsMenu';

export const MENU_OPTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
};

const TestsTable = (props) => {
  const { fetching, pagination } = useSelector((state) => state.tests);
  const { list, onRowClick, onPagination } = props;

  const rows = useTablePagination({ pagination, dataList: list });

  const handleMenuClick = async (id, option) => {
    const res = await activateAllPrioritiesInTest(id, { status: option });
    Toastr.success(res.data.message);
  };

  const columns = [
    {
      field: 'lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'name',
      headerName: i18n.t('remisol_name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'principal',
      headerName: i18n.t('principal'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => i18n.t(valueGetter(row, field)),
    },
    {
      field: 'category_type',
      headerName: i18n.t('category'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({ row }) => (
        <TestsMenu onMenuClick={(option) => handleMenuClick(row.id, option)} />
      ),
    },
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

TestsTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
  onPagination: PropTypes.func,
};

export default TestsTable;

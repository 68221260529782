import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {MASTER_RESERVATION_STATUS, PERMISSION} from 'models';
import {printPDF} from 'utils/labUtils';
import {userHasPermission} from 'utils/permissionUtils';
import {getPatientInvoice} from 'redux/patients/actionCreators';

import {Button} from 'atoms';

const PrintInvoiceButton = ({masterOrderId, status}) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const showPrintBtn = status !== MASTER_RESERVATION_STATUS.PENDING &&
    userHasPermission(PERMISSION.PRINT_INVOICE);

  const handlePrintInvoice = () => {
    setIsDisabled(true);
    dispatch(getPatientInvoice(masterOrderId))
      .then(resp => resp.value.data)
      .then(blob => printPDF(blob))
      .then(() => setIsDisabled(false));
  };

  return (
    <>
      {showPrintBtn && (
        <Button
          variant="secondary"
          label={i18n.t(isDisabled ? 'printing' : 'print_invoice')}
          disabled={isDisabled}
          onClick={handlePrintInvoice}
        />
      )}
    </>
  );
};

PrintInvoiceButton.propTypes = {
  masterOrderId: PropTypes.string,
  status: PropTypes.string
};

export default PrintInvoiceButton;

import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import i18n from 'translation/i18n';
import {getUser, getUsers, updateUser} from 'redux/lab/actionCreators';

import {Modal} from 'atoms';
import {ModalHeader} from 'templates';
import {ACTIVE_STATUS} from 'models';
import {UserForm} from './UserForm';
import PasswordForm from './PasswordForm';

export const EditUser = ({userId, onClose}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [user, setUser] = useState({status: false});
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    dispatch(getUser(userId))
      .then(resp => {
        const data = get(resp, 'value.data.user', {});
        const existing_permissions = get(data, 'permissions', []);
        delete data.permissions;
        setUser({
          ...data,
          role: data.role_label,
          status: data.status_label === ACTIVE_STATUS.ACTIVATED,
          existing_permissions
        });
      });
  }, []);

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const saveUser = (payload) => {
    dispatch(updateUser(userId, payload))
      .then(() => {
        dispatch(getUsers());
        handleClose();
      });
  };

  const handleSubmit = (userInfo) => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
      return;
    }
    const permission_ids = userInfo.permissions.map(p => p.value);
    delete userInfo.permissions;
    const payload = {
      user: {
        ...userInfo,
        permission_ids
      }
    };
    if (!isPasswordEdit) {
      payload.user.status = userInfo.status ? 'activated' : 'deactivated';
    }
    saveUser(payload);
  };

  return (
    <Modal
      header={
        <ModalHeader
          title={i18n.t(isPasswordEdit ? 'change_password' : 'edit_user')}
          onBack={(isPasswordEdit || activeStep === 1) ? null : onBack}
        />
      }
      isOpen={showModal}
      onClose={handleClose}
      disableCloseOut
      width={activeStep === 1 ? 350 : 500}
    >
      {isPasswordEdit ? (
        <PasswordForm onSubmit={(data) => saveUser({ user: data })}/>
      ) : (
        <UserForm
          isEdit
          user={user}
          activeStep={activeStep}
          onSubmit={handleSubmit}
          onChangePassword={() => setIsPasswordEdit(true)}
        />
      )}
    </Modal>
  );
};

EditUser.propTypes = {
  userId: PropTypes.string,
  onClose: PropTypes.func
};
import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {Flex, Spacer, Text} from 'atoms';
import LanguageSwitcher from 'components/global/LanguageSwitcher';
import UserInfo from 'components/global/UserInfo';
import {ReactComponent as MenuIcon} from 'assets/icons/common/hamburger.svg';

import './styles.scss';
import {NavLink} from 'react-router-dom';

const MobileHeader = ({toggleLayout}) => {
  return (
    <div className="mobile-header">
      <Flex>
        <MenuIcon onClick={toggleLayout}/>
        <Spacer width={16}/>
        <NavLink to="overview">
          <Text typography="subtitle" weight="bold" color="var(--brand-text-primary)">{i18n.t('app_title')}</Text>
        </NavLink>
      </Flex>
      <Flex>
        <LanguageSwitcher/>
        <Spacer width={8}/>
        <UserInfo/>
      </Flex>
    </div>
  );
};

MobileHeader.propTypes = {
  toggleLayout: PropTypes.func
};

export default MobileHeader;
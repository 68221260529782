import React from 'react';
import { useDispatch } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';

import i18n from 'translation/i18n';
import { getStockDetails, resetStockQuantity } from 'redux/inventory/actionCreators';

import { Button, Card } from 'atoms';
import {CardContainer, ConfirmModal} from 'templates';
import PropTypes from 'prop-types';
import ItemInfo from './details/ItemInfo';
import PurchaseInfo from './details/PurchaseInfo';
import StockModal from './updateOrCreate/StockModal';

const Actions = ({stockId}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false);

  const handleReset = (isConfirmed) => {
    setShowModal(false);
    if (isConfirmed) {
      dispatch(resetStockQuantity(stockId)).then(() => {
        dispatch(getStockDetails(stockId));
      });
    }
  };

  return isBrowser && (
    <>
      <Button
        label={i18n.t('reset_stock')}
        onClick={() => setShowModal(true)}
      />
      <ConfirmModal
        showModal={showModal}
        title={i18n.t('reset_stock')}
        message={i18n.t('reset_stock_message')}
        onClose={handleReset}
      />
    </>
  );
};

Actions.propTypes = {
  stockId: PropTypes.string
};

const StockDetails = ({stockId}) => {
  const [showModal, setShowModal] = React.useState(false);

  const toggleShowModal = () => setShowModal(!showModal);

  const content = (
    <>
      <CardContainer
        title={i18n.t('stock_detail_title')}
        noPadding
        cardActions={<Actions stockId={stockId}/>}
        showBackButton
      >
        <ItemInfo onEdit={toggleShowModal} />
        <PurchaseInfo onEdit={toggleShowModal} />
      </CardContainer>
      {showModal && (
        <StockModal isEdit showModal={showModal} onClose={toggleShowModal} />
      )}
    </>
  );

  return isMobile ? content : <Card styles={{margin: '2rem'}}>{content}</Card>;
};

StockDetails.propTypes = {
  stockId: PropTypes.string
};

export default StockDetails;
export const PACKAGES_CREATE = 'PACKAGES_CREATE';
export const PACKAGES_CREATE_PENDING = 'PACKAGES_CREATE_PENDING';
export const PACKAGES_CREATE_FULFILLED = 'PACKAGES_CREATE_FULFILLED';
export const PACKAGES_CREATE_REJECTED = 'PACKAGES_CREATE_REJECTED';

export const PACKAGES_LOAD = 'PACKAGES_LOAD';
export const PACKAGES_LOAD_PENDING = 'PACKAGES_LOAD_PENDING';
export const PACKAGES_LOAD_FULFILLED = 'PACKAGES_LOAD_FULFILLED';
export const PACKAGES_LOAD_REJECTED = 'PACKAGES_LOAD_REJECTED';

export const PACKAGES_UPDATE = 'PACKAGES_UPDATE';
export const PACKAGES_UPDATE_PENDING = 'PACKAGES_UPDATE_PENDING';
export const PACKAGES_UPDATE_FULFILLED = 'PACKAGES_UPDATE_FULFILLED';
export const PACKAGES_UPDATE_REJECTED = 'PACKAGES_UPDATE_REJECTED';

export const PACKAGES_SHOW = 'PACKAGES_SHOW';
export const PACKAGES_SHOW_PENDING = 'PACKAGES_SHOW_PENDING';
export const PACKAGES_SHOW_FULFILLED = 'PACKAGES_SHOW_FULFILLED';
export const PACKAGES_SHOW_REJECTED = 'PACKAGES_SHOW_REJECTED';

export const PACKAGE_PRINT_RESULTS = 'PACKAGE_PRINT_RESULTS';
export const PACKAGE_PRINT_RESULTS_PENDING = 'PACKAGE_PRINT_RESULTS_PENDING';
export const PACKAGE_PRINT_RESULTS_FULFILLED =
  'PACKAGE_PRINT_RESULTS_FULFILLED';
export const PACKAGE_PRINT_RESULTS_REJECTED = 'PACKAGE_PRINT_RESULTS_REJECTED';

export const PACKAGE_DELETE = 'PACKAGE_DELETE';
export const PACKAGE_DELETE_PENDING = 'PACKAGE_DELETE_PENDING';
export const PACKAGE_DELETE_FULFILLED = 'PACKAGE_DELETE_FULFILLED';
export const PACKAGE_DELETE_REJECTED = 'PACKAGE_DELETE_REJECTED';

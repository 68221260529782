import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import i18n from 'translation/i18n';
import {AR_LABEL} from 'utils/constants';

import ReactSelect from 'react-select';
import {Flex, Spacer, Text} from 'atoms';
import styles from 'atoms/Select/styles.module.css';

const {language} = i18n;

const MultiSelect = forwardRef(({
  className,
  isDisabled,
  label,
  locale,
  onChange,
  options,
  style,
  value,
  ...rest
}, ref) => (
  <div
    className={clsx(
      className,
      language === AR_LABEL ? styles.containerRtl : styles.containerLtr
    )}
    style={style}
  >
    {label && (
      <>
        <Flex>
          <Text
            typography="caption12"
            as="label"
            weight="bold"
            color="var(--brand-text-gray-light)"
          >
            {label.toUpperCase()}
          </Text>
        </Flex>
        <Spacer height="8px"/>
      </>
    )}
    <ReactSelect
      ref={ref}
      isMulti
      isDisabled={isDisabled}
      menuPlacement="auto"
      menuPosition="fixed"
      value={value}
      options={options}
      onChange={onChange}
      {...rest}
    />
  </div>
));

MultiSelect.defaultProps = {
  locale: 'en',
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  locale: PropTypes.oneOf(['ar', 'en']),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({value: PropTypes.string, label: PropTypes.string})
  ).isRequired,
  style: PropTypes.shape(),
  value: PropTypes.arrayOf(PropTypes.shape()),
};


export default MultiSelect;
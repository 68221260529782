import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import routes from 'utils/routeHelpers/lab';
import { userCanAccessPage } from 'utils/permissionUtils';

import MobileHeader from 'components/global/MobileHeader';
import PrivateRoute from './PrivateRoutes';

const LabRoutes = ({ toggleLayout }) => (
  <div className="lab-page">
    {isMobile && <MobileHeader toggleLayout={toggleLayout} />}
    <Switch>
      {routes.map(
        ({ path, component }, index) =>
          userCanAccessPage(path.split('/')[1]) && (
            <PrivateRoute key={index} path={path} component={component} />
          ),
      )}
      <Redirect to="/overview" />
    </Switch>
  </div>
);

LabRoutes.propTypes = {
  toggleLayout: PropTypes.func,
};

export default LabRoutes;

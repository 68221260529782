import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { getListForSelect, isLabUser } from 'utils/labUtils';
import { composeValidators, enDecimal, required } from 'utils/FormValidations';
import { valueGetter } from 'utils/uiHelpers';
import { RESULT_TYPES } from 'utils/options';

import { Grid } from '@mui/material';
import { Button, Card } from 'atoms';
import { CheckboxField, SelectField, TextInputField } from 'components/global/final-form';
import TextAreaInputField from 'components/global/final-form/TextAreaInputField';
import { ReactComponent as CloseIcon } from 'assets/icons/common/cross-rounded.svg';

const SubTestForm = ({ index, values, showClose, onClose }) => {
  const { change } = useForm();
  const { test_types, genders } = useSelector((state) => state.admin);
  const [ranges, setRanges] = useState(valueGetter(values, `services_attributes.${index}.reference_ranges`) || [{}]);
  const [nonLogicalRanges, setNonLogicalRanges] = useState(valueGetter(values, `services_attributes.${index}.reference_range_non_logicals`) || [{}]);
  const refRangeType = useMemo(() => valueGetter(values, `services_attributes.${index}.ref_range_type`), [values]);
  const showRangeClose = useMemo(() => ranges.length > 1, [ranges]);
  const showNonLogicalRangeClose = useMemo(() => nonLogicalRanges.length > 1, [nonLogicalRanges]);
  const isNotLabUser = !isLabUser();

  const handleAddRange = () => {
    if (refRangeType === 'non_logical') {
      setNonLogicalRanges([...nonLogicalRanges, {}]);
    } else {
      setRanges([...ranges, {}]);
    }
  };

  const handleRemoveRange = (i) => {
    const updatedRanges = [...ranges];
    updatedRanges.splice(i, 1);
    setRanges(updatedRanges);
    change(`services_attributes.${index}.reference_ranges`, updatedRanges);
  };

  const handleRemoveNonLogicalRange = (i) => {
    const updateNonLogicalRanges = [...nonLogicalRanges];
    updateNonLogicalRanges.splice(i, 1);
    setNonLogicalRanges(updateNonLogicalRanges);
    change(`services_attributes.${index}.reference_range_non_logicals`, updateNonLogicalRanges);
  };

  useEffect(() => {
    const refRanges = valueGetter(values, `services_attributes.${index}.reference_ranges`);
    if (refRanges) {
      setRanges(refRanges);
    }
    const refNonLogicalRanges = valueGetter(values, `services_attributes.${index}.reference_range_non_logicals`);
    if (refNonLogicalRanges) {
      setNonLogicalRanges(refNonLogicalRanges);
    }
  }, [refRangeType]);

  return (
    <Card
      styles={{
        padding: '1rem',
        paddingTop: '2rem',
        marginBottom: '0.5rem',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: '8px', right: '8px' }}>
        {showClose && <CloseIcon onClick={onClose} />}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInputField
            name={`services_attributes.${index}.lis_code`}
            label={i18n.t('lis_code')}
            disabled={isNotLabUser}
            validate={required}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInputField
            name={`services_attributes.${index}.name`}
            label={i18n.t('parameter_name')}
            validate={required}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInputField
            name={`services_attributes.${index}.description`}
            label={i18n.t('description')}
            validate={required}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <SelectField
            name={`services_attributes.${index}.test_type`}
            label={i18n.t('test_type')}
            placeholder={i18n.t('test_type')}
            options={getListForSelect(test_types)}
            validate={required}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInputField
            name={`services_attributes.${index}.unit`}
            label={i18n.t('unit')}
            placeholder={i18n.t('unit')}
            validate={required}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInputField
            name={`services_attributes.${index}.no_decimal`}
            label={i18n.t('precision')}
            placeholder={i18n.t('precision')}
            validate={required}
          />
        </Grid>
        <Grid item xs={9}>
          <SelectField
            name={`services_attributes.${index}.ref_range_type`}
            label={i18n.t('ref_range_type')}
            options={[
              { value: 'logical', label: i18n.t('logical') },
              { value: 'non_logical', label: i18n.t('non_logical') },
            ]}
            validate={required}
          />
        </Grid>
        <Grid item xs={3} mt={3}>
          <Button
            label={i18n.t('add')}
            variant="secondary"
            onClick={handleAddRange}
          />
        </Grid>
        {refRangeType === 'non_logical' && (
          <Grid item xs={12}>
            {nonLogicalRanges.map((_, i) => (
              <div key={`non-logical-range-${i}`} style={{ position: 'relative' }}>
                <div style={{position: 'absolute', top: '5px', right: '5px'}}>
                  {showNonLogicalRangeClose && (
                    <CloseIcon onClick={() => handleRemoveNonLogicalRange(i)}/>
                  )}
                </div>
                <Grid container padding={2} gap={2} border="1px solid #d2d6de" borderRadius={2}>
                  <TextAreaInputField
                    width="100%"
                    name={`services_attributes.${index}.reference_range_non_logicals.${i}.description`}
                    label={i18n.t('description')}
                    placeholder={i18n.t('description')}
                    validate={required}
                  />
                </Grid>
              </div>
            ))}
          </Grid>
        )}
        {refRangeType === 'logical' && (
          <Grid item xs={12}>
            {ranges.map((_, i) => (
              <div key={`range-${i}`} style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                  {showRangeClose && <CloseIcon onClick={() => handleRemoveRange(i)} />}
                </div>
                <Grid container padding={2} gap={2} border="1px solid #d2d6de" borderRadius={2}>
                  <Grid item xs={10} style={{ paddingTop: 0 }}>
                    <CheckboxField
                      name={`services_attributes.${index}.reference_ranges.${i}.as_default`}
                      label='Normal Range'
                    />
                  </Grid>
                  <Grid item xs={3.7}>
                    <SelectField
                      name={`services_attributes.${index}.reference_ranges.${i}.gender`}
                      label={i18n.t('gender')}
                      placeholder={i18n.t('gender')}
                      options={getListForSelect(genders)}
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={3.7}>
                    <TextInputField
                      name={`services_attributes.${index}.reference_ranges.${i}.min_age`}
                      label={i18n.t('min_age')}
                      placeholder={i18n.t('min_age')}
                      validate={composeValidators(required, enDecimal)}
                    />
                  </Grid>
                  <Grid item xs={3.7}>
                    <TextInputField
                      name={`services_attributes.${index}.reference_ranges.${i}.max_age`}
                      label={i18n.t('max_age')}
                      placeholder={i18n.t('max_age')}
                      validate={composeValidators(required, enDecimal)}
                    />
                  </Grid>
                  <Grid item xs={3.7}>
                    <TextInputField
                      name={`services_attributes.${index}.reference_ranges.${i}.min_value`}
                      label={i18n.t('min_value')}
                      placeholder={i18n.t('min_value')}
                      validate={composeValidators(required, enDecimal)}
                    />
                  </Grid>
                  <Grid item xs={3.7}>
                    <TextInputField
                      name={`services_attributes.${index}.reference_ranges.${i}.max_value`}
                      label={i18n.t('max_value')}
                      placeholder={i18n.t('max_value')}
                      validate={composeValidators(required, enDecimal)}
                    />
                  </Grid>
                  <Grid item xs={3.7}>
                    <TextInputField
                      name={`services_attributes.${index}.reference_ranges.${i}.result`}
                      label={i18n.t('result')}
                      placeholder={i18n.t('result')}
                      options={RESULT_TYPES}
                      validate={required}
                    />
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

SubTestForm.propTypes = {
  values: PropTypes.shape(),
  index: PropTypes.number,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SubTestForm;

import React from 'react';
import {Box} from '@mui/material';
import {NavLink, useHistory} from 'react-router-dom';
import i18n from 'translation/i18n';

import {Text, Flex, Spacer} from 'atoms';
import UserInfo from 'components/global/UserInfo';
import {ReactComponent as Search} from 'assets/icons/common/search.svg';
import {ReactComponent as Settings} from 'assets/icons/common/settings-gears.svg';
import LanguageSwitcher from '../LanguageSwitcher';
import './styles.scss';

const AdminHeader = () => {
  const {push} = useHistory();
  return (
    <Box className="admin-header" display="flex" justifyContent="space-between">
      <Flex itemsCenter>
        <NavLink activeClassName="is-active" to="/labs">
          <Text typography="headline" weight="bold">{i18n.t('labs')}</Text>
        </NavLink>
        <NavLink activeClassName="is-active" to="/tests">
          <Text typography="headline" weight="bold">{i18n.t('tests')}</Text>
        </NavLink>
        <NavLink activeClassName="is-active" to="/subscriptions">
          <Text typography="headline" weight="bold">{i18n.t('subscriptions')}</Text>
        </NavLink>
        <NavLink activeClassName="is-active" to="/specimen-types">
        <Text typography="headline" weight="bold">{i18n.t('specimen_types')}</Text>
      </NavLink>
      </Flex>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Search/>
        <Spacer width={16}/>
        <Flex itemsCenter className="page-actions">
          <LanguageSwitcher />
          <Spacer width={16}/>
          <Settings style={{cursor: 'pointer'}} onClick={() => push('/settings')}/>
        </Flex>
        <UserInfo hideProfileOption/>
      </Box>
    </Box>
  );
};

export default AdminHeader;

import React from 'react';

import i18n from 'translation/i18n';
import {composeValidators, email, maxLength, minLength, enNumber, required, shouldStartWith} from 'utils/FormValidations';

import {Button, Spacer} from 'atoms';
import {TextInputField} from 'components/global/final-form';

const CreateClient1 = () => {
  return (
    <>
      <TextInputField
        name="service_provider.name_en"
        placeholder={i18n.t('name')}
        label={i18n.t('name_en').toUpperCase()}
        validate={required}
      />
      <Spacer height={12}/>
      <TextInputField
        name="service_provider.name_ar"
        placeholder={i18n.t('name')}
        label={i18n.t('name_ar').toUpperCase()}
        validate={required}
      />
      <Spacer height={12}/>
      <TextInputField
        name="user.email"
        placeholder={i18n.t('email')}
        label={i18n.t('email_address').toUpperCase()}
        validate={composeValidators(required, email)}
      />
      <Spacer height={12}/>
      <TextInputField
        name="user.mobile"
        placeholder={i18n.t('phone')}
        label={i18n.t('phone_number').toUpperCase()}
        validate={
          composeValidators(required, enNumber, shouldStartWith(5), maxLength(9), minLength(9))
        }
        maxLength={9}
      />
      <Spacer height={12}/>
      <Button type="submit" label={i18n.t('next')}/>
    </>
  );
};

export default CreateClient1;

import React, {useEffect, useMemo, useState} from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import { Grid } from '@mui/material';
import { Button, Text } from 'atoms';
import { SelectField } from 'components/global/final-form';
import { valueGetter } from 'utils/uiHelpers';
// eslint-disable-next-line import/no-named-as-default-member
import ReferenceRange from './ReferenceRange';

const Step2 = ({editMode}) => {
  const [ranges, setRanges] = useState([]);
  const { values } = useFormState();
  const { change } = useForm();
  const isLogical = useMemo(() => valueGetter(values, 'ref_range_type') === 'logical', [values]);

  const handleAddRange = () => {
    setRanges([...ranges, {}]);
  };

  const handleRemoveRange = (index) => {
    const updatedRanges = [...ranges];
    updatedRanges.splice(index, 1);
    setRanges(updatedRanges);
    change('reference_ranges', updatedRanges);
  };

  const handleTypeChange = (value) => {
    setRanges([{}]);
    change('reference_ranges', []);
    change('ref_range_type', value);
  };

  useEffect(() => {
    if (values.reference_ranges && ranges.length === 0) {
      setRanges(values.reference_ranges);
    }
  }, [values]);

  return (
    <>
      <Grid container marginBottom={2}>
        <Grid item xs={9}>
          <SelectField
            label={i18n.t('ref_range_type')}
            name="ref_range_type"
            options={[
              { value: 'logical', label: i18n.t('logical') },
              { value: 'non_logical', label: i18n.t('non_logical') },
            ]}
            disabled={editMode}
            onChange={handleTypeChange}
          />
        </Grid>
      </Grid>

      <Grid container marginBottom={2}>
        <Grid item xs={9} alignItems="center">
          <Text typography="paragraph14">
            {i18n.t('reference_ranges')}
          </Text>
        </Grid>
        {isLogical && (
          <Grid item xs={3}>
            <Button size="small" label={i18n.t('add')} onClick={handleAddRange}/>
          </Grid>
        )}
      </Grid>
      {ranges.map((range, index) => (
        <ReferenceRange
          key={index}
          index={index}
          showClose={ranges.length > 1}
          isLogical={isLogical}
          onClose={() => handleRemoveRange(index)}
        />
      ))}
    </>
  );
};

Step2.propTypes = {
  editMode: PropTypes.bool,
};

export default Step2;

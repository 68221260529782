import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { bulkUploadTests } from 'redux/admin/actionCreators';
import { testsLoad } from 'redux/tests/actionCreators';

import {Button, Flex, LinkButton, Modal, RadioBox, Spacer, Text} from 'atoms';
import { ModalContent, ModalHeader } from 'templates';
import clsx from 'clsx';
import styles from '../styles.module.css';

const TestUpload = () => {
  const dispatch = useDispatch();
  const { bulkUploadErrors } = useSelector((state) => state.admin);
  const [showModal, setShowModal] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [serviceType, setServiceType] = React.useState('multi');

  const handleUpload = (event) => {
    setUploading(true);
    if (!event.target.files) return;
    const file = event.target.files[0];
    const data = new FormData();
    data.append('service_type', serviceType);
    data.append('csv', file);
    dispatch(bulkUploadTests(data)).then(() => {
      dispatch(testsLoad());
      setServiceType('single');
      setShowModal(false);
    }).finally(() => {
      setUploading(false);
      setSubmitted(true);
    });
  };

  const handleDownload = () => {
    const filename = `upload_${serviceType}_tests_template.xlsx`;
    const link = document.createElement('a');
    link.href = `/${filename}`;
    link.download = filename;
    link.click();
  };

  return (
    <>
      <Button
        label={i18n.t('upload_tests')}
        onClick={() => {
          setShowModal(true);
          setSubmitted(false);
        }}
      />
      {showModal && (
        <Modal
          width={500}
          isOpen={showModal}
          disableCloseOut
          header={<ModalHeader title={i18n.t('upload_tests')} />}
          footer={
            <>
              <label className={clsx(styles.btn, uploading && styles.disabled)} htmlFor="file-upload">
                <Text typography="paragraph14">{i18n.t('upload_excel')}</Text>
              </label>
              <input
                id="file-upload"
                hidden
                type="file"
                accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                disabled={uploading}
                onChange={handleUpload}
              />
            </>
          }
          onClose={() => setShowModal(false)}
        >
          <ModalContent>
            <LinkButton
              label={i18n.t('download_template')}
              onClick={handleDownload}
            />
            <Spacer height={48} />
            {false && (
              <Flex>
                <RadioBox
                  label={i18n.t('single')}
                  value="single"
                  checked={serviceType === 'single'}
                  onChange={setServiceType}
                />
                <Spacer width={48}/>
                <RadioBox
                  label={i18n.t('multi')}
                  value="multi"
                  checked={serviceType === 'multi'}
                  onChange={setServiceType}
                />
              </Flex>
            )}
            <Spacer height={48} />
            {submitted && bulkUploadErrors.length && (
              <>
                <Text typography="paragraph16" color="red">
                  {bulkUploadErrors.length} error(s) occurred while uploading the file:
                </Text>
                <ul className={styles.errors}>
                  {bulkUploadErrors.map((e, i) => (
                    <Text key={i} as="li" color="red" typography="paragraph14">
                      Row {e.row + 2} - {e.error}
                    </Text>
                  ))}
                </ul>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default TestUpload;
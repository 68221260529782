import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {formattedPrice} from 'utils/labUtils';

import {Flex, Text} from 'atoms';

const PaymentSummary = ({price, mode}) => {
  const total = formattedPrice(price?.total, 'saudi_riyal');
  const items = [
    {label: 'paid', value: total},
    {label: 'cash', value: mode === 'cash' ? total : '—'},
    {label: 'card', value: mode !== 'cash' ? total : '—'},
  ];
  return (
    <Flex justifyBetween style={{padding: '1.5rem 0'}}>
      {items.map(item => (
        <Flex flexCol key={item.label}>
          <Text style={{marginBottom: '0.25rem'}} typography="caption13" weight="600" color="var(--brand-text-gray)">
            {i18n.t(item.label)}
          </Text>
          <Text typography="paragraph16" weight="bold">{item.value}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

PaymentSummary.propTypes = {
  price: PropTypes.shape(),
  mode: PropTypes.string
};

export default PaymentSummary;

import React from 'react';
import {NavLink} from 'react-router-dom';
import i18n from 'translation/i18n';
import clsx from 'clsx';
import {Grid} from '@mui/material';
import {Flex, Text, Spacer} from 'atoms';
import UserInfo from 'components/global/UserInfo';
import {ReactComponent as Search} from 'assets/icons/common/search.svg';
import LanguageSwitcher from '../LanguageSwitcher';
import './styles.scss';

const PatientHeader = () => {
  return (
    <>
      <Flex className="patient-header" justifyBetween>
        <Grid spacing={3} sm={12} lg={6} container>
          <Grid item>
            <NavLink activeClassName="is-active" to="/tests">
              <Text typography="headline" weight="bold">{i18n.t('tests')}</Text>
            </NavLink>
          </Grid>
          <Grid item>
            <NavLink activeClassName="is-active" to="/book-test">
              <Text typography="headline" weight="bold">{i18n.t('book_test')}</Text>
            </NavLink>
          </Grid>
        </Grid>
        <Grid spacing={3} justifyContent="end" sm={12} lg={6} container>
          <Grid item className='hide-in-small-screen'>
            <Flex itemsCenter className={clsx('page-actions', 'lg-screen')}>
              <Search/>
              <Spacer width={16}/>
              <LanguageSwitcher />
            </Flex>
          </Grid>
          <Grid item>
            <UserInfo hideProfileOption/>
          </Grid>
        </Grid>
      </Flex>
      <div className='show-in-small-screen'>
        <Flex className="patient-header" justifyBetween>
          <Flex itemsCenter justifyEnd>
            <Search />
          </Flex>
          <LanguageSwitcher />
        </Flex>
      </div>
    </>
  );
};

export default PatientHeader;

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {removeAuthUser, getAuthUser} from 'redux/auth/actionCreators';
import {userModule} from 'utils/labUtils';
import {userCanAccessPage} from 'utils/permissionUtils';

import TempLogo from 'assets/icons/logo/labby-logo-eclipse.svg';
import {Avatar, Menu, MenuItem} from '@mui/material';
import {Text, Spacer} from 'atoms';
import {PAGE, USER_ROLE} from 'models';
import './styles.scss';

const UserInfo = (props) => {
  const {labDetails} = useSelector(state => state.general);
  const {hideProfileOption} = props;
  const {push, location} = useHistory();
  const userInfo = getAuthUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});
  const open = Boolean(anchorEl);
  const module = userModule();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeAuthUser();
    switch (user.role_label) {
    case USER_ROLE.PATIENT:
      return push('/patient/login');

    case USER_ROLE.CLINIC:
      return push('/clinic/login');

    case USER_ROLE.SUPER_ADMIN:
      return push('/admin/login');

    case USER_ROLE.COLLECTION_CENTER:
      return push('/collection-center/login');

    default:
      return push('/lab/login');
    }
  };

  const handleProfileClick = (path) => {
    const isNotInPage = !location.pathname.endsWith(path);
    if (isNotInPage) {
      push(`/${path}`);
    }
  };

  useEffect(() => {
    setUser(userInfo);
  }, []);

  return (
    <div className="user-info">
      <Text typography="paragraph14" onClick={() => handleProfileClick('user-profile')}>
        {user.name_i18n}
      </Text>
      <Spacer width={8}/>
      <Avatar
        id="profile-pic"
        aria-label="more"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        src={labDetails.logo_url || TempLogo}
      />
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'profile-pic'}}
      >
        {!hideProfileOption && (
          <div>
            <MenuItem onClick={() => handleProfileClick('user-profile')}>
              <Text typography="paragraph14" weight="bold">{i18n.t('profile')}</Text>
            </MenuItem>
            {userCanAccessPage(PAGE.LAB_PROFILE) && (
              <MenuItem onClick={() => handleProfileClick('lab-profile')}>
                <Text typography="paragraph14" weight="bold">{i18n.t(`${module}_profile`)}</Text>
              </MenuItem>
            )}
          </div>
        )}
        <MenuItem onClick={handleLogout}>
          <Text typography="paragraph14" weight="bold">{i18n.t('logout')}</Text>
        </MenuItem>
      </Menu>
    </div>
  );
};

UserInfo.propTypes = {
  hideProfileOption: PropTypes.bool,
};

export default UserInfo;

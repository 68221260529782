import React from 'react';
import PropTypes from 'prop-types';

import {Form} from 'react-final-form';
import StepsTemplate from './steps/StepsTemplate';

export const UserForm = ({onChangePassword, onSubmit, activeStep, user, isEdit}) => (
  <Form
    style={{maxHeight: 'calc(100vh - 50rem)', overflowY: 'auto'}}
    initialValues={user}
    onSubmit={(values) => onSubmit(values)}
  >
    {({handleSubmit, values, form}) => (
      <form onSubmit={(data) => handleSubmit(data)}>
        <StepsTemplate
          form={form}
          activeStep={activeStep}
          isEdit={isEdit}
          values={values}
          onChangePassword={onChangePassword}
        />
      </form>
    )}
  </Form>
);

UserForm.defaultProps = {
  isEdit: false,
  user: {},
  onChangePassword: () => {
  }
};

UserForm.propTypes = {
  isEdit: PropTypes.bool,
  activeStep: PropTypes.number,
  onSubmit: PropTypes.func,
  onChangePassword: PropTypes.func,
  user: PropTypes.shape()
};
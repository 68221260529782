import { Button } from 'atoms';
import React from 'react';
import i18n from 'translation/i18n';
import { SelectTests } from './SelectTests';

const CreatePackageStepTwo = () => {
  return (
    <>
      <SelectTests />
      <Button type="submit" label={i18n.t('package_add')} />
    </>
  );
};

export default CreatePackageStepTwo;

import { Card } from 'atoms';
import HeaderFilter from 'components/global/HeaderFilter';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { testsLoad } from 'redux/tests/actionCreators';
import { isAdminUser } from 'utils/labUtils';
import { ListFilters } from 'components/hoc';
import { TestsTable } from '.';
import { TABS } from '../tabs';
import TestFilters from '../TestFilters';
import { PackageList } from '../testPackages';

const checkValues = {
  input: true,
  span: true,
  svg: true,
  button: true,
};

const TestList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { message, tests } = useSelector((state) => state.tests);
  const { query, setQuery } = useURLQuery();

  const filterParams = {
    category: query?.category,
    search: query?.search,
    status: query?.status,
    priority: query?.priority,
  };

  const loadServices = (params = {}) => dispatch(testsLoad(params));

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      status: filter.status,
      category: filter.category,
      priority: filter.priority,
    };
    setQuery(params);
    loadServices(params);
  };

  const handlePagination = useHandelPagination({
    filterParams,
    onPageChange: loadServices,
  });

  const handleRowClick = (values, inputTarget) => {
    const { id } = values.row;
    if (!checkValues[inputTarget.target.nodeName.toLowerCase()]) {
      return push(`/tests/${id}`, { id });
    }
    return false;
  };

  React.useEffect(() => {
    loadServices(filterParams);
  }, []);

  const [tab, setTab] = useState(query?.tab || TABS[0].value);
  const isAdmin = isAdminUser();

  return (
    <>
      {isAdmin && tab && (
        <div style={{ display: 'flex', margin: '0 2rem' }}>
          <Card styles={{ padding: '0.5rem' }}>
            <HeaderFilter
              selectedOption={tab}
              options={TABS}
              onChange={(value) => {
                push(`/tests?tab=${value}`);
                setTab(value);
              }}
            />
          </Card>{' '}
        </div>
      )}
      {tab === TABS[0].value ? (
        <Card styles={{ margin: '2rem' }}>
          <ListFilters Wrapped={TestFilters} onChange={handleFilterChange} />
          <TestsTable
            list={tests}
            onRowClick={handleRowClick}
            onDone={loadServices}
            noData={message}
            onPagination={handlePagination}
            checkboxSelection={false}
          />
        </Card>
      ) : (
        <PackageList />
      )}
    </>
  );
};

export default TestList;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { generateOptionsFromEnum, isLabUser } from 'utils/labUtils';
import { getLocations } from 'redux/inventory/actionCreators';

import LabHeader from 'components/global/LabHeader';
import { Locations } from 'components/locations';
import { Stocks } from 'components/stocks';

const HEADER_OPTIONS = {
  STOCKS: 'stocks',
  LOCATIONS: 'locations',
};

const Inventory = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(HEADER_OPTIONS.STOCKS);

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  return (
    <>
      <LabHeader
        title="inventory"
        selectedOption={tab}
        filterOptions={isLabUser() ? generateOptionsFromEnum(HEADER_OPTIONS) : []}
        onFilterChange={setTab}
      />
      {tab === HEADER_OPTIONS.STOCKS && <Stocks/>}
      {tab === HEADER_OPTIONS.LOCATIONS && <Locations/>}
    </>
  );
};

export default Inventory;

import React from 'react';
import i18n from 'translation/i18n';
import {Spacer} from 'atoms';
import {ModalContent} from 'templates';
import {TextInputField} from 'components/global/final-form';
import {
  composeValidators,
  required,
  maxLength,
  minLength,
  enNumber,
} from 'utils/FormValidations';

const LabStep2 = () => {

  return (
    <ModalContent>
      <TextInputField
        name="service_provider.vat_number"
        placeholder={i18n.t('vat')}
        label={i18n.t('vat_number').toUpperCase()}
        validate={composeValidators(required, enNumber)}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.cr_number"
        placeholder={i18n.t('commercial_registration')}
        label={i18n.t('cr_number').toUpperCase()}
        validate={composeValidators(required, enNumber, maxLength(10), minLength(10))}
        maxLength={10}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.bank_name"
        placeholder={i18n.t('bank_name')}
        label={i18n.t('bank_name').toUpperCase()}
        validate={required}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.iban_number"
        placeholder={i18n.t('iban')}
        label={i18n.t('iban').toUpperCase()}
        validate={required}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.address"
        placeholder={i18n.t('lab_address')}
        label={i18n.t('address').toUpperCase()}
        validate={required}
      />
    </ModalContent>
  );
};

export default LabStep2;

import { get } from 'lodash';

import { LOCALE } from 'utils/constants';

export const isRtl = localStorage.getItem(LOCALE) === 'ar';

export const valueGetter = (obj, path) => get(obj, path, '');

export const goNextFieldByKeyUp = (event, maxLength = 1, byEnter) => {
  const eventKey = event.key.toLowerCase();
  if (eventKey === 'escape' || eventKey === 'backspace') {
    const { form } = event.target;
    const index = [...form].indexOf(event.target);
    if (index > 0) {
      form.elements[index - 1].focus();
    }
    return event.preventDefault();
  }

  if (
    event.target.value.length === maxLength ||
    (byEnter && eventKey === 'enter')
  ) {
    const { form } = event.target;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    return event.preventDefault();
  }
  return null;
};

export const allFileTypes = 'image/*, .doc, .docx, .pdf, .xls, .xlsx, .csv';

export const isPathIncludes = (path) => {
  const { pathname } = window.location;
  return pathname.includes(path);
};

export const isHostContainSlug = () => {
  const url = window.location.host;
  const urlAr = url.split('.');
  const devUrlPrefixes = ['dev', 'dev2'];
  const stagingUrlPrefixes = ['staging', 'staging2'];
  const productionUrlPrefixes = ['labby'];
  if (
    [
      ...devUrlPrefixes,
      ...stagingUrlPrefixes,
      ...productionUrlPrefixes,
      ...['ngrok-free']
    ].includes(urlAr[1] || '') ||
    (urlAr[1] || '')?.startsWith('localhost')
  ) {
    return true;
  }
  return false;
};
export const getHostSlug = () => {
  const url = window.location.host;
  const urlAr = url.split('.');
  const devUrlPrefixes = ['dev', 'dev2'];
  const stagingUrlPrefixes = ['staging', 'staging2'];
  const productionUrlPrefixes = ['labby'];
  if (
    [
      ...devUrlPrefixes,
      ...stagingUrlPrefixes,
      ...productionUrlPrefixes,
    ].includes(urlAr[1] || '') ||
    (urlAr[1] || '')?.startsWith('localhost')
  ) {
    return urlAr[0];
  }
  return 'www';
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return undefined;
};

export const removeSlugFromHost = (newSlug = 'www') => {
  let hostUrl = window.location.host;
  const urlAr = hostUrl.split('.');
  if (isHostContainSlug() && urlAr[0] !== newSlug) {
    hostUrl = hostUrl.replace(`${urlAr[0]}.`, `${newSlug}.`);
    window.location.host = hostUrl;
  }
};

import {
  handleAPI,
  API_VERSION_ONE,
  removeEmptyParams,
  moduleBaseUrl,
} from 'utils/apiUtils';

import * as actions from './actions';

export function getBillings(outsourcing, params = {}) {
  const url = `/${API_VERSION_ONE}/lab/billings/${outsourcing}`;
  const method = 'GET';

  return {
    type: actions.BILLINGS,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getB2BInvoices(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/b2b_invoices`;
  const method = 'GET';

  return {
    type: actions.B2B_INVOICES,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getInvoices(params = {}) {
  const url = `${moduleBaseUrl()}/invoices`;
  const method = 'GET';

  return {
    type: actions.INVOICES,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getInvoice(id) {
  const url = `/${moduleBaseUrl()}/invoices/${id}`;
  const method = 'GET';

  return {
    type: actions.INVOICE,
    payload: handleAPI(url, {}, method),
  };
}

export function verifyPayment(payload) {
  const url = `/${API_VERSION_ONE}/lab/billings/verify`;
  const method = 'POST';

  return {
    type: actions.BILLING_VERIFY,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function getB2BInvoice(invoiceId) {
  const url = `/${API_VERSION_ONE}/lab/b2b_invoices/${invoiceId}`;
  const method = 'GET';

  return {
    type: actions.GET_INVOICE,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function getInvoicePrint(invoiceId) {
  const url = `/${moduleBaseUrl()}/invoices/${invoiceId}/print_invoice`;
  const method = 'GET';

  return {
    type: actions.GET_INVOICE,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function refundPayment(invoiceId) {
  const url = `/${API_VERSION_ONE}/lab/b2b_invoices/${invoiceId}/refund`;
  const method = 'POST';

  return {
    type: actions.BILLING_REFUND,
    payload: handleAPI(url, {}, method),
  };
}

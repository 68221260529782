import React from 'react';
import PropTypes from 'prop-types';
import {Flex, Text} from 'atoms';

const InlineInfo = ({label, value, labelVariant, valueVariant, labelWeight, valueWeight}) => {
  return (
    <Flex justifyBetween>
      <Text 
        typography={labelVariant || 'caption13'} 
        color="var(--brand-text-gray-light)" 
        textCase="capitalize"
        weight={labelWeight || 'medium'}
      >
        {label}
      </Text>
      <Text typography={valueVariant || 'paragraph14'} weight={valueWeight || 'bold'}>
        {value || '—'}
      </Text>
    </Flex>
  );
};

InlineInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelVariant: PropTypes.string,
  valueVariant: PropTypes.string,
  labelWeight: PropTypes.string,
  valueWeight: PropTypes.string,
};

export default InlineInfo;

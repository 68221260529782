import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {ACTIVE_STATUS} from 'models';
import {useTablePagination} from 'hooks';
import {deleteSpecimenType, updateSpecimenType} from 'redux/admin/actionCreators';

import {DataTable, Switch} from 'atoms';
import {ConfirmModal} from 'templates';
import SpecimenTypeActions from './SpecimenTypeActions';
import CreateSpecimenTypeModal from './CreateSpecimenType/CreateSpecimenTypeModal';

const SpecimenTypeTable = ({list, refetch}) => {
  const dispatch = useDispatch();
  const { fetching, pagination } = useSelector((state) => state.admin);
  const rows = useTablePagination({ pagination, dataList: list });
  const [selected, setSelected] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleStatusUpdate = (id, isActive = true) =>
    dispatch(updateSpecimenType(id, { specimen_type: { status: isActive ? 'active' : 'inactive' }}));

  const handleOnSubmit = (values) => {
    dispatch(updateSpecimenType(selected.id, { specimen_type: values })).then(() => {
      setSelected(null);
      setShowEditModal(false);
      refetch();
    });
  };

  const handleConfirm = (value) => {
    if (value) {
      dispatch(deleteSpecimenType(selected.id)).then(() => {
        setSelected(null);
        setShowDeleteModal(false);
        refetch();
      });
    }
    setSelected(null);
    setShowDeleteModal(false);
  };

  const handleMenuClick = (row, option) => {
    setSelected(row);
    if (option === 'delete') {
      setShowDeleteModal(true);
    } else {
      setShowEditModal(true);
    }
  };

  const columns = [
    {
      field: 'name',
      minWidth: 250,
      flex: 1,
      headerName: i18n.t('specimen_name'),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ row, value }) => (
        <Switch
          defaultChecked={value === ACTIVE_STATUS.ACTIVE}
          onChange={(checked) => handleStatusUpdate(row.id, checked)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({ row }) => (
        <SpecimenTypeActions onMenuClick={(option) => handleMenuClick(row, option)}/>
      ),
    },
  ];

  return (
    <>
      <DataTable columns={columns} rows={rows} loading={fetching}/>
      <ConfirmModal
        showModal={showDeleteModal}
        title={i18n.t('delete')}
        message={i18n.t('delete_specimen_type_message', {name: selected?.name})}
        onClose={handleConfirm}
      />
      {showEditModal && (
        <CreateSpecimenTypeModal isEdit initialValues={selected} onSubmit={handleOnSubmit}/>
      )}
    </>
  );
};

SpecimenTypeTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  refetch: PropTypes.func,
};

export default SpecimenTypeTable;
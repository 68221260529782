import React from 'react';

import LabHeader from 'components/global/LabHeader';
import LabShare from 'components/share';

export const Share = () => {
  return (
    <>
      <LabHeader title="share"/>
      <LabShare/>
    </>
  );
};

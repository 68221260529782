import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import clsx from 'clsx';

import {
  Spacer,
  Text,
  Flex,
} from 'atoms';

import styles from './CustomSelect.module.css';

const itemStyle = {
  true: styles.itemSelected,
  false: styles.item,
};

const itemColor = {
  true: 'var(--brand-text-dark-gray)',
  false: 'var(--brand-text-dark-gray-opacity-40)',
};

const getSelectedOption = (options, value) => options.filter(opt => opt.value === value)[0];

const CustomSelect = ({
  selected,
  onChange,
  options,
  style,
  label,
  ...rest
}) => {
  const [selectedItem, setSelectedItem] = React.useState(options[0]);

  const handleOnChange = (item) => {
    setSelectedItem(item);
    onChange(item);
  };

  React.useEffect(() => {
    if(selected){
      setSelectedItem(getSelectedOption(options, selected));
    }
  }, []);

  return (
    <div {...rest} style={{ ...style }}>
      {label && (
        <>
          <Text
            typography="caption12"
            as="span"
            weight="medium"
            color="var(--brand-text-gray-light)"
          >
            {label}
          </Text>
          <Spacer height="8px" />
        </>
      )}
      <Flex flexWrap style={{ gap: '6px'}}>
        {options.map(((item, ind) => (
          <div
            className={clsx(styles.item, itemStyle[(item.value === selectedItem.value)])}
            onClick={() => handleOnChange(item)}
            key={ind}
          >
            <Text
              typography="paragraph14"
              color={itemColor[(item.value === selectedItem.value)]}
            >
              {item.label}
            </Text>
          </div>
        )))}
      </Flex>
    </div>
  );
};

CustomSelect.defaultProps = {
  options: [{ label: i18n.t('no_options'), value: null }],
  style: { },
};

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(Object),
  style: PropTypes.shape(),
  label: PropTypes.string,
  selected: PropTypes.string,
};

export default CustomSelect;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import {
  getBatchOrder,
  removeOrdersFromBatch,
  updateBatchOrderResult,
} from 'redux/orders/actionCreators';

import { Card } from 'atoms';
import BatchOrderFilters from './BatchOrderFilters';
import BatchOrderTable from './BatchOrderTable';

const BatchOrder = () => {
  const dispatch = useDispatch();
  const { id: batchId } = useParams();
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleSelectionChange = (orderIds) => {
    const showBtn = orderIds.length > 0;
    setShowRemoveBtn(showBtn);
    setSelectedOrderIds(orderIds);
  };

  const handleRemove = () => {
    dispatch(removeOrdersFromBatch(batchId, selectedOrderIds));
  };
  const handleUpdate = (result) => {
    dispatch(updateBatchOrderResult(batchId, result, selectedOrderIds)).then(
      () => {
        dispatch(getBatchOrder(batchId));
      },
    );
  };

  const content = (
    <>
      <BatchOrderFilters
        showRemoveBtn={showRemoveBtn}
        onRemove={handleRemove}
        onUpdate={handleUpdate}
      />
      <BatchOrderTable onSelectionChange={handleSelectionChange} />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default BatchOrder;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Styles from './Table.module.css';

import Row from './components/Row';
import Cell from './components/Cell';
import Thead from './components/Thead';
import Tbody from './components/Tbody';

const switchSortDirection = (sortDir) => (sortDir === 'asc' ? 'desc' : 'asc');

const Table = React.forwardRef((props, ref) => {
  const {
    headers,
    withActions,
    hoverActions,
    children,
    sortable,
    sortBy,
    sortDir,
    sortExclude,
    onSorting,
    variant,
    containerStyles,
    loading,
    hasNextPage,
    onLoadMore,
    testid,
    'data-testid': dataTestId,
    disableHoverStyles,
  } = props;
  const [theaders, setTheaders] = React.useState([]);
  const [sort, setSort] = React.useState({
    sortBy,
    sortDir: sortDir || 'asc',
  });

  const [infiniteRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    scrollContainer: 'parent',
  });
  // handle on th click if the table sortable,
  const handleOnSorting = (key) => {
    const srt = {
      sortBy: key,
      sortDir: sort.sortBy === key ? switchSortDirection(sort.sortDir) : 'asc',
    };

    onSorting(srt);
    setSort(srt);
  };

  // make the headers as object
  // if user add heder as string array recreate the header
  // as object
  const handleHeaders = () => {
    let ths = headers;
    if (typeof (headers[0]) === 'string') {
      ths = headers.map((h, i) => ({
        label: h,
        key: i,
      }));
    }
    setTheaders(ths);
  };

  React.useEffect(() => {
    handleHeaders();
  }, [headers]);

  return (
    <div
      className={clsx(Styles.container, variant && Styles[variant])}
      style={{ ...containerStyles }}
    >
      <table
        className={clsx(
          Styles.table,
          hoverActions && Styles.hoverActions,
          disableHoverStyles && Styles.disableHoverStyles,
        )}
        ref={ref}
        data-testid={dataTestId || testid}
      >
        {headers && (
          <Thead>
            <Row>
              {theaders.map((th, i) => (
                <Cell
                  key={`table-th-${i}`}
                  sortable={!!(sortable && !sortExclude.includes(th.key))}
                  sortActive={sort.sortBy === th.key}
                  sortDir={sort.sortDir}
                  onClick={() => sortable && !sortExclude.includes(th.key)
                    && handleOnSorting(th.key)}
                  icon={th.icon}
                  width={th.width}
                  th
                >
                  {th.label}
                </Cell>
              ))}
              {(withActions || hoverActions) && <Cell th />}
            </Row>
          </Thead>
        )}
        <Tbody>
          {children}
        </Tbody>
      </table>
      <span style={{ width: 0, height: 0 }} ref={infiniteRef} />
    </div>
  );
});

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  withActions: PropTypes.bool,
  hoverActions: PropTypes.bool,
  children: PropTypes.node,
  sortBy: PropTypes.string,
  sortDir: PropTypes.string,
  testid: PropTypes.string,
  'data-testid': PropTypes.string,
  sortable: PropTypes.bool,
  onSorting: PropTypes.func,
  sortExclude: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.string,
  containerStyles: PropTypes.shape(),
  loading: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  onLoadMore: PropTypes.func,
  disableHoverStyles: PropTypes.bool,
};

Table.defaultProps = {
  onSorting: () => { },
  loading: false,
  hasNextPage: false,
  onLoadMore: () => { },
  disableHoverStyles: false,
};

export default Table;

import {
  handleAPI,
  API_VERSION_ONE,
  removeEmptyParams,
  moduleBaseUrl,
} from 'utils/apiUtils';
import * as actions from './actions';

export function getAdminServiceProviders(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/service_providers`;
  const method = 'GET';

  return {
    type: actions.GET_ADMIN_SERVICE_PROVIDERS,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function createSpecimenType(payload) {
  const url = `/${API_VERSION_ONE}/admin/specimen_types`;
  const method = 'POST';

  return {
    type: actions.UPDATE_SPECIMEN_TYPE,
    payload: handleAPI(url, {}, method, payload),
  };
}
export function getSpecimenTypes(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/specimen_types`;
  const method = 'GET';

  return {
    type: actions.GET_SPECIMEN_TYPES,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function updateSpecimenType(id, payload) {
  const url = `/${API_VERSION_ONE}/admin/specimen_types/${id}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_SPECIMEN_TYPE,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function deleteSpecimenType(id) {
  const url = `/${API_VERSION_ONE}/admin/specimen_types/${id}`;
  const method = 'DELETE';

  return {
    type: actions.DELETE_SPECIMEN_TYPE,
    payload: handleAPI(url, {}, method),
  };
}

export function getPlans(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/subscriptions`;
  const method = 'GET';

  return {
    type: actions.GET_PLANS,
    payload: handleAPI(url, params, method),
  };
}

export function updatePlan(id, data = {}) {
  const url = `/${API_VERSION_ONE}/admin/subscriptions/${id}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_PLAN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getServiceProviderSubscriptions(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/lab_subscriptions`;
  const method = 'GET';

  return {
    type: actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS,
    payload: handleAPI(url, params, method),
  };
}

export function getAdminServiceProviderInfo(id) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${id}`;
  const method = 'GET';

  return {
    type: actions.GET_ADMIN_SERVICE_PROVIDER_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function renewLabSubscription(lab_subscription) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${lab_subscription.service_provider_id}/lab_subscriptions`;
  const method = 'POST';

  return {
    type: actions.CREATE_SERVICE_PROVIDER_SUBSCRIPTION,
    payload: handleAPI(url, {}, method, { lab_subscription }),
  };
}

export function getLabSubscriptions(id, params = {}) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${id}/lab_subscriptions`;
  const method = 'GET';

  return {
    type: actions.GET_LAB_SUBSCRIPTIONS,
    payload: handleAPI(url, params, method),
  };
}

export function getLabSubscriptionInvoices(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/subscription_invoices`;
  const method = 'GET';

  return {
    type: actions.GET_LAB_SUBSCRIPTION_INVOICES,
    payload: handleAPI(url, params, method),
  };
}

export function printLabSubscriptionInvoice(id) {
  const url = `/${API_VERSION_ONE}/admin/subscription_invoices/${id}`;
  const method = 'GET';

  return {
    type: actions.PRINT_LAB_SUBSCRIPTION_INVOICE,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function refundLabSubscriptionInvoice(id) {
  const url = `/${API_VERSION_ONE}/admin/subscription_invoices/${id}/refund`;
  const method = 'POST';

  return {
    type: actions.REFUND_LAB_SUBSCRIPTION_INVOICE,
    payload: handleAPI(url, {}, method, {}),
  };
}

export function updateAdminServiceProviders(data, id) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${id}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_ADMIN_SERVICE_PROVIDERS,
    payload: handleAPI(url, {}, method, data),
  };
}

export function updateServiceProviderSubscription(serviceProviderId, subscriptionId,  payload) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${serviceProviderId}/lab_subscriptions/${subscriptionId}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function changeServiceProviderSubscription(serviceProviderId, subscriptionId,  payload) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${serviceProviderId}/lab_subscriptions/${subscriptionId}/change`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function createTest(data) {
  const url = `/${API_VERSION_ONE}/admin/services`;
  const method = 'POST';

  return {
    type: actions.CREATE_TEST,
    payload: handleAPI(url, {}, method, data),
  };
}

export function editTest(serviceId, data) {
  const url = `/${API_VERSION_ONE}/admin/services/${serviceId}`;
  const method = 'PUT';

  return {
    type: actions.EDIT_TEST,
    payload: handleAPI(url, {}, method, data),
  };
}

export function serviceOptions() {
  const url = `${moduleBaseUrl()}/services/options`;
  const method = 'GET';

  return {
    type: actions.GET_ADMIN_SERVICE_OPTIONS,
    payload: handleAPI(url, {}, method),
  };
}

export function bulkUploadTests(data) {
  const url = `${moduleBaseUrl()}/services/import`;
  const method = 'POST';

  return {
    type: actions.BULK_UPLOAD_TEST,
    payload: handleAPI(url, {}, method, data),
  };
}

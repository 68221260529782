import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { composeValidators, enDecimal, required } from 'utils/FormValidations';
import { getListForSelect } from 'utils/labUtils';
import { RESULT_TYPES } from 'utils/options';
import { Grid } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/common/cross-rounded.svg';
import { Card } from 'atoms';
import { CheckboxField, SelectField, TextInputField, TextAreaInputField } from 'components/global/final-form';

const ReferenceRange = ({ index, showClose, isLogical, onClose }) => {
  const { genders } = useSelector((state) => state.admin);

  return (
    <Card
      styles={{
        padding: '1rem',
        paddingTop: '2rem',
        marginBottom: '0.5rem',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: '8px', right: '8px' }}>
        {showClose && <CloseIcon onClick={onClose} />}
      </div>
      <Grid container spacing={2}>
        {isLogical ? (
          <>
            <Grid item xs={10} style={{ paddingTop: 0 }}>
              <CheckboxField
                name={`reference_ranges.${index}.as_default`}
                label='Normal Range'
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name={`reference_ranges.${index}.gender`}
                label={i18n.t('gender')}
                placeholder={i18n.t('gender')}
                options={getListForSelect(genders)}
                validate={required}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField
                name={`reference_ranges.${index}.min_age`}
                label={i18n.t('min_age')}
                placeholder={i18n.t('min_age')}
                validate={composeValidators(required, enDecimal)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField
                name={`reference_ranges.${index}.max_age`}
                label={i18n.t('max_age')}
                placeholder={i18n.t('max_age')}
                validate={composeValidators(required, enDecimal)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField
                name={`reference_ranges.${index}.min_value`}
                label={i18n.t('min_value')}
                placeholder={i18n.t('min_value')}
                validate={composeValidators(required, enDecimal)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField
                name={`reference_ranges.${index}.max_value`}
                label={i18n.t('max_value')}
                placeholder={i18n.t('max_value')}
                validate={composeValidators(required, enDecimal)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField
                name={`reference_ranges.${index}.result`}
                label={i18n.t('result')}
                placeholder={i18n.t('result')}
                options={RESULT_TYPES}
                validate={required}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={8}>
            <TextAreaInputField
              name={`reference_ranges.${index}.description`}
              label={i18n.t('description')}
              placeholder={i18n.t('description')}
              validate={composeValidators(required)}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

ReferenceRange.propTypes = {
  index: PropTypes.number,
  isLogical: PropTypes.bool,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ReferenceRange;

import React, { useState } from 'react';
import LabHeader from 'components/global/LabHeader';
import { TestList } from 'components/tests/index';
import PackageList from 'components/tests/testPackages/PackageList';
import i18n from 'translation/i18n';
import { useURLQuery } from 'hooks/useURLQuery';

export const TABS = [
  { value: 'tests', label: i18n.t('tests') },
  { value: 'packages', label: i18n.t('packages') },
];

const Tests = () => {
  const { query, setQuery } = useURLQuery();
  const [tab, setTab] = useState(query?.tab || TABS[0].value);

  return (
    <>
      <LabHeader
        title="tests"
        selectedOption={tab}
        filterOptions={TABS}
        onFilterChange={(value) => {
          setTab(value);
          setQuery({ tab: value });
        }}
      />
      {tab === TABS[0].value ? <TestList /> : <PackageList />}
    </>
  );
};

export default Tests;

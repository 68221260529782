import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { formattedPrice } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';

import { useSelector } from 'react-redux';
import { DataTable } from 'atoms';

const ReportTable = ({ onPagination }) => {
  const {reports, fetching,pagination} = useSelector(state => state.report);

  const columns = [
    { field: 'patient_name', headerName: i18n.t('patient_name'), minWidth: 120, flex: 1 },
    { field: 'invoice_no', headerName: i18n.t('invoice_number'), minWidth: 120, flex: 1 },
    {
      field: 'invoice_date',
      headerName: i18n.t('invoice_date'),
      renderCell: ({ value }) => format(value, DISPLAY_DATE_FORMAT),
      minWidth: 120,
      flex: 1
    },
    {
      field: 'total_amount',
      headerName: i18n.t('total_amount'),
      valueGetter: ({row, field}) => formattedPrice(valueGetter(row, field), 'saudi_riyal'),
      minWidth: 120,
      flex: 1
    },
    {
      field: 'invoice_type',
      headerName: i18n.t('invoice_type'),
      flex: 1
    }
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={reports}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

ReportTable.propTypes = {
  onPagination: PropTypes.func
};

export default ReportTable;
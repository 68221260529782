/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import { Button } from 'atoms';
import PatientPackageForm from './PatientPackageForm';
import PatientTestForm from './PatientTestForm';

const Step3 = ({ testTypes }) => {
  const { change } = useForm();
  const { values,  } = useFormState();
  const [tests, setTests] = useState([]);
  const [packages, setPackages] = useState(values?.orders_package || []);

  const handleAddTest = () => {
    setTests([...tests, {}]);
  };
  const handleAddPackage = () => {
    setPackages([...packages, {}]);
  };

  const handleRemoveTest = (index) => {
    const updatedTests = [...tests];
    updatedTests.splice(index, 1);
    change('orders', updatedTests);
    setTests(updatedTests);
  };

  const handleRemovePackage = (index) => {
    const updatedPackages = [...packages];
    updatedPackages.splice(index, 1);
    change('orders_package', updatedPackages);
    setPackages(updatedPackages);
  };

  useEffect(() => {
    if (values.orders) {
      setTests(values.orders);
    } else {
      handleAddTest();
    }
  }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <Button
          label={i18n.t('add_test')}
          onClick={handleAddTest}
        />
        <Button
          label={i18n.t('add_package')}
          onClick={handleAddPackage}
        />
      </div>
      {packages.map((_, index) => (
        <PatientPackageForm
          values={values}
          key={index}
          index={index}
          showClose={tests.length + packages.length > 1}
          onClose={() => handleRemovePackage()}
        />
      ))}
      {tests.map((_, index) => (
        <PatientTestForm
          testTypes={testTypes}
          values={values}
          key={index}
          index={index}
          showClose={tests.length + packages.length > 1}
          onClose={() => handleRemoveTest()}
        />
      ))}
    </>
  );
};

Step3.propTypes = {
  testTypes: PropTypes.arrayOf(PropTypes.shape()),
};

export default Step3;

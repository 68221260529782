import {snakeCase} from 'lodash';

import {handleAPI, API_VERSION_ONE, removeEmptyParams} from 'utils/apiUtils';
import * as actions from './actions';

export function getReportList(type, params = {}) {
  const url = `/${API_VERSION_ONE}/reports/${snakeCase(type)}`;
  const method = 'GET';

  return {
    type: actions.REPORTS,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getReportData(type, params = {}) {
  const url = `/${API_VERSION_ONE}/reports/${snakeCase(type)}`;
  const method = 'GET';

  return {
    type: actions.REPORT_DATA,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function printReport(type, params = {}) {
  const url = `/${API_VERSION_ONE}/reports/${snakeCase(type)}.pdf`;
  const method = 'GET';

  return {
    type: actions.PRINT_REPORT_DATA,
    payload: handleAPI(url, removeEmptyParams(params), method, null, {}, { responseType: 'blob' }),
  };
}

export function downloadReport(type, params = {}) {
  const url = `/${API_VERSION_ONE}/reports/${snakeCase(type)}.csv`;
  const method = 'GET';

  return {
    type: actions.DOWNLOAD_REPORT,
    payload: handleAPI(url, removeEmptyParams(params), method, null, {}, { responseType: 'blob' }),
  };
}

export function getReportUrl(type) {
  const url = `/${API_VERSION_ONE}/reports/${type}_report_url`;
  const method = 'GET';

  return {
    type: actions.REPORT_URL,
    payload: handleAPI(url, {}, method),
  };
}

export function getChartDataForReport(type, params = {}) {
  const url = `/${API_VERSION_ONE}/reports/chart_${type}`;
  const method = 'GET';

  return {
    type: actions.GET_REPORT_CHART_DATA,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { getAuthUser } from 'redux/auth/actionCreators';
import { isClinicUser, isCollectionCenterUser } from 'utils/labUtils';
import { userCanAccessPage, userHasPermission } from 'utils/permissionUtils';
import { PAGE, PERMISSION, USER_ROLE } from 'models';

import { Flex, Spacer, Text } from 'atoms';
import NewPatient from 'components/reservation/NewPatient/NewPatient';
import { ReactComponent as Logo } from 'assets/icons/logo/tashkhes-logo.svg';
import { ReactComponent as Close } from 'assets/icons/common/close-filled.svg';
import { NavItem } from './NavItem';
import './LabSideNav.scss';

export const LabSideNav = ({ toggleLayout }) => {
  const primaryNavs = [
    PAGE.OVERVIEW,
    PAGE.RESERVATIONS,
    PAGE.ORDERS,
    PAGE.PCR_BATCHES,
    PAGE.OVERDUE_TESTS,
    PAGE.BILLING,
    PAGE.INVOICES,
    PAGE.REPORTS,
    PAGE.INVENTORY,
  ];
  const secondaryNavs = [PAGE.USERS, PAGE.CLIENTS, PAGE.TESTS];
  const tertiaryNavs = [PAGE.SHARE, PAGE.SERIAL]; // add settings when required
  const labClientNavs = [
    PAGE.OVERVIEW,
    PAGE.RESERVATIONS,
    PAGE.ORDERS,
    PAGE.INVOICES,
    PAGE.TESTS,
    PAGE.REPORTS,
  ];
  const isLabClient = isClinicUser() || isCollectionCenterUser();
  const { role_label } = getAuthUser();

  const getNavItem = (nav) => userCanAccessPage(nav) && (
    <NavItem key={nav} onClick={toggleLayout} nav={nav} />
  );

  return (
    <Flex flexCol justifyBetween className="lab-side-nav">
      <div>
        <Flex justifyBetween className="heading">
          <Flex>
            <Logo />
            <Spacer width={16} />
            <Text typography="subtitle" color="white" as="span" weight="bold">
              {i18n.t('app_title')}
            </Text>
          </Flex>
          {isMobile && <Close onClick={toggleLayout} />}
        </Flex>
        {isLabClient ? (
          <div className="lab-navs">
            {labClientNavs.map(getNavItem)}
          </div>
        ) : (
          <>
            <div className="lab-navs">
              {primaryNavs.map(getNavItem)}
            </div>
            <div className="lab-navs secondary-navs">
              {secondaryNavs.map(getNavItem)}
            </div>
            {role_label === USER_ROLE.LAB_ADMIN && (
              <div className="lab-navs">
                {tertiaryNavs.map((nav) => (
                  <NavItem key={nav} onClick={toggleLayout} nav={nav} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {userHasPermission(PERMISSION.ADD_PATIENT) && <NewPatient />}
    </Flex>
  );
};

LabSideNav.propTypes = {
  toggleLayout: PropTypes.func,
};

import React from 'react';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {formattedPrice, labStatusTextColor} from 'utils/labUtils';

import {DataTable, Text} from 'atoms';
import SubscriptionActions from './SubscriptionActions';

const SubscriptionInfo = () => {
  const {
    fetching,
    service_provider_subscriptions
  } = useSelector(state => state.lab);

  const columns = [
    {
      field: 'subscription.name_text',
      headerName: i18n.t('package_name'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'subscription.amount',
      headerName: i18n.t('price'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) =>
        formattedPrice(valueGetter(row, field), 'saudi_riyal'),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 80,
      flex: 1,
      renderCell: ({ row, field }) => (
        <Text
          typography="paragraph14"
          weight="bold"
          color={labStatusTextColor[valueGetter(row, field)]}
        >
          {i18n.t(valueGetter(row, field))}
        </Text>
      ),
    },
    {
      field: 'subscription.duration',
      headerName: i18n.t('type'),
      minWidth: 80,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'expiry',
      headerName: i18n.t('expiry'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) =>
        format(valueGetter(row, field), DISPLAY_DATE_FORMAT),
    },
    {
      field: 'actions',
      headerName: i18n.t('actions'),
      minWidth: 50,
      renderCell: ({row}) => <SubscriptionActions data={row} />
    }
  ];

  return (
    <DataTable
      height={600}
      mobileTableHeight={15}
      rows={service_provider_subscriptions || []}
      columns={columns}
      loading={fetching}
    />
  );
};

export default SubscriptionInfo;
const Auth = {
  // *

  // A
  address: 'Address',
  all_pcr_features: 'All PCR Features',
  advanced_reports: 'Advanced Reports',
  add_your_card_details: 'Add your card details',
  already_have_account: 'Already have an account? Login.',

  // B

  // C
  cvv: 'CVV',
  choose_the_best_plan_for_your_business:
    'Choose the best plan for your business',
  custom_link: 'Custom Link',
  clinic_management: 'Clinic Management',
  create_lab: 'Create Lab',
  change_logo: 'Change Logo',
  commercial_registration: 'Commercial Registration',
  cr_number: 'CR Number',
  clinic_address: 'Clinic Address',
  card_number: 'Card Number',
  choose_location: 'Choose location',

  // D
  dont_have_an_account: 'Don’t have an account?',
  day_free_trial_no_payments_required:
    '14 Day Free Trial, no payments required',
  digits: '16 Digits',

  // E
  email: 'Email',
  email_notifications: 'Email Notifications',
  email_address: 'Email address',
  expiry: 'Expiry',
  enter_your_email_and_password_below: 'Enter your email and password below',
  enter_your_email_to_reset_your_password:
    'Enter your email to reset your password',
  enter_the_code_sent_to: 'Enter the code sent to',
  enter_your_new_password_and_verify_it_in_order_to_reset_it:
    'Enter your new password and verify it, in order to reset it.',

  // F
  full_solution: 'Full Solution',
  flexible_pricing_without_monthly_fees_and_a_5_fee_per_transaction:
    'Flexible pricing without monthly fees and a 5% fee per transaction.',
  forgot_password: 'Forgot password?',
  features: 'Features',

  // G
  get_early_access: 'Get early access',

  // H
  have_an_account: 'Have an account?',

  // I
  iban: 'IBAN',

  // J

  // K

  // L
  location: 'Location',
  log_in: 'Login',
  lab_address: 'Lab address',
  login_as_a_patient_for: 'Login as a patient for {{lab}}',

  // M

  // N
  next: 'Next',
  new_password: 'New Password',
  verify_new_password: 'Verify New Password',
  name: 'Name',
  name_en: 'Name [ English ]',
  name_ar: 'Name [ Arabic ]',
  name_on_card: 'Name on card',

  // O

  // P
  plans_and_pricing: 'Plans and pricing',
  pcr_plan: 'PCR Plan',
  phone: 'Phone',
  phone_number: 'Phone Number',
  pick_a_location: 'Pick a location',
  payment: 'Payment',
  password: 'Password',
  passwords_should_be_equal: 'Passwords should be equal!',
  password_confirmation: 'Password Confirmation',

  // Q

  // R
  reservation_management: 'Reservation Management',
  resend_code: 'Resend Code',
  reset_password: 'Reset Password',

  // S
  slug: 'Slug',
  send_results_sms: 'Send Results SMS',
  subscribe: 'Subscribe',
  signup: 'Sign up',
  signup_as_a_patient_for: 'Signup as a patient for {{lab}}',
  subscription_created_successfully: 'Subscription created successfully',

  // T
  test_management: 'Test Management',

  // U
  user_management: 'User Management',

  // V
  vat: 'Vat',
  vat_number: 'Vat Number',
  verify: 'Verify',

  // W
  we_have_sent_an_email_to_you_please_check_your_inbox_to_be_able_to_create_a_new_password:
    'We have sent an email to you. Please check your inbox to be able to updateOrCreate a new password',
  we_have_sent_an_email_to_admin_please_wait_for_your_lab_to_be_activated:
    'We have sent an email to the admin, please wait for your lab to be activated!',

  // X

  // Y

  // Z
};

export default Auth;

import React from 'react';
import PropTypes from 'prop-types';

const RouteLayoutTemplate = ({
  children,
  padding,
  style,
}) => {
  return (
    <div style={{ padding, ...style }}>
      {children}
    </div>
  );
};

RouteLayoutTemplate.defaultProps = {
  padding: 30,
};

RouteLayoutTemplate.propTypes = {
  children: PropTypes.node,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
};

export default RouteLayoutTemplate;

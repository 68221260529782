import store from 'redux/store';
// import { ACCESS_TOKEN } from 'utils/constants';
import { removeAuthUser } from 'redux/auth/actionCreators';
import { kebabCase } from 'lodash';
import { userModule } from './labUtils';

/*
  this function for handle global error response from axios
*/

function errorResponseHandler(error) {
  const { response } = error;
  const module = userModule();
  if (!module) {
    return Promise.reject(error);
  }

  if (response) {
    switch (response.status) {
      case 401:
        store.dispatch(removeAuthUser());
        window.location.href = `/${kebabCase(module)}/login`;
        break;

      // case 403:
      //   if (localStorage.getItem(ACCESS_TOKEN)) {
      //     window.location.href = '/';
      //   }
      //   break;

      default:
        break;
    }
  }
  return Promise.reject(error);
}

export default errorResponseHandler;

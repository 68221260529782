import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import { useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { MODULE } from 'models';
import { INVOICE_FILTERS, PATIENT_SOURCES } from 'utils/options';
import { valueGetter } from 'utils/uiHelpers';
import { useURLQuery } from 'hooks/useURLQuery';

import { Grid } from '@mui/material';
import { Flex, Spacer, Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';
import PrintReport from '../PrintReport';
import DownloadReport from '../DownloadReport';


const allSelectFilterConfigs = [
  { key: 'filter', options: INVOICE_FILTERS, placeholderKey: 'filter' },
  { key: 'source', options: PATIENT_SOURCES, placeholderKey: 'source' }
];
const dateFilterConfigs = [
  { key: 'from_date', placeholderKey: 'from_date' },
  { key: 'to_date', placeholderKey: 'to_date' },
];

const ReportFilters = ({
  reportType,
  filters,
  generateSelectFilters,
  generateOtherFilters,
}) => {
  const { clinics, collectionCenters } = useSelector((state) => state.lab);
  const [selectFilters, setSelectFilters] = React.useState(allSelectFilterConfigs.slice(0, 1));
  const { query, setQuery } = useURLQuery();

  const getOptions = (list) => list.map((item) => ({
    value: valueGetter(item, 'secondary.id'),
    label: valueGetter(item, 'secondary.name_i18n')
  }));

  const handleFilterChange = ({ filter, source }) => {
    const noChange = filter?.value === query?.filter && source?.value === query?.source;
    if (noChange) return;
    const newQuery = { ...query };
    const filtersToShow = [allSelectFilterConfigs[0]];
    if (filter?.value.includes('b2b')) {
      filtersToShow.push(allSelectFilterConfigs[1]);
    } else {
      source = {};
    }
    if (source?.value === MODULE.CLINIC) {
      filtersToShow.push({ key: 'source_id', options: getOptions(clinics), placeholderKey: 'clinic' });
    }
    if (source?.value === MODULE.COLLECTION_CENTER) {
      filtersToShow.push({ key: 'source_id', options: getOptions(collectionCenters), placeholderKey: 'collection_center' });
    }
    if (query.filter !== filter?.value) {
      newQuery.filter = filter?.value;
      newQuery.source = undefined;
      newQuery.source_id = undefined;
    } else if (query.source !== source?.value) {
      newQuery.source = source?.value;
      newQuery.source_id = undefined;
    }
    setQuery(newQuery);
    setSelectFilters(filtersToShow);
  };

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={isMobile ? 2 : 3}
      alignItems="center"
    >
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('reports_list_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t(snakeCase(`${reportType}_list_subtitle`))}
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={3}>
          <SelectFilter
            className="w-96"
            showTimeSelect
            selectFilterConfigs={selectFilters}
            dateFilterConfigs={dateFilterConfigs}
            onApply={generateSelectFilters}
            onCancel={generateSelectFilters}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <SearchInput
          placeholder={i18n.t(snakeCase(`${reportType}_search_input_placeholder`))}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Flex flexRow justifyEnd>
          <DownloadReport type={reportType} filters={filters} />
          <Spacer width={16}/>
          <PrintReport type={reportType} filters={filters} />
        </Flex>
      </Grid>
      {isBrowser && (
        <Grid item lg={1}>
          <SelectFilter
            className="ml-8 w-96"
            showTimeSelect
            selectFilterConfigs={selectFilters}
            dateFilterConfigs={dateFilterConfigs}
            onChange={handleFilterChange}
            onApply={generateSelectFilters}
            onCancel={generateSelectFilters}
          />
        </Grid>
      )}
    </Grid>
  );
};

ReportFilters.propTypes = {
  reportType: PropTypes.string,
  filters: PropTypes.shape(),
  generateSelectFilters: PropTypes.func,
  generateOtherFilters: PropTypes.func,
};

export default ReportFilters;

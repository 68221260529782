import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { REPORT } from 'models';
import { getReportData } from 'redux/report/actionCreators';
import { dateTimeQueryFormatToDate, dateTimeToQueryFormat } from 'utils/date';
import {PAYMENT_MODES} from 'utils/options';

import { ListFilters } from 'components/hoc';
import { useURLQuery } from 'hooks/useURLQuery';
import ReportFilters from '../ReportFilters';
import ReportTable from './ReportTable';

const TotalAmountsReports = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const selectFilters = [{
    key: 'payment_type',
    options: PAYMENT_MODES,
    placeholderKey: 'payment_type',
  }];

  const appliedFilters = {
    source: query.source,
    payment_type: query.payment_type,
    from_date: query.from_date
      ? dateTimeQueryFormatToDate(query.from_date)
      : null,
    to_date: query.to_date ? dateTimeQueryFormatToDate(query.to_date) : null,
  };

  const fetchReports = (params = {}) => {
    dispatch(getReportData(REPORT.TOTAL_AMOUNTS, params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      source: filter.source,
      payment_type: filter.payment_type,
      from_date: filter.from_date
        ? dateTimeToQueryFormat(filter.from_date)
        : null,
      to_date: filter.to_date ? dateTimeToQueryFormat(filter.to_date) : null,
    };
    setQuery(params);
    fetchReports(params);
  };

  useEffect(() => {
    fetchReports(appliedFilters);
  }, []);

  return (
    <>
      <ListFilters
        Wrapped={ReportFilters}
        reportType={REPORT.TOTAL_AMOUNTS}
        showSourceFilter
        selectFilters={selectFilters}
        filters={appliedFilters}
        onChange={handleFilterChange}
      />
      <ReportTable />
    </>
  );
};

export default TotalAmountsReports;

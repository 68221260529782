import React from 'react';
import i18n from 'translation/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, Text } from 'atoms';
import { valueGetter } from 'utils/uiHelpers';
import { ModalContent } from 'templates';
import { getLabSubscriptions } from 'redux/admin/actionCreators';
import { formattedPrice, labStatusTextColor } from 'utils/labUtils';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';
import LabSubscriptionActions from './LabSubscriptionActions';

const LabSubscriptions = () => {
  const dispatch = useDispatch();

  const { service_provider, service_provider_subscriptions, fetching } =
    useSelector((state) => state.admin);

  const loadSubscriptions = () => {
    dispatch(getLabSubscriptions(service_provider.id));
  };

  const columns = [
    {
      field: 'subscription.name_text',
      headerName: i18n.t('package_name'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'subscription.amount',
      headerName: i18n.t('price'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) =>
        formattedPrice(valueGetter(row, field), 'saudi_riyal'),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 80,
      flex: 1,
      renderCell: ({ row, field }) => (
        <Text
          typography="paragraph14"
          weight="bold"
          color={labStatusTextColor[valueGetter(row, field)]}
        >
          {i18n.t(valueGetter(row, field))}
        </Text>
      ),
    },
    {
      field: 'subscription.duration',
      headerName: i18n.t('type'),
      minWidth: 80,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'expiry',
      headerName: i18n.t('expiry'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) =>
        format(valueGetter(row, field), DISPLAY_DATE_FORMAT),
    },
    {
      field: 'actions',
      headerName: i18n.t('actions'),
      minWidth: 50,
      renderCell: ({row}) => (
        <LabSubscriptionActions
          serviceProviderId={service_provider.id}
          data={row}
        />
      )
    }
  ];

  React.useEffect(() => {
    loadSubscriptions();
  }, []);

  return (
    <ModalContent>
      <DataTable
        columns={columns}
        rows={service_provider_subscriptions || []}
        loading={fetching}
      />
    </ModalContent>
  );
};

LabSubscriptions.propTypes = {};

export default LabSubscriptions;

import React from 'react';
import { usePathname } from 'hooks';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import i18n from 'translation/i18n';
import { getStatusColor } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

import { DataTable, Tag } from 'atoms';
import EditResultTextField from 'components/orders/EditResultTextField';
import { ORDER_STATUS, PERMISSION } from 'models/Enums';
import PropTypes from 'prop-types';
import VerificationTag from './ReservationTestInfo/VerificationTag';

export const ReservationTestsTable = ({ canEditResult, refetch }) => {
  const { reservationTests, fetching } = useSelector((state) => state.lab);
  const { push } = useHistory();
  const { order_id, id } = useParams();
  const masterOrderId = order_id || id;
  const isOrderPage = usePathname().includes('orders');
  const showVerifyColumn = isOrderPage && reservationTests.some(({ status, verified }) => {
    return status === ORDER_STATUS.SUBMITTED || (status === ORDER_STATUS.READY && verified);
  });

  const columns = [
    {
      field: 'service.name',
      headerName: i18n.t('test_name'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'service.lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'service.category_type',
      headerName: i18n.t('category'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'service.specimen_types',
      headerName: i18n.t('type_of_specimen'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ value }) => value.map(v => v.name).join(', '),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ row, field }) =>
        valueGetter(row, field) && (
          <Tag
            text={i18n.t(valueGetter(row, field))}
            color={getStatusColor(valueGetter(row, field))}
          />
        ),
    },
    {
      field: 'technical_verify',
      headerName: i18n.t('tech_verification'),
      minWidth: 180,
      flex: 1,
      hide: !showVerifyColumn,
      renderCell: ({ row }) => (
        <VerificationTag
          onlyTag
          masterOrderId={masterOrderId}
          showVerifyButton={isOrderPage}
          type={PERMISSION.TECHNICAL_VERIFY}
          testInfo={row}
          onRefetch={refetch}
        />
      )
    },
    {
      field: 'medical_verify',
      headerName: i18n.t('medical_verification'),
      minWidth: 180,
      flex: 1,
      hide: !showVerifyColumn,
      renderCell: ({ row }) => (
        <VerificationTag
          onlyTag
          masterOrderId={masterOrderId}
          showVerifyButton={isOrderPage}
          type={PERMISSION.MEDICAL_VERIFY}
          testInfo={row}
          onRefetch={refetch}
        />
      )
    },
    {
      field: 'test_details.0.test_result',
      headerName: i18n.t('result'),
      minWidth: 150,
      hide: !canEditResult,
      flex: 1,
      renderCell: ({ row, field }) => {
        if (valueGetter(row, 'service.service_type') === 'multi') return <></>;
        const testStatus = valueGetter(row, 'status');
        return (
          <EditResultTextField
            result={valueGetter(row, field)}
            canEdit={[ORDER_STATUS.PROCESS, ORDER_STATUS.READY].includes(testStatus)}
            testId={valueGetter(row, 'id')}
            masterOrderId={masterOrderId}
            onSubmit={refetch}
          />
        );
      },
    },
  ];

  const handleCellClick = ({ field }, event) => {
    if (['test_details.0.test_result', 'technical_verify', 'medical_verify'].includes(field)) {
      event.stopPropagation();
    }
  };

  const handleRowClick = (values) => {
    const { id: testId } = values;
    push(`/orders/${masterOrderId}/tests/${testId}`);
  };

  return (
    <DataTable
      columns={columns}
      rows={!fetching && (reservationTests || [])}
      loading={fetching}
      onRowClick={handleRowClick}
      onCellClick={handleCellClick}
    />
  );
};

ReservationTestsTable.defaultProps = {
  canEditResult: false,
};

ReservationTestsTable.propTypes = {
  canEditResult: PropTypes.bool,
  refetch: PropTypes.func,
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import { checkReferenceRange } from 'utils/FormValidations';
import StepsTemplate from './steps/StepsTemplate';

const CreateTestForm = ({ onSubmit, isSingleTest, activeStep, editMode }) => {
  const { specimen_types } = useSelector((s) => s.admin);
  const { service_show } = useSelector((s) => s.tests);
  const [initialValues, setInitialValues] = useState({ is_single_test: isSingleTest });
  const initials = service_show;

  useEffect(() => {
    if (editMode) {
      const selectedSpecimens = (initials.specimen_types||[]).map(t => t.name);
      const testDetail = {
        ...initials,
        is_single_test: isSingleTest,
        specimen_types: specimen_types
          .filter(s => selectedSpecimens.includes(s.name))
          .map(s => ({...s, value: s.id, label: s.name}))
      };
      if (!isSingleTest) {
        testDetail.services_attributes = initials.sub_services;
      }
      if (initials.ref_range_type === 'non_logical') {
        testDetail.reference_ranges = initials.reference_range_non_logicals;
      }
      setInitialValues(testDetail);
    }
  }, [editMode]);

  return (
    <Form
      onSubmit={(values) => {
        onSubmit(values);
      }}
      initialValues={initialValues}
      style={{ maxHeight: 'calc(100vh - 50rem)', overflowY: 'auto' }}
      validate={(values) => {
        const errors = {};
        if (
          values.ref_range_type === 'logical' &&
          values.reference_ranges &&
          activeStep === 2
        ) {
          const err = checkReferenceRange(values.reference_ranges);
          if (err) {
            errors.reference_ranges = [];
            errors.reference_ranges[err.index] = err.fields.reduce((acc, field) => {
              acc[field] = err.message;
              return acc;
            }, {});
          }
        }
        return errors;
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={(values) => handleSubmit(values)}>
          <StepsTemplate activeStep={activeStep} editMode={editMode} />
        </form>
      )}
    </Form>
  );
};

CreateTestForm.defaultProps = {
  editMode: false,
};

CreateTestForm.propTypes = {
  isSingleTest: PropTypes.bool,
  onSubmit: PropTypes.func,
  activeStep: PropTypes.number,
  editMode: PropTypes.bool,
};

export default CreateTestForm;

import React from 'react';
import PropTypes from 'prop-types';
import {Card as CardLayout} from '@mui/material';
import clsx from 'clsx';

import classes from './Card.module.css';

export const Card = ({className, children, styles}) => {
  return (
    <CardLayout
      variant="outlined"
      className={clsx(classes.card, className)}
      classes={classes}
      sx={styles}
    >
      {children}
    </CardLayout>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.shape()
};

import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'translation/i18n';
import DatePicker, { registerLocale } from 'react-datepicker';
import { arSA } from 'date-fns/locale';
import { isRtl } from 'utils/uiHelpers';
import {
  TextInput,
  Text,
  Flex,
  Spacer,
} from 'atoms';
import { ReactComponent as Calendar } from 'assets/icons/atoms/calendar-primary.svg';
import styles from './DatePicker.module.css';

if (isRtl) {
  registerLocale('ar', arSA);
}

const CustomLargeInput = (props) => {
  const { value } = props;

  return (
    <Flex {...props} justifyCenter className={styles.largeInput}>
      <Flex itemsCenter>
        <Calendar />
        <Spacer width={6} />
        <Text typography="paragraph14" color="var(--brand-text-primary)">
          {value || I18n.t('pick_date')}
        </Text>
      </Flex>
    </Flex>
  );
};

CustomLargeInput.propTypes = {
  value: PropTypes.string,
};

const ReactPicker = ({
  withCustomClearIcon,
  hideMonthDropdown,
  hideYearDropdown,
  disabledTooltip,
  placeholderText,
  hideTimeSelect,
  showTimeSelect,
  displayFormat,
  withClockIcon,
  dateFormat,
  className,
  todayDate,
  isPending,
  placement,
  onChange,
  variant,
  message,
  locale,
  testId,
  label,
  style,
  value,
  error,
  size,
  name,
  showIcon,
  maxDate,
  minDate,
  startDate,
  endDate,
  disabled,
  ...props
}) => {
  const [startingDate, setStartingDate] = React.useState();

  React.useEffect(() => {
    if (value) {
      setStartingDate(new Date(value));
    } else {
      setStartingDate(null);
    }
  }, [value]);

  const handleOnChange = (val) => {
    if (!val) onChange('');
    const date = new Date(val);
    onChange(date);
    setStartingDate(date);
  };

  return (
    <Flex
      flexCol
    >
      {label && (
        <>
          <Text
            typography="caption12"
            as="span"
            weight="medium"
            color="var(--brand-text-gray-light)"
          >
            {label.toUpperCase()}
          </Text>
          <Spacer height={6} />
        </>
      )}

      <DatePicker
        // {...(!hideMonthDropdown && { showMonthDropdown: true })}
        // {...(!hideYearDropdown && { showYearDropdown: true })}
        dateFormat={displayFormat || dateFormat}
        placeholderText={placeholderText}
        customInput={variant === 'large' ? <CustomLargeInput {...props} /> : <TextInput endIcon={showIcon && <Calendar/>} />}
        onChange={handleOnChange}
        popperPlacement={placement || 'bottom'}
        selected={startingDate}
        name={name && name}
        // calendarClassName=""
        // renderDayContents={}
        // highlightDates=""
        locale={locale}
        className={className}
        showTimeSelect={showTimeSelect}
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        showYearDropdown
        showMonthDropdown
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate}
        minDate={minDate}
        autoComplete='off'
        disabled={disabled}
        // {...props}
      />
      {/* {error && <span className="tashkhes--datepicker-error">{error}</span>} */}
      {/* {message && <small>{message}</small>} */}
    </Flex>
  );
};

ReactPicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  todayDate: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  startDate: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  withCustomClearIcon: PropTypes.bool,
  placeholderText: PropTypes.string,
  disabledTooltip: PropTypes.string,
  hideMonthDropdown: PropTypes.bool,
  hideYearDropdown: PropTypes.bool,
  displayFormat: PropTypes.string,
  hideTimeSelect: PropTypes.bool,
  withClockIcon: PropTypes.bool,
  dateFormat: PropTypes.string,
  className: PropTypes.string,
  isPending: PropTypes.bool,
  message: PropTypes.string,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  style: PropTypes.shape(),
  input: PropTypes.shape(),
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  showIcon: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  placement: PropTypes.string,
  maxDate: PropTypes.shape(),
  minDate: PropTypes.shape(),
};

ReactPicker.defaultProps = {
  placeholderText: I18n.t('select_date'),
  dateFormat: 'yyyy-MM-dd',
  locale: 'en',
  size: 'medium',
  variant: 'simple',
  showIcon: false
};

export default React.memo(ReactPicker);

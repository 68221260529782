import React from 'react';
import PropTypes from 'prop-types';
import {useForm, useFormState} from 'react-final-form';
import i18n from 'translation/i18n';
import {Grid} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {getProfileOptions} from 'redux/profile/actionCreators';
import {
  CardContainer,
  CardSectionHeader,
  FieldInfoCardList,
  ModalContent,
  CustomImageUploader,
} from 'templates';
import {Flex, Text, Spacer, Button} from 'atoms';
import {profileInfo, userModule} from 'utils/labUtils';
import TempLogo from 'assets/icons/logo/labby-logo-eclipse.svg';

import ChangeModal from './ChangeModal';
import SubscriptionInfo from './SubscriptionInfo';

const modalData = {
  mobile_number: {
    title: i18n.t('change_number'),
    subTitle: i18n.t('enter_your_new_number'),
    key: 'mobile_number',
  },
  password: {
    title: i18n.t('change_password'),
    subTitle: i18n.t('enter_your_password'),
    key: 'password',
  },
  email: {
    title: i18n.t('change_email'),
    subTitle: i18n.t('enter_your_email'),
    key: 'email',
  },
};

const ProfileInfo = (props) => {
  const {editing, onEditClick, onEditDone} = props;
  const {profile, submitting, countries} = useSelector(state => state.profile);
  const dispatch = useDispatch();

  const {change} = useForm();
  const {values} = useFormState();
  const countryList = countries?.map(country => ({label: country.name_i18n, value: country.id}));
  const info = profileInfo(!editing, {}, {countries: countryList});
  const [labLogo, setLabLogo] = React.useState(null);
  const [showModal, setShowModal] = React.useState(null);

  const editText = {
    lab: 'edit_lab_info',
    clinic: 'edit_clinic_info',
    collection_center: 'edit_collection_center_info'
  };

  const actionCallback = (actionType) => {
    if(values.admin_service_provider){
      setShowModal({
        ...modalData[actionType],
        initialValues: values.admin_service_provider[0]});
    }
  };

  const onImageChange = (data) => {
    change('logo.data', data.file);
    change('logo.filename', data.file_file_name);
    change('logo.content_type', data.type);
    return setLabLogo(data.file);
  };

  React.useEffect(()=> {
    if(!isEmpty(profile)){
      setLabLogo(profile.logo_url);
    }
  },[profile.logo_url]);

  React.useEffect(()=> {
    if(values.admin_service_provider && !values.admin_service_provider[0]?.password){
      change('admin_service_provider.0.password', '00000000');
    }
  },[labLogo]);

  React.useEffect(()=> {
    dispatch(getProfileOptions());
  },[]);

  return (
    <CardContainer noPadding style={{width: '100%'}}>
      <ModalContent padding="32px 24px">
        <Flex justifyBetween itemsCenter>
          <Flex itemsCenter>
            <CustomImageUploader
              imgSrc={labLogo || TempLogo}
              altLabel="Stamp"
              onDone={onImageChange}
              actionLabel={i18n.t('change')}
              disabled={!editing}
              variant="simple"
            />
            <Spacer width={24}/>
            <Text typography="title" weight="bold">
              {profile.name_i18n || i18n.t('lab_name')}
            </Text>
          </Flex>
          {editing ?
            (<Button label={i18n.t('save')} type="submit" fitContent disabled={submitting}/>)
            :
            (
              <Text typography="caption12" link onClick={onEditClick}>
                {i18n.t(editText[userModule()])}
              </Text>
            )
          }
        </Flex>
      </ModalContent>

      <CardSectionHeader title={i18n.t('general_info')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          <FieldInfoCardList list={info.general}/>
        </Grid>
      </ModalContent>
      <CardSectionHeader title={i18n.t('location_info')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          <FieldInfoCardList list={info.location}/>
        </Grid>
      </ModalContent>
      <CardSectionHeader title={i18n.t('privacy_info')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          <FieldInfoCardList list={info.privacy} actionCallback={actionCallback}/>
        </Grid>
      </ModalContent>
      <CardSectionHeader title={i18n.t('subscription_info')} uppercased/>
      <SubscriptionInfo/>
      {!!showModal && (
        <ChangeModal 
          show={!!showModal} 
          onDone={() => {setShowModal(null); onEditDone();}} 
          data={showModal}
        />
      )}
    </CardContainer>
  );
};

ProfileInfo.propTypes = {
  editing: PropTypes.bool,
  onEditClick: PropTypes.func,
  onEditDone: PropTypes.func,
};

export default ProfileInfo;

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Overview from 'container/labs/Overview';

const OverviewRoutes = () => (
  <Switch>
    <Route path="/overview" component={Overview}/>
  </Switch>
);

export default OverviewRoutes;

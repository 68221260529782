import React from 'react';
import PropTypes from 'prop-types';
import { Divider as MUIDivider } from '@mui/material';

const Divider = ({
  width, height, color, style, ...rest
}) => (
  <MUIDivider  
    {...rest} 
    style={{
      borderStyle: 'hidden',
      ...style,
      width,
      height,
      backgroundColor: color
    }}
  />
);

Divider.defaultProps = {
  color: 'var(--brand-divider-white)',
  width: '100%',
  height: 1,
};

Divider.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  color: PropTypes.string,
};

export default Divider;

const Common = {
  // *

  // A
  about: 'About',
  about_title: 'About title',
  about_description: 'About description',
  add: 'Add',
  add_test: 'Add test',
  add_package: 'Add package',
  all: 'All',
  ar: 'AR',
  ar_full: 'العربية',
  apply: 'Apply',
  accept: 'Accept',
  activate: 'Activate',
  actions: 'Actions',

  // B
  back: 'Back',

  // C
  cancel_order: 'Cancel Order',
  canceled: 'Cancelled',
  card: 'Card',
  cash: 'Cash',
  change: 'Change',
  choose: 'Choose',
  clear: 'Clear',
  clinic: 'Clinic',
  clinic_profile: 'Clinic Profile',
  create: 'Create',
  create_new_account: 'Create new account',
  collection_center_profile: 'Center Profile',
  comments: 'Comments',
  copy_link: 'Copy Link',
  copy_success: 'Copied successfully!',
  contact_us_form_name_placeholder: 'Ahmed Ashraf',
  contact_us_form_email_placeholder: 'Email Address',
  contact_us_form_mobile_placeholder: '+(999)687-5892',
  contact_us_form_lab_name_placeholder: 'Lab name',
  contact_us_form_location_placeholder: 'ex. Saudi Arabia',
  contact_us_form_message_placeholder: 'If you have any additional information, please type it in here...',

  // D
  delete: 'Delete',
  deactivate: 'Deactivate',
  done: 'Done',
  download_csv: 'Download CSV',
  download_pdf: 'Download PDF',
  downloading: 'Downloading...',
  drop_the_files_here: 'Drop the files here',
  duplicated: 'Duplicated',
  // E
  en: 'EN',
  en_full: 'English',
  edit: 'Edit',
  edit_info: 'Edit Info',
  email_msg: 'Text should be of email format',
  enable_phl_logo: 'enable PHL logo',

  // F
  female: 'Female',
  filter: 'Filter',
  from: 'From',

  // G
  general: 'General',
  gender: 'Gender',

  // H
  hesn_result: 'HESN Result',
  hesn_status: 'HESN status',
  high: 'High',

  // I
  internet_error_message:
    'It seems something wrong with you internet connection!',
  issue_date: 'Issue Date',
  inventory: 'Inventory',

  // J

  // K

  // L
  lab_profile: 'Lab Profile',
  Language: 'Language',
  logout: 'Logout',
  logical: 'Logical',
  low: 'Low',

  // M
  male: 'Male',
  mark_as: 'Mark As',
  min_length: 'min length {{min}}',
  max_length: 'max length {{max}}',
  must_be_more_than: 'must be more than {{min}}',
  must_be_less_than: 'must be less than {{max}}',
  mobile: 'Mobile',
  my_details: 'My Details',

  // N
  negative: 'Negative',
  no_further_changes: 'No Further Changes',
  number_msg: 'Must contain numbers only',
  number_ar_msg: 'Text can not be in Arabic language',
  number: 'Number',
  no: 'No',
  normal: 'Normal',
  no_active_tests_tootip_title:
    'There is no active tests!, please set at least one test to active in order to allow you adding a patient.',
  not_ready: 'Not ready',
  not_ready_yet: 'Not ready yet',
  not_sent_yet_to_hesn: 'Not sent yet to hesn!',
  non_logical: 'Non logical',

  // O
  ok: 'OK',

  // P
  paid: 'Paid',
  payment_mode_text: 'Paid by {{mode}}',
  phone_or_email_msg: 'Must be a valid phone number or email',
  pending: 'Pending',
  pick_date: 'Pick a date',
  positive: 'Positive',
  priority: 'Priority',
  print: 'Print',
  print_pdf: 'Print PDF',
  print_results: 'Print Results',
  push: 'Push',
  permissions: 'Permissions',

  // Q

  // R
  required: 'Required',
  reject: 'Reject',
  resend: 'Resend',
  retry_to_send_hesn_again: 'Retry to send hesn again',
  failed: 'Failed',
  failed_to_send_hesn: 'Failed to send to hesn',
  error_message: 'Error Message',

  // S
  soon: 'Soon',
  sar_month: 'SAR / Month',
  saudi_riyal: 'SAR',
  save: 'Save',
  set_discount: 'Set Discount',
  set_price: 'Set Price',
  sort: 'Sort',
  status: 'Status',
  status_accepted: 'Accepted',
  status_cancelled: 'Cancelled',
  status_canceled: 'Cancelled',
  status_collected: 'Collected',
  status_confirmed: 'Confirmed',
  status_created: 'Unpaid',
  status_done: 'Done',
  status_paid: 'Paid',
  status_processing: 'Processing',
  status_pending: 'Pending',
  status_ready: 'Ready',
  status_recollect: 'Recollect',
  status_rejected: 'Rejected',
  status_submitted: 'Submitted',
  status_technical: 'Technical',
  status_medical: 'Medical',
  select_date: 'Select date',
  should_start_with: 'Should start with {{start}}',
  submit: 'Submit',
  swab_id: 'Swab ID',

  // T
  terms_and_conditions: 'Terms & Conditions',
  to: 'To',

  // U
  update: 'Update',
  user_profile: 'User Profile',
  updateTestResult: 'Save test result',

  // V
  view_all: 'View all',
  view_details: 'View details',

  // W

  // X

  // Y
  yes: 'Yes',

  // Z

  // MAIN PAGE
  slogan: 'Unlock Seamless Lab Management with',
  slogan_bold: 'Labby',
  slogan_description: ' Streamline your lab operations, enhance patient care and elevate your practice to new summits',
  contact_us: 'Contact Us',
  home: 'Home',
  pricing: 'Pricing',
  faq: 'FAQ',
  login: 'Login',
  main_section_1_title: 'Lab Control Dashboard',
  main_section_1_description: 'Experience unparalleled control with Labby\'s intuitive dashboard. Real-time analytics and key performance indicators are readily accessible, enhancing efficiency in laboratory management. Experience the ease of managing appointments, patient records, and billing in one central hub',
  main_section_1_cta: 'Manage Your Patients',
  main_section_2_title: 'Inventory Management',
  main_section_2_description: 'Master inventory control with Labby. Efficient management of supplies, accurate forecasting, and waste minimization ensure smooth laboratory operations',
  main_section_2_cta: 'Control Your Lab Operations',
  main_section_3_title: 'Integrated Testing and Sample Management with Analyzer Interfacing',
  main_section_3_description: 'Labby redefines laboratory operations by integrating routine testing, molecular testing, sample management, and analyzer interfacing. It supports a wide array of tests for quick, accurate results, specializes in detailed molecular testing, and streamlines sample tracking from collection to analysis. The system also offers seamless integration with various analyzers, automating data transfer and enhancing workflow synchronization',
  main_section_3_cta: 'Explore Integrated Features',
  main_section_4_title: 'Customizable Lab Packages',
  main_section_4_description: 'Engage customers with Labby\'s customizable lab packages for special occasions like National Day and Mother\'s Day, creating unique and relevant promotions.',
  main_section_4_cta: 'Customize Your Campaigns',
  main_section_5_title: 'Enhanced Patient Engagement and Clinic Integration',
  main_section_5_description: 'Labby’s patient portal improves patient engagement by providing easy access to test results, appointment scheduling, and direct communication. Simultaneously, it simplifies the integration of clinics and collection centers into your network, enhancing data flow and collaboration.',
  main_section_5_cta: 'Engage with Your Patients',
  main_section_6_title: 'Insightful Reporting',
  main_section_6_description: 'Gain valuable insights easily with Labby’s robust reporting tools. Detailed analyses of performance metrics and financial data are available to empower informed, strategic decisions.',
  main_section_6_cta: 'Make Strategic decisions',
  faqs: 'FAQ(s)',
  faq_1_title: 'Why is Labby the best tool?',
  faq_1_description: 'Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor',
  faq_2_title: 'When did Labby was founded?',
  faq_2_description: 'Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor',
  trusted_clients: '6.3k Trusted Clients',
  get_in: 'Get in',
  touch: 'Touch',
  get_in_touch_description: 'Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo molestie vel, ornare non id blandit netus.',
  full_name: 'Full Name',
  address: 'Address',
  footer_description: 'Streamline your lab operations, enhance patient care, and elevate your practice to new summits',
  support: 'Support',
  copy_right: 'Copyright © 2024 Labby',
  all_rights_reserved: 'All rights reserved',
  privacy_policy: 'Privacy Policy',
  send: 'Send',
  contact_us_title: 'Contact us, will be in touch soon',
  contact_us_description: 'Whether you have questions about our services, need help scheduling an appointment, or simply want to share your feedback, we\'re here to listen',
  your_location: 'Your Location',
  lab_name: 'Lab Name',
  your_message: 'Your Message',
  application_sent: 'Application sent!',
  pricingTitle: 'Pricing',
  package_labby: 'Labby',
  package_labby_price: 'SAR 4000',
  pricingDescription: 'Choose the perfect plan for your business needs',
  feature: 'Feature',
  packageFeature1: 'Custom URL link',
  packageFeature2: '3000 Patient Management',
  packageFeature3: 'Reservations',
  packageFeature4: 'Invoices',
  packageFeature5: 'Medical and Financial Reports',
  packageFeature6: '10 Users',
  packageFeature7: '3 Analyzers',
  packageFeature23: '5 Clinic and Collection centers',
  packageFeature8: 'Test Menu managment',
  packageFeature9: 'Manage Tests priority',
  packageFeature10: 'Hesn integration',
  packageFeature11: 'SMS notifiaction',
  packageFeature12: 'B2B billing',
  packageFeature13: 'Result Validation',
  packageFeature14: 'PCR batches',
  package_labby_plus: 'Labby +',
  package_labby_plus_price: 'SAR 8000',
  package_labby_plus_feature_description: 'Everything included in labby, plus...',
  packageFeature15: '20 Users',
  packageFeature16: 'Inventory management',
  packageFeature17: '5 Analyzers',
  packageFeature18: '15 Clinic and Collection centers',
  packageFeature19: 'Patient portal (patient can book test by himself)',
  packageFeature20: 'Email notifications',
  packageFeature21: 'Package Management',
  packageFeature22: 'Customize landing page',
  packageFeature24: '5000 Patient Management',
  package_infinity: 'Labby Infinity',
  package_infinity_description: 'Fully customizable packages, With no limits on all system features and integrations.',
  subscribe: 'Subscribe',
  subscribe_contact: 'Contact us',
  month: 'Month',
  adds_on: 'Adds-ons',
  adds_on_description: 'Customize your plan with optional add-ons',
  main_page: 'Home',
  enter_your_slug: 'Enter your Lab Slug(lab name)',
  enter_your_slug_description: 'Login to your lab dashboard with single sign-on',
  slug_name: 'Slug Name(lab name)',
  continue: 'Continue',
  arabic_lang: 'اللغة العربية',
  english_lang: 'English',
  additional_user_management: 'Additional User Management',
  additional_analyzer_integration: 'Additional Analyzer integration',
  patient_portal: 'Patient Portal',
  clinic_and_cc: 'Clinic and Collection Center',
  email_notification: 'Email Notification',
  unlimited: 'Unlimited',
  free: 'Free',
  price_per_user: 'SAR 100/user',
  price_per_machine: 'SAR 4000/machine',
  sar_amount: 'SAR {{_}}',
};

export default Common;

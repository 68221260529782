import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { downloadCSV, getListForSelect, printPDF } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';
import { downloadReport } from 'redux/inventory/actionCreators';

import { Grid } from '@mui/material';
import { Button, Flex, Spacer, Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';
import CreateStock from './updateOrCreate/CreateStock';

const StocksFilters = ({ generateOtherFilters, generateSelectFilters }) => {
  const dispatch = useDispatch();
  const { locations, options, downloading } = useSelector((state) => state.inventory);

  const [selectFilterConfigs, setSelectFilterConfigs] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedExtension, setSelectedExtension] = useState('');

  const filterButton = (
    <Grid item xs={3} lg={1}>
      <SelectFilter
        selectFilterConfigs={selectFilterConfigs}
        onApply={generateSelectFilters}
        onCancel={generateSelectFilters}
      />
    </Grid>
  );

  const handleDownloadCSV = () => {
    setSelectedExtension('csv');
    dispatch(downloadReport('csv'))
      .then(resp => resp.value.data)
      .then(blob => { downloadCSV(blob, 'stocks'); setSelectedExtension(''); });
  };

  const handleDownloadPDF = () => {
    setSelectedExtension('pdf');
    dispatch(downloadReport('pdf'))
      .then(resp => resp.value.data)
      .then(blob => { printPDF(blob); setSelectedExtension(''); });
  };

  useEffect(() => {
    setSelectFilterConfigs([
      {
        key: 'status',
        options: statusOptions,
        placeholderKey: 'status',
      },
      {
        key: 'location_id',
        options: locationOptions,
        placeholderKey: 'location',
      }
    ]);
  }, [locationOptions, statusOptions]);

  useEffect(() => {
    setLocationOptions(getListForSelect(locations));
  }, [locations]);

  useEffect(() => {
    const data = valueGetter(options, 'status') || [];
    setStatusOptions(getListForSelect(data));
  }, [options]);

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={isBrowser ? 3 : 2}
      alignItems="center"
    >
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('stocks_table_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t('stocks_table_subtitle')}
        </Text>
      </Grid>
      {isMobile && filterButton}
      <Grid item xs={12} lg={4}>
        <SearchInput
          placeholder={i18n.t('stocks_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && filterButton}
      <Grid item xs={12} lg={4}>
        <Flex>
          <Button
            fitContent
            label={i18n.t(downloading && selectedExtension === 'csv' ? 'downloading' : 'download_csv')}
            disabled={downloading && selectedExtension === 'csv'}
            onClick={handleDownloadCSV}
          />
          <Spacer width={8} />
          <Button
            fitContent
            label={i18n.t(downloading && selectedExtension === 'pdf' ? 'downloading' : 'download_pdf')}
            disabled={downloading && selectedExtension === 'pdf'}
            onClick={handleDownloadPDF}
          />
          <Spacer width={8} />
          <CreateStock />
        </Flex>
      </Grid>
    </Grid>
  );
};

StocksFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
};

export default StocksFilters;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import {
  getOptionsForDropdown,
  getStatusColor,
} from 'utils/labUtils';
import {
  cancelMasterOrder,
  updateMasterOrderDetails,
} from 'redux/lab/actionCreators';
import { MASTER_RESERVATION_STATUS, GENDER } from 'models';

import { Grid } from '@mui/material';
import { Button, Flex, Spacer, Switch, Tag, Text, TextInput } from 'atoms';
import { FieldInfoCardList, SectionTitle } from 'templates';
import MasterOrderStatusTransition from 'components/global/MasterOrderStatusTransition';

export const MasterOrderInfoContent = ({ reload }) => {
  const dispatch = useDispatch();
  const { change } = useForm();
  const { reservationInfo } = useSelector((state) => state.lab);
  const { values } = useFormState();
  const { genders, nationalities, directorates, isolotion_places } =
    useSelector((state) => state.patient);

  const [editing, setEditing] = React.useState(false);
  const [isFemale, setIsFemale] = useState(false);
  const [isPregnant, setIsPregnant] = React.useState(false);
  const [status, setStatus] = useState('');
  const [comments, setComments] = useState();

  const onEditClick = () => {
    if (editing) {
      const payload = {
        patient: values.patient_detail,
        user: {
          email: values.user.email,
          mobile: values.user.mobile,
        },
      };
      dispatch(updateMasterOrderDetails(values.id, payload)).then(() =>
        setEditing(false),
      );
    } else {
      setEditing(true);
    }
  };

  const saveComments = () => {
    dispatch(updateMasterOrderDetails(values.id, { note: comments }));
  };

  const handleMenuClick = ({ option, reason, refund }) => {
    if (option === MASTER_RESERVATION_STATUS.CANCELED) {
      const payload = { reason, refund };
      dispatch(cancelMasterOrder(reservationInfo.id, payload)).then(() =>
        reload(),
      );
    } else {
      const payload = { master_order: { status: option } };
      dispatch(updateMasterOrderDetails(reservationInfo.id, payload)).then(() =>
        reload(),
      );
    }
  };

  const items = [
    {
      name: 'patient_detail.name',
      label: i18n.t('patient_name'),
      placeholder: i18n.t('patient_name'),
      disabled: !editing,
    },
    {
      name: 'user.mobile',
      label: i18n.t('phone'),
      placeholder: i18n.t('phone'),
      disabled: !editing,
    },
    {
      custom: (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('status')}
          </Text>
          <Flex itemsCenter>
            <Tag text={status} color={getStatusColor(status)} />
            <MasterOrderStatusTransition
              status={status}
              onMenuClick={handleMenuClick}
            />
          </Flex>
        </Grid>
      ),
    },
    {
      name: 'patient_detail.personal_id',
      label: i18n.t('personal_id'),
      placeholder: i18n.t('personal_id'),
      disabled: !editing,
    },
    {
      name: 'patient_detail.date_of_birth',
      label: i18n.t('date_of_birth'),
      placeholder: i18n.t('date_of_birth'),
      disabled: !editing,
      as: 'date',
    },
    {
      name: 'user.email',
      label: i18n.t('email'),
      placeholder: i18n.t('email'),
      disabled: !editing,
    },
    {
      name: 'patient_detail.passport_number',
      label: i18n.t('passport_number'),
      placeholder: i18n.t('passport_number'),
      disabled: !editing,
    },
    {
      name: 'reservation_id',
      label: i18n.t('reservation_id'),
      placeholder: i18n.t('reservation_id'),
      disabled: true,
    },
    {
      name: 'sample_id',
      label: i18n.t('sample_id'),
      placeholder: i18n.t('sample_id'),
      disabled: true,
    },
    {
      name: 'appointment.time',
      label: i18n.t('date'),
      placeholder: i18n.t('date'),
      disabled: !editing,
      as: 'date',
    },
    {
      name: 'appointment.collection_method',
      label: i18n.t('collection_method'),
      placeholder: i18n.t('collection_method'),
      disabled: true,
    },
    {
      name: 'patient_detail.isolotion_place_id',
      label: i18n.t('isolation_place'),
      placeholder: i18n.t('isolation_place'),
      disabled: !editing,
      options: getOptionsForDropdown(isolotion_places, 'id', 'name_i18n'),
      as: 'dropdown',
    },
    {
      name: 'patient_detail.nationality_id',
      label: i18n.t('nationality'),
      placeholder: i18n.t('nationality'),
      disabled: !editing,
      options: getOptionsForDropdown(nationalities, 'id', 'name_i18n'),
      as: 'dropdown',
    },
    {
      name: 'patient_detail.directorate_id',
      label: i18n.t('directorate'),
      placeholder: i18n.t('directorate'),
      disabled: !editing,
      options: getOptionsForDropdown(directorates, 'id', 'name_i18n'),
      as: 'dropdown',
    },
    {
      name: 'patient_detail.gender',
      label: i18n.t('gender'),
      placeholder: i18n.t('gender'),
      disabled: !editing,
      options: getOptionsForDropdown(genders),
      action: {
        onChange: (value) => {setIsFemale(value === GENDER.FEMALE);}
      },
      as: 'dropdown',
    },
    isFemale && {
      custom: (
        <Flex flexCol justifyCenter itemsCenter style={{ padding: '18px' }}>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('is_pregnant')}
          </Text>
          <Spacer height={16} />
          <Switch
            name="is_pregnant"
            checked={isPregnant}
            onChange={(value) => {
              change('patient_detail.is_pregnant', value);
              setIsPregnant(value);
            }}
            disabled={!editing}
          />
        </Flex>
      ),
    },
    isPregnant && {
      name: 'patient_detail.pregnancy_week',
      label: i18n.t('pregnancy_week'),
      placeholder: i18n.t('pregnancy_week'),
      disabled: !editing,
    },
    {
      custom: (
        <Grid item xs={12}>
          <Flex flexCol justifyCenter>
            <Flex itemsCenter>
              <Text
                typography="caption12"
                as="span"
                weight="bold"
                color="var(--brand-text-gray-light)"
                textCase="uppercase"
              >
                {i18n.t('comments')}
              </Text>
              <Spacer width={8} />
              <Button
                size="small"
                variant="secondary"
                fitContent
                label={i18n.t('save')}
                disabled={comments === valueGetter(reservationInfo, 'note')}
                onClick={saveComments}
              />
            </Flex>
            <Spacer height={8} />
            <TextInput
              name="note"
              rows={2}
              as="textarea"
              value={comments}
              placeholder={i18n.t('comments')}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </Flex>
        </Grid>
      )
    },
  ].filter(Boolean);

  useEffect(() => {
    setIsFemale(valueGetter(reservationInfo, 'patient_detail.gender') === 'female');
    setIsPregnant(
      valueGetter(reservationInfo, 'patient_detail.is_pregnant') || false,
    );
    setStatus(valueGetter(reservationInfo, 'status'));
    setComments(valueGetter(reservationInfo, 'note'));
  }, [reservationInfo]);

  return (
    <>
      <SectionTitle
        titleKey={i18n.t('order_info_title')}
        showEdit
        editKey={i18n.t(editing ? 'save' : 'edit_info')}
        onEdit={onEditClick}
      />
      <Grid
        container
        justifyContent="flex-start"
        spacing="16px"
        sx={{ padding: '16px' }}
      >
        <FieldInfoCardList
          list={items}
          girdSizes={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 3 }}
        />
      </Grid>
    </>
  );
};

MasterOrderInfoContent.propTypes = {
  reload: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';

import { ReactComponent as MenuIcon } from 'assets/icons/common/more-vertical.svg';
import { Text } from 'atoms';

import i18n from 'translation/i18n';
import '../users/styles.scss';

export const MENU_OPTIONS = {
  ACTIVATE: 'active',
  DEACTIVATE: 'inactive',
};

export const TestsMenu = ({ onMenuClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (option) => {
    onMenuClick(option);
    handleClose();
  };

  return (
    <>
      <IconButton
        id="user-row-menu"
        disableRipple
        aria-haspopup="true"
        aria-controls={open ? 'user-row-menu-popover' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="user-row-menu-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'user-row-menu' }}
      >
        <MenuItem onClick={() => handleMenuClick(MENU_OPTIONS.ACTIVATE)}>
          <Text typography="paragraph14" weight="bold">
            {i18n.t('activate')}
          </Text>
        </MenuItem>
        <MenuItem onClick={() => handleMenuClick(MENU_OPTIONS.DEACTIVATE)}>
          <Text typography="paragraph14" weight="bold">
            {i18n.t('deactivate')}
          </Text>
        </MenuItem>
      </Menu>
    </>
  );
};

TestsMenu.defaultProps = {
  onMenuClick: () => {},
};

TestsMenu.propTypes = {
  onMenuClick: PropTypes.func,
};

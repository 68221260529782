import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import useHandelPagination from 'hooks/useHandelPagination';
import PropTypes from 'prop-types';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { getB2BInvoices } from 'redux/billing/actionCreators';
import { dateQueryFormatToDate, dateToQueryFormat } from 'utils/date';
import { useURLQuery } from 'hooks/useURLQuery';
import InvoiceFilters from './InvoiceFilters';
import InvoiceSummary from './InvoiceSummary';
import InvoiceTable from './InvoiceTable';

const InvoiceContent = ({ client }) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [buyerId, setBuyerId] = useState(null);
  const [filters, setFilters] = useState({});
  const { query, setQuery } = useURLQuery();

  // * removing 's' from buyer types options type
  const buyer_type = client.replace('-', '_').substring(0, client.length - 1);

  const loadInvoices = (param = {}) => {
    const params = {
      buyer_type,
      ...param,
    };
    setFilters(params);
    return dispatch(getB2BInvoices(params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      buyer_id: buyerId,
      from_date: filter.from_date ? dateToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateToQueryFormat(filter.to_date) : null,
    };
    loadInvoices(params);
    setQuery(params);
    setSelectedIndex(-1);
  };

  const handleReload = () => {
    loadInvoices(filters);
    setSelectedIndex(-1);
  };

  const handlePagination = useHandelPagination({
    filters,
    onPageChange: loadInvoices,
  });

  useEffect(() => {
    const params = {
      search: query?.search,
      buyer_id: query?.buyer_id,
      from_date: query?.from_date
        ? dateQueryFormatToDate(query?.from_date)
        : null,
      to_date: query?.to_date ? dateQueryFormatToDate(query?.to_date) : null,
    };
    loadInvoices(params).then(({ action }) => {
      setBuyerId(action.payload.data?.b2b_invoices[0]?.buyer_id);
    });
  }, [buyer_type]);

  const invoiceContent = (
    <>
      <ListFilters
        Wrapped={InvoiceFilters}
        client={client}
        onChange={handleFilterChange}
      />
      <InvoiceTable
        onRowClick={(index) => setSelectedIndex(index)}
        onPagination={handlePagination}
      />
    </>
  );

  return (
    <Grid
      container
      spacing={isBrowser ? 4 : 0}
      px={isBrowser ? 4 : 0}
      paddingTop={0}
    >
      <Grid item xs={12} lg={8}>
        {isMobile ? invoiceContent : <Card>{invoiceContent}</Card>}
      </Grid>
      <Grid item xs={12} lg={4} mt={isMobile ? 2 : 0} mx={isMobile ? 2 : 0}>
        <InvoiceSummary index={selectedIndex} reload={handleReload} />
      </Grid>
    </Grid>
  );
};

InvoiceContent.propTypes = {
  client: PropTypes.string,
};

export default InvoiceContent;

// Import translations
import Auth from './modules/ar/auth';
import Lab from './modules/ar/lab';
import Common from './modules/ar/common';
import Reservation from './modules/ar/reservation';
import Tests from './modules/ar/tests';
import Overview from './modules/ar/overview';
import Clients from './modules/ar/clients';
import Billing from './modules/ar/billing';
import LandingPage from './modules/ar/landing-page';

const AR = {
  ...Auth,
  ...Lab,
  ...Common,
  ...Reservation,
  ...Tests,
  ...Overview,
  ...Clients,
  ...Billing,
  ...LandingPage
};

export default AR;

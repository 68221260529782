const { useEffect, useState } = require('react');

const useTablePagination = ({ pagination, dataList }) => {
  const [rows, setRows] = useState([]);
  const dataIsFetched = Array.isArray(dataList) && dataList.length >= 0;

  useEffect(() => {
    if (!dataIsFetched) return;
    if (!pagination?.per_page) {
      // eslint-disable-next-line no-console
      console.error(
        'useTablePagination: pagination object not set, please provide pagination object.',
      );
      setRows(dataList); // fallback in case there is no pagination
      return;
    }

    // when datagrid pagination mode is client
    // const { current_page = 0, per_page = 0, previous_page = 0 } = pagination;
    // const data = [...rows];
    // if (rows.length <= current_page * per_page) {
    //   data.push(...dataList);
    // } else {
    //   data.splice(previous_page, per_page, ...dataList);
    // }
    // setRows(data);

    // datagrid pagination mode "server"
    setRows(dataList);
  }, [dataList]);

  return rows;
};

export default useTablePagination;

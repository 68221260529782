import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import {Modal, Spacer, Text, Flex} from 'atoms';
import { ModalHeader , ModalContent } from 'templates';
import i18n from 'translation/i18n';
import {PaymentFields} from 'components/public';

const AddCardModal = ({open, onClose, initialValues, onSubmit}) => {
  return (
    <Modal
      isOpen={open}
      variant="centerDialog"
      onClose={onClose}
      width={380}
    >
      <Spacer height={48}/>
      <ModalHeader title={i18n.t('add_card')} titleVariant="headline" center/>
      <Spacer height={9}/>
      <Flex justifyCenter style={{width: '100%'}}>
        <Text typography="paragraph14" color="var(--brand-text-gray-light)">
          {i18n.t('add_your_card_details')}
        </Text>
      </Flex>
      <Spacer height={48}/>
      <Form onSubmit={onSubmit} style={{ height: '100%'}} initialValues={initialValues}>
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <ModalContent style={{paddingRight: '32px', paddingLeft: '32px'}}>
              <PaymentFields buttonText={i18n.t('submit')} datePlacement="top"/>
            </ModalContent>
          </form>
        )}
      </Form>
    </Modal>

  );
};

AddCardModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape(),
};

export default AddCardModal;
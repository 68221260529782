import React from 'react';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {Spacer, Button} from 'atoms';
import {ModalContent} from 'templates';
import {TextInputField} from 'components/global/final-form';

const ChangePasswordFields = () => {
  const {submitting} = useSelector(state => state.profile);
  return (
    <ModalContent>
      <TextInputField
        name="current_password"
        label={i18n.t('current_password')}
        placeholder={i18n.t('password')}
        type="password"
        hideShowPasswordOption
      />
      <Spacer height={20}/>
      <TextInputField 
        name="password"
        label={i18n.t('new_password')}
        placeholder={i18n.t('password')}
        type="password"
        hideShowPasswordOption
      />
      <Spacer height={20}/>
      <TextInputField
        name="password_confirmation"
        label={i18n.t('re_enter_new_password')}
        placeholder={i18n.t('password')}
        type="password"
        hideShowPasswordOption
      />
      <Spacer height={48}/>
      <Button label={i18n.t('change_password')} type="submit" disabled={submitting}/>
    </ModalContent>
  );
};

export default ChangePasswordFields;

import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import {
  composeValidators,
  shouldStartWith,
  maxLength,
  minLength,
  required,
  enNumber,
  email,
} from 'utils/FormValidations';

import { useForm, useFormState } from 'react-final-form';
import { Spacer } from 'atoms';
import { ModalContent, CustomImageUploader } from 'templates';
import { SelectField, TextInputField } from 'components/global/final-form';
import { ReactComponent as DefaultLabIcon } from 'assets/icons/login-flow/lab-default.svg';

const LabStep1 = () => {
  const {change} = useForm();
  const {plans} = useSelector((state) => state.admin);
  const {values} = useFormState();
  const [labLogo, setLabLogo] = React.useState(valueGetter(values, 'logo.data'));

  const onImageChange = (data) => {
    change('logo.data', data.file);
    change('logo.filename', data.file_file_name);
    change('logo.content_type', data.type);
    return setLabLogo(data.file);
  };

  return(
    <ModalContent>
      <Spacer height={4} />
      <CustomImageUploader
        imgSrc={labLogo || DefaultLabIcon}
        altLabel="LAB_LOGO"
        onDone={(data) => onImageChange(data)}
        actionLabel={i18n.t('change_logo')}
        containerStyle={{width: '50%', border: 'none'}}
        uploaderType="avatar"
        flexCol={false}
        bordered={false}
      />
      <Spacer height={32} />
      <SelectField
        name="subscription"
        label={i18n.t('subscription_plan')}
        placeholder={i18n.t('subscription_plan')}
        options={plans.map((p) => ({ label: p.name_text, value: p.id }))}
        validate={required}
      />
      <Spacer height={32} />
      <TextInputField
        name="service_provider.name_en"
        placeholder={i18n.t('name')}
        label={i18n.t('name_en')}
        validate={required}
      />
      <Spacer height={20} />
      <TextInputField
        name="service_provider.name_ar"
        placeholder={i18n.t('name')}
        label={i18n.t('name_ar')}
      />
      <Spacer height={20} />
      <TextInputField
        name="service_provider.slug"
        placeholder={i18n.t('slug')}
        label={i18n.t('slug')}
        validate={required}
      />
      <Spacer height={20} />
      <TextInputField
        name="user.email"
        placeholder={i18n.t('email')}
        label={i18n.t('email_address')}
        validate={composeValidators(email)}
      />
      <Spacer height={20} />
      <TextInputField
        name="user.mobile"
        placeholder={i18n.t('phone')}
        label={i18n.t('phone_number')}
        validate={
          composeValidators(required, enNumber, shouldStartWith(5), maxLength(9), minLength(9))
        }
        maxLength={9}
      />
    </ModalContent>
  );
};

export default LabStep1;
import React from 'react';
import { useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { isLabUser } from 'utils/labUtils';
import { usePathname } from 'hooks';
import { DataTable } from 'atoms';

const PackageTestsTable = () => {
  const { onePackage, fetching } = useSelector((state) => state.packages);
  const showBatch = isLabUser() && usePathname().includes('orders');

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('test_name'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'batch_number',
      headerName: i18n.t('batch'),
      minWidth: 100,
      flex: 1,
      hide: !showBatch,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ value }) =>
        value ? `${i18n.t('batch', { batchNumber: value })}` : '',
    },
    {
      field: 'category_type',
      headerName: i18n.t('category'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'specimen_type',
      headerName: i18n.t('type_of_specimen'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={!fetching && onePackage ? onePackage.services : []}
      loading={fetching}
    />
  );
};

export default PackageTestsTable;

import React from 'react';

import { generateOptionsFromEnum } from 'utils/labUtils';

import LabHeader from 'components/global/LabHeader';
import { UserList } from 'components/users';
import { Roles } from 'components/roles';

const HEADER_OPTIONS = {
  USERS: 'users',
  ROLES: 'roles',
};

export const Users = () => {
  const [tab, setTab] = React.useState(HEADER_OPTIONS.USERS);

  return (
    <>
      <LabHeader
        title="user_management"
        selectedOption={tab}
        filterOptions={generateOptionsFromEnum(HEADER_OPTIONS)}
        onFilterChange={setTab}
      />
      {tab === HEADER_OPTIONS.USERS && <UserList/>}
      {tab === HEADER_OPTIONS.ROLES && <Roles/>}
    </>
  );
};

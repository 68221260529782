import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Batches } from 'container/labs/Batches';
import { Orders } from 'container/labs/Orders';
import { OrderDetails } from 'container/labs/Orders/OrderDetails';
import ReservationTestInfoForm from 'components/reservation/masterReservation/ReservationTests/ReservationTestInfo';
import { BatchOrderDetails } from 'components/orders/lab/masterOrders/masterOrderTests/batch';
import { ReservationPackageInfoForm } from 'components/reservation/masterReservation/ReservationTests/ReservationTestInfo/ReservationPackageInfoForm';

const OrdersRoutes = () => (
  <Switch>
    <Route path="/pcr-batches/:id" exact component={BatchOrderDetails} />
    <Route path="/pcr-batches" exact component={Batches} />

    <Route
      path="/orders/:id/tests/:test_id"
      exact
      component={ReservationTestInfoForm}
    />
    <Route
      path="/orders/:id/packages/tests/:test_id"
      exact
      component={ReservationTestInfoForm}
    />
    <Route
      path="/orders/:id/packages/:package_id"
      exact
      component={ReservationPackageInfoForm}
    />
    <Route path="/orders/:order_id" exact component={OrderDetails} />
    <Route path="/orders" exact component={Orders} />

    <Redirect to="/orders" />
  </Switch>
);

export default OrdersRoutes;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { SUBSCRIPTION_STATUS } from 'models';
import { valueGetter } from 'utils/uiHelpers';

import { Menu, MenuItem } from '@mui/material';
import { Button } from 'atoms';
import { ReactComponent as MenuIcon } from 'assets/icons/common/more-vertical.svg';
import styles from './labs.module.css';
import SubscriptionRenewModal from './SubscriptionRenewModal';
import SubscriptionConfigModal from './SubscriptionConfigModal';
import SubscriptionInvoicesModal from './SubscriptionInvoicesModal';
import SubscriptionChangeModal from './SubscriptionChangeModal';

const LabSubscriptionActions = ({serviceProviderId, data}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [toggleShowInvoicesModal, setToggleShowInvoicesModal] = useState(false);
  const [toggleShowRenewModal, setToggleShowRenewModal] = useState(false);
  const [toggleShowConfigModal, setToggleShowConfigModal] = useState(false);
  const [toggleShowChangeModal, setToggleShowChangeModal] = useState(false);
  const open = Boolean(anchorEl);
  const showRenewButton = [
    SUBSCRIPTION_STATUS.INACTIVE,
    SUBSCRIPTION_STATUS.EXPIRED
  ].includes(data.status);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (anchorEl) {
      handleClose();
    }
  }, [toggleShowRenewModal, toggleShowConfigModal, toggleShowInvoicesModal, toggleShowChangeModal]);

  return (
    <>
      <div>
        <MenuIcon
          id="more-pic"
          aria-label="more"
          aria-controls={open ? 'more-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{padding: '12px 36px'}}
        />
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{'aria-labelledby': 'more-pic'}}
        >
          {serviceProviderId && (
            <MenuItem className={styles.hideItemActions}>
              <Button
                size="small"
                label={i18n.t('invoices')}
                variant="secondary"
                onClick={() => setToggleShowInvoicesModal(true)}
              />
            </MenuItem>
          )}
          {showRenewButton && (
            <MenuItem className={styles.hideItemActions}>
              <Button
                size="small"
                label={i18n.t('renew')}
                variant="secondary"
                onClick={() => setToggleShowRenewModal(true)}
              />
            </MenuItem>
          )}
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t('update_config')}
              variant="secondary"
              onClick={() => setToggleShowConfigModal(true)}
            />
          </MenuItem>
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t('update_subscription_plan')}
              variant="secondary"
              onClick={() => setToggleShowChangeModal(true)}
            />
          </MenuItem>
        </Menu>
      </div>
      {toggleShowInvoicesModal && (
        <SubscriptionInvoicesModal
          subscriptionId={valueGetter(data, 'id')}
          onClose={() => setToggleShowInvoicesModal(false)}
        />
      )}
      {toggleShowRenewModal && (
        <SubscriptionRenewModal
          showModal={toggleShowRenewModal}
          onClose={() => setToggleShowRenewModal(false)}
        />
      )}
      {toggleShowConfigModal && (
        <SubscriptionConfigModal
          showModal={toggleShowConfigModal}
          serviceProviderId={serviceProviderId}
          subscriptionId={data.id}
          analyzer_count={data.analyzer_count}
          clients_count={data.clients_count}
          patient_portal={data.patient_portal}
          users_count={data.users_count}
          onClose={() => setToggleShowConfigModal(false)}
        />
      )}
      {toggleShowChangeModal && (
        <SubscriptionChangeModal
          serviceProviderId={serviceProviderId}
          subscriptionId={data.id}
          currentPlanId={data.subscription.id}
          onClose={() => setToggleShowChangeModal(false)}
        />
      )}
    </>
  );
};

LabSubscriptionActions.propTypes = {
  serviceProviderId: PropTypes.string,
  data: PropTypes.shape(),
};

export default LabSubscriptionActions;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { createStock, getStockDetails, getStocks, updateStock } from 'redux/inventory/actionCreators';

import { Form } from 'react-final-form';
import { Modal } from 'atoms';
import { ModalHeader } from 'templates';
import StepsTemplate from './StepsTemplate';

const StockModal = ({isEdit, reloadList, showModal, onClose}) => {
  const dispatch = useDispatch();
  const { stock } = useSelector(state => state.inventory);

  const [activeStep, setActiveStep] = React.useState(1);
  const [initial, setInitial] = React.useState({});

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onReload = () => {
    dispatch(reloadList ? getStocks() : getStockDetails(stock.id));
    setActiveStep(1);
    onClose();
  };

  const onSubmit = (values) => {
    if (activeStep <= 3) {
      setActiveStep(activeStep + 1);
      return;
    }
    const payload = {
      stock: {
        ...values,
        total_amount: Number(values.total_amount),
        original_quantity: Number(values.original_quantity),
      }
    };
    if (isEdit) {
      dispatch(updateStock(stock.id, payload)).then(onReload);
    } else {
      dispatch(createStock(payload)).then(onReload);
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      setInitial({
        ...stock,
        location_id: valueGetter(stock, 'location.id')
      });
    }
  }, [stock, isEdit]);
  
  return (
    <Modal
      header={
        <ModalHeader
          title={i18n.t(isEdit ? 'edit_stock' : 'create_stock')}
          onBack={activeStep > 1 ? onBack : null}
        />
      }
      isOpen={showModal}
      width={350}
      disableCloseOut
      onClose={() => onClose()}
    >
      <Form style={{maxHeight: 'calc(100vh - 50rem)', overflowY: 'auto'}} initialValues={initial} onSubmit={(values) => {onSubmit(values);}}>
        {({handleSubmit}) => (
          <form onSubmit={(values) => handleSubmit(values)}>
            <StepsTemplate isEdit={isEdit} activeStep={activeStep}/>
          </form>
        )}
      </Form>
    </Modal>
  );
};

StockModal.defaultProps = {
  isEdit: false,
  reloadList: false,
};

StockModal.propTypes = {
  isEdit: PropTypes.bool,
  reloadList: PropTypes.bool,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StockModal;
import React from 'react';
import {isMobile} from 'react-device-detect';
import clsx from 'clsx';
import {get, last, indexOf, chunk} from 'lodash';

import {ORDER_STATUS} from 'models';
import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {orderTrackerStatus} from 'utils/labUtils';
import {LOCALE} from 'utils/constants';
import {DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, format} from 'utils/date';

import {Box} from '@mui/material';
import {Flex, Spacer, Text} from 'atoms';
import {useSelector} from 'react-redux';
import './styles.scss';

const ORDER_STATUS_ORDER = [
  ORDER_STATUS.CONFIRM,
  ORDER_STATUS.COLLECT,
  ORDER_STATUS.PROCESS,
  ORDER_STATUS.SUBMITTED,
  ORDER_STATUS.TECHNICAL,
  ORDER_STATUS.MEDICAL,
  ORDER_STATUS.READY,
  ORDER_STATUS.CANCEL
];

const OrderTracking = () => {
  const {reservationTestInfo} = useSelector(state => state.lab);
  const logs = get(reservationTestInfo, 'order_status_logs', []);
  const allStatus = logs.map(log => log.status)
    .sort((a, b) => indexOf(ORDER_STATUS_ORDER, a) - indexOf(ORDER_STATUS_ORDER, b));
  const currentStatus = last(allStatus);
  const activeIndex = indexOf(allStatus, currentStatus);
  const locale = localStorage.getItem(LOCALE) || 'en';
  let chunkSize = 6;

  if (isMobile) {
    chunkSize = currentStatus === ORDER_STATUS.CANCEL ? 4 : 3;
  }
  if (![ORDER_STATUS.READY, ORDER_STATUS.CANCEL].includes(currentStatus)) {
    allStatus.push(...orderTrackerStatus(currentStatus));
  }

  return (
    <>
      <Text className="section-title" typography="caption12" weight="bold" textCase="uppercase">
        {i18n.t('order_tracking')}
      </Text>
      <Box mx={isMobile ? 0 : 3} my={isMobile ? 0 : 2} py={2} style={{backgroundColor: 'var(--brand-card-bg)'}}>
        {chunk(allStatus, chunkSize).map((statusChunk, chunkIndex) => (
          <Flex justifyEvenly key={chunkIndex}>
            {statusChunk.map((status, index) => {
              const current = logs.find(log => log.status === status);
              const relativeIndex = chunkIndex * chunkSize + index;

              return (
                <Flex key={`${chunkIndex}-${relativeIndex}`} flexCol itemsCenter flexGrowEqual>
                  <Text typography="caption13" weight="bold">{i18n.t(`status_${status}`)}</Text>
                  <div className="order-tracking">
                    <div className={clsx(
                      'tracker-circle',
                      relativeIndex < activeIndex && 'done',
                      relativeIndex === activeIndex && 'active'
                    )}/>
                    <div className={clsx(
                      'tracker-bar',
                      locale,
                      relativeIndex < activeIndex && 'done',
                      index === statusChunk.length - 1 && 'last',
                    )}/>
                  </div>
                  <Text typography="caption13" weight="bold">{valueGetter(current, 'updated_by.name_i18n')}</Text>
                  <Text typography="caption11" weight="600" color="var(--brand-text-gray)">
                    {format(valueGetter(current, 'updated_at'), DISPLAY_DATE_FORMAT)}
                  </Text>
                  <Text typography="caption11" weight="600" color="var(--brand-text-gray)">
                    {format(valueGetter(current, 'updated_at'), DISPLAY_TIME_FORMAT)}
                  </Text>
                  <Spacer height={16}/>
                </Flex>
              );
            })}
          </Flex>
        ))}
      </Box>
    </>
  );
};

export default OrderTracking;

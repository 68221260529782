import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { OUTSOURCING } from 'models';
import i18n from 'translation/i18n';
import { formattedPrice } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

import { DataTable, Flex, Text } from 'atoms';
import { ArrowLeft } from '@mui/icons-material';

const BillingTable = ({ outsourcing, setSelectedTestIds }) => {
  const [showBatch, setShowBatch] = React.useState(false);
  const { fetching, billings, batches } = useSelector((state) => state.billing);

  const batchColumns = [
    {
      field: 'serial_number',
      headerName: i18n.t('batch'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ value }) => `${i18n.t('batch', { batchNumber: value })}`,
    },
    {
      field: 'order_count',
      headerName: i18n.t('no_of_patients'),
      minWidth: 120,
      flex: 1,
    },
  ];
  const orderColumns = [
    {
      field: 'service_name',
      headerName: i18n.t('test'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'total_payment',
      headerName: i18n.t('total_amount'),
      minWidth: 250,
      flex: 1,
      renderCell: ({ value }) => formattedPrice(value, 'saudi_riyal'),
    },
  ];

  const goBack = () => {
    setShowBatch(true);
  };

  React.useEffect(() => {
    setShowBatch(outsourcing === OUTSOURCING.BATCH);
  }, [outsourcing]);

  React.useEffect(() => {
    setSelectedTestIds([]);
  }, [fetching]);

  return (
    <>
      {outsourcing === OUTSOURCING.SAMPLE && (
        <DataTable
          mobileTableHeight={30}
          columns={orderColumns}
          rows={billings}
          loading={fetching}
          checkboxSelection
          onSelectionModelChange={setSelectedTestIds}
        />
      )}
      {outsourcing === OUTSOURCING.BATCH && showBatch && (
        <DataTable
          mobileTableHeight={30}
          columns={batchColumns}
          rows={batches}
          loading={fetching}
          onRowClick={() => setShowBatch(false)}
        />
      )}
      {outsourcing === OUTSOURCING.BATCH && !showBatch && (
        <>
          <Flex
            onClick={goBack}
            style={{ cursor: 'pointer', marginLeft: '1.5rem' }}
          >
            <ArrowLeft />
            <Text
              typography="caption12"
              weight="bold"
              style={{ marginLeft: '1rem', marginBottom: '0.5rem' }}
            >
              {i18n.t('back')}
            </Text>
          </Flex>
          <DataTable
            columns={orderColumns}
            rows={billings}
            loading={fetching}
          />
        </>
      )}
    </>
  );
};

BillingTable.propTypes = {
  outsourcing: PropTypes.string,
  setSelectedTestIds: PropTypes.func,
};

export default BillingTable;

import React from 'react';

import LabHeader from 'components/global/LabHeader';
import {BatchOrderList} from 'components/orders';

const Batches = () => (
  <>
    <LabHeader title="pcr_batches"/>
    <BatchOrderList/>
  </>
);

export default Batches;

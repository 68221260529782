import React, {useMemo} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import i18n from 'translation/i18n';
import { SOLUTION } from 'models';
import { PLAN_VALUE, PATIENT_SIGNUP_BOOKING } from 'utils/constants';
import { isStepNotValid, isPatient, isPatientSignup } from 'utils/labUtils';
import { testsLoad } from 'redux/tests/actionCreators';
import { setAuthUser, getAuthUser } from 'redux/auth/actionCreators';
import { patientBookTest, patientOptionsLoad } from 'redux/patients/actionCreators';

import { Box } from '@mui/material';
import {Alert} from 'atoms';
import {
  ModalTemplate,
  ModalHeader,
  ModalContent,
} from 'templates';
import NewPatientForm from 'components/reservation/NewPatient/NewPatientForm';
import TestPlan from 'components/public/TestPlan';

const steps = {
  1: [
    { parent: 'patient', child: 'name' },
    { parent: 'patient', child: 'gender' },
  ],
  2: [
    { parent: 'patient', child: 'nationality_id' },
    { parent: 'patient', child: 'date_of_birth' },
    { parent: 'patient', child: 'directorate_id' },
    { parent: 'patient', child: 'isolotion_place_id' },
    { parent: 'patient', child: 'id_type' },
    { parent: 'patient', child: 'occupation' },
    { parent: 'patient', child: 'personal_id' },
  ],
  3: [],
  4: [],
  5: [{ parent: 'appointment', child: 'time' }],
};

const SelfTestBooking = () => {
  const dispatch = useDispatch();
  const { location, push } = useHistory();

  const { state } = location;
  const [activeStep, setActiveStep] = React.useState(1);
  const [showAlert, setShowAlert] = React.useState(false);
  const modalWidth = useMemo(() => {
    if (isMobile) return 'calc(100vw - 2rem)';
    return activeStep === 3 ? 600 : 350;
  }, [activeStep]);

  const onSubmit = (values) => {
    setShowAlert(false);
    const isEmptyField = isStepNotValid(values, activeStep, steps);
    if (isEmptyField) {
      setShowAlert(true);
      return false;
    }
    if (activeStep < 5) {
      return setActiveStep(activeStep + 1);
    }
    return dispatch(patientBookTest(values)).then(({ action }) => {
      const userData = getAuthUser();
      setAuthUser({
        ...userData,
        ...action.payload.data.order.patient_detail.user,
      });
      setActiveStep(1);
      if (isPatientSignup) {
        localStorage.removeItem(PATIENT_SIGNUP_BOOKING);
        push('/tests');
        window.location.reload();
      }
      push('/tests');
    });
  };

  const handleOnPlanChanged = (plan) => {
    if (plan.value === SOLUTION.PCR_SOLUTION) {
      setActiveStep(1);
    }
  };

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  React.useEffect(() => {
    if (state?.step) {
      setActiveStep(state.step);
    }
    if (isPatient() && !localStorage.getItem(PLAN_VALUE)) setActiveStep(1);
    dispatch(testsLoad({ status: 'active' }));
    dispatch(patientOptionsLoad());
  }, []);

  return (
    <>
      {activeStep <= 0 ? (
        <TestPlan onDone={handleOnPlanChanged} />
      ) : (
        <Box m={2} display="flex" justifyContent="center">
          <ModalTemplate noPadding width={modalWidth}>
            <ModalContent>
              <ModalHeader
                title={i18n.t('book_test')}
                onBack={activeStep === 1 ? null : () => onBack()}
              />
            </ModalContent>
            <NewPatientForm onSubmit={onSubmit} activeStep={activeStep} />
          </ModalTemplate>
        </Box>
      )}
      <Alert
        message={i18n.t('all_fields_are_required')}
        variant="danger"
        placement="bottom"
        show={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </>
  );
};

export default SelfTestBooking;

import React from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {ACTIVE_STATUSES} from 'utils/options';
import {isPathIncludes} from 'utils/uiHelpers';

import {Grid} from '@mui/material';
import {Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';
import NewClient from './manageClients/NewClient';

export const ClientFilters = ({client, generateOtherFilters, generateSelectFilters}) => {
  const filterConfigs = [
    {key: 'status', options: ACTIVE_STATUSES, placeholderKey: 'status'},
  ];

  const listTitles = {
    true: 'clinic_list_title',
    false: 'collection_center_list_title',
  };

  const listSubTitles = {
    true: 'clinic_list_subtitle',
    false: 'collection_center_list_subtitle',
  };

  return (
    <Grid container spacing={isMobile ? 2 : 4} py={2} px={isMobile ? 2 : 3} alignItems="center">
      <Grid item xs={8} lg={3}>
        <Text typography="subtitle" weight="bold">{i18n.t(listTitles[isPathIncludes('clinics')])}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t(listSubTitles[isPathIncludes('clinics')])}
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={4}>
          <NewClient client={client}/>
        </Grid>
      )}
      <Grid item xs={9} lg={4}>
        <SearchInput
          placeholder={i18n.t('lab_clients_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && <Grid item lg={2}/>}
      <Grid item xs={3} lg={1}>
        <SelectFilter
          selectFilterConfigs={filterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
      {isBrowser && (
        <Grid item lg={2}>
          <NewClient client={client}/>
        </Grid>
      )}
    </Grid>
  );
};

ClientFilters.propTypes = {
  client: PropTypes.string,
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};
import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {Text, Flex} from 'atoms';
import styles from './templates.module.css';

const CardSectionHeader = ({
  uppercased,
  padding,
  title,
  customTitle,
  style,
  actionName,
  actionText,
  onActionClick,
}) => {
  return (
    <Flex justifyBetween className={styles.cardSectionHeader} style={{padding, ...style}}>
      {customTitle ||
      (
        <Text typography="caption12" weight="bold" textCase={uppercased ? 'uppercase' : 'capitalize'}>
          {title}
        </Text>
      )}
      {actionText && (
        <Text typography="caption12" link weight="bold" onClick={() => onActionClick(actionName)}>
          {actionText || i18n.t('edit')}
        </Text>
      )}
    </Flex>
  );
};

CardSectionHeader.defaultProps = {
  onActionClick: () => {},
};

CardSectionHeader.propTypes = {
  uppercased: PropTypes.bool,
  title: PropTypes.string,
  customTitle: PropTypes.node,
  actionName: PropTypes.string,
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
  style: PropTypes.shape(),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CardSectionHeader;

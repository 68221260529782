import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';

import {Button} from 'atoms';
import {ModalContent} from 'templates';
import Step1 from './Step1';
import Step2 from './Step2';

const StepsTemplate = ({isEdit, activeStep, form, values, onChangePassword}) => {
  const { fetching } = useSelector(state => state.lab);
  const finalStepKey = isEdit ? 'save' : 'send_invitation';

  const step = {
    1: <Step1 isEdit={isEdit} form={form} values={values} onChangePassword={onChangePassword}/>,
    2: <Step2 form={form} values={values} />
  };

  return (
    <>
      <ModalContent>
        {step[activeStep]}
      </ModalContent>
      <ModalContent>
        <Button
          type="submit"
          label={i18n.t(activeStep === 2 ? finalStepKey : 'next')}
          loading={fetching}
        />
      </ModalContent>
    </>
  );
};


StepsTemplate.propTypes = {
  isEdit: PropTypes.bool,
  activeStep: PropTypes.number,
  onChangePassword: PropTypes.func,
  form: PropTypes.shape(),
  values: PropTypes.shape(),
};

export default StepsTemplate;
import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {plans} from 'utils/loginFlow';
import {PATIENT_SIGNUP_BOOKING, PLAN_VALUE} from 'utils/constants';
import {isPatient} from 'utils/labUtils';

import {Flex, Spacer, Text} from 'atoms';
import {ModalContent, ModalTemplate} from 'templates';
import {SOLUTION} from 'models';

import styles from './styles.module.css';

const TestPlan = ({onDone, isAdmin}) => {
  const handlePlanChanged = (plan) => {
    if (plan.value === SOLUTION.PCR_SOLUTION) {
      localStorage.setItem(PLAN_VALUE, plan.value);
      if (!isPatient()) {
        localStorage.setItem(PATIENT_SIGNUP_BOOKING, true);
      }
      onDone(plan);
    }
  };

  return (
    <Flex flexCol justifyCenter itemsCenter>
      <Text typography="headline" weight="bold">
        {isAdmin ? i18n.t('choose_plan') : i18n.t('choose_your_test')}
      </Text>
      <Spacer height={20}/>
      {plans.map((plan, ind) => (
        <div onClick={() => isAdmin ? onDone(plan) : handlePlanChanged(plan)} style={{cursor: 'pointer'}} key={ind}>
          <ModalTemplate gradientBorder={plan.supported}>
            <ModalContent>
              <Flex justifyBetween>
                <Text
                  typography='headline'
                  color='var(--brand-text-black)'
                  weight="bold"
                >
                  {(plan.value === SOLUTION.FULL_SOLUTION && !isAdmin) ? i18n.t('routine_lab_testing') : plan.title}
                </Text>

                {plan.value === SOLUTION.FULL_SOLUTION && (
                  <Text typography='caption12' className={styles.soonBadge}>
                    {i18n.t('soon').toUpperCase()}
                  </Text>
                )}
              </Flex>
              <Spacer height={24}/>
              <Text typography='paragraph16' color="var(--brand-text-gray-light)">
                {plan.description}
              </Text>
            </ModalContent>
          </ModalTemplate>
          <Spacer height={24}/>
        </div>
      ))}
    </Flex>
  );
};

TestPlan.propTypes = {
  onDone: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default TestPlan;
import { Card } from 'atoms';
import useHandelPagination from 'hooks/useHandelPagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans } from 'redux/admin/actionCreators';
import { SubscriptionFilters, SubscriptionTable } from '.';

const SubscriptionList = () => {
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.admin);

  const loadPlans = (params = {}) => {
    dispatch(getPlans(params));
  };

  const handlePagination = useHandelPagination({
    filterParams: {},
    onPageChange: loadPlans,
  });

  React.useEffect(() => {
    loadPlans();
  }, []);

  return (
    <Card styles={{ margin: '2rem' }}>
      <SubscriptionFilters />
      <SubscriptionTable list={plans} handlePagination={handlePagination} />
    </Card>
  );
};

export default SubscriptionList;

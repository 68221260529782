import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { SUBSCRIPTION_STATUS } from 'models';
import {
  subscriptionPatientPortalToggle,
  subscriptionRenew,
  subscriptionUpdateResource,
  subscriptionPlanChange,
  getPlans,
  getServiceProviderSubscriptions
} from 'redux/lab/actionCreators';
import { required } from 'utils/FormValidations';
import { subscriptionOrder } from 'utils/labUtils';

import { Form } from 'react-final-form';
import { Menu, MenuItem } from '@mui/material';
import { Button, Modal, Spacer } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';
import { SelectField, TextInputField } from 'components/global/final-form';
import { ReactComponent as MenuIcon } from 'assets/icons/common/more-vertical.svg';
import styles from './styles.module.css';

const UpdateModal = ({ updateKey, initial, onClose }) => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const payload = {
      count: Number(values[updateKey])
    };
    dispatch(subscriptionUpdateResource(updateKey, payload)).then(onClose);
  };

  return (
    <Modal
      disableCloseOut
      isOpen
      width={350}
      header={<ModalHeader title={i18n.t(`request_${updateKey}_update`)} />}
      onClose={onClose}
    >
     <Form onSubmit={onSubmit} initialValues={{
       analyzer: initial.analyzer_count,
       user: initial.users_count,
       client: initial.clients_count
     }}>
       {({handleSubmit, values}) => (
         <form onSubmit={handleSubmit}>
           <ModalContent>
             <TextInputField
               name={updateKey}
               type="number"
               label={i18n.t(`${updateKey}_count`)}
               validate={required}
             />
           </ModalContent>
           <Spacer
             height={1}
             width="100%"
             style={{ backgroundColor: 'var(--brand-divider-white)' }}
           />
           <ModalContent>
             <Button
               label={i18n.t('submit')}
               onClick={() => onSubmit(values)}
             />
           </ModalContent>
         </form>
       )}
     </Form>
    </Modal>
  );
};

UpdateModal.propTypes = {
  updateKey: PropTypes.string,
  initial: PropTypes.shape(),
  onClose: PropTypes.func,
};

const ChangeModal = ({ currentPlanId, onClose }) => {
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.admin);
  const currentPlanName = React.useMemo(() => {
    const plan = plans.find((p) => p.id === currentPlanId);
    return plan?.name || null;
  }, [plans, currentPlanId]);

  const onSubmit = (values) => {
    const plan = plans.find((p) => p.id === values.subscription);
    const action = subscriptionOrder.indexOf(plan.name) > subscriptionOrder.indexOf(currentPlanName) ? 'upgrade' : 'downgrade';
    const payload = {
      subscription_name: plan.name,
    };
    dispatch(subscriptionPlanChange(action, payload)).then(onClose);
  };

  React.useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <Modal
      disableCloseOut
      isOpen
      width={350}
      header={<ModalHeader title={i18n.t('change_subscription_plan')} />}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit} initialValues={{ subscription: currentPlanId }}>
        {({handleSubmit, values}) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <SelectField
                name="subscription"
                label={i18n.t('subscription_plan')}
                placeholder={i18n.t('subscription_plan')}
                options={plans.map((p) => ({ label: p.name_text, value: p.id }))}
                validate={required}
              />
            </ModalContent>
            <Spacer
              height={1}
              width="100%"
              style={{ backgroundColor: 'var(--brand-divider-white)' }}
            />
            <ModalContent>
              <Button
                label={i18n.t('submit')}
                disabled={values.subscription === currentPlanId}
                onClick={() => onSubmit(values)}
              />
            </ModalContent>
          </form>
        )}
      </Form>
    </Modal>
  );
};

ChangeModal.propTypes = {
  currentPlanId: PropTypes.string,
  onClose: PropTypes.func,
};

const SubscriptionActions = ({data}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const [showChangeModal, setShowChangeModal] = React.useState(false);
  const [updateKey, setUpdateKey] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const showRenewButton = [
    SUBSCRIPTION_STATUS.INACTIVE,
    SUBSCRIPTION_STATUS.EXPIRED
  ].includes(data.status);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refetchSubscriptions = () => {
    dispatch(getServiceProviderSubscriptions());
  };

  const handlePatientPortalToggle = () => {
    handleClose();
    dispatch(
      subscriptionPatientPortalToggle({ activation: !data.patient_portal })
    ).then(refetchSubscriptions);
  };

  const handleResourceUpdate = (key) => {
    handleClose();
    setUpdateKey(key);
    setShowUpdateModal(true);
  };

  const onModalClose = () => {
    setShowUpdateModal(false);
    setShowChangeModal(false);
    setUpdateKey(null);
  };

  const handleRenew = () => {
    handleClose();
    dispatch(subscriptionRenew()).then(refetchSubscriptions);
  };

  return (
    <>
      <div>
        <MenuIcon
          id="more-pic"
          aria-label="more"
          aria-controls={open ? 'more-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{padding: '12px 36px'}}
        />
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{'aria-labelledby': 'more-pic'}}
        >
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t(data.patient_portal ? 'patient_portal_deactivate' : 'patient_portal_activate')}
              variant="secondary"
              onClick={handlePatientPortalToggle}
            />
          </MenuItem>
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t('request_analyzer_update')}
              variant="secondary"
              onClick={() => handleResourceUpdate('analyzer')}
            />
          </MenuItem>
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t('request_user_update')}
              variant="secondary"
              onClick={() => handleResourceUpdate('user')}
            />
          </MenuItem>
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t('request_client_update')}
              variant="secondary"
              onClick={() => handleResourceUpdate('client')}
            />
          </MenuItem>
          <MenuItem className={styles.hideItemActions}>
            <Button
              size="small"
              label={i18n.t('change_subscription_plan')}
              variant="secondary"
              onClick={() => {
                handleClose();
                setShowChangeModal(true);
              }}
            />
          </MenuItem>
          {showRenewButton && (
            <MenuItem className={styles.hideItemActions}>
              <Button
                size="small"
                label={i18n.t('renew')}
                variant="secondary"
                onClick={handleRenew}
              />
            </MenuItem>
          )}
        </Menu>
      </div>
      {showUpdateModal && (
        <UpdateModal
          updateKey={updateKey}
          initial={data}
          onClose={onModalClose}
        />
      )}
      {showChangeModal && (
        <ChangeModal
          currentPlanId={data.subscription.id}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

SubscriptionActions.propTypes = {
  data: PropTypes.shape(),
};

export default SubscriptionActions;
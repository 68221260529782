import React from 'react';
import PropTypes from 'prop-types';
import TooltipMUI from '@mui/material/Tooltip';
import './styles.css';

const Tooltip = ({
  children,
  ...rest
}) => (
  <TooltipMUI
    arrow
    aria-describedby="tooltip"
    {...rest}
  >
    { children }
  </TooltipMUI>
);

Tooltip.propTypes = {
  children: PropTypes.node,
};

export default Tooltip;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { getClinics, getCollectionCenters } from 'redux/lab/actionCreators';
import { getB2BInvoice, getBillings } from 'redux/billing/actionCreators';
import { RESET_BILLINGS } from 'redux/billing/actions';
import { valueGetter } from 'utils/uiHelpers';
import { printPDF } from 'utils/labUtils';
import { userHasPermission } from 'utils/permissionUtils';
import { dateQueryFormatToDate, dateToQueryFormat } from 'utils/date';
import { OUTSOURCING, PERMISSION } from 'models';
import { useURLQuery } from 'hooks/useURLQuery';

import { Grid } from '@mui/material';
import { Button, Card, Spacer } from 'atoms';
import { ListFilters } from 'components/hoc';
import {
  BillingFilters,
  BillingHeaderFilters,
  BillingSummary,
  BillingTable,
} from '.';

const BillingContent = ({ client }) => {
  const dispatch = useDispatch();
  const [appliedFilters, setAppliedFilters] = useState({});
  const [printDisabled, setPrintDisabled] = useState(false);
  const { invoice } = useSelector((state) => state.billing);
  const { query, setQuery } = useURLQuery();
  const [selectedTestIds, setSelectedTestIds] = useState([]);
  const showPrintBtn = userHasPermission(PERMISSION.PRINT_INVOICE) && !isEmpty(invoice);

  const resetBillings = () => dispatch({ type: RESET_BILLINGS });

  const fetchBillings = (appliedFilter) => {
    if (
      !valueGetter(appliedFilter, 'outsourcing') &&
      !valueGetter(appliedFilter, 'search')
    ) {
      resetBillings();
      return;
    }
    const searchKey =
      appliedFilter?.outsourcing === OUTSOURCING.SAMPLE
        ? 'reservation_id'
        : 'batch_number';
    const params = {
      clinic_id: appliedFilter.clinic_id,
      [searchKey]: appliedFilter.search,
      from_date: appliedFilter.from_date
        ? dateToQueryFormat(appliedFilter.from_date)
        : null,
      to_date: appliedFilter.to_date
        ? dateToQueryFormat(appliedFilter.to_date)
        : null,
    };
    dispatch(getBillings(appliedFilter?.outsourcing, params));
  };

  const handleFilterChange = (filter) => {
    const newFilters = {
      ...appliedFilters,
      ...filter,
      search: filter?.search || undefined,
    };

    setQuery({
      ...newFilters,
      from_date: newFilters.from_date
        ? dateToQueryFormat(newFilters.from_date)
        : null,
      to_date: newFilters.to_date
        ? dateToQueryFormat(newFilters.to_date)
        : null,
    });

    setAppliedFilters(newFilters);
    if (!newFilters.search) {
      resetBillings();
      return;
    }
    fetchBillings(newFilters);
  };

  const refetch = () => {
    const params = {
      outsourcing: query?.outsourcing,
      clinic_id: query?.clinic_id,
      search: query?.search,
      from_date: query?.from_date
        ? dateQueryFormatToDate(query?.from_date)
        : null,
      to_date: query?.to_date ? dateQueryFormatToDate(query?.to_date) : null,
    };
    handleFilterChange(params);
  };

  React.useEffect(() => {
    refetch();
  }, [module]);

  const handlePrint = () => {
    setPrintDisabled(true);
    dispatch(getB2BInvoice(invoice.id))
      .then((resp) => resp.value.data)
      .then((blob) => printPDF(blob))
      .finally(() => setPrintDisabled(false));
  };

  useEffect(() => {
    dispatch(getClinics());
    dispatch(getCollectionCenters());
  }, []);

  const billingContent = (
    <>
      <ListFilters Wrapped={BillingFilters} onChange={handleFilterChange} />
      <BillingTable
        outsourcing={appliedFilters.outsourcing}
        setSelectedTestIds={setSelectedTestIds}
      />
    </>
  );

  return (
    <Grid container sx={{ marginTop: isMobile ? '1rem' : '2rem' }}>
      <Grid item xs={12} lg={8}>
        <BillingHeaderFilters client={client} onChange={handleFilterChange} />
        {isMobile ? (
          billingContent
        ) : (
          <Card styles={{ margin: '2rem' }}>{billingContent}</Card>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        pr={isBrowser ? 4 : 0}
        px={isMobile ? 2 : 0}
        mt={isMobile ? 2 : 0}
      >
        {selectedTestIds.length > 0 && (
          <>
            <BillingSummary
              filter={appliedFilters}
              testIds={selectedTestIds}
              refetch={refetch}
            />
            {showPrintBtn && (
              <>
                <Spacer height={16} />
                <Button
                  label={i18n.t(printDisabled ? 'printing' : 'print_invoice')}
                  variant="secondary"
                  disabled={printDisabled}
                  onClick={handlePrint}
                />
                {isMobile && <Spacer height={32} />}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

BillingContent.propTypes = {
  client: PropTypes.string,
};

export default BillingContent;

const Reservation = {
  // *

  // A
  add_patient: 'إضافة مريض',
  at_the_lab: 'في المختبر',
  all_fields_are_required: 'جميع الحقول مطلوبة!',

  // B
  bill_and_collection: 'الفاتورة والتحصيل',
  border_no: 'رقم الحدود',

  // C
  confirm_reservation: 'تأكيد الحجز',
  choose_gender: 'اختر الجنس',
  choose_type: 'اختر النوع',
  coming_from_outside_ksa_within_last_2_weeks: 'قادم من خارج المملكة العربية السعودية خلال الأسبوعين الماضيين',
  credit_card: 'بطاقة الائتمان',
  collection_method: 'طريقة التحصيل',
  choose_directorate: 'اختر المديرية',
  choose_isolation_place: 'اختر موقع العزل',
  choose_nationality: 'اختر الجنسية',

  // D
  date_of_birth: 'تاريخ الميلاد',
  date: 'التاريخ',
  diagnosis: 'التشخيص',
  discount: 'الخصم',
  default_result_options: 'Default Result Options',

  // E
  employee_government_sector: 'موظف قطاع حكومي',
  employee_private_sector: 'موظف بالقطاع الخاص',

  // F

  // G
  gulf_id: 'هوية الخليج',

  // H
  hajj: 'الحج',
  healthcare_worker: 'عامل رعاية صحية',
  home: 'المنزل',

  // I
  iqama_no: 'رقم الاقامة',
  id_number: 'رقم بطاقة الهوية',

  // J

  // K

  // L

  // M

  // N
  national_id: 'الهوية الوطنية',
  no_signs: 'لا أعراض',
  non_logical_result_placeholder: 'Select a default result above or enter a custom result',

  // O
  occupation: 'الوظيفة',

  // P
  patient_name: 'اسم المريض',
  patient_name_en: 'اسم المريض [ انجليزي ]',
  patient_name_ar: 'اسم المريض [ عربي ]',
  payment_method: 'نوع الدفع',
  pick_a_date_and_time: 'اختر التاريخ',

  // Q

  // R
  reason_of_testing: 'سبب الفحص',
  replace_image: 'Replace Image',

  // S
  selected_file: 'Selected file',
  signs: 'أعراض',
  signs_and_symptoms: 'العلامات والأعراض',
  subtotal: 'المجموع الجزئي',

  // T
  test_details: 'تفاصيل الفحص',
  type_of_personal_id: 'نوع بطاقة الهوية الشخصية',
  travelling_to_outside_ksa: 'السفر إلى خارج المملكة العربية السعودية',
  tax: 'الضريبة',
  total: 'الإجمالي',

  // U
  umrah_from_outside: 'عمرة من الخارج',
  user_id: 'User ID',
  unemployed: 'غير موظف',
  upload_image: 'Upload Image',

  // V

  // W
  worker_government_sector: 'عامل بالقطاع الحكومي',
  worker_private_sector: 'عامل بالقطاع الخاص',
  worker_individuals: 'عامل فردي',

  // X

  // Y

  // Z

};

export default Reservation;
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useMemo, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { serviceOptions } from 'redux/admin/actionCreators';
import { valueGetter } from 'utils/uiHelpers';
import { isAdminUser, isLabUser } from 'utils/labUtils';

import { Flex, Spacer, Text } from 'atoms';
import { CardContainer, CardSectionHeader, ModalContent } from 'templates';
import BackButton from 'components/global/BackButton';
import InfoListCards from './InfoListCards';
import TestModal from '../CreateTest/TestModal';
import ReferenceRangesTable from './ReferenceRangesTable';
import NonLogicalReferenceRangesTable from './NonLogicalReferenceRangesTable';
import TestPrioritiesTable from './TestPrioritiesTable';
import SubTestTable from './SubTestTable';

const AdminTestInformationCard = ({ serviceTypeId, onReload }) => {
  const canEdit = isLabUser() || isAdminUser();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { service_show } = useSelector((state) => state.tests);
  const isSingleTest = useMemo(() => valueGetter(service_show, 'service_type') === 'single', [service_show]);

  const toggleShowModal = (reloadData) => {
    setShowModal(!showModal);
    if (reloadData) {
      onReload();
    }
  };

  useEffect(() => {
    if (canEdit) {
      dispatch(serviceOptions());
    }
  }, []);

  const ranges = {
    logical: <ReferenceRangesTable />,
    non_logical: <NonLogicalReferenceRangesTable />,
  };

  return (
    <>
      <CardContainer noPadding>
        <ModalContent padding="32px 24px">
          <Flex flexCol={isBrowser} itemsCenter={isMobile}>
            <BackButton />
            {isBrowser && <Spacer height={16} />}
            {isMobile && <Spacer width={16} />}
            <Text
              typography={isMobile ? 'paragraph16' : 'subtitle'}
              weight="bold"
              color="var(--brand-text-black)"
              textCase="capitalize"
            >
              {i18n.t('test_details')}
            </Text>
          </Flex>
        </ModalContent>
        <CardSectionHeader
          uppercased
          title={i18n.t('test_information')}
          actionText={canEdit ? i18n.t('edit_info') : ''}
          onActionClick={toggleShowModal}
        />
        <InfoListCards isSingleTest={isSingleTest} serviceTypeId={serviceTypeId} />
        <Spacer height={10} />
        {!isSingleTest && (
          <>
            <CardSectionHeader
              uppercased
              title={i18n.t('sub_tests')}
              actionText={canEdit ? i18n.t('edit_info') : ''}
              onActionClick={toggleShowModal}
            />
            <SubTestTable subtests={valueGetter(service_show, 'sub_services') || []}/>
          </>
        )}
        <TestPrioritiesTable />
        {isSingleTest && (
          <>
            <CardSectionHeader
              uppercased
              title={i18n.t('reference_ranges')}
              actionText={canEdit ? i18n.t('edit_info') : ''}
              onActionClick={toggleShowModal}
            />
            {ranges[service_show.ref_range_type]}
          </>
        )}
      </CardContainer>
      <TestModal
        editMode
        isSingleTest={isSingleTest}
        showModal={showModal}
        onClose={toggleShowModal}
      />
    </>
  );
};

AdminTestInformationCard.defaultProps = {
  serviceTypeId: '',
};

AdminTestInformationCard.propTypes = {
  serviceTypeId: PropTypes.string,
  onReload: PropTypes.func,
};

export default AdminTestInformationCard;

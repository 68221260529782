export const TESTS_LOAD = 'TESTS_LOAD';
export const TESTS_LOAD_PENDING = 'TESTS_LOAD_PENDING';
export const TESTS_LOAD_FULFILLED = 'TESTS_LOAD_FULFILLED';
export const TESTS_LOAD_REJECTED = 'TESTS_LOAD_REJECTED';

export const TESTS_UPDATE = 'TESTS_UPDATE';
export const TESTS_UPDATE_PENDING = 'TESTS_UPDATE_PENDING';
export const TESTS_UPDATE_FULFILLED = 'TESTS_UPDATE_FULFILLED';
export const TESTS_UPDATE_REJECTED = 'TESTS_UPDATE_REJECTED';

export const TESTS_SHOW = 'TESTS_SHOW';
export const TESTS_SHOW_PENDING = 'TESTS_SHOW_PENDING';
export const TESTS_SHOW_FULFILLED = 'TESTS_SHOW_FULFILLED';
export const TESTS_SHOW_REJECTED = 'TESTS_SHOW_REJECTED';

export const SERVICE_SHOW = 'SERVICE_SHOW';
export const SERVICE_SHOW_PENDING = 'SERVICE_SHOW_PENDING';
export const SERVICE_SHOW_FULFILLED = 'SERVICE_SHOW_FULFILLED';
export const SERVICE_SHOW_REJECTED = 'SERVICE_SHOW_REJECTED';

export const TEST_PRINT_RESULTS = 'TEST_PRINT_RESULTS';
export const TEST_PRINT_RESULTS_PENDING = 'TEST_PRINT_RESULTS_PENDING';
export const TEST_PRINT_RESULTS_FULFILLED = 'TEST_PRINT_RESULTS_FULFILLED';
export const TEST_PRINT_RESULTS_REJECTED = 'TEST_PRINT_RESULTS_REJECTED';

export const TEST_DELETE = 'TEST_DELETE';
export const TEST_DELETE_PENDING = 'TEST_DELETE_PENDING';
export const TEST_DELETE_FULFILLED = 'TEST_DELETE_FULFILLED';
export const TEST_DELETE_REJECTED = 'TEST_DELETE_REJECTED';

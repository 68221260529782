import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { useTablePagination } from 'hooks';
import { valueGetter } from 'utils/uiHelpers';

import { DataTable } from 'atoms';
import { ReactComponent as EditIcon } from 'assets/icons/common/edit.svg';

const LocationsTable = ({ onEditRow, onPagination }) => {
  const { fetching, locations, pagination } = useSelector((state) => state.inventory);
  const rows = useTablePagination({ pagination, dataList: locations });

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('name'),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'right',
      minWidth: 50,
      renderCell: ({row}) => (
        <EditIcon
          style={{ cursor: 'pointer' }}
          height={24}
          onClick={() => onEditRow(row)}
        />
      )
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={rows}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

LocationsTable.propTypes = {
  onEditRow: PropTypes.func,
  onPagination: PropTypes.func
};

export default LocationsTable;

import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { createLocation, updateLocation } from 'redux/inventory/actionCreators';

import { Box } from '@mui/material';
import { Button, Flex, Spacer, Text, TextInput } from 'atoms';

const LocationForm = ({ isEdit, location, onDone }) => {
  const dispatch = useDispatch();
  const [name, setName] = React.useState(isEdit ? location.name : '');

  const disableSave = React.useMemo(() => {
    if(isEdit) return name === location.name;
    return isEmpty(name.trim());
  }, [name, isEdit]);

  const handleSave = () => {
    const payload = { location: { name }};
    dispatch(isEdit ? updateLocation(location.id, payload) : createLocation(payload)).then(() => {
      setName('');
      onDone(true);
    });
  };

  useEffect(() => {
    setName(isEdit ? location.name : '');
  }, [location]);

  return (
    <Box p={2}>
      <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
        {i18n.t(isEdit ? 'edit_location' : 'add_location')}
      </Text>
      <Spacer height={32}/>
      <TextInput label="name" value={name} onChange={(e) => setName(e.target.value)} />
      <Spacer height={32}/>
      <Flex>
        <Button variant="secondary" label={i18n.t('cancel')} onClick={() => onDone(false)} />
        <Spacer width={32}/>
        <Button label={i18n.t('save')} disabled={disableSave} onClick={handleSave} />
      </Flex>
    </Box>
  );
};

LocationForm.defaultProps = {
  isEdit: false,
  location: {},
};

LocationForm.propTypes = {
  isEdit: PropTypes.bool,
  location: PropTypes.shape(),
  onDone: PropTypes.func,
};

export default LocationForm;
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'translation/i18n';
import { useTablePagination } from 'hooks';
import { DISPLAY_DATE_FORMAT, duration, format } from 'utils/date';
import { getStatusColor } from 'utils/labUtils';

import { DataTable, Tag } from 'atoms';

const OverdueTestTable = ({onPagination}) => {
  const { push } = useHistory();
  const {
    overdueOrders,
    fetching,
    overdueOrdersPagination: pagination,
  } = useSelector((state) => state.lab);

  const handleRowClick = ({ id, row: { master_order_id } }) => {
    push(`/orders/${master_order_id}/tests/${id}?isOverdue=true`);
  };

  const rows = useTablePagination({ pagination, dataList: overdueOrders });

  const columns = [
    {
      field: 'patient_detail_name',
      headerName: i18n.t('patient'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'service_name',
      headerName: i18n.t('test_name'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'date_of_confirmed',
      headerName: i18n.t('overdue_since'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }) => duration(value),
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'turn_around_time',
      headerName: i18n.t('tat_full'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }) => {
        const [hh, mm] = value.split(':');
        const hours = Number(hh);
        const minutes = Number(mm);
        return `${hours ? `${hours} hours ` : ''} ${minutes ? `${minutes} minutes` : ''}`;
      },
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)} />
      ),
    },
    {
      field: 'created_at',
      headerName: i18n.t('created_at'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => format(value, DISPLAY_DATE_FORMAT),
    },
  ];
  
  return (
    <DataTable
      columns={columns}
      rows={rows}
      loading={fetching}
      onPageChange={(page) => onPagination(page + 1)}
      onRowClick={handleRowClick}
    />
  );
};

OverdueTestTable.propTypes = {
  onPagination: PropTypes.func,
};

export default OverdueTestTable;
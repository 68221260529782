/* eslint-disable no-nested-ternary */
import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import styles from './DataTable.module.css';

const DataTable = ({
  className,
  columns,
  rows,
  pageSize,
  mobileTableHeight,
  ...rest
}) => {
  return (
    <Box
      height={
        rest.height
          ? rest.height
          : isMobile
          ? `calc(100vh - ${mobileTableHeight}rem)`
          : 'calc(100vh - 15rem)'
      }
      width="100%"
    >
      <DataGrid
        className={clsx(styles.dataTable, className)}
        classes={styles}
        columns={columns}
        rows={rows}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        rowHeight={isMobile ? 56 : 70}
        pageSize={pageSize}
        rowsPerPageOptions={[25]}
        paginationMode={rest.paginationMode || 'server'}
        {...rest}
      />
    </Box>
  );
};

DataTable.defaultProps = {
  pageSize: 25,
  mobileTableHeight: 17.5,
  checkboxSelection: false,
};

DataTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  pageSize: PropTypes.number,
  mobileTableHeight: PropTypes.number,
  height: PropTypes.number,
  checkboxSelection: PropTypes.bool,
};

export default DataTable;

import React from 'react';
import i18n from 'translation/i18n';
import LabHeader from 'components/global/LabHeader';
import {ProfileForm} from 'components/Profile';
import {userModule} from 'utils/labUtils';

const EntityProfile = () => {
  const editText = {
    lab: 'lab_profile',
    clinic: 'clinic_profile',
    collection_center: 'collection_center_profile'
  };

  return (
    <>
      <LabHeader title={i18n.t(editText[userModule()])}/>
      <ProfileForm/>
    </>
  );
};

export default EntityProfile;

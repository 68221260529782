import React from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {BATCH_ORDER_STATUSES} from 'utils/options';

import {Grid} from '@mui/material';
import {Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';
import CreateBatch from 'components/orders/lab/orders/CreateBatch';

const BatchOrderListFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const selectFilterConfigs = [{key: 'status', options: BATCH_ORDER_STATUSES, placeholderKey: 'status'}];
  const dateFilterConfigs = [{key: 'date', placeholderKey: 'created_at'}];

  const filterContent = (
    <Grid item xs={3} lg={1}>
      <SelectFilter
        selectFilterConfigs={selectFilterConfigs}
        dateFilterConfigs={dateFilterConfigs}
        onApply={generateSelectFilters}
        onCancel={generateSelectFilters}
      />
    </Grid>
  );

  return (
    <Grid container spacing={isMobile ? 2 : 4} py={2} px={3} alignItems="center">
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">{i18n.t('batch_order_list_title')}</Text>
        <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
          {i18n.t('batch_order_list_subtitle')}
        </Text>
      </Grid>
      {isMobile && filterContent}
      <Grid item xs={12} lg={4}>
        <SearchInput
          placeholder={i18n.t('batch_order_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && (
        <>
          <Grid item lg={2} />
          {filterContent}
          <Grid item lg={2}>
            <CreateBatch/>
          </Grid>
        </>
      )}
    </Grid>
  );
};

BatchOrderListFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

export default BatchOrderListFilters;

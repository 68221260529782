import React from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Grid} from '@mui/material';
import {Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';

const InvoiceFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const dateFilterConfigs = [
    {key: 'from_date', placeholderKey: 'from_date'},
    {key: 'to_date', placeholderKey: 'to_date'}
  ];

  const selectFilter = (
    <Grid item xs={3} lg={2}>
      <SelectFilter
        dateFilterConfigs={dateFilterConfigs}
        onApply={generateSelectFilters}
        onCancel={generateSelectFilters}
      />
    </Grid>
  );

  return (
    <Grid container spacing={isMobile ? 2 : 4} py={isBrowser ? 2 : 0} px={isBrowser ? 3 : 2} alignItems="center">
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('invoice_list_title')}
        </Text>
        <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
          {i18n.t('invoice_list_subtitle')}
        </Text>
      </Grid>
      {isMobile && selectFilter}
      <Grid item xs={12} lg={7}>
        <SearchInput
          placeholder={i18n.t('invoice_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && selectFilter}
    </Grid>
  );
};

InvoiceFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

export default InvoiceFilters;
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Invoices from 'container/labs/Invoices';

const InvoicesRoutes = () => (
  <Switch>
    <Route path="/invoices" component={Invoices}/>
  </Switch>
);

export default InvoicesRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import { Text, Tooltip } from 'atoms';

const ToolTipHandler = ({
  show,
  title,
  children,
}) => {
  return (
    <>
      {show ?
        <Tooltip
          title={(
            <Text typography="caption12">
              {title}
            </Text>
          )}
        >
          <div>
            {children}
          </div>
        </Tooltip>
        :
        <>
          {children}
        </>
      }
    </>
  );
};

ToolTipHandler.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default ToolTipHandler;

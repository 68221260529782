/* eslint-disable no-console */
import { Form } from 'react-final-form';
import React, { useState } from 'react';
import { Button, Card, Spacer } from 'atoms';
import LabHeader from 'components/global/LabHeader';
import { SelectField } from 'components/global/final-form';
import { isMobile } from 'react-device-detect';
import i18n from 'translation/i18n';
import { useSerial } from './provider';

const Serial = () => {
    const { connectedDeviceId, connect, disconnect } = useSerial();
    const [advanceMode, setAdvanceMode] = useState(false);
    return (
        <>
            <LabHeader title={i18n.t('serial')} />
            <div style={{ padding: '14px 40px', maxWidth: '500px' }}>
                {advanceMode && <Form
                    onSubmit={(data) => {
                        connect('custom', data);
                        setAdvanceMode(false);
                    }}
                    initialValues={{
                        baudRate: '9600',
                        dataBits: '8',
                        parity: 'none',
                        stopBits: '1',
                        flowControl: 'none',
                        readerMode: 'byob',
                    }}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <SelectField
                                name="readerMode"
                                label="Reader Mode"
                                disabled={connectedDeviceId === 'custom'}
                                options={[
                                    { value: 'byob', label: 'BYOB' },
                                    { value: '', label: 'None' },
                                ]}
                            />
                            <Spacer height={12} />
                            <SelectField
                                name="baudRate"
                                label="Baud Rate"
                                disabled={connectedDeviceId === 'custom'}
                                options={[
                                    { value: '9600', label: '9600' },
                                    { value: '14400', label: '14400' },
                                    { value: '19220', label: '19220' },
                                    { value: '28800', label: '28800' },
                                    { value: '38400', label: '38400' },
                                    { value: '57600', label: '57600' },
                                    { value: '115200', label: '115200' },
                                    { value: '230400', label: '230400' },
                                    { value: '460800', label: '460800' },
                                    { value: '921600', label: '921600' },
                                ]}
                            />
                            <Spacer height={12} />
                            <SelectField
                                name="parity"
                                label="Parity"
                                disabled={connectedDeviceId === 'custom'}
                                options={[
                                    { value: 'none', label: 'none' },
                                    { value: 'even', label: 'even' },
                                    { value: 'odd', label: 'odd' },
                                ]}
                            />
                            <Spacer height={12} />
                            <SelectField
                                name="dataBits"
                                label="Data Bits"
                                disabled={connectedDeviceId === 'custom'}
                                options={[
                                    { value: '7', label: '7' },
                                    { value: '8', label: '8' },
                                ]}
                            />
                            <Spacer height={12} />
                            <SelectField
                                name="stopBits"
                                label="Stop Bits"
                                disabled={connectedDeviceId === 'custom'}
                                options={[
                                    { value: '1', label: '1' },
                                    { value: '2', label: '2' },
                                ]}
                            />
                            <Spacer height={12} />
                            <SelectField
                                name="flowControl"
                                label="Flow Control"
                                disabled={connectedDeviceId === 'custom'}
                                options={[
                                    { value: 'none', label: 'none' },
                                    { value: 'hardware', label: 'hardware' },
                                ]}
                            />
                            <div style={{ paddingTop: '8px' }}>
                                <Button
                                    label={connectedDeviceId === 'custom' ? 'Disconnect' : 'Connect'}
                                    type="submit"
                                />
                            </div>
                            <div style={{ paddingTop: '8px' }}>
                                <Button
                                    variant='secondary'
                                    label="Cancel"
                                    type="button"
                                    onClick={() => { setAdvanceMode(false); }}
                                />
                            </div>

                        </form>
                    )}
                </Form>}

                {!advanceMode &&
                    <>
                        <Card styles={{ marginBottom: `${isMobile ? '2rem 1rem' : '2rem'}`, padding: '1rem 1.5rem' }}>
                            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                <p style={{ width: '200px' }}>Cobas 311</p>
                                <Button
                                    disabled={connectedDeviceId && connectedDeviceId !== 'cobas-311'}
                                    onClick={() => {
                                        if (connectedDeviceId === 'cobas-311') {
                                            disconnect();
                                        } else connect('cobas-311');
                                    }}
                                    label={connectedDeviceId === 'cobas-311' ? 'Disconnect' : 'Connect'}
                                    fitContent
                                />
                            </div>
                        </Card>
                        <Card styles={{ marginBottom: `${isMobile ? '2rem 1rem' : '2rem'}`, padding: '1rem 1.5rem' }}>
                            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                <p style={{ width: '200px' }}>Cobas 411</p>
                                <Button
                                    disabled={connectedDeviceId && connectedDeviceId !== 'cobas-411'}
                                    onClick={() => {
                                        if (connectedDeviceId === 'cobas-411') {
                                            disconnect();
                                        } else connect('cobas-411');
                                    }}
                                    label={connectedDeviceId === 'cobas-411' ? 'Disconnect' : 'Connect'}
                                    fitContent
                                />
                            </div>
                        </Card>
                        <Card styles={{ padding: '1rem 1.5rem' }}>
                            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                <p style={{ width: '200px' }}>Advance settings</p>
                                <Button
                                    disabled={connectedDeviceId && connectedDeviceId !== 'custom'}
                                    onClick={() => {
                                        if (connectedDeviceId === 'custom') {
                                            disconnect();
                                        } setAdvanceMode(true);
                                    }}
                                    label={connectedDeviceId === 'custom' ? 'Disconnect' : 'Connect'}
                                    fitContent
                                />
                            </div>
                        </Card>
                    </>
                }
            </div>
        </>
    );
};
export default Serial;

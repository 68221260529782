import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {getPatientOrders} from 'redux/orders/actionCreators';
import {dateToQueryFormat} from 'utils/date';

import {Card} from 'atoms';
import {ListFilters} from 'components/hoc';
import PatientOrderFilters from './PatientOrderFilters';
import PatientOrderTable from './PatientOrderTable';

const PatientOrders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientOrders());
  });

  const handleFilterChange = filter => {
    const params = {
      search: filter.search,
      priority: filter.priority,
      result: filter.result,
      status: filter.status,
      from_date: filter.date ? dateToQueryFormat(filter.date) : null
    };
    dispatch(getPatientOrders(params));
  };

  return (
    <Card styles={{margin: '2rem'}}>
      <ListFilters Wrapped={PatientOrderFilters} onChange={handleFilterChange}/>
      <PatientOrderTable/>
    </Card>
  );
};

export default PatientOrders;

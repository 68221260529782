import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {ACTIVE_STATUS} from 'models';
import {valueGetter, isPathIncludes} from 'utils/uiHelpers';
import {getClientDetails, updateClient} from 'redux/lab/actionCreators';

import {Box} from '@mui/material';
import {Flex, Spacer, Switch, Text} from 'atoms';

const ClientSettings = () => {
  const dispatch = useDispatch();
  const {clientInfo} = useSelector(state => state.lab);
  const [isActive, setIsActive] = useState(false);

  const activationLabel = {
    true: 'clinic_activation',
    false: 'collection_center_activation'
  };

  const handleStatusChange = (status) => {
    const payload = {
      status: status ? ACTIVE_STATUS.ACTIVE : ACTIVE_STATUS.INACTIVE
    };
    dispatch(updateClient(clientInfo.id, payload))
      .then(() => dispatch(getClientDetails(clientInfo.id)));
  };

  useEffect(() => {
    setIsActive(valueGetter(clientInfo, 'status') === ACTIVE_STATUS.ACTIVE);
  }, [clientInfo]);

  return (
    <>
      <Box px={3} py={4}>
        <Flex justifyBetween>
          <Text typography="paragraph14" weight="bold">
            {i18n.t(activationLabel[isPathIncludes('clinics')])}
          </Text>
          <Switch checked={isActive} onChange={handleStatusChange}/>
        </Flex>
        <Spacer height={24}/>
      </Box>
    </>
  );
};

export default ClientSettings;
import Toastr from 'toastr/build/toastr.min';
import 'toastr/build/toastr.css';

Toastr.options = {
  preventDuplicates: true,
  preventOpenDuplicates: true,
  progressBar: true,
  ...(localStorage.getItem('Locale') === 'ar' && {
    rtl: true,
    positionClass: 'toast-top-left',
  }),
};

export {Toastr};

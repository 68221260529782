import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';

import { serviceShow } from 'redux/tests/actionCreators';

import { Grid } from '@mui/material';
import { TashkhesLoader } from 'atoms';
import { RouteLayoutTemplate } from 'templates';
import AdminTestInformationCard from './DetailCards/AdminTestInformationCard';

const TestDetail = () => {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state) => state.tests);
  const { state } = useLocation();

  const loadData = () => dispatch(serviceShow(state.id));

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <RouteLayoutTemplate padding={isBrowser ? 30 : 16}>
      {fetching ? (
        <TashkhesLoader />
      ) : (
        <Grid
          container
          rowSpacing={isMobile ? 2 : 0}
        >
          <Grid item xs={24}>
            <AdminTestInformationCard onReload={loadData} />
          </Grid>
        </Grid>
      )}
    </RouteLayoutTemplate>
  );
};

export default TestDetail;

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {EntityProfile, UserProfile} from 'container/labs/Profile';

const ProfileRoutes = () => (
  <Switch>
    <Route path="/lab-profile" component={EntityProfile}/>
    <Route path="/user-profile" component={UserProfile}/>
  </Switch>
);

export default ProfileRoutes;

import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ClinicDetails, Clients, CollectionCenterDetails} from 'container/labs/LabClients';

const ClientsRoutes = () => (
  <Switch>
    <Route path="/clients/clinics/:id" exact component={ClinicDetails}/>
    <Route path="/clients/clinics" exact component={Clients}/>
    <Route path="/clients/collection-centers/:id" exact component={CollectionCenterDetails}/>
    <Route path="/clients/collection-centers" exact component={Clients}/>
    <Redirect to="/clients/clinics" />
  </Switch>
);

export default ClientsRoutes;

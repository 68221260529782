import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { Option } from 'models';

import { Box } from '@mui/material';
import { Text, Flex, Spacer } from 'atoms';
import LanguageSwitcher from 'components/global/LanguageSwitcher';
import HeaderFilter from 'components/global/HeaderFilter';
import UserInfo from 'components/global/UserInfo';

import './styles.scss';

const LabHeader = ({
  title,
  selectedOption,
  filterOptions,
  onFilterChange,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    setShowFilter(!!(filterOptions && filterOptions.length));
  }, [filterOptions]);

  return isMobile ? (
    <div className="lab-header">
      <Flex className="mobile-view" itemsCenter justifyBetween>
        <Text typography="paragraph16" weight="bold">
          {i18n.t(title)}
        </Text>
        {showFilter && (
          <HeaderFilter
            selectedOption={selectedOption}
            options={filterOptions}
            onChange={onFilterChange}
          />
        )}
      </Flex>
    </div>
  ) : (
    <Flex className="lab-header" justifyBetween>
      <Flex itemsCenter>
        <Text
          className="page-title"
          as="span"
          weight="bold"
          textCase="capitalize"
        >
          {i18n.t(title)}
        </Text>
        <Spacer width={20} />
        {showFilter && (
          <HeaderFilter
            selectedOption={selectedOption}
            options={filterOptions}
            onChange={onFilterChange}
          />
        )}
      </Flex>
      <Box display="flex" marginTop={1}>
        <Flex itemsCenter className="page-actions">
          <LanguageSwitcher />
        </Flex>
        <UserInfo />
      </Box>
    </Flex>
  );
};

LabHeader.propTypes = {
  title: PropTypes.string,
  selectedOption: PropTypes.string,
  filterOptions: PropTypes.arrayOf(Option),
  onFilterChange: PropTypes.func,
};

export default LabHeader;

import React from 'react';
import PropTypes from 'prop-types';

import {Redirect, Route} from 'react-router-dom';
import {ACCESS_TOKEN} from 'utils/constants';

const PrivateRoute = ({component: Component, ...rest}) => {
  const token = !!localStorage.getItem(ACCESS_TOKEN);

  return (
    <Route
      {...rest}
      render={props => (
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/lab/login"/>
        )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape(),
  ]),
  location: PropTypes.shape(),
};

export default PrivateRoute;

import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Grid} from '@mui/material';
import {DataTable, Modal} from 'atoms';
import {InfoCard, ModalContent, ModalHeader} from 'templates';
import styles from '../styles.module.css';
import {valueGetter} from '../../../utils/uiHelpers';

const SubTestDetailModal = ({showModal, service, onClose}) => {
  const isLogical = service.ref_range_type === 'logical';
  const testDetails = [
    { label: 'lis_code', value: service.lis_code },
    { label: 'remisol_name', value: service.name },
    { label: 'description', value: service.description },
    { label: 'test_type', value: service.test_type },
    { label: 'unit', value: service.unit },
    { label: 'precision', value: service.no_decimal },
    { label: 'ref_range_type', value: i18n.t(service.ref_range_type) }
  ];
  const columns = [
    {
      field: 'gender',
      headerName: i18n.t('gender'),
      minWidth: 150,
    },
    {
      field: 'min_age',
      headerName: i18n.t('min_age'),
      minWidth: 150,
    },
    {
      field: 'max_age',
      headerName: i18n.t('max_age'),
      minWidth: 150,
    },
    {
      field: 'min_value',
      headerName: i18n.t('min_value'),
      minWidth: 150,
    },
    {
      field: 'max_value',
      headerName: i18n.t('max_value'),
      minWidth: 150,
    },
    {
      field: 'result',
      headerName: i18n.t('result'),
      minWidth: 150,
    },
  ];
  
  return (
    <Modal
      header={<ModalHeader title={i18n.t('sub_test_details')}/>}
      width="80vw"
      isOpen={showModal}
      onClose={onClose}
    >
      <ModalContent>
        <Grid container spacing="20px" className={styles.infoCardsContainer}>
          {testDetails.map((li, index) => (
            <Grid item key={index} xs={6} md={4} xl={3}>
              <InfoCard titleKey={li.label} value={li.value} key={index} />
            </Grid>
          ))}
          {!isLogical && (
            <Grid item xs={6} md={4} xl={3}>
              <InfoCard
                titleKey="ref_range_description"
                value={valueGetter(service, 'reference_range_non_logicals').map(r => r.description).join('; ')}
              />
            </Grid>
          )}
        </Grid>
        {isLogical && <DataTable columns={columns} rows={valueGetter(service, 'reference_ranges') || []}/>}
      </ModalContent>
    </Modal>
  );
};

SubTestDetailModal.propTypes = {
  showModal: PropTypes.bool,
  service: PropTypes.shape(),
  onClose: PropTypes.func
};

export default SubTestDetailModal;
import React, { useEffect } from 'react';
import { valueGetter } from 'utils/uiHelpers';
import { CLIENT_TYPES } from 'utils/options';
import LabHeader from 'components/global/LabHeader';
import { BillingContent } from 'components/billing';
import { useURLQuery } from 'hooks/useURLQuery';

export const Billing = () => {
  const { query, setQuery } = useURLQuery();

  useEffect(() => {
    setQuery({ header: valueGetter(CLIENT_TYPES, '0.value') });
  }, []);

  const handleFilterChange = (value) => {
    setQuery({ header: value });
  };

  return (
    <>
      <LabHeader
        title="billing"
        selectedOption={query?.header}
        filterOptions={CLIENT_TYPES}
        onFilterChange={handleFilterChange}
      />
      <BillingContent client={query?.header} />
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card} from '@mui/material';

import i18n from 'translation/i18n';
import {Text} from 'atoms';

import './styles.scss';

export const OverviewStatCard = ({title, value}) => {
  return (
    <Card variant="outlined" className="overview-stat-card">
      <Box p={3} display="flex" flexDirection="column" alignItems="center">
        <Text typography="paragraph16" color="var(--brand-text-gray-light)" weight="bold">
          {i18n.t(title)}
        </Text>
        <Text typography="display40" weight="bold" style={{marginTop: '0.75rem'}}>{value}</Text>
      </Box>
    </Card>
  );
};

OverviewStatCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number
};

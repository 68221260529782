import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import { required } from 'utils/FormValidations';
import { translatedLabelForOptions } from 'utils/labUtils';
import {
  getLabSubscriptions,
  getPlans,
  getServiceProviderSubscriptions,
  renewLabSubscription,
} from 'redux/admin/actionCreators';
import { SUBSCRIPTION_DURATION } from 'models';
import { ReactComponent as CashMoneySelected } from 'assets/icons/reservations/cash-money-selected.svg';
import { ReactComponent as CashMoney } from 'assets/icons/reservations/cash-money.svg';
import { ReactComponent as CardPosSelected } from 'assets/icons/reservations/card-pos-selected.svg';
import { ReactComponent as CardPos } from 'assets/icons/reservations/card-pos.svg';
import { Button, Modal, RadioBox, Spacer, Text } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';
import { SelectField, TextInputField } from 'components/global/final-form';

const SubscriptionRenewModal = ({ showModal, onClose }) => {
  const dispatch = useDispatch();
  const [payMethod, setPayMethod] = useState('cash');
  const { id: serviceProviderId } = useParams();
  const { plans, submitting } = useSelector((state) => state.admin);

  const onSubmit = (value) => {
    const data = {
      service_provider_id: serviceProviderId,
      subscription_id: value.subscriptionId,
      status: 'active',
      payment: {
        type: payMethod,
        discount_amount: value.discount,
      },
    };
    dispatch(renewLabSubscription(data))
      .then(() => {
        dispatch(
          getServiceProviderSubscriptions({
            service_provider_id: serviceProviderId,
          }),
        );
        dispatch(getLabSubscriptions(serviceProviderId));
      })
      .then(() => onClose(true));
  };

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  if (!plans) return <p>loading...</p>;

  const trailOption = plans.find(
    (o) => o.duration === SUBSCRIPTION_DURATION.TRIAL,
  );
  const withDurationOption = plans
    .filter((o) => o.duration !== SUBSCRIPTION_DURATION.TRIAL)
    .map((i) => i.id);
  const initialValues = {
    subscriptionId: trailOption?.id,
    discount: 0,
  };
  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={350}
        header={<ModalHeader title={i18n.t('renew_subscription')} />}
        onClose={onClose}
      >
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <SelectField
                  name="subscriptionId"
                  label={i18n.t('duration')}
                  options={translatedLabelForOptions(
                    plans,
                    'duration',
                    'subscription_',
                  )}
                  valueKey="id"
                  labelKey="duration"
                  validate={required}
                />
                {withDurationOption.includes(values.subscriptionId) && (
                  <>
                    <Spacer height={12} />
                    <TextInputField
                      name="discount"
                      type="number"
                      label={i18n.t('discount')}
                      validate={required}
                    />
                  </>
                )}
                <Spacer height={12} />
                <Text
                  typography="caption12"
                  color="var(--brand-text-gray-light)"
                  weight="bold"
                >
                  {i18n.t('payment_method').toUpperCase()}
                </Text>
                <Spacer height={6} />
                <RadioBox
                  name="payment.type"
                  label={i18n.t('cash')}
                  variant="large"
                  checked={payMethod === 'cash'}
                  startIcon={
                    payMethod === 'cash' ? <CashMoneySelected /> : <CashMoney />
                  }
                  onChange={() => setPayMethod('cash')}
                />
                <RadioBox
                  name="payment.type"
                  label={i18n.t('transaction')}
                  variant="large"
                  checked={payMethod === 'transaction'}
                  startIcon={
                    payMethod === 'transaction' ? (
                      <CardPosSelected />
                    ) : (
                      <CardPos />
                    )
                  }
                  onChange={() => setPayMethod('transaction')}
                />
              </ModalContent>
              <Spacer height={12} />
              <Spacer
                height={1}
                width="100%"
                style={{ backgroundColor: 'var(--brand-divider-white)' }}
              />
              <ModalContent>
                <Button
                  label={i18n.t('submit')}
                  onClick={() => onSubmit(values)}
                  disabled={submitting}
                />
              </ModalContent>
            </form>
          )}
        </Form>
      </Modal>
    )
  );
};

SubscriptionRenewModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SubscriptionRenewModal;

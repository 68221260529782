import React from 'react';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {formattedPrice} from 'utils/labUtils';
import {valueGetter} from 'utils/uiHelpers';

import {Box} from '@mui/material';
import {Flex, Spacer, Text} from 'atoms';

const ClientStats = () => {
  const {clientInfo} = useSelector(state => state.lab);

  return (
    <Box px={3} py={4}>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('reserved_orders')}
        </Text>
        <Text typography="paragraph14" weight="bold">
          {valueGetter(clientInfo, 'total_reserved_orders')}
        </Text>
      </Flex>
      <Spacer height={8}/>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('confirmed_orders')}
        </Text>
        <Text typography="paragraph14" weight="bold">
          {valueGetter(clientInfo, 'total_confirmed_orders')}
        </Text>
      </Flex>
      <Spacer height={8}/>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('total_income')}
        </Text>
        <Text typography="paragraph14" weight="bold">
          {formattedPrice(valueGetter(clientInfo, 'total_income'), 'saudi_riyal')}
        </Text>
      </Flex>
    </Box>
  );
};

export default ClientStats;
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { isStepNotValid, userModule } from 'utils/labUtils';
import { createTest, editTest } from 'redux/admin/actionCreators';

import { Alert, Modal } from 'atoms';
import { ModalHeader } from 'templates';
import { serviceUpdate } from 'redux/lab/actionCreators';
import CreateTestForm from './CreateTestForm';

const steps = {
  1: [],
  2: [],
};

const TestModal = ({ isSingleTest, showModal, editMode, onClose }) => {
  const module = userModule();
  const isAdmin = module === 'admin';

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const [showAlert, setShowAlert] = useState(false);

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const beforeClose = (reloadData = false) => {
    setActiveStep(1);
    setShowAlert(false);
    onClose(reloadData);
  };

  const getSingleTestPayload = (values) => {
    const isLogical = values.ref_range_type === 'logical';
    const referenceRangesPayload = {
      ...(isLogical
        ? {
          reference_ranges_attributes: (values.reference_ranges || []).map(
            (r) => ({
              ...r,
              max_age: Number(r.max_age),
              min_age: Number(r.min_age),
              max_value: Number(r.max_value),
              min_value: Number(r.min_value),
            }),
          ),
        }
        : { reference_range_non_logicals_attributes: values.reference_ranges }),
    };
    return {
      service_type: 'single',
      code: values.lis_code,
      category_type: values.category_type,
      description: values.description,
      lis_code: values.lis_code,
      name: values.name,
      no_decimal: values.no_decimal,
      principal: values.principal,
      ref_range_type: values.ref_range_type,
      specimen_type_ids: values.specimen_types.map((s) => s.value),
      test_type: values.test_type,
      unit: values.unit,
      analyzer_code: values.analyzer_code,
      ...referenceRangesPayload,
    };
  };

  const getMultiTestPayload = (values) => ({
    service_type: 'multi',
    code: values.lis_code,
    lis_code: values.lis_code,
    name: values.name,
    description: values.description,
    category_type: values.category_type,
    principal: values.principal,
    specimen_type_ids: values.specimen_types.map((s) => s.value),
    sub_services_attributes: values.services_attributes.map(a => {
      const isLogical = a.ref_range_type === 'logical';
      const attributes = {
        ...(editMode && a.id ? { id: a.id } : {}),
        name: a.name,
        lis_code: a.lis_code,
        code: a.lis_code,
        description: a.description,
        test_type: a.test_type,
        unit: a.unit,
        no_decimal: a.no_decimal,
        ref_range_type: a.ref_range_type,
        analyzer_code: a.analyzer_code,
      };
      if (isLogical) {
        attributes.reference_ranges_attributes = a.reference_ranges.map(r => ({
          ...r,
          ...(editMode && r.id ? { id: r.id } : {}),
          min_age: Number(r.min_age),
          max_age: Number(r.max_age),
          min_value: Number(r.min_value),
          max_value: Number(r.max_value),
        }));
      } else {
        attributes.reference_range_non_logicals_attributes = a.reference_range_non_logicals
          .map(r => ({
            ...r,
            ...(editMode && r.id ? { id: r.id } : {}),
          }));
      }
      return attributes;
    })
  });

  const onSubmit = (values) => {
    setShowAlert(false);
    const isEmptyField = isStepNotValid(values, activeStep, steps);
    if (isEmptyField) {
      setShowAlert(true);
      return;
    }
    let payload = {};
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
      return;
    }
    if (values.is_single_test) {
      payload = getSingleTestPayload(values);
    } else {
      payload = getMultiTestPayload(values);
    }
    if (editMode) {
      if (isAdmin) {
        dispatch(editTest(values.id, {service: payload})).then(() => {
          beforeClose(true);
        });
      } else {
        dispatch(serviceUpdate(values.id, {service: payload})).then(() => {
          beforeClose(true);
        });
      }
    } else {
      dispatch(createTest({ service: payload })).then(() => {
        beforeClose(true);
      });
    }
  };

  return (
    <Modal
      header={
        <ModalHeader
          title={i18n.t(editMode ? 'edit_test' : 'create_test')}
          onBack={activeStep === 1 ? null : () => onBack()}
        />
      }
      isOpen={showModal}
      width={activeStep === 1 ? 350 : 600}
      disableCloseOut
      onClose={() => beforeClose(false)}
    >
      <CreateTestForm
        editMode={editMode}
        isSingleTest={isSingleTest}
        activeStep={activeStep}
        onSubmit={onSubmit}
      />
      <Alert
        message={i18n.t('all_fields_are_required')}
        variant="danger"
        placement="bottom"
        show={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </Modal>
  );
};

TestModal.defaultProps = {
  isSingleTest: true,
  editMode: false,
};

TestModal.propTypes = {
  isSingleTest: PropTypes.bool,
  showModal: PropTypes.bool,
  editMode: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TestModal;

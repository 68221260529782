import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';

import i18n from 'translation/i18n';

import { Text } from 'atoms';
import { ReactComponent as MenuIcon } from 'assets/icons/common/more-vertical.svg';
import './styles.scss';

const SpecimenTypeActions = ({
  children,
  menuPosition,
  onMenuClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (option) => {
    onMenuClick(option);
    handleClose();
  };

  return (
    <>
      <div
        id="specimen-type-actions"
        aria-haspopup="true"
        aria-controls={open ? 'specimen-type-actions-popover' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {children || (
          <IconButton>
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <Menu
        id="specimen-type-actions-popover"
        className={`position-${menuPosition}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'specimen-type-actions' }}
      >
        <MenuItem
          onClick={() => handleMenuClick('edit')}
        >
          <Text
            typography="paragraph14"
            weight="bold"
          >
            {i18n.t('edit')}
          </Text>
        </MenuItem>
        <MenuItem
          className="cancel-order"
          onClick={() => handleMenuClick('delete')}
        >
          <Text
            typography="paragraph14"
            weight="bold"
            color="var(--text-color-button-danger)"
          >
            {i18n.t('delete')}
          </Text>
        </MenuItem>
      </Menu>
    </>
  );
};

SpecimenTypeActions.defaultProps = {
  onMenuClick: () => {},
  menuPosition: 'left',
};

SpecimenTypeActions.propTypes = {
  children: PropTypes.node,
  menuPosition: PropTypes.oneOf(['left', 'right', 'bottom']),
  onMenuClick: PropTypes.func,
};

export default SpecimenTypeActions;

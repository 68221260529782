import {get} from 'lodash';

import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  printing: false,
  downloading: false,
  reports: [],
  totalAmount: 0,
  pagination: {},
  chartData: {}
};

export default function reducer(
  state = initialState,
  action
) {
  switch (action.type) {
  case actions.PRINT_REPORT_DATA_PENDING:
    return {
      ...state,
      printing: true
    };

  case actions.DOWNLOAD_REPORT_PENDING:
    return {
      ...state,
      downloading: true
    };

  case actions.REPORTS_PENDING:
  case actions.REPORT_DATA_PENDING:
    return {
      ...state,
      fetching: true,
      totalAmount: 0,
      reports: []
    };

  case actions.GET_REPORT_CHART_DATA_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.REPORTS_FULFILLED: {
    const reports = get(action, 'payload.data.data', []).map((report, index) => ({id: `report-${index}`, ...report}));
    const totalAmount = get(action, 'payload.data.total_amount_paid') || 0;
    return {
      ...state,
      pagination: get(action, 'payload.data.pagination', {}),
      fetching: false,
      totalAmount,
      reports
    };
  }

  case actions.REPORT_DATA_FULFILLED: {
    const report = get(action, 'payload.data.data', {});
    const reports = Object.keys(report).map((key, index) => ({id: `report-${index}`, key, value: report[key]}));
    return {
      ...state,
      fetching: false,
      pagination: get(action, 'payload.data.pagination', {}),
      reports
    };
  }

  case actions.PRINT_REPORT_DATA_FULFILLED:
    return {
      ...state,
      printing: false
    };

  case actions.DOWNLOAD_REPORT_FULFILLED:
    return {
      ...state,
      downloading: false
    };

  case actions.GET_REPORT_CHART_DATA_FULFILLED:
    return {
      ...state,
      fetching: false,
      chartData: {
        ...state.chartData,
        ...get(action, 'payload.data', {})
      }
    };

  case actions.REPORT_DATA_REJECTED:
  case actions.REPORTS_REJECTED:
  case actions.GET_REPORT_CHART_DATA_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      fetching: false
    };

  case actions.PRINT_REPORT_DATA_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      printing: false
    };

  case actions.DOWNLOAD_REPORT_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      downloading: false
    };

  default:
    return state;
  }
}
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { testsLoad } from 'redux/tests/actionCreators';
import SelectTestsTable from './SelectTestsTable';

const SelectTests = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(testsLoad());
  }, []);

  // const handleFilterChange = (filter) => dispatch(testsLoad(filter));

  return (
    <>
      {/* <ListFilters
        Wrapped={ReservationTestFilters}
        onChange={handleFilterChange}
      /> */}
      <SelectTestsTable />
    </>
  );
};

export default SelectTests;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DataTable } from 'atoms';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';

const TestsTable = (props) => {
  const { fetching, pagination } = useSelector((state) => state.tests);
  const { list, onRowClick, onPagination } = props;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!pagination) return;
    const { current_page = 0, per_page = 0, previous_page = 0 } = pagination;
    const data = [...rows];
    if (rows.length <= current_page * per_page) {
      data.push(...list);
    } else {
      data.splice(previous_page, per_page, ...list);
    }
    setRows(data);
  }, [list]);

  // const handleMenuClick = async (id, option) => {
  //   await activateAllPrioritiesInTest(id, { status: option });
  // };

  const columns = [
    {
      field: 'lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'name',
      headerName: i18n.t('remisol_name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'principal',
      headerName: i18n.t('principal'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => i18n.t(valueGetter(row, field)),
    },
    {
      field: 'category_type',
      headerName: i18n.t('category'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    // API not ready yet
    // {
    //   field: 'actions',
    //   headerName: ' ',
    //   align: 'center',
    //   sortable: false,
    //   minWidth: 50,
    //   renderCell: ({ row }) => (
    //     <TestsMenu onMenuClick={(option) => handleMenuClick(row.id, option)} />
    //   ),
    // },
  ];

  return (
    <DataTable
      columns={columns}
      rows={list}
      onRowClick={onRowClick}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

TestsTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
  onPagination: PropTypes.func,
};

export default TestsTable;

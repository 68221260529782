import { get } from 'lodash';

import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  saving: false,
  downloading: false,
  locations: [],
  stocks: [],
  pagination: {},
  options: {},
  stock: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOCATIONS_PENDING:
    case actions.SAVE_LOCATION_PENDING:
    case actions.STOCKS_PENDING:
    case actions.SAVE_STOCK_PENDING:
    case actions.STOCK_DETAILS_PENDING:
      return {
        ...state,
        fetching: true,
        saving: true,
      };

    case actions.DOWNLOAD_REPORT_PENDING:
      return {
        ...state,
        downloading: true
      };

    case actions.LOCATIONS_FULFILLED:
      return {
        ...state,
        locations: get(action, 'payload.data.locations', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.SAVE_LOCATION_FULFILLED:
    case actions.SAVE_STOCK_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        saving: false,
      };

    case actions.STOCKS_FULFILLED:
      return {
        ...state,
        stocks: get(action, 'payload.data.stocks', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.STOCK_DETAILS_FULFILLED:
      return {
        ...state,
        stock: get(action, 'payload.data.stock', {}),
        fetching: false,
      };

    case actions.STOCK_OPTIONS_FULFILLED:
      return {
        ...state,
        options: get(action, 'payload.data', {}),
      };

    case actions.DOWNLOAD_REPORT_FULFILLED:
      return {
        ...state,
        downloading: false
      };

    case actions.LOCATIONS_REJECTED:
    case actions.SAVE_LOCATION_REJECTED:
    case actions.STOCKS_REJECTED:
    case actions.STOCK_OPTIONS_REJECTED:
    case actions.SAVE_STOCK_REJECTED:
    case actions.STOCK_DETAILS_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        fetching: false,
        saving: false,
      };

    case actions.DOWNLOAD_REPORT_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        downloading: false
      };

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import clsx from 'clsx';
import {
  Text,
  Flex,
  Spacer,
} from 'atoms';
import {ReactComponent as BackIcon} from 'assets/icons/common/arrow-left.svg';

import styles from './ModalTemplate.module.css';
import 'styles/styleTokens.css';

const { language} = i18n;

const titleAligning = {
  ar: 'right',
  en: 'left',
};

// variants: center/normal
const ModalTemplate = ({
  gradientBorder,
  noPadding,
  subTitle,
  children,
  variant,
  footer,
  title,
  width,
  style,
  onBack,
}) => {

  return (
    <div className={gradientBorder ? styles.modalGradientBorderContainer : undefined}>
      <div
        className={clsx(
          styles.modalContainer,
          gradientBorder ? styles.modalGradientBorder : undefined
        )}
        style={{ width }}
      >
        {title && (
          <div
            className={clsx((variant === 'center' ? styles.centeredModalContainer : styles.normalModalContainer))}
          >
            <Flex flexCol style={{ textAlign: variant === 'center' ? 'center' : titleAligning[language] }}>
              <Flex itemsCenter justifyCenter={variant === 'center'}>
                {onBack && <BackIcon onClick={onBack} style={{cursor: 'pointer'}}/>}
                <Spacer width={14}/>
                {title && <Text typography={variant === 'center' ? 'headline' : 'paragraph16'}>{title}</Text>}
              </Flex>
              {subTitle && <Spacer height={variant === 'center' ? 9 : 2} />}
              {subTitle && <Text typography='paragraph14' color="var(--brand-text-gray-light)">{subTitle}</Text>}
            </Flex>
          </div>
        )}
        {title && variant === 'normal' && <Spacer height={1} width='100%' style={{ backgroundColor: 'var(--brand-divider-white)' }} />}

        <div
          className={clsx((variant === 'center' ? styles.centeredModalContainer : styles.normalModalContainer))}
          style={{padding: noPadding && 0, ...style}}
        >
          {children}
        </div>

        {footer && (
          <>
            <Spacer height={variant === 'center' ? 40 : 10} />
            <Spacer height={1} width='100%' style={{ backgroundColor: 'var(--brand-divider-white)' }} />
            <div
              className={clsx((variant === 'center' ? styles.centeredModalContainer : styles.normalModalContainer))}
            >
              {footer}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ModalTemplate.defaultProps = {
  width: 380,
  variant: 'normal',
};

ModalTemplate.propTypes = {
  gradientBorder: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  subTitle: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  footer: PropTypes.node,
  noPadding: PropTypes.bool,
  style: PropTypes.shape(),
  onBack: PropTypes.func,
};

export default ModalTemplate;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { REPORT } from 'models';
import { getReportList } from 'redux/report/actionCreators';
import { dateTimeQueryFormatToDate, dateTimeToQueryFormat } from 'utils/date';
import { useURLQuery } from 'hooks/useURLQuery';
import { ListFilters } from 'components/hoc';
import { CATEGORIES, PRIORITIES, RESULT_STATUSES } from 'utils/options';
import ReportFilters from '../ReportFilters';
import ReportTable from './ReportTable';
import useHandelPagination from '../../../hooks/useHandelPagination';

const selectFilters = [
  { key: 'category', options: CATEGORIES, placeholderKey: 'category' },
  { key: 'priority', options: PRIORITIES, placeholderKey: 'priority' },
  { key: 'result_status', options: RESULT_STATUSES, placeholderKey: 'result_status' },
];

const ResultsReports = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();
  const appliedFilters = {
    source: query.source,
    category: query.category,
    priority: query.priority,
    result_status: query.result_status,
    from_date: query.from_date ? dateTimeQueryFormatToDate(query.from_date) : null,
    to_date: query.to_date ? dateTimeQueryFormatToDate(query.to_date) : null,
  };

  const fetchReports = (params = {}) => {
    dispatch(getReportList(REPORT.RESULTS, params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      source: filter.source,
      category: filter.category,
      priority: filter.priority,
      result_status: filter.result_status,
      from_date: filter.from_date ? dateTimeToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateTimeToQueryFormat(filter.to_date) : null,
    };
    setQuery(params);
    fetchReports(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: fetchReports
  });

  useEffect(() => {
    fetchReports(appliedFilters);
  }, []);

  return (
    <>
      <ListFilters
        Wrapped={ReportFilters}
        reportType={REPORT.RESULTS}
        showSourceFilter
        selectFilters={selectFilters}
        filters={appliedFilters}
        onChange={handleFilterChange}
      />
      <ReportTable onPagination={handlePagination} />
    </>
  );
};

export default ResultsReports;

export const APPLICATION_JSON = 'application/json';
export const ACCESS_TOKEN = 'AccessToken';
export const LOCALE = 'Locale';
export const SLUG = 'Slug';
export const USER = 'User';
export const LAB_ID = 'LabId';
export const PLAN_VALUE = 'PlanValue';
export const PATIENT_SIGNUP_BOOKING = 'IsPatientSignup';
export const PATIENT_LABEL = 'patient';
export const LAB_LABEL = 'lab';
export const CLINIC_LABEL = 'clinic';
export const COLLECTION_CENTER_LABEL = 'collection_center';
export const ADMIN_LABEL = 'admin';
export const SUPER_ADMIN = 'super_admin';
export const SERVICES_LABEL = 'services';
export const SERVICE_PRIORITIES_LABEL = 'service_priorities';
export const AR_LABEL = 'ar';
export const EN_LABEL = 'en';
export const RTL_LABEL = 'rtl';
export const LTR_LABEL = 'ltr';
export const USER_PERMISSIONS = 'UserPermissions';

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { PERMISSION } from 'models';
import { sendMasterOrderTestResult } from 'redux/orders/actionCreators';
import { userHasPermission } from 'utils/permissionUtils';

import { IconButton } from '@mui/material';
import { ImageViewer, Spacer, TextInput } from 'atoms';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import EditNonLogicalResultModal from './EditNonLogicalResultModal';

const EditResultTextField = ({
  refRangeType,
  refOptions,
  attachmentUrl,
  masterOrderId,
  testId,
  subTestId,
  result: initialResult,
  canEdit,
  onSubmit,
}) => {
  const [editing, setEditing] = React.useState(false);
  const [result, setResult] = React.useState(initialResult);

  React.useEffect(() => {
    setResult(initialResult);
  }, [initialResult]);

  const dispatch = useDispatch();

  const onSubmitResult = () => {
    const sub_service_id = subTestId || {};
    const payload = {
      order: {
        result,
        ...{ sub_service_id }
      }
    };
    dispatch(sendMasterOrderTestResult(masterOrderId, testId, payload)).then(() => {
      if (onSubmit) onSubmit();
    });
    setEditing(false);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
      <TextInput
        value={result}
        onChange={(e) => setResult(e.target.value)}
        disabled={!editing}
      />
      {canEdit && userHasPermission(PERMISSION.UPDATE_RESULT) && (
        <>
          {!editing && (
            <>
              <Spacer width={16} />
              <IconButton onClick={() => setEditing(true)}>
                <EditIcon style={{ color: 'black' }} />
              </IconButton>
              <Spacer width={16} />
              {attachmentUrl && <ImageViewer imageUrl={attachmentUrl} />}
            </>
          )}
          {editing && (
            <>
              {refRangeType === 'logical' ? (
                <CheckIcon
                  style={{ cursor: 'pointer' }}
                  onClick={onSubmitResult}
                />
              ) : (
                <EditNonLogicalResultModal
                  existingResult={result}
                  existingImage={attachmentUrl}
                  masterOrderId={masterOrderId}
                  testId={testId}
                  subTestId={subTestId}
                  refOptions={refOptions}
                  onClose={() => {
                    setEditing(false);
                    onSubmit();
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

EditResultTextField.defaultProps = {
  subTestId: null,
};

EditResultTextField.propTypes = {
  refRangeType: PropTypes.string,
  refOptions: PropTypes.arrayOf(PropTypes.shape()),
  masterOrderId: PropTypes.string,
  attachmentUrl: PropTypes.string,
  testId: PropTypes.string,
  subTestId: PropTypes.string,
  result: PropTypes.string,
  canEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default EditResultTextField;

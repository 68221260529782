import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {valueGetter} from 'utils/uiHelpers';
import {getReportUrl} from 'redux/report/actionCreators';

import {Box} from '@mui/material';

const FinanceReport = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const selectedOption = pathname.split('/')[2];
  const [url, setUrl] = useState('');

  const fetchReportUrl = () => {
    dispatch(getReportUrl(selectedOption.split('-')[0])).then((resp) => {
      setUrl(valueGetter(resp, 'value.data.data'));
    });
  };

  useEffect(() => {
    fetchReportUrl();
  }, [selectedOption]);

  return (
    <Box height="calc(100vh - 11rem)">
      <iframe
        style={{border: 'none'}}
        title={selectedOption}
        src={url}
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default FinanceReport;
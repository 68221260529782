const LandingPage = {
  // *

  // A
  about_us: 'عن لابي',
  about_us_page_header: 'مرحبًا بك في لابي - حيث يلتقي الابتكار بالتأثير، وحيث يكون نجاحك على رأس أولوياتنا.',
  about_us_page_subheader: 'في لابي، نحن أكثر من مجرد مزود برامج - نحن شريكك الموثوق به في رحلتك نحو النجاح. سواء كنت مختبرًا صغيرًا مستقلاً أو مؤسسة رعاية صحية كبيرة،نحن هنا لدعمك في كل خطوة على الطريق. بدءًا من التنفيذ السلس ووصولاً إلى الدعم والتدريب المستمرين، نحن ملتزمون بمساعدتك في إطلاق الإمكانات الكاملة لمختبرك',
  about_us_section_header: 'عن المعمل',
  about_us_section_description_1: 'استمتع براحة التحكم الفريدة من نوعها مع لابي من خلال لوحة التحكم البديهية. التحليلات في الوقت الحقيقي والمؤشرات الرئيسية للأداء متاحة بسهولة، مما يعزز الكفاءة في إدارة المختبر.',
  about_us_section_description_2: 'استمتع بسهولة إدارة المواعيد وسجلات المرضى والفوترة في مركز واحد.',

  // B

  // C

  // D

  // E

  // F
  features_section_header: 'الميزات',
  features_section_subheader: 'اكتشف جميع الميزات الموجودة في لابي، فالأمر كله يتعلق بتنمية أعمالك!',
  features_title_1: 'لوحة تحكم المختبر',
  features_title_2: 'إدارة المخزون',
  features_title_3: 'باقات المختبر قابلة للتخصيص',
  features_description_1: 'استمتع بسهولة إدارة المواعيد وسجلات المرضى والفوترة في مركز واحد',
  features_description_2: 'إدارة فعّالة للإمدادات، توقع دقيق، وتقليل الفاقد يضمنان تسير عمليات المختبر بسلاسة',
  features_description_3: 'فاعلية العملاء باستخدام باقات المختبر القابلة للتخصيص من لابي للمناسبات الخاصة مثل اليوم الوطني وعيد الأم، مما يخلق عروضًا فريدة وملائمة.',
  faq_section_header: 'الأسئلة الشائعة',
  faq_question_1: 'ما هو لابي وبماذا يفيد المختبرات الطبية؟',
  faq_question_2: 'ما هي الميزات الرئيسية التي يقدمها لابي لإدارة المختبرات الطبية؟',
  faq_question_3: 'هل يمكن لـ لابي التكامل مع الأنظمة والآلات الموجودة؟',
  faq_question_4: 'كيف تقدم لابي الدعم والتدريب لمستخدميها؟',
  faq_question_5: 'كيف يمكن للمختبرات البدء باستخدام لابي؟',
  faq_question_6: ' كيف تضمن لابي أمان وخصوصية البيانات الطبية الحساسة؟',
  faq_answer_1: 'لابي عبارة عن منصة  مصممة خصيصًا لإدارة المختبرات الطبية بكفاءة. فهو يساعد على تبسيط العمليات المعملية وتحسين سير العمل وإدارة المخزون وتعزيز الكفاءة التشغيلية الشاملة.',
  faq_answer_2: 'يقدم لابي مجموعة شاملة من الميزات بما في ذلك إدارة المستخدمين وإعداد تقارير النتائج وإدارة المخزون والجدولة وتتبع العينات والتحليلات. كما يوفر أيضًا مسارات عمل قابلة للتخصيص وموقعًا إلكترونيًا للعميل ليناسب الاحتياجات المحددة لأنواع مختلفة من المختبرات.',
  faq_answer_3: 'نعم، تم تصميم لابي للتكامل بسلاسة مع الأنظمة الموجودة مثل أنظمة حصن. إلى جانب التكامل السلس مع أجهزة المختبر، يساعد هذا التكامل في تقليل الاضطرابات ويسمح بتبادل البيانات بسلاسة بين الأنظمة والأجهزة المختلفة.',
  faq_answer_4: 'يقدم لابي خيارات دعم على مدار الساعة طوال أيام الأسبوع، بما في ذلك الوثائق والموارد عبر الإنترنت وقنوات خدمة العملاء لمساعدة المستخدم. بالإضافة إلى ذلك، تتوفر جلسات تدريبية وبرامج تعليمية لمساعدة المستخدمين على الانضمام بسرعة وزيادة قدرات النظام الأساسي.',
  faq_answer_5: 'يمكن للمختبرات البدء مع لابي عن طريق الاتصال بفريق المبيعات أو الاشتراك في النسخة التجريبية على موقع لابي. تتضمن عملية الإعداد عادةً إعداد النظام الأساسي وتكوين الإعدادات وتوفير التدريب للمستخدمين لضمان الانتقال السلس.',
  faq_answer_6: 'يدير لابي البيانات بكفاءة ويضمن الوصول الكامل والملكية للمختبرات إلى بياناتها الخاصة.',

  // G
  get_started: 'ابدأ',
  get_in_touch_section_description: 'أسئلة أو تعليقات أو اقتراحات؟ ما عليك سوى ملء النموذج وسنتواصل معك قريبًا.',

  // H
  hero_section_title_patient: 'احصل على نتائج التحاليل عن طريق',
  hero_section_title_underline_patient: 'الاشعارات',
  hero_section_subtitle_patient: 'تسهيل عمليات المختبر الخاص بك، وتعزيز رعاية المرضى، ورفع مستوى ممارستك إلى آفاق جديدة.',
  hiw_section_header: 'كيف يعمل لابي',
  hiw_section_1_card_1_title: 'قم بإنشاء ملف مختبرك',
  hiw_section_1_card_2_title: 'إدارة مختبرك',
  hiw_section_1_card_3_title: 'تفاعل مع إحصائيات مختبرك',
  hiw_section_1_card_1_description: 'نوفر لك القدرة على التزامن مع وزارة الصحة من خلال HESN. يمكنك توقع الدعم الفعّال والسريع عندما تحتاج إلينا.',
  hiw_section_1_card_2_description: 'سجّل العيادات ومراكز التحاليل تحت مختبرك واحصل على حجز المرضى منها',
  hiw_section_1_card_3_description: 'احصل على رؤى حول السمات الديموغرافية للمرضى والمواعيد لتعزيز نجاح مختبرك',
  hiw_section_2_card_1_title: 'نظام ادارة المرضى المركزي',
  hiw_section_2_card_2_title: 'حسّن عمليات مختبرك',
  hiw_section_2_card_3_title: 'نظام مبسط',
  hiw_section_2_card_1_description: 'التعامل مع عدد كبير من المرضى دون جهد باستخدام نظام إدارة المرضى المركزي الخاص بلابي.',
  hiw_section_2_card_2_description: 'قم بتحسين عمليات المختبر الخاصة بك وتقليل التكاليف ورفع الكفاءة باستخدام حلول لابي الشاملة.',
  hiw_section_2_card_3_description: 'إدارة أكثر من مليون عينة بسهولة وكفاءة باستخدام نظامنا المبسّط.',

  // I

  // J

  // K
  keep_in_touch_section_header: 'ابق على تواصل',
  keep_in_touch_section_description: 'هناك العديد من الأشكال المتوفرة لنصوص لوريم إيبسوم، لكن الأغلبية قد عانت من التغيير بشكل ما، عن طريق إدخال الفكاهة أو الكلمات العشوائية التي لا تبدو قابلة للتصديق ولو قليلاً.',

  // L

  // M

  // N

  // O
  our_mission_title: 'مهمتنا',
  our_mission_description_1: 'في لابي، نحن أكثر من مجرد مزود برامج - نحن شريكك الموثوق به في رحلتك نحو النجاح. سواء كنت مختبرًا صغيرًا مستقلاً أو مؤسسة رعاية صحية كبيرة،نحن هنا لدعمك في كل خطوة على الطريق. بدءًا من التنفيذ السلس ووصولاً إلى الدعم والتدريب المستمرين، نحن ملتزمون بمساعدتك في إطلاق الإمكانات الكاملة لمختبرك',
  our_mission_description_2: 'ما يميز لابي هو التزامنا الثابت بالتميز والابتكار ورضا العملاء. نحن نتعاون بشكل وثيق مع المتخصصين في المختبرات لفهم نقاط الضعف والتحديات والتطلعات التي يواجهونها، مما يضمن أن حلولنا ليست قوية من الناحية الفنية فحسب، بل تتوافق أيضًا بشكل عميق مع احتياجات العالم الحقيقي لمستخدمينا.',
  our_mission_description_3: 'مهمتنا هي تمكين المختبرات من جميع الأحجام والتخصصات بالأدوات التي تحتاجها للتميز. نحن نؤمن أنه من خلال الاستفادة من أحدث التطورات في التكنولوجيا وتحليلات البيانات والأتمتة، لا تستطيع المختبرات تحسين عملياتها فحسب، بل يمكنها أيضًا تحقيق نتائج أفضل للمرضى.',

  // P

  // Q

  // R
  request_demo: 'اطلب عرض تجريبي',

  // S
  services_section_header: 'خدماتنا',
  services_title_1: 'تحليل PCR',
  services_title_2: 'الباقات',
  services_title_3: 'التحاليل الروتينية',
  services_description_1: 'اختبار للكشف عن المادة الوراثية من كائن حي معين، مثل الفيروس. يكتشف الاختبار وجود فيروس إذا كان لديك الفيروس وقت الاختبار.',
  services_description_2: 'المساعدة في تحديد حالة النقص أو المشكلة. قد تساعدك حزم الاختبارات المعملية الخاصة بـ Medicover، سواء كان هدفك هو التعافي في أسرع وقت ممكن أو الوقاية من المرض.',
  services_description_3: 'اختبار للكشف عن المادة الوراثية من كائن حي معين، مثل الفيروس. يكتشف الاختبار وجود فيروس إذا كان لديك الفيروس وقت الاختبار.',

  // T

  // U

  // V

  // W

  // X

  // Y

  // Z
};

export default LandingPage;

import React, {useState} from 'react';
import {Form} from 'react-final-form';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {composeValidators, email, maxLength, minLength, number, required} from 'utils/FormValidations';

import {Grid} from '@mui/material';
import {CardContainer, FieldInfoCardList, SectionTitle} from 'templates';
import PropTypes from 'prop-types';

const ProfileForm = ({onSave}) => {
  const [isEdit, setIsEdit] = useState(false);
  const {userProfile} = useSelector(state => state.profile);
  const userInfoFields = [
    {
      name: 'name_en',
      label: i18n.t('name_en'),
      placeholder: i18n.t('name_en'),
      validate: required,
      disabled: !isEdit,
      value: userProfile.name_en,
    },
    {
      name: 'name_ar',
      label: i18n.t('name_ar'),
      placeholder: i18n.t('name_ar'),
      validate: required,
      disabled: !isEdit,
      value: userProfile.name_ar,
    },
    {
      name: 'mobile',
      label: i18n.t('mobile_number'),
      placeholder: i18n.t('mobile_number'),
      validate: composeValidators(required, number, maxLength(9), minLength(9)),
      disabled: !isEdit,
      value: userProfile.mobile,
    },
    {
      name: 'email',
      label: i18n.t('email'),
      placeholder: i18n.t('email'),
      validate: composeValidators(required, email),
      disabled: !isEdit,
      value: userProfile.email,
    },
  ];

  const handleEdit = (values) => {
    if (isEdit) {
      onSave(values);
    }
    setIsEdit(!isEdit);
  };

  return (
    <Form onSubmit={onSave} initialValues={userProfile}>
      {({values}) => (
        <form>
          <CardContainer
            noPadding
          >
            <SectionTitle
              titleKey="my_details"
              showEdit
              editKey={isEdit ? 'save' : 'edit_info'}
              onEdit={() => handleEdit(values)}
            />
            <Grid container padding={2}>
              <FieldInfoCardList list={userInfoFields}/>
            </Grid>
          </CardContainer>
        </form>
      )}
    </Form>
  );
};

ProfileForm.propTypes = {
  onSave: PropTypes.func
};

export default ProfileForm;
import React from 'react';
import PropTypes from 'prop-types';
import {Menu, MenuItem} from '@mui/material';
import i18n from 'translation/i18n';
import {Flex, Button, Spacer} from 'atoms';
import {ReactComponent as MenuIcon} from 'assets/icons/common/more-vertical.svg';
import {activatingText, activatingTextColor} from 'utils/labUtils';
import {LAB_STATUS, LAB_ACTIONS, ACTIVE_STATUS} from 'models';
import styles from './labs.module.css';

const LabTableActions = (props) => {
  const {onOptionClick, data} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeValue, setActiveValue] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelected = (type) => {
    if(type.activated !== undefined){
      setActiveValue(type.activated);
      onOptionClick({...data, ...type}, LAB_ACTIONS.ACTIVATION);
      return setAnchorEl(null);
    }
    onOptionClick(data, type);
    return setAnchorEl(null);
  };

  React.useEffect(() => {
    if(data.status !== undefined){
      setActiveValue(data.status === ACTIVE_STATUS.ACTIVATED);
    }
  }, [data.status]);

  return (
    <div>
      <MenuIcon
        id="more-pic"
        aria-label="more"
        aria-controls={open ? 'more-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{padding: '12px 36px'}}
      />
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'more-pic'}}
      >
        {data.status === LAB_STATUS.PENDING && (
          <MenuItem className={styles.hideItemActions}>
            <Flex justifyBetween>
              <Button
                size="small"
                label={i18n.t('accept')} 
                variant="secondary"
                onClick={() => handleItemSelected(LAB_ACTIONS.ACCEPT)}
              />
              <Spacer width={8}/>
              <Button
                size="small"
                label={i18n.t('reject')} 
                variant="danger"
                onClick={() => handleItemSelected(LAB_ACTIONS.REJECT)}
              />
            </Flex>
            <Spacer height={16}/>
          </MenuItem>
        )}
        {(![LAB_STATUS.PENDING, LAB_STATUS.REJECTED].includes(data.status)) && (
          <MenuItem className={styles.hideItemActions}>
            <Flex justifyCenter style={{width: '100%', padding: '1px 8px'}}>
              <Button 
                size="small"
                label={activatingText[activeValue]} 
                variant={activatingTextColor[activeValue]}
                onClick={() => {
                  const activated = !activeValue;
                  return handleItemSelected({activated});
                }}
              />
            </Flex>
            <Spacer height={16}/>
          </MenuItem>
        )}
        {/* <MenuItem className={styles.hideItemActions}>
          <Flex justifyCenter style={{width: '100%', padding: '1px 8px'}}>
            <LinkButton
              size="small"
              label={i18n.t('delete')} 
              variant="danger"
              onClick={() => handleItemSelected(LAB_ACTIONS.DELETE)}
            />
          </Flex>
        </MenuItem> */}
      </Menu>
    </div>
  );
};

LabTableActions.propTypes = {
  onOptionClick: PropTypes.func,
  data: PropTypes.shape(),
};

export default LabTableActions;

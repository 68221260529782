import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {Spacer, Divider, Button} from 'atoms';
import i18n from 'translation/i18n';
import {ModalContent} from 'templates';
import {CurrentStep} from '.';

const CreateLabForm = (props) => {
  const {onSubmit, initialValues, activeStep, onPlanChoosed} = props;

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <CurrentStep 
            activeStep={activeStep} 
            onPlanChoosed={onPlanChoosed}
          />
          {activeStep > 0 && (
            <>
              <Spacer height={32}/>
              <Divider/>
              <Spacer height={8}/>
              <ModalContent>
                <Button type="submit" label={activeStep === 2 ? i18n.t('submit'): i18n.t('next')}/>
              </ModalContent>
            </>
          )}
        </form>
      )}
    </Form>
  );
};

CreateLabForm.propTypes = {
  onSubmit: PropTypes.func,
  onPlanChoosed: PropTypes.func,
  initialValues: PropTypes.shape(),
  activeStep: PropTypes.number,
};

export default CreateLabForm;

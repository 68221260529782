import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { ReactComponent as Arrow } from 'assets/icons/common/arrow-filled-down.svg';
import { Text } from 'atoms';
import { Option } from 'models';
import PropTypes from 'prop-types';
import './HeaderFilter.scss';

const HeaderFilter = ({ options, selectedOption, onChange }) => {
  const menuProps = {
    classes: {
      paper: 'header-filter-popover',
      list: 'header-filter-options',
    },
  };

  return (
    <Select
      className="header-filter"
      value={selectedOption}
      variant="standard"
      IconComponent={Arrow}
      MenuProps={menuProps}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Text typography="paragraph14" weight="bold">
            {option.label}
          </Text>
        </MenuItem>
      ))}
    </Select>
  );
};

HeaderFilter.propTypes = {
  selectedOption: PropTypes.string,
  options: PropTypes.arrayOf(Option),
  onChange: PropTypes.func,
};

export default HeaderFilter;

import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import { getLabSubscriptionInvoices } from 'redux/admin/actionCreators';
import { valueGetter } from 'utils/uiHelpers';
import { formattedPrice } from 'utils/labUtils';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';

import {DataTable, Modal} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';
import LabInvoiceActions from './LabInvoiceActions';

const SubscriptionInvoicesModal = ({subscriptionId, onClose}) => {
  const dispatch = useDispatch();
  const { subscription_invoices, fetching } = useSelector((state) => state.admin);

  const loadInvoices = () => {
    dispatch(getLabSubscriptionInvoices({
      service_provider_subscription_id: subscriptionId,
    }));
  };

  const columns = [
    {
      field: 'invoice_number',
      headerName: i18n.t('invoice_number'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'sub_total',
      headerName: i18n.t('total_amount'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => formattedPrice(valueGetter(row, field), 'saudi_riyal')
    },
    {
      field: 'amount_paid',
      headerName: i18n.t('paid_amount'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => formattedPrice(valueGetter(row, field), 'saudi_riyal')
    },
    {
      field: 'issue_date',
      headerName: i18n.t('issue_date'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => format(valueGetter(row, field), DISPLAY_DATE_FORMAT)
    },
    {
      field: 'actions',
      headerName: i18n.t('actions'),
      minWidth: 100,
      maxWidth: 100,
      renderCell: ({row}) => <LabInvoiceActions data={row} onDone={loadInvoices}/>,
    },
  ];

  React.useEffect(() => {
    loadInvoices();
  }, []);
  
  return (
    <Modal
      disableCloseOut
      isOpen
      width="75vw"
      header={<ModalHeader title={i18n.t('invoices')} />}
      onClose={onClose}
    >
      <ModalContent>
        <DataTable
          columns={columns}
          rows={subscription_invoices || []}
          loading={fetching}
        />
      </ModalContent>
    </Modal>
  );
};

SubscriptionInvoicesModal.propTypes = {
  subscriptionId: PropTypes.string,
  onClose: PropTypes.func,
};

export default SubscriptionInvoicesModal;
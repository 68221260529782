export const LOCATIONS = 'LOCATIONS';
export const LOCATIONS_PENDING = 'LOCATIONS_PENDING';
export const LOCATIONS_FULFILLED = 'LOCATIONS_FULFILLED';
export const LOCATIONS_REJECTED = 'LOCATIONS_REJECTED';

export const SAVE_LOCATION = 'SAVE_LOCATION';
export const SAVE_LOCATION_PENDING = 'SAVE_LOCATION_PENDING';
export const SAVE_LOCATION_FULFILLED = 'SAVE_LOCATION_FULFILLED';
export const SAVE_LOCATION_REJECTED = 'SAVE_LOCATION_REJECTED';

export const STOCKS = 'STOCKS';
export const STOCKS_PENDING = 'STOCKS_PENDING';
export const STOCKS_FULFILLED = 'STOCKS_FULFILLED';
export const STOCKS_REJECTED = 'STOCKS_REJECTED';

export const SAVE_STOCK = 'SAVE_STOCK';
export const SAVE_STOCK_PENDING = 'SAVE_STOCK_PENDING';
export const SAVE_STOCK_FULFILLED = 'SAVE_STOCK_FULFILLED';
export const SAVE_STOCK_REJECTED = 'SAVE_STOCK_REJECTED';

export const STOCK_DETAILS = 'STOCK_DETAILS';
export const STOCK_DETAILS_PENDING = 'STOCK_DETAILS_PENDING';
export const STOCK_DETAILS_FULFILLED = 'STOCK_DETAILS_FULFILLED';
export const STOCK_DETAILS_REJECTED = 'STOCK_DETAILS_REJECTED';

export const STOCK_OPTIONS = 'STOCK_OPTIONS';
export const STOCK_OPTIONS_PENDING = 'STOCK_OPTIONS_PENDING';
export const STOCK_OPTIONS_FULFILLED = 'STOCK_OPTIONS_FULFILLED';
export const STOCK_OPTIONS_REJECTED = 'STOCK_OPTIONS_REJECTED';

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_PENDING = 'DOWNLOAD_REPORT_PENDING';
export const DOWNLOAD_REPORT_FULFILLED = 'DOWNLOAD_REPORT_FULFILLED';
export const DOWNLOAD_REPORT_REJECTED = 'DOWNLOAD_REPORT_REJECTED';
import Overview from 'routers/authorized/OverviewRoutes';
import Reservation from 'routers/authorized/ReservationRoutes';
import Orders from 'routers/authorized/OrdersRoutes';
import OverdueTests from 'routers/authorized/OverdueTestsRoutes';
import Billing from 'routers/authorized/BillingRoutes';
import Invoices from 'routers/authorized/InvoicesRoutes';
import Reports from 'routers/authorized/ReportRoutes';
import Inventory from 'routers/authorized/InventoryRoutes';
import Users from 'routers/authorized/UsersRoutes';
import Clients from 'routers/authorized/ClientsRoutes';
import Tests from 'routers/authorized/TestRoutes';
import Package from 'routers/authorized/PackageRoutes';
import Share from 'routers/authorized/ShareRoutes';
import ProfileRoutes from 'routers/authorized/ProfileRoutes';
import SerialPage from 'container/labs/Serial';

const routes = [
  {
    path: '/overview',
    component: Overview,
  },
  {
    path: '/lab-profile',
    component: ProfileRoutes,
  },
  {
    path: '/user-profile',
    component: ProfileRoutes,
  },
  {
    path: '/reservations',
    component: Reservation,
  },
  {
    path: '/orders',
    component: Orders,
  },
  {
    path: '/pcr-batches',
    component: Orders,
  },
  {
    path: '/overdue-tests',
    component: OverdueTests,
  },
  {
    path: '/billing',
    component: Billing,
  },
  {
    path: '/invoices',
    component: Invoices,
  },
  {
    path: '/reports',
    component: Reports,
  },
  {
    path: '/inventory',
    component: Inventory,
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/clients',
    component: Clients,
  },
  {
    path: '/tests',
    component: Tests,
  },
  {
    path: '/packages',
    component: Package,
  },
  {
    path: '/share',
    component: Share,
  },
  {
    path: '/serial_settings',
    component: SerialPage,
  },
];

export default routes;

import React from 'react';
import {Route, Switch} from 'react-router-dom';

import { Inventory, InventoryDetail } from 'container/labs/Inventory';

const InventoryRoutes = () => (
  <Switch>
    <Route exact path="/inventory/stocks/:id" component={InventoryDetail}/>
    <Route exact path="/inventory" component={Inventory}/>
  </Switch>
);

export default InventoryRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { TextareaAutosize } from '@mui/material';
import { Spacer, Text } from 'atoms';

const TextAreaInputField = ({
  validate,
  onChange,
  onBlur,
  style,
  name,
  label,
  ...props
}) => {
  const getStatus = (meta) => {
    if (meta.error) {
      return meta.touched ? 'error' : undefined;
    }
    return undefined;
  };

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <>
          {!!label && (
            <>
              <Text
                typography="caption12"
                as="span"
                weight="bold"
                color="var(--brand-text-gray-light)"
                textCase="uppercase"
              >
                {label}
              </Text>
              <Spacer height={4} />
            </>
          )}
          <TextareaAutosize
            status={getStatus(meta)}
            statusMessage={meta.error}
            minRows={5}
            style={{ marginBottom: 8, marginTop: 8, width: '150%', ...style }}
            {...props}
            {...input}
            onChange={({ target }) => {
              if (onChange) { onChange(target.value); }
              input.onChange(target.value);
            }}
            onBlur={onBlur}
          />
        </>
      )}
    </Field>
  );
};

TextAreaInputField.defaultProps = {
  label: '',
};

TextAreaInputField.propTypes = {
  label: PropTypes.string,
  style: PropTypes.shape(),
  validate: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  parse: PropTypes.func,
};

export default TextAreaInputField;

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {REPORT} from 'models';

import Reports from 'container/labs/Reports';
import {isLabUser} from 'utils/labUtils';

const ReportRoutes = () => {
  const defaultRoute = isLabUser() ? REPORT.USER_PERFORMANCE : REPORT.SOURCE_REPORTS;

  /*
  <Route path="/reports/invoice-finance-report" exact component={Reports}/>
  <Route path="/reports/b2b-finance-report" exact component={Reports}/>
  */

  return (
    <Switch>
      <Route path="/reports/sales" exact component={Reports}/>
      <Route path="/reports/user-performance" exact component={Reports}/>
      <Route path="/reports/invoices" exact component={Reports}/>
      <Route path="/reports/results" exact component={Reports}/>
      <Route path="/reports/source-reports" exact component={Reports}/>
      <Route path="/reports/total-amounts" exact component={Reports}/>
      <Route path="/reports/b2b-finance-report" exact component={Reports}/>
      <Redirect to={`/reports/${defaultRoute}`}/>
    </Switch>
  );
};

export default ReportRoutes;

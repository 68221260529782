import { Tests, TestDetails, PackageDetails } from 'container/admin/Tests';
import { Labs, LabDetails } from 'container/admin/Labs';
import { Subscriptions } from 'container/admin/Subscriptions';
import { Settings } from 'container/admin/Settings';
import { Profile } from 'container/admin/Profile';
import { SpecimenTypes } from 'container/admin/SpecimenTypes';

const routes = [
  {
    path: '/package/:id',
    component: PackageDetails,
  },
  {
    path: '/tests/:id',
    component: TestDetails,
  },
  {
    path: '/tests',
    component: Tests,
  },
  {
    path: '/specimen-types',
    component: SpecimenTypes,
  },
  {
    path: '/labs/:id',
    component: LabDetails,
  },
  {
    path: '/labs',
    component: Labs,
  },
  {
    path: '/subscriptions',
    component: Subscriptions,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/user-profile',
    component: Profile,
  },
];

export default routes;

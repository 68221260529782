import PropTypes from 'prop-types';
// import {Patient} from 'models';

export const Order =
  PropTypes.shape({
    patient_detail: PropTypes.shape(
      PropTypes.shape({
        name: PropTypes.string,
        mobile: PropTypes.string
      })),
    service: PropTypes.string,
    status: PropTypes.string,
    updated_at: PropTypes.string,
    overdue_by: PropTypes.number
  });

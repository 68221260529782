import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {camelCase} from 'lodash';

import styles from './Tag.module.css';

export const Tag = ({variant, color, text}) => {
  return (
    <>
      <div className={clsx(styles[camelCase(`tag-${variant}`)])}>
        <div className={clsx(styles.tagBg)} style={{backgroundColor: color}}/>
        <div className={clsx(styles.tagText)} style={{color}}>{text}</div>
      </div>
    </>
  );
};

Tag.defaultProps = {
  variant: 'normal',
  color: 'var(--brand-collect-text)'
};

Tag.propTypes = {
  variant: PropTypes.oneOf(['pill', 'normal']),
  color: PropTypes.string,
  text: PropTypes.string,
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {getRoles, getUserOptions, getUsers} from 'redux/lab/actionCreators';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import { useURLQuery } from 'hooks/useURLQuery';
import useHandelPagination from 'hooks/useHandelPagination';
import { UserFilters } from './UserFilters';
import { UserTable } from './UserTable';

export const UserList = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const filters = {
    search: query?.search,
    status: query?.status,
    role: query?.role,
  };

  useEffect(() => {
    dispatch(getUsers(filters));
    dispatch(getUserOptions());
    dispatch(getRoles());
  });

  const load = (params = {}) => dispatch(getUsers(params));
  const handleFilterChange = (f) => {
    const params = {
      search: f.search,
      status: f.status,
      role: f.role,
    };
    setQuery(params);
    dispatch(getUsers(params));
  };

  const handlePagination = useHandelPagination({
    filters,
    onPageChange: load,
  });

  const content = (
    <>
      <ListFilters Wrapped={UserFilters} onChange={handleFilterChange} />
      <UserTable onPagination={handlePagination} />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

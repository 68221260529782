import React from 'react';
import {useSelector} from 'react-redux';
import i18n from 'translation/i18n';
import {ModalContent} from 'templates';
import {TextInputField} from 'components/global/final-form';
import {Spacer, Button} from 'atoms';
import {
  composeValidators,
  shouldStartWith,
  maxLength,
  minLength,
  required,
  enNumber,
} from 'utils/FormValidations';

const ChangeNumberFields = () => {
  const {submitting} = useSelector(state => state.profile);
  return (
    <ModalContent>
      <TextInputField 
        name="new_mobile"
        label={i18n.t('new_number')}
        placeholder={i18n.t('number')}
        maxLength={9}
        validate={
          composeValidators(required, enNumber, shouldStartWith(5), maxLength(9), minLength(9))
        }
      />
      <Spacer height={112}/>
      <Button label={i18n.t('change_number')} type="submit" disabled={submitting}/>
    </ModalContent>
  );
};

export default ChangeNumberFields;

import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-final-form';
import {TestPlan} from 'components/public';
import {ModalContent} from 'templates';

const PlanStep = ({onPlanChoosed}) => {
  const {change} = useForm();

  const onDone = (data) => {
    change('subscription', { name: data.value });
    change('service_provider.service_provider_type', 'lab');
    onPlanChoosed(data);
  };

  return (
    <ModalContent>
      <TestPlan onDone={onDone} isAdmin/>
    </ModalContent>
  );
};

PlanStep.propTypes = {
  onPlanChoosed: PropTypes.func,
};

export default PlanStep;

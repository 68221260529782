import {USER_ROLE} from 'models';
import { getAuthUser } from 'redux/auth/actionCreators';
import {moduleBaseUrl ,handleAPI, API_VERSION_ONE} from 'utils/apiUtils';
import * as actions from './actions';

export function profilesShow(data) {
  const url = `${moduleBaseUrl()}/profiles`;
  const method = 'GET';

  return {
    type: actions.PROFILE_SHOW,
    payload: handleAPI(url, {}, method, data),
  };
}

export function profilesShowForAdmin(data) {
  const url = `/${API_VERSION_ONE}/profiles`;
  const method = 'GET';

  return {
    type: actions.ADMIN_PROFILE_SHOW,
    payload: handleAPI(url, {}, method, data),
  };
}

export function profilesUpdate(profile) {
  const url = `${moduleBaseUrl()}/profiles`;
  const method = 'PUT';

  return {
    type: actions.PROFILE_UPDATE,
    payload: handleAPI(url, {}, method, {profile}),
  };
}

export function profileUpdateForAdmin(profile) {
  const url = `/${API_VERSION_ONE}/admin/profile`;
  const method = 'PUT';
  const payload = {
    admin: profile
  };

  return {
    type: actions.PROFILE_UPDATE,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function changeEmail(data) {
  const url = `/${API_VERSION_ONE}/lab/setting/emails/change`;
  const method = 'PUT';

  return {
    type: actions.CHANGE_EMAIL,
    payload: handleAPI(url, {}, method, data),
  };
}

export function changeMobiles(data) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrl = isAdmin ? 'admin/profile/mobile' : 'lab/setting/mobiles/change';
  const url = `/${API_VERSION_ONE}/${apiUrl}`;
  const method = isAdmin ? 'POST' : 'PUT';

  return {
    type: actions.CHANGE_MOBILE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function verifyChangeMobiles(data) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrl = isAdmin ? 'admin/profile/mobile/verify' : 'lab/setting/mobiles/confirm';
  const url = `/${API_VERSION_ONE}/${apiUrl}`;
  const method = isAdmin ? 'POST' : 'PUT';

  return {
    type: actions.VERIFY_CHANGE_MOBILE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function changePassword(data) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrl = isAdmin ? 'admin/profile/password' : 'lab/setting/passwords';

  const url = `/${API_VERSION_ONE}/${apiUrl}`;
  const method = isAdmin ? 'POST' : 'PUT';

  return {
    type: actions.CHANGE_PASSWORD,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getUserProfile() {
  const url = `/${API_VERSION_ONE}/profiles`;
  const method = 'GET';

  return {
    type: actions.USER_PROFILE,
    payload: handleAPI(url, {}, method),
  };
}

export function updateUserProfile(payload) {
  const url = `/${API_VERSION_ONE}/profiles`;
  const method = 'PUT';

  return {
    type: actions.PROFILE_UPDATE,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function getProfileOptions() {
  const url = `/${API_VERSION_ONE}/options`;
  const method = 'GET';

  return {
    type: actions.PROFILE_OPTIONS,
    payload: handleAPI(url, {}, method),
  };
}

import React, {useEffect} from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {getRoleDetail, updateRoleDetail} from 'redux/lab/actionCreators';
import { valueGetter } from 'utils/uiHelpers';

import { Grid } from '@mui/material';
import { Button, Card, MultiSelect, Spacer, Text } from 'atoms';

const RoleDetail = ({ id, name }) => {
  const dispatch = useDispatch();
  const [allPermissions, setAllPermissions] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [disableSave, setDisableSave] = React.useState(true);

  const getRole = () => {
    dispatch(getRoleDetail(id)).then(({value})=> {
      const data = (valueGetter(value, 'data.role') || []).map(p => ({label: p.permission_label, value: p.id, selected: p.available}));
      setAllPermissions(data);
      setPermissions(data.filter(p => p.selected));
    });
  };

  const handleSave = () => {
    setDisableSave(true);
    const payload = { permission_ids: permissions.map(p => p.value) };
    dispatch(updateRoleDetail(id, payload)).catch(() => {
      setDisableSave(false);
    });
  };

  const handleChange = (data) => {
    setDisableSave(false);
    setPermissions(data);
  };

  useEffect(() => {
    getRole();
  }, []);

  return (
    <Card styles={{margin: `${isMobile ? '2rem 1rem' : '2rem'}`, padding: '1rem 1.5rem'}}>
      <Text typography="subtitle" weight="bold" textCase="capitalize">{name}</Text>
      <Spacer height={8}/>
      <Grid container gap={2}>
        <Grid item xs={12} lg={9.5}>
          <MultiSelect noOptionsMessage={() => 'All permission added'} value={permissions} onChange={handleChange} options={allPermissions}/>
        </Grid>
        <Grid item xs={2}>
          <Button label={i18n.t('update')} disabled={disableSave} onClick={handleSave}/>
        </Grid>
      </Grid>
    </Card>
  );
};

RoleDetail.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string
};

export default RoleDetail;
export const SERVICE_UPDATE = 'SERVICE_UPDATE';
export const SERVICE_UPDATE_PENDING = 'SERVICE_UPDATE_PENDING';
export const SERVICE_UPDATE_FULFILLED = 'SERVICE_UPDATE_FULFILLED';
export const SERVICE_UPDATE_REJECTED = 'SERVICE_UPDATE_REJECTED';

export const OVERVIEW_STATS = 'OVERVIEW_STATS';
export const OVERVIEW_STATS_PENDING = 'OVERVIEW_STATS_PENDING';
export const OVERVIEW_STATS_FULFILLED = 'OVERVIEW_STATS_FULFILLED';
export const OVERVIEW_STATS_REJECTED = 'OVERVIEW_STATS_REJECTED';

export const OVERDUE_TESTS_LIST = 'OVERDUE_TESTS_LIST';
export const OVERDUE_TESTS_LIST_PENDING = 'OVERDUE_TESTS_LIST_PENDING';
export const OVERDUE_TESTS_LIST_FULFILLED = 'OVERDUE_TESTS_LIST_FULFILLED';
export const OVERDUE_TESTS_LIST_REJECTED = 'OVERDUE_TESTS_LIST_REJECTED';

export const MASTER_RESERVATION_LIST = 'MASTER_RESERVATION_LIST';
export const MASTER_RESERVATION_LIST_PENDING =
  'MASTER_RESERVATION_LIST_PENDING';
export const MASTER_RESERVATION_LIST_FULFILLED =
  'MASTER_RESERVATION_LIST_FULFILLED';
export const MASTER_RESERVATION_LIST_REJECTED =
  'MASTER_RESERVATION_LIST_REJECTED';

export const RESERVATION_INFO = 'RESERVATION_INFO';
export const RESERVATION_INFO_PENDING = 'RESERVATION_INFO_PENDING';
export const RESERVATION_INFO_FULFILLED = 'RESERVATION_INFO_FULFILLED';
export const RESERVATION_INFO_REJECTED = 'RESERVATION_INFO_REJECTED';

export const MASTER_RESERVATION_INFO = 'MASTER_RESERVATION_INFO';
export const MASTER_RESERVATION_INFO_PENDING =
  'MASTER_RESERVATION_INFO_PENDING';
export const MASTER_RESERVATION_INFO_FULFILLED =
  'MASTER_RESERVATION_INFO_FULFILLED';
export const MASTER_RESERVATION_INFO_REJECTED =
  'MASTER_RESERVATION_INFO_REJECTED';

export const UPDATE_MASTER_RESERVATION_INFO = 'UPDATE_MASTER_RESERVATION_INFO';
export const UPDATE_MASTER_RESERVATION_INFO_PENDING =
  'UPDATE_MASTER_RESERVATION_INFO_PENDING';
export const UPDATE_MASTER_RESERVATION_INFO_FULFILLED =
  'UPDATE_MASTER_RESERVATION_INFO_FULFILLED';
export const UPDATE_MASTER_RESERVATION_INFO_REJECTED =
  'UPDATE_MASTER_RESERVATION_INFO_REJECTED';

export const MASTER_RESERVATION_TESTS = 'MASTER_RESERVATION_TESTS';
export const MASTER_RESERVATION_TESTS_PENDING =
  'MASTER_RESERVATION_TESTS_PENDING';
export const MASTER_RESERVATION_TESTS_FULFILLED =
  'MASTER_RESERVATION_TESTS_FULFILLED';
export const MASTER_RESERVATION_TESTS_REJECTED =
  'MASTER_RESERVATION_TESTS_REJECTED';

export const MASTER_RESERVATION_TEST_INFO = 'MASTER_RESERVATION_TEST_INFO';
export const MASTER_RESERVATION_TEST_INFO_PENDING =
  'MASTER_RESERVATION_TEST_INFO_PENDING';
export const MASTER_RESERVATION_TEST_INFO_FULFILLED =
  'MASTER_RESERVATION_TEST_INFO_FULFILLED';
export const MASTER_RESERVATION_TEST_INFO_REJECTED =
  'MASTER_RESERVATION_TEST_INFO_REJECTED';

export const UPDATE_MASTER_ORDER_TEST_INFO = 'UPDATE_MASTER_ORDER_TEST_INFO';
export const UPDATE_MASTER_ORDER_TEST_INFO_PENDING =
  'UPDATE_MASTER_ORDER_TEST_INFO_PENDING';
export const UPDATE_MASTER_ORDER_TEST_INFO_FULFILLED =
  'UPDATE_MASTER_ORDER_TEST_INFO_FULFILLED';
export const UPDATE_MASTER_ORDER_TEST_INFO_REJECTED =
  'UPDATE_MASTER_ORDER_TEST_INFO_REJECTED';

export const USER_LIST = 'USER_LIST';
export const USER_LIST_PENDING = 'USER_LIST_PENDING';
export const USER_LIST_FULFILLED = 'USER_LIST_FULFILLED';
export const USER_LIST_REJECTED = 'USER_LIST_REJECTED';

export const USER_OPTIONS = 'USER_OPTIONS';
export const USER_OPTIONS_PENDING = 'USER_OPTIONS_PENDING';
export const USER_OPTIONS_FULFILLED = 'USER_OPTIONS_FULFILLED';
export const USER_OPTIONS_REJECTED = 'USER_OPTIONS_REJECTED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const GET_USER = 'GET_USER';
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_FULFILLED = 'GET_USER_FULFILLED';
export const GET_USER_REJECTED = 'GET_USER_REJECTED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';

export const CLINIC_LIST = 'CLINIC_LIST';
export const CLINIC_LIST_PENDING = 'CLINIC_LIST_PENDING';
export const CLINIC_LIST_FULFILLED = 'CLINIC_LIST_FULFILLED';
export const CLINIC_LIST_REJECTED = 'CLINIC_LIST_REJECTED';

export const CLINIC_INFO = 'CLINIC_INFO';
export const CLINIC_INFO_PENDING = 'CLINIC_INFO_PENDING';
export const CLINIC_INFO_FULFILLED = 'CLINIC_INFO_FULFILLED';
export const CLINIC_INFO_REJECTED = 'CLINIC_INFO_REJECTED';

export const FILTER_CLIENT_TEST = 'FILTER_CLIENT_TEST';

export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const UPDATE_CLINIC_PENDING = 'UPDATE_CLINIC_PENDING';
export const UPDATE_CLINIC_FULFILLED = 'UPDATE_CLINIC_FULFILLED';
export const UPDATE_CLINIC_REJECTED = 'UPDATE_CLINIC_REJECTED';

export const ACTIVE_CLINICS = 'ACTIVE_CLINICS';
export const ACTIVE_CLINICS_PENDING = 'ACTIVE_CLINICS_PENDING';
export const ACTIVE_CLINICS_FULFILLED = 'ACTIVE_CLINICS_FULFILLED';
export const ACTIVE_CLINICS_REJECTED = 'ACTIVE_CLINICS_REJECTED';

export const ACTIVE_CENTERS = 'ACTIVE_CENTERS';
export const ACTIVE_CENTERS_PENDING = 'ACTIVE_CENTERS_PENDING';
export const ACTIVE_CENTERS_FULFILLED = 'ACTIVE_CENTERS_FULFILLED';
export const ACTIVE_CENTERS_REJECTED = 'ACTIVE_CENTERS_REJECTED';

export const INDEX_MASTER_ORDER_ORDERS = 'INDEX_MASTER_ORDER_ORDERS';
export const INDEX_MASTER_ORDER_ORDERS_PENDING =
  'INDEX_MASTER_ORDER_ORDERS_PENDING';
export const INDEX_MASTER_ORDER_ORDERS_FULFILLED =
  'INDEX_MASTER_ORDER_ORDERS_FULFILLED';
export const INDEX_MASTER_ORDER_ORDERS_REJECTED =
  'INDEX_MASTER_ORDER_ORDERS_REJECTED';

export const SYNC_CLINIC_TESTS = 'SYNC_CLINIC_TESTS';
export const SYNC_CLINIC_TESTS_PENDING = 'SYNC_CLINIC_TESTS_PENDING';
export const SYNC_CLINIC_TESTS_FULFILLED = 'SYNC_CLINIC_TESTS_FULFILLED';
export const SYNC_CLINIC_TESTS_REJECTED = 'SYNC_CLINIC_TESTS_REJECTED';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_PENDING = 'GET_ROLES_PENDING';
export const GET_ROLES_FULFILLED = 'GET_ROLES_FULFILLED';
export const GET_ROLES_REJECTED = 'GET_ROLES_REJECTED';

export const GET_ROLE_DETAIL = 'GET_ROLE_DETAIL';


export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_PLANS_FULFILLED = 'GET_PLANS_FULFILLED';
export const GET_PLANS_REJECTED = 'GET_PLANS_REJECTED';

export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED';
import React from 'react';
// import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

const TashkhesLoader = ({
  ...rest
}) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <CircularProgress {...rest} />
  </Box>
);

TashkhesLoader.propTypes = {

};

export default TashkhesLoader;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import progressStyles from './ProgressBar.module.css';
import Text from '../Text';

const textClasses = {
  en: 'caption12',
  ar: 'caption12',
};

const ProgressBar = ({
  value,
  width,
  height,
  title,
  locale,
}) => (
  <div
    className={clsx(
      progressStyles.container,
      textClasses[locale],
    )}
  >
    {title && <Text className={progressStyles.title} typography="caption12">{title}</Text>}
    <div className={progressStyles.progressBar} style={{ width, height }}>
      <div style={{ width: `${value * 100}%` }} className={progressStyles.progress} />
    </div>
  </div>
);

ProgressBar.defaultProps = {
  height: 8,
  width: '100%',
  value: 0,
  locale: 'en',
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  locale: PropTypes.oneOf(['ar', 'en']),
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProgressBar;

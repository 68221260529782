import React from 'react';
import PropTypes from 'prop-types';
import {
  Spacer,
  Flex,
  Text,
} from 'atoms';
import { ReactComponent as ArrowLeft } from 'assets/icons/common/arrow-left.svg';

const ModalHeader = ({
  center,
  title,
  subTitle,
  onBack,
  titleVariant,
  subtitleVariant,
}) => {
  return (
    <Flex itemsCenter>
      {onBack && <ArrowLeft onClick={onBack} style={{ cursor: 'pointer' }}/>}
      <Spacer width={15} />
      <Flex flexCol itemsCenter={center} style={{width: '100%'}}>
        <Text typography={titleVariant || 'paragraph16'} weight="bold">
          {title}
        </Text> 
        {subTitle && (
          <>
            <Spacer height={9}/>
            <Text typography={subtitleVariant || 'paragraph16'} color="var(--brand-text-gray-light)">
              {subTitle}
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onBack: PropTypes.func,
  center: PropTypes.bool,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
};

export default ModalHeader;

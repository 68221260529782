import React, {useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import { DataTable } from 'atoms';
import SubTestDetailModal from './SubTestDetailModal';

const SubTestTable = ({subtests}) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const columns = [
    {
      field: 'lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'name',
      headerName: i18n.t('remisol_name'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'test_type',
      headerName: i18n.t('test_type'),
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'unit',
      headerName: i18n.t('unit'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'analyzer_code',
      headerName: i18n.t('analyzer_code'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'no_decimal',
      headerName: i18n.t('no_decimal'),
      minWidth: 150,
      flex: 1,
    }
  ];

  const handleRowClick = ({row}) => {
    setSelected(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelected(null);
  };

  return (
    <>
      <DataTable
        height={320}
        columns={columns}
        rows={subtests}
        onRowClick={handleRowClick}
        rowCount={subtests.length}
        onPageChange={() => {}}
      />
      {showModal && (
        <SubTestDetailModal
          showModal={showModal}
          service={selected}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

SubTestTable.propTypes = {
  subtests: PropTypes.arrayOf(PropTypes.shape()),
};

export default SubTestTable;
import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {composeValidators, maxLength, minLength, number, required} from 'utils/FormValidations';
import {getClientDetails, updateClient} from 'redux/lab/actionCreators';

import {Button, Modal, Spacer} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';
import {TextInputField} from 'components/global/final-form';
import {isPathIncludes} from 'utils/uiHelpers';

const EditClinic = ({showModal, onClose}) => {
  const dispatch = useDispatch();
  const {clientInfo} = useSelector(state => state.lab);
  const client = isPathIncludes('clinics') ? 'clinic' : 'collection_center';

  const clinic = {
    service_provider: clientInfo.secondary
  };

  const headerTitles = {
    true: 'edit_clinic',
    false: 'edit_collection_center',
  };

  const onSubmit = (payload) => {
    const userData = payload.service_provider.admin_service_provider[0];

    const newData = {
      [client]: {
        service_provider: {
          name_en: payload.service_provider.name_en,
          name_ar: payload.service_provider.name_ar,
          cr_number: payload.service_provider.cr_number,
          vat_number: payload.service_provider.vat_number,
          bank_name: payload.service_provider.bank_name,
          iban_number: payload.service_provider.iban_number,
          users_attributes: {
            id: userData.id,
            email:  userData.email,
            ...(userData.password && {password: userData.password}),
            ...(userData.password_confirmation 
              && {password_confirmation: userData.password_confirmation}),
          }
        }
      } 
    };

    dispatch(updateClient(clientInfo.id, newData))
      .then(() => {
        dispatch(getClientDetails(clientInfo.id));
        onClose();
      });
  };

  return (
    <Modal
      header={<ModalHeader title={i18n.t(headerTitles[isPathIncludes('clinics')])}/>}
      isOpen={showModal}
      onClose={onClose}
      width={350}
      maxHeight="80vh"
    >
      <Form onSubmit={onSubmit} initialValues={clinic}>
        {({handleSubmit, values}) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <TextInputField
                name="service_provider.name_en"
                label={i18n.t('name_en')}
                placeholder={i18n.t('name')}
                validate={required}
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.name_ar"
                label={i18n.t('name_ar')}
                placeholder={i18n.t('name')}
                validate={required}
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.admin_service_provider[0].email"
                label={i18n.t('email')}
                placeholder={i18n.t('email')}
                validate={required}
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.admin_service_provider[0].password"
                label={i18n.t('new_password')}
                placeholder={i18n.t('new_password')}
                validate={required}
                type="password"
                hideShowPasswordOption
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.admin_service_provider[0].password_confirmation"
                label={i18n.t('password_confirmation')}
                placeholder={i18n.t('password_confirmation')}
                validate={required}
                type="password"
                hideShowPasswordOption
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.vat_number"
                placeholder={i18n.t('vat')}
                label={i18n.t('vat_number').toUpperCase()}
                validate={composeValidators(required, number)}
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.cr_number"
                placeholder={i18n.t('commercial_registration')}
                label={i18n.t('cr_number').toUpperCase()}
                validate={composeValidators(required, number, maxLength(10), minLength(10))}
                maxLength={10}
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.bank_name"
                placeholder={i18n.t('bank_name')}
                label={i18n.t('bank_name').toUpperCase()}
                validate={required}
              />
              <Spacer height={12}/>
              <TextInputField
                name="service_provider.iban_number"
                placeholder={i18n.t('iban')}
                label={i18n.t('iban').toUpperCase()}
                validate={required}
              />
            </ModalContent>
            <Spacer height={12}/>
            <Spacer height={1} width="100%" style={{backgroundColor: 'var(--brand-divider-white)'}}/>
            <ModalContent>
              <Button
                label={i18n.t('submit')}
                onClick={() => onSubmit(values)}
              />
            </ModalContent>
          </form>
        )}
      </Form>
    </Modal>
  );
};

EditClinic.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default EditClinic;
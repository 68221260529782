import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { parse } from 'date-fns';

import i18n from 'translation/i18n';
import { getChartDataForReport } from 'redux/report/actionCreators';

import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { chartsGridClasses } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LinkButton, Text, Card } from 'atoms';
import './styles.scss';

export const TotalInvoices = ({range}) => {
  const duration = (range === 'custom' ? 'week' : range).concat('ly');
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {chartData} = useSelector(state => state.report);
  const dataset = useMemo(() => {
    const invoices = chartData.invoices || {};
    return Object.keys(invoices).map(key => ({
      xLabel: duration !== 'monthly' ?
        key :
        parse(key.replace(/\s+/g, ' '), 'MMM d, yyyy', new Date()).getDate().toString(),
      label: key,
      value: Number(invoices[key])
    }));
  }, [chartData]);

  const getChartData = () => {
    dispatch(getChartDataForReport('invoice', {filter: duration}));
  };

  const xAxisFormatter = (label, context) => {
    if (context.location === 'tick') return label;
    return dataset.find(d => d.xLabel === label).label;
  };

  const yAxisFormatter = (value) => {
    if (value >= 1000) return `${value / 1000}K`;
    if (value >= 1000000) return `${value / 1000000}M`;
    return value;
  };

  useEffect(() => {
    getChartData();
  }, [duration]);

  return (
    <>
      <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">{i18n.t('overview_invoices_title')}</Text>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
          {i18n.t('overview_invoices_subtitle', { duration })}
        </Text>
        <LinkButton
          label={i18n.t('view_all')}
          onClick={() => push('/reports/invoices')}
        />
      </Box>
      <Card styles={{ marginTop: '1rem', paddingX: '1rem' }}>
        <BarChart
          sx={{
            [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 1, fill: '#EDEEEF' },
            [`& .${axisClasses.line}`]: { display: 'none' },
            [`& .${axisClasses.tick}`]: { display: 'none' },
            [`& .${axisClasses.directionY}`]: { transform: 'translate(1rem, 0)' },
            [`& .${axisClasses.directionY} .${axisClasses.tickLabel}`]: { fill: '#ADB1B2', textAnchor: 'start' },
            [`& .${axisClasses.directionX} .${axisClasses.tickLabel}`]: { fontSize: '13px', fill: '#383838' }
          }}
          dataset={dataset}
          colors={['#5E72EB']}
          series={[{dataKey: 'value'}]}
          xAxis={[{scaleType: 'band', dataKey: 'xLabel', valueFormatter: xAxisFormatter, categoryGapRatio: 0.5, barGapRatio: 1}]}
          yAxis={[{valueFormatter: yAxisFormatter}]}
          height={300}
          grid={{horizontal: true}}
        />
      </Card>
    </>
  );
};

TotalInvoices.propTypes = {
  range: PropTypes.string
};
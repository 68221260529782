import { combineReducers } from 'redux';
import general from './general/reducer';
import patient from './patients/reducer';
import profile from './profile/reducer';
import tests from './tests/reducer';
import packages from './packages/reducer';
import auth from './auth/reducer';
import lab from './lab/reducer';
import admin from './admin/reducer';
import orders from './orders/reducer';
import billing from './billing/reducer';
import report from './report/reducer';
import inventory from './inventory/reducer';

const rootReducer = combineReducers({
  general,
  patient,
  profile,
  tests,
  packages,
  auth,
  admin,
  lab,
  orders,
  billing,
  report,
  inventory
});

export default rootReducer;
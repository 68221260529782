import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { DISPLAY_DATE_TIME_FORMAT, format } from 'utils/date';
import { getStatusColor } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

import { DataTable, Tag } from 'atoms';
import { useTablePagination } from 'hooks';

export const MasterOrderTable = ({ onPagination }) => {
  const { push } = useHistory();
  const { master_orders, fetching, pagination } = useSelector(
    (state) => state.orders,
  );

  const handleRowClick = ({ id }) => {
    push(`/orders/${id}/`);
  };

  const rows = useTablePagination({ pagination, dataList: master_orders });

  const columns = [
    {
      field: 'patient_name',
      headerName: i18n.t('patient'),
      minWidth: 200,
      flex: 4,
    },
    {
      field: 'patient_mobile',
      headerName: i18n.t('phone'),
      minWidth: 200,
      flex: 2,
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 200,
      flex: 2,
    },
    {
      field: 'sample_id',
      headerName: i18n.t('sample_id'),
      minWidth: 200,
      flex: 2,
    },
    {
      field: 'date',
      headerName: i18n.t('date'),
      minWidth: 200,
      flex: 2,
      renderCell: ({ value }) => format(value, DISPLAY_DATE_TIME_FORMAT),
    },
    {
      field: 'count_orders',
      headerName: i18n.t('total_tests'),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 150,
      flex: 2,
      renderCell: ({ value }) => (
        <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)} />
      ),
    },
  ];

  return (
    <DataTable
      mobileTableHeight={13.1}
      columns={columns}
      loading={fetching}
      rows={rows}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
      onRowClick={handleRowClick}
    />
  );
};

MasterOrderTable.propTypes = {
  onPagination: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { valueGetter } from 'utils/uiHelpers';
import { TextInput } from 'atoms';

const TextInputField = ({
  validate,
  onChange,
  onBlur,
  style,
  name,
  inputStyle,
  ...props
}) => {
  const getStatus = (meta) => {
    if (meta.error) {
      return meta.touched ? 'error' : undefined;
    }
    return undefined;
  };

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <TextInput
          status={getStatus(meta)}
          statusMessage={meta.error}
          style={{ marginBottom: 8, ...style}}
          {...props}
          {...input}
          value={valueGetter(props, 'value') || input.value}
          onChange={({ target }) => {
            if (onChange) {
              onChange(target.value);
            }
            input.onChange(target.value);
          }}
          onBlur={onBlur}
          inputStyle={inputStyle}
        />
      )}
    </Field>
  );
};
TextInputField.propTypes = {
  style: PropTypes.shape(),
  inputStyle: PropTypes.shape(),
  validate: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  parse: PropTypes.func,
};

export default TextInputField;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Serial from './serial';

const SerialPage = () => {

    return (
        <Switch>
            <Route path="/serial_settings" component={Serial} />
        </Switch>
    );
};
export default SerialPage;
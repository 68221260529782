import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';

import { getStocks } from 'redux/inventory/actionCreators';
import useHandelPagination from 'hooks/useHandelPagination';

import { Grid } from '@mui/material';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import StocksFilters from './StocksFilters';
import StocksTable from './StocksTable';

const Stocks = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});

  const loadStocks = (params = {}) => {
    setFilters(params);
    dispatch(getStocks(params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      location_id: filter.location_id,
      status: filter.status
    };
    loadStocks(params);
  };

  const handlePagination = useHandelPagination({
    filters,
    onPageChange: loadStocks,
  });

  const content = (
    <>
      <ListFilters
        Wrapped={StocksFilters}
        onChange={handleFilterChange}
      />
      <StocksTable reload={() => loadStocks(filters)} onPagination={handlePagination} />
    </>
  );

  useEffect(() => {
    loadStocks();
  }, []);

  return (
    <Grid
      container
      spacing={isBrowser ? 4 : 0}
      px={isBrowser ? 4 : 0}
      paddingTop={0}
    >
      <Grid item xs={12}>
        {isMobile ? content : <Card>{content}</Card>}
      </Grid>
    </Grid>
  );
};

export default Stocks;
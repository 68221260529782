import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';

import {Box, Grid} from '@mui/material';
import {Text} from 'atoms';
import {OverviewStatCard} from 'components/overview';

export const OverviewStats = ({isCustomRange}) => {
  const [stats, setStats] = useState({});
  const {statistics} = useSelector(state => state.lab);

  useEffect(() => {
    setStats(valueGetter(statistics, 'total_status') || {});
  }, [statistics]);

  return (
    <Box py={isCustomRange ? 0 : 2} px={isMobile ? 2 : 5}>
      <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
        {i18n.t('overview_stats_title')}
      </Text>
      <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
        {i18n.t('overview_stats_subtitle')}
      </Text>
      {isMobile ? (
        <Grid container spacing={2} mt={1}>
          {Object.keys(stats).map(key =>
            <Grid key={key} item xs={6}>
              <OverviewStatCard
                value={valueGetter(stats, `${key}.total`)}
                title={`${key}_count`}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Box display="flex" justifyContent="space-between" mt={2}>
          {Object.keys(stats).map(key =>
            <OverviewStatCard
              key={key}
              value={valueGetter(stats, `${key}.total`)}
              title={`${key}_count`}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

OverviewStats.propTypes = {
  isCustomRange: PropTypes.bool
};

import React from 'react';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {
  required,
} from 'utils/FormValidations';

import {Spacer} from 'atoms';
import {
  SelectField,
  TextInputField,
  DatePickerField,
} from 'components/global/final-form';
import {getListForSelect} from 'utils/labUtils';
import {addYears} from 'date-fns';

const Step2 = () => {
  const {
    id_types,
    nationalities,
    directorates,
    isolotion_places,
    occupations
  } = useSelector(state => state.patient);

  return (
    <>
      <SelectField
        name="patient.nationality_id"
        label={i18n.t('nationality')}
        placeholder={i18n.t('choose_nationality')}
        options={getListForSelect(nationalities)}
        validate={required}
        maxMenuHeight={320}
      />
      <Spacer height={12}/>
      <DatePickerField
        name="patient.date_of_birth"
        label={i18n.t('date_of_birth')}
        displayFormat="dd MMM yyyy"
        placeholder={i18n.t('date')}
        validate={required}
        minDate={addYears(new Date(), -100)}
        maxDate={(new Date())}
      />
      <Spacer height={12}/>
      <SelectField
        name="patient.directorate_id"
        label={i18n.t('directorate')}
        placeholder={i18n.t('choose_directorate')}
        options={getListForSelect(directorates)}
        validate={required}
        maxMenuHeight={320}
      />
      <Spacer height={12}/>
      <SelectField
        name="patient.isolotion_place_id"
        label={i18n.t('location')}
        placeholder={i18n.t('choose_location')}
        options={getListForSelect(isolotion_places)}
        validate={required}
        maxMenuHeight={320}
      />
      <Spacer height={12}/>
      <TextInputField
        name="patient.passport_number"
        label={i18n.t('passport_number')}
        placeholder={i18n.t('passport_number')}
      />
      <Spacer height={12}/>
      <SelectField
        name="patient.id_type"
        label={i18n.t('type_of_personal_id')}
        placeholder={i18n.t('choose_type')}
        options={getListForSelect(id_types)}
        validate={required}
      />
      <Spacer height={12}/>
      <TextInputField
        name="patient.personal_id"
        label={i18n.t('id_number')}
        placeholder={i18n.t('id_number')}
        validate={required}
      />
      <Spacer height={12}/>
      <SelectField
        name="patient.occupation"
        label={i18n.t('occupation')}
        placeholder={i18n.t('occupation')}
        options={getListForSelect(occupations)}
        validate={required}
        maxMenuHeight={320}
      />
    </>
  );
};

export default Step2;
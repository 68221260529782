import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';
import * as actions from './actions';
import {valueGetter} from '../../utils/uiHelpers';

export const initialState = {
  fetching: false,
  submitting: false,
  service_providers: [],
  specimen_types: [],
  service_provider_subscriptions: [],
  subscriptions: [],
  plans: [],
  plan: {},
  service_provider: {},
  service_provider_subscription: {},
  options: {},
  pagination: {},
  bulkUploadResponse: {},
  bulkUploadErrors: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ADMIN_SERVICE_PROVIDERS_PENDING:
    case actions.GET_SPECIMEN_TYPES_PENDING:
    case actions.GET_SUBSCRIPTIONS_OPTIONS_PENDING:
    case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING:
    case actions.GET_LAB_SUBSCRIPTION_INVOICES_PENDING:
    case actions.GET_ADMIN_SERVICE_PROVIDER_INFO_PENDING:
    case actions.GET_ADMIN_SERVICE_OPTIONS_PENDING:
    case actions.GET_LAB_SUBSCRIPTIONS_PENDING:
    case actions.GET_PLANS_PENDING:
    case actions.SHOW_PLAN_PENDING:
      return {
        ...state,
        specimen_types: [],
        fetching: true,
      };

    case actions.CREATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING:
    case actions.UPDATE_ADMIN_SERVICE_PROVIDERS_PENDING:
    case actions.UPDATE_SPECIMEN_TYPE_PENDING:
    case actions.DELETE_SPECIMEN_TYPE_PENDING:
    case actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING:
    case actions.REFUND_LAB_SUBSCRIPTION_INVOICE_PENDING:
    case actions.PRINT_LAB_SUBSCRIPTION_INVOICE_PENDING:
    case actions.CREATE_TEST_PENDING:
    case actions.EDIT_TEST_PENDING:
      return {
        ...state,
        submitting: true,
      };

    case actions.BULK_UPLOAD_TEST_PENDING:
      return {
        ...state,
        bulkUploadErrors: [],
        bulkUploadResponse: {},
        submitting: true,
      };

    case actions.GET_LAB_SUBSCRIPTIONS_FULFILLED: {
      return {
        ...state,
        service_provider_subscriptions:
          action.payload.data.service_provider_subscriptions,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }
    case actions.GET_PLANS_FULFILLED: {
      return {
        ...state,
        plans: action.payload.data.subscriptions,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }
    case actions.SHOW_PLAN_FULFILLED: {
      return {
        ...state,
        plan: action.payload.data.subscription,
        fetching: false,
      };
    }

    case actions.GET_ADMIN_SERVICE_PROVIDERS_FULFILLED: {
      return {
        ...state,
        service_providers: action.payload.data.service_providers,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }

    case actions.GET_SPECIMEN_TYPES_FULFILLED: {
      return {
        ...state,
        specimen_types: action.payload.data.specimen_types,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }

    case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED: {
      return {
        ...state,
        service_provider_subscriptions:
          action.payload.data.service_provider_subscriptions,
        pagination: action.payload.data.pagination,
        fetching: false,
      };
    }

    case actions.GET_ADMIN_SERVICE_PROVIDER_INFO_FULFILLED: {
      return {
        ...state,
        service_provider: action.payload.data.service_provider,
        fetching: false,
      };
    }

    case actions.GET_LAB_SUBSCRIPTION_INVOICES_FULFILLED: {
      return {
        ...state,
        subscription_invoices: action.payload.data.subscription_invoices,
        fetching: false,
      };
    }

    case actions.GET_SUBSCRIPTIONS_OPTIONS_FULFILLED: {
      return {
        ...state,
        subscriptions: action.payload.data.subscriptions,
        fetching: false,
      };
    }

    case actions.GET_ADMIN_SERVICE_OPTIONS_FULFILLED: {
      return {
        ...state,
        ...action.payload.data,
        fetching: false,
      };
    }

    case actions.CREATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED:
    case actions.UPDATE_ADMIN_SERVICE_PROVIDERS_FULFILLED:
    case actions.UPDATE_SPECIMEN_TYPE_FULFILLED:
    case actions.DELETE_SPECIMEN_TYPE_FULFILLED:
    case actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED:
    case actions.REFUND_LAB_SUBSCRIPTION_INVOICE_FULFILLED:
    case actions.PRINT_LAB_SUBSCRIPTION_INVOICE_FULFILLED:
    case actions.CREATE_TEST_FULFILLED:
    case actions.EDIT_TEST_FULFILLED: {
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        submitting: false,
      };
    }

    case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED:
    case actions.GET_SUBSCRIPTIONS_OPTIONS_REJECTED:
    case actions.GET_ADMIN_SERVICE_PROVIDERS_REJECTED:
    case actions.GET_SPECIMEN_TYPES_REJECTED:
    case actions.GET_LAB_SUBSCRIPTION_INVOICES_REJECTED:
    case actions.GET_ADMIN_SERVICE_PROVIDER_INFO_REJECTED:
    case actions.GET_LAB_SUBSCRIPTIONS_REJECTED:
    case actions.CREATE_PLAN_REJECTED:
    case actions.GET_ADMIN_SERVICE_OPTIONS_REJECTED:
    case actions.GET_PLANS_REJECTED:
      Toastr.error(handleResponseErr(action.payload));

      return {
        ...state,
        fetching: false,
      };

    case actions.CREATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED:
    case actions.UPDATE_ADMIN_SERVICE_PROVIDERS_REJECTED:
    case actions.UPDATE_SPECIMEN_TYPE_REJECTED:
    case actions.DELETE_SPECIMEN_TYPE_REJECTED:
    case actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED:
    case actions.REFUND_LAB_SUBSCRIPTION_INVOICE_REJECTED:
    case actions.PRINT_LAB_SUBSCRIPTION_INVOICE_REJECTED:
    case actions.CREATE_TEST_REJECTED:
    case actions.EDIT_TEST_REJECTED:
      Toastr.error(handleResponseErr(action.payload));

      return {
        ...state,
        submitting: false,
      };

    case actions.BULK_UPLOAD_TEST_FULFILLED:
      Toastr.success(`${action.payload.data.updated} tests updated and ${action.payload.data.message}`);
      return {
        ...state,
        bulkUploadResponse: action.payload.data,
        submitting: false,
      };

    case actions.BULK_UPLOAD_TEST_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        bulkUploadErrors: valueGetter(action, 'payload.response.data.data.errors') || [],
        submitting: false,
      };

    default:
      return state;
  }
}

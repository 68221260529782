import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { startOfMonth, startOfYear, sub } from 'date-fns';

import i18n from 'translation/i18n';
import { getOverviewStats } from 'redux/lab/actionCreators';

import { Grid } from '@mui/material';
import LabHeader from 'components/global/LabHeader';
import { NewRequests, OverdueTests, OverviewStats, TotalPayments, TotalInvoices } from 'components/overview';
import CustomDateSelector from './CustomDateSelector';

const headerOptions = [
  { value: 'week', label: i18n.t('this_week') },
  { value: 'month', label: i18n.t('this_month') },
  { value: 'year', label: i18n.t('this_year') },
  { value: 'custom', label: i18n.t('custom_dates') },
];

export const Overview = () => {
  const dispatch = useDispatch();
  const [range, setRange] = useState(headerOptions[0].value);
  const isCustomRange = useMemo(() => range === 'custom', [range]);

  const updateDateRange = (dateRange) => {
    setRange(dateRange);
    const toDate = new Date();
    let fromDate = sub(toDate, { weeks: 1 });
    if (dateRange === 'month') {
      fromDate = startOfMonth(toDate);
    } else if (dateRange === 'year') {
      fromDate = startOfYear(toDate);
    }
    dispatch(getOverviewStats(fromDate, toDate));
  };

  useEffect(() => {
    updateDateRange(range);
  }, []);

  return (
    <>
      <LabHeader
        title="overview"
        filterOptions={headerOptions}
        onFilterChange={(value) => updateDateRange(value)}
        selectedOption={range}
      />
      {isCustomRange && <CustomDateSelector />}
      <OverviewStats isCustomRange={isCustomRange} />
      <Grid container spacing={3} px={isMobile ? 2 : 5} py={isMobile ? 0 : 5}>
        <Grid item xs={12} sm={6}>
          <TotalPayments range={range} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TotalInvoices range={range} />
        </Grid>
      </Grid>
      <Grid container spacing={3} px={isMobile ? 2 : 5} py={isMobile ? 0 : 5}>
        <Grid item xs={12} sm={6}>
          <NewRequests />
        </Grid>
        <Grid item xs={12} sm={6}>
          <OverdueTests />
        </Grid>
      </Grid>
    </>
  );
};

export const CREATE_BATCH_ORDER = 'CREATE_BATCH_ORDER';
export const CREATE_BATCH_ORDER_PENDING = 'CREATE_BATCH_ORDER_PENDING';
export const CREATE_BATCH_ORDER_FULFILLED = 'CREATE_BATCH_ORDER_FULFILLED';
export const CREATE_BATCH_ORDER_REJECTED = 'CREATE_BATCH_ORDER_REJECTED';

export const MASRTER_ORDER_LIST = 'MASRTER_ORDER_LIST';
export const MASRTER_ORDER_LIST_PENDING = 'MASRTER_ORDER_LIST_PENDING';
export const MASRTER_ORDER_LIST_FULFILLED = 'MASRTER_ORDER_LIST_FULFILLED';
export const MASRTER_ORDER_LIST_REJECTED = 'MASRTER_ORDER_LIST_REJECTED';

export const PATIENT_ORDER_LIST = 'PATIENT_ORDER_LIST';
export const PATIENT_ORDER_LIST_PENDING = 'PATIENT_ORDER_LIST_PENDING';
export const PATIENT_ORDER_LIST_FULFILLED = 'PATIENT_ORDER_LIST_FULFILLED';
export const PATIENT_ORDER_LIST_REJECTED = 'PATIENT_ORDER_LIST_REJECTED';

export const SINGLE_ORDER_LIST = 'SINGLE_ORDER_LIST';
export const SINGLE_ORDER_LIST_PENDING = 'SINGLE_ORDER_LIST_PENDING';
export const SINGLE_ORDER_LIST_FULFILLED = 'SINGLE_ORDER_LIST_FULFILLED';
export const SINGLE_ORDER_LIST_REJECTED = 'SINGLE_ORDER_LIST_REJECTED';

export const BATCH_ORDER_LIST = 'BATCH_ORDER_LIST';
export const BATCH_ORDER_LIST_PENDING = 'BATCH_ORDER_LIST_PENDING';
export const BATCH_ORDER_LIST_FULFILLED = 'BATCH_ORDER_LIST_FULFILLED';
export const BATCH_ORDER_LIST_REJECTED = 'BATCH_ORDER_LIST_REJECTED';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_PENDING = 'GET_ORDER_PENDING';
export const GET_ORDER_FULFILLED = 'GET_ORDER_FULFILLED';
export const GET_ORDER_REJECTED = 'GET_ORDER_REJECTED';

export const GET_BATCH_ORDER = 'GET_BATCH_ORDER';
export const GET_BATCH_ORDER_PENDING = 'GET_BATCH_ORDER_PENDING';
export const GET_BATCH_ORDER_FULFILLED = 'GET_BATCH_ORDER_FULFILLED';
export const GET_BATCH_ORDER_REJECTED = 'GET_BATCH_ORDER_REJECTED';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_PENDING = 'UPDATE_ORDER_PENDING';
export const UPDATE_ORDER_FULFILLED = 'UPDATE_ORDER_FULFILLED';
export const UPDATE_ORDER_REJECTED = 'UPDATE_ORDER_REJECTED';

export const REMOVE_ORDER_FROM_BATCH = 'REMOVE_ORDER_FROM_BATCH';
export const REMOVE_ORDER_FROM_BATCH_PENDING = 'REMOVE_ORDER_FROM_BATCH_PENDING';
export const REMOVE_ORDER_FROM_BATCH_FULFILLED = 'REMOVE_ORDER_FROM_BATCH_FULFILLED';
export const REMOVE_ORDER_FROM_BATCH_REJECTED = 'REMOVE_ORDER_FROM_BATCH_REJECTED';

export const RESEND_HESN = 'RESEND_HESN';
export const RESEND_HESN_PENDING = 'RESEND_HESN_PENDING';
export const RESEND_HESN_FULFILLED = 'RESEND_HESN_FULFILLED';
export const RESEND_HESN_REJECTED = 'RESEND_HESN_REJECTED';

export const EXPORT_PATCH_AS_PDF = 'EXPORT_PATCH_AS_PDF';
export const EXPORT_PATCH_AS_PDF_PENDING = 'EXPORT_PATCH_AS_PDF_PENDING';
export const EXPORT_PATCH_AS_PDF_FULFILLED = 'EXPORT_PATCH_AS_PDF_FULFILLED';
export const EXPORT_PATCH_AS_PDF_REJECTED = 'EXPORT_PATCH_AS_PDF_REJECTED';

export const VERIFY_ORDER = 'VERIFY_ORDER';
export const VERIFY_ORDER_PENDING = 'VERIFY_ORDER_PENDING';
export const VERIFY_ORDER_FULFILLED = 'VERIFY_ORDER_FULFILLED';
export const VERIFY_ORDER_REJECTED = 'VERIFY_ORDER_REJECTED';

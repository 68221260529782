import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-final-form';

import i18n from 'translation/i18n';
import { getPatientOrder } from 'redux/orders/actionCreators';
import { updateMasterOrderDetails } from 'redux/lab/actionCreators';

import { Grid } from '@mui/material';
import { Card, Spacer } from 'atoms';
import { CardContainer, ModalContent, SectionTitle } from 'templates';
import { GeneralReservationInfo } from 'components/orders/patient/GeneralReservationInfo';
import { ReservationInfoActions } from 'components/reservation/masterReservation/ReservationInfo';
import { ReservationTestsTable } from 'components/orders/patient/ReservationTestsTable';
import { ReservationSummary } from 'components/orders/patient/ReservationSummary';

const TestDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.orders);
  const [editing, setEditing] = useState(false);

  const showPatientOrder = () => {
    dispatch(getPatientOrder(id));
  };

  const onEditClick = (values) => {
    if (editing) {
      const payload = {
        patient: values.patient_detail,
        user: {
          email: values.user.email,
          mobile: values.user.mobile,
        },
      };
      dispatch(updateMasterOrderDetails(values.id, payload)).then(() =>
        setEditing(false),
      );
    } else {
      setEditing(true);
    }
  };

  useEffect(() => {
    showPatientOrder();
  }, []);

  const content = (
    <CardContainer
      title={i18n.t('reservation_info_title')}
      noPadding
      cardActions={<ReservationInfoActions masterOrder={orderDetails} />}
      showBackButton
    >
      <Form onSubmit={() => {}} initialValues={orderDetails}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <SectionTitle
              titleKey="general_reservation_information"
              showEdit={false}
              editKey={i18n.t(editing ? 'save' : 'edit_info')}
              onEdit={() => onEditClick(values)}
            />
            <Spacer height={16} />
            <Grid container spacing={4} p={4}>
              <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
                <GeneralReservationInfo editing={editing} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <ModalContent>
                  <ReservationSummary />
                </ModalContent>
              </Grid>
            </Grid>
            <Spacer height={16} />
            <SectionTitle titleKey={i18n.t('test_list_info')} />
            <ReservationTestsTable />
          </form>
        )}
      </Form>
    </CardContainer>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default TestDetails;

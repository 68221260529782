import React from 'react';
import {Spacer} from 'atoms';
import {LanguageCard, AboutCard, TermsCard} from '.';

const SettingPage = () => {
  return (
    <div style={{padding: '2rem'}}>
      <LanguageCard />
      <Spacer height={20}/>
      <AboutCard />
      <Spacer height={20}/>
      <TermsCard />
    </div>
  );
};

export default SettingPage;

import React, {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {Form} from 'react-final-form';

import {useDispatch, useSelector} from 'react-redux';
import {profilesShow, profilesUpdate} from 'redux/profile/actionCreators';
import {getServiceProviderSubscriptions} from 'redux/lab/actionCreators';
import {isLabUser} from 'utils/labUtils';

import {Grid} from '@mui/material';
import {Spacer} from 'atoms';
import {ProfileInfo, AdditionalInfo} from '.';

const ProfileForm = () => {
  const dispatch = useDispatch();
  const {profile} = useSelector(state => state.profile);
  const [editing, setEditing] = useState(false);
  const isLab = isLabUser();

  const reloadData = () => {
    dispatch(profilesShow());
    setEditing(false);
  };

  const onSubmit = (values) => {
    delete values.admin_service_provider;
    delete values.name_i18n;
    delete values.logo_url;
    delete values.signature_url;
    delete values.stamp_url;
    dispatch(profilesUpdate(values))
      .then(() => reloadData());
  };

  useEffect(() => {
    dispatch(profilesShow());
    dispatch(getServiceProviderSubscriptions());
  }, []);

  return (
    <Form onSubmit={onSubmit} initialValues={profile}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={isMobile ? 2 : 4} sx={{margin: 0}}>
            <Grid item xs={11} lg={isLab ? 8 : 11.5}>
              <ProfileInfo
                editing={editing}
                onEditClick={() => setEditing(true)}
                onEditDone={reloadData}
              />
            </Grid>
            {isLab && (
              <Grid item xs={11} lg={3.5}>
                <AdditionalInfo editing={editing}/>
              </Grid>
            )}
          </Grid>
          <Spacer height={40}/>
        </form>
      )}
    </Form>
  );
};

export default ProfileForm;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { getListForSelect } from 'utils/labUtils';
import { required } from 'utils/FormValidations';
import { SYMPTOMS } from 'utils/options';
import { valueGetter } from 'utils/uiHelpers';

import { Grid } from '@mui/material';
import { Card } from 'atoms';
import { SelectField } from 'components/global/final-form';
import { ReactComponent as CloseIcon } from 'assets/icons/common/cross-rounded.svg';

const PatientTestForm = ({ testTypes, index, values, showClose, onClose }) => {
  const [priorities, setPriorities] = useState([]);
  const [isCovid, setIsCovid] = useState(false);
  const { reason_for_testings } = useSelector((state) => state.patient);

  const handleTestSelect = (testId) => {
    const selectedTest = testTypes.find(t => t.value === testId);
    if (!selectedTest) return;
    setIsCovid(selectedTest.label.replace('-', '').toLowerCase().includes('covid19'));
    setPriorities(selectedTest.priorities);
  };

  useEffect(() => {
    const selectedTest = valueGetter(values, `orders.${index}.service_id`);
    if (selectedTest) {
      handleTestSelect(selectedTest);
    }
  }, [testTypes]);

  return (
    <Card
      styles={{
        padding: '1rem',
        paddingTop: '2rem',
        marginBottom: '0.5rem',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: '8px', right: '8px' }}>
        {showClose && <CloseIcon onClick={onClose} />}
      </div>
      <Grid container spacing={2}>

        <Grid item xs={isMobile ? 12 : 4}>
          <SelectField
            name={`orders.${index}.service_id`}
            label={i18n.t('test_name')}
            placeholder={i18n.t('test_name')}
            options={testTypes}
            validate={required}
            maxMenuHeight={320}
            onChange={handleTestSelect}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <SelectField
            name={`orders.${index}.priority_id`}
            label={i18n.t('test_priority')}
            placeholder={i18n.t('test_priority')}
            options={priorities}
            validate={required}
            maxMenuHeight={320}
          />
        </Grid>

        {isCovid && (
          <>
            <Grid item xs={isMobile ? 12 : 4}>
              <SelectField
                name={`orders.${index}.sign_and_symptoms`}
                label={i18n.t('signs_and_symptoms')}
                placeholder={i18n.t('signs_and_symptoms')}
                options={SYMPTOMS}
                validate={required}
                maxMenuHeight={320}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <SelectField
                name={`orders.${index}.reason_for_testing`}
                label={i18n.t('reason_for_testing')}
                placeholder={i18n.t('reason_for_testing')}
                options={getListForSelect(reason_for_testings)}
                validate={required}
                maxMenuHeight={320}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

PatientTestForm.propTypes = {
  testTypes: PropTypes.arrayOf(PropTypes.shape()),
  values: PropTypes.shape(),
  index: PropTypes.number,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PatientTestForm;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Spacer,
  Text,
  Flex,
} from 'atoms';
import { ReactComponent as Close } from 'assets/icons/common/close.svg';
import styles from './Alert.module.css';
import {
  defaultIcons,
  alertStyles,
  alertPlacement,
} from './Helpers';

const Alert = ({
  customMessage,
  withoutIcon,
  startIcon,
  placement,
  canClose,
  variant,
  message,
  onClose,
  show,
  style,
  ...rest
}) => {
  return(
    <>
      {show && ReactDOM.createPortal(
        <div
          className={clsx(styles.alertContainer, alertStyles[variant], alertPlacement[placement])}
          style={{ ...style }}
          {...rest}
        >
          <Flex justifyBetween>
            <Flex itemsCenter>
              {startIcon || (!withoutIcon && defaultIcons[variant])}
              {(startIcon || !withoutIcon) && <Spacer width={ 16}/>}
              {customMessage || (
                <Text typography="paragraph16">
                  {message}
                </Text>
              )}
            </Flex>
            {canClose && <Close onClick={onClose}/>}
          </Flex>
        </div>,
        document.body
      )}
    </>
  );};

Alert.defaultProps = {
  variant: 'secondary',
  canClose: true,
  placement: 'top',
};

Alert.propTypes = {
  customMessage: PropTypes.node,
  withoutIcon: PropTypes.bool,
  placement: PropTypes.string,
  variant: PropTypes.string,
  message: PropTypes.string,
  startIcon: PropTypes.node,
  canClose: PropTypes.bool,
  style: PropTypes.shape(),
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

export default Alert;

import React from 'react';
import { Grid } from '@mui/material';
import i18n from 'translation/i18n';
import { Text } from 'atoms';

const SubscriptionFilters = () => {
  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={8} lg={10}>
        <Text typography="subtitle" weight="bold">
          {i18n.t('all_subscriptions')}
        </Text>
        <Text typography="caption13" style={{ opacity: 0.4 }}>
          {i18n.t('subscriptions_list_subtitle')}
        </Text>
      </Grid>
    </Grid>
  );
};

export default SubscriptionFilters;

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React from 'react';
import i18n from 'translation/i18n';
import { formattedPrice } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';
import { DataTable } from 'atoms';

const ReservationPackageTable = () => {
  const { reservationInfo, fetching } = useSelector((state) => state.lab);
  const { packages } = reservationInfo;

  const { push } = useHistory();

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'discount',
      headerName: i18n.t('discount'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => formattedPrice(valueGetter(row, field)),
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
  ];

  const handleRowClick = (values) => {
    const { id } = values;
    push(`packages/${id}`);
  };

  return (
    <DataTable
      columns={columns}
      rows={!fetching && (packages || [])}
      loading={fetching}
      onRowClick={handleRowClick}
    />
  );
};

export default ReservationPackageTable;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button, Flex, Modal, ProgressBar, Spacer, Text } from 'atoms';
import { TextInputField } from 'components/global/final-form';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { fetchPatientDetails } from 'redux/patients/actionCreators';
import { ModalContent } from 'templates';
import i18n from 'translation/i18n';
import {
  composeValidators,
  phoneOrEmail,
  required,
} from 'utils/FormValidations';
import CurrentStep from './CurrentStep';

const titles = [
  {
    title: i18n.t('test_details'),
    steps: [1, 2, 3],
  },
  {
    title: i18n.t('bill_and_collection'),
    steps: [4, 5],
  },
];

const StepsHeader = ({ activeStep }) => {
  return (
    <>
      <Flex justifyBetween style={{ padding: '0 29px' }}>
        {titles.map((title, index) => (
          <Text
            typography="paragraph14"
            weight={title.steps.includes(activeStep) ? 'bold' : 'medium'}
            color={
              title.steps.includes(activeStep)
                ? 'var(--brand-text-black)'
                : 'var(--brand-text-black-opacity-40)'
            }
            key={index}
          >
            {title.title}
          </Text>
        ))}
      </Flex>
      <Spacer height={16} />
      <ProgressBar value={0.2 * activeStep} height={2} />
    </>
  );
};

StepsHeader.propTypes = {
  activeStep: PropTypes.number,
};

const ExistPatientPhonePopup = ({ open, onSubmit, onClose }) => {
  return (
    <Modal isOpen={open} minHeight={200} minWidth={400} onClose={onClose}>
      <Form onSubmit={onSubmit} initialValues={{}}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <TextInputField
                name="patient_datails"
                label={i18n.t('search_phone_email')}
                placeholder={i18n.t('search_phone_email')}
                validate={composeValidators(
                  required,
                  phoneOrEmail
                )}
              />
            </ModalContent>
            <ModalContent>
              <Button
                label={i18n.t('submit')}
                disabled={!values.patient_datails}
                type="submit"
              />
            </ModalContent>
          </form>
        )}
      </Form>
    </Modal>
  );
};

ExistPatientPhonePopup.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const StepsTemplate = ({ activeStep, onShowPatientInfo }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleStep0Submit = (values) => {
    if (values === 'exist') setOpen(true);
    else onShowPatientInfo(values);
  };

  const patientPhoneOnSubmit = (values) => {
    dispatch(fetchPatientDetails(values)).then(() => {
      setOpen(false);
      onShowPatientInfo(values);
    });
  };

  return (
    <>
      {activeStep !== 0 && <StepsHeader activeStep={activeStep} />}
      <ModalContent>
        <CurrentStep
          activeStep={activeStep}
          handleStep0Submit={handleStep0Submit}
        />
      </ModalContent>
      {activeStep !== 0 && (
        <>
          <Spacer height={20} />
          <Spacer
            height={1}
            width="100%"
            style={{ backgroundColor: 'var(--brand-divider-white)' }}
          />
          <ModalContent>
            <Button
              label={
                activeStep === 5
                  ? i18n.t('confirm_reservation')
                  : i18n.t('next')
              }
              type="submit"
            />
          </ModalContent>
        </>
      )}

      {open && (
        <ExistPatientPhonePopup
          open={open}
          onSubmit={patientPhoneOnSubmit}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

StepsTemplate.propTypes = {
  onShowPatientInfo: PropTypes.func,
  activeStep: PropTypes.number,
};

export default StepsTemplate;

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React, { useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { cancelMasterOrder } from 'redux/lab/actionCreators';
import { getPatientOrder } from 'redux/orders/actionCreators';
import i18n from 'translation/i18n';
import { getOptionsForDropdown, getStatusColor } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

import { Grid } from '@mui/material';
import { Flex, Spacer, Switch, Tag, Text } from 'atoms';
import MasterOrderStatusTransition from 'components/global/MasterOrderStatusTransition';
import { FieldInfoCardList } from 'templates';

export const GeneralReservationInfo = ({ editing }) => {
  const dispatch = useDispatch();
  const { change } = useForm();
  const { orderDetails } = useSelector((state) => state.orders);
  const { genders, nationalities, directorates, isolotion_places } =
    useSelector((state) => state.patient);
  const [isPregnant, setIsPregnant] = useState(false);
  const [status, setStatus] = useState('');

  const handleMenuClick = ({ reason, refund }) => {
    const masterOrderId = orderDetails.id;
    const payload = {
      master_order: {
        reason,
        refund,
      },
    };
    dispatch(cancelMasterOrder(masterOrderId, payload)).then(() =>
      dispatch(getPatientOrder(masterOrderId)),
    );
  };

  const items = [
    {
      name: 'patient_detail.name',
      label: i18n.t('patient_name'),
      placeholder: i18n.t('patient_name'),
      disabled: !editing,
    },
    {
      name: 'user.mobile',
      label: i18n.t('phone'),
      placeholder: i18n.t('phone'),
      disabled: !editing,
    },
    {
      custom: (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('status')}
          </Text>
          <Flex itemsCenter>
            <Tag text={status} color={getStatusColor(status)} />
            <MasterOrderStatusTransition
              status={status}
              onMenuClick={handleMenuClick}
            />
          </Flex>
        </Grid>
      ),
    },
    {
      name: 'patient_detail.personal_id',
      label: i18n.t('personal_id'),
      placeholder: i18n.t('personal_id'),
      disabled: !editing,
    },
    {
      name: 'patient_detail.date_of_birth',
      label: i18n.t('date_of_birth'),
      placeholder: i18n.t('date_of_birth'),
      disabled: !editing,
      as: 'date',
    },
    {
      name: 'user.email',
      label: i18n.t('email'),
      placeholder: i18n.t('email'),
      disabled: !editing,
    },
    {
      name: 'patient_detail.passport_number',
      label: i18n.t('passport_number'),
      placeholder: i18n.t('passport_number'),
      disabled: !editing,
    },
    {
      name: 'patient_detail.personal_id',
      label: i18n.t('iqama_no'),
      placeholder: i18n.t('iqama_no'),
      disabled: !editing,
    },
    {
      name: 'reservation_id',
      label: i18n.t('reservation_id'),
      placeholder: i18n.t('reservation_id'),
      disabled: true,
    },
    {
      name: 'appointment.time',
      label: i18n.t('date'),
      placeholder: i18n.t('date'),
      disabled: !editing,
      as: 'date',
    },
    {
      name: 'appointment.collection_method',
      label: i18n.t('collection_method'),
      placeholder: i18n.t('collection_method'),
      disabled: true,
    },
    {
      name: 'patient_detail.gender',
      label: i18n.t('gender'),
      placeholder: i18n.t('gender'),
      disabled: !editing,
      options: getOptionsForDropdown(genders),
      as: 'dropdown',
    },
    {
      name: 'patient_detail.nationality.id',
      label: i18n.t('nationality'),
      placeholder: i18n.t('nationality'),
      disabled: !editing,
      options: getOptionsForDropdown(nationalities, 'id', 'name_i18n'),
      as: 'dropdown',
    },
    {
      name: 'patient_detail.directorate.id',
      label: i18n.t('directorate'),
      placeholder: i18n.t('directorate'),
      disabled: !editing,
      options: getOptionsForDropdown(directorates, 'id', 'name_i18n'),
      as: 'dropdown',
    },
    {
      name: 'patient_detail.isolotion_place.id',
      label: i18n.t('isolation_place'),
      placeholder: i18n.t('isolation_place'),
      disabled: !editing,
      options: getOptionsForDropdown(isolotion_places, 'id', 'name_i18n'),
      as: 'dropdown',
    },
    {
      custom: (
        <Flex flexCol justifyCenter itemsCenter style={{ padding: '18px' }}>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('is_pregnant')}
          </Text>
          <Spacer height={16} />
          <Switch
            name="is_pregnant"
            checked={isPregnant}
            onChange={(value) => setIsPregnant(value)}
            disabled={!editing}
          />
        </Flex>
      ),
    },
    isPregnant && {
      name: 'patient_detail.pregnancy_week',
      label: i18n.t('pregnancy_week'),
      placeholder: i18n.t('pregnancy_week'),
      disabled: !editing,
    },
  ].filter(Boolean);

  useEffect(() => {
    change('is_pregnant', isPregnant);
  }, [isPregnant]);

  useEffect(() => {
    setIsPregnant(
      valueGetter(orderDetails, 'patient_detail.is_pregnant') || false,
    );
    setStatus(valueGetter(orderDetails, 'status'));
  }, [orderDetails]);

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing="16px"
      sx={{ padding: '16px' }}
    >
      <FieldInfoCardList
        list={items}
        girdSizes={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 3 }}
      />
    </Grid>
  );
};

GeneralReservationInfo.propTypes = {
  editing: PropTypes.bool,
};

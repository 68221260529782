const Lab = {
  // *

  // A
  add_new: 'اضافة جديد',
  all_subscriptions: 'جميع الاشتراكات',
  all_labs: 'جميع المختبرات',
  all_patients: 'جميع المرضى',
  app_title: 'لابي',
  active: 'فعَّال',
  activated: 'مفعَّل',
  activation: 'تفعيل',
  activate_deactivate: 'تفعيل / إلغاء التفعيل',
  accept_reject: 'قبول / رفض',
  accepted: 'مقبول',
  amount: 'المبلغ',
  additional_street_name: 'إسم الشارع الإضافي',
  are_you_adding_a_new_patient: 'هل تضيف موضف جديد ؟',
  availability: 'Availability',
  add_location: 'Add Location',
  analyzer_count: 'Analyzer Count',
  activate_patient_portal: 'Activate Patient Portal',

  // B
  batch: '{{batchNumber}} Batch',
  batch_created: 'Batch تم انشاء الـ',
  batch_orders: 'Batch طلبات',
  batch_order_list_title: 'Batches',
  batch_order_list_subtitle: 'نظرة عامة على جميع العينات المحجوزة',
  batch_order_search_input_placeholder:
    'ابحث عن طريق (Batch) أو (تم إنشاؤه بواسطة)',
  billing: 'الفوترة',
  booked_orders_count: 'تم الحجز',
  b2b_invoices: 'B2B فواتير',
  b2c_invoices: 'B2C فواتير',
  building_number: 'رقم المبنى',
  billing_not_verified: 'لم يتم تأكيد الفاتورة',

  // C
  change_status: 'تغيير الحالة',
  choose_role: 'اختر الدور',
  clinic_activation: 'تفعيل العيادة',
  clinic_info_title: 'تفاصيل العيادة',
  clinic_information: 'معلومات عن العياده',
  clinic_list_title: 'جميع العيادات',
  collection_center_list_title: 'جميع مراكز التحصيل',
  collected: 'تم تجميعها',
  created: 'اضيفت',
  confirmed: 'مأكدة',
  processing: 'جاري المعالجة',
  ready: 'جاهزة',
  cancelled: 'ملغي',
  recollected: 'تم إعادة تجميعها',
  clinic_list_subtitle: 'إدارة العيادات المرتبطة بالمختبر',
  collection_center_list_subtitle: 'إدارة مراكز التحصيل المرتبطة بالمختبر',
  collection_center_patients: 'مرضى مركز الجمع',
  clinic_patients: 'مرضى العيادات',
  lab_clients_search_input_placeholder:
    'البحث بالاسم أو البريد الإلكتروني أو الهاتف',
  clinics: 'العيادات',
  completed_orders_count: 'تم الانتهاء',
  collected_orders_count: 'تم الجمع',
  collection_time: 'وقت الجمع',
  confirmed_orders_count: 'تم التأكيد',
  count: 'Count',
  create_batch: 'انشاء Batch',
  create_clinic: 'انشاء عيادة',
  create_collection_center: 'انشاء مراكز التحصيل',
  create_user: 'انشاء مستخدم',
  created_at: 'تم الإنشاء في',
  created_by: 'تم الإنشاء بوسطة',
  create_lab_1: 'إنشاء مختبر (١)',
  create_lab_2: 'إنشاء مختبر (٢)',
  choose_plan: 'اختيار الخطة',
  confirmed_orders: 'الطلبات المؤكدة',
  isolation_place: 'مكان العزل',
  current_subscription: 'الاشتراك الحالي',
  custom_dates: 'Custom Dates',
  country: 'الدولة',
  city_name: 'اسم المدينة',
  create_stock: 'Create Stock',
  client_count: 'Client Count',
  change_subscription_plan: 'Change Subscription Plan',

  // D
  deactivated: 'غير مفعَّلة',
  delete_specimen_type_message: 'Are you sure you want to delete {{name}}?',
  delete_user: 'حذف مستخدم',
  directorate: 'المديرية',
  discount_for_clinic: 'خصم للعيادة',
  duration: 'الفترة الزمنية',

  // E
  edit_clinic: 'تعديل بيانات العياده',
  edit_patient: 'تعديل بيانات المريض',
  edit_user: 'تعديل مستخدم',
  export_csv: 'تصدير CSV',
  export_as_pdf: 'تصدير كـ PDF',
  expand_free_trial_days: 'تمديد أيام التجربة المجانية',
  expire_date: 'تاريخ انتهاء الصلاحية',
  exist: 'موجود مسبقاً',
  expiry_date: 'Expiry Date',
  expiration: 'Expiration',
  edit_stock: 'Edit Stock',
  edit_location: 'Edit Location',
  edit_quantity: 'Edit Quantity',
  enter_quantity: 'Enter Quantity',
  entered_by: 'Entered By',

  // F
  from_date: 'من تاريخ',

  // G
  go_to_batches: 'الذهاب إلي batches',
  general_reservation_information: 'معلومات عامة عن الحجز',

  // H
  hospital: 'المستشفى',

  // I
  inactive: 'غير مفعَّل',
  invite_clinic_to_lab: 'دعوة العيادة إلى المختبر',
  invite_collection_center_to_lab: 'دعوة مركز التحصيل إلى المختبر',
  invite_patient_to_lab: 'دعوة المريض إلى لمختبر',
  invoices: 'الفواتير',
  invoices_list_subtitle: 'احصائيات على جميع الفواتير',
  invoices_search_input_placeholder: 'بحث عن الفواتير',
  is_pregnant: 'حامل ؟',
  item_name: 'Item name',
  item_info: 'Item info',

  // J

  // K

  // L
  lab: 'Lab',
  labs: 'المختبرات',
  lab_name: 'اسم المختبر',
  lab_accountant: 'محاسب المختبر',
  lab_accountant_manager: 'مدير حسابات المختبر',
  lab_admin: 'مدير المختبر',
  lab_doctor: 'طبيب المختبر',
  lab_operator: 'عامل المختبر',
  lab_patients: 'مرضى المختبر',
  lab_supervisor: 'مشرف المختبر',
  lab_technician: 'فني المختبر',
  lab_technologist: 'تقني المختبر',
  lab_store_keeper: 'مستودع المختبر',
  lab_list_subtitle: 'قائمة المختبرات',
  lab_login_url: 'Lab login URL',
  lab_statistics: 'إحصائيات المختبر',
  lab_search_input_placeholder: 'البحث عن اسم المختبر',
  locations: 'موقع',
  locations_table_title: 'All locations',
  locations_table_subtitle: 'Overview of all locations',
  locations_search_input_placeholder: 'Search by name',
  lot_number: 'Lot Number',

  // M
  master_reservation_list_title: 'الحجوزات',
  master_order_list_subtitle: 'نظرة عامة على جميع العينات المحجوزة',
  master_order_list_title: 'الطلبات',
  manufacturer: 'Manufacturer',
  medical_verification: 'التحقق الطبي',

  // N
  nasopharyngeal_swab: 'Nasopharyngeal swab',
  nationality: 'الجنسية',
  new_clinic: 'عيادة جديدة',
  new_collection_center: 'مركز تحصيل جديد',
  new_user: 'مستخدم جديد',
  normal: 'طبيعي',
  no_of_orders: 'عدد المرضى',
  no_of_patients: 'عدد المرضى',
  no_of_positive_results: 'عدد النتائج الإيجابية',
  no_of_negative_results: 'عدد النتائج السلبية',
  new_lab: 'مختبر جديد',
  no_decimal: 'عدد الكسور العشرية',
  new: 'جديد',
  note: 'ملاحظة',
  not_collected_by_client: 'العميل لم يجمعها بعد',

  // O
  order_id: 'رقم الطلب',
  order_info_title: 'تفاصيل الطلب',
  patient_order_info_title: 'تفاصيل الفحص',
  order_list_title: 'جميع الطلبات',
  order_list_subtitle: 'نظرة عامة على جميع العينات المحجوزة',
  order_search_input_placeholder:
    'ابحث عن المريض أو الهاتف أو الفحص أو رقم الحجز',
  order_tracking: 'تتبع الطلب',
  order_type_single: 'أحادي',
  order_type_batch: 'Batch',
  orders: 'الطلبات',
  oropharyngeal_swab: 'Oropharyngeal swab',
  overall_result: 'النتيجة العامة',
  overdue: 'متأخر',
  overview: 'الاحصائيات',
  overview_invoices_title: 'Total Invoices',
  overview_invoices_subtitle: 'Total amount of payments last {{duration}}',
  overview_overdue_title: 'الفحوصات المتأخرة',
  overview_overdue_subtitle: 'لقد تأخرت الفحوصات في مراحل مختلفة',
  overview_payments_title: 'Total Payments',
  overview_payments_subtitle: 'Total amount of payments last {{duration}}',
  overview_requests_title: 'طلبات جديدة',
  overview_requests_subtitle: 'أحدث طلبات الفحوصات وحالاتها',
  overview_stats_title: 'كل الفحوصات',
  overview_stats_subtitle: 'لمحة عامة عن أرقام هذا الشهر',
  orders_search_input_placeholder:
    'ابحث عن المريض أو الهاتف أو الطلب أو رقم الطلب',
  original_quantity: 'Original Qty.',
  original_quantity_edit_label: 'Enter quantity to add',

  // P
  passport_number: 'رقم جواز السفر',
  password: 'كلمة المرور',
  patient: 'المريض',
  patient_information: 'معلومات المريض',
  patient_order_list_title: 'كل الفحوصات',
  patient_order_list_subtitle: 'نظرة عامة على جميع العينات المحجوزة',
  patient_order_search_input_placeholder:
    'البحث عن طريق المريض أو الفحص أو رقم الحجز',
  patient_source: 'مصدر المريض',
  payment_type: 'طريقة الدفع',
  pcr_batches: 'PCR Batches',
  position: 'الموقع',
  print_barcode: 'طباعة الباركود',
  print_invoice: 'طباعة الفاتورة',
  print_result: 'طباعة النتائج',
  printing: 'طباعة...',
  processing_orders_count: 'تحت المعالجة',
  processed: 'تمت المعالجة',
  plan: 'خطة',
  personal_information: 'معلومات خاصه',
  print: 'طباعة',
  postal_zone: 'المنطقة البريدية',
  pregnancy_week: 'اسبوع الحمل',
  paid_subscription: 'اشتراك مدفوع',
  purchase_date: 'Purchase Date',
  purchase_info: 'Purchase Info',
  patient_portal_deactivate: 'Request Patient Portal Deactivation',
  patient_portal_activate: 'Request Patient Portal Activation',

  // Q

  // R
  reason: 'السبب',
  reason_for_cancelled: 'سبب الجمع',
  reason_for_canceled: 'سبب الجمع',
  reason_for_rejected: 'سبب الرفض',
  reason_placeholder: 'نوع...',
  refund: 'استرداد المبلغ',
  refund_message: 'قم بتأكيد طلب استرداد المبلغ',
  refunded_b2b_invoices: 'فواتير B2B المستردة',
  refunded_b2c_invoices: 'فواتير B2C المستردة',
  remove_from_batch: 'حذف من الـ batch',
  report_income_finance: 'Income Finance',
  report_invoice_finance: 'Invoice Finance',
  report_b2b_finance: 'B2B Finance',
  report_invoices: 'الفواتير',
  report_results: 'النتائج',
  report_sales: 'المبيعات',
  report_source_reports: 'تقارير المصدر',
  report_total_amounts: 'المبالغ الإجمالية',
  report_user_performance: 'اداء المستحخدم',
  reports: 'التقارير',
  reports_list_title: 'كل التقارير',
  resend_reservation_detail: 'Resend Reservation Detail',
  reservations: 'الحجوزات',
  reservation_date: 'تاريخ الحجز',
  reservation_id: 'رقم الحجز',
  reservation_info_title: 'تفاصيل الحجز',
  reservation_list_title: 'مرضى الحجز',
  reservation_list_subtitle: 'لمحة عامة عن جميع المرضى الحاليين',
  reservation_search_input_placeholder: 'البحث عن مريض أو هاتف أو رقم الحجز',
  reservation_test_list_search_input_placeholder:
    'البحث عن اسم الفحص او LIS كود',
  result: 'النتيجة',
  result_entered: 'النتيجة المدخلة',
  result_negative: 'سلبي (-)',
  result_pending: 'قيد الانتظار',
  result_positive: 'إيجابي (+)',
  results: 'النتائج',
  results_list_subtitle: ' احصائيات على جميع النتائج',
  results_search_input_placeholder: 'البحث بالعيادة أو المختبر أو المريض',
  role: 'الدور',
  rejected: 'مرفوض',
  reserved_orders: 'الطلبات المحجوزة',
  renew: 'تجديد',
  renew_subscription: 'تجديد الإشتراك',
  reservation_info_test_title: 'تفاصيل فحص الحجز',
  reservation_info_packages_title: 'تفاصيل حزمة الحجز',
  remaining_quantity: 'Remaining Qty.',
  received_by: 'Received By',
  reset_stock: 'Reset Stock',
  reset_stock_message: 'Are you sure you want to reset the stock quantity?',
  remaining_quantity_edit_label: 'Enter used count',
  roles: 'Roles',
  request_analyzer_update: 'Request Analyzer Update',
  request_user_update: 'Request User Update',
  request_client_update: 'Request Client Update',

  // S
  sample_id: 'رقم تعريف العينة',
  sales_list_subtitle: 'احصائيات على كل المبيعات',
  sales_search_input_placeholder: 'Search by patient name, phone or email',
  search_phone_email: 'Search by phone or email',
  send_invitation: 'إرسال دعوة',
  send_result: 'إرسال النتيجة',
  settings: 'الإعدادات',
  share: 'رابط المشاركة',
  source: 'Source',
  source_reports_list_subtitle: 'احصائيات على جميع التقارير',
  specimen_name: 'Specimen Name',
  specimen_types: 'Specimen Types',
  specimen_type_title: 'Specimen Type',
  specimen_type_subtitle: 'Overview of all specimen types',
  specimen_type_input_placeholder: 'Search by name',
  stocks: 'مخزون',
  super_admin: 'مسؤول أول',
  summary: 'ملخص',
  subscription_monthly: 'شهرياً',
  subscription_quarterly: 'ربع سنوي',
  subscription_half_yearly: 'نصف سنوي',
  subscription_yearly: 'سنوي',
  subscription_trial: 'تجريبي',
  subscriptions: 'الإشتراكات',
  subscriptions_list_subtitle: 'قائمة الإشتراكات',
  street_name: 'اسم الشارع',
  single_orders: 'طلبات احادية',
  subscription_type: 'نوع الاشتراك',
  stocks_table_title: 'All Stocks',
  stocks_table_subtitle: 'Overview of all stocks',
  stocks_search_input_placeholder: 'Search by name, serial number, lot number, manufacturer',
  serial_number: 'Serial Number',
  supplier_name: 'Supplier Name',
  supplier_phone: 'Supplier Phone',
  supplier_email: 'Supplier Email',
  stock_detail_title: 'Stock Details',
  submitted: 'تم الإرسال',
  serial: 'المحلل',
  Analyzers: '',
  serial_settings: 'اعدادات المحلل',
  subscription_plan: 'Subscription Plan',

  // T
  test: 'الفحص',
  test_result: 'نتيجة الفحص',
  tests: 'الفحوصات',
  packages: 'الحزم',
  this_week: 'هذا الإسبوع',
  this_month: 'هذا الشهر',
  this_year: 'هذه السنة',
  to_date: 'الى تاريخ',
  total_income: 'إجمالي الدخل',
  total_paid_amount: 'اجمالي المبلغ المدفوع',
  total_canceled_amount: 'Total Cancelled Amount',
  total_canceled_and_refunded_amount: 'Total Canceled & Refunded Amount',
  total_amounts_list_subtitle: 'احصائيات على جميع النتائج',
  total_amounts_search_input_placeholder: 'البحث بالعيادة أو المختبر أو المريض',
  total_reserved_orders: 'عدد المرضى للمختبر',
  total_tests: 'اجمالي الفحوصات',
  test_list_info: 'قائمة فحوصات الحجز',
  trial_subscription: 'اشتراك تجريبي',
  tech_verification: 'التحقق الفني',

  // U
  update_results: 'تحديث النتائج',
  urgent: 'عاجل',
  user: 'مستخدم',
  user_activation: 'تنشيط المستخدم',
  user_list_title: 'جميع المستخدمين',
  user_list_subtitle: 'إدارة المسؤولين والمستخدمين',
  user_name_en: 'اسم المستخدم [en]',
  user_name_ar: 'اسم المستخدم [ar]',
  user_performance_list_subtitle: 'احصائيات على أداء المستخدم',
  user_performance_search_input_placeholder:
    'البحث بالاسم أو الهاتف أو البريد الإلكتروني',
  user_search_input_placeholder: 'ابحث بالاسم أو الهاتف أو البريد الإلكتروني',
  users: 'المستخدمين',
  user_role: 'User Role',
  update_config: 'Update Config',
  update_subscription_plan: 'Update Subscription Plan',
  user_count: 'User Count',

  // V
  view_unpaid_only: 'عرض غير المدفوع فقط',
  value: 'القيمة',
  verify_all: 'Verify All',

  // W

  // X

  // Y

  // Z
};

export default Lab;

import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Card, Flex, Text} from 'atoms';
import {ReactComponent as CashMoney} from 'assets/icons/reservations/cash-money.svg';
import {ReactComponent as CardPos} from 'assets/icons/reservations/card-pos.svg';
import {ReactComponent as MadaIcon} from 'assets/icons/reservations/mada.svg';
import './styles.scss';

const PaymentInfo = ({mode}) => {
  return (
    <Card className="payment-info-card">
      <Flex itemsCenter>
        {mode === 'cash' && <CashMoney/>}
        {mode === 'credit_card' && <CardPos/>}
        {mode === 'MADA' && <MadaIcon/>}
        <Text style={{marginLeft: '0.5rem'}} typography="caption12" weight="bold">
          {i18n.t('payment_mode_text', {mode})}
        </Text>
      </Flex>
    </Card>
  );
};

PaymentInfo.propTypes = {
  mode: PropTypes.string,
};

export default PaymentInfo;

export const BILLINGS = 'BILLINGS';
export const BILLINGS_PENDING = 'BILLINGS_PENDING';
export const BILLINGS_FULFILLED = 'BILLINGS_FULFILLED';
export const BILLINGS_REJECTED = 'BILLINGS_REJECTED';

export const RESET_BILLINGS = 'RESET_BILLINGS';

export const INVOICE = 'INVOICE';
export const INVOICE_PENDING = 'INVOICE_PENDING';
export const INVOICE_FULFILLED = 'INVOICE_FULFILLED';
export const INVOICE_REJECTED = 'INVOICE_REJECTED';

export const INVOICES = 'INVOICES';
export const INVOICES_PENDING = 'INVOICES_PENDING';
export const INVOICES_FULFILLED = 'INVOICES_FULFILLED';
export const INVOICES_REJECTED = 'INVOICES_REJECTED';

export const B2B_INVOICES = 'B2B_INVOICES';
export const B2B_INVOICES_PENDING = 'B2B_INVOICES_PENDING';
export const B2B_INVOICES_FULFILLED = 'B2B_INVOICES_FULFILLED';
export const B2B_INVOICES_REJECTED = 'B2B_INVOICES_REJECTED';

export const BILLING_VERIFY = 'BILLING_VERIFY';
export const BILLING_VERIFY_PENDING = 'BILLING_VERIFY_PENDING';
export const BILLING_VERIFY_FULFILLED = 'BILLING_VERIFY_FULFILLED';
export const BILLING_VERIFY_REJECTED = 'BILLING_VERIFY_REJECTED';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_PENDING = 'GET_INVOICE_PENDING';
export const GET_INVOICE_FULFILLED = 'GET_INVOICE_FULFILLED';
export const GET_INVOICE_REJECTED = 'GET_INVOICE_REJECTED';

export const BILLING_REFUND = 'BILLING_REFUND';
export const BILLING_REFUND_PENDING = 'BILLING_REFUND_PENDING';
export const BILLING_REFUND_FULFILLED = 'BILLING_REFUND_FULFILLED';
export const BILLING_REFUND_REJECTED = 'BILLING_REFUND_REJECTED';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import { ORDER_STATUS, REPORT } from 'models';
import { getReportList } from 'redux/report/actionCreators';
import { dateTimeQueryFormatToDate, dateTimeToQueryFormat } from 'utils/date';
import { GENDERS, PRIORITIES } from 'utils/options';
import { generateOptionsFromEnum } from 'utils/labUtils';

import { ListFilters } from 'components/hoc';
import ReportFilters from '../ReportFilters';
import ReportTable from './ReportTable';

const selectFilters = [
  {
    key: 'gender',
    options: GENDERS,
    placeholderKey: 'gender',
  },
  {
    key: 'priority',
    options: PRIORITIES,
    placeholderKey: 'priority',
  },
  {
    key: 'status',
    options: generateOptionsFromEnum(ORDER_STATUS),
    placeholderKey: 'status',
  }
];

const SalesReports = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const appliedFilters = {
    patient_by: query.patient_by,
    from_date: query.from_date
      ? dateTimeQueryFormatToDate(query.from_date)
      : null,
    to_date: query.to_date ? dateTimeQueryFormatToDate(query.to_date) : null,
    source: query.source,
    search: query.search,
    gender: query.gender,
    priority: query.priority,
    status: query.status,
  };

  const fetchReports = (params = {}) => {
    dispatch(getReportList(REPORT.SALES, params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      patient_by: filter.patient_by,
      from_date: filter.from_date
        ? dateTimeToQueryFormat(filter.from_date)
        : null,
      to_date: filter.to_date ? dateTimeToQueryFormat(filter.to_date) : null,
      source: filter.source,
      search: filter.search,
      gender: filter.gender,
      priority: filter.priority,
      status: filter.status,
    };
    setQuery(params);
    fetchReports(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: fetchReports
  });

  useEffect(() => {
    fetchReports(appliedFilters);
  }, []);

  return (
    <>
      <ListFilters
        Wrapped={ReportFilters}
        reportType={REPORT.SALES}
        showSearch
        showSourceFilter
        selectFilters={selectFilters}
        filters={appliedFilters}
        onChange={handleFilterChange}
      />
      <ReportTable onPagination={handlePagination} />
    </>
  );
};

export default SalesReports;

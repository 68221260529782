import {get} from 'lodash';

import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  labDetails: {}
};

export default function reducer(
  state = initialState,
  action
) {
  switch (action.type) {
  case actions.LAB_DETAILS_PENDING:
    return {
      ...state,
      fetching: true
    };

  case actions.LAB_DETAILS_FULFILLED:
    return {
      ...state,
      labDetails: get(action, 'payload.data.service_provider'),
      fetching: false
    };

  case actions.LAB_DETAILS_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      fetching: false
    };

  default: return state;
  }
}
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {getLocations, getStockDetails} from 'redux/inventory/actionCreators';

import LabHeader from 'components/global/LabHeader';
import {StockDetails} from 'components/stocks';

const InventoryDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getStockDetails(id));
    dispatch(getLocations());
  }, [id]);

  return (
    <>
      <LabHeader title="stocks"/>
      <StockDetails stockId={id}/>
    </>
  );
};

export default InventoryDetail;
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { tags, weightOptions } from './helper';
import 'styles/styleTokens.css';
import styles from './styles.module.css';

const textCases = {
  uppercase: 'uppercase',
  lowercase: 'lowercase',
  capitalize: 'capitalize',
};

const Text = React.forwardRef(({
  children,
  as,
  typography,
  className,
  textCase,
  color,
  weight,
  onClick,
  style,
  link,
  center,
  ...rest
}, ref) => {
  const typeface = tags[typography] || as; // in case if typography not existing in the tags list
  const Tag = as || typeface;
  const thick = React.useMemo(() => (weightOptions.includes(weight) ? weight : 'medium'), [weight]);

  return (
    <Tag
      ref={ref}
      {...rest}
      onClick={onClick}
      style={{
        color: link ? 'var(--brand-text-primary)' : color,
        cursor: onClick && 'pointer',
        textTransform: textCases[textCase] || 'unset',
        textAlign: center ? 'center' : 'auto',
        ...style, // for previous work to not modify
      }}
      className={clsx(styles[typography], `weight_${thick}`, className)}
    >
      {children}
    </Tag>
  );
});

Text.defaultProps = {
  typography: 'headline',
  as: 'p',
};

Text.propTypes = {
  style: PropTypes.shape(),
  as: PropTypes.node,
  children: PropTypes.node,
  typography: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  onClick: PropTypes.func,
  textCase: PropTypes.string,
  link: PropTypes.bool,
  center: PropTypes.bool,
};

export default Text;

import { Grid } from '@mui/material';
import { Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';
import PropTypes from 'prop-types';
import { isBrowser, isMobile } from 'react-device-detect';
import i18n from 'translation/i18n';
import { ACTIVE_STATUSES, PRIORITIES } from 'utils/options';
import React from 'react';
import NewPackage from './NewPackage/NewPackage';

const selectFilterConfigs = [
  { key: 'status', options: ACTIVE_STATUSES, placeholderKey: 'status' },
  { key: 'priority', options: PRIORITIES, placeholderKey: 'priority' },
];

const PackageFilters = ({ generateOtherFilters, generateSelectFilters }) => {
  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={2}
      alignItems="center"
    >
      <Grid item xs={8} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('all_packages')}
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={4}>
          <NewPackage />
        </Grid>
      )}
      <Grid item xs={9} lg={4}>
        <SearchInput
          placeholder={i18n.t('packages_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && <Grid item lg={2} />}

      <Grid item xs={3} lg={1}>
        <SelectFilter
          selectFilterConfigs={selectFilterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
      <Grid item lg={2}>
        <NewPackage />
      </Grid>
    </Grid>
  );
};

PackageFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
};

export default PackageFilters;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import {
  getClinics,
  getCollectionCenters,
  getMasterReservations,
} from 'redux/lab/actionCreators';
import { ListFilters } from 'components/hoc';
import { Card } from 'atoms';
import { dateQueryFormatToDate, dateToQueryFormat } from 'utils/date';
import { isLabUser } from 'utils/labUtils';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import {
  MasterReservationFilters,
  MasterReservationTable,
} from './masterReservation';

export const MasterReservationList = ({ module }) => {
  const dispatch = useDispatch();
  const isLab = isLabUser();
  const { query, setQuery } = useURLQuery();

  const loadReservations = useCallback(
    (params = {}) => {
      dispatch(getMasterReservations({ ...params, patient_by: module }));
    },
    [getMasterReservations, dispatch, module],
  );

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      result: filter.result,
      status: filter.status,
      priority: filter.priority,
      clinic_id: filter.clinic,
      collection_center_id: filter.collection_center,
      from_date: filter.from_date ? dateToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateToQueryFormat(filter.to_date) : null,
    };

    loadReservations(params);
    setQuery(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: loadReservations,
  });

  React.useEffect(() => {
    const params = {
      search: query?.search,
      result: query?.result,
      status: query?.status,
      priority: query?.priority,
      clinic_id: query?.clinic_id,
      collection_center_id: query?.collection_center_id,
      from_date: query?.from_date
        ? dateQueryFormatToDate(query?.from_date)
        : null,
      to_date: query?.to_date ? dateQueryFormatToDate(query?.to_date) : null,
      page: query?.page,
    };
    handleFilterChange(params);
  }, [module]);

  React.useEffect(() => {
    if (isLab) {
      dispatch(getClinics());
      dispatch(getCollectionCenters());
    }
  }, []);

  const content = (
    <>
      <ListFilters
        Wrapped={MasterReservationFilters}
        onChange={handleFilterChange}
      />
      <MasterReservationTable onPagination={handlePagination} />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

MasterReservationList.propTypes = {
  module: PropTypes.string,
};

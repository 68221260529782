import {
  moduleBaseUrl,
  handleAPI,
  API_VERSION_ONE,
  removeEmptyParams,
} from 'utils/apiUtils';

import * as actions from './actions';

export function getMasterOrders(params = {}) {
  const url = `/${moduleBaseUrl()}/master_orders/confirm_reservations`;
  const method = 'GET';

  return {
    type: actions.MASRTER_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getPatientOrders(params = {}) {
  const url = `/${API_VERSION_ONE}/patient/master_orders`;
  const method = 'GET';

  return {
    type: actions.PATIENT_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getSingleOrders(params = {}) {
  const url = `${moduleBaseUrl()}/orders`;
  const method = 'GET';

  return {
    type: actions.SINGLE_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getMasterSingleOrders(order_id, params = {}) {
  const url = `${moduleBaseUrl()}/master_orders/${order_id}/orders`;
  const method = 'GET';

  return {
    type: actions.SINGLE_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function createBatchOrder(orderIds) {
  const url = `${moduleBaseUrl()}/batches`;
  const method = 'POST';
  const payload = {
    batch: {
      order_ids: orderIds,
    },
  };

  return {
    type: actions.CREATE_BATCH_ORDER,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function getBatchOrders(params = {}) {
  const url = `${moduleBaseUrl()}/batches`;
  const method = 'GET';

  return {
    type: actions.BATCH_ORDER_LIST,
    payload: handleAPI(url, params, method),
  };
}

export function getBatchOrder(batchId) {
  const url = `${moduleBaseUrl()}/batches/${batchId}`;
  const method = 'GET';

  return {
    type: actions.GET_BATCH_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function removeOrdersFromBatch(batchId, orderIds) {
  const url = `${moduleBaseUrl()}/batches/${batchId}`;
  const method = 'PUT';
  const data = {
    order_ids: {
      removed: orderIds,
    },
  };

  return {
    type: actions.REMOVE_ORDER_FROM_BATCH,
    payload: handleAPI(url, {}, method, data),
  };
}

export function updateBatchOrderResult(batchId, result, order_ids) {
  const url = `${moduleBaseUrl()}/batches/${batchId}`;
  const method = 'PUT';
  const data = {
    result,
    ...(order_ids ? { order_ids: { selected: order_ids } } : {}),
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getPatientOrder(orderId) {
  const url = `/${API_VERSION_ONE}/patient/master_orders/${orderId}`;
  const method = 'GET';

  return {
    type: actions.GET_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function getOrder(orderId) {
  const url = `${moduleBaseUrl()}/orders/${orderId}`;
  const method = 'GET';

  return {
    type: actions.GET_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function updateOrderResult(orderId, result) {
  const url = `${moduleBaseUrl()}/orders/${orderId}/send_result`;
  const method = 'PUT';
  const data = {
    order: {
      result,
    },
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function printOrderResult(masterOrderId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/print_result`;
  const method = 'GET';

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function exportBatchAsPDF(batchId) {
  const url = `${moduleBaseUrl()}/batches/${batchId}/print_batch`;
  const method = 'GET';

  return {
    type: actions.EXPORT_PATCH_AS_PDF,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function sendOrderResult(masterOrderId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/send_pdf_result`;
  const method = 'GET';

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function sendReservationDetail(masterOrderId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/resend/reservation_details`;
  const method = 'POST';

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function sendMasterOrderTestResult(masterOrderId, orderId, data) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/orders/${orderId}/send_result`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function updateOrderStatus(orderId, status) {
  const url = `${moduleBaseUrl()}/orders/${orderId}`;
  const method = 'PUT';
  const data = {
    order: {
      status,
    },
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function rejectOrder(orderId, reason) {
  const url = `${moduleBaseUrl()}/orders/${orderId}/reject`;
  const method = 'PUT';
  const data = {
    reason,
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function cancelOrder(orderId, reason) {
  const url = `${moduleBaseUrl()}/orders/${orderId}/cancel`;
  const method = 'PUT';
  const data = {
    reason,
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function resendHesn(data) {
  const url = `${moduleBaseUrl()}/hesn/resend`;
  const method = 'POST';

  return {
    type: actions.RESEND_HESN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function verifySampleId(params = {}) {
  const url = '/v1/lab/batches/verification';
  const method = 'GET';

  return {
    type: actions.VERIFY_ORDER,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { required } from 'utils/FormValidations';
import { subscriptionOrder } from 'utils/labUtils';
import {
  changeServiceProviderSubscription,
  getLabSubscriptions,
  getPlans,
} from 'redux/admin/actionCreators';

import { Button, Modal, Spacer } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';
import { SelectField } from 'components/global/final-form';

const SubscriptionChangeModal = ({ serviceProviderId, subscriptionId, currentPlanId, onClose }) => {
  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.admin);
  const [submitting, setSubmitting] = React.useState(false);
  const currentPlanName = React.useMemo(() => {
    const plan = plans.find((p) => p.id === currentPlanId);
    return plan?.name || null;
  }, [plans, currentPlanId]);

  const onSubmit = (values) => {
    const plan = plans.find((p) => p.id === values.subscription);
    const payload = {
      action_change: subscriptionOrder.indexOf(plan.name) > subscriptionOrder.indexOf(currentPlanName) ? 'upgraded' : 'downgraded',
      subscription_id: plan.id,
    };
    dispatch(
      changeServiceProviderSubscription(
        serviceProviderId,
        subscriptionId,
        payload
      )
    ).then(() => {
        dispatch(getLabSubscriptions(serviceProviderId));
        onClose();
      })
      .finally(() => setSubmitting(false));
  };

  React.useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <Modal
      disableCloseOut
      isOpen
      width={350}
      header={<ModalHeader title={i18n.t('change_subscription_plan')} />}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit} initialValues={{ subscription: currentPlanId }}>
        {({handleSubmit, values}) => (
          <form onSubmit={handleSubmit}>
            <ModalContent>
              <SelectField
                name="subscription"
                label={i18n.t('subscription_plan')}
                placeholder={i18n.t('subscription_plan')}
                options={plans.map((p) => ({ label: p.name_text, value: p.id }))}
                validate={required}
              />
            </ModalContent>
            <Spacer
              height={1}
              width="100%"
              style={{ backgroundColor: 'var(--brand-divider-white)' }}
            />
            <ModalContent>
              <Button
                label={i18n.t('submit')}
                disabled={submitting || values.subscription === currentPlanId}
                onClick={() => onSubmit(values)}
              />
            </ModalContent>
          </form>
        )}
      </Form>
    </Modal>
  );
};

SubscriptionChangeModal.propTypes = {
  serviceProviderId: PropTypes.string,
  subscriptionId: PropTypes.string,
  currentPlanId: PropTypes.string,
  onClose: PropTypes.func,
};

export default SubscriptionChangeModal;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import i18n from 'translation/i18n';
import { PERMISSION, ORDER_STATUS, VERIFICATION_STATUS, USER_ROLE } from 'models';
import { getStatusColor } from 'utils/labUtils';
import { userHasPermission } from 'utils/permissionUtils';
import { getAuthUser } from 'redux/auth/actionCreators';
import { verifyMasterOrderTest } from 'redux/lab/actionCreators';

import { Grid, IconButton } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Flex, Spacer, Tag, Text } from 'atoms';

const VerificationTag = ({
  onlyTag,
  masterOrderId,
  showVerifyButton,
  testInfo,
  type,
  onRefetch
}) => {
  const dispatch = useDispatch();
  const { role_label } = getAuthUser();
  const { id, status, verified, verification_type } = testInfo;

  const isTechnical = type === PERMISSION.TECHNICAL_VERIFY;
  const label =  isTechnical ? 'tech_verification' : 'medical_verification';
  const isSubmitted = status === ORDER_STATUS.SUBMITTED;
  const hasPermission = userHasPermission(type);
  const showVerify = isSubmitted || (status === ORDER_STATUS.READY && verified);

  const showBtn = React.useMemo(() => {
    if (!showVerifyButton || !hasPermission) return false;
    if (isTechnical) return isSubmitted;
    return verification_type !== 'medical';
  }, [showVerifyButton, hasPermission, isTechnical, isSubmitted, verification_type]);

  const tag = React.useMemo(() => {
    if (isTechnical && verified) return VERIFICATION_STATUS.VERIFIED;
    if (!isTechnical && verification_type === 'medical') return VERIFICATION_STATUS.VERIFIED;
    return VERIFICATION_STATUS.PENDING;
  }, [isTechnical, verification_type]);

  const handleVerify = () => {
    dispatch(verifyMasterOrderTest(masterOrderId, id)).then(() => {
      if (!verified && !isTechnical && role_label === USER_ROLE.LAB_ADMIN) {
        dispatch(verifyMasterOrderTest(masterOrderId, id)).then(onRefetch);
      } else {
        onRefetch();
      }
    });
  };

  const content = (
    <Flex itemsCenter>
      <Tag text={tag} color={getStatusColor(tag)} />
      <Spacer width={8} height={40}/>
      {showBtn && (
        <IconButton
          color="success"
          onClick={handleVerify}
        >
          <CheckCircle/>
        </IconButton>
      )}
    </Flex>
  );

  if (onlyTag && showVerify) return content;

  return showVerify && (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
      <Text
        typography="caption12"
        as="span"
        weight="bold"
        color="var(--brand-text-gray-light)"
        textCase="uppercase"
      >
        {i18n.t(label)}
      </Text>
      {content}
    </Grid>
  );
};

VerificationTag.defaultProps = {
  onlyTag: false,
};

VerificationTag.propTypes = {
  onlyTag: PropTypes.bool,
  masterOrderId: PropTypes.string,
  showVerifyButton: PropTypes.bool,
  testInfo: PropTypes.shape(),
  onRefetch: PropTypes.func,
  type: PropTypes.oneOf([PERMISSION.MEDICAL_VERIFY, PERMISSION.TECHNICAL_VERIFY])
};

export default VerificationTag;
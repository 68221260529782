import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ListFilters = ({ Wrapped, onChange, ...rest }) => {
  const [selectFilters, setSelectFilters] = useState({});
  const [otherFilters, setOtherFilters] = useState({});

  const generateOtherFilters = (key, value) => {
    let appliedFilters = otherFilters;
    if (value) {
      appliedFilters = { ...appliedFilters, [key]: value };
    } else {
      delete appliedFilters[key];
    }
    setOtherFilters(appliedFilters);
    onChange({
      ...selectFilters,
      ...appliedFilters,
    });
  };

  const generateSelectFilters = (filters) => {
    setSelectFilters(filters);
    onChange({
      ...otherFilters,
      ...filters,
    });
  };

  return (
    <Wrapped
      generateSelectFilters={generateSelectFilters}
      generateOtherFilters={generateOtherFilters}
      {...rest}
    />
  );
};

ListFilters.propTypes = {
  Wrapped: PropTypes.func,
  onChange: PropTypes.func,
};

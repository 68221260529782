import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Checkbox } from 'atoms';

const CheckboxField = ({
  name,
  validate,
  onChange,
  ...rest
}) => (
  <Field
    name={name}
    type="checkbox"
    validate={validate}
  >
    {({ input }) => (
      <Checkbox
        {...input}
        {...rest}
        onChange={(e) => {
          if (onChange) onChange(e);
          input.onChange(e);
        }}
      />
    )}
  </Field>
);

CheckboxField.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckboxField;

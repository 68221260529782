import React, {useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Grid} from '@mui/material';
import {Button, Card, Text} from 'atoms';
import {isMobile} from 'react-device-detect';
import {copyToClipboard} from '../utils/labUtils';

const ShareLink = ({titleKey, link, styles}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const handleCopy = () => {
    copyToClipboard(link)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 3000);
      });
  };

  return (
    <Card styles={{margin: `${isMobile ? '2rem 1rem' : '2rem'}`, padding: '1rem 1.5rem', ...styles}}>
      <Text typography="subtitle" weight="bold">{i18n.t(titleKey)}</Text>
      <Grid container mt={1} spacing={2}>
        <Grid item xs={12} lg={6} display="flex" alignItems="center" style={{backgroundColor: 'var(--brand-card-bg)'}}>
          <Text as="span" typography="caption" color="var(--brand-text-gray)">{link}</Text>
        </Grid>
        <Grid item xs={6} lg={2}>
          <Button variant="secondary" label={i18n.t('copy_link')} onClick={handleCopy}/>
        </Grid>
        <Grid item xs={6} lg={2} display="flex" alignItems="center">
          {showSuccess && (
            <Text as="span" typography="caption" color="var(--brand-success)">
              {i18n.t('copy_success')}
            </Text>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

ShareLink.defaultProps = {
  styles: {}
};

ShareLink.propTypes = {
  titleKey: PropTypes.string,
  link: PropTypes.string,
  styles: PropTypes.shape()
};

export default ShareLink;
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Button, Modal, TextInput} from 'atoms';
import { ModalContent, ModalHeader } from 'templates';

const ValueEditModal = ({ showModal, initialValue, header, label, placeholder, onClose }) => {
  const [value, setValue] = React.useState();

  const handleClose = (shouldUpdate) => {
    onClose(shouldUpdate, value);
    setValue();
  };

  useEffect(() => {
    setValue(initialValue);
  }, []);

 return showModal && (
   <Modal
    isOpen={showModal}
    disableCloseOut
    width={240}
    header={<ModalHeader title={header} />}
    footer={<Button
      variant="secondary"
      label={i18n.t('submit')}
      onClick={() => handleClose(true)}
    />}
    onClose={() => handleClose(false)}
   >
     <ModalContent style={{ height: '10rem' }}>
       <TextInput
        type="number"
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
       />
     </ModalContent>
   </Modal>
 );
};

ValueEditModal.propTypes = {
  showModal: PropTypes.bool,
  initialValue: PropTypes.number,
  header: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onClose: PropTypes.func,
};

export default ValueEditModal;
import React from 'react';
import { snakeCase } from 'lodash';
import i18n from 'translation/i18n';
import { CLIENT_TYPE } from 'models';
import { valueGetter } from 'utils/uiHelpers';
import { CLIENT_TYPES } from 'utils/options';
import { Flex, RadioBox, Spacer, Text } from 'atoms';
import { InvoiceContent } from 'components/b2b-invoice';
import { useURLQuery } from 'hooks/useURLQuery';

const clientTypes = [CLIENT_TYPE.CLINICS, CLIENT_TYPE.COLLECTION_CENTERS];

export const B2BInvoices = () => {
  const { query, setQuery } = useURLQuery();
  const client = query?.client || valueGetter(CLIENT_TYPES, '0.value');

  const setClient = (value) => {
    setQuery({ client: value });
  };

  return (
    <>
      <Flex flexCol style={{ paddingLeft: '2rem', marginBottom: '1rem' }}>
        <Text typography="paragraph16">{i18n.t('client')}</Text>
        <Flex itemsCenter>
          <RadioBox
            label={i18n.t(clientTypes[0])}
            value={clientTypes[0]}
            checked={client === clientTypes[0]}
            onChange={(value) => setClient(value)}
          />
          <Spacer width={24} />
          <RadioBox
            label={i18n.t(snakeCase(clientTypes[1]))}
            value={clientTypes[1]}
            checked={client === clientTypes[1]}
            onChange={(value) => setClient(value)}
          />
        </Flex>
      </Flex>
      <InvoiceContent client={client} />
    </>
  );
};

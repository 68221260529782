import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import { omit } from 'lodash';

import i18n from 'translation/i18n';
import { getPricesLoad } from 'redux/patients/actionCreators';
import { getLastChars, isPatient } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

import { Flex, RadioBox, Spacer, Text } from 'atoms';
import { PriceSummary } from 'templates/OrderSummaryTemplate';
import { ReactComponent as CashMoneySelected } from 'assets/icons/reservations/cash-money-selected.svg';
import { ReactComponent as CashMoney } from 'assets/icons/reservations/cash-money.svg';
import { ReactComponent as CardPosSelected } from 'assets/icons/reservations/card-pos-selected.svg';
import { ReactComponent as CardPos } from 'assets/icons/reservations/card-pos.svg';
import { ReactComponent as MadaCard } from 'assets/icons/reservations/mada-card.svg';
import { ReactComponent as ApplePay } from 'assets/icons/reservations/apple-pay-card.svg';
import { ReactComponent as FilledCircle } from 'assets/icons/reservations/filled-circle.svg';
import AddCardModal from './AddCardModal';

const Step4 = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [payMethod, setPayMethod] = useState('cash');
  const { change } = useForm();
  const { values } = useFormState();
  const { prices } = useSelector((state) => state.patient);
  const isPatientUser = isPatient();

  const onPaymentChanged = (vals) => {
    const payment = {
      ...values.payment,
      ...vals.payment,
    };
    change('payment.card_number', payment.card_number);
    change('payment.name_on_card', payment.name_on_card);
    change('payment.expiry', payment.expiry);
    change('payment.cvv', payment.cvv);
    change('payment.type', payMethod);
    setOpenModal(false);
  };

  const getPrice = (serviceTypeIds) => {
    const nationality_id = valueGetter(values, 'patient.nationality_id');
    dispatch(
      getPricesLoad(
        { nationality_id },
        serviceTypeIds,
      ),
    );
  };

  const setPaymentDetails = () => {
    const packageOrders = values?.orders_package?.flatMap((p) =>
      p?.services?.map((s) => ({
        ...s,
        package_id: p.package_id,
      })),
    );
    const orders = [...(values?.orders || []), ...(packageOrders || [])];
    const serviceTypeIds = orders.map((order) => order.priority_id);
    getPrice(serviceTypeIds);
  };

  useEffect(() => {
    if (payMethod === 'cash') {
      change('payment', omit(values.payment, ['card_number', 'name_on_card', 'expiry', 'cvv']));
    }
    change('payment.type', payMethod);
  }, [payMethod]);

  useEffect(() => {
    if (values.payment?.type && values.payment?.type !== 'cash') {
      setPayMethod('transaction');
    } else {
      setPayMethod('cash');
    }
    setPaymentDetails();
  }, []);

  useEffect(() => {
    const calculatedPrice = prices.reduce(
      (acc, pr) => ({
        tax: acc.tax + Number(pr.tax),
        sub_total: acc.sub_total + Number(pr.sub_total),
        total: acc.total + Number(pr.total),
        discount_amount: acc.discount_amount + 0,
      }),
      {
        tax: 0,
        sub_total: 0,
        total: 0,
        discount_amount: 0,
      },
    );
    change('payment.tax', calculatedPrice.tax);
    change('payment.sub_total', calculatedPrice.sub_total);
    change('payment.amount', calculatedPrice.total);
    change('payment.total', calculatedPrice.total);
    change('payment.discount_amount', calculatedPrice.discount_amount);
  }, [prices]);

  return (
    <>
      <Spacer height={8} />
      <PriceSummary price={values.payment} />
      <Spacer height={48} />

      <Text
        typography="paragraph14"
        color="var(--brand-text-gray-light)"
        weight="bold"
      >
        {i18n.t('payment_method').toUpperCase()}
      </Text>
      <Spacer height={6} />

      <RadioBox
        name="payment.type"
        label={i18n.t('cash')}
        variant="large"
        checked={payMethod === 'cash'}
        startIcon={payMethod === 'cash' ? <CashMoneySelected /> : <CashMoney />}
        onChange={() => setPayMethod('cash')}
      />
      <Spacer height={20} />
      <RadioBox
        name="payment.type"
        label={i18n.t('transaction')}
        variant="large"
        checked={payMethod === 'transaction'}
        startIcon={
          payMethod === 'transaction' ? <CardPosSelected /> : <CardPos />
        }
        onChange={() => setPayMethod('transaction')}
        actionButton={
          isPatientUser
            ? {
                label: i18n.t(
                  values.payment?.card_number ? 'change' : 'add_card',
                ),
                value: values.payment?.card_number ? (
                  <Flex itemsCenter>
                    <FilledCircle />
                    <Spacer width={4} />
                    <FilledCircle />
                    <Spacer width={4} />
                    {getLastChars(values.payment?.card_number)}
                  </Flex>
                ) : (
                  ''
                ),
                onClick: () => setOpenModal(true),
              }
            : {}
        }
      />
      {isPatientUser && (
        <>
          <Spacer height={20} />
          <RadioBox
            name="payment.type"
            label={i18n.t('mada')}
            variant="large"
            checked={payMethod === 'mada'}
            startIcon={<MadaCard />}
            onChange={() => setPayMethod('mada')}
          />
          <Spacer height={20} />
          <RadioBox
            name="payment.type"
            label={i18n.t('apple_pay')}
            variant="large"
            checked={payMethod === 'apple_pay'}
            startIcon={<ApplePay />}
            onChange={() => setPayMethod('apple_pay')}
          />
          <AddCardModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            initialValues={{
              payment: {
                ...values.payment,
              },
            }}
            onSubmit={onPaymentChanged}
          />
        </>
      )}
      <Spacer height={40} />
    </>
  );
};

export default Step4;

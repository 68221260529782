import { API_VERSION_ONE, handleAPI } from 'utils/apiUtils';
import { userModule } from 'utils/labUtils';

import * as actions from './actions';

export function packagesLoad(params = {}) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/packages`;
  const method = 'GET';

  return {
    type: actions.PACKAGES_LOAD,
    payload: handleAPI(url, params, method),
  };
}

export function packageUpdate(id, data = {}) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/packages/${id}`;
  const method = 'PUT';

  return {
    type: actions.PACKAGES_UPDATE,
    payload: handleAPI(url, {}, method, data),
  };
}
export function packageCreate(data = {}) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/packages`;
  const method = 'POST';

  return {
    type: actions.PACKAGES_CREATE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function packageShow(id, params = {}) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/packages/${id}`;
  const method = 'GET';

  return {
    type: actions.PACKAGES_SHOW,
    payload: handleAPI(url, params, method, {}),
  };
}

export function packageDelete(id) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/packages/${id}`;
  const method = 'DELETE';

  return {
    type: actions.PACKAGE_DELETE,
    payload: handleAPI(url, {}, method),
  };
}

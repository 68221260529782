/* eslint-disable no-use-before-define */
import QueryString from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { dateQueryFormatToDate, dateTimeQueryFormatToDate } from 'utils/date';

export const useURLQuery = () => {
  const { location, push } = useHistory();
  const { pathname } = location;

  const query = useMemo(
    () => ({
      ...QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    }),
    [location.search],
  );

  const removeQuery = useCallback(
    (key) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(key);
      push({
        pathname,
        search: searchParams.toString(),
      });
    },
    [pathname, location.search],
  );
  const removeManyQuery = useCallback(
    (keys = []) => {
      const searchParams = new URLSearchParams(location.search);
      keys.forEach((key) => searchParams.delete(key));
      push({
        pathname,
        search: searchParams.toString(),
      });
    },
    [location.search, pathname, push],
  );

  const setQuery = (newQuery = {}) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries({ ...query, ...newQuery }).forEach(([key, value]) => {
      if (
        ['number', 'string'].includes(typeof value) &&
        value !== 'undefined'
      ) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });
    push({
      pathname,
      search: searchParams.toString(),
    });
  };

  const clearQuery = useCallback(() => {
    push({
      pathname,
      search: '',
    });
  }, [pathname]);

  const getQueryByObject = useCallback(
    (obj, key = 'key') =>
      obj.reduce((p, c) => {
        return { ...p, [c[key]]: query[c[key]] };
      }, {}),
    [query],
  );
  const getQueryByArray = useCallback(
    (keys) =>
      keys.reduce((p, c) => {
        return { ...p, [c]: query[c] };
      }, {}),
    [query],
  );

  const getQueryByConfigObject = useCallback(
    (
      config,
      key = 'key',
      // , keyToQueryMappings = []
    ) =>
      config.reduce((p, c) => {
        // // keyMapping is when the query parameter in URL is different
        // than it's object key in config... i added _id in the end as a fallback instead
        // const keyMapping = (keyToQueryMappings.find(
        //   ([localKey]) => localKey === c[key],
        // ) || [null, null])[1];

        let queryValue = query[c[key]] || query[`${c[key]}_id`];
        if (queryValue) {
          const isDate = (c[key] || '').includes('date');
          if (isDate) {
            const isTime = queryValue.includes(':');
            if (isTime) queryValue = dateTimeQueryFormatToDate(queryValue);
            else queryValue = dateQueryFormatToDate(queryValue);
          }
          return {
            ...p,
            [c[key]]:
              c?.options?.find((o) => o.value === queryValue) || queryValue,
          };
        }
        return p;
      }, {}),
    [query],
  );

  return {
    query,
    removeQuery,
    setQuery,
    clearQuery,
    getQueryByObject,
    getQueryByArray,
    getQueryByConfigObject,
    removeManyQuery,
    pathname,
  };
};

import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'translation/i18n';
import { getStockOptions } from 'redux/inventory/actionCreators';

import { Button } from 'atoms';
import StockModal from './StockModal';

const CreateStock = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    dispatch(getStockOptions());
  }, []);

  return (
    <>
      <Button fitContent label={i18n.t('create')} onClick={() => toggleShowModal(false)}/>
      <StockModal reloadList showModal={showModal} onClose={toggleShowModal} />
    </>
  );
};

export default CreateStock;

const Clinic = {
  // *

  // A
  add_clinic: 'Add Clinic',
  add_collection_center: 'Add Collection Center',

  // B

  // C
  clients: 'Clients',
  collection_center: 'Collection Center',
  collection_centers: 'Collection Centers',
  collection_center_activation: 'Collection center activation',
  collection_center_information: 'Collection center information',
  collection_center_to_lab_pricing: 'Collection center to lab pricing',
  
  // D

  // E

  // F

  // G

  // H

  // I

  // J

  // K

  // L

  // M

  // N

  // O

  // P

  // Q

  // R
  register_clinic_title: 'Register as a Clinic',
  register_clinic_subtitle: 'Signup as a clinic for {{lab}}',
  register_collection_center_title: 'Register as a Collection Center',
  register_collection_center_subtitle: 'Signup as a Collection Center for {{lab}}',

  // S

  // T

  // U

  // V

  // W

  // X

  // Y

  // Z

};

export default Clinic;

const Tests = {
  // *

  // A
  add_sub_test: 'Add Sub Test',
  all_tests: 'All tests',
  all_packages: 'All packages',
  activation: 'Activation',
  active: 'Active',
  add_card: 'Add Card',
  apple_pay: 'Apple Pay',
  analyzer_code: 'Analyzer Code',

  // B
  book_test: 'Book Test',

  // C
  category: 'Category',
  choose_your_test: 'Choose your test',
  clinic_to_patient_pricing: 'Clinic To Patient pricing',
  clinic_to_lab_pricing: 'Clinic To Lab pricing',
  create_test: 'Create Test',
  create_plan: 'Create Plan',
  create_single_test: 'Single Test',
  create_multi_test: 'Multi Test',
  edit_plan: 'Edit Plan',

  // D
  description: 'Description',
  download_template: 'Download Template',

  // E
  edit_test: 'Edit Test',
  enter_result: 'Enter result',
  enter_note: 'Enter note',

  // F

  // G

  // H

  // I
  inactive: 'Inactive',
  instrument_name: 'Instrument Name',

  // J

  // K

  // L
  lis_code: 'LIS Code',
  logical: 'Logical',

  // M
  mada: 'MADA',
  max_age: 'Max. Age',
  max_value: 'Max. Value',
  min_age: 'Min. Age',
  min_value: 'Min. Value',
  multi: 'Multi',

  // N
  non_logical: 'Non logical',

  // O
  orders_count: 'Orders Count',
  overdue_since: 'Overdue Since',
  overdue_tests: 'Overdue Tests',
  overdue_tests_title: 'Overdue Tests',
  overdue_tests_subtitle: 'Overview of all overdue tests',
  overdue_tests_search_input_placeholder: 'Search by patient name, test  or reservation ID',

  // P
  parameter_name: 'Parameter Name',
  position: 'Position',
  precision: 'No. Decimal',
  price: 'Price',
  price_per_patient: 'Price Per Patient',
  principal: 'Principal',
  packages: 'Packages',
  package: 'Package',
  package_name: 'Package Name',
  packages_search_input_placeholder: 'Search by name',
  package_info_title: 'Package details',
  package_tests_info_title: 'Package tests',
  package_add: 'Add package',
  package_edit: 'Edit package',
  package_delete: 'Delete package',
  confirm: 'Confirm',
  cancel: 'Cancel',
  package_delete_are_you_sure: 'Are you sure of deleting this package?',
  // Q

  // R
  reason_for_testing: 'Reason for Testing',
  result_status: 'Result Status',
  reference_ranges: 'Reference Ranges',
  remisol_name: 'Test Name',
  request_a_test: 'Request a Test',
  request_test: 'Request Test',
  reference_range: 'Reference range',
  routine_lab_testing: 'Routine lab testing',
  ref_range_type: 'Reference range type',
  ref_range_description: 'Reference range description',

  // S
  search_by_test_name: 'Search by test name',
  send_request: 'Send Request',
  specimen_type: 'Specimen Type',
  stat: 'STAT',
  summary: 'Summary',
  sub_tests: 'Sub Tests',
  sub_test_details: 'Sub Test Details',
  select_result: 'Select Result',
  sync_tests: 'Sync tests',
  single: 'Single',

  // T
  tests: 'Tests',
  tests_list_subtitle: 'Tests list subtitle',
  test_name: 'Test Name',
  test_category: 'Test Category',
  test_type: 'Test Type',
  test_price: 'Test Price',
  test_priority: 'Test Priority',
  test_interpretation: 'Test Interpretation',
  test_result: 'Test Result',
  type: 'Type',
  test_information: 'Test information',
  test_code: 'Test code',
  type_of_specimen: 'Type of Specimen',
  test_description: 'Test description',
  test_status: 'Test status',
  transaction: 'Transaction',
  test_results_has_not_generated_yet: 'The test results has not generated yet!',
  test_search_input_placeholder: 'Search by name or code',
  tat: 'TAT',
  tat_full: 'Turn Around Time',
  to_master_order: 'Show Master Order',

  // U
  unit: 'Unit',
  update_test: 'Update Test',
  update_price: 'Update Price',
  upload_csv: 'Upload CSV',
  upload_excel: 'Upload Excel',
  upload_tests: 'Upload Tests',
  upload: 'Upload',

  // V

  // W

  // X

  // Y

  // Z
};

export default Tests;

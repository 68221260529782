import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';

import { Grid } from '@mui/material';
import { InfoCard } from 'templates';
import PropTypes from 'prop-types';
import styles from '../styles.module.css';

const InfoListCards = ({ isSingleTest }) => {
  const { service_show: service } = useSelector((state) => state.tests);

  const singleTestInfo = isSingleTest ? [
    { label: 'test_type', value: service.test_type },
    { label: 'unit', value: service.unit },
    { label: 'no_decimal', value: service.no_decimal },
    { label: 'ref_range_type', value: i18n.t(service.ref_range_type) },
    { label: 'analyzer_code', value: service.analyzer_code }
  ] : [];

  const adminTestInfo = isEmpty(service) ? [] : [
    { label: 'category', value: service.category_type },
    { label: 'lis_code', value: service.lis_code },
    { label: 'remisol_name', value: service.name },
    { label: 'description', value: service.description },
    { label: 'principal', value: service.principal },
    { label: 'specimen_type', value: (valueGetter(service, 'specimen_types') || []).map(s => i18n.t(s.name)).join(', ') },
    ...singleTestInfo
  ];

  return (
    <Grid container spacing="20px" className={styles.infoCardsContainer}>
      {adminTestInfo.map((li, index) => (
        <Grid item key={index} xs={6} md={4} xl={3}>
          <InfoCard titleKey={li.label} value={li.value} key={index} />
        </Grid>
      ))}
    </Grid>
  );
};

InfoListCards.propTypes = {
  isSingleTest: PropTypes.bool
};

export default InfoListCards;

import { moduleBaseUrl, handleAPI, API_VERSION_ONE } from 'utils/apiUtils';
import { format } from 'date-fns';
import * as actions from './actions';

export function addPatient(data) {
  const url = `${moduleBaseUrl()}/master_orders`;
  const method = 'POST';

  const input = {
    ...data,
  };
  if (typeof input.patient.date_of_birth === 'object') {
    input.patient.date_of_birth = format(
      input.patient.date_of_birth,
      'yyyy-MM-dd',
    );
  }

  return {
    type: actions.ADD_PATIENT,
    payload: handleAPI(url, {}, method, input),
  };
}

export function updatePatient(patientId, payload) {
  const url = `${moduleBaseUrl()}/patients/${patientId}`;
  const method = 'PUT';

  return {
    type: actions.ADD_PATIENT,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function fetchPatientDetails(params) {
  const url = `${moduleBaseUrl()}/master_orders/patient_datails`;
  const method = 'GET';

  return {
    type: actions.FETCH_PATIENT_DETAILS,
    payload: handleAPI(url, params, method),
  };
}

export function getPatientInvoice(masterOrderId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/invoice`;
  const method = 'GET';

  return {
    type: actions.GET_PATIENT_INVOICE,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function patientRefund(patientId) {
  const url = `${moduleBaseUrl()}/patients/${patientId}/refund`;
  const method = 'POST';

  return {
    type: actions.PATIENT_REFUND,
    payload: handleAPI(url, {}, method),
  };
}

export function getPatientBarcode(masterOrderId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/barcode`;
  const method = 'GET';

  return {
    type: actions.GET_PATIENT_INVOICE,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}

export function patientBookTest(data) {
  const url = `/${API_VERSION_ONE}/patient/master_orders/book`;
  const method = 'POST';

  return {
    type: actions.PATIENT_BOOK_TEST,
    payload: handleAPI(url, {}, method, data),
  };
}

export function labPatientOptionsLoad() {
  const url = `${moduleBaseUrl()}/master_orders/options`;
  const method = 'GET';

  return {
    type: actions.PATIENT_OPTIONS_LOAD,
    payload: handleAPI(url, {}, method, {}),
  };
}

export function patientOptionsLoad() {
  const url = `/${API_VERSION_ONE}/patient/master_orders/options`;
  const method = 'GET';

  return {
    type: actions.PATIENT_OPTIONS_LOAD,
    payload: handleAPI(url, {}, method, {}),
  };
}

export function getPricesLoad(params, service_type_ids) {
  const url = `${moduleBaseUrl()}/services/price`;
  const method = 'POST';

  return {
    type: actions.GET_PRICES_LOAD,
    payload: handleAPI(url, params, method, { service_type_ids }),
  };
}

const Tests = {
  // *

  // A
  add_sub_test: 'إضافة فحص فرعي',
  all_tests: 'كل الفحوصات',
  all_packages: 'كل الحزم',
  activation: 'تفعيل',
  active: 'مفعل',
  add_card: 'إضافة بطاقة',
  apple_pay: 'آبل بي',
  analyzer_code: 'Analyzer Code',

  // B
  book_test: 'حجز فحص',

  // C
  category: 'الصنف',
  choose_your_test: 'اختر نوع الفحص',
  clinic_tests: 'فحوصات العيادة',
  create_test: 'انشاء فحص',
  create_plan: 'إنشاء خطة',
  create_single_test: 'Single Test',
  create_multi_test: 'Multi Test',
  edit_plan: 'تعديل خطة',

  // D
  description: 'الوصف',
  download_template: 'تحميل القالب',

  // E
  edit_test: 'تعديل الفحص',
  enter_result: 'ادخال النتيجة',
  enter_note: 'ادخال ملاحظة',

  // F

  // G

  // H

  // I
  inactive: 'غير مفعل',
  instrument_name: 'Instrument Name',

  // J

  // K

  // L
  lis_code: 'LIS Code',
  logical: 'منطقي',

  // M
  mada: 'مدى',
  max_age: 'Max. Age',
  max_value: 'Max. Value',
  min_age: 'Min. Age',
  min_value: 'Min. Value',
  multi: 'Multi',

  // N
  non_logical: 'غير منطقي',

  // O
  orders_count: 'Orders Count',
  overdue_since: 'Overdue Since',
  overdue_tests: 'Overdue Tests',
  overdue_tests_title: 'Overdue Tests',
  overdue_tests_subtitle: 'Overview of all overdue tests',
  overdue_tests_search_input_placeholder: 'Search by patient name, test  or reservation ID',

  // P
  parameter_name: 'Parameter Name',
  packages_search_input_placeholder: 'البحث بالاسم',
  position: 'Position',
  precision: 'No. Decimal',
  price: 'السعر',
  package: 'الحزمة',
  package_name: 'اسم الحزمة',
  packages: 'الحزم',
  price_per_patient: 'السعر لكل مريض',
  package_info_title: 'تفاصيل الحزمة',
  package_tests_info_title: 'قائمة فحوصات الحزمة',
  package_add: 'اضف حزمة',
  package_edit: 'تعديل حزمة',
  package_delete: 'حذف الحزمة',
  confirm: 'تأكيد',
  cancel: 'تراجع',
  package_delete_are_you_sure: 'هل انت متأكد من حذف الحزمة؟',

  // Q

  // R
  reason_for_testing: 'سبب الفحص',
  result_status: 'Result Status',
  reference_ranges: 'نطاق النتيجة',
  remisol_name: 'Test Name',
  request_a_test: 'طلب فحص',
  request_test: 'طلب فحص',
  reference_range: 'المرجعيه',
  routine_lab_testing: 'الفحوصات المخبرية الروتينية',
  ref_range_type: 'نوع النطاق المرجعي',
  ref_range_description: 'وصف النطاق المرجعي',

  // S
  search_by_test_name: 'البحث بإسم الفحص',
  send_request: 'ارسال الطلب',
  specimen_type: 'نوع العينة',
  stat: 'STAT',
  summary: 'عام',
  sub_tests: 'Sub Tests',
  sub_test_details: 'Sub Test Details',
  select_result: 'اختيار النتيجة',
  sync_tests: 'مزامنة الفحوصات',
  single: 'Single',

  // T
  tests: 'الفحوصات',
  tests_list_subtitle: 'عن الفحوصات',
  test_name: 'اسم الفحص',
  test_category: 'Test Category',
  test_type: 'نوع الفحص',
  test_price: 'سعر الفحص',
  test_priority: 'اولوية الاختبار',
  test_interpretation: 'Test Interpretation',
  test_result: 'Test Result',
  type: 'النوع',
  test_information: 'معلومات الفحص',
  test_code: 'كود الفحص',
  type_of_specimen: 'نوع العينه',
  test_description: 'وصف الفحص',
  test_status: 'حالة الفحص',
  transaction: 'شبكة',
  test_results_has_not_generated_yet: 'لم يتم إنشاء نتائج الفحص حتى الآن!',
  test_search_input_placeholder: 'Search by name or code',
  tat: 'TAT',
  tat_full: 'Turn Around Time',
  to_master_order: 'Show Master Order',

  // U
  unit: 'الوحدة',
  update_test: 'تحديث الفحص',
  update_price: 'تحديث السعر',
  upload_csv: 'Upload CSV',
  upload_excel: 'Upload Excel',
  upload_tests: 'Upload Tests',
  upload: 'Upload',

  // V

  // W

  // X

  // Y

  // Z
};

export default Tests;

export const TEMP_PASS = '11111111';

export const ORDER_TYPE = {
  SINGLE: 'single',
  BATCH: 'batch',
};

export const CLIENT_TYPE = {
  CLINICS: 'clinics',
  COLLECTION_CENTERS: 'collection-centers',
};

export const INVOICE_TYPE = {
  B2C: 'B2C',
  B2B: 'B2B',
};

export const STOCK_STATUS = {
  NEW: 'new',
  IN_USE: 'in_use',
};

export const ORDER_STATUS = {
  CREATED: 'created',
  PAID: 'paid',
  CONFIRM: 'confirmed',
  COLLECT: 'collected',
  PROCESS: 'processing',
  REJECT: 'rejected',
  READY: 'ready',
  CANCEL: 'cancelled',
  RECOLLECT: 'recollect',
  SUBMITTED: 'submitted',
  TECHNICAL: 'technical',
  MEDICAL: 'medical',
};

export const BATCH_ORDER_STATUS = {
  PROCESS: 'processing',
  DONE: 'done',
};

export const PRIORITY = {
  NORMAL: 'normal',
  STAT: 'stat',
  URGENT: 'urgent',
};

export const TEST_RESULT = {
  NEGATIVE: 'negative',
  PENDING: 'pending',
  POSITIVE: 'positive',
};

export const LAB_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
};

export const LAB_ACTIONS = {
  ACTIVATION: 'activation',
  ACCEPT: 'accept',
  REJECT: 'reject',
  DELETE: 'delete',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
};

export const ACTIVE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
};

export const SUBSCRIPTION_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  EXPIRED: 'expired',
};

export const MASTER_RESERVATION_STATUS = {
  PENDING: 'pending',
  PAID: 'paid',
  DONE: 'done',
  CANCELED: 'canceled',
};

export const MASTER_RESERVATION_TEST_STATUS = {
  CREATED: 'created',
  CONFIRM: 'confirmed',
  COLLECT: 'collected',
  PROCESS: 'processing',
  REJECT: 'recollected',
  READY: 'ready',
  CANCEL: 'cancelled',
};

export const USER_ROLE = {
  SUPER_ADMIN: 'super_admin',
  LAB_ADMIN: 'lab_admin',
  LAB_OPERATOR: 'lab_operator',
  LAB_TECHNICIAN: 'lab_technician',
  LAB_TECHNOLOGIST: 'lab_technologist',
  LAB_SUPERVISOR: 'lab_supervisor',
  LAB_DOCTOR: 'lab_doctor',
  LAB_ACCOUNTANT: 'lab_accountant',
  LAB_ACCOUNTANT_MANAGER: 'lab_accountant_manager',
  LAB_STORE_KEEPER: 'lab_store_keeper',
  PATIENT: 'patient',
  CLINIC: 'clinic',
  COLLECTION_CENTER: 'collection_center',
};

export const SOLUTION = {
  PCR_SOLUTION: 'PCR_SOLUTION',
  FULL_SOLUTION: 'FULL_SOLUTION',
  ROUTINE_LAB_TESTING: 'ROUTINE_LAB_TESTING',
};

export const CLINIC_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
};

export const OUTSOURCING = {
  SAMPLE: 'sample',
  BATCH: 'batch',
};

export const PAYMENT_MODE = {
  CASH: 'cash',
  CARD: 'transaction',
  MADA: 'mada',
  APPLE_PAY: 'apple_pay',
};

export const MODULE = {
  ADMIN: 'admin',
  LAB: 'lab',
  PATIENT: 'patient',
  CLINIC: 'clinic',
  COLLECTION_CENTER: 'collection_center',
};

export const SUBSCRIPTION_DURATION = {
  TRIAL: 'trial',
  MONTHLY: 'monthly',
};

export const PAGE = {
  OVERVIEW: 'overview',
  RESERVATIONS: 'reservations',
  ORDERS: 'orders',
  PCR_BATCHES: 'pcr-batches',
  OVERDUE_TESTS: 'overdue-tests',
  BILLING: 'billing',
  INVOICES: 'invoices',
  REPORTS: 'reports',
  INVENTORY: 'inventory',
  USERS: 'users',
  CLIENTS: 'clients',
  TESTS: 'tests',
  PACKAGE: 'packages',
  SHARE: 'share',
  LAB_PROFILE: 'lab-profile',
  USER_PROFILE: 'user-profile',
  SERIAL: 'serial_settings',
};

export const PERMISSION = {
  ADD_PATIENT: 'add_patient',
  UPDATE_RESULT: 'result_entry_order',
  MEDICAL_VERIFY: 'medical_verification',
  TECHNICAL_VERIFY: 'technical_verification',
  PRINT_INVOICE: 'print_invoice',
  PRINT_RESULT: 'print_results',
  PRINT_BARCODE: 'print_barcode',
  ISSUE_REFUND: 'refund',
  MASTER_ORDER_CANCEL: 'cancel_master_order',
  MASTER_ORDER_PAY: 'pay_master_order',
  ORDER_CANCEL: 'cancel_order',
  ORDER_COLLECT: 'collect_order',
  ORDER_PROCESS: 'processing_order',
  ORDER_RECOLLECT: 'recollect_order',
};

export const HESN_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  NOT_READY_YET: 'not_ready_yet',
  PENDING: 'pending',
};

export const REPORT = {
  SALES: 'sales',
  USER_PERFORMANCE: 'user-performance',
  INVOICES: 'invoices',
  RESULTS: 'results',
  TOTAL_AMOUNTS: 'total-amounts',
  SOURCE_REPORTS: 'source-reports',
  INCOME_FINANCE: 'income-finance-report',
  INVOICE_FINANCE: 'invoice-finance-report',
  B2B_FINANCE: 'b2b-finance-report',
};

export const TEST_CATEGORY = {
  MOLECULAR: 'molecular',
  CHEMISTRY: 'chemistry',
  IMMUNO: 'immuno',
  SEROLOGY: 'serology',
  HEMATOLOGY: 'hematology',
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const VERIFICATION_STATUS = {
  PENDING: 'pending',
  VERIFIED: 'verified',
};

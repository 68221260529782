/* eslint-disable no-constant-condition */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */

import { sendAck } from './sendAck';

export async function readMultiFrameData({
    serialPort,
    bufferSize = 8 * 1024,
    onDoneReading,
    writer,
    isByobMode
}) {
    if (serialPort) {
        let currentFrame = '';
        let summary = [];
        let reader;

        while (serialPort.readable) {
            if (isByobMode) {
                console.log('reading data byob mode');
                reader = serialPort.readable.getReader({ mode: 'byob' });
            } else {
                console.log('reading data non-byob mode');
                reader = serialPort.readable.getReader();
            }
            console.log(reader);
            try {
                while (true) {
                    const { value, done } = await reader.read(
                        new Uint8Array(new ArrayBuffer(bufferSize), 0, bufferSize),
                    );
                    if (done) {
                        break;
                    }
                    const asciiValue = value.toString('ascii');

                    if (asciiValue === '5') await sendAck({ writer });
                    if (asciiValue === '4') {
                        const astm_unicode = summary.join(',');
                        onDoneReading(astm_unicode);
                        console.log('done reading: ', astm_unicode);
                        summary = [];
                    }
                    // value.toString('ascii') -> 4,5,23,45
                    summary.push(asciiValue);
                    // dataFrames = ['5', '4,5,23,45', '4']
                    console.log('added to dataFrames: ', asciiValue);

                    currentFrame += asciiValue;
                    // Check for the end of a frame (typically EOT character, 0x04)
                    if (currentFrame.split(',').includes('10')) {
                        // Send an ACK to acknowledge the frame
                        await sendAck({ writer });

                        // Reset the current frame
                        currentFrame = '';
                    }
                }
            } catch (error) {
                console.error('Error reading data:', error);
            } finally {
                console.log('reader released');
                reader.releaseLock();
            }
        }
        console.log('exited reading while loop');
    }
}

/* eslint-disable no-plusplus */
import I18n from 'translation/i18n';

export const checkReferenceRange = (referenceRanges) => {
  let isInvalid;
  let index = -1;
  referenceRanges = referenceRanges.map(r => ({
    ...r,
    min_age: Number(r.min_age),
    max_age: Number(r.max_age),
    min_value: parseFloat(r.min_value),
    max_value: parseFloat(r.max_value),
  }));
  // Validation 1: min_age should always be greater than max_age
  index = referenceRanges.findIndex((r) => r.min_age >= r.max_age);
  if (index > -1) {
    return {
      index,
      message: I18n.t('invalid_ages'),
      fields: ['min_age', 'max_age']
    };
  }
  // Validation 2: min_value should always be greater than max_value
  index = referenceRanges.findIndex((r) => r.min_value >= r.max_value);
  if (index > -1) {
    return {
      index,
      message: I18n.t('invalid_values'),
      fields: ['min_value', 'max_value']
    };
  }
  // Validation 3: No duplicate ranges
  isInvalid = referenceRanges.some((r1, i1) => {
    index = i1;
    const sameGenderRanges = referenceRanges.filter((r2, i2) => {
      if (i1 === i2 || r1.gender !== r2.gender) return false;
      return r1.min_value === r2.min_value &&
        r1.max_value === r2.max_value &&
        r1.min_age === r2.min_age &&
        r1.max_age === r2.max_age;
    });
    return sameGenderRanges.length > 0;
  });
  if (isInvalid) {
    return {
      index,
      message: I18n.t('duplicate'),
      fields: ['gender', 'min_age', 'max_age', 'min_value', 'max_value']
    };
  }
  // Validation 4: No intersecting ages for the same gender
  isInvalid = referenceRanges.some((r1, i1) => {
    index = i1;
    const sameGenderRanges = referenceRanges.filter(
      (r2, i2) => r2.gender === r1.gender && i1 !== i2
    );
    return sameGenderRanges.some(r2 =>
      (r1.min_age > r2.min_age && r1.min_age < r2.max_age) ||
      (r1.max_age > r2.min_age && r1.max_age < r2.max_age)
    );
  });
  if (isInvalid) {
    return {
      index,
      message: I18n.t('intersecting_ages'),
      fields: ['min_age', 'max_age']
    };
  }
  // Validation 5: No intersecting values for the same gender
  isInvalid = referenceRanges.some((r1, i1) => {
    index = i1;
    const sameGenderAgeRanges = referenceRanges.filter((r2, i2) =>
      i1 !== i2 &&
      r1.gender === r2.gender &&
      r1.min_age === r2.min_age &&
      r1.max_age === r2.max_age
    );
    return sameGenderAgeRanges.some(r2 =>
      (r1.min_value >= r2.min_value && r1.min_value <= r2.max_value) ||
      (r1.max_value >= r2.min_value && r1.max_value <= r2.max_value)
    );
  });
  if (isInvalid) {
    return {
      index,
      message: I18n.t('intersecting_values'),
      fields: ['min_value', 'max_value']
    };
  }
  return undefined;
};

export const required = (value) => (value || value === 0 ? undefined : I18n.t('required'));
export const requiredWithNoMessage = (value) => (value ? undefined : ' ');

export const maxLength = (max) => (value) =>
  value && value.length > max ? I18n.t('max_length', { max }) : undefined;

export const shouldStartWith = (start) => (value) =>
  value && value.charAt(0) !== String(start)
    ? I18n.t('should_start_with', { start })
    : undefined;

export const maxValue = (max) => (value) =>
  value && value > max ? I18n.t('must_be_less_than', { max }) : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? I18n.t('min_length', { min }) : undefined;

export const minValue = (min) => (value) =>
  value && value < min ? I18n.t('must_be_more_than', { min }) : undefined;

export const number = (value) =>
  value && (Number.isNaN(Number(value)) || /\D/.test(value))
    ? I18n.t('number_msg')
    : undefined;

export const enNumber = (value) => {
  const arRegex = /[\u0600-\u06FF]/;
  const isNumber = Number.isNaN(Number(value));
  const isAr = arRegex.test(value);
  const error_msg = isAr ? 'number_ar_msg' : 'number_msg';

  return (
    value &&
    (isNumber || /\D/.test(value) || isAr ? I18n.t(error_msg) : undefined)
  );
};
export const enDecimal = (value) => {
  const arRegex = /[\u0600-\u06FF]/;
  const isNumber = Number.isNaN(Number(value));
  const isAr = arRegex.test(value);
  const error_msg = isAr ? 'number_ar_msg' : 'number_msg';

  return value && (isNumber || isAr ? I18n.t(error_msg) : undefined);
};

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9-]{2,63}$/i.test(value)
    ? I18n.t('email_msg')
    : undefined;

export const phoneOrEmail = (value) => {
  const isEmail = !email(value);
  const isPhone = !enNumber(value) &&
    !shouldStartWith(5)(value) &&
    !minLength(9)(value) &&
    !maxLength(9)(value);
  if (isEmail || isPhone) return undefined;
  if (!enNumber(value)) {
    return shouldStartWith(5)(value) || minLength(9)(value) || maxLength(9)(value);
  }
  return I18n.t('phone_or_email_msg');
};

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

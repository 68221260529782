import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { isNaN } from 'lodash';

import i18n from 'translation/i18n';

import { Button, Modal, Spacer, Text, TextInput } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';

const TATModal = ({showModal, initialTAT, onClose}) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const handleClose = (isUpdate) => {
    if (isUpdate) {
      onClose(Number(hours) * 60 + Number(minutes));
    } else {
      onClose();
    }
  };

  useEffect(() => {
    const duration = isNaN(initialTAT) ? {} : {
      hours: Math.floor(initialTAT / 60).toString(),
      minutes: Math.floor(initialTAT % 60).toString()
    };
    setHours(duration.hours);
    setMinutes(duration.minutes);
  }, [initialTAT]);

  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={260}
        header={<ModalHeader title={i18n.t('tat_full')}/>}
        footer={<Button
          variant="secondary"
          label={i18n.t('submit')}
          onClick={() => handleClose(true)}
        />}
        onClose={handleClose}
      >
        <ModalContent padding="0 1rem">
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('hours')}
          </Text>
          <TextInput
            type="number"
            value={hours}
            placeholder={i18n.t('')}
            style={{margin: '0.5rem 0 1rem'}}
            onChange={(e) => setHours(e.target.value)}
          />
          <Spacer height={8}/>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('minutes')}
          </Text>
          <TextInput
            type="number"
            value={minutes}
            placeholder={i18n.t('')}
            style={{margin: '0.5rem 0 1rem'}}
            onChange={(e) => setMinutes(e.target.value)}
          />
        </ModalContent>
      </Modal>
    )
  );
};

TATModal.propTypes = {
  showModal: PropTypes.bool,
  initialTAT: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func
};

export default TATModal;
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Billing from 'container/labs/Billing';

const BillingRoutes = () => (
  <Switch>
    <Route path="/billing" component={Billing}/>
  </Switch>
);

export default BillingRoutes;

import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { packagesLoad } from 'redux/packages/actionCreators';
import { isPatient } from 'utils/labUtils';
import PackageFilters from './PackageFilters';
import PackageTable from './PackageTable';

const PackageList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { message, packages } = useSelector((state) => state.packages);
  const { query, setQuery } = useURLQuery();

  const load = (params = {}) => dispatch(packagesLoad(params));
  const handleRowClick = ({ id }) => push(`/packages/${id}`, { id });
  const handleFilterChange = (filter) => {
    const params = {
      priority: filter.priority,
      status: filter.status,
      search: filter.search,
    };
    setQuery(params);
    load(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: load,
  });

  React.useEffect(() => {
    if (!isPatient()) {
      const params = {
        priority: query?.priority,
        status: query?.status,
        search: query?.search,
      };
      dispatch(packagesLoad(params));
    }
  }, []);

  const content = (
    <>
      <ListFilters Wrapped={PackageFilters} onChange={handleFilterChange} />
      <PackageTable
        list={packages}
        onRowClick={handleRowClick}
        noData={message}
        onPagination={handlePagination}
      />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default PackageList;

import React, {useEffect, useState} from 'react';
import {useForm, useFormState} from 'react-final-form';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';

import { Button } from 'atoms';
import SubTestForm from './SubTestForm';

const MultiStep2 = () => {
  const { change } = useForm();
  const { values } = useFormState();
  const [subTests, setSubTests] = useState([]);

  const handleAddTest = () => {
    setSubTests([
      ...valueGetter(values, 'services_attributes') || [],
      {
        lis_code: `${values.lis_code}.${subTests.length + 1}`
      }
    ]);
  };

  const handleRemoveTest = (index) => {
    const updated = valueGetter(values, 'services_attributes').map((item, i) => {
      if (i === index) return null;
      const count = index > i ? i + 1 : i;
      return {
        ...item,
        lis_code: `${values.lis_code}.${count}`
      };
    }).filter(Boolean);
    setSubTests(updated);
  };

  useEffect(() => {
    change('services_attributes', subTests);
  }, [subTests]);

  useEffect(() => {
    if (values.services_attributes) {
      setSubTests(values.services_attributes);
    } else {
      handleAddTest();
    }
  }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <Button
          label={i18n.t('add_sub_test')}
          variant="secondary"
          onClick={handleAddTest}
        />
      </div>
      {subTests.map((_, index) => (
        <SubTestForm
          values={values}
          key={`sub-test-${index}`}
          index={index}
          showClose={subTests.length > 1}
          onClose={() => handleRemoveTest(index)}
        />
      ))}
    </>
  );
};

export default MultiStep2;
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {ReactComponent as AddIcon} from 'assets/icons/common/add-square.svg';
import {createUser, getUsers} from 'redux/lab/actionCreators';

import {Button, Modal} from 'atoms';
import {ModalHeader} from 'templates';
import {UserForm} from './UserForm';

export const CreateUser = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState();
  const [activeStep, setActiveStep] = useState(1);

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleOnClose = () => {
    setActiveStep(1);
    setShowModal(false);
  };

  const handleSubmit = (data) => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1);
      return;
    }
    const permission_ids = data.permissions.map(p => p.value);
    delete data.permissions;
    const payload = {
      user: {
        ...data,
        permission_ids,
        status: 'activated',
      }
    };
    dispatch(createUser(payload))
      .then(() => {
        dispatch(getUsers());
        handleOnClose();
      })
      .catch(() => {
        setActiveStep(1);
      });
  };

  return (
    <>
      <Button
        label={i18n.t('new_user')}
        startIcon={<AddIcon/>}
        onClick={() => setShowModal(true)}
      />
      <Modal
        header={
          <ModalHeader
            title={i18n.t('create_user')}
            onBack={activeStep === 1 ? null : onBack}
          />
        }
        disableCloseOut
        isOpen={showModal}
        onClose={handleOnClose}
        width={activeStep === 1 ? 350 : 500}
      >
        <UserForm activeStep={activeStep} onSubmit={handleSubmit}/>
      </Modal>
    </>
  );
};

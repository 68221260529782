import React from 'react';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {PRIORITIES, TEST_RESULTS} from 'utils/options';
import {MASTER_RESERVATION_STATUSES} from 'models';

import {Text, Flex} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';

const PatientOrderFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const selectFilterConfigs = [
    {key: 'status', options: MASTER_RESERVATION_STATUSES, placeholderKey: 'status'},
    {key: 'priority', options: PRIORITIES, placeholderKey: 'priority'},
    {key: 'result', options: TEST_RESULTS, placeholderKey: 'result'}
  ];
  const dateFilterConfigs = [
    {key: 'date', placeholderKey: 'date'}
  ];

  return (
    <>
      <Grid container spacing={4} py={2} px={3} alignItems="center">
        <Grid item md={3} sm={3} xs={8}>
          <Text typography="subtitle" weight="bold">{i18n.t('patient_order_list_title')}</Text>
          <Text typography="caption13" style={{opacity: 0.4}}>
            {i18n.t('patient_order_list_subtitle')}
          </Text>
        </Grid>
        <Grid item md={3} sm={6} xs={0} className="hide-in-small-screen">
          <SearchInput
            placeholder={i18n.t('patient_order_search_input_placeholder')}
            onChange={(value) => generateOtherFilters('search', value)}
          />
        </Grid>
        <Grid item md={4} sm={2} xs={0} className="hide-in-small-screen"/>
        <Grid item md={2} sm={1} xs={4}>
          <SelectFilter
            className="ml-8"
            flexClassName="filter-sx-in-small-screen"
            selectFilterConfigs={selectFilterConfigs}
            dateFilterConfigs={dateFilterConfigs}
            onApply={generateSelectFilters}
            onCancel={generateSelectFilters}
          />
        </Grid>
      </Grid>

      <Flex item sm={4} xs={0} className="show-filter-in-small-screen">
        <SearchInput
          placeholder={i18n.t('patient_order_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Flex>
    </>
  );
};

PatientOrderFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

export default PatientOrderFilters;

import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import i18n from 'translation/i18n';
import {getClientDetails} from 'redux/lab/actionCreators';

import {Grid} from '@mui/material';
import LabHeader from 'components/global/LabHeader';
import {ClientInfo, ClientSummary, ClientTests, ClientPatientPricing} from 'components/labClients';
import {CardContainer} from 'templates';

const CollectionCenterDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientDetails(id));
  });

  return (
    <>
      <LabHeader title="collection_centers"/>
      <Grid container spacing={4} p={4}>
        <Grid item xs={8}>
          <CardContainer
            className="clinic-info-container"
            title={i18n.t('collection_center_info_title')}
            showBackButton
            noPadding
          >
            <ClientInfo/>
            <ClientTests/>
            {false && <ClientPatientPricing/>}
          </CardContainer>
        </Grid>
        <Grid item xs={4}>
          <ClientSummary/>
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionCenterDetails;
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import routes from 'utils/routeHelpers/patient';
import PrivateRoute from './PrivateRoutes';

const PatientRoutes = () => {
  // const { push } = useHistory();
  // const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (isPatientSignup) {
  //     push('/book-test');
  //   } else {
  //     dispatch(patientOptionsLoad());
  //   }
  // }, []);

  return (
    <div className="patient-page">
      <Switch>
        {routes.map(({ path, component }, index) => (
          <PrivateRoute key={index} path={path} component={component} />
        ))}
        <Redirect to="/tests" />
      </Switch>
    </div>
  );
};
export default PatientRoutes;

/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { handleAPI, moduleBaseUrl } from 'utils/apiUtils';
import { Toastr } from 'utils/Toastr';
import { Button, Modal } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';
import i18n from 'translation/i18n';
import { connectToPort } from './util/connectToPort';

export const SerialContext = createContext({
    portState: { port: null, connectedDeviceId: null }, setPortState: null
});

export function SerialProvider({ children }) {
    const [portState, setPortState] = useState({ port: null, connectedDeviceId: null });

    return (
        <SerialContext.Provider value={{ portState, setPortState }}>
            {children}
            <Reconnect />
        </SerialContext.Provider >
    );
}


const parse = async (astm_unicode) => {
    const url = `${moduleBaseUrl()}/analyzers/astm/parse_and_update`;
    const method = 'POST';
    await handleAPI(url, {}, method, { astm_unicode });
    Toastr.success('Updated successfully');
};

export const useSerial = () => {
    const { portState, setPortState } = useContext(SerialContext);

    const disconnect = () => {
        if (portState.port) {
            portState.port.close();
        }
        setPortState({ port: null, connectedDeviceId: null });
        localStorage.removeItem('serial-connection');
        window.location.reload();

    };

    const connect = (device = 'cobas-311', options = {}) => {
        const presetOptions = {
            'cobas-411': {
                'baudRate': '9600',
                'dataBits': '8',
                'parity': 'none',
                'stopBits': '1',
                'flowControl': 'none',
                'readerMode': 'none'
            },
            'cobas-311': {
                'baudRate': '9600',
                'dataBits': '8',
                'parity': 'none',
                'stopBits': '2',
                'flowControl': 'none',
                'readerMode': 'byob'
            },
            'custom': {}
        }[device];

        connectToPort({
            options: {
                ...presetOptions,
                ...options
            },
            onDoneReading: parse,
            onConnected: (port) => {
                setPortState({ port, connectedDeviceId: device });
                const connectionInfo = {
                    time: new Date().toLocaleString(),
                    device,
                };
                localStorage.setItem('serial-connection', JSON.stringify(connectionInfo));
            },
            onDisconnected: () => {
                setPortState({ port: null, connectedDeviceId: null });
                // clear connection info from local storage
                localStorage.removeItem('serial-connection');
            }
        });
    };

    return { disconnect, connect, connectedDeviceId: portState.connectedDeviceId };
};



const Reconnect = () => {
    const [connectTo, setConnectTo] = useState(null);
    const { connect, connectedDeviceId } = useSerial();
    useEffect(() => {
        if (connectedDeviceId) return;
        // check local storage for previous connection
        const connection = localStorage.getItem('serial-connection');
        if (connection) {
            const { device } = JSON.parse(connection);
            setConnectTo(device);
        }
    }, []);

    return <>
        {connectTo && <Modal
            disableCloseOut
            isOpen
            width={260}
            header={<ModalHeader title={i18n.t('serial_settings')} />}
            footer={<Button
                variant="secondary"
                label={i18n.t('reconnect')}
                onClick={() => {
                    connect(connectTo);
                    setConnectTo(null);
                }}
            />}
            onClose={() => {
                setConnectTo(null);
                localStorage.removeItem('serial-connection');
            }}
        >
            <ModalContent padding="0 1rem">
                Connection to analyzer {connectTo} was interrupted. Do you want to reconnect?
            </ModalContent>
        </Modal>}
    </>;
};
import { Modal } from 'atoms';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { packageUpdate } from 'redux/packages/actionCreators';
import { ModalHeader, SectionTitle } from 'templates';
import i18n from 'translation/i18n';
import CreatePackageStepTwo from '../../NewPackage/NewPackageSteps/CreatePackageStepTwo';
import PackageTestsTable from './PackageTestsTable';

const PackageTests = () => {
  const dispatch = useDispatch();
  const { onePackage, fetching } = useSelector((state) => state.packages);
  const [editing, setEditing] = React.useState(false);

  const handleFilterChange = (filter) => {
    const params = {
      package: {
        service_ids: filter.service_ids,
      },
    };
    dispatch(packageUpdate(onePackage.id, params));
    setEditing(false);
  };
  return (
    <>
      <SectionTitle
        titleKey={i18n.t('package_tests_info_title')}
        showEdit
        editKey={i18n.t('edit_info')}
        onEdit={() => setEditing(true)}
      />
      <PackageTestsTable />
      {!fetching && onePackage && (
        <Modal
          header={<ModalHeader title={i18n.t('package_edit')} />}
          isOpen={editing}
          onClose={() => setEditing(false)}
          width={isMobile ? 350 : '90%'}
          maxWidth={600}
        >
          <Form
            onSubmit={handleFilterChange}
            initialValues={{
              service_ids: onePackage.services.map((s) => s.id),
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ padding: 8 }}>
                  <CreatePackageStepTwo />
                </div>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};

export default PackageTests;

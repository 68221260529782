import React, {useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useDispatch} from 'react-redux';
import {sub, add} from 'date-fns';

import i18n from 'translation/i18n';
import {getOverviewStats} from 'redux/lab/actionCreators';

import {Box} from '@mui/material';
import {Button, DatePicker, Flex, Spacer, Text} from 'atoms';

const CustomDateSelector = () => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(sub(new Date(), {weeks: 1}));
  const [toDate, setToDate] = useState(new Date());

  return (
    <Box px={isMobile ? 2 : 5} py={isMobile ? 2 : 5}>
      <Flex itemsCenter>
        <Text typography="paragraph14">{i18n.t('from')}</Text>
        <Spacer width={8}/>
        <DatePicker
          showIcon
          value={fromDate}
          dateFormat="dd/MM/yyyy"
          displayFormat="dd MMM yyyy"
          placeholderText={i18n.t('from_date')}
          maxDate={sub(toDate, {days: 1})}
          onChange={(value) => setFromDate(value)}
        />
        <Spacer width={16}/>
        <Text typography="paragraph14">{i18n.t('to')}</Text>
        <Spacer width={8}/>
        <DatePicker
          showIcon
          value={toDate}
          dateFormat="dd/MM/yyyy"
          displayFormat="dd MMM yyyy"
          placeholderText={i18n.t('to_date')}
          minDate={add(fromDate, {days: 1})}
          onChange={(value) => setToDate(value)}
        />
        <Spacer width={16}/>
        <Button fitContent label={i18n.t('ok')} onClick={() => dispatch(getOverviewStats(fromDate, toDate))}/>
      </Flex>
    </Box>
  );
};

export default CustomDateSelector;
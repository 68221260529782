import React from 'react';
import LabHeader from 'components/global/LabHeader';
import { MasterOrderList } from 'components/orders/lab/MasterOrderList';

const Orders = () => {
  return (
    <>
      <LabHeader title="master_order_list_title"/>
      <MasterOrderList/>
    </>
  );
};

export default Orders;

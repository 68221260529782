import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import I18n from 'translation/i18n';

function ErrorPage({
  errorCode,
  title,
  description,
  isBoundary,
  testId,
}) {
  return (
    <div
      data-testid={testId}
      className="flex column flex-center mx-5"
      style={{
        height: '100vh',
        width: '100vw',
        textAlign: 'center'
      }}
    >
      <p
        data-testid={`${testId}-error-code`}
        style={{ color: '#909090', fontSize: 160 }}
      >
        {errorCode}
      </p>
      <div>
        <h1 data-testid={`${testId}-title`}>{I18n.t(title)}</h1>
        <p data-testid={`${testId}-description`}>{I18n.t(description)}</p>
        {
          isBoundary ?
            <a data-testid={`${testId}-link-back`} href="/">{I18n.t('back')}</a> :
            <Link data-testid={`${testId}-link-back`} to="/">{I18n.t('back')}</Link>
        }
      </div>
    </div>
  );
}

ErrorPage.defaultProps = {
  testId: 'ds-error-page',
};

ErrorPage.propTypes = {
  isBoundary: PropTypes.bool,
  testId: PropTypes.string,
  errorCode: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ErrorPage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import { useURLQuery } from 'hooks/useURLQuery';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { CLIENT_TYPE, OUTSOURCING } from 'models';
import { Flex, Text, Select, Spacer, RadioBox, TashkhesLoader } from 'atoms';
import styles from './styles.module.css';

const outsourcingTypes = [OUTSOURCING.SAMPLE, OUTSOURCING.BATCH];

const BillingHeaderFilters = ({ client, onChange }) => {
  const { query } = useURLQuery();
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [outsourcing, setOutsourcing] = useState(null);
  const { clinics, collectionCenters, fetching } = useSelector(
    (state) => state.lab,
  );

  const handleFilterChange = () => {
    if (!selectedClinic) {
      return;
    }
    const payload = {
      outsourcing: outsourcing || query?.outsourcing,
      clinic_id: selectedClinic.value || query?.clinic_id,
      search: query?.search,
    };
    onChange(payload);
  };

  useEffect(() => {
    const clients =
      client === CLIENT_TYPE.CLINICS ? clinics : collectionCenters;
    const options = clients.map((option) => ({
      value: valueGetter(option, 'secondary.id'),
      label: valueGetter(option, 'secondary.name_i18n'),
    }));
    setClientOptions(options);
    setSelectedClinic(
      query?.clinic_id
        ? options.find((o) => o.value === query?.clinic_id) ||
            valueGetter(options, '0')
        : valueGetter(options, '0') || null,
    );
    setOutsourcing(outsourcing || query?.outsourcing || outsourcingTypes[0]);
  }, [client, clinics, collectionCenters]);

  useEffect(() => {
    handleFilterChange();
  }, [selectedClinic, outsourcing]);

  return (
    <Flex
      flexCol={isMobile}
      itemsCenter={isBrowser}
      className={styles.headerFilterContainer}
    >
      {fetching ? (
        <TashkhesLoader size={30} />
      ) : (
        <Select
          label={i18n.t(snakeCase(client))}
          style={{ width: isMobile ? '100%' : '50%' }}
          options={clientOptions}
          value={selectedClinic}
          onChange={(value) => setSelectedClinic(value)}
        />
      )}

      {isBrowser && <Spacer width={54} />}
      {isMobile && <Spacer height={16} />}
      <Flex flexCol>
        <Text typography="paragraph16">{i18n.t('outsourcing')}</Text>
        {isBrowser && <Spacer height={12} />}
        <Flex itemsCenter>
          <RadioBox
            label={i18n.t(outsourcingTypes[0])}
            value={outsourcingTypes[0]}
            checked={outsourcing === outsourcingTypes[0]}
            onChange={(value) => setOutsourcing(value)}
          />
          <Spacer width={24} />
          <RadioBox
            label={i18n.t(outsourcingTypes[1])}
            value={outsourcingTypes[1]}
            checked={outsourcing === outsourcingTypes[1]}
            onChange={(value) => setOutsourcing(value)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

BillingHeaderFilters.propTypes = {
  client: PropTypes.string,
  onChange: PropTypes.func,
};

export default BillingHeaderFilters;

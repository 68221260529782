import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';

import StepsTemplate from './steps/StepsTemplate';

const NewPatientForm = ({ onSubmit, activeStep, onShowPatientInfo }) => {
  return (
    <Form onSubmit={onSubmit} style={{ height: '100%' }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <StepsTemplate
            activeStep={activeStep}
            onShowPatientInfo={onShowPatientInfo}
          />
        </form>
      )}
    </Form>
  );
};

NewPatientForm.propTypes = {
  onShowPatientInfo: PropTypes.func,
  onSubmit: PropTypes.func,
  activeStep: PropTypes.number,
};

export default NewPatientForm;

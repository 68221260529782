import React, {useEffect, useState} from 'react';

import i18n from 'translation/i18n';
import {serviceOptions} from 'redux/admin/actionCreators';
import {testsLoad} from 'redux/tests/actionCreators';

import {Button} from 'atoms';
import {useDispatch} from 'react-redux';
import TestModal from './TestModal';

const CreateTest = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const loadServices = (params = {}) => dispatch(testsLoad(params));

  const toggleShowModal = (reloadData) => {
    setShowModal(!showModal);
    if (reloadData) {
      loadServices();
    }
  };

  useEffect(() => {
    dispatch(serviceOptions());
  }, []);

  return (
    <>
      <Button label={i18n.t('create_test')} onClick={() => toggleShowModal(false)}/>
      <TestModal isSingleTest={false} showModal={showModal} onClose={toggleShowModal}/>
    </>
  );
};

export default CreateTest;

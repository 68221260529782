import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CLIENT_TYPE } from 'models';
import { CLIENT_TYPES } from 'utils/options';
import LabHeader from 'components/global/LabHeader';
import { ClientList } from 'components/labClients';

const Clients = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const client =
    pathname === '/clients/clinics'
      ? CLIENT_TYPE.CLINICS
      : CLIENT_TYPE.COLLECTION_CENTERS;

  const handleFilterChange = (value) => {
    push(`/clients/${value}`);
  };

  return (
    <>
      <LabHeader
        title="clients"
        selectedOption={client}
        filterOptions={CLIENT_TYPES}
        onFilterChange={handleFilterChange}
      />
      <ClientList client={client} />
    </>
  );
};

export default Clients;

import { MODULE, USER_ROLE } from 'models';
import { getAuthUser } from 'redux/auth/actionCreators';
import { handleAPI, API_VERSION_ONE } from 'utils/apiUtils';
import { userModule } from 'utils/labUtils';
import {
  SERVICE_PRIORITIES_LABEL,
  SERVICES_LABEL,
  LAB_LABEL,
  ADMIN_LABEL,
} from 'utils/constants';

import * as actions from './actions';

export function testsLoad(params = {}) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/services`;
  const method = 'GET';

  return {
    type: actions.TESTS_LOAD,
    payload: handleAPI(url, params, method),
  };
}

export function testUpdate(service_id, data = {}) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrlKey = isAdmin
    ? `${ADMIN_LABEL}/${SERVICE_PRIORITIES_LABEL}/${service_id}`
    : `${LAB_LABEL}/${SERVICES_LABEL}/${service_id}/update`;
  const url = `/${API_VERSION_ONE}/${apiUrlKey}`;
  const method = 'PUT';

  return {
    type: actions.TESTS_UPDATE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function testPriorityUpdate(service_id, data = {}) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrlKey = isAdmin
    ? `${ADMIN_LABEL}/${SERVICE_PRIORITIES_LABEL}/${service_id}`
    : `${LAB_LABEL}/${SERVICES_LABEL}/${service_id}/priority`;
  const url = `/${API_VERSION_ONE}/${apiUrlKey}`;
  const method = 'PUT';

  return {
    type: actions.TESTS_UPDATE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function serviceShow(id, params = {}) {
  const module = userModule();
  const url = `/${API_VERSION_ONE}/${module}/services`;
  const method = 'GET';

  return {
    type: actions.SERVICE_SHOW,
    payload: handleAPI(url, { ...params, id }, method),
  };
}
export function testShow(id, params = {}) {
  const module = userModule();
  const path = module === MODULE.ADMIN ? 'services' : 'service_types';
  const url = `/${API_VERSION_ONE}/${module}/${path}/${id}`;
  const method = 'GET';

  return {
    type: actions.TESTS_SHOW,
    payload: handleAPI(url, params, method, {}),
  };
}

export function testDelete(id) {
  const url = `/${API_VERSION_ONE}/admin/services/${id}`;
  const method = 'DELETE';

  return {
    type: actions.TEST_DELETE,
    payload: handleAPI(url, {}, method),
  };
}

export async function activateAllPrioritiesInTest(id, { status = 'active' }) {
  const module = userModule();

  const url = `/${API_VERSION_ONE}/${module}/services/${id}/activation`;
  const method = 'PUT';

  return handleAPI(url, { status }, method);
}

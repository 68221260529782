import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {printPDF} from 'utils/labUtils';
import {printReport} from 'redux/report/actionCreators';

import {Button} from 'atoms';

const PrintReport = ({type, filters}) => {
  const dispatch = useDispatch();
  const {fetching, printing} = useSelector(state => state.report);

  const handlePrintInvoice = () => {
    dispatch(printReport(type, filters))
      .then(resp => resp.value.data)
      .then(blob => printPDF(blob));
  };

  return (
    <Button
      fitContent
      label={i18n.t(printing ? 'printing' : 'print_pdf')}
      onClick={handlePrintInvoice}
      disabled={fetching || printing}
    />
  );
};

PrintReport.propTypes = {
  filters: PropTypes.shape(),
  type: PropTypes.string
};

export default PrintReport;
import SelfTestBooking from 'components/reservation/SelfTestBooking';
import { Tests, TestDetails } from 'container/patients/Tests';
import { Profile } from 'container/patients/Profile';

const routes = [
  {
    path: '/tests/:id',
    component: TestDetails,
  },
  {
    path: '/tests',
    component: Tests,
  },
  {
    path: '/book-test',
    component: SelfTestBooking,
  },
  {
    path: '/user-profile',
    component: Profile,
  },
];

export default routes;

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import { Grid } from '@mui/material';
import { API_ROOT } from 'env';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { isEmpty } from 'lodash';
import {
  CardContainer,
  CardSectionHeader,
  FieldInfoCardList,
  ModalContent,
  CustomImageUploader,
  SubTabs,
  ShareLink,
} from 'templates';
import { Flex, Text, Spacer } from 'atoms';
import { TEMP_PASS } from 'models';
import { labInfo, labDetailsSubTabs } from 'utils/admin';
import TempLogo from 'assets/icons/logo/labby-logo-eclipse.svg';
import BackButton from 'components/global/BackButton';
import { LabDetailsActions } from '.';
import LabSubscriptions from './LabSubscriptions';

const LabGeneralInfo = (props) => {
  const baseUrl = API_ROOT.split('/')[0];
  const { onEditClick, editing, onEditDone } = props;
  const { service_provider } = useSelector((state) => state.admin);
  const { change } = useForm();
  const [labLogo, setLabLogo] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(
    labDetailsSubTabs[0].value,
  );
  const info = labInfo(!editing);

  const onImageChange = (data) => {
    change('logo.data', data.file);
    change('logo.filename', data.file_file_name);
    change('logo.content_type', data.type);
    return setLabLogo(data.file);
  };

  const handleEditClick = () => {
    change('password', !editing ? '' : TEMP_PASS);
    onEditClick();
  };

  const handleTabClick = (data) => setSelectedTab(data.value);

  React.useEffect(() => {
    if (!isEmpty(service_provider)) {
      setLabLogo(service_provider.logo_url);
    }
  }, [service_provider.logo_url]);

  return (
    <CardContainer noPadding style={{ width: '100%' }}>
      <ModalContent padding="32px 24px">
        <BackButton />
        <Spacer height={16} />
        <Flex justifyBetween itemsCenter>
          <Flex itemsCenter>
            <CustomImageUploader
              imgSrc={labLogo || TempLogo}
              altLabel="Stamp"
              onDone={onImageChange}
              actionLabel={i18n.t('change')}
              disabled={!editing}
              variant="simple"
            />
            <Spacer width={24} />
            <Text typography="title" weight="bold">
              {service_provider.name_i18n || i18n.t('lab_name')}
            </Text>
          </Flex>
          <LabDetailsActions data={service_provider} onDone={onEditDone} />
        </Flex>
      </ModalContent>

      <CardSectionHeader
        uppercased
        title={i18n.t('general_info')}
        customTitle={
          <SubTabs tabs={labDetailsSubTabs} onTabClick={handleTabClick} />
        }
        actionText={i18n.t(editing ? 'save' : 'edit_lab_info')}
        onActionClick={handleEditClick}
        style={{
          height: '16px',
          borderBottom: '1px solid var(--brand-text-gray-lighter)',
        }}
      />
      <Spacer height={12} />
      {selectedTab === labDetailsSubTabs[0].value ? (
        <>
          <ModalContent>
            <Grid container spacing="16px" sx={{ padding: '16px' }}>
              {!isEmpty(info) && <FieldInfoCardList list={info.general} />}
              <Grid item xs={12}>
                <ShareLink styles={{margin: '0'}} titleKey="lab_login_url" link={`http://${service_provider.slug}.${baseUrl}/lab/login`}/>
              </Grid>
            </Grid>
          </ModalContent>
          <CardSectionHeader title={i18n.t('location_info')} uppercased />
          <ModalContent>
            <Grid container spacing="16px" sx={{ padding: '16px' }}>
              {!isEmpty(info) && <FieldInfoCardList list={info.location} />}
            </Grid>
          </ModalContent>
          <CardSectionHeader title={i18n.t('privacy_info')} uppercased />
          <ModalContent>
            <Grid container spacing="16px" sx={{ padding: '16px' }}>
              {!isEmpty(info) && <FieldInfoCardList list={info.privacy} />}
            </Grid>
          </ModalContent>
          <CardSectionHeader title={i18n.t('lab_statistics')} uppercased />
          <ModalContent>
            <Grid container spacing="16px" sx={{ padding: '16px' }}>
              {!isEmpty(info) && (
                <FieldInfoCardList list={info.lab_statistics} />
              )}
            </Grid>
          </ModalContent>
        </>
      ) : (
        <LabSubscriptions />
      )}
    </CardContainer>
  );
};

LabGeneralInfo.propTypes = {
  editing: PropTypes.bool,
  onEditClick: PropTypes.func,
  onEditDone: PropTypes.func,
};

export default LabGeneralInfo;

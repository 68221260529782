import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {ORDER_STATUS, PERMISSION} from 'models';
import { verifyMasterOrderAllTests } from 'redux/lab/actionCreators';
import {userHasPermission} from 'utils/permissionUtils';

import { ListFilters } from 'components/hoc';
import {useParams} from 'react-router-dom';
import ReservationPackageTable from './ReservationPackagesTable';
import { ReservationTestFilters } from './ReservationTestInfo/ReservationTestFilters';
import { ReservationTestsTable } from './ReservationTestsTable';


const tabs = [
  { label: i18n.t('tests'), index: 0 },
  { label: i18n.t('packages'), index: 1 },
];

export const ReservationTests = ({
  handleFilterChange,
  canEditResult,
  refetch,
}) => {
  const dispatch = useDispatch();
  const { id, order_id } = useParams();
  const { reservationTests } = useSelector((state) => state.lab);
  const [tab, setTab] = useState(0);

  const hasPermission =
    userHasPermission(PERMISSION.MEDICAL_VERIFY) || userHasPermission(PERMISSION.TECHNICAL_VERIFY);
  const canVerify = hasPermission &&
    reservationTests.some(test => test.status === ORDER_STATUS.SUBMITTED);

  const handleVerifyAll = () => {
    const masterOrderId = order_id || id;
    dispatch(verifyMasterOrderAllTests(masterOrderId)).then(refetch);
  };

  const filter = ({ generateOtherFilters, generateSelectFilters }) => (
    <ReservationTestFilters
      selectedTab={tab}
      setSelectedTab={setTab}
      tabs={tabs}
      hideFilters={tab === 1}
      generateSelectFilters={generateSelectFilters}
      generateOtherFilters={generateOtherFilters}
      showVerifyAll={tab === 0 && canVerify}
      onVerifyAll={handleVerifyAll}
    />
  );

  return (
    <>
      <ListFilters Wrapped={filter} onChange={handleFilterChange} />
      {tab === 0 ? (
        <ReservationTestsTable
          canEditResult={canEditResult}
          refetch={refetch}
        />
      ) : (
        <ReservationPackageTable />
      )}
    </>
  );
};

ReservationTests.defaultProps = {
  canEditResult: false,
};

ReservationTests.propTypes = {
  handleFilterChange: PropTypes.func,
  refetch: PropTypes.func,
  canEditResult: PropTypes.bool,
};

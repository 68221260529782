import React from 'react';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {formattedPrice} from 'utils/labUtils';

import {DataTable} from 'atoms';

const ReportTable = () => {
  const {reports, fetching} = useSelector(state => state.report);

  const columns = [
    {
      field: 'key',
      headerName: i18n.t('type'),
      minWidth: 180,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'value',
      headerName: i18n.t('amount'),
      minWidth: 170,
      flex: 1,
      renderCell: ({value}) => formattedPrice(i18n.t(value), 'saudi_riyal')
    }
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={reports}
      loading={fetching}
    />
  );
};

export default ReportTable;
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {getStatusColor} from 'utils/labUtils';

import {DataTable, Tag} from 'atoms';

const BatchOrderTable = ({onSelectionChange}) => {
  const {push} = useHistory();
  const {batchDetails, fetching} = useSelector(state => state.orders);
  const [orders, setOrders] = useState([]);

  const handleSelectionModelChange = (orderIds) => {
    onSelectionChange(orderIds);
  };

  const handleCellClick = ({field}, event) => {
    if (field === '__check__') {
      event.stopPropagation();
    }
  };

  const handleRowClick = ({id, row}) => {
    push(`/orders/${row.master_order_id}/tests/${id}`);
  };

  useEffect(() => {
    setOrders(batchDetails.orders);
  }, [batchDetails]);


  const columns = [
    {
      field: 'patient_name',
      headerName: i18n.t('patient'),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'sample_id',
      headerName: i18n.t('sample_id'),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'service_name',
      headerName: i18n.t('test_name'),
      minWidth: 150,
      flex: 1
    },
    {
      field: 'position',
      headerName: i18n.t('position'),
      minWidth: 100,
      flex: 1
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 100,
      flex: 1
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    },
    {
      field: 'result',
      headerName: i18n.t('result'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    }
  ];

  return (
    <DataTable
      mobileTableHeight={13.1}
      checkboxSelection
      columns={columns}
      rows={orders}
      loading={fetching}
      onCellClick={handleCellClick}
      onRowClick={handleRowClick}
      onSelectionModelChange={handleSelectionModelChange}
    />
  );
};

BatchOrderTable.propTypes = {
  onSelectionChange: PropTypes.func
};

export default BatchOrderTable;

import React from 'react';
import PropTypes from 'prop-types';
import { isBrowser, isMobile } from 'react-device-detect';

import i18n from 'translation/i18n';
import { ORDER_STATUS } from 'models';
import { PRIORITIES } from 'utils/options';
import { generateOptionsFromEnum } from 'utils/labUtils';

import { Grid } from '@mui/material';
import { Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';

const dateFilterConfigs = [
  { key: 'from_date', placeholderKey: 'from_date' },
  { key: 'to_date', placeholderKey: 'to_date' },
];
const selectFiltersConfigs = [
  {
    key: 'priority',
    options: PRIORITIES,
    placeholderKey: 'priority',
  },
  {
    key: 'status',
    options: generateOptionsFromEnum(ORDER_STATUS),
    placeholderKey: 'status',
  }
];

const OverdueTestFilters = ({
  generateOtherFilters,
  generateSelectFilters,
}) => {
  const filter = (
    <Grid item xs={1}>
      <SelectFilter
        className="ml-8"
        selectFilterConfigs={selectFiltersConfigs}
        dateFilterConfigs={dateFilterConfigs}
        onApply={generateSelectFilters}
        onCancel={generateSelectFilters}
      />
    </Grid>
  );

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={2}
      alignItems="center"
    >
      <Grid item xs={8} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('overdue_tests_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t('overdue_tests_subtitle')}
        </Text>
      </Grid>
      {isMobile && (
        <>
          <Grid item xs={2} />
          {filter}
        </>
      )}
      <Grid item xs={12} lg={4}>
        <SearchInput
          placeholder={i18n.t('overdue_tests_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && (
        <>
          <Grid item lg={4} />
          {filter}
        </>
      )}
    </Grid>
  );
};

OverdueTestFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
};

export default OverdueTestFilters;
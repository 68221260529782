import React from 'react';
import i18n from 'translation/i18n';
import { isLabUser } from 'utils/labUtils';
import {
  CLINIC_LABEL,
  COLLECTION_CENTER_LABEL,
  LAB_LABEL,
} from 'utils/constants';
import LabHeader from 'components/global/LabHeader';
import { MasterReservationList } from 'components/reservation';
import { useURLQuery } from 'hooks/useURLQuery';

const headerOptions = [
  { value: 'all', label: i18n.t('all_patients') },
  { value: LAB_LABEL, label: i18n.t('lab_patients') },
  { value: CLINIC_LABEL, label: i18n.t('clinic_patients') },
  {
    value: COLLECTION_CENTER_LABEL,
    label: i18n.t('collection_center_patients'),
  },
];

export const Reservations = () => {
  const { query, setQuery } = useURLQuery();

  const tab = query?.labHeader || headerOptions[0].value;

  const handleFilterChange = (value) => {
    setQuery({ labHeader: value });
  };

  return (
    <>
      <LabHeader
        title="reservations"
        filterOptions={isLabUser() ? headerOptions : []}
        onFilterChange={handleFilterChange}
        selectedOption={tab || headerOptions[0].value}
      />
      <MasterReservationList module={tab} />
    </>
  );
};

import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import styles from './DataTable.module.css';

const SelectTestsTable = () => {
  const { tests, fetching } = useSelector((state) => state.tests);
  const { input } = useField('service_ids');

  // fixes a bug, DataGrid call handleSelection with
  // an empty input even though there is default selection
  let isDefaultPopulated = useRef(false).current;
  const handleSelection = (e) => {
    const ignore =
      isDefaultPopulated === false && // called the first time
      Array.isArray(e) &&
      e.length === 0 && // handle select called with empty input
      Array.isArray(input.value) &&
      input.value.length > 0; // and there is default input

    if (ignore) {
      isDefaultPopulated = true;
      return; // prevent overwriting the default input
    }

    input.onChange(e);
  };

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('test_name'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },

    {
      field: 'category_type',
      headerName: i18n.t('category'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
  ];

  return (
    <Box
      height={isMobile ? `calc(100vh - ${17.5}rem)` : 'calc(100vh - 15rem)'}
      width="100%"
    >
      <DataGrid
        className={clsx(styles.dataTable, '')}
        classes={styles}
        columns={columns}
        checkboxSelection
        rowHeight={isMobile ? 40 : 50}
        mobileTableHeight={17.5}
        pageSize={25}
        rows={!fetching && (tests || [])}
        loading={fetching}
        onSelectionModelChange={(e) => handleSelection(e)}
        selectionModel={input.value}
      />
    </Box>
  );
};

export default SelectTestsTable;

// Import translations
import Auth from './modules/en/auth';
import Lab from './modules/en/lab';
import Common from './modules/en/common';
import Reservation from './modules/en/reservation';
import Tests from './modules/en/tests';
import Overview from './modules/en/overview';
import Clients from './modules/en/clients';
import Billing from './modules/en/billing';
import LandingPage from './modules/en/landing-page';

const EN = {
  ...Auth,
  ...Lab,
  ...Common,
  ...Reservation,
  ...Tests,
  ...Overview,
  ...Clients,
  ...Billing,
  ...LandingPage
};

export default EN;

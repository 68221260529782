import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import i18n from 'translation/i18n';
import { Card } from 'atoms';
import { CardContainer } from 'templates';
import { ReservationInfoActions } from './ReservationInfo';
import { ReservationInfoForm } from './ReservationInfo/ReservationInfoForm';

export const MasterReservationInfo = () => {
  const { reservationInfo } = useSelector((state) => state.lab);

  const content = (
    <CardContainer
      title={i18n.t('reservation_info_title')}
      noPadding
      cardActions={<ReservationInfoActions masterOrder={reservationInfo} />}
      showBackButton
    >
      <ReservationInfoForm />
    </CardContainer>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

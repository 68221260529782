import i18n from 'translation/i18n';
import {
  BATCH_ORDER_STATUS,
  CLINIC_STATUS,
  MASTER_RESERVATION_STATUS,
  MODULE,
  ORDER_STATUS, PERMISSION, STOCK_STATUS,
  VERIFICATION_STATUS,
} from 'models';
import { getAuthUser } from 'redux/auth/actionCreators';
import { isEmpty } from 'lodash';
import { PATIENT_SIGNUP_BOOKING } from 'utils/constants';
import { valueGetter } from 'utils/uiHelpers';
import {
  composeValidators,
  maxLength,
  minLength,
  required,
  enNumber,
} from './FormValidations';
import { userHasPermission } from './permissionUtils';

const initialPrice = {
  tax: 0,
  sub_total: 0,
  total: 0,
  discount_amount: 0,
};

export const getExpirationColor = (value) => {
  switch (value) {
    case 'Expired':
    case 'Will expire in 3 days':
    case 'Will expire in a week':
      return 'var(--text-color-button-danger)';
    case 'Will expire in a month':
      return 'var(--brand-process-text)';
    case 'Valid':
    default:
      return 'var(--brand-success)';
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case ORDER_STATUS.CREATED:
      return 'var(--brand-warning)';
    case ORDER_STATUS.PAID:
    case MASTER_RESERVATION_STATUS.PAID:
    case STOCK_STATUS.IN_USE:
    case VERIFICATION_STATUS.VERIFIED:
      return 'var(--brand-success)';
    case ORDER_STATUS.CONFIRM:
      return 'var(--brand-confirm-text)';
    case ORDER_STATUS.PROCESS:
    case ORDER_STATUS.SUBMITTED:
    case VERIFICATION_STATUS.PENDING:
      return 'var(--brand-process-text)';
    case BATCH_ORDER_STATUS.DONE:
    case ORDER_STATUS.READY:
    case MASTER_RESERVATION_STATUS.DONE:
      return 'var(--brand-ready-text)';
    case ORDER_STATUS.REJECT:
    case ORDER_STATUS.CANCEL:
    case MASTER_RESERVATION_STATUS.CANCELED:
      return 'var(--text-color-button-danger)';
    case ORDER_STATUS.COLLECT:
    case MASTER_RESERVATION_STATUS.PENDING:
    default:
      return 'var(--brand-collect-text)';
  }
};

export const formattedPrice = (value, currency) => {
  value = Number.isNaN(parseFloat(value)) ? 0 : value;
  const price = parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${price} ${i18n.t(currency)}`;
};

export const getListForSelect = (list = []) =>
  list.map((li) => {
    return { ...li, value: li.id, label: li.name || li.name_i18n };
  });

export const getOptionsForDropdown = (list, id = 'id', name = 'name') => {
  if (!isEmpty(list)) {
    return list.map((li) => ({ value: li?.[id], label: li?.[name] }));
  }
  return [];
};

export const generateOptionsFromEnum = (enumObj) =>
  Object.values(enumObj).map((value) => ({
    value,
    label: i18n.t(value),
  }));

export const translatedLabelForOptions = (items, key, prefix = '') =>
  items.map((item) => ({
    ...item,
    [key]: i18n.t(`${prefix}${item[key]}`),
  }));

export const getPriceFromPayments = (payments) =>
  payments
    .filter((payment) => payment.status === 'success')
    .reduce(
      (price, payment) => ({
        tax: Number(price.tax) + Number(payment.tax),
        sub_total: Number(price.sub_total) + Number(payment.sub_total),
        total: Number(price.total) + Number(payment.amount_paid),
        discount_amount:
          Number(price.discount_amount) + Number(payment.discount_amount),
      }),
      initialPrice,
    );

export const isStepNotValid = (values, stepNum, stepsValidation) => {
  const validations = stepsValidation[stepNum];
  const isEmptyField = validations
    .map((validate) => {
      const paValue = values[validate.parent];
      if (!paValue || !paValue[validate.child]) {
        return 1;
      }
      return null;
    })
    .filter(Boolean);
  return isEmptyField.length > 0;
};

export const orderNextStatus = (status) => {
  if (status === ORDER_STATUS.READY && userHasPermission(PERMISSION.MEDICAL_VERIFY)) {
    return [ORDER_STATUS.PROCESS, ORDER_STATUS.RECOLLECT];
  }
  const nextStatus = {
    [ORDER_STATUS.RECOLLECT]: [ORDER_STATUS.COLLECT],
    [ORDER_STATUS.CONFIRM]: [ORDER_STATUS.COLLECT],
    [ORDER_STATUS.COLLECT]: [ORDER_STATUS.PROCESS, ORDER_STATUS.RECOLLECT],
    [ORDER_STATUS.PROCESS]: [ORDER_STATUS.RECOLLECT],
  };
  return nextStatus[status];
};

export const orderNextStatusForClinic = {
  [ORDER_STATUS.RECOLLECT]: [ORDER_STATUS.COLLECT],
  [ORDER_STATUS.CONFIRM]: [ORDER_STATUS.COLLECT],
};

export const clinicNextStatus = {
  [CLINIC_STATUS.PENDING]: [CLINIC_STATUS.ACCEPTED, CLINIC_STATUS.REJECTED],
};

export const orderTrackerStatus = (currentStatus) => {
  switch (currentStatus) {
    case ORDER_STATUS.COLLECT:
      return [ORDER_STATUS.PROCESS, ORDER_STATUS.SUBMITTED, ORDER_STATUS.READY];
    case ORDER_STATUS.PROCESS:
      return [ORDER_STATUS.SUBMITTED, ORDER_STATUS.READY];
    case ORDER_STATUS.SUBMITTED:
    case ORDER_STATUS.TECHNICAL:
    case ORDER_STATUS.MEDICAL:
      return [ORDER_STATUS.READY];
    default:
      return [
        ORDER_STATUS.COLLECT,
        ORDER_STATUS.PROCESS,
        ORDER_STATUS.SUBMITTED,
        ORDER_STATUS.READY
      ];
  }
};

export const getLastChars = (label, numOfChars = 2) => {
  let subString = '';
  [...Array(Number(numOfChars))].map((_, index) => {
    const last = Number(label?.length) - (Number(numOfChars) - index);
    if (last > 0) {
      subString += label?.charAt(last) || '';
    }
    return subString;
  });
  return subString;
};

export const userModule = () => valueGetter(getAuthUser(), 'module');

export const isPatient = () => userModule() === MODULE.PATIENT;

export const isClinicUser = () => userModule() === MODULE.CLINIC;

export const isLabUser = () => userModule() === MODULE.LAB;

export const isCollectionCenterUser = () =>
  userModule() === MODULE.COLLECTION_CENTER;

export const isLabClient = () => isClinicUser() || isCollectionCenterUser();

export const isAdminUser = () => userModule() === MODULE.ADMIN;

export const isPatientSignup = localStorage.getItem(PATIENT_SIGNUP_BOOKING);

export const profileInfo = (disabled, data = {}, options) => {
  return {
    general: [
      {
        name: 'name_en',
        label: i18n.t('name_en'),
        placeholder: i18n.t('name_en'),
        validate: required,
        disabled,
        value: data.name_en,
      },
      {
        name: 'name_ar',
        label: i18n.t('name_ar'),
        placeholder: i18n.t('name_ar'),
        validate: required,
        disabled,
        value: data.name_ar,
      },
      {
        name: 'bank_name',
        label: i18n.t('bank_name'),
        placeholder: i18n.t('bank_name'),
        validate: required,
        disabled,
        value: data.bank_name,
      },
      {
        name: 'iban_number',
        label: i18n.t('iban'),
        placeholder: i18n.t('iban'),
        validate: required,
        disabled,
        value: data.iban_number,
      },
      {
        name: 'vat_number',
        label: i18n.t('vat_number'),
        placeholder: i18n.t('vat_number'),
        validate: composeValidators(required, enNumber),
        disabled,
        value: data.vat_number,
      },
      {
        name: 'cr_number',
        label: i18n.t('cr_number'),
        placeholder: i18n.t('cr_number'),
        validate: composeValidators(
          required,
          enNumber,
          maxLength(10),
          minLength(10),
        ),
        maxLength: 10,
        disabled,
        value: data.cr_number,
      },
    ],

    location: [
      {
        name: 'address',
        label: i18n.t('address'),
        placeholder: i18n.t('address'),
        validate: required,
        disabled,
        value: data.address,
      },
      {
        name: 'location.country',
        label: i18n.t('country'),
        as: 'dropdown',
        placeholder: i18n.t('country'),
        disabled,
        value: data.location,
        options: options?.countries,
      },
      {
        name: 'location.street_name',
        label: i18n.t('street_name'),
        placeholder: i18n.t('street_name'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.additional_street_name',
        label: i18n.t('additional_street_name'),
        placeholder: i18n.t('additional_street_name'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.building_number',
        label: i18n.t('building_number'),
        placeholder: i18n.t('building_number'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.city_name',
        label: i18n.t('city_name'),
        as: 'dropdown',
        placeholder: i18n.t('city_name'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.postal_zone',
        label: i18n.t('postal_zone'),
        placeholder: i18n.t('postal_zone'),
        disabled,
        value: data.location,
      },
    ],

    privacy: [
      {
        name: 'admin_service_provider.0.email',
        label: i18n.t('email'),
        placeholder: i18n.t('email'),
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('email'),
        },
        disabled: true,
        value: data.admin_service_provider
          ? data.admin_service_provider[0]?.email
          : '',
      },
      !data.is_admin_role && {
        name: 'admin_service_provider.0.password',
        label: i18n.t('password'),
        placeholder: i18n.t('password'),
        type: 'password',
        hideShowPasswordOption: true,
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('password'),
        },
        disabled: true,
        value: data.admin_service_provider
          ? data.admin_service_provider[0]?.password
          : '',
      },
      {
        name: 'admin_service_provider.0.mobile',
        label: i18n.t('mobile_number'),
        placeholder: i18n.t('mobile_number'),
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('mobile_number'),
        },
        disabled: true,
        value: data.admin_service_provider
          ? data.admin_service_provider[0]?.mobile
          : '',
      },
    ].filter(Boolean),
    lab_statistics: [
      {
        name: 'reserved_orders',
        label: i18n.t('reserved_orders'),
        placeholder: i18n.t('reserved_orders'),
        disabled,
        value: data.reserved_orders,
      },
      {
        name: 'confirmed_orders',
        label: i18n.t('confirmed_orders'),
        placeholder: i18n.t('confirmed_orders'),
        disabled,
        value: data.confirmed_orders,
      },
      {
        name: 'total_income',
        label: i18n.t('total_income'),
        placeholder: i18n.t('total_income'),
        disabled,
        value: data.total_income,
      },
    ],
  };
};

export const printPDF = (content) => {
  try {
    const blobURL = URL.createObjectURL(content);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      });
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const downloadCSV = (content, fileName) => {
  try {
    const blob = new Blob([content], { type: 'text/csv' });
    const a = document.createElement('a');
    a.download = `${fileName}.csv`;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

// Labs Management UI-Helpers:
export const newLabStepTitles = {
  0: i18n.t('plan'),
  1: i18n.t('create_lab_1'),
  2: i18n.t('create_lab_2'),
  3: i18n.t('payment'),
};

export const activatingText = {
  false: i18n.t('activate'),
  true: i18n.t('deactivate'),
};

export const activatingTextColor = {
  false: 'secondary',
  true: 'danger',
};

export const labStatusTextColor = {
  pending: 'var(--brand-process-text)',
  accepted: 'var(--brand-success)',
  rejected: 'var(--brand-danger)',
  activated: 'var(--brand-success)',
  active: 'var(--brand-success)',
  deactivated: 'var(--brand-warning)',
  inactive: 'var(--brand-warning)',
};

export const labStatusTagColor = {
  pending: 'var(--brand-warning-bg-1)',
  accepted: 'var(--brand-success-bg)',
  rejected: 'var(--brand-danger-bg)',
  activated: 'var(--brand-success-bg)',
  active: 'var(--brand-success-bg)',
  deactivated: 'var(--brand-warning-bg-1)',
  inactive: 'var(--brand-warning-bg-1)',
};

export const getOtpFromValues = (values) => {
  const otp = [...Array(6)].map((ـ, i) => values[`code-${i}`]);
  return otp.join('');
};

export const copyToClipboard = (text) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(text);
  }
  // text area method
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    // here the magic happens
    // eslint-disable-next-line no-unused-expressions
    document.execCommand('copy') ? res() : rej();
    textArea.remove();
  });
};

export const subscriptionOrder = ['labby', 'labby_plus', 'labby_infinity'];

import React from 'react';
import {CustomSwitcher} from 'atoms';
import {LOCALE} from 'utils/constants';
import {setLocale} from 'redux/auth/actionCreators';
import {languages} from 'utils/options';

const LanguageSwitcher = () => {
  const switchLanguage = (data) => {
    setLocale(data.value);
    window.location.reload();
  };

  return (
    <CustomSwitcher 
      defaultChecked={localStorage.getItem(LOCALE)}
      options={languages}
      onChange={switchLanguage}
    />
  );
};

export default LanguageSwitcher;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useURLQuery } from 'hooks/useURLQuery';
import { REPORT } from 'models';
import { getReportList } from 'redux/report/actionCreators';
import { dateTimeQueryFormatToDate, dateTimeToQueryFormat } from 'utils/date';
import { ListFilters } from 'components/hoc';
import useHandelPagination from 'hooks/useHandelPagination';
import ReportFilters from './ReportFilters';
import ReportTable from './ReportTable';

const InvoicesReports = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();
  const appliedFilters = {
    search: query.search,
    filter: query.filter,
    source: query.source,
    source_id: query.source_id,
    from_date: query.from_date ? dateTimeQueryFormatToDate(query.from_date) : null,
    to_date: query.to_date ? dateTimeQueryFormatToDate(query.to_date) : null
  };
  const fetchReports = (params = {}) => {
    dispatch(getReportList(REPORT.INVOICES, params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      filter: filter.filter,
      source: filter.source,
      source_id: filter.source_id,
      from_date: filter.from_date ? dateTimeToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateTimeToQueryFormat(filter.to_date) : null
    };
    setQuery(params);
    fetchReports(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: fetchReports
  });

  useEffect(() => {
    fetchReports(appliedFilters);
  }, []);

  return (
    <>
      <ListFilters
        Wrapped={ReportFilters}
        reportType={REPORT.INVOICES}
        filters={appliedFilters}
        onChange={handleFilterChange}
      />
      <ReportTable onPagination={handlePagination} />
    </>
  );
};

export default InvoicesReports;

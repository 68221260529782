import React from 'react';
import { isMobile } from 'react-device-detect';
import i18n from 'translation/i18n';
import { Card } from 'atoms';
import { CardContainer } from 'templates';
import { PackageInfoForm } from './PackageInfo/PackageInfoForm';

const PackageInfo = () => {
  const content = (
    <CardContainer
      title={i18n.t('package_info_title')}
      noPadding
      showBackButton
    >
      <PackageInfoForm />
    </CardContainer>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default PackageInfo;

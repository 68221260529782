import React, {useCallback} from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { dateToQueryFormat } from 'utils/date';
import { useURLQuery } from 'hooks/useURLQuery';
import useHandelPagination from 'hooks/useHandelPagination';
import { getOverdueTests } from 'redux/lab/actionCreators';

import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import OverdueTestFilters from './OverdueTestFilters';
import OverdueTestTable from './OverdueTestTable';

const OverdueTestList = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const loadOverdueTests = useCallback(
    (params = {}) => {
      dispatch(getOverdueTests(params));
    },
    [getOverdueTests, dispatch, module],
  );

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: loadOverdueTests,
  });

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      priority: filter.priority,
      status: filter.status,
      from_date: filter.from_date ? dateToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateToQueryFormat(filter.to_date) : null,
    };
    loadOverdueTests(params);
    setQuery(params);
  };
  
  const content = (
    <>
      <ListFilters
        Wrapped={OverdueTestFilters}
        onChange={handleFilterChange}
      />
      <OverdueTestTable onPagination={handlePagination} />
    </>
  );

  React.useEffect(() => {
    const params = {
      search: query.search,
      priority: query.priority,
      status: query.status,
      from_date: query.from_date ? dateToQueryFormat(query.from_date) : null,
      to_date: query.to_date ? dateToQueryFormat(query.to_date) : null,
    };
    handleFilterChange(params);
  }, []);

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default OverdueTestList;
import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import { Button } from 'atoms';
import { ModalContent } from 'templates';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

const StepsTemplate = ({ isEdit, activeStep }) => {
  const buttonKey = isEdit ? 'update' : 'create';

  const step = {
    1: <Step1 />,
    2: <Step2 />,
    3: <Step3 />,
    4: <Step4 />
  };

  return (
    <>
      <ModalContent>
        {step[activeStep]}
      </ModalContent>
      <ModalContent>
        <Button type="submit" label={i18n.t(activeStep === 4 ? buttonKey : 'next')} />
      </ModalContent>
    </>
  );
};

StepsTemplate.propTypes = {
  isEdit: PropTypes.bool,
  activeStep: PropTypes.number,
};

export default StepsTemplate;
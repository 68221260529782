import React from 'react';

import i18n from 'translation/i18n';
import { required } from 'utils/FormValidations';

import { Spacer } from 'atoms';
import { TextInputField } from 'components/global/final-form';

const Step1 = () =>  (
    <>
      <TextInputField
        name="item_name"
        label={i18n.t('item_name')}
        placeholder={i18n.t('item_name')}
        validate={required}
      />
      <Spacer height={4} />
      <TextInputField
        name="description"
        label={i18n.t('description')}
        placeholder={i18n.t('description')}
        validate={required}
      />
      <Spacer height={4} />
      <TextInputField
        name="lot_number"
        label={i18n.t('lot_number')}
        placeholder={i18n.t('lot_number')}
        validate={required}
      />
      <Spacer height={4} />
      <TextInputField
        name="serial_number"
        label={i18n.t('serial_number')}
        placeholder={i18n.t('serial_number')}
        validate={required}
      />
      <Spacer height={4} />
      <TextInputField
        name="manufacturer"
        label={i18n.t('manufacturer')}
        placeholder={i18n.t('manufacturer')}
        validate={required}
      />
      <Spacer height={4} />
      <TextInputField
        name="unit"
        label={i18n.t('unit')}
        placeholder={i18n.t('unit')}
        validate={required}
      />
    </>
);

export default Step1;
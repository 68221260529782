import React, {useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { ORDER_STATUS } from 'models';
import { usePathname } from 'hooks';
import { valueGetter } from 'utils/uiHelpers';
import { isLabUser } from 'utils/labUtils';

import { DataTable } from 'atoms';
import EditResultTextField from 'components/orders/EditResultTextField';
import SubTestDetailModal from 'components/tests/DetailCards/SubTestDetailModal';

const SubTestTable = ({masterOrderId, testId, testStatus, subtests, refetch}) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const isOrderPage = usePathname().includes('orders');
  const isLab = isLabUser();
  const canEdit = [
    ORDER_STATUS.PROCESS,
    ORDER_STATUS.SUBMITTED,
    ORDER_STATUS.READY
  ].includes(testStatus);

  const columns = [
    {
      field: 'lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'name',
      headerName: i18n.t('remisol_name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'test_type',
      headerName: i18n.t('test_type'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'overall_result',
      headerName: i18n.t('test_interpretation'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'test_result',
      headerName: i18n.t('test_result'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({row, value}) => {
        if (!isOrderPage || !isLab) return <>{value}</>;
        return (
          <EditResultTextField
            refRangeType={row.ref_range_type}
            refOptions={row.reference_range_non_logicals || []}
            result={value}
            attachmentUrl={row.attachment_url || ''}
            canEdit={canEdit}
            masterOrderId={masterOrderId}
            testId={testId}
            subTestId={row.id}
            onSubmit={refetch}
          />
        );
      }
    }
  ];

  const handleCellClick = ({ field }, event) => {
    if (field === 'test_result') {
      event.stopPropagation();
    }
  };

  const handleRowClick = ({row}) => {
    setSelected(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelected(null);
  };

  return (
    <>
      <DataTable
        height={320}
        columns={columns}
        rows={subtests}
        rowCount={subtests.length}
        onCellClick={handleCellClick}
        onRowClick={handleRowClick}
        onPageChange={() => {}}
      />
      {showModal && (
        <SubTestDetailModal
          showModal={showModal}
          service={selected}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

SubTestTable.propTypes = {
  masterOrderId: PropTypes.string,
  testId: PropTypes.string,
  testStatus: PropTypes.string,
  subtests: PropTypes.arrayOf(PropTypes.shape()),
  refetch: PropTypes.func,
};

export default SubTestTable;
import React from 'react';
import {useSelector} from 'react-redux';
import i18n from 'translation/i18n';
import {ModalContent} from 'templates';
import {TextInputField} from 'components/global/final-form';
import {Spacer, Button} from 'atoms';
import {
  composeValidators,
  required,
  email,
} from 'utils/FormValidations';

const ChangeEmailFields = () => {
  const {submitting} = useSelector(state => state.profile);
  return (
    <ModalContent>
      <TextInputField 
        name="new_email" 
        label={i18n.t('new_email')} 
        placeholder={i18n.t('email')}
        validate={composeValidators(required, email)}
      />
      <Spacer height={112}/>
      <Button label={i18n.t('change_email')} type="submit" disabled={submitting}/>
    </ModalContent>
  );
};

export default ChangeEmailFields;

import { getAuthUser } from 'redux/auth/actionCreators';
import {PAGE, USER_ROLE, REPORT, ORDER_STATUS, PERMISSION} from 'models';
import { USER_PERMISSIONS } from './constants';
import {isClinicUser, isCollectionCenterUser, isLabUser, isPatient} from './labUtils';

const pagePermissionMap = {
  /* there is a mismatch between the permission
  * name coming from backend than the one in frontend.
  * This is a mapping for mismatch permissions.
  */
  [PAGE.PCR_BATCHES]: 'pcr_batches',
  [PAGE.OVERDUE_TESTS]: 'reservations',
  [PAGE.INVENTORY]: 'stock',
  [PAGE.PACKAGE]: 'tests'
};

export const actionPermissionMap = {
  [ORDER_STATUS.COLLECT]: PERMISSION.ORDER_COLLECT,
  [ORDER_STATUS.RECOLLECT]: PERMISSION.ORDER_RECOLLECT,
  [ORDER_STATUS.PROCESS]: PERMISSION.ORDER_PROCESS,
};

export const userCanAccessPage = (page) => {
  const userPermissions = JSON.parse(localStorage.getItem(USER_PERMISSIONS)) || [];
  const value = pagePermissionMap[page] || page;
  const hasPermission = userPermissions.includes(value.toLowerCase());
  if (hasPermission) return true;
  const { role_label } = getAuthUser();
  switch (role_label) {
    case USER_ROLE.LAB_ADMIN:
      // return true;
      return [
        PAGE.USER_PROFILE,
        PAGE.LAB_PROFILE,
        PAGE.USERS,
        PAGE.CLIENTS,
        PAGE.TESTS,
        PAGE.SHARE,
        PAGE.SERIAL,
      ].includes(page);
    case USER_ROLE.CLINIC:
    case USER_ROLE.COLLECTION_CENTER:
      return [
        PAGE.USER_PROFILE,
        PAGE.OVERVIEW,
        PAGE.RESERVATIONS,
        PAGE.ORDERS,
        PAGE.TESTS,
        PAGE.LAB_PROFILE,
        PAGE.REPORTS,
        PAGE.SERIAL,
      ].includes(page);
    default:
      return [PAGE.USER_PROFILE].includes(page);
  }
};

export const clientHasPermission = (permission) => {
  return [
    PERMISSION.ADD_PATIENT,
    PERMISSION.MASTER_ORDER_PAY,
    PERMISSION.MASTER_ORDER_CANCEL,
    PERMISSION.PRINT_INVOICE,
    PERMISSION.PRINT_RESULT,
    PERMISSION.PRINT_BARCODE,
    PERMISSION.ORDER_COLLECT
  ].includes(permission);
};

export const patientHasPermission = (permission) => {
  return [
    PERMISSION.PRINT_RESULT
  ].includes(permission);
};

export const userHasPermission = (permission) => {
  const isPatientUser = isPatient();
  if (isPatientUser) return patientHasPermission(permission);
  const isClientUser = isClinicUser() || isCollectionCenterUser();
  if (isClientUser) return clientHasPermission(permission);
  // const { role_label } = getAuthUser();
  // if ([USER_ROLE.LAB_ADMIN].includes(role_label)) return true;
  const userPermissions = JSON.parse(localStorage.getItem(USER_PERMISSIONS)) || [];
  return userPermissions.includes(permission);
};

export const reportPermission = (report) => {
  const isLab = isLabUser();
  if (isLab) return report !== REPORT.SOURCE_REPORTS;
  return report === REPORT.SOURCE_REPORTS;
};

import { DataTable, Tag, TashkhesLoader } from 'atoms';
import OrderResultTransition from 'components/global/OrderResultTransition';
import { useTablePagination } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getBatchOrder,
  getBatchOrders,
  updateBatchOrderResult,
} from 'redux/orders/actionCreators';
import i18n from 'translation/i18n';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';
import { getStatusColor } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

const BatchListTestName = ({ batchId }) => {
  const [fetching, setFetching] = useState(true);
  const [testName, setTestName] = useState(null);

  const getBatchDetails = async () => {
    const result = await getBatchOrder(batchId).payload;
    setTestName(valueGetter(result, 'data.batch.orders.0.service_name'));
    setFetching(false);
  };

  useEffect(() => {
    getBatchDetails();
  }, []);

  return fetching ? <TashkhesLoader /> : <p>{testName}</p>;
};

BatchListTestName.propTypes = {
  batchId: PropTypes.string,
};

const BatchOrderListTable = ({ onPagination }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { batchOrders, fetching, pagination } = useSelector(
    (state) => state.orders,
  );

  const handleMenuClick = (batchId, result) => {
    dispatch(updateBatchOrderResult(batchId, result)).then(() => {
      dispatch(getBatchOrders());
    });
  };

  const handleRowClick = ({ id }) => {
    push(`/pcr-batches/${id}`);
  };

  const rows = useTablePagination({ pagination, dataList: batchOrders });

  const columns = [
    {
      field: 'serial_number',
      headerName: i18n.t('batch'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => `${i18n.t('batch', { batchNumber: value })}`,
    },
    {
      field: 'test_name',
      headerName: i18n.t('test_name'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }) => <BatchListTestName batchId={row.id} />,
    },
    {
      field: 'created_at',
      headerName: i18n.t('created_at'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => format(value, DISPLAY_DATE_FORMAT),
    },
    {
      field: 'created_by.name_i18n',
      headerName: i18n.t('created_by'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ value }) => (
        <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)} />
      ),
    },
    {
      field: 'order_count',
      headerName: i18n.t('no_of_orders'),
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => i18n.t(value),
    },
    {
      field: 'result',
      headerName: i18n.t('result'),
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => i18n.t(value),
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({ row }) => (
        <OrderResultTransition
          menuPosition="left"
          result={row.result}
          status={row.status}
          onMenuClick={(result) => handleMenuClick(row.id, result)}
        />
      ),
    },
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={rows}
      loading={fetching}
      onRowClick={handleRowClick}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

export default BatchOrderListTable;

BatchOrderListTable.propTypes = {
  onPagination: PropTypes.func,
};

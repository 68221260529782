import React, { useEffect, useState } from 'react';

import i18n from 'translation/i18n';
import {
  getClientDetails,
  syncClinicTestsWithLab,
  updateClient,
  searchClientTest,
} from 'redux/lab/actionCreators';
import { valueGetter, isPathIncludes } from 'utils/uiHelpers';
import { formattedPrice } from 'utils/labUtils';
import { ACTIVE_STATUS } from 'models';

import { ReactComponent as EditIcon } from 'assets/icons/common/edit.svg';
import { DataTable, Switch, Text, Flex, Spacer } from 'atoms';
import { SectionTitle } from 'templates';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { ListFilters } from 'components/hoc';
import PriceModal from './PriceModal';
import ClientTestFilters from './ClientTestFilters';

const ClientTests = () => {
  const dispatch = useDispatch();
  const {
    clientInfo,
    clientTests: tests,
    fetching,
  } = useSelector((state) => state.lab);
  const [selectedTest, setSelectedTest] = useState(null);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});

  const onPriceEdit = (value) => {
    setSelectedTest(value);
    setShowPriceModal(true);
  };

  const updateTest = async (values) => {
    const params = cloneDeep(values);
    params.forEach((val) => {
      delete val.priority;
      delete val.service;
    });

    const payload = {
      service_provider_assocation_services_pricings_attributes: params,
    };
    await dispatch(updateClient(clientInfo.id, payload));
    await dispatch(getClientDetails(clientInfo.id));
    setSelectedTest(null);
    setShowPriceModal(false);
  };

  const syncClinicTests = async () => {
    await dispatch(syncClinicTestsWithLab(clientInfo.id));
  };

  const handleFilterChange = (value) => {
    if (Object.keys(value).length === 0) setAppliedFilters({});
    else setAppliedFilters({ ...appliedFilters, ...value });
  };

  const handlePriceUpdate = (amount) => {
    if (amount) {
      const values = tests.map((test) => {
        if (test.id === selectedTest.id) {
          return {
            ...test,
            amount,
          };
        }
        return test;
      });
      updateTest(values);
    } else {
      setShowPriceModal(false);
    }
  };

  const handlePriceStatus = (isChecked, row) => {
    const values = tests.map((test) => {
      if (test.id === row.id) {
        return {
          ...test,
          status: isChecked ? ACTIVE_STATUS.ACTIVE : ACTIVE_STATUS.INACTIVE,
        };
      }
      return test;
    });
    updateTest(values);
  };

  const columns = [
    {
      field: 'service.name',
      headerName: i18n.t('test_name'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'service.lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'amount',
      headerName: i18n.t('test_price'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ row, value }) => (
        <Flex itemsCenter>
          <Text typography="caption13" weight="bold">
            {formattedPrice(value, 'saudi_riyal')}
          </Text>
          <Spacer width={4} />
          <EditIcon
            height={16}
            onClick={() => onPriceEdit(row)}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
      ),
    },
    {
      field: 'status',
      headerName: i18n.t('activation'),
      minWidth: 100,
      renderCell: ({ row, value }) => {
        const isActive = value === ACTIVE_STATUS.ACTIVE;
        return (
          <Switch
            defaultChecked={isActive}
            onChange={(checked) => handlePriceStatus(checked, row)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(searchClientTest(appliedFilters));
  }, [appliedFilters]);

  return (
    <>
      <SectionTitle
        titleKey={
          isPathIncludes('clinics')
            ? 'clinic_to_lab_pricing'
            : 'collection_center_to_lab_pricing'
        }
        editKey="sync_tests"
        onEdit={syncClinicTests}
        showEdit
      />
      <ListFilters Wrapped={ClientTestFilters} onChange={handleFilterChange} />
      <DataTable
        columns={columns}
        rows={tests}
        loading={fetching}
        paginationMode="client"
      />
      <PriceModal
        titleKey="test_price"
        showModal={showPriceModal}
        initialPrice={selectedTest?.amount}
        onClose={handlePriceUpdate}
      />
    </>
  );
};

export default ClientTests;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import {range} from 'lodash';

import i18n from 'translation/i18n';
import {getAuthUser} from 'redux/auth/actionCreators';
import {GENDER} from 'models/Enums';
import {BOOLEAN_OPTIONS} from 'utils/options';
import {getListForSelect, isPatient} from 'utils/labUtils';
import {
  composeValidators,
  email,
  enNumber,
  maxLength,
  minLength,
  required,
  shouldStartWith,
} from 'utils/FormValidations';
import {valueGetter} from 'utils/uiHelpers';

import {Spacer} from 'atoms';
import {SelectField, TextInputField,} from 'components/global/final-form';

const Step1 = ({isExist}) => {
  const [isFemale, setIsFemale] = useState(false);
  const [isPregnant, setIsPregnant] = useState(false);
  const {genders, patient_detail} = useSelector(state => state.patient);
  const {change} = useForm();
  const {values} = useFormState();
  const pregnancyWeeks = range(1, 41).map(n => ({label: n, value: n}));

  const handleGenderSelect = (gender) => {
    setIsFemale(gender === GENDER.FEMALE);
  };

  useEffect(() => {
    if (isExist && !values.initialized) {
      const details = {
        name: patient_detail.name,
        gender: patient_detail.gender,
        is_pregnant: patient_detail.is_pregnant,
        pregnancy_week: patient_detail.pregnancy_week,
        mobile: patient_detail.user.mobile,
        email: patient_detail.user.email,
        nationality_id: patient_detail.nationality.id,
        directorate_id: patient_detail.directorate.id,
        isolotion_place_id: patient_detail.isolotion_place.id,
        date_of_birth: patient_detail.date_of_birth,
        passport_number: patient_detail.passport_number,
        id_type: patient_detail.id_type,
        personal_id: patient_detail.personal_id,
        occupation: patient_detail.occupation,
      };
      change('patient', details);
      change('initialized', true);
    }
  }, []);

  useEffect(() => {
    if (isPatient()) {
      const userInfo = getAuthUser();
      change('patient.mobile', userInfo.mobile);
      change('patient.email', userInfo.email);
    }
    setIsPregnant(valueGetter(values, 'patient.is_pregnant') || false);
  }, []);

  return (
    <>
      <TextInputField
        name="patient.name"
        label={i18n.t('patient_name')}
        placeholder={i18n.t('name')}
        validate={required}
      />
      <Spacer height={12}/>
      <SelectField
        name="patient.gender"
        label={i18n.t('gender')}
        placeholder={i18n.t('choose_gender')}
        options={getListForSelect(genders)}
        validate={required}
        onChange={handleGenderSelect}
      />
      {isFemale && (
        <>
          <Spacer height={12}/>
          <SelectField
            name="patient.is_pregnant"
            label={i18n.t('is_pregnant')}
            placeholder={i18n.t('choose')}
            options={BOOLEAN_OPTIONS}
            onChange={(value) => setIsPregnant(value)}
          />
        </>
      )}
      {isPregnant && (
        <>
          <Spacer height={12}/>
          <SelectField
            name="patient.pregnancy_week"
            label={i18n.t('pregnancy_week')}
            placeholder={i18n.t('choose')}
            options={pregnancyWeeks}
          />
        </>
      )}
      {!isPatient() && (
        <>
          <Spacer height={12}/>
          <TextInputField
            name="patient.mobile"
            label={i18n.t('phone_number')}
            placeholder={i18n.t('phone')}
            maxLength={9}
            validate={
              composeValidators(
                required, enNumber, shouldStartWith(5), maxLength(9), minLength(9)
              )
            }
            disabled={isExist}
          />
          <Spacer height={12}/>
          <TextInputField
            name="patient.email"
            label={i18n.t('email_address')}
            placeholder={i18n.t('email')}
            validate={email}
            disabled={isExist}
          />
        </>
      )}
    </>
  );
};

Step1.propTypes = {
  isExist: PropTypes.bool
};

export default Step1;
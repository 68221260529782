import React from 'react';

import i18n from 'translation/i18n';

import {CardContainer} from 'templates';
import {Spacer} from 'atoms';
import ClientStats from './ClientStats';
import ClientSettings from './ClientSettings';

const ClientSummary = () => {
  return (
    <CardContainer title={i18n.t('summary')} noPadding>
      <ClientStats/>
      <Spacer height={1} width="100%" style={{backgroundColor: 'var(--brand-divider-white)'}}/>
      <ClientSettings/>
    </CardContainer>
  );
};

export default ClientSummary;
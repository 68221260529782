export const GET_ADMIN_SERVICE_PROVIDERS = 'GET_ADMIN_SERVICE_PROVIDERS';
export const GET_ADMIN_SERVICE_PROVIDERS_PENDING =
  'GET_ADMIN_SERVICE_PROVIDERS_PENDING';
export const GET_ADMIN_SERVICE_PROVIDERS_FULFILLED =
  'GET_ADMIN_SERVICE_PROVIDERS_FULFILLED';
export const GET_ADMIN_SERVICE_PROVIDERS_REJECTED =
  'GET_ADMIN_SERVICE_PROVIDERS_REJECTED';

export const GET_SPECIMEN_TYPES = 'GET_SPECIMEN_TYPES';
export const GET_SPECIMEN_TYPES_PENDING =
  'GET_SPECIMEN_TYPES_PENDING';
export const GET_SPECIMEN_TYPES_FULFILLED =
  'GET_SPECIMEN_TYPES_FULFILLED';
export const GET_SPECIMEN_TYPES_REJECTED =
  'GET_SPECIMEN_TYPES_REJECTED';

export const UPDATE_SPECIMEN_TYPE = 'UPDATE_SPECIMEN_TYPE';
export const UPDATE_SPECIMEN_TYPE_PENDING =
  'UPDATE_SPECIMEN_TYPE_PENDING';
export const UPDATE_SPECIMEN_TYPE_FULFILLED =
  'UPDATE_SPECIMEN_TYPE_FULFILLED';
export const UPDATE_SPECIMEN_TYPE_REJECTED =
  'UPDATE_SPECIMEN_TYPE_REJECTED';

export const DELETE_SPECIMEN_TYPE = 'DELETE_SPECIMEN_TYPE';
export const DELETE_SPECIMEN_TYPE_PENDING =
  'DELETE_SPECIMEN_TYPE_PENDING';
export const DELETE_SPECIMEN_TYPE_FULFILLED =
  'DELETE_SPECIMEN_TYPE_FULFILLED';
export const DELETE_SPECIMEN_TYPE_REJECTED =
  'UPDATE_SPECIMEN_TYPE_REJECTED';

export const GET_SUBSCRIPTIONS_OPTIONS = 'GET_SUBSCRIPTIONS_OPTIONS';
export const GET_SUBSCRIPTIONS_OPTIONS_PENDING =
  'GET_SUBSCRIPTIONS_OPTIONS_PENDING';
export const GET_SUBSCRIPTIONS_OPTIONS_FULFILLED =
  'GET_SUBSCRIPTIONS_OPTIONS_FULFILLED';
export const GET_SUBSCRIPTIONS_OPTIONS_REJECTED =
  'GET_SUBSCRIPTIONS_OPTIONS_REJECTED';

export const UPDATE_ADMIN_SERVICE_PROVIDERS = 'UPDATE_ADMIN_SERVICE_PROVIDERS';
export const UPDATE_ADMIN_SERVICE_PROVIDERS_PENDING =
  'UPDATE_ADMIN_SERVICE_PROVIDERS_PENDING';
export const UPDATE_ADMIN_SERVICE_PROVIDERS_FULFILLED =
  'UPDATE_ADMIN_SERVICE_PROVIDERS_FULFILLED';
export const UPDATE_ADMIN_SERVICE_PROVIDERS_REJECTED =
  'UPDATE_ADMIN_SERVICE_PROVIDERS_REJECTED';

export const GET_ADMIN_SERVICE_PROVIDER_INFO =
  'GET_ADMIN_SERVICE_PROVIDER_INFO';
export const GET_ADMIN_SERVICE_PROVIDER_INFO_PENDING =
  'GET_ADMIN_SERVICE_PROVIDER_INFO_PENDING';
export const GET_ADMIN_SERVICE_PROVIDER_INFO_FULFILLED =
  'GET_ADMIN_SERVICE_PROVIDER_INFO_FULFILLED';
export const GET_ADMIN_SERVICE_PROVIDER_INFO_REJECTED =
  'GET_ADMIN_SERVICE_PROVIDER_INFO_REJECTED';

export const CREATE_SERVICE_PROVIDER_SUBSCRIPTION =
  'CREATE_SERVICE_PROVIDER_SUBSCRIPTION';
export const CREATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING =
  'CREATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING';
export const CREATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED =
  'CREATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED';
export const CREATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED =
  'CREATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED';

export const SHOW_PLAN = 'SHOW_PLAN';
export const SHOW_PLAN_PENDING = 'SHOW_PLAN_PENDING';
export const SHOW_PLAN_FULFILLED = 'SHOW_PLAN_FULFILLED';
export const SHOW_PLAN_REJECTED = 'SHOW_PLAN_REJECTED';

export const CREATE_PLAN = 'CREATE_PLAN';
export const CREATE_PLAN_PENDING = 'CREATE_PLAN_PENDING';
export const CREATE_PLAN_FULFILLED = 'CREATE_PLAN_FULFILLED';
export const CREATE_PLAN_REJECTED = 'CREATE_PLAN_REJECTED';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_PENDING = 'UPDATE_PLAN_PENDING';
export const UPDATE_PLAN_FULFILLED = 'UPDATE_PLAN_FULFILLED';
export const UPDATE_PLAN_REJECTED = 'UPDATE_PLAN_REJECTED';

export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_PLANS_FULFILLED = 'GET_PLANS_FULFILLED';
export const GET_PLANS_REJECTED = 'GET_PLANS_REJECTED';

export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED =
  'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED';

export const GET_LAB_SUBSCRIPTIONS = 'GET_LAB_SUBSCRIPTIONS';
export const GET_LAB_SUBSCRIPTIONS_PENDING = 'GET_LAB_SUBSCRIPTIONS_PENDING';
export const GET_LAB_SUBSCRIPTIONS_FULFILLED =
  'GET_LAB_SUBSCRIPTIONS_FULFILLED';
export const GET_LAB_SUBSCRIPTIONS_REJECTED = 'GET_LAB_SUBSCRIPTIONS_REJECTED';

export const GET_LAB_SUBSCRIPTION_INVOICES = 'GET_LAB_SUBSCRIPTION_INVOICES';
export const GET_LAB_SUBSCRIPTION_INVOICES_PENDING =
  'GET_LAB_SUBSCRIPTION_INVOICES_PENDING';
export const GET_LAB_SUBSCRIPTION_INVOICES_FULFILLED =
  'GET_LAB_SUBSCRIPTION_INVOICES_FULFILLED';
export const GET_LAB_SUBSCRIPTION_INVOICES_REJECTED =
  'GET_LAB_SUBSCRIPTION_INVOICES_REJECTED';

export const REFUND_LAB_SUBSCRIPTION_INVOICE =
  'REFUND_LAB_SUBSCRIPTION_INVOICE';
export const REFUND_LAB_SUBSCRIPTION_INVOICE_PENDING =
  'REFUND_LAB_SUBSCRIPTION_INVOICE_PENDING';
export const REFUND_LAB_SUBSCRIPTION_INVOICE_FULFILLED =
  'REFUND_LAB_SUBSCRIPTION_INVOICE_FULFILLED';
export const REFUND_LAB_SUBSCRIPTION_INVOICE_REJECTED =
  'REFUND_LAB_SUBSCRIPTION_INVOICE_REJECTED';

export const PRINT_LAB_SUBSCRIPTION_INVOICE = 'PRINT_LAB_SUBSCRIPTION_INVOICE';
export const PRINT_LAB_SUBSCRIPTION_INVOICE_PENDING =
  'PRINT_LAB_SUBSCRIPTION_INVOICE_PENDING';
export const PRINT_LAB_SUBSCRIPTION_INVOICE_FULFILLED =
  'PRINT_LAB_SUBSCRIPTION_INVOICE_FULFILLED';
export const PRINT_LAB_SUBSCRIPTION_INVOICE_REJECTED =
  'PRINT_LAB_SUBSCRIPTION_INVOICE_REJECTED';

export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION =
  'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION';
export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING =
  'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING';
export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED =
  'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED';
export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED =
  'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED';

export const CREATE_TEST = 'CREATE_TEST';
export const CREATE_TEST_PENDING = 'CREATE_TEST_PENDING';
export const CREATE_TEST_FULFILLED = 'CREATE_TEST_FULFILLED';
export const CREATE_TEST_REJECTED = 'CREATE_TEST_REJECTED';

export const BULK_UPLOAD_TEST = 'BULK_UPLOAD_TEST';
export const BULK_UPLOAD_TEST_PENDING = 'BULK_UPLOAD_TEST_PENDING';
export const BULK_UPLOAD_TEST_FULFILLED = 'BULK_UPLOAD_TEST_FULFILLED';
export const BULK_UPLOAD_TEST_REJECTED = 'BULK_UPLOAD_TEST_REJECTED';

export const EDIT_TEST = 'EDIT_TEST';
export const EDIT_TEST_PENDING = 'EDIT_TEST_PENDING';
export const EDIT_TEST_FULFILLED = 'EDIT_TEST_FULFILLED';
export const EDIT_TEST_REJECTED = 'EDIT_TEST_REJECTED';

export const GET_ADMIN_SERVICE_OPTIONS = 'GET_ADMIN_SERVICE_OPTIONS';
export const GET_ADMIN_SERVICE_OPTIONS_PENDING =
  'GET_ADMIN_SERVICE_OPTIONS_PENDING';
export const GET_ADMIN_SERVICE_OPTIONS_FULFILLED =
  'GET_ADMIN_SERVICE_OPTIONS_FULFILLED';
export const GET_ADMIN_SERVICE_OPTIONS_REJECTED =
  'GET_ADMIN_SERVICE_OPTIONS_REJECTED';

import React, {useEffect, useState} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {flatMap} from 'lodash';
import {addYears} from 'date-fns';

import i18n from 'translation/i18n';
import {required} from 'utils/FormValidations';

import {ReactComponent as HospitalLabSelected} from 'assets/icons/reservations/hospital-lab-selected.svg';
import {ReactComponent as HospitalLab} from 'assets/icons/reservations/hospital-lab.svg';
import {ReactComponent as PatientHouseSelected} from 'assets/icons/reservations/patient-house-selected.svg';
import {ReactComponent as PatientHouse} from 'assets/icons/reservations/patient-house.svg';
import {RadioBox, Spacer, Text} from 'atoms';
import {DatePickerField} from 'components/global/final-form';
import {serviceShow} from 'redux/tests/actionCreators';

const Step5 = () => {
  const {change} = useForm();
  const {values} = useFormState();

  const [isAllTestHomeCollectable, setIsAllTestHomeCollectable] =
    useState(false);

  useEffect(() => {
    const orders = values.orders || [];
    const packages = values.orders_package || [];
    const packageServices = flatMap(packages, 'services');
    const services_ids = [...orders, ...packageServices].map((o) => o.service_id);
    const priority_ids = [...orders, ...packageServices].map((o) => o.priority_id);
    (async (ids = []) => {
      const result = await Promise.all(
        ids.map((id) => serviceShow(id).payload),
      );

      const homeCollectionIsEnabledInAllTests = result
        // api returns data in this shape so we have to
        // extract the test information with find by id
        .map((r) => {
          return r.data.services[0].types.find((t) =>
            priority_ids.includes(t.id),
          );
        })
        // make array of booleans
        .map((t) => {
          return t.home_collection_enabled === true;
        })
        // return true if all elements in the array is true,
        // return false if one element is false
        .reduce((previous, current) => {
          if (previous === true && current === true) return true;
          return false;
        }, true);

      setIsAllTestHomeCollectable(homeCollectionIsEnabledInAllTests);
    })(services_ids);
  }, [values]);

  const [collectionMethod, setCollectionMethod] =
    React.useState('lab_collection');

  const handleDateChange = (data) => {
    change('appointment.time', data);
  };

  React.useEffect(() => {
    change('appointment.collection_method', collectionMethod);
  }, [collectionMethod]);

  React.useEffect(() => {
    if (
      values.appointment?.collection_method &&
      values.appointment.collection_method === 'home_collection'
    ) {
      setCollectionMethod('home_collection');
    } else {
      setCollectionMethod('lab_collection');
      handleDateChange(null);
    }
  }, []);

  return (
    <>
      <Spacer height={8}/>
      <Text
        typography="paragraph14"
        color="var(--brand-text-gray-light)"
        weight="bold"
      >
        {i18n.t('collection_method').toUpperCase()}
      </Text>
      <Spacer height={6}/>

      <RadioBox
        name="appointment.collection_method"
        label={i18n.t('at_the_lab')}
        validate={required}
        variant="large"
        checked={collectionMethod === 'lab_collection'}
        startIcon={
          collectionMethod === 'lab_collection' ? (
            <HospitalLabSelected/>
          ) : (
            <HospitalLab/>
          )
        }
        onChange={() => setCollectionMethod('lab_collection')}
      />
      <Spacer height={20}/>
      {isAllTestHomeCollectable && (
        <>
          <RadioBox
            name="appointment.collection_method"
            label={i18n.t('home')}
            variant="large"
            validate={required}
            checked={collectionMethod === 'home_collection'}
            startIcon={
              collectionMethod === 'home_collection' ? (
                <PatientHouseSelected/>
              ) : (
                <PatientHouse/>
              )
            }
            onChange={() => setCollectionMethod('home_collection')}
          />
        </>
      )}
      {collectionMethod === 'home_collection' && (
        <>
          <Spacer height={32}/>
          <Text
            typography="paragraph14"
            color="var(--brand-text-gray-light)"
            weight="bold"
          >
            {i18n.t('pick_a_date_and_time').toUpperCase()}
          </Text>
          <Spacer height={6}/>
          <DatePickerField
            name="appointment.time"
            variant="large"
            onChange={handleDateChange}
            validate={required}
            displayFormat="dd / MM / yyyy  hh:mm a"
            dateFormat="dd / MM / yyyy  hh:mm a"
            showTimeSelect
            minDate={new Date()}
            maxDate={addYears(new Date(), 2)}
          />
        </>
      )}
      <Spacer height={140}/>
    </>
  );
};

export default Step5;

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Button, Modal, Text, TextInput} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';

const ReasonModal = ({showModal, status, onClose}) => {
  const [reason, setReason] = useState('');

  const handleClose = (value) => {
    onClose(value);
    setReason('');
  };

  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={260}
        header={<ModalHeader title={i18n.t(`reason_for_${status}`)}/>}
        onClose={handleClose}
      >
        <ModalContent padding="0 1rem">
          <Text typography="caption12" color="var(--brand-text-gray-light)">
            {i18n.t('reason').toUpperCase()}
          </Text>
          <TextInput
            value={reason}
            as="textarea"
            rows={8}
            style={{margin: '0.5rem 0 1rem'}}
            placeholder={i18n.t('reason_placeholder')}
            onChange={(e) => setReason(e.target.value)}
          />
          <Button
            variant="secondary"
            label={i18n.t('submit')}
            onClick={() => handleClose(reason)}
          />
        </ModalContent>
      </Modal>
    )
  );
};

ReasonModal.propTypes = {
  showModal: PropTypes.bool,
  status: PropTypes.string,
  onClose: PropTypes.func
};

export default ReasonModal;

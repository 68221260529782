import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {downloadCSV} from 'utils/labUtils';
import {downloadReport} from 'redux/report/actionCreators';

import {Button} from 'atoms';

const DownloadReport = ({type, filters}) => {
  const dispatch = useDispatch();
  const {fetching, downloading} = useSelector(state => state.report);

  const handlePrintInvoice = () => {
    dispatch(downloadReport(type, filters))
      .then(resp => resp.value.data)
      .then(blob => downloadCSV(blob, type));
  };

  return (
    <Button
      fitContent
      label={i18n.t(downloading ? 'downloading' : 'download_csv')}
      onClick={handlePrintInvoice}
      disabled={fetching || downloading}
    />
  );
};

DownloadReport.propTypes = {
  filters: PropTypes.shape(),
  type: PropTypes.string
};

export default DownloadReport;
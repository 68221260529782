import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';

import {DataTable} from 'atoms';
import {useSelector} from 'react-redux';

const ReportTable = ({ onPagination }) => {
  const {reports, fetching, pagination} = useSelector(state => state.report);

  const columns = [
    { field: 'test_category', headerName: i18n.t('test_category'), minWidth: 120, flex: 1 },
    { field: 'test_name', headerName: i18n.t('test_name'), minWidth: 120, flex: 1 },
    { field: 'test_priority', headerName: i18n.t('test_priority'), minWidth: 120, flex: 1 },
    { field: 'test_type', headerName: i18n.t('test_type'), minWidth: 120, flex: 1 },
    { field: 'result_status', headerName: i18n.t('result_status'), minWidth: 120, flex: 1 },
    { field: 'orders_count', headerName: i18n.t('orders_count'), minWidth: 120, flex: 1 }
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={reports}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

ReportTable.propTypes = {
  onPagination: PropTypes.func
};

export default ReportTable;
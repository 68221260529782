import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {Form} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {Modal, Spacer} from 'atoms';
import {ModalHeader, ModalContent} from 'templates';
import {resendCode, getAuthUser} from 'redux/auth/actionCreators';
import {
  changeEmail,
  changeMobiles,
  verifyChangeMobiles,
  changePassword,
} from 'redux/profile/actionCreators';
import {getCountDownsec} from 'utils/loginFlow';
import {getOtpFromValues} from  'utils/labUtils';
import {USER_ROLE} from 'models';
import ChangeNumberFields from './ChangeNumberFields';
import ChangePasswordFields from './ChangePasswordFields';
import VerifyContentFields from './VerifyContentFields';
import ChangeEmailFields from './ChangeEmailFields';

const ChangeModal = (props) => {
  const dispatch = useDispatch();
  const {data, show, onDone} = props;
  const [contentData, setContentData] = React.useState(data);
  // Verify States
  const [countDown, setCountDown] = React.useState('00:59');
  const [activeCount, setActiveCount] = React.useState(false);

  const handleResend = (value) => {
    dispatch(resendCode(value));
    setActiveCount(true);
    setCountDown('00:59');
  };

  const formContent = {
    mobile_number: <ChangeNumberFields/>,
    email: <ChangeEmailFields/>,
    password: <ChangePasswordFields/>,
    verify: (
      <VerifyContentFields 
        activeCount={activeCount}
        countDown={countDown}
        onResendClick={(value) => handleResend(value)}
      />
    ),
  };
  
  const onChangeContentData = (newData) => {
    setContentData({...contentData, ...newData});
  };

  const onSubmit = (values) => {
    switch (contentData.key) {
    case 'mobile_number': {
      const mobiles = {...values, old_mobile: data.initialValues.mobile};

      return dispatch(changeMobiles(mobiles))
        .then(() => onChangeContentData({
          title: i18n.t('verify'),
          subTitle: `${i18n.t('enter_the_code_sent_to')} ${mobiles.new_mobile}`,
          key: 'verify',
        })); 
    }

    case 'verify': {
      const otp = values.otp ? {otp: values.otp} : {otp: getOtpFromValues(values)};
      return dispatch(verifyChangeMobiles(otp))
        .then(() => onDone()); 
    }

    case 'email': {
      const emails = {...values, old_email: data.initialValues.email};
      return dispatch(changeEmail(emails))
        .then(() => onDone());
    }

    case 'password': {
      let passwords = {...values};
      const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;

      if(isAdmin){
        passwords = {...passwords, new_password: passwords.password};
        delete passwords.password;
        delete passwords.password_confirmation;
      }
      return dispatch(changePassword(passwords))
        .then(() => onDone());
    }
    default:
      return null;
    }
  };

  React.useEffect(() => {
    const secs = getCountDownsec(countDown);
    if (activeCount && secs > 0) {
      setTimeout(() => setCountDown(`00:${secs - 1}`), 1000);
    } else {
      setActiveCount(false);
    }
  }, [countDown, activeCount]);

  return (
    show && (
      <Modal
        isOpen={show}
        onClose={onDone}
        width={380}
      >
        <Spacer height={48}/>
        <ModalHeader  
          title={contentData.title || 'title'}
          subTitle={contentData.subTitle || 'subTitle'}
          titleVariant="headline"
          subtitleVariant="title"
          center
        />
        <Spacer height={16}/>
        <Form onSubmit={onSubmit}>
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <ModalContent padding="32px">
                {formContent[contentData.key]}
              </ModalContent>
            </form>
          )}
        </Form>
      </Modal>
    ));
};

ChangeModal.propTypes = {
  data: PropTypes.shape(),
  onDone: PropTypes.func,
  show: PropTypes.bool,
};

export default ChangeModal;

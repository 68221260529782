import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { labClientRegister, getSlug } from 'redux/auth/actionCreators';
import { getClients } from 'redux/lab/actionCreators';

import { ReactComponent as AddIcon } from 'assets/icons/common/add-square.svg';
import { Button, Modal, Flex } from 'atoms';
import { ModalHeader, ModalContent } from 'templates';
import CreateClient1 from 'container/public/Shared/LabClients/CreateClientSteps/CreateClient1';
import CreateClient2 from 'container/public/Shared/LabClients/CreateClientSteps/CreateClient2';
import { isPathIncludes } from 'utils/uiHelpers';
import { isMobile } from 'react-device-detect';

const CurrentStep = ({ activeStep, onBack, clientLabel }) => {
  const step = {
    1: <CreateClient1 />,
    2: <CreateClient2 onBack={onBack} clientLabel={clientLabel} />,
  };

  return step[activeStep];
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number,
  onBack: PropTypes.func,
  clientLabel: PropTypes.string,
};

const NewClient = ({ client }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState();
  const [activeStep, setActiveStep] = React.useState(1);

  const newButtonLabels = {
    true: 'new_clinic',
    false: 'new_collection_center',
  };

  const headerTitles = {
    true: 'create_clinic',
    false: 'create_collection_center',
  };

  const handleFormSubmit = (data) => {
    if (activeStep === 1) {
      return setActiveStep(activeStep + 1);
    }
    const payload = {
      ...data,
      service_provider_association: {
        slug: getSlug(),
      },
    };
    return dispatch(labClientRegister(payload)).then(() => {
      dispatch(getClients(client));
      setShowModal(false);
    });
  };

  return (
    <Flex justifyEnd>
      <Button
        label={i18n.t(
          isMobile ? 'add_new' : newButtonLabels[isPathIncludes('clinics')],
        )}
        startIcon={<AddIcon style={{ minWidth: '16px' }} />}
        onClick={() => setShowModal(true)}
        fitContent
      />
      <Modal
        header={
          <ModalHeader
            title={i18n.t(headerTitles[isPathIncludes('clinics')])}
            onBack={
              activeStep === 1 ? null : () => setActiveStep(activeStep - 1)
            }
          />
        }
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        width={350}
      >
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <CurrentStep
                  activeStep={activeStep}
                  onBack={() => setActiveStep(activeStep - 1)}
                  clientLabel={
                    isPathIncludes('clinics') ? 'clinic' : 'collection_center'
                  }
                />
              </ModalContent>
            </form>
          )}
        </Form>
      </Modal>
    </Flex>
  );
};

NewClient.propTypes = {
  client: PropTypes.string,
};

export default NewClient;

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {isBrowser, isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {USER_STATUSES} from 'utils/options';

import {Grid} from '@mui/material';
import {Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';
import {CreateUser} from './NewUser/CreateUser';

export const UserFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const [roles, setRoles] = useState([]);
  const {userRoles} = useSelector(state => state.lab);

  useEffect(() => {
    const mappedRoles = userRoles.map(role => ({value: role, label: i18n.t(role)}));
    setRoles(mappedRoles);
  }, [userRoles]);

  const filterConfigs = [
    {key: 'status', options: USER_STATUSES, placeholderKey: 'status'},
    {key: 'role', options: roles, placeholderKey: 'role'},
  ];

  return (
    <Grid container spacing={isMobile ? 2 : 4} py={2} px={isMobile ? 2 : 3} alignItems="center">
      <Grid item xs={8} lg={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('user_list_title')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('user_list_subtitle')}
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={4}>
          <CreateUser/>
        </Grid>
      )}
      <Grid item xs={9} lg={4}>
        <SearchInput
          placeholder={i18n.t('user_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && <Grid item lg={2}/>}
      <Grid item xs={3} lg={1}>
        <SelectFilter
          selectFilterConfigs={filterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
      {isBrowser && (
        <Grid item lg={2}>
          <CreateUser/>
        </Grid>
      )}
    </Grid>
  );
};

UserFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

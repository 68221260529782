import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNaN } from 'lodash';

import i18n from 'translation/i18n';
import { ACTIVE_STATUS } from 'models';
import { valueGetter } from 'utils/uiHelpers';
import { formattedPrice, userModule } from 'utils/labUtils';
import { testPriorityUpdate } from 'redux/tests/actionCreators';

import { DataTable, Switch, Text, Flex, Spacer } from 'atoms';
import { SectionTitle } from 'templates';
import PriceModal from 'components/labClients/PriceModal';
import TATModal from 'components/labClients/TATModal';
import { ReactComponent as EditIcon } from 'assets/icons/common/edit.svg';

const TestPrioritiesTable = () => {
  const dispatch = useDispatch();
  const { service_show, fetching } = useSelector(
    (state) => state.tests,
  );

  const module = userModule();
  const isAdmin = module === 'admin';

  const priorities = valueGetter(service_show, 'types') || [];

  const [selectedTest, setSelectedTest] = useState(null);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showTATModal, setShowTATModal] = useState(false);

  const onPriceEdit = (value) => {
    setSelectedTest(value);
    setShowPriceModal(true);
  };

  const onTATEdit = (value) => {
    setSelectedTest(value);
    setShowTATModal(true);
  };

  const updateTest = async (values) => {
    if (isAdmin) {
      dispatch(
        testPriorityUpdate(values.id, {
          service_priority: {
            price: values.amount,
            status: values.activate ? 'active' : 'inactive',
            home_collection_enabled: values.home_collection_enabled,
          },
        }),
      );
    } else {
      dispatch(
        testPriorityUpdate(service_show.id, {
          service: {
            priority: values.priority,
            price: values.amount,
            activate: values.activate,
            home_collection_enabled: values.home_collection_enabled,
            turn_around_time: values.turn_around_time,
          },
        }),
      );
    }
    setSelectedTest(null);
    setShowPriceModal(false);
    setShowTATModal(false);
  };

  const handlePriceUpdate = (amount) => {
    if (amount) {
      const t = priorities.find((test) => test.id === selectedTest.id);
      t.amount = amount;
      updateTest({ ...t, amount });
    } else {
      setShowPriceModal(false);
    }
  };

  const handleTATUpdate = (time) => {
    if (time) {
      const t = priorities.find((test) => test.id === selectedTest.id);
      t.turn_around_time = time;
      updateTest({ ...t, turn_around_time: time });
    } else {
      setShowPriceModal(false);
    }
  };

  const handlePriceStatus = (isChecked, row) => {
    const values = priorities.find((t) => t.id === row.id);
    updateTest({ ...values, activate: isChecked });
  };
  const handleUpdateHomeCollection = (isChecked, row) => {
    const values = priorities.find((t) => t.id === row.id);
    updateTest({ ...values, home_collection_enabled: isChecked });
  };

  const columns = useMemo(() => {
    if (isAdmin) {
      return [
        {
          field: 'name',
          headerName: i18n.t('test_priority'),
          minWidth: 120,
          flex: 1,
          renderCell: ({ value }) => i18n.t(value),
        },
        {
          field: 'status',
          headerName: i18n.t('activation'),
          minWidth: 100,
          renderCell: ({ row, value }) => {
            const isActive = value === ACTIVE_STATUS.ACTIVE;
            return (
              <Switch
                defaultChecked={isActive}
                onChange={(checked) => handlePriceStatus(checked, row)}
              />
            );
          },
        },
      ];
    }
    return [
      {
        field: 'priority',
        headerName: i18n.t('test_priority'),
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'turn_around_time',
        headerName: i18n.t('tat_full'),
        minWidth: 100,
        flex: 1,
        renderCell: ({ row, value }) => {
          const duration = isNaN(value) ? {} : {
            hours: Math.floor(value / 60),
            minutes: Math.floor(value % 60)
          };

          return (
            <Flex itemsCenter>
              <Text typography="caption13" weight="bold">
                {duration.hours ? `${duration.hours} hours ` : ''}
                {duration.minutes ? `${duration.minutes} minutes` : ''}
              </Text>
              <Spacer width={4} />
              <EditIcon
                height={16}
                onClick={() => onTATEdit(row)}
                style={{ cursor: 'pointer' }}
              />
            </Flex>
          );
        }
      },
      {
        field: 'home_collection_enabled',
        headerName: i18n.t('home'),
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row, field }) => valueGetter(row, field),
        renderCell: ({ row, value }) => (
          <Switch
            defaultChecked={value}
            onChange={(enabled) => handleUpdateHomeCollection(enabled, row)}
          />
        ),
      },
      {
        field: 'amount',
        headerName: i18n.t('test_price'),
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row, field }) => valueGetter(row, field),
        renderCell: ({ row, value }) => (
          <Flex itemsCenter>
            <Text typography="caption13" weight="bold">
              {formattedPrice(value, 'saudi_riyal')}
            </Text>
            <Spacer width={4} />
            <EditIcon
              height={16}
              onClick={() => onPriceEdit(row)}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
        ),
      },
      {
        field: 'status',
        headerName: i18n.t('activation'),
        minWidth: 100,
        renderCell: ({ row, value }) => {
          const isActive = value === ACTIVE_STATUS.ACTIVE;
          return (
            <Switch
              defaultChecked={isActive}
              onChange={(checked) => handlePriceStatus(checked, row)}
            />
          );
        },
      },
    ];
  });

  return (
    <>
      <SectionTitle titleKey={i18n.t('priorities')} />
      <DataTable
        columns={columns}
        rows={priorities || []}
        loading={fetching}
        paginationMode="client"
        height={319}
      />
      <PriceModal
        titleKey="test_price"
        showModal={showPriceModal}
        initialPrice={valueGetter(selectedTest, 'amount')}
        onClose={handlePriceUpdate}
      />
      <TATModal
        showModal={showTATModal}
        initialTAT={valueGetter(selectedTest, 'turn_around_time')}
        onClose={handleTATUpdate}
      />
    </>
  );
};

export default TestPrioritiesTable;

/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { chain } from 'lodash';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { formattedPrice } from 'utils/labUtils';
import { OUTSOURCING, PAYMENT_MODE } from 'models';
import { verifyPayment } from 'redux/billing/actionCreators';
import { ReactComponent as DiscountIcon } from 'assets/icons/reservations/discount.svg';
import { Divider, Spacer, Button, Text, Flex } from 'atoms';
import { ModalContent, CardContainer, InlineInfo, InfoCard } from 'templates';
import PaymentSelection from 'templates/PaymentSelection';
import { Box } from '@mui/material';
import { useURLQuery } from 'hooks/useURLQuery';
import DiscountModal from './DiscountModal';

const BillingSummary = ({ filter, testIds, refetch }) => {
  const dispatch = useDispatch();
  const { summary, batches, billings } = useSelector((state) => state.billing);
  const [discount, setDiscount] = useState(0);
  const [paymentMode, setPaymentMode] = useState(PAYMENT_MODE.CASH);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const { query } = useURLQuery();

  const billsByPriority = chain(billings).filter((bill) => testIds.includes(bill.id)).groupBy('priority').value();
  const billTotal = chain(billings)
    .filter((bill) => testIds.includes(bill.id))
    .reduce((acc, bill) => bill.total_payment + acc, 0)
    .value();

  const handleDiscountUpdate = (value) => {
    if (value) {
      // eslint-disable-next-line radix
      setDiscount(parseInt(value));
    }
    setShowDiscountModal(false);
  };

  const handleVerifyPayment = () => {
    const payload = {
      number_of_samples: summary.number_of_samples,
      patient_name: summary.patient_name,
      sample_cost: summary.sample_cost,
      tax_amount: summary.tax_amount,
      tax_rate: summary.tax_rate,
      taxable_amount: summary.taxable_amount,
      total_cost: summary.total_cost,
      clinic_id: filter.clinic_id,
      outsourcing_type: filter.outsourcing,
      payment_mode: paymentMode,
      discount,
      order_ids: testIds,
      reservation_id: query?.search,
    };
    if (filter.outsourcing === OUTSOURCING.BATCH) {
      payload.batch_id = valueGetter(batches, '0.id');
    }
    dispatch(verifyPayment(payload)).then(refetch);
  };

  return (
    <>
      <CardContainer
        noPadding
        style={{ width: '100%' }}
        title={i18n.t('summary')}
      >
        <ModalContent>
          <InlineInfo
            label={i18n.t('patient')}
            value={valueGetter(summary, 'patient_name')}
          />
          <InlineInfo label={i18n.t('no_of_samples')} value=" " />
          <Box ml={4}>
            {Object.keys(billsByPriority).map((priority) => (
              <InlineInfo
                key={priority}
                label={i18n.t(priority)}
                value={billsByPriority[priority].length}
              />
            ))}
          </Box>
          <InlineInfo label={i18n.t('sample_cost')} value="" />
          <Box ml={4}>
            {Object.keys(billsByPriority).map((priority) => {
              const value = billsByPriority[priority].reduce((a, b) => b.total_payment + a, 0);
              return (
                <InlineInfo
                  key={priority}
                  label={i18n.t(priority)}
                  value={formattedPrice(value, 'saudi_riyal')}
                />
              );
            })}
          </Box>
          <Spacer height={12} />
          <Divider />
          <Spacer height={10} />
          <InlineInfo
            labelVariant="paragraph14"
            valueVariant="paragraph14"
            labelWeight="bold"
            label={i18n.t('total')}
            value={formattedPrice(billTotal, 'saudi_riyal')}
          />
          <Spacer height={32} />
          <Text typography="paragraph14" weight="bold">
            {i18n.t('discount_for_clinic')}
          </Text>
          <InfoCard isCustom>
            <Flex justifyBetween itemsCenter>
              <Flex itemsCenter>
                <DiscountIcon />
                <Spacer width={11} />
                <Text
                  typography="paragraph14"
                  color="var(--brand-text-black)"
                >
                  {discount} {i18n.t('saudi_riyal')}
                </Text>
              </Flex>
              <Text
                typography="caption13"
                color="var(--brand-text-primary)"
                weight="bold"
                onClick={() => setShowDiscountModal(true)}
              >
                {i18n.t('set_discount')}
              </Text>
            </Flex>
          </InfoCard>
          <Spacer height={24} />
          <PaymentSelection
            title={i18n.t('payment_method')}
            defaultMode={paymentMode}
            methods={[
              PAYMENT_MODE.CASH,
              PAYMENT_MODE.CARD,
              PAYMENT_MODE.MADA,
            ]}
            onChange={(value) => setPaymentMode(value)}
          />
          <Spacer height={32} />
          <Button
            label={i18n.t('verify_payment')}
            onClick={handleVerifyPayment}
            disabled={testIds.length === 0}
          />
        </ModalContent>
      </CardContainer>
      {showDiscountModal && (
        <DiscountModal
          initialDiscount={discount}
          onClose={handleDiscountUpdate}
        />
      )}
    </>
  );
};

BillingSummary.propTypes = {
  filter: PropTypes.shape(),
  testIds: PropTypes.arrayOf(PropTypes.string),
  refetch: PropTypes.func,
};

export default BillingSummary;

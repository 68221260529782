import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Text, Flex} from 'atoms';
import styles from './templates.module.css';

const SubTabs = ({
  onTabClick,
  tabs,
  style,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);

  const handleTabClick = (data) => {
    setSelectedTab(data);
    onTabClick(data);
  };

  return (
    <Flex>
      {tabs.map((data, ind) => {
        return  (
          <Text
            key={ind} 
            typography="paragraph14"
            className={clsx(
              styles.tabItem, 
              data.value === selectedTab.value ? styles.selectedTab : undefined
            )} 
            onClick={() => handleTabClick(data)}
            style={style}
          >
            {data.label}
          </Text>
        );
      })}
    </Flex>
  );
};

SubTabs.propTypes = {
  onTabClick: PropTypes.func,
  tabs: PropTypes.arrayOf(Object),
  style: PropTypes.shape(),
};

export default SubTabs;

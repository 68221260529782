import React from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import { getMasterOrders } from 'redux/orders/actionCreators';
import { ListFilters } from 'components/hoc';
import { Card } from 'atoms';
import { dateToQueryFormat, dateQueryFormatToDate } from 'utils/date';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import { MasterOrderFilters } from './masterOrders/MasterOrderFilters';
import { MasterOrderTable } from './masterOrders/MasterOrderTable';

export const MasterOrderList = ({ module }) => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const loadMasterOrders = (params = {}) => {
    dispatch(getMasterOrders({ ...params }));
  };

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      result: filter.result,
      status: filter.status,
      priority: filter.priority,
      from_date: filter.from_date ? dateToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateToQueryFormat(filter.to_date) : null,
    };

    setQuery(params);
    loadMasterOrders(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: loadMasterOrders,
  });

  React.useEffect(() => {
    const params = {
      search: query?.search,
      result: query?.result,
      status: query?.status,
      priority: query?.priority,
      from_date: query?.from_date
        ? dateQueryFormatToDate(query?.from_date)
        : null,
      to_date: query?.to_date ? dateQueryFormatToDate(query?.to_date) : null,
    };
    handleFilterChange(params);
  }, [module]);

  const content = (
    <>
      <ListFilters Wrapped={MasterOrderFilters} onChange={handleFilterChange} />
      <MasterOrderTable onPagination={handlePagination} />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

MasterOrderList.propTypes = {
  module: PropTypes.string,
};

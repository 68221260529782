import PropTypes from 'prop-types';
import React from 'react';

import i18n from 'translation/i18n';

import { Button, LinkButton, Modal, Text } from 'atoms';
import { isMobile } from 'react-device-detect';
import ModalHeader from './ModalHeader';
import './styles.scss';

const SectionTitle = ({
  titleKey,
  showEdit,
  editKey,
  onEdit,
  showDelete,
  onDelete,
  deleteKey,
}) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <div className="section-title">
      <Text typography="caption12" weight="bold" textCase="uppercase">
        {i18n.t(titleKey)}
      </Text>
      <div style={{ display: 'flex', gap: '16px' }}>
        {showEdit && <LinkButton label={i18n.t(editKey)} onClick={onEdit} />}
        {showDelete && (
          <>
            <LinkButton
              style={{ color: 'red' }}
              label={i18n.t(deleteKey)}
              onClick={() => setOpenModal(true)}
            />
            <Modal
              header={<ModalHeader title={i18n.t('package_delete')} />}
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              width={isMobile ? 350 : '90%'}
              maxWidth={600}
              height={200}
            >
              <div style={{ textAlign: 'center' }}>
                <p>{i18n.t('package_delete_are_you_sure')}</p>
                <div
                  style={{
                    display: 'flex',
                    maxWidth: '300px',
                    margin: '0 auto',
                  }}
                >
                  <div style={{ maxWidth: '200px', margin: '0 auto' }}>
                    <Button onClick={onDelete} label={i18n.t('confirm')} />
                  </div>
                  <div style={{ maxWidth: '200px', margin: '0 auto' }}>
                    <Button
                      variant="secondary"
                      onClick={() => setOpenModal(false)}
                      label={i18n.t('cancel')}
                    />
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

SectionTitle.defaultProps = {
  showEdit: false,
  editKey: 'edit_info',
};

SectionTitle.propTypes = {
  titleKey: PropTypes.string,
  showEdit: PropTypes.bool,
  editKey: PropTypes.string,
  onEdit: PropTypes.func,
  showDelete: PropTypes.bool,
  deleteKey: PropTypes.string,
  onDelete: PropTypes.func,
};

export default SectionTitle;

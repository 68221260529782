/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import PropTypes from 'prop-types';

import { valueGetter } from 'utils/uiHelpers';
import { testsLoad } from 'redux/tests/actionCreators';
import { ACTIVE_STATUS } from 'models';

import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const CurrentStep = ({ activeStep, handleStep0Submit }) => {
  const [isExist, setIsExist] = React.useState(false);
  const [testTypes, setTestTypes] = React.useState([]);

  const handleSubmit = (values) => {
    setIsExist(values === 'exist');
    handleStep0Submit(values);
  };

  const getTests = async (page) => {
    return testsLoad({ status: 'active', page }).payload;
  };

  const generateTestTypes = (services) => {
    const options = services.map(s => ({
      value: s.id,
      label: s.name,
      priorities: s.types
        .filter(t => t.status === ACTIVE_STATUS.ACTIVE)
        .map(t => ({ value: t.id, label: t.priority }))
    }));
    setTestTypes((prev) => [...prev, ...options]);
  };

  const loadTests = async () => {
    const res = await getTests(1);
    const totalPages = valueGetter(res, 'data.pagination.total_pages') || 1;
    generateTestTypes(valueGetter(res, 'data.services') || {});
    const requests = Array.from({ length: totalPages - 1 }, (_, i) => i + 2)
      .map((page) => getTests(page));
    const responses = await Promise.all(requests);
    responses.forEach((response) => {
      generateTestTypes(valueGetter(response, 'data.services') || {});
    });
  };

  const step = {
    0: <Step0 onClick={handleSubmit} />,
    1: <Step1 isExist={isExist} />,
    2: <Step2 />,
    3: <Step3 testTypes={testTypes} />,
    4: <Step4 />,
    5: <Step5 />,
  };

  React.useEffect(() => {
    loadTests();
  }, []);

  return step[activeStep];
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number,
  handleStep0Submit: PropTypes.func,
};

export default CurrentStep;

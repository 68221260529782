const Billing = {
  // *
      
  // A

  // B
  b2b: 'B2B',
  b2c: 'B2C',
  billing_list_title: 'جميع الفواتير',
  billing_list_subtitle: 'إحصائيات عن كل الفواتير',
  billing_search_input_placeholder: 'أدخل رقم العينة أو رقم batch',
      
  // C
  client: 'Client',
  clinic_name: 'اسم العيادة',
      
  // D
      
  // E
      
  // F
      
  // G
      
  // H
      
  // I
  invoice_list_title: 'جميع الفواتير',
  invoice_list_subtitle: 'نظرة عامة على جميع الفواتير',
  invoice_number: 'رقم الفاتورة',
  invoice_date: 'تاريخ الفاتورة',
  invoice_search_input_placeholder: 'ابحث برقم الفاتورة',
  invoice_type: 'نوع الفاتورة',

  // J
      
  // K
      
  // L
      
  // M
      
  // N
  no_of_samples: 'عدد العينات',
  
  // O
  outsourcing: 'Outsourcing',

  // P
  payment_refunded: 'تم استرجاع المبلغ',
  payment_status: 'Payment Status',
  payment_verified: 'تم التأكيد',
  paid_amount: 'Paid Amount',

  // Q
      
  // R
  refunded_amount: 'المبلغ المسترجع',
  refunded_b2b: 'B2B المسترجع',
  refunded_b2c: 'B2C المسترجع',

  // S
  sample: 'عينة',
  sample_cost: 'تكلفة العينه',
  
  // T
  total_amount: 'اجمال المبلغ',
  
  // U
      
  // V
  verify_payment: 'تأكيد الدفع',
  // W
      
  // X
      
  // Y
      
  // Z
      
};
      
export default Billing;
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ACTIVE_STATUS } from 'models';
import i18n from 'translation/i18n';
import { isStepNotValid } from 'utils/labUtils';
import {valueGetter} from 'utils/uiHelpers';

import { ReactComponent as AddIcon } from 'assets/icons/common/add-square.svg';
import { Alert, Modal, Button } from 'atoms';
import { ModalHeader } from 'templates';
import ToolTipHandler from 'components/global/ToolTipHandler';
import {
  addPatient,
  labPatientOptionsLoad,
} from 'redux/patients/actionCreators';
import { testsLoad } from 'redux/tests/actionCreators';
import NewPatientForm from './NewPatientForm';

const steps = {
  0: [],
  1: [
    { parent: 'patient', child: 'name' },
    { parent: 'patient', child: 'gender' },
    { parent: 'patient', child: 'mobile' },
  ],
  2: [
    { parent: 'patient', child: 'nationality_id' },
    { parent: 'patient', child: 'date_of_birth' },
    { parent: 'patient', child: 'directorate_id' },
    { parent: 'patient', child: 'isolotion_place_id' },
    { parent: 'patient', child: 'id_type' },
    { parent: 'patient', child: 'personal_id' },
    { parent: 'patient', child: 'occupation' },
  ],
  3: [],
  4: [],
  5: [],
};

const NewPatient = ({ onDone }) => {
  const dispatch = useDispatch();
  const { services, fetching } = useSelector((state) => state.tests);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const activeTests = services.filter(
    (service) => service.status === ACTIVE_STATUS.ACTIVE,
  );

  const onSubmit = (values) => {
    setShowAlert(false);
    const isEmptyField = isStepNotValid(values, activeStep, steps);
    if (isEmptyField) {
      setShowAlert(true);
      return false;
    }
    if (activeStep < 5) {
      return setActiveStep(activeStep + 1);
    }
    const packageOrders = values?.orders_package?.flatMap((p) =>
      p?.services?.map((s) => ({ ...s, package_id: p.package_id })),
    );
    const orders = [...(values?.orders || []), ...(packageOrders || [])];
    const params = {
      ...values,
      orders,
    };
    if (valueGetter(params, 'appointment.collection_method') === 'lab_collection') {
      delete params?.appointment?.time;
    }
    return dispatch(addPatient(params)).then(() => {
      setShowModal(false);
      setActiveStep(0);
      onDone();
    });
  };

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onShowPatientInfo = () => {
    setActiveStep(1);
  };

  React.useEffect(() => {
    dispatch(testsLoad({ status: 'active' }));
    dispatch(labPatientOptionsLoad());
  }, []);

  return (
    <>
      <ToolTipHandler
        show={!activeTests.length}
        title={i18n.t('no_active_tests_tootip_title')}
      >
        <Button
          label={i18n.t('add_patient')}
          startIcon={<AddIcon />}
          onClick={() => setShowModal(true)}
          disabled={fetching || !activeTests.length}
        />
      </ToolTipHandler>
      <Modal
        header={
          <ModalHeader
            title={i18n.t('add_patient')}
            onBack={[0, 1].includes(activeStep) ? null : () => onBack()}
          />
        }
        isOpen={showModal}
        onClose={() => {
          setActiveStep(0);
          setShowModal(false);
          setShowAlert(false);
        }}
        width={activeStep === 3 ? 600 : 350}
        disableCloseOut
      >
        <NewPatientForm
          onSubmit={onSubmit}
          activeStep={activeStep}
          onShowPatientInfo={onShowPatientInfo}
        />
        <Alert
          message={i18n.t('all_fields_are_required')}
          variant="danger"
          placement="bottom"
          show={showAlert}
          onClose={() => setShowAlert(false)}
        />
      </Modal>
    </>
  );
};

NewPatient.defaultProps = {
  onDone: () => {},
};

NewPatient.propTypes = {
  onDone: PropTypes.func,
};

export default NewPatient;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as Check } from 'assets/icons/atoms/Checkbox.svg';
import { ReactComponent as UnChecked } from 'assets/icons/atoms/Checkbox-unChecked.svg';
import Spacer from 'atoms/Spacer';
import Flex from 'atoms/Flex';
import Text from 'atoms/Text';

import 'styles/styleTokens.css';
import checkboxStyle from './Checkbox.module.css';

const Checkbox = (props) => {
  const {
    label,
    name,
    className,
    style,
    checked,
    onChange,
    disabled,
    testid,
    'data-testid': dataTestId,
  } = props;

  return (
    <div
      className={clsx(
        checkboxStyle.checkbox,
        disabled && checkboxStyle.disabled,
        className,
      )}
      style={style}
    >
      <input
        type="checkbox"
        disabled={disabled}
        name={name}
        checked={checked}
        onClick={(e) => onChange(e.target.checked)}
        data-testid={dataTestId || testid}
      />

      <Flex itemsCenter>
        <span className={clsx(checkboxStyle.iconContainer)}>
          {checked ? <Check /> : <UnChecked />}
        </span>

        <Spacer width="8px" style={{ flex: 'none' }} />

        <Text
          as="label"
          htmlFor={name}
          typography="paragraph14"
          weight="medium"
          color="var(--color-natural-black-default)"
        >
          {label}
        </Text>
      </Flex>
    </div>
  );
};
Checkbox.defaultProps = {
  label: '',
  name: '',
  onChange: () => {},
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  testid: PropTypes.string,
  'data-testid': PropTypes.string,
  style: PropTypes.shape(),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};
export default Checkbox;

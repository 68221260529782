import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getClients } from 'redux/lab/actionCreators';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import { ClientFilters } from './ClientFilters';
import ClientTable from './ClientTable';

const ClientList = ({ client }) => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const appliedFilters = {
    search: query?.search,
    status: query?.status,
    role: query?.role,
  };

  const load = (params = {}) => dispatch(getClients(client, params));

  useEffect(() => {
    load(appliedFilters);
  });

  const handlePagination = useHandelPagination({
    filters: appliedFilters,
    onPageChange: load,
  });

  const handleFilterChange = (filters) => {
    const params = {
      search: filters.search,
      status: filters.status,
      role: filters.role,
    };
    setQuery(params);
    load(params);
  };

  const content = (
    <>
      <ListFilters
        Wrapped={ClientFilters}
        client={client}
        onChange={handleFilterChange}
      />
      <ClientTable
        client={client}
        filters={appliedFilters}
        onPagination={handlePagination}
      />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

ClientList.propTypes = {
  client: PropTypes.string,
};

export default ClientList;

import React from 'react';
import PropTypes from 'prop-types';

import {REPORT} from 'models';
import UserPerformanceReports from './user-performance';
import SalesReports from './sales';
import InvoicesReports from './invoices';
import ResultsReports from './results';
import TotalAmountsReports from './total-amounts';
import SourceReports from './source';
import FinanceReport from './finance';

const CurrentReport = ({selectedReport}) => {
  const step = {
    [REPORT.USER_PERFORMANCE]: <UserPerformanceReports/>,
    [REPORT.SALES]: <SalesReports/>,
    [REPORT.RESULTS]: <ResultsReports/>,
    [REPORT.INVOICES]: <InvoicesReports/>,
    [REPORT.TOTAL_AMOUNTS]: <TotalAmountsReports/>,
    [REPORT.SOURCE_REPORTS]: <SourceReports/>,
    [REPORT.INCOME_FINANCE]: <FinanceReport/>,
    [REPORT.INVOICE_FINANCE]: <FinanceReport/>,
    [REPORT.B2B_FINANCE]: <FinanceReport/>
  };
    
  return step[selectedReport];
};
  
CurrentReport.propTypes = {
  selectedReport: PropTypes.string,
};

export default CurrentReport;

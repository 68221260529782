import i18n from 'translation/i18n';
import { TEMP_PASS } from 'models';
import {
  composeValidators,
  maxLength,
  minLength,
  required,
  number,
} from './FormValidations';

export const profileInfo = (disabled, data = {}) => {
  return {
    general: [
      {
        name: 'name_en',
        label: i18n.t('name_en'),
        placeholder: i18n.t('name_en'),
        value: data.name_en,
        disabled,
      },
      {
        name: 'name_ar',
        label: i18n.t('name_ar'),
        placeholder: i18n.t('name_ar'),
        value: data.name_ar,
        disabled,
      },
      {
        name: 'mobile',
        label: i18n.t('mobile'),
        placeholder: i18n.t('mobile'),
        value: data.mobile,
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('mobile_number'),
        },
        disabled: true,
      },
      {
        name: 'email',
        label: i18n.t('email'),
        placeholder: i18n.t('email'),
        value: data.email,
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('email'),
        },
        disabled: true,
      },
      {
        name: 'password',
        label: i18n.t('password'),
        placeholder: i18n.t('password'),
        type: 'password',
        hideShowPasswordOption: 'true',
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('password'),
        },
        disabled: true,
        value: TEMP_PASS,
      },
    ],
  };
};

export const labInfo = (disabled, data = {}, options) => {
  return {
    general: [
      {
        name: 'name_en',
        label: i18n.t('name_en'),
        placeholder: i18n.t('name_en'),
        validate: required,
        disabled,
        value: data.name_en,
      },
      {
        name: 'name_ar',
        label: i18n.t('name_ar'),
        placeholder: i18n.t('name_ar'),
        validate: required,
        disabled,
        value: data.name_ar,
      },
      {
        name: 'bank_name',
        label: i18n.t('bank_name'),
        placeholder: i18n.t('bank_name'),
        validate: required,
        disabled,
        value: data.bank_name,
      },
      {
        name: 'iban_number',
        label: i18n.t('iban'),
        placeholder: i18n.t('iban'),
        validate: required,
        disabled,
        value: data.iban_number,
      },
      {
        name: 'vat_number',
        label: i18n.t('vat_number'),
        placeholder: i18n.t('vat_number'),
        validate: composeValidators(required, number),
        disabled,
        value: data.vat_number,
      },
      {
        name: 'cr_number',
        label: i18n.t('cr_number'),
        placeholder: i18n.t('cr_number'),
        validate: composeValidators(
          required,
          number,
          maxLength(10),
          minLength(10),
        ),
        maxLength: 10,
        disabled,
        value: data.cr_number,
      },
    ],

    location: [
      {
        name: 'address',
        label: i18n.t('address'),
        placeholder: i18n.t('address'),
        validate: required,
        disabled,
        value: data.address,
      },
      {
        name: 'location.country',
        label: i18n.t('country'),
        as: 'dropdown',
        placeholder: i18n.t('country'),
        disabled,
        value: data.location,
        options: options?.countries,
      },
      {
        name: 'location.street_name',
        label: i18n.t('street_name'),
        placeholder: i18n.t('street_name'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.additional_street_name',
        label: i18n.t('additional_street_name'),
        placeholder: i18n.t('additional_street_name'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.building_number',
        label: i18n.t('building_number'),
        placeholder: i18n.t('building_number'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.city_name',
        label: i18n.t('city_name'),
        as: 'dropdown',
        placeholder: i18n.t('city_name'),
        disabled,
        value: data.location,
      },
      {
        name: 'location.postal_zone',
        label: i18n.t('postal_zone'),
        placeholder: i18n.t('postal_zone'),
        disabled,
        value: data.location,
      },
    ],

    privacy: [
      {
        name: 'admin_service_provider.0.email',
        label: i18n.t('email'),
        placeholder: i18n.t('email'),
        disabled,
        value: data.admin_service_provider
          ? data.admin_service_provider[0]?.email
          : '',
      },
      !data.is_admin_role && {
        name: 'password',
        label: i18n.t('password'),
        placeholder: i18n.t('password'),
        type: 'password',
        hideShowPasswordOption: true,
        disabled,
        value: data.admin_service_provider
          ? data.admin_service_provider[0]?.password
          : '',
      },
      {
        name: 'admin_service_provider.0.mobile',
        label: i18n.t('mobile_number'),
        placeholder: i18n.t('mobile_number'),
        disabled,
        value: data.admin_service_provider
          ? data.admin_service_provider[0]?.mobile
          : '',
      },
    ].filter(Boolean),
    lab_statistics: [
      {
        name: 'reserved_orders',
        label: i18n.t('reserved_orders'),
        placeholder: i18n.t('reserved_orders'),
        disabled,
        value: data.reserved_orders,
      },
      {
        name: 'confirmed_orders',
        label: i18n.t('confirmed_orders'),
        placeholder: i18n.t('confirmed_orders'),
        disabled,
        value: data.confirmed_orders,
      },
      {
        name: 'total_income',
        label: i18n.t('total_income'),
        placeholder: i18n.t('total_income'),
        disabled,
        value: data.total_income,
      },
    ],
  };
};

export const labDetailsSubTabs = [
  { label: i18n.t('personal_information'), value: 'personal_information' },
  { label: i18n.t('subscriptions'), value: 'subscriptions' },
];

import { handleAPI, API_VERSION_ONE } from 'utils/apiUtils';
import {
  ACCESS_TOKEN,
  LOCALE,
  SLUG,
  USER,
  LAB_ID,
  LAB_LABEL,
  ADMIN_LABEL,
  CLINIC_LABEL,
  COLLECTION_CENTER_LABEL, USER_PERMISSIONS,
} from 'utils/constants';

import { USER_ROLE } from 'models';
import * as actions from './actions';

// export function getLocation(lat, lng) {
//   const url = `https://us1.locationiq.com/v1/reverse.php?key=YOUR_PRIVATE_TOKEN&lat=${lat}&lon=${lng}&format=json`;
//   const method = 'GET';

//   return {
//     type: actions.REGISTER_LAB,
//     payload: handleFullAPI(url, {}, method),
//   };
// }

// Local Storage Auth : ---------------
export function setAuthSlug(slug) {
  localStorage.setItem(SLUG, slug);
}

export function setAuthLabId(id) {
  localStorage.setItem(LAB_ID, id);
}

export function setAuthToken(accessToken) {
  return localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function setAuthUser(user) {
  const role = user.role_label;
  let module = role.includes(LAB_LABEL) ? LAB_LABEL : role;
  if (role === USER_ROLE.SUPER_ADMIN) {
    module = ADMIN_LABEL;
  }
  localStorage.setItem(USER, JSON.stringify({ ...user, module }));
}

export function setAuthUserForAdmin(user) {
  const role = user.role_label;
  let module = role.includes(LAB_LABEL) ? LAB_LABEL : role;
  if (role === USER_ROLE.SUPER_ADMIN) {
    module = ADMIN_LABEL;
  }
  localStorage.setItem(USER, JSON.stringify({ ...user, module }));
}

export function getAuthUser() {
  const user = localStorage.getItem(USER);
  return JSON.parse(user);
}

export function getSlug() {
  return localStorage.getItem(SLUG);
}

export function removeAuthToken() {
  localStorage.removeItem(USER_PERMISSIONS);
  localStorage.removeItem(ACCESS_TOKEN);
}

export function removeAuthUser() {
  localStorage.removeItem(USER);
  removeAuthToken();
  return {
    type: actions.REMOVE_AUTH_USER,
  };
}

export function removeAuthSlug() {
  localStorage.removeItem(SLUG);
}

export function setLocale(locale = 'en') {
  localStorage.setItem(LOCALE, locale);
}

// registration: ----------------------------------------
export function getPlansOptionsRequest() {
  const url = `/${API_VERSION_ONE}/subscription`;
  const method = 'GET';

  return handleAPI(url, {}, method);
}

export function registerLab(data) {
  const url = `/${API_VERSION_ONE}/auth/registrations/lab`;
  const method = 'POST';

  return {
    type: actions.REGISTER_LAB,
    payload: handleAPI(url, {}, method, data, {}, {}, false, true),
  };
}

export function patientSignup(data) {
  const url = `/${API_VERSION_ONE}/auth/registrations/patient`;
  const method = 'POST';

  return {
    type: actions.PATIENT_SIGNUP,
    payload: handleAPI(url, {}, method, data),
  };
}

export function labClientRegister(data) {
  const isClinic = window.location.pathname.includes(CLINIC_LABEL);
  const clientLabel = isClinic ? CLINIC_LABEL : COLLECTION_CENTER_LABEL;
  const url = `/${API_VERSION_ONE}/auth/registrations/${clientLabel}`;
  const method = 'POST';

  return {
    type: actions.LAB_CLIENT_REGISTER,
    payload: handleAPI(url, {}, method, data),
  };
}

// login flow ----------------------------------------

export function adminLogin(data) {
  const url = `/${API_VERSION_ONE}/auth/login/admin`;
  const method = 'POST';

  return {
    type: actions.ADMIN_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function patientLogin(data) {
  const url = `/${API_VERSION_ONE}/auth/login/patient`;
  const method = 'POST';

  return {
    type: actions.PATIENT_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function labLogin(module, data) {
  const url = `/${API_VERSION_ONE}/auth/login/${module}`;
  const method = 'POST';

  return {
    type: actions.LAB_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function verifyLogin(otp, mobile) {
  const url = `/${API_VERSION_ONE}/auth/verify`;
  const method = 'POST';
  const data = {
    otp,
    mobile,
  };

  return {
    type: actions.VERIFY_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function resendCode(mobile) {
  const url = `/${API_VERSION_ONE}/auth/verify`;
  const method = 'PUT';
  const data = { mobile };

  return {
    type: actions.RESEND_CODE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getResetEmail(email) {
  const url = `/${API_VERSION_ONE}/auth/passwords`;
  const method = 'POST';
  const data = { key: email };

  return {
    type: actions.GET_RESET_EMAIL,
    payload: handleAPI(url, {}, method, data),
  };
}

export function resetPassword(data) {
  const url = `/${API_VERSION_ONE}/auth/passwords`;
  const method = 'PUT';

  return {
    type: actions.RESET_PASSWORD,
    payload: handleAPI(url, {}, method, data),
  };
}

import axios from 'axios';
import { Cache, cacheAdapterEnhancer } from 'axios-extensions';
import { API_ROOT, PROTOCOL } from 'env';
import Qs from 'qs';
import {
  ACCESS_TOKEN,
  ADMIN_LABEL,
  APPLICATION_JSON,
  CLINIC_LABEL,
  COLLECTION_CENTER_LABEL,
  LAB_LABEL,
  LOCALE,
  PATIENT_LABEL,
} from 'utils/constants';
import errorResponseHandler from 'utils/errorHandler';
import {
  isAdminUser,
  isClinicUser,
  isCollectionCenterUser,
  isPatient,
} from 'utils/labUtils';
import { isHostContainSlug } from './uiHelpers';

const API_VERSION_ONE = 'v1';

const maxAge = 20 * 1000;

export const moduleBaseUrl = () => {
  let module = LAB_LABEL;
  if (isClinicUser()) {
    module = CLINIC_LABEL;
  } else if (isCollectionCenterUser()) {
    module = COLLECTION_CENTER_LABEL;
  } else if (isPatient()) {
    module = PATIENT_LABEL;
  } else if (isAdminUser()) {
    module = ADMIN_LABEL;
  }
  return `/${API_VERSION_ONE}/${module}`;
};

const http = axios.create({
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    defaultCache: new Cache({ maxAge }),
  }),
});

if (http.interceptors) {
  http.interceptors.response.use(null, errorResponseHandler);
}

export const serializerConfig = {
  arrayFormat: 'brackets',
  encode: false,
};

function removeEmptyParams(params) {
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
}

function handleAPI(
  path,
  params,
  method,
  data,
  cacheConfig = {},
  options = {},
  isThirdPartyUrl = false,
  removeSlug = false,
) {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const locale = localStorage.getItem(LOCALE) || 'en';

  let headers = {
    'Content-Type': APPLICATION_JSON,
    Accept: APPLICATION_JSON,
    Authorization: `Bearer ${token}`,
    locale,
  };

  let url = `${API_ROOT}${path}`;

  if (isHostContainSlug() && !removeSlug) {
    const slug = window.location.host.split('.')[0];
    url = `${slug}.${url}`;
  } else if (!url.includes('www') && !url.includes('localhost')) {
    url = `www.${url}`;
  }

  url = `${PROTOCOL}://${url}`;

  if (isThirdPartyUrl) {
    url = path;
    headers = {
      'Content-Type': APPLICATION_JSON,
    };
  }

  const { cache } = cacheConfig;

  return http({
    method,
    url,
    params,
    paramsSerializer: (paramObject) =>
      Qs.stringify(paramObject, serializerConfig),
    data,
    headers,
    cache,
    ...options,
  });
}

export { API_VERSION_ONE, handleAPI, removeEmptyParams };

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PackageDetails from 'container/labs/Tests/PackageDetials';


const TestRoutes = () => (
  <Switch>
    <Route exact path="/packages/:id" component={PackageDetails} />
  </Switch>
);

export default TestRoutes;

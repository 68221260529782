import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';
import * as actions from './actions';
import {valueGetter} from '../../utils/uiHelpers';
import {USER_PERMISSIONS} from '../../utils/constants';

export const initialState = {
  fetching: false,
  submitting: false,
  fetchingOptions: false,
  profile: {},
  userProfile: {}
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.PROFILE_SHOW_PENDING:
  case actions.ADMIN_PROFILE_SHOW_PENDING:
  case actions.USER_PROFILE_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.PROFILE_UPDATE_PENDING:
  case actions.CHANGE_EMAIL_PENDING:
  case actions.CHANGE_MOBILE_PENDING:
  case actions.VERIFY_CHANGE_MOBILE_PENDING:
  case actions.CHANGE_PASSWORD_PENDING:
    return {
      ...state,
      submitting: true,
    };

  case actions.PROFILE_OPTIONS_PENDING:
    return {
      ...state,
      fetchingOptions: true,
    };
      
  case actions.PROFILE_SHOW_FULFILLED: {
    return {
      ...state,
      profile: action.payload.data.profile,
      fetching: false,
    };
  }

  case actions.ADMIN_PROFILE_SHOW_FULFILLED: {
    return {
      ...state,
      profile: action.payload.data.profile,
      fetching: false,
    };
  }

  case actions.USER_PROFILE_FULFILLED: {
    const allPermissions = valueGetter(action, 'payload.data.profile.allowed_permissions') || [];
    localStorage.setItem(USER_PERMISSIONS, JSON.stringify(allPermissions));
    return {
      ...state,
      userProfile: action.payload.data.profile,
      fetching: false,
    };
  }

  case actions.PROFILE_UPDATE_FULFILLED:
  case actions.CHANGE_EMAIL_FULFILLED:
  case actions.CHANGE_MOBILE_FULFILLED:
  case actions.VERIFY_CHANGE_MOBILE_FULFILLED:
  case actions.CHANGE_PASSWORD_FULFILLED: {
    Toastr.success(action.payload.data.message);

    return {
      ...state,
      data: action.payload.data,
      submitting: false,
    };
  }

  case actions.PROFILE_OPTIONS_FULFILLED:
    return {
      ...state,
      countries: action.payload.data.countries,
      fetchingOptions: false,
    };
    
  case actions.PROFILE_SHOW_REJECTED:
  case actions.ADMIN_PROFILE_SHOW_REJECTED:
  case actions.USER_PROFILE_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    
    return {
      ...state,
      fetching: false,
    };

  case actions.PROFILE_UPDATE_REJECTED:
  case actions.CHANGE_EMAIL_REJECTED:
  case actions.CHANGE_MOBILE_REJECTED:
  case actions.VERIFY_CHANGE_MOBILE_REJECTED:
  case actions.CHANGE_PASSWORD_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    
    return {
      ...state,
      submitting: false,
    };
    
  case actions.PROFILE_OPTIONS_REJECTED:
    return {
      ...state,
      fetchingOptions: false,
    };
    
  default:
    return state;
  }
}
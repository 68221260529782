import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { exportBatchAsPDF } from 'redux/orders/actionCreators';
import i18n from 'translation/i18n';
import { printPDF } from 'utils/labUtils';
import { Grid } from '@mui/material';
import { Button, Flex, Spacer, Text } from 'atoms';
import BackButton from 'components/global/BackButton';
import OrderResultTransition from 'components/global/OrderResultTransition';

const BatchOrderFilters = ({ showRemoveBtn, onRemove, onUpdate }) => {
  const dispatch = useDispatch();
  const { batchDetails, submitting } = useSelector((state) => state.orders);
  const [batchId, setBatchId] = useState();

  const exportPDF = () => {
    dispatch(exportBatchAsPDF(batchId))
      .then((resp) => resp.value.data)
      .then((blob) => printPDF(blob));
  };

  const handleMenuClick = (result) => {
    onUpdate(result);
  };

  useEffect(() => {
    setBatchId(batchDetails.id);
  }, [batchDetails]);

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 4}
      py={2}
      px={3}
      alignItems="center"
    >
      <Grid item xs={6} lg={6}>
        <Flex flexCol={isBrowser} itemsCenter={isMobile}>
          <BackButton />
          {isMobile && <Spacer width={16} />}
          <Text
            typography={isMobile ? 'paragraph16' : 'subtitle'}
            weight="bold"
          >
            {i18n.t('batch', { batchNumber: batchDetails.serial_number })}
          </Text>
        </Flex>
      </Grid>
      <Grid item xs={6} lg={2}>
        {showRemoveBtn ? (
          <Button
            variant="danger"
            label={i18n.t('remove_from_batch')}
            onClick={onRemove}
          />
        ) : (
          <Spacer height={40} />
        )}
      </Grid>
      <Grid item xs={6} lg={2}>
        <OrderResultTransition
          menuPosition="bottom"
          status={batchDetails.status}
          result={batchDetails.result}
          onMenuClick={handleMenuClick}
        >
          <Button variant="primary" label={i18n.t('update_results')} />
        </OrderResultTransition>
      </Grid>
      <Grid item xs={6} lg={2}>
        <Button
          variant="secondary"
          label={i18n.t('export_as_pdf')}
          onClick={exportPDF}
          disabled={submitting}
        />
      </Grid>
    </Grid>
  );
};

BatchOrderFilters.propTypes = {
  showRemoveBtn: PropTypes.bool,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default BatchOrderFilters;

import {handleAPI, API_VERSION_ONE} from 'utils/apiUtils';

import * as actions from './actions';

export function getLabDetails() {
  const url = `/${API_VERSION_ONE}/lab_details`;
  const method = 'GET';

  return {
    type: actions.LAB_DETAILS,
    payload: handleAPI(url, {}, method),
  };
}

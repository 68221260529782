import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Button, Modal, TextInput} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';

const DiscountModal = ({initialDiscount, onClose}) => {
  const [discount, setDiscount] = useState(0);

  const handleClose = (value) => {
    onClose(value);
    setDiscount(0);
  };

  useEffect(() => {
    setDiscount(initialDiscount);
  }, [initialDiscount]);

  return (
    <Modal
      disableCloseOut
      isOpen
      width={260}
      header={<ModalHeader title={i18n.t('discount')}/>}
      footer={<Button
        variant="secondary"
        label={i18n.t('submit')}
        onClick={() => handleClose(discount)}
      />}
      onClose={handleClose}
    >
      <ModalContent padding="0 1rem">
        <TextInput
          type="number"
          value={discount}
          placeholder={i18n.t('discount')}
          style={{margin: '0.5rem 0 1rem'}}
          onChange={(e) => setDiscount(e.target.value)}
        />
      </ModalContent>
    </Modal>
  );
};

DiscountModal.propTypes = {
  initialDiscount: PropTypes.number,
  onClose: PropTypes.func
};

export default DiscountModal;
import { snakeCase } from 'lodash';

import {
  moduleBaseUrl,
  handleAPI,
  API_VERSION_ONE,
  removeEmptyParams,
} from 'utils/apiUtils';
import { dateToQueryFormat } from 'utils/date';
import { isPathIncludes } from 'utils/uiHelpers';
import { ACTIVE_STATUS } from 'models';

import * as actions from './actions';

export function serviceUpdate(id, referenceRangesPayload = {}) {
  const url = `${moduleBaseUrl()}/services/${id}/update`;
  const method = 'PUT';

  return {
    type: actions.SERVICE_UPDATE,
    payload: handleAPI(url, {}, method, {
      service: referenceRangesPayload,
    }),
  };
}

export function syncClinicTestsWithLab(id) {
  const url = `${moduleBaseUrl()}/clinics/${id}/refresh`;
  const method = 'POST';

  return {
    type: actions.SYNC_CLINIC_TESTS,
    payload: handleAPI(url, {}, method),
  };
}

export function getOverviewStats(startDate, endDate) {
  const url = `${moduleBaseUrl()}/statistics`;
  const method = 'GET';
  const params = {
    start_date: dateToQueryFormat(startDate),
    end_date: dateToQueryFormat(endDate),
  };

  return {
    type: actions.OVERVIEW_STATS,
    payload: handleAPI(url, params, method),
  };
}

export function getOverdueTests(params = {}) {
  const url = `${moduleBaseUrl()}/overdue_orders`;
  const method = 'GET';

  return {
    type: actions.OVERDUE_TESTS_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getMasterReservations(params = {}) {
  const url = `${moduleBaseUrl()}/master_orders/reservations`;
  const method = 'GET';

  return {
    type: actions.MASTER_RESERVATION_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getReservationDetails(id) {
  const url = `${moduleBaseUrl()}/patients/${id}`;
  const method = 'GET';

  return {
    type: actions.RESERVATION_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function getMasterReservationDetails(id) {
  const url = `${moduleBaseUrl()}/master_orders/${id}`;
  const method = 'GET';

  return {
    type: actions.MASTER_RESERVATION_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function indexMasterOrderOrders(
  masterOrderId,
  params = {},
  path = 'orders',
) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/${path}`;
  const method = 'GET';

  return {
    type: actions.INDEX_MASTER_ORDER_ORDERS,
    payload: handleAPI(url, params, method),
  };
}

export function updateMasterOrderDetails(masterOrderId, data) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_RESERVATION_INFO,
    payload: handleAPI(url, {}, method, data),
  };
}

export function cancelMasterOrder(masterOrderId, data) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/cancel`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_RESERVATION_INFO,
    payload: handleAPI(url, {}, method, data),
  };
}

export function updateMasterOrdeeTestDetails(masterOrderId, orderId, data) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/orders/${orderId}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_RESERVATION_INFO,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getMasterOrderDetails(id, params = {}) {
  const url = `${moduleBaseUrl()}/master_orders/${id}`;
  const method = 'GET';

  return {
    type: actions.MASTER_RESERVATION_INFO,
    payload: handleAPI(url, params, method),
  };
}

export function getMasterReservationTests(id, params = {}) {
  const url = `${moduleBaseUrl()}/master_orders/${id}/patients`;
  const method = 'GET';

  return {
    type: actions.MASTER_RESERVATION_TESTS,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getMasterReservationTestDetails(reservation_id, test_id) {
  const url = `${moduleBaseUrl()}/master_orders/${reservation_id}/patients/${test_id}`;
  const method = 'GET';

  return {
    type: actions.MASTER_RESERVATION_TEST_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function updateMasterOrderTestDetails(masterOrderId, testId, data) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/orders/${testId}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_ORDER_TEST_INFO,
    payload: handleAPI(url, {}, method, data),
  };
}

export function cancelMasterOrderTest(masterOrderId, testId, data) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/orders/${testId}/cancel`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_ORDER_TEST_INFO,
    payload: handleAPI(url, {}, method, data),
  };
}

export function verifyMasterOrderTest(masterOrderId, testId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/orders/${testId}/verify_results`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_ORDER_TEST_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function verifyMasterOrderAllTests(masterOrderId) {
  const url = `${moduleBaseUrl()}/master_orders/${masterOrderId}/orders/verify_all`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_MASTER_ORDER_TEST_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function getUsers(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/users`;
  const method = 'GET';

  return {
    type: actions.USER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getUserOptions() {
  const url = `/${API_VERSION_ONE}/lab/users/options`;
  const method = 'GET';

  return {
    type: actions.USER_OPTIONS,
    payload: handleAPI(url, {}, method),
  };
}

export function createUser(data) {
  const url = `/${API_VERSION_ONE}/lab/users`;
  const method = 'POST';

  return {
    type: actions.CREATE_USER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getUser(userId) {
  const url = `/${API_VERSION_ONE}/lab/users/${userId}`;
  const method = 'GET';

  return {
    type: actions.GET_USER,
    payload: handleAPI(url, {}, method),
  };
}

export function updateUser(userId, data) {
  const url = `/${API_VERSION_ONE}/lab/users/${userId}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_USER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getPlans() {
  const url = `/${API_VERSION_ONE}/subscription`;
  const method = 'GET';

  return {
    type: actions.GET_PLANS,
    payload: handleAPI(url, {}, method),
  };
}

export function getServiceProviderSubscriptions(params = {}) {
  const url = `${moduleBaseUrl()}/service_provider_subscriptions`;
  const method = 'GET';

  return {
    type: actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS,
    payload: handleAPI(url, params, method),
  };
}

export function deleteUser(userId) {
  const url = `/${API_VERSION_ONE}/lab/users/${userId}`;
  const method = 'DELETE';

  return {
    type: actions.DELETE_USER,
    payload: handleAPI(url, {}, method),
  };
}

export function getClients(client, params = {}) {
  const url = `/${API_VERSION_ONE}/lab/${snakeCase(client)}`;
  const method = 'GET';

  return {
    type: actions.CLINIC_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getClinics() {
  const url = `/${API_VERSION_ONE}/lab/clinics`;
  const method = 'GET';

  return {
    type: actions.ACTIVE_CLINICS,
    payload: handleAPI(url, { status: ACTIVE_STATUS.ACTIVE }, method),
  };
}

export function getCollectionCenters() {
  const url = `/${API_VERSION_ONE}/lab/collection_centers`;
  const method = 'GET';

  return {
    type: actions.ACTIVE_CENTERS,
    payload: handleAPI(url, { status: ACTIVE_STATUS.ACTIVE }, method),
  };
}

export function getClientDetails(id) {
  const client = isPathIncludes('clinics') ? 'clinics' : 'collection_centers';
  const url = `/${API_VERSION_ONE}/lab/${client}/${id}`;
  const method = 'GET';

  return {
    type: actions.CLINIC_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function searchClientTest(filters) {
  return {
    type: actions.FILTER_CLIENT_TEST,
    payload: { filters },
  };
}

export function updateClient(id, data) {
  const client = isPathIncludes('clinics') ? 'clinics' : 'collection_centers';
  const url = `/${API_VERSION_ONE}/lab/${client}/${id}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_CLINIC,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getRoles() {
  const url = `/${API_VERSION_ONE}/lab/roles`;
  const method = 'GET';

  return {
    type: actions.GET_ROLES,
    payload: handleAPI(url, {}, method),
  };
}

export function getRoleDetail(id) {
  const url = `/${API_VERSION_ONE}/lab/roles/${id}`;
  const method = 'GET';

  return {
    type: actions.GET_ROLE_DETAIL,
    payload: handleAPI(url, {}, method),
  };
}

export function updateRoleDetail(id, data) {
  const url = `/${API_VERSION_ONE}/lab/roles/${id}/update_permissions`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_CLINIC,
    payload: handleAPI(url, {}, method, data),
  };
}

export function subscriptionRenew() {
  const url = `/${API_VERSION_ONE}/lab/service_provider_subscriptions/renew`;
  const method = 'POST';

  return {
    type: actions.SERVICE_UPDATE,
    payload: handleAPI(url, {}, method),
  };
}

export function subscriptionPatientPortalToggle(payload) {
  const url = `/${API_VERSION_ONE}/lab/service_provider_subscriptions/patient_portal`;
  const method = 'POST';

  return {
    type: actions.SERVICE_UPDATE,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function subscriptionUpdateResource(updateKey, payload) {
  const url = `/${API_VERSION_ONE}/lab/service_provider_subscriptions/request_${updateKey}`;
  const method = 'POST';

  return {
    type: actions.SERVICE_UPDATE,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function subscriptionPlanChange(action, payload) {
  const url = `/${API_VERSION_ONE}/lab/service_provider_subscriptions/${action}`;
  const method = 'POST';

  return {
    type: actions.SERVICE_UPDATE,
    payload: handleAPI(url, {}, method, payload),
  };
}

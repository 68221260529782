import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import i18n from 'translation/i18n';
import { required } from 'utils/FormValidations';
import { getLabSubscriptions, updateServiceProviderSubscription } from 'redux/admin/actionCreators';

import { Form } from 'react-final-form';
import { Button, Flex, Modal, Spacer, Switch, Text } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';
import { TextInputField } from 'components/global/final-form';

const SubscriptionConfigModal = ({
  showModal, serviceProviderId, subscriptionId, patient_portal, onClose, ...rest
}) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = React.useState(false);
  const [showPatientPortal, setShowPatientPortal] = React.useState(patient_portal);

  const onSubmit = (values) => {
    setSubmitting(true);
    const payload = {
      patient_portal: showPatientPortal,
      analyzer_count: Number(values.analyzer_count),
      users_count: Number(values.users_count),
      clients_count: Number(values.clients_count),
    };
    dispatch(
      updateServiceProviderSubscription(
        serviceProviderId,
        subscriptionId,
        { lab_subscription: payload }
      )
    )
      .then(() => {
        dispatch(getLabSubscriptions(serviceProviderId));
        onClose();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={350}
        header={<ModalHeader title={i18n.t('update_config')}  />}
        onClose={onClose}
      >
        <Form onSubmit={() => {}} initialValues={{ ...rest }}>
          {({handleSubmit, values}) => (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <Flex itemsCenter justifyBetween>
                  <Text typography="caption12" color="var(--brand-text-gray-light)" weight="bold">
                    {i18n.t('activate_patient_portal').toUpperCase()}
                  </Text>
                  <Switch
                    name="patient_portal"
                    checked={showPatientPortal}
                    onChange={(value) => setShowPatientPortal(value)}
                  />
                </Flex>
                <Spacer height={12} />
                <TextInputField
                  name="analyzer_count"
                  label={i18n.t('analyzer_count')}
                  type="number"
                  min={0}
                  validate={required}
                />
                <Spacer height={12} />
                <TextInputField
                  name="users_count"
                  label={i18n.t('user_count')}
                  type="number"
                  min={0}
                  validate={required}
                />
                <Spacer height={12} />
                <TextInputField
                  name="clients_count"
                  label={i18n.t('client_count')}
                  type="number"
                  min={0}
                  validate={required}
                />
                <Spacer height={12} />
              </ModalContent>
              <Spacer
                height={1}
                width="100%"
                style={{ backgroundColor: 'var(--brand-divider-white)' }}
              />
              <ModalContent>
                <Button
                  label={i18n.t('submit')}
                  onClick={() => onSubmit(values)}
                  disabled={submitting}
                />
              </ModalContent>
            </form>
          )}
        </Form>
      </Modal>
    )
  );
};

SubscriptionConfigModal.propTypes = {
  showModal: PropTypes.bool,
  serviceProviderId: PropTypes.string,
  subscriptionId: PropTypes.string,
  onClose: PropTypes.func,
  analyzer_count: PropTypes.number,
  users_count: PropTypes.number,
  clients_count: PropTypes.number,
  patient_portal: PropTypes.bool,
};

export default SubscriptionConfigModal;
import { Grid } from '@mui/material';
import { Spacer } from 'atoms';
import { useURLQuery } from 'hooks/useURLQuery';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getMasterReservationTests,
  updateMasterOrderDetails,
} from 'redux/lab/actionCreators';
import { ModalContent, SectionTitle } from 'templates';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { ReservationTests } from '../ReservationTests';
import { GeneralReservationInfo } from './GeneralReservationInfo';
import { ReservationSummary } from './ReservationSummary';

export const ReservationInfoForm = () => {
  const dispatch = useDispatch();
  const { reservationInfo } = useSelector((state) => state.lab);
  const [editing, setEditing] = React.useState(false);
  const initialValues = {
    ...reservationInfo,
    patient_detail: {
      ...reservationInfo.patient_detail,
      nationality_id: valueGetter(
        reservationInfo,
        'patient_detail.nationality.id',
      ),
      directorate_id: valueGetter(
        reservationInfo,
        'patient_detail.directorate.id',
      ),
      isolotion_place_id: valueGetter(
        reservationInfo,
        'patient_detail.isolotion_place.id',
      ),
    },
  };

  const onEditClick = (values) => {
    if (editing) {
      const payload = {
        note: values.note,
        patient: values.patient_detail,
        user: {
          email: values.user.email,
          mobile: values.user.mobile,
        },
      };
      dispatch(updateMasterOrderDetails(values.id, payload)).then(() =>
        setEditing(false),
      );
    } else {
      setEditing(true);
    }
  };

  const { id } = useParams();
  const { setQuery } = useURLQuery();

  const loadReservationTests = (params = {}) => {
    dispatch(getMasterReservationTests(id, params));
  };
  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      status: filter.status,
      priority: filter.priority,
    };

    setQuery({ ...params });
    loadReservationTests(params);
  };

  return (
    <Form onSubmit={() => {}} initialValues={initialValues}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <SectionTitle
            titleKey="general_reservation_information"
            showEdit
            editKey={i18n.t(editing ? 'save' : 'edit_info')}
            onEdit={() => onEditClick(values)}
          />
          <Spacer height={16} />
          <Grid container>
            <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
              <GeneralReservationInfo editing={editing} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <ModalContent>
                <ReservationSummary />
              </ModalContent>
            </Grid>
          </Grid>
          <Spacer height={16} />
          <ReservationTests handleFilterChange={handleFilterChange} />
        </form>
      )}
    </Form>
  );
};

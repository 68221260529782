import React from 'react';
import PropTypes from 'prop-types';

import {Text} from 'atoms';
import styles from './LinkButton.module.css';

const LinkButton = ({label, style, onClick}) => {
  return (
    <Text
      className={styles.linkButton}
      style={{...style}}
      typography="caption13"
      weight="bold"
      color="var(--brand-text-primary)"
      onClick={onClick}
    >
      {label}
    </Text>
  );
};

LinkButton.defaultProps = {
  onClick: () => {}
};

LinkButton.propTypes = {
  label: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
};

export default LinkButton;

import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import i18n from 'translation/i18n';
import {getStatusColor} from 'utils/labUtils';
import {duration} from 'utils/date';

import {LinkButton, Tag, Text} from 'atoms';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import './styles.scss';

export const OverdueTests = () => {
  const {push} = useHistory();
  const [orders, setOrders] = useState([]);
  const {statistics} = useSelector(state => state.lab);

  useEffect(() => {
    const value = statistics?.overdue_orders || [];
    setOrders(value);
  }, [statistics]);

  return (
    <>
      <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">{i18n.t('overview_overdue_title')}</Text>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
          {i18n.t('overview_overdue_subtitle')}
        </Text>
        <LinkButton
          label={i18n.t('view_all')}
          onClick={() => push('/overdue-tests')}
        />
      </Box>
      <TableContainer className="overdue-tests-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t('patient')}</TableCell>
              <TableCell>{i18n.t('test')} / {i18n.t('tat')}</TableCell>
              <TableCell>{i18n.t('overdue_since')}</TableCell>
              <TableCell>{i18n.t('status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => {
              const [hh, mm] = order.turn_around_time.split(':');
              const hours = Number(hh);
              const minutes = Number(mm);

              return (
                <TableRow
                  key={index}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell>
                    <Text typography="paragraph14" weight="bold">{order.patient_detail_name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text typography="paragraph14" weight="bold">{order.service_name}</Text>
                    <Text typography="caption12" weight="light" color="#C5C7CD">
                      {hours ? `${hours} hours ` : ''}
                      {minutes ? `${minutes} minutes` : ''}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text typography="paragraph14" weight="bold">{duration(order.date_of_confirmed)}</Text>
                  </TableCell>
                  <TableCell>
                    <Tag text={i18n.t(`status_${order.status}`)} color={getStatusColor(order.status)}/>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};


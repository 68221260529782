import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';

import {DataTable, Tag} from 'atoms';

const PatientOrderTable = () => {
  const {push} = useHistory();
  const {patientOrders, fetching} = useSelector(state => state.orders);

  const handleRowClick = ({id}) => {
    push(`/tests/${id}`);
  };

  const columns = [
    {
      field: 'patient_name',
      headerName: i18n.t('patient'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 100,
      flex: 1
    },
    {
      field: 'date',
      headerName: i18n.t('date'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => format(value, DISPLAY_DATE_FORMAT)
    },
    {
      field: 'sample_id',
      headerName: i18n.t('sample_id'),
      minWidth: 100,
      flex: 1
    },
    {
      field: 'count_orders',
      headerName: i18n.t('total_tests'),
      minWidth: 80,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 80,
      flex: 1,
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={patientOrders}
      loading={fetching}
      onRowClick={handleRowClick}
    />
  );
};

export default PatientOrderTable;

import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';

import LabSideNav from 'components/global/LabSideNav';
import LabRoutes from 'routers/LabRoutes';

import './styles.scss';
import { SerialProvider } from 'container/labs/Serial/provider';

const LabLayout = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  const toggleSideNav = () => {
    setShowSideNav(!showSideNav);
  };

  return (
    <SerialProvider>
      <div
        className={clsx(
          'lab-layout',
          isMobile && 'mobile-view',
          showSideNav && 'show-side-nav',
        )}
      >
        <LabSideNav toggleLayout={toggleSideNav} />
        <LabRoutes toggleLayout={toggleSideNav} />
      </div>
    </SerialProvider>
  );
};

export default LabLayout;

import React from 'react';
import { useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { DataTable } from 'atoms';

const ReferenceRangesTable = () => {
  const { service_show, fetching } = useSelector((state) => state.tests);

  const referenceRanges =
    valueGetter(service_show, 'reference_ranges') || [];

  const columns = [
    {
      field: 'gender',
      headerName: i18n.t('gender'),
      minWidth: 150,
    },
    {
      field: 'min_age',
      headerName: i18n.t('min_age'),
      minWidth: 150,
    },
    {
      field: 'max_age',
      headerName: i18n.t('max_age'),
      minWidth: 150,
    },
    {
      field: 'min_value',
      headerName: i18n.t('min_value'),
      minWidth: 150,
    },
    {
      field: 'max_value',
      headerName: i18n.t('max_value'),
      minWidth: 150,
    },
    {
      field: 'result',
      headerName: i18n.t('result'),
      minWidth: 150,
    },
  ];

  return (
    <DataTable columns={columns} rows={referenceRanges} loading={fetching} />
  );
};

export default ReferenceRangesTable;

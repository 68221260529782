import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Reservations, ReservationDetails } from 'container/labs/Reservation';
import ReservationTestInfoForm from 'components/reservation/masterReservation/ReservationTests/ReservationTestInfo';
import { ReservationPackageInfoForm } from 'components/reservation/masterReservation/ReservationTests/ReservationTestInfo/ReservationPackageInfoForm';

const ReservationRoutes = () => (
  <Switch>
    <Route
      path="/reservations/:id/tests/:test_id"
      exact
      component={ReservationTestInfoForm}
    />
    <Route
      path="/reservations/:id/packages/:package_id"
      exact
      component={ReservationPackageInfoForm}
    />
    <Route
      path="/reservations/:id/packages/tests/:test_id"
      exact
      component={ReservationTestInfoForm}
    />
    <Route path="/reservations/:id" exact component={ReservationDetails} />
    <Route path="/reservations" exact component={Reservations} />
  </Switch>
);

export default ReservationRoutes;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {sendMasterOrderTestResult} from 'redux/orders/actionCreators';

import {Box, Grid, IconButton, TextareaAutosize} from '@mui/material';
import {Button, Modal, Select, Spacer, Text} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';
import DeleteIcon from '@mui/icons-material/Clear';
import styles from '../tests/styles.module.css';

const EditNonLogicalResultModal = ({
  existingResult,
  existingImage,
  masterOrderId,
  testId,
  subTestId,
  refOptions,
  onClose
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = React.useState(existingResult);
  const [image, setImage] = React.useState(existingImage);
  const [attachment, setAttachment] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const options = React.useMemo(() => {
    return refOptions.map((option) => ({
      value: option.id,
      label: option.description
    }));
  }, [refOptions]);

  const handleUpload = (event) => {
    setUploading(true);
    if (!event.target.files) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setAttachment({
        data: reader.result,
        filename: file.name,
        content_type: file.type,
        destroy: false
      });
      setImage(reader.result);
      setUploading(false);
    };
    reader.onerror = (error) => {
      console.log('File upload error: ', error);
      setAttachment(null);
      setUploading(false);
    };
  };

  const handleSubmit = () => {
    const payload = {
      order: { result, attachment, sub_service_id: subTestId }
    };
    dispatch(
      sendMasterOrderTestResult(masterOrderId, testId, payload)
    ).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      isOpen
      header={<ModalHeader title={i18n.t('enter_result')}/>}
      width={350}
      disableCloseOut
      onClose={onClose}
    >
      <ModalContent>
        <Select
          label={i18n.t('default_result_options')}
          options={options}
          value=""
          onChange={(v) => setResult(v.label)}
        />
      </ModalContent>
      <Spacer height={1} width="100%" style={{backgroundColor: 'var(--brand-divider-white)'}}/>
      <ModalContent>
        <Grid container padding={2} gap={2} mb={2} border="1px solid #d2d6de" borderRadius={2}>
          <Text
            typography="caption12"
            as="span"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('enter_result')}
          </Text>
          <TextareaAutosize
            style={{width: '100%'}}
            placeholder={i18n.t('non_logical_result_placeholder')}
            minRows={5}
            width="100%"
            value={result}
            onChange={(e) => setResult(e.target.value)}
          />
          {image && (
            <Box width={300} height={250} border="1px solid lightgrey" borderRadius={2} position="relative">
              <IconButton
                size="small"
                style={{ position: 'absolute', top: '0.1rem', right: '0.1rem' }}
                onClick={() => {
                  setImage(null);
                  setAttachment(existingImage ? { destroy: true } : null);
                }}
              >
                <DeleteIcon/>
              </IconButton>
              <img
                style={{objectFit: 'contain', width: '100%', height: '100%'}}
                src={image}
                alt="result_image"
              />
            </Box>
          )}
          <label className={clsx(styles.btn, uploading && styles.disabled)} htmlFor="file-upload">
            <Text typography="paragraph14">{i18n.t(existingImage ? 'replace_image' : 'upload_image')}</Text>
          </label>
          <input
            id="file-upload"
            hidden
            type="file"
            accept="image/*"
            disabled={uploading}
            onChange={handleUpload}
          />
        </Grid>
        <Button
          label={i18n.t('submit')}
          disabled={!result.trim()}
          onClick={handleSubmit}
        />
      </ModalContent>
    </Modal>
  );
};

EditNonLogicalResultModal.propTypes = {
  existingResult: PropTypes.string,
  existingImage: PropTypes.string,
  masterOrderId: PropTypes.string,
  testId: PropTypes.string,
  subTestId: PropTypes.string,
  refOptions: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func
};

export default EditNonLogicalResultModal;
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import i18n from 'translation/i18n';
import { getClientDetails } from 'redux/lab/actionCreators';
import { Grid } from '@mui/material';
import LabHeader from 'components/global/LabHeader';
import { ClientInfo, ClientSummary, ClientTests } from 'components/labClients';
import { CardContainer } from 'templates';

const ClinicDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientDetails(id));
  }, []);

  return (
    <>
      <LabHeader title="clinics" />
      <Grid container spacing={isMobile ? 2 : 4} p={isMobile ? 2 : 4}>
        <Grid item xs={12} lg={8}>
          <CardContainer
            className="clinic-info-container"
            title={i18n.t('clinic_info_title')}
            showBackButton
            noPadding
          >
            <ClientInfo />
            <ClientTests />
            {/* <ClientPatientPricing/> */}
          </CardContainer>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ClientSummary />
        </Grid>
      </Grid>
    </>
  );
};

export default ClinicDetails;

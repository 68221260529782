import React from 'react';
import i18n from 'translation/i18n';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import {Form} from 'react-final-form';
import {Button, Text, Flex} from 'atoms';
import {TEMP_PASS} from 'models';
import {
  CardContainer,
  CardSectionHeader,
  FieldInfoCardList,
  ModalContent,
} from 'templates';
import {profileInfo} from 'utils/admin';
import {profilesShowForAdmin, profileUpdateForAdmin} from 'redux/profile/actionCreators';
import ChangeModal from '../ChangeModal';

const modalData = {
  mobile_number: {
    title: i18n.t('change_number'),
    subTitle: i18n.t('enter_your_new_number'),
    key: 'mobile_number',
  },
  password: {
    title: i18n.t('change_password'),
    subTitle: i18n.t('enter_your_password'),
    key: 'password',
  },
  email: {
    title: i18n.t('change_email'),
    subTitle: i18n.t('enter_your_email'),
    key: 'email',
  },
};

const AdminProfileInfo = () => {
  const [editting, setEditting] = React.useState(false);
  const [showModal, setShowModal] = React.useState(null);
  const dispatch = useDispatch();
  const {profile, submitting} = useSelector(state => state.profile);
  const info = profileInfo(!editting);

  const loadProfile = () => {
    dispatch(profilesShowForAdmin());
  };

  const actionCallback = (actionType, values) => {
    setShowModal({
      ...modalData[actionType],
      initialValues: values});
  };

  const onEditDone = () => {
    setShowModal(null);
    loadProfile();
  };

  const onProfileSubmit = (values) => {
    delete values.email;
    delete values.mobile;
    delete values.password;
    dispatch(profileUpdateForAdmin(values));
    setEditting(false);
  };

  React.useEffect(()=>{
    loadProfile();
  }, []);

  return (
    <Form onSubmit={onProfileSubmit} initialValues={{...profile, password: TEMP_PASS}}>
      {({handleSubmit, values}) => (
        <form onSubmit={handleSubmit}>
          <Flex justifyEnd itemsEnd style={{padding: '32px 20px 1px 1px', minHeight: '32px'}}>
            {editting ? 
              (<Button label={i18n.t('save')} size="small" type="submit" fitContent disabled={submitting}/>)
              :
              (
                <Text typography="caption12" link onClick={() => setEditting(true)}>
                  {i18n.t('edit_profile_info')}
                </Text>
              )
            }
          </Flex>
          <ModalContent>
            <CardContainer noPadding style={{width: '100%'}}>
              <CardSectionHeader title={i18n.t('profile')} uppercased/>
              <ModalContent>
                <Grid container spacing='16px' sx={{padding: '16px'}}>
                  <FieldInfoCardList 
                    list={info.general} 
                    actionCallback={(actionType) => actionCallback(actionType, values)}
                  />
                </Grid>
              </ModalContent>
            </CardContainer>
          </ModalContent>
          {!!showModal && (
            <ChangeModal
              show={!!showModal} 
              onDone={onEditDone} 
              data={showModal}
            />
          )}
        </form>
      )}
    </Form>
  );
};

export default AdminProfileInfo;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Grid } from '@mui/material';
import { isEmpty, omit } from 'lodash';
import { Spacer } from 'atoms';
import { TEMP_PASS } from 'models';
import {
  getAdminServiceProviderInfo,
  updateAdminServiceProviders,
} from 'redux/admin/actionCreators';
import { LabGeneralInfo, LabAdditionInfo, LabSubscriptionInfo } from '.';

const LabInfo = () => {
  const [editing, setEditing] = useState(false);
  const { service_provider } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { id } = useParams();

  const loadServiceProviderInfo = () => {
    dispatch(getAdminServiceProviderInfo(id));
  };

  const loadData = () => {
    loadServiceProviderInfo();
  };

  const onLabSubmit = (data) => {
    const userInfo = omit(
      {
        ...data.admin_service_provider[0],
        password: data.password,
      },
      [
        isEmpty(data.password) || data.password.includes(TEMP_PASS)
          ? 'password'
          : '',
      ],
    );
    data.users_attributes = [userInfo];
    const pureData = omit(data, [
      'id',
      'slug',
      'admin_service_provider',
      'name_i18n',
      'reserved_orders',
      'confirmed_orders',
      'total_income',
      'logo_url',
      'signature_url',
      'stamp_url',
      'status',
    ]);

    dispatch(updateAdminServiceProviders(pureData, id)).then(() => {
      loadData();
      setEditing(false);
    });
  };

  const handleEditClick = (values) => {
    if (!editing) {
      return setEditing(true);
    }
    return onLabSubmit(values);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ ...service_provider, password: TEMP_PASS }}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} sx={{ margin: 0 }}>
            <Grid item xs={8}>
              <LabGeneralInfo
                onDone={loadData}
                onEditClick={() => handleEditClick(values)}
                onEditDone={loadData}
                editing={editing}
              />
              <Spacer height={24} />
            </Grid>
            <Grid item xs={3.5}>
              <LabSubscriptionInfo />
              <Spacer height={20} />
              <LabAdditionInfo editing={editing} />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default LabInfo;

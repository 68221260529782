import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'translation/i18n';
import { valueGetter, isPathIncludes } from 'utils/uiHelpers';
import { clinicNextStatus } from 'utils/labUtils';
import { ACTIVE_STATUS, CLINIC_STATUS } from 'models';
import { getClients, updateClient } from 'redux/lab/actionCreators';

import { DataTable, Flex, Spacer, Switch, Tag, Text } from 'atoms';
import ClinicStatusTransition from 'components/global/ClinicStatusTransition';
// eslint-disable-next-line import/no-named-as-default-member
import useTablePagination from 'hooks/useTablePagination';

const { language } = i18n;
const menuAlign = {
  ar: 'right',
  en: 'left',
};

const ClientTable = ({ client, filters, onPagination }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { clients, fetching, pagination } = useSelector((state) => state.lab);

  const rows = useTablePagination({ pagination, dataList: clients });

  const handleStatusChange = (clinicId, status) => {
    const payload = {
      status: status ? ACTIVE_STATUS.ACTIVE : ACTIVE_STATUS.INACTIVE,
    };
    dispatch(updateClient(clinicId, payload)).then(() =>
      dispatch(getClients(client, filters)),
    );
  };

  const clientDetailsPath = {
    true: '/clients/clinics',
    false: '/clients/collection-centers',
  };

  const handleRowClick = ({ id }) => {
    push(`${clientDetailsPath[isPathIncludes('clinics')]}/${id}`);
  };

  const handleCellClick = ({ field }, event) => {
    if (field === 'status') {
      event.stopPropagation();
    }
  };

  const handleMenuClick = (clinicId, { option, reason }) => {
    const payload = {
      request_status: option,
      reason,
    };
    dispatch(updateClient(clinicId, payload)).then(() =>
      dispatch(getClients(client, filters)),
    );
  };

  const columns = [
    {
      field: 'secondary.name_i18n',
      headerName: i18n.t('name'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ row, value }) => (
        <Flex justifyBetween itemsCenter>
          <Text typography="paragraph14" weight="bold">
            {value}
          </Text>
          <Spacer width={16} />
          {row.request_status === CLINIC_STATUS.PENDING && (
            <Tag
              variant="pill"
              text={row.request_status}
              color="var(--brand-process-text)"
            />
          )}
        </Flex>
      ),
    },
    {
      field: 'secondary.admin_service_provider.0.mobile',
      headerName: i18n.t('phone'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'secondary.address',
      headerName: i18n.t('address'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'status',
      headerName: i18n.t('activation'),
      minWidth: 120,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ row, value }) => {
        const isActive = value === ACTIVE_STATUS.ACTIVE;
        return (
          <Switch
            disabled={row.request_status === CLINIC_STATUS.PENDING}
            checked={isActive}
            onChange={(status) => handleStatusChange(row.id, status)}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({ row }) => (
        <ClinicStatusTransition
          menuPosition={menuAlign[language]}
          options={clinicNextStatus[row.request_status]}
          onMenuClick={(payload) => handleMenuClick(row.id, payload)}
        />
      ),
    },
  ];

  return (
    <DataTable
      mobileTableHeight={15}
      loading={fetching}
      columns={columns}
      rows={fetching ? [] : rows}
      onCellClick={handleCellClick}
      onRowClick={handleRowClick}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

ClientTable.propTypes = {
  client: PropTypes.string,
  filters: PropTypes.shape(),
  onPagination: PropTypes.func,
};

export default ClientTable;

import React from 'react';
import AdminHeader from 'components/global/AdminHeader';
import AdminRoutes from 'routers/AdminRoutes';

import './styles.scss';

const AdminLayout = () => {
  return (
    <div className="admin-layout">
      <AdminHeader/>
      <AdminRoutes/>
    </div>
  );
};

export default AdminLayout;

import React from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {isClinicUser} from 'utils/labUtils';
import {ORDER_TYPES} from 'utils/options';
import {ORDER_TYPE} from 'models';

import LabHeader from 'components/global/LabHeader';
import {MasterOrderInfoWrapper} from 'components/orders/lab/masterOrders';

export const OrderDetails = () => {
  const {order_id} = useParams();
  const {push} = useHistory();

  const handleFilterChange = value => {
    push(`/orders/${order_id}/${value}`);
  };

  return (
    <>
      <LabHeader
        title="master_order_list_title"
        selectedOption={ORDER_TYPE.SINGLE}
        filterOptions={isClinicUser() ? [] : ORDER_TYPES}
        onFilterChange={handleFilterChange}
      />
      <MasterOrderInfoWrapper/>
    </>
  );
};

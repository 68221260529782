import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import LabHeader from 'components/global/LabHeader';
import { MasterReservationInfo } from 'components/reservation/masterReservation';
import {
  getMasterReservationDetails,
  getMasterReservationTests,
} from 'redux/lab/actionCreators';
import { useURLQuery } from 'hooks/useURLQuery';

export const ReservationDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const loadReservationInfo = () => {
    dispatch(getMasterReservationDetails(id));
  };

  const { query } = useURLQuery();

  const loadReservationTests = () => {
    dispatch(getMasterReservationTests(id, { ...query }));
  };

  React.useEffect(() => {
    loadReservationInfo();
    loadReservationTests();
  }, []);

  return (
    <>
      <LabHeader title="reservations" />
      <MasterReservationInfo />
    </>
  );
};

/* eslint-disable no-console */
import { serial as polyfill } from 'web-serial-polyfill';
import { readMultiFrameData } from './readMultiframeData';

export async function connectToPort({ options, onConnected, onDoneReading, onDisconnected }) {
    const bufferSize = 8 * 1024; // 8kB
    const isByobMode = options.readerMode === 'byob';
    const serial = navigator.serial || polyfill;
    if (!navigator.serial && serial) {
        alert('Web Serial API not supported, using polyfill');
    }

    let port = await serial.requestPort({});

    function markDisconnected() {
        console.log('<DISCONNECTED>');
        port = undefined;
        onDisconnected();
    }

    if (!port) {
        return;
    }

    const connectionOptions = {
        bufferSize,
        baudRate: Number.parseInt(options.baudRate, 10),
        dataBits: Number.parseInt(options.dataBits, 10),
        parity: options.parity,
        stopBits: Number.parseInt(options.stopBits, 10),
        flowControl: options.flowControl,
        // Prior to Chrome 86 these names were used.
        baudrate: Number.parseInt(options.baudRate, 10),
        databits: Number.parseInt(options.dataBits, 10),
        stopbits: Number.parseInt(options.stopBits, 10),
        rtscts: options.flowControl === 'hardware',
    };
    console.log(connectionOptions);

    try {
        await port.open(connectionOptions);
        onConnected(port);
        console.log('<CONNECTED>');
    } catch (e) {
        console.error(e);
        if (e instanceof Error) {
            console.log(`<ERROR: ${e.message}>`);
        }
        markDisconnected();
        return;
    }

    const writer = port.writable.getWriter();

    await readMultiFrameData({
        serialPort: port,
        bufferSize,
        onDoneReading,
        writer,
        isByobMode
    });
}

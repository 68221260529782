import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import { Button, Flex, Modal, Spacer, Switch, Text, TextInput } from 'atoms';
import { ModalContent, ModalHeader } from 'templates';

const ReasonModal = ({ showModal, status, onClose, showRefundOption }) => {
  const [reason, setReason] = useState('');
  const [refund, setRefund] = useState(false);

  const handleClose = (value) => {
    onClose(value);
    setReason('');
    setRefund(false);
  };

  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={260}
        header={<ModalHeader title={i18n.t(`reason_for_${status}`)} />}
        onClose={handleClose}
      >
        <ModalContent padding="0 1rem">
          <Text typography="caption12" color="var(--brand-text-gray-light)">
            {i18n.t('reason').toUpperCase()}
          </Text>
          <TextInput
            value={reason}
            as="textarea"
            rows={8}
            style={{ margin: '0.5rem 0 1rem' }}
            placeholder={i18n.t('reason_placeholder')}
            onChange={(e) => setReason(e.target.value)}
          />
          {showRefundOption && (
            <Flex itemsCenter justifyBetween>
              <Text typography="caption12" color="var(--brand-text-gray-light)">
                {i18n.t('refund').toUpperCase()}
              </Text>
              <Switch
                name="refund"
                checked={refund}
                onChange={(value) => setRefund(value)}
              />
            </Flex>
          )}
          <Spacer height={10} />
          <Button
            variant="secondary"
            label={i18n.t('submit')}
            onClick={() => handleClose({ reason, refund })}
          />
        </ModalContent>
      </Modal>
    )
  );
};

ReasonModal.propTypes = {
  showModal: PropTypes.bool,
  showRefundOption: PropTypes.bool,
  status: PropTypes.string,
  onClose: PropTypes.func,
};

export default ReasonModal;

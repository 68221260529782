import { Card } from 'atoms';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { testsLoad } from 'redux/tests/actionCreators';
import { isPatient } from 'utils/labUtils';
import { ListFilters } from 'components/hoc';
import { TestFilters, TestTable } from './index';

const checkValues = {
  input: true,
  span: true,
  svg: true,
  button: true,
  path: true,
};

const TestList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { message, tests } = useSelector((state) => state.tests);
  const { query, setQuery } = useURLQuery();

  const handleRowClick = (values, inputTarget) => {
    const { id } = values.row;
    if (!checkValues[inputTarget.target.nodeName.toLowerCase()]) {
      return push(`/tests/${id}`, { id });
    }
    return false;
  };

  const load = (params = {}) => dispatch(testsLoad(params));

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      status: filter.status,
      category: filter.category,
      priority: filter.priority,
    };
    setQuery(params);
    load(params);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: load,
  });

  React.useEffect(() => {
    if (!isPatient()) {
      const params = {
        category: query?.category,
        search: query?.search,
        status: query?.status,
        priority: query?.priority,
      };
      load(params);
    }
  }, []);

  const content = (
    <>
      <ListFilters Wrapped={TestFilters} onChange={handleFilterChange} />
      <TestTable
        list={tests}
        onRowClick={handleRowClick}
        noData={message}
        onPagination={handlePagination}
      />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default TestList;

import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {composeValidators, maxLength, minLength, enNumber, required} from 'utils/FormValidations';
import {valueGetter} from 'utils/uiHelpers';
import {getAuthUser} from 'redux/auth/actionCreators';

import {Button, Spacer} from 'atoms';
import {ReactComponent as BackIcon} from 'assets/icons/common/arrow-left.svg';
import {TextInputField} from 'components/global/final-form';

const CreateClient2 = ({onBack, clientLabel}) => {
  const isLabUser = !!valueGetter(getAuthUser(), 'role_label');

  return (
    <>
      {!isLabUser &&
        <>
          <BackIcon onClick={onBack} style={{cursor: 'pointer'}}/>
          <Spacer height={12}/>
        </>
      }
      <TextInputField
        name="service_provider.vat_number"
        placeholder={i18n.t('vat')}
        label={i18n.t('vat_number').toUpperCase()}
        validate={composeValidators(required, enNumber)}
      />
      <Spacer height={12}/>
      <TextInputField
        name="service_provider.cr_number"
        placeholder={i18n.t('commercial_registration')}
        label={i18n.t('cr_number').toUpperCase()}
        validate={composeValidators(required, enNumber, maxLength(10), minLength(10))}
        maxLength={10}
      />
      <Spacer height={12}/>
      <TextInputField
        name="service_provider.bank_name"
        placeholder={i18n.t('bank_name')}
        label={i18n.t('bank_name').toUpperCase()}
        validate={required}
      />
      <Spacer height={12}/>
      <TextInputField
        name="service_provider.iban_number"
        placeholder={i18n.t('iban')}
        label={i18n.t('iban').toUpperCase()}
        validate={required}
      />
      <Spacer height={12}/>
      <TextInputField
        name="service_provider.address"
        placeholder={i18n.t('lab_address')}
        label={i18n.t('address').toUpperCase()}
        validate={required}
      />
      <Spacer height={24}/>
      <Button type="submit" label={i18n.t(`add_${clientLabel}`)}/>
    </>
  );
};

CreateClient2.propTypes = {
  onBack: PropTypes.func,
  clientLabel: PropTypes.string
};

export default CreateClient2;
import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { composeValidators, enNumber, required } from 'utils/FormValidations';
import { getListForSelect } from 'utils/labUtils';

import { Spacer } from 'atoms';
import { DatePickerField, SelectField, TextInputField } from 'components/global/final-form';

const Step2 = () => {
  const { locations } = useSelector((state) => state.inventory);

	return (
		<>
			<SelectField
				name="location_id"
				label={i18n.t('location')}
				placeholder={i18n.t('location')}
				validate={required}
        options={getListForSelect(locations)}
      />
      <Spacer height={8} />
      <TextInputField
				name="original_quantity"
				label={i18n.t('quantity')}
				placeholder={i18n.t('quantity')}
				validate={composeValidators(required, enNumber)}
      />
      <Spacer height={4} />
      <TextInputField
				name="total_amount"
				label={i18n.t('total_amount')}
				placeholder={i18n.t('total_amount')}
				validate={composeValidators(required, enNumber)}
      />
      <Spacer height={4} />
      <DatePickerField
				name="expiry_date"
				label={i18n.t('expiry_date')}
				displayFormat="dd MMM yyyy"
				placeholder={i18n.t('expiry_date')}
				minDate={new Date()}
				validate={required}
      />
    </>
  );
};

export default Step2;
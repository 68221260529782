import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {isBrowser} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {PERMISSION} from 'models';
import {printOrderResult, sendOrderResult, sendReservationDetail} from 'redux/orders/actionCreators';
import {getPatientBarcode} from 'redux/patients/actionCreators';
import {printPDF} from 'utils/labUtils';
import {valueGetter} from 'utils/uiHelpers';
import {userHasPermission} from 'utils/permissionUtils';

import {IconButton, Menu, MenuItem} from '@mui/material';
import {Button, Spacer, Text} from 'atoms';
import {ReactComponent as Printer} from 'assets/icons/common/printer.svg';
import {ReactComponent as MenuIcon} from 'assets/icons/common/more-vertical.svg';

const ReservationInfoActions = ({masterOrder}) => {
  const dispatch = useDispatch();
  const [disableResultPrint, setDisableResultPrint] = useState(false);
  const [disableBarcodePrint, setDisableBarcodePrint] = useState(false);
  const masterOrderId = valueGetter(masterOrder, 'id');
  const showBarcodePrint = (valueGetter(masterOrder, 'allow_print_barcode') || false) && userHasPermission(PERMISSION.PRINT_BARCODE);
  const showResultPrint = (valueGetter(masterOrder, 'allow_print_pdf_results') || false) && userHasPermission(PERMISSION.PRINT_RESULT);
  const showResultSend = (valueGetter(masterOrder, 'allow_send_pdf_results') || false) && userHasPermission(PERMISSION.PRINT_RESULT);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePrintBarcode = () => {
    setDisableBarcodePrint(true);
    dispatch(getPatientBarcode(masterOrderId))
      .then(resp => resp.value.data)
      .then(blob => printPDF(blob))
      .finally(() => setDisableBarcodePrint(false));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handlePrintResult = () => {
    setDisableResultPrint(true);
    dispatch(printOrderResult(masterOrderId))
      .then(resp => resp.value.data)
      .then(blob => printPDF(blob))
      .finally(() => setDisableResultPrint(false));
  };

  const handleShareResult = () => {
    dispatch(sendOrderResult(masterOrderId));
  };

  return isBrowser && (
    <>
      {showBarcodePrint && (
        <Button
          label={i18n.t('print_barcode')}
          startIcon={<Printer/>}
          disabled={disableBarcodePrint}
          onClick={handlePrintBarcode}
        />
      )}
      {showResultPrint && (
        <>
          <Spacer width={32}/>
          <Button
            label={i18n.t('print_result')}
            startIcon={<Printer/>}
            disabled={disableResultPrint}
            onClick={handlePrintResult}
          />
        </>
      )}
      <Spacer width={32}/>
      <div
        id="reservation-actions"
        aria-haspopup="true"
        aria-controls={open ? 'reservation-actions-popover' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <IconButton>
          <MenuIcon />
        </IconButton>
      </div>
      <Menu
        id="reservation-actions-popover"
        open={open}
        anchorEl={anchorEl}
        MenuListProps={{ 'aria-labelledby': 'reservation-actions' }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          disabled={disableBarcodePrint}
          onClick={() => dispatch(sendReservationDetail(masterOrderId))}
        >
          <Text typography="paragraph14">{i18n.t('resend_reservation_detail')}</Text>
        </MenuItem>
        {showResultSend && (
          <MenuItem onClick={handleShareResult}>
            <Text typography="paragraph14">{i18n.t('send_result')}</Text>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

ReservationInfoActions.propTypes = {
  masterOrder: PropTypes.shape()
};

export default ReservationInfoActions;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasIn } from 'lodash';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { STOCK_STATUS } from 'models';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';
import {getExpirationColor, getStatusColor} from 'utils/labUtils';
import {
  getStockDetails, updateStock,
  updateStockOriginalQuantity,
  updateStockRemainingQuantity
} from 'redux/inventory/actionCreators';

import { Grid, IconButton } from '@mui/material';
import { CheckCircle, Edit } from '@mui/icons-material';
import { Flex, Spacer, Tag, Text } from 'atoms';
import { InfoCard, SectionTitle, ValueEditModal } from 'templates';

const ItemInfo = ({ onEdit }) => {
  const dispatch = useDispatch();
  const { stock, fetching } = useSelector(state => state.inventory);

  const [showModal, setShowModal] = React.useState(false);
  const [editKey, setEditKey] = React.useState('');

  const reload = () => {
    dispatch(getStockDetails(stock.id));
    setShowModal(false);
    setEditKey('');
  };

  const handleOnEdit = (shouldUpdate, value) => {
    if (!shouldUpdate) {
      setShowModal(false);
      setEditKey('');
      return;
    }
    if (editKey === 'original_quantity') {
      dispatch(updateStockOriginalQuantity(stock.id, {new_quantity: Number(value)})).then(reload);
    } else if (editKey === 'remaining_quantity') {
      dispatch(updateStockRemainingQuantity(stock.id, {amount: Number(value)})).then(reload);
    }
  };

  const handleStatusChange = () => {
    dispatch(updateStock(stock.id, {status: STOCK_STATUS.IN_USE})).then(reload);
  };

  const details = [
    { label: 'item_name', value: stock.item_name },
    { label: 'description', value: stock.description },
    {
      label: 'status',
      custom: (
        <Flex itemsStart>
          <Tag text={stock.status_text} color={getStatusColor(stock.status)} />
          <Spacer width={16} />
          {stock.status === STOCK_STATUS.NEW && (
            <IconButton
              style={{fontSize: '1rem', marginTop: '-0.5rem'}}
              color="success"
              onClick={handleStatusChange}
            >
              <CheckCircle/>
            </IconButton>
          )}
        </Flex>
      )
    },
    {
      label: 'original_quantity',
      custom: (
        <Flex itemsStart>
          <Text typography="paragraph16" weight="bold">{stock.original_quantity}</Text>
          <Spacer width={16} />
          <IconButton
            style={{fontSize: '1.25rem', marginTop: '-0.5rem'}}
            color="primary"
            onClick={() => {
              setEditKey('original_quantity');
              setShowModal(true);
            }}
          >
            <Edit fontSize="inherit"/>
          </IconButton>
        </Flex>
      )
    },
    {
      label: 'remaining_quantity',
      custom: (
        <Flex itemsStart>
          <Text typography="paragraph16" weight="bold">{stock.remaining_quantity}</Text>
          <Spacer width={16} />
          {stock.status === STOCK_STATUS.IN_USE && (
            <IconButton
              style={{fontSize: '1.25rem', marginTop: '-0.5rem'}}
              color="primary"
              onClick={() => {
                setEditKey('remaining_quantity');
                setShowModal(true);
              }}
            >
              <Edit fontSize="inherit"/>
            </IconButton>
          )}
        </Flex>
      )
    },
    { label: 'availability', value: stock.availability },
    { label: 'lot_number', value: stock.lot_number },
    { label: 'serial_number', value: stock.serial_number },
    { label: 'unit', value: stock.unit },
    { label: 'manufacturer', value: stock.manufacturer },
    { label: 'expiry_date', value: format(stock.expiry_date, DISPLAY_DATE_FORMAT) },
    {
      label: 'expiration',
      custom: (<Tag text={stock.expiration} color={getExpirationColor(stock.expiration)} />)
    },
  ];

  return (
    <>
      <SectionTitle
        titleKey="item_info"
        showEdit
        editKey={i18n.t('edit_info')}
        onEdit={onEdit}
      />
      <Spacer height={16} />
      <Grid container spacing="20px">
        {details.map((detail, index) => (
          <Grid item key={index} xs={6} md={4} xl={3}>
            <InfoCard
              isCustom={hasIn(detail, 'custom')}
              titleKey={detail.label}
              value={fetching || detail.value}
            >
              {!fetching && hasIn(detail, 'custom') ? detail.custom : <></>}
            </InfoCard>
          </Grid>
        ))}
      </Grid>
      <ValueEditModal
        label={i18n.t(`${editKey}_edit_label`)}
        showModal={showModal}
        onClose={handleOnEdit}
      />
    </>
  );
};

ItemInfo.propTypes = {
  onEdit: PropTypes.func
};

export default ItemInfo;
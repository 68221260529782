import React from 'react';
import LabHeader from 'components/global/LabHeader';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { packageShow } from 'redux/packages/actionCreators';
import PackageInfo from 'components/tests/testPackages/PackageInfo';
import i18n from 'translation/i18n';

const PackageDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const loadPackageInfo = () => {
    dispatch(packageShow(id));
  };

  React.useEffect(() => {
    loadPackageInfo();
  }, []);

  return (
    <>
      <LabHeader title={i18n.t('package')} />
      <PackageInfo />
    </>
  );
};

export default PackageDetails;

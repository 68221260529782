import React from 'react';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor} from 'utils/labUtils';

import {DataTable, Tag} from 'atoms';

export const ReservationTestsTable = () => {
  const {orderDetails} = useSelector(state => state.orders);

  const columns = [
    {
      field: 'service.name',
      headerName: i18n.t('test_name'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'service.lis_code',
      headerName: i18n.t('lis_code'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
    },
    {
      field: 'service.category_type',
      headerName: i18n.t('category'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
    },
    {
      field: 'service.specimen_type',
      headerName: i18n.t('type_of_specimen'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({row, field}) => valueGetter(row, field) && (
        <Tag
          text={i18n.t(valueGetter(row, field))}
          color={getStatusColor(valueGetter(row, field))}
        />
      )
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={valueGetter(orderDetails, 'orders') || []}
    />
  );
};

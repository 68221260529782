import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {HESN_STATUS, MODULE, ORDER_STATUS, PERMISSION} from 'models';
import { usePathname } from 'hooks';
import {
  cancelMasterOrderTest,
  updateMasterOrderTestDetails,
} from 'redux/lab/actionCreators';
import { resendHesn } from 'redux/orders/actionCreators';
import {
  getStatusColor,
  isLabClient,
  isLabUser,
  orderNextStatus,
  orderNextStatusForClinic,
} from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';
import { Toastr } from 'utils/Toastr';

import { Grid } from '@mui/material';
import { Button, Flex, LinkButton, Tag, Text, TextInput } from 'atoms';
import { CardSectionHeader, FieldInfoCardList } from 'templates';
import OrderStatusTransition from 'components/global/OrderStatusTransition';
import { ReactComponent as WarningIcon } from 'assets/icons/common/warning-hollow.svg';
import styles from 'templates/templates.module.css';
import {isEmpty} from 'lodash';
import SubTestTable from './SubTestTable';
import VerificationTag from './VerificationTag';

const ReservationTestInfo = ({ reload }) => {
  const dispatch = useDispatch();
  const { reservationTestInfo, reservationInfo } = useSelector(
    (state) => state.lab,
  );

  const [status, setStatus] = useState('');
  const [subtests, setSubtests] = useState([]);
  const [nextStatus, setNextStatus] = useState([]);
  const [hasHesn, setHasHesn] = useState(false);
  const { id: masterOrderId, test_id: testId } = useParams();
  const isOrderPage = usePathname().includes('orders');
  const isLab = isLabUser();
  const isClient = isLabClient();

  const handleMenuClick = ({ option, reason, refund }) => {
    if (option === ORDER_STATUS.CANCEL) {
      const payload = { reason, refund };
      dispatch(cancelMasterOrderTest(masterOrderId, testId, payload)).then(reload);
    } else {
      const payload = { order: { status: option } };
      dispatch(
        updateMasterOrderTestDetails(masterOrderId, testId, payload),
      ).then(reload);
    }
  };

  const source = valueGetter(reservationInfo, 'source');
  const orderIsAddedByClient = [MODULE.CLINIC, MODULE.COLLECTION_CENTER].includes(source);
  const masterOrderStatus = valueGetter(reservationTestInfo, 'status');
  const orderIsNotCollected = !isClient && ['confirmed', 'created'].includes(masterOrderStatus); // viewer is not a clinic
  const orderIsNotVerified =
    ['collected'].includes(masterOrderStatus) && valueGetter(reservationTestInfo, 'b2b_invoice') === false;
  const hideChangingStatus =
    orderIsAddedByClient && (orderIsNotCollected || orderIsNotVerified);

  const isSingleTest = valueGetter(reservationTestInfo, 'service.service_type') === 'single';
  const hesnStatus = valueGetter(reservationTestInfo, 'hesn_order.status');

  const resendingHesn = () => {
    return dispatch(resendHesn({ order_id: testId })).then((e) => {
      Toastr.success(e.value.data.message || 'HESN Sent');
      reload();
    });
  };

  const items = [
    {
      name: 'service.name',
      label: i18n.t('test_name'),
      placeholder: i18n.t('patient_name'),
      disabled: true,
    },
    {
      name: 'service.lis_code',
      label: i18n.t('lis_code'),
      placeholder: i18n.t('lis_code'),
      disabled: true,
    },
    {
      name: 'priority',
      label: i18n.t('priority'),
      placeholder: i18n.t('priority'),
      disabled: true,
    },
    isLab && isOrderPage && {
      name: 'batch_number',
      label: i18n.t('batch'),
      placeholder: i18n.t('batch'),
      disabled: true,
    },
    {
      name: 'service.specimen_types',
      label: i18n.t('type_of_specimen'),
      placeholder: i18n.t('type_of_specimen'),
      disabled: true,
      value: (valueGetter(reservationTestInfo, 'service.specimen_types') || [])
        .map((item) => item.name)
        .join(', '),
    },
    {
      custom: (
        <Grid key="test-status" item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('status')}
          </Text>
          <Flex itemsCenter>
            <Tag text={status} color={getStatusColor(status)} />
            {!hideChangingStatus && (
              <OrderStatusTransition
                showCancel
                options={nextStatus}
                onMenuClick={handleMenuClick}
              >
                <LinkButton
                  style={{ margin: '0 1rem' }}
                  label={i18n.t('change')}
                />
              </OrderStatusTransition>
            )}
          </Flex>
          {hideChangingStatus && (
            <div
              style={{
                padding: '0 1rem',
                backgroundColor: '#FFFBEB',
                borderRadius: '0.375rem',
                display: 'flex',
                alignItems: 'center',
                margin: '0.5rem 0',
              }}
            >
              <div style={{ flexShrink: '0' }}>
                <WarningIcon />
              </div>
              <p
                style={{
                  color: '#B45309',
                  fontSize: '0.875rem',
                  margin: '0 0.75rem',
                  padding: '0.5rem 0',
                }}
              >
                {orderIsNotCollected
                  ? i18n.t('not_collected_by_client')
                  : orderIsNotVerified && i18n.t('billing_not_verified')}
              </p>
            </div>
          )}
        </Grid>
      ),
    },
    {
      name: 'service.description',
      label: i18n.t('description'),
      placeholder: i18n.t('description'),
      disabled: true,
    },
    isSingleTest && {
      name: 'service.unit',
      label: i18n.t('unit'),
      placeholder: i18n.t('unit'),
      disabled: true,
    },
    isSingleTest && {
      name: 'service.precision',
      label: i18n.t('no_decimal'),
      placeholder: i18n.t('no_decimal'),
      disabled: true,
    },
    {
      name: 'service.category_type',
      label: i18n.t('category'),
      placeholder: i18n.t('category'),
      disabled: true,
    },
    isSingleTest && {
      name: 'test_details.0.overall_result',
      label: i18n.t('overall_result'),
      placeholder: i18n.t('overall_result'),
      disabled: true,
    },
    isSingleTest && {
      name: 'test_details.0.test_result',
      label: i18n.t('test_result'),
      placeholder: i18n.t('test_result'),
      disabled: true,
    },
    !isEmpty(reservationTestInfo) && {
      custom: (
        <Fragment key="verification-tag">
          <VerificationTag
            masterOrderId={masterOrderId}
            showVerifyButton={isOrderPage}
            type={PERMISSION.TECHNICAL_VERIFY}
            testInfo={reservationTestInfo}
            onRefetch={reload}
          />
          <VerificationTag
            masterOrderId={masterOrderId}
            showVerifyButton={isOrderPage}
            type={PERMISSION.MEDICAL_VERIFY}
            testInfo={reservationTestInfo}
            onRefetch={reload}
          />
        </Fragment>
      )
    },
    isOrderPage &&
      hasHesn && {
        custom: (
          <div key="hesn-status" style={{ display: 'flex', paddingTop: 7, paddingLeft: 10 }}>
            <TextInput
              name="hesn_order.status"
              label={i18n.t('hesn_status')}
              placeholder={i18n.t('hesn_status')}
              disabled
              className={styles.fieldInfoCard}
              value={hesnStatus}
            />
            {[
              HESN_STATUS.FAILED,
              HESN_STATUS.NOT_READY_YET,
              HESN_STATUS.PENDING,
            ].includes(hesnStatus) && (
              <div style={{ paddingTop: 35, paddingLeft: 10 }}>
                <Button
                  fitContent
                  variant="secondary"
                  label={i18n.t('push')}
                  onClick={resendingHesn}
                  size="small"
                />
              </div>
            )}
          </div>
        ),
      },
    isOrderPage &&
      hasHesn && {
        name: 'hesn_order.response.ValidCandidates.0.SwabID',
        label: i18n.t('swab_id'),
        placeholder: i18n.t('swab_id'),
        disabled: true,
      },
  ].filter(Boolean);

  useEffect(() => {
    const testStatus = valueGetter(reservationTestInfo, 'status');
    setStatus(testStatus);
    setHasHesn(valueGetter(reservationTestInfo, 'hesn_order.status'));
    const sub_services = valueGetter(reservationTestInfo, 'service.sub_services') || [];
    const sub_services_details = valueGetter(reservationTestInfo, 'test_details') || [];
    setSubtests(sub_services.map((s) => ({
      ...s,
      ...sub_services_details.find(d => d.serviceable_id === s.id)
    })));
    if (isOrderPage) {
      setNextStatus(
        isLab
          ? orderNextStatus(testStatus)
          : orderNextStatusForClinic[testStatus],
      );
    }
  }, [reservationTestInfo]);

  return (
    <>
      <Grid container spacing="16px" sx={{ padding: '16px' }}>
        <FieldInfoCardList list={items} />
      </Grid>
      {!isSingleTest && (
        <>
          <CardSectionHeader uppercased title={i18n.t('sub_tests')}/>
          <SubTestTable
            masterOrderId={masterOrderId}
            testId={testId}
            testStatus={status}
            subtests={subtests}
            refetch={reload}
          />
        </>
      )}
    </>
  );
};

ReservationTestInfo.propTypes = {
  reload: PropTypes.func,
};

export default ReservationTestInfo;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { getInvoices } from 'redux/billing/actionCreators';
import { dateQueryFormatToDate, dateToQueryFormat } from 'utils/date';
import { Grid } from '@mui/material';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import InvoiceTable from './InvoiceTable';
import InvoiceFilters from './InvoiceFilters';
// eslint-disable-next-line import/no-named-as-default-member
import InvoiceSummary from './InvoiceSummary';

const InvoiceContent = ({ client }) => {
  const dispatch = useDispatch();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(0);
  const { query, setQuery } = useURLQuery();

  const loadInvoices = (params = {}) => dispatch(getInvoices(params));

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      from_date: filter.from_date ? dateToQueryFormat(filter.from_date) : null,
      to_date: filter.to_date ? dateToQueryFormat(filter.to_date) : null,
    };
    setQuery(params);
    loadInvoices(params);
    setSelectedInvoiceId(0);
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: loadInvoices,
  });

  useEffect(() => {
    const params = {
      search: query?.search,
      from_date: query?.from_date
        ? dateQueryFormatToDate(query?.from_date)
        : null,
      to_date: query?.to_date ? dateQueryFormatToDate(query?.to_date) : null,
    };
    loadInvoices(params);
  }, []);

  const invoiceContent = (
    <>
      <ListFilters
        Wrapped={InvoiceFilters}
        client={client}
        onChange={handleFilterChange}
      />
      <InvoiceTable
        onRowClick={(id) => setSelectedInvoiceId(id)}
        onPagination={handlePagination}
      />
    </>
  );

  return (
    <Grid container spacing={isBrowser ? 4 : 0} mt={1} px={isBrowser ? 4 : 0}>
      <Grid item xs={12} lg={8}>
        {isMobile ? invoiceContent : <Card>{invoiceContent}</Card>}
      </Grid>
      <Grid item xs={12} lg={4} mt={isMobile ? 2 : 0} mx={isMobile ? 2 : 0}>
        <InvoiceSummary invoiceId={selectedInvoiceId} />
      </Grid>
    </Grid>
  );
};

InvoiceContent.propTypes = {
  client: PropTypes.string,
};

export default InvoiceContent;

import React from 'react';
import i18n from 'translation/i18n';
import {Card, TextInput , Spacer , Flex , Button} from 'atoms';
import {CardSectionHeader, ModalContent} from 'templates';

const TermsCard = () => {
  const [isDisabled, setIsDisabled] = React.useState(true);

  const handleClick = () => {
    if(isDisabled){
      return setIsDisabled(false);
    }
    return setIsDisabled(true);
  };

  return(
    <Card>
      <CardSectionHeader title={i18n.t('terms_and_conditions')} uppercased/>
      <ModalContent>
        <TextInput
          placeholder={i18n.t('terms_and_conditions')}
          as="textarea"
          inputStyle={{resize: 'none'}}
          disabled={isDisabled}
          value="Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae neque alias omnis modi architecto, incidunt natus vitae iure nemo ab quos quam vero laboriosam, cupiditate animi eos id praesentium totam.
          Dolores at minus est mollitia nostrum maxime atque assumenda cum quaerat reiciendis. Fuga sequi aut quidem quaerat, vel exercitationem modi. Consequatur asperiores laboriosam delectus, voluptatem excepturi vitae quam earum eius?
          Eius voluptatibus voluptate officiis harum vero aliquid dignissimos aspernatur ad soluta iste, facere expedita? Iusto vero nam molestias fuga qui, ratione repudiandae mollitia nesciunt velit obcaecati, distinctio voluptatibus doloremque sequi?
          Esse quod numquam, iure, asperiores fugit perspiciatis molestias perferendis molestiae ut tenetur consequuntur natus? Repudiandae alias doloremque, minima ad corrupti aliquid id illo vitae consectetur. Iusto facilis aspernatur quo in.
          Suscipit quas inventore mollitia eos officia ratione qui deleniti laudantium dolores, tempore a quisquam quod, repellendus minima. Perferendis ipsum suscipit voluptatum fuga at quaerat voluptas, repellat laudantium, quibusdam dolorem rerum."
        />
        <Spacer height={20}/>
        <Flex justifyEnd>
          <Button 
            label={i18n.t(isDisabled ? 'edit' : 'save')}
            variant='secondary'
            onClick={handleClick}
            fitContent
          />
        </Flex>
      </ModalContent>
    </Card>
  );
};

export default TermsCard;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import {
  Button,
  Spacer,
  Flex,
} from 'atoms';

import {
  TextInputField,
  DatePickerField,
} from 'components/global/final-form';
import {
  composeValidators,
  required,
  maxLength,
  minLength,
  enNumber,
} from 'utils/FormValidations';
import { addYears } from 'date-fns';

const PaymentFields = ({buttonText, datePlacement, isAdmin}) => {
  const {fetching} = useSelector(state => state.auth);

  return (
    <>
      <TextInputField
        name="payment.card_number"
        placeholder={i18n.t('digits')}
        label={i18n.t('card_number').toUpperCase()}
        validate={composeValidators(required, enNumber, maxLength(16), minLength(16))}
        maxLength={16}
      />
      <Spacer height={20} />

      <TextInputField
        name="payment.name_on_card"
        placeholder={i18n.t('name')}
        label={i18n.t('name_on_card').toUpperCase()}
        validate={required}
      />
      <Spacer height={20} />

      <Flex>
        <DatePickerField
          name="payment.expiry"
          placeholderText='MM/YYYY'
          label={i18n.t('expiry').toUpperCase()}
          displayFormat="MM/yyyy"
          validate={required}
          placement={datePlacement}
          minDate={(new Date())}
          maxDate={addYears(new Date(), 10)}
        />
        <Spacer width={16} />
        <TextInputField
          name="payment.cvv"
          placeholder={i18n.t('cvv')}
          label={i18n.t('cvv').toUpperCase()}
          validate={composeValidators(required, enNumber, maxLength(3), minLength(3))}
          maxLength={3}
        />
      </Flex>
      <Spacer height={isAdmin ? 10 : 60} />
      {isAdmin 
        ? <Spacer height={8}/> 
        : <Button label={buttonText || i18n.t('create_lab')} disabled={fetching} type="submit" />
      }
    </>
  );
};

PaymentFields.propTypes = {
  buttonText: PropTypes.string,
  datePlacement: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default PaymentFields;

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';
import {isArray} from 'lodash';

import i18n from 'translation/i18n';
import {testsLoad} from 'redux/tests/actionCreators';
import {required} from 'utils/FormValidations';
import {getListForSelect} from 'utils/labUtils';

import {ReactComponent as CloseIcon} from 'assets/icons/common/cross-rounded.svg';
import {Grid} from '@mui/material';
import {Card} from 'atoms';
import {SelectField} from 'components/global/final-form';
import PatientPackageTestForm from './PatientPackageTestForm';

const PatientPackageForm = ({ index, values, showClose, onClose }) => {
  const { change } = useForm();
  const { packages } = useSelector((state) => state.patient);
  const [services, setServices] = useState([]);

  const getServiceType = async (id, priority) => {
    const { types } = (await testsLoad({id}).payload).data.services[0];
    return types.find((t) => t.priority === priority);
  };

  const handleSelectPackage = async (id) => {
    const selectedPackage = packages.find((p) => p.id === id);
    if (!selectedPackage) return;
    const queries = selectedPackage.services.map(
      service => getServiceType(service.id, selectedPackage.priority)
    );
    Promise.all(queries).then((res) => {
      const selectedPackageServices = res.map((type, i) => {
        const service = selectedPackage.services[i];
        return {
          priority: type.priority,
          priority_id: type.id,
          service_id: service.id,
          category_type: service.category_type,
          name: service.name,
        };
      });
      setServices([...selectedPackageServices]);
      change(`orders_package.${index}.services`, [...selectedPackageServices]);
    });
  };

  useEffect(() => {
    if (isArray(values?.orders_package) && values.orders_package.length > 0) {
      handleSelectPackage(values?.orders_package[index].package_id);
    }
  }, []);

  return (
    <Card
      styles={{
        padding: '1rem',
        paddingTop: '2rem',
        marginBottom: '0.5rem',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', top: '8px', right: '8px' }}>
        {showClose && <CloseIcon onClick={onClose} />}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <SelectField
            name={`orders_package.${index}.package_id`}
            label={i18n.t('package')}
            placeholder={i18n.t('package')}
            options={getListForSelect(packages)}
            validate={required}
            maxMenuHeight={320}
            onChange={handleSelectPackage}
          />
        </Grid>
      </Grid>
      {services.map((test, testIndex) => (
        <PatientPackageTestForm
          values={test}
          key={testIndex}
          testIndex={testIndex}
          index={index}
        />
      ))}
    </Card>
  );
};

PatientPackageForm.propTypes = {
  values: PropTypes.shape(),
  index: PropTypes.number,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PatientPackageForm;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Text from 'atoms/Text';
import Spacer from 'atoms/Spacer';
import buttonStyles from './Button.module.css';

const textClasses = {
  large: 'subtitle',
  medium: 'paragraph14',
  small: 'caption12',
};

const variantColors = {
  primary: 'var(--color-natural-white-default-new)',
  secondary: 'var(--color-brand-primary-default-new)',
  danger: 'var(--color-brand-primary-default-new)',
};

const Button = React.forwardRef(({
  icon: IconComponent,
  startIcon,
  endIcon,
  locale,
  label,
  size,
  color,
  className,
  variant,
  divider,
  isFocusRingVisible,
  loading,
  disabled,
  fitContent,
  style,
  ...rest
}, ref) => {
  const btnVariant = React.useMemo(() => (['primary', 'secondary', 'danger'].includes(variant) ? variant : 'primary'), [variant]);

  const renderIcon = (icon) => {
    if (typeof icon === 'function') return icon(variantColors[variant]);
    return icon;
  };

  function handleContent() {
    if (IconComponent) return IconComponent;
    return (
      <>
        {startIcon && (
          <>
            {renderIcon(startIcon)}
            {label && <Spacer width={8} />}
          </>
        )}
        <Text
          as="span"
          weight="medium"
          typography={textClasses[size]}
          className={buttonStyles.label}
        >
          {label}
        </Text>
        {endIcon && (
          <>
            {divider && <div className={buttonStyles.divider} />}
            {label && <Spacer width={8} />}
            {renderIcon(endIcon)}
          </>
        )}
      </>
    );
  }

  return (
    <button
      {...rest}
      ref={ref}
      disabled={loading || disabled}
      className={clsx(
        buttonStyles.btn,
        buttonStyles[size],
        buttonStyles[btnVariant],
        isFocusRingVisible && buttonStyles.focusRing,
        IconComponent && buttonStyles.iconBtn,
        startIcon && buttonStyles.withStartIcon,
        endIcon && buttonStyles.withEndIcon,
        className,
      )}
      style={{
        width: fitContent ? 'fit-content' : '100%', 
        padding: '16px 35px',
        ...style,
      }}
    >
      {handleContent()}
    </button>
  );
});

Button.defaultProps = {
  label: '',
  size: 'medium',
  locale: 'en',
  color: 'blue',
  variant: 'primary',
  disabled: false,
  type: 'button',
};

Button.propTypes = {
  isFocusRingVisible: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  locale: PropTypes.oneOf(['ar', 'en']),
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'slateGrey']),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  type: PropTypes.string,
  divider: PropTypes.bool,
  fitContent: PropTypes.bool,
  style: PropTypes.shape(),
};

export default Button;

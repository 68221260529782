import React from 'react';

import i18n from 'translation/i18n';
import {
	composeValidators,
	email,
	enNumber,
	maxLength,
	minLength,
	required,
	shouldStartWith
} from 'utils/FormValidations';

import { Spacer } from 'atoms';
import { DatePickerField, TextInputField } from 'components/global/final-form';

const Step3 = () => (
		<>
      <TextInputField
				name="supplier_vendor_name"
				label={i18n.t('supplier_name')}
				placeholder={i18n.t('supplier_name')}
				validate={required}
      />
      <Spacer height={4} />
      <TextInputField
				name="supplier_vendor_phone"
				label={i18n.t('supplier_phone')}
				placeholder={i18n.t('supplier_phone')}
				maxLength={9}
				validate={
				  composeValidators(
						required, enNumber, shouldStartWith(5), maxLength(9), minLength(9)
					)
			  }
      />
			<Spacer height={4} />
      <TextInputField
				name="supplier_contact_email"
				label={i18n.t('supplier_email')}
				placeholder={i18n.t('supplier_email')}
				validate={email}
      />
      <Spacer height={4} />
      <DatePickerField
				name="purshase_date"
				label={i18n.t('purchase_date')}
				placeholder={i18n.t('purchase_date')}
				displayFormat="dd MMM yyyy"
				maxDate={new Date()}
				validate={required}
      />
    </>
);

export default Step3;
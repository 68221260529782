import React from 'react';
import {useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {required} from 'utils/FormValidations';
import {getListForSelect} from 'utils/labUtils';
import {SYMPTOMS} from 'utils/options';

import {Grid} from '@mui/material';
import {Card, TextInput} from 'atoms';
import {SelectField} from 'components/global/final-form';
import {ReactComponent as CloseIcon} from 'assets/icons/common/cross-rounded.svg';

const PatientPackageTestForm = ({
  index,
  testIndex,
  values,
  showClose,
  onClose,
}) => {
  const { type_of_specimens, reason_for_testings } = useSelector(
    (state) => state.patient,
  );
  const isCovid = values.name.replace('-', '').toLowerCase().includes('covid19');

  return (
    <Card
      styles={{
        padding: '1rem',
        paddingTop: '2rem',
        marginBottom: '0.5rem',
        position: 'relative',
      }}
    >
      <div style={{position: 'absolute', top: '8px', right: '8px'}}>
        {showClose && <CloseIcon onClick={onClose}/>}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInput
            value={values.category_type}
            label={i18n.t('category')}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInput
            value={values.name}
            label={i18n.t('test_type')}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <TextInput
            value={values.priority}
            label={i18n.t('test_priority')}
            disabled
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <SelectField
            name={`orders_package.${index}.services.${testIndex}.type_of_specimen`}
            label={i18n.t('type_of_specimen')}
            placeholder={i18n.t('type_of_specimen')}
            options={getListForSelect(type_of_specimens)}
            validate={required}
            maxMenuHeight={320}
          />
        </Grid>
        {isCovid && (
          <>
            <Grid item xs={isMobile ? 12 : 4}>
              <SelectField
                name={`orders_package.${index}.services.${testIndex}.sign_and_symptoms`}
                label={i18n.t('signs_and_symptoms')}
                placeholder={i18n.t('signs_and_symptoms')}
                options={SYMPTOMS}
                validate={required}
                maxMenuHeight={320}
              />
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <SelectField
                name={`orders_package.${index}.services.${testIndex}.reason_for_testing`}
                label={i18n.t('reason_for_testing')}
                placeholder={i18n.t('reason_for_testing')}
                options={getListForSelect(reason_for_testings)}
                validate={required}
                maxMenuHeight={320}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

PatientPackageTestForm.propTypes = {
  values: PropTypes.shape(),
  index: PropTypes.number,
  testIndex: PropTypes.number,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PatientPackageTestForm;

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { formattedPrice, isAdminUser } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';

import { Grid } from '@mui/material';
import { Flex, Spacer, Switch, Text } from 'atoms';
import { USER_STATUS } from 'models';
import { packageShow, packageUpdate } from 'redux/packages/actionCreators';
import { FieldInfoCardList } from 'templates';
import React from 'react';
import { PRIORITIES } from 'utils/options';

const GeneralPackageInfo = ({ editing }) => {
  const dispatch = useDispatch();
  const { onePackage } = useSelector((state) => state.packages);
  const isAdmin = isAdminUser();

  const handleStatusChange = (value) => {
    const payload = {
      status: value ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE,
    };
    dispatch(packageUpdate(onePackage.id, payload)).then(() => {
      dispatch(packageShow(onePackage.id));
    });
  };

  const items = [
    {
      name: 'name',
      label: i18n.t('package_name'),
      placeholder: i18n.t('package_name'),
      disabled: !editing,
      value: valueGetter(onePackage, 'name'),
    },
    ...(!isAdmin
      ? [
          {
            name: 'total_price',
            label: i18n.t('price'),
            placeholder: i18n.t('price'),
            disabled: !editing,
            value: formattedPrice(valueGetter(onePackage, 'total_price')),
          },
          {
            name: 'discount',
            label: i18n.t('discount'),
            placeholder: i18n.t('discount'),
            disabled: !editing,
            value: formattedPrice(valueGetter(onePackage, 'discount')),
          },
        ]
      : []),
    {
      name: 'description',
      label: i18n.t('description'),
      placeholder: i18n.t('description'),
      disabled: !editing,
      value: formattedPrice(valueGetter(onePackage, 'description')),
    },
    {
      name: 'priority',
      label: i18n.t('priority'),
      placeholder: i18n.t('priority'),
      disabled: !editing,
      value: valueGetter(onePackage, 'priority'),
      as: 'dropdown',
      options: PRIORITIES,
    },
    {
      custom: (
        <Flex flexCol justifyCenter itemsCenter style={{ padding: '18px' }}>
          <Text
            typography="caption12"
            as="span"
            weight="bold"
            color="var(--brand-text-gray-light)"
            textCase="uppercase"
          >
            {i18n.t('status')}
          </Text>
          <Spacer height={16} />
          <Switch
            name="status"
            checked={valueGetter(onePackage, 'status') === USER_STATUS.ACTIVE}
            onChange={(value) => handleStatusChange(value)}
            disabled={!editing}
          />
        </Flex>
      ),
    },
  ];

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing="16px"
      sx={{ padding: '16px' }}
    >
      <FieldInfoCardList
        list={items}
        girdSizes={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 3 }}
      />
    </Grid>
  );
};

GeneralPackageInfo.propTypes = {
  editing: PropTypes.bool,
};

export default GeneralPackageInfo;

import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import {createSpecimenType} from 'redux/admin/actionCreators';

import CreateSpecimenTypeModal from './CreateSpecimenTypeModal';

const CreateSpecimenType = ({reload}) => {
  const dispatch = useDispatch();

  const handleOnSubmit = (values) => {
      dispatch(createSpecimenType({specimen_type: values})).then(() => {
        reload();
      });
  };

  return(
    <CreateSpecimenTypeModal
      initialValues={{}}
      onSubmit={handleOnSubmit}
    />
  );
};

CreateSpecimenType.propTypes = {
  reload: PropTypes.func
};

export default CreateSpecimenType;
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {
  composeValidators,
  email,
  enNumber,
  maxLength, minLength,
  required,
  shouldStartWith
} from 'utils/FormValidations';

import {Flex, Spacer, Switch, Text} from 'atoms';
import {SelectField, TextInputField} from 'components/global/final-form';
import {USER_ROLE} from '../../../../models';

const Step1 = ({ isEdit, form, values, onChangePassword }) => {
  const [roles, setRoles] = useState([]);
  const {userRoles} = useSelector(state => state.lab);

  useEffect(() => {
    const mappedRoles = userRoles
      .map(role => ({value: role, label: i18n.t(role)}))
      .filter(role => role.value !== USER_ROLE.LAB_ADMIN);
    setRoles(mappedRoles);
  }, [userRoles]);

  return (
    <>
      <TextInputField
        name="name_en"
        label={i18n.t('user_name_en')}
        placeholder={i18n.t('user_name_en')}
        validate={required}
      />
      <Spacer height={8}/>
      <TextInputField
        name="name_ar"
        label={i18n.t('user_name_ar')}
        placeholder={i18n.t('user_name_ar')}
        validate={required}
      />
      <Spacer height={8}/>
      <SelectField
        name="role"
        label={i18n.t('role')}
        placeholder={i18n.t('choose_role')}
        options={roles}
        validate={required}
        onChange={() => {
          form.change('permissions', null);
        }}
      />
      <Spacer height={16}/>
      <TextInputField
        name="email"
        label={i18n.t('email')}
        placeholder={i18n.t('email_address')}
        validate={composeValidators(required, email)}
      />
      <Spacer height={8}/>
      <TextInputField
        name="mobile"
        label={i18n.t('phone_number')}
        placeholder={i18n.t('phone')}
        maxLength={9}
        validate={
          composeValidators(
            required, enNumber, shouldStartWith(5), maxLength(9), minLength(9)
          )
        }
      />
      <Spacer height={8}/>
      {isEdit && (
        <TextInputField
          name="password"
          type="password"
          label={i18n.t('password')}
          placeholder="**********"
          disabled
          endIcon={
            <Text
              typography="caption13"
              color="var(--brand-text-primary)"
              weight="bold"
              onClick={onChangePassword}
            >
              {i18n.t('change')}
            </Text>
          }
        />
      )}
      {isEdit && (
        <>
          <Spacer height={8}/>
          <Flex itemsCenter justifyBetween>
            <Text typography="caption12" weight="bold" color="var(--brand-text-gray-light)">
              {i18n.t('user_activation').toUpperCase()}
            </Text>
            <Switch
              checked={values.status}
              onChange={(val) => form.change('status', val)}
            />
          </Flex>
        </>
      )}
    </>
  );
};

Step1.propTypes = {
  isEdit: PropTypes.bool,
  form: PropTypes.shape(),
  values: PropTypes.shape(),
  onChangePassword: PropTypes.func,
};

export default Step1;
import React, {useEffect} from 'react';
import i18n from 'translation/i18n';
import {Grid} from '@mui/material';

import {
  CardContainer,
  CardSectionHeader,
  FieldInfoCardList,
  ModalContent,
} from 'templates';
import {profileInfo} from 'utils/admin';
import {getUserProfile} from 'redux/profile/actionCreators';
import {useDispatch, useSelector} from 'react-redux';

const PatientProfileInfo = () => {
  const dispatch = useDispatch();
  const {userProfile} = useSelector(state => state.profile);
  const info = profileInfo(true, userProfile);

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <ModalContent>
      <CardContainer noPadding style={{width: '100%'}}>
        <CardSectionHeader title={i18n.t('profile')} uppercased/>
        <ModalContent>
          <Grid container spacing='16px' sx={{padding: '16px'}}>
            <FieldInfoCardList list={info.general} as="text"/>
          </Grid>
        </ModalContent>
      </CardContainer>
    </ModalContent>
  );
};

export default PatientProfileInfo;

import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import {InfoCard} from 'templates';

const InfoCards = ({items}) => {
  return (
    <>
      {items.map(item => !item.hide && (
        <Grid item xl={3} md={4} xs={6} key={item.key}>
          <InfoCard titleKey={item.key} value={item.value}/>
        </Grid>
      ))}
    </>
  );
};

InfoCards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape())
};

export default InfoCards;

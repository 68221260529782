import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { TextInput } from 'atoms';

import { ReactComponent as Search } from 'assets/icons/common/search-gray.svg';
import './style.scss';
import { useURLQuery } from 'hooks/useURLQuery';

export const SearchInput = ({ onChange, ...rest }) => {
  const { query, pathname } = useURLQuery();
  const [search, setSearch] = useState(query.search || rest.value || '');
  const onChangeDebounced = useCallback(
    debounce((e) => onChange(e.target.value), 1000),
    [debounce, query, pathname],
  );

  return (
    <TextInput
      inputClass="search-input"
      startIcon={<Search />}
      inputStyle={{ borderRadius: '2rem' }}
      onChange={(e) => {
        onChangeDebounced(e);
        setSearch(e.target.value);
      }}
      {...rest}
      value={search}
    />
  );
};

SearchInput.defaultProps = {
  onChange: () => {},
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { parse } from 'date-fns';

import i18n from 'translation/i18n';
import { getChartDataForReport } from 'redux/report/actionCreators';

import { Box } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { chartsGridClasses } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Card, LinkButton, Text } from 'atoms';
import './styles.scss';

export const TotalPayments = ({range}) => {
  const dispatch = useDispatch();
  const duration = (range === 'custom' ? 'week' : range).concat('ly');
  const {push} = useHistory();
  const {chartData} = useSelector(state => state.report);
  const dataset = useMemo(() => {
    const invoices = chartData.sales || {};
    return Object.keys(invoices).map(key => ({
      xLabel: duration !== 'monthly' ?
        key :
        parse(key.replace(/\s+/g, ' '), 'MMM d, yyyy', new Date()).getDate().toString(),
      label: key,
      value: Number(invoices[key])
    }));
  }, [chartData]);

  const xAxisFormatter = (label, context) => {
    if (context.location === 'tick') return label;
    return dataset.find(d => d.xLabel === label).label;
  };

  const yAxisFormatter = (value) => {
    if (value >= 1000) return `${value / 1000}K`;
    if (value >= 1000000) return `${value / 1000000}M`;
    return value;
  };

  const getChartData = () => {
    dispatch(getChartDataForReport('sales', {filter: duration}));
  };

  useEffect(() => {
    getChartData();
  }, [duration]);

  return (
    <>
      <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">{i18n.t('overview_payments_title')}</Text>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <Text typography={isMobile ? 'caption12' : 'caption13'} style={{opacity: 0.4}}>
          {i18n.t('overview_payments_subtitle', { duration })}
        </Text>
        <LinkButton
          label={i18n.t('view_all')}
          onClick={() => push('/reports/sales')}
        />
      </Box>
      <Card styles={{ marginTop: '1rem', paddingX: '1rem' }}>
        <LineChart
          sx={{
            '& .MuiAreaElement-series-payments': { fill: 'url(\'#gradient-color\')' },
            [`& .${chartsGridClasses.line}`]: { strokeDasharray: '5 3', strokeWidth: 1, fill: '#EDEEEF' },
            [`& .${axisClasses.line}`]: { display: 'none' },
            [`& .${axisClasses.tick}`]: { display: 'none' },
            [`& .${axisClasses.directionY}`]: { transform: 'translate(1rem, 0)' },
            [`& .${axisClasses.directionY} .${axisClasses.tickLabel}`]: { fill: '#ADB1B2', textAnchor: 'start' },
            [`& .${axisClasses.directionX} .${axisClasses.tickLabel}`]: { fontSize: '13px', fill: '#383838' }
          }}
          dataset={dataset}
          colors={['#5E72EB']}
          series={[{id: 'payments', dataKey: 'value', area: true, showMark: false}]}
          xAxis={[{scaleType: 'point', dataKey: 'xLabel', valueFormatter: xAxisFormatter}]}
          yAxis={[{valueFormatter: yAxisFormatter}]}
          height={300}
          grid={{vertical: true}}
        >
          <defs>
            <linearGradient id="gradient-color" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#5E72EB"/>
              <stop offset="50%" stopColor="#CCD3FF"/>
              <stop offset="100%" stopColor="#FFFFFF"/>
            </linearGradient>
          </defs>
        </LineChart>
      </Card>
    </>
  );
};

TotalPayments.propTypes = {
  range: PropTypes.string
};
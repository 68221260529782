import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { registerLab } from 'redux/auth/actionCreators';
import { getPlans } from 'redux/admin/actionCreators';

import { CreateLabModal } from '.';

const CreateNewLab = ({reload}) => {
  const dispatch = useDispatch();
  const handleOnSubmit = (values) => dispatch(registerLab(values));

  React.useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <CreateLabModal initialValues={{}} onSubmit={handleOnSubmit} reload={reload}/>
  );
};

CreateNewLab.propTypes = {
  reload: PropTypes.func,
};

export default CreateNewLab;

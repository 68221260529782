import { Card } from 'atoms';
import LabHeader from 'components/global/LabHeader';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { indexMasterOrderOrders } from 'redux/lab/actionCreators';
import { CardContainer } from 'templates';
import i18n from 'translation/i18n';
import { ReservationTestsTable } from '../ReservationTestsTable';

export const ReservationPackageInfoForm = () => {
  const dispatch = useDispatch();
  const { package_id, id } = useParams();

  const loadTestDetails = () => {
    dispatch(indexMasterOrderOrders(id, { package_id }, 'patients'));
  };

  React.useEffect(() => {
    loadTestDetails();
  }, []);

  const content = (
    <>
      <CardContainer
        title={i18n.t('reservation_info_packages_title')}
        noPadding
        showBackButton
      >
        <ReservationTestsTable canEditResult refetch={loadTestDetails} />
      </CardContainer>
    </>
  );

  return (
    <>
      <LabHeader title="reservations" />
      {isMobile ? content : <Card styles={{ margin: '2rem' }}>{content}</Card>}
    </>
  );
};

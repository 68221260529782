import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { USER_ROLE } from 'models';
import i18n from 'translation/i18n';
import { getAuthUser, setAuthUserForAdmin } from 'redux/auth/actionCreators';
import { getLabDetails } from 'redux/general/actionCreators';
import {getUserProfile} from 'redux/profile/actionCreators';
import {
  ACCESS_TOKEN,
  SLUG,
  EN_LABEL,
  RTL_LABEL,
  LTR_LABEL,
  ADMIN_LABEL,
  LAB_LABEL,
  SUPER_ADMIN,
} from 'utils/constants';
import { isHostContainSlug } from 'utils/uiHelpers';

import AdminLayout from 'layouts/AdminLayout';
import LabLayout from 'layouts/LabLayout';
import PatientLayout from 'layouts/PatientLayout';
import 'App.css';

// Routes
import PublicRoutes from './PublicRoutes';

const { language } = i18n;

const RootRouter = () => {
  const dispatch = useDispatch();
  const isLogged = !!localStorage?.[ACCESS_TOKEN];
  const hasSlug = !!localStorage?.[SLUG];
  const user = getAuthUser();
  const isAdmin = user?.role_label === USER_ROLE.SUPER_ADMIN;

  const loggedLayout = {
    lab: <LabLayout />,
    clinic: <LabLayout />,
    collection_center: <LabLayout />,
    patient: <PatientLayout />,
    admin: <AdminLayout />,
  };

  useEffect(() => {
    const subdomain = window.location.host.split('.')[0];
    if (subdomain === ADMIN_LABEL) {
      setAuthUserForAdmin({
        ...user,
        role_label: subdomain === ADMIN_LABEL ? SUPER_ADMIN : LAB_LABEL,
      });
    }

    if (
      subdomain !== ADMIN_LABEL &&
      subdomain !== 'www' &&
      isHostContainSlug()
    ) {
      dispatch(getLabDetails());
    }

    if (isLogged) {
      dispatch(getUserProfile());
    }
  }, []);

  return (
    <div style={{ direction: language === EN_LABEL ? LTR_LABEL : RTL_LABEL }} lang={language === EN_LABEL ?'en':'ar'}>
      {(isAdmin || hasSlug) && isLogged ? (
        loggedLayout[user.module] || <span />
      ) : (
        <PublicRoutes />
      )}
    </div>
  );
};

export default withRouter(RootRouter);

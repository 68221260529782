import { format as formatFns, formatDistanceToNow, parse } from 'date-fns';
import { arSA, enUS } from 'date-fns/locale';
import { toGregorian } from 'hijri-converter';
import { LOCALE } from 'utils/constants';

export const DISPLAY_DATE_FORMAT = 'dd MMM yyyy';
export const DISPLAY_TIME_FORMAT = 'hh:mm a';
export const DISPLAY_DATE_TIME_FORMAT = 'dd MMM yyyy - hh:mm a';
const locale = { en: enUS, ar: arSA };
const enFormat = ['yyyy-MM-dd', 'yyyy/MM/dd'];
const language = localStorage.getItem(LOCALE) || 'en';

export function format(date, formatStr) {
  if (!date) return '';
  let newDate = date;
  if (typeof newDate === 'string') {
    newDate = new Date(date);
  }
  if (enFormat.includes(formatStr)) {
    return formatFns(newDate, formatStr);
  }
  return formatFns(newDate, formatStr, {
    locale: locale[language],
  });
}

export const getDateSplit = (fullDate) => {
  const splitDate = fullDate.split('-');
  const day = splitDate[0];
  const month = splitDate[1];
  const year = splitDate[2];
  return {
    year,
    month,
    day,
  };
};

export const isDateMatch = (d) => {
  const dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/;
  return `${d}`.match(dateReg) !== null;
};

export const getTwoDigits = (n) => `0${n}`.slice(-2);

export const getInt = (numString) => parseInt(numString, 10);

export const HijriToGregorian = (dateString) => {
  const { year, month, day } = getDateSplit(dateString);
  const { gd, gm, gy } = toGregorian(getInt(year), getInt(month), getInt(day));
  return `${getTwoDigits(gd)}-${getTwoDigits(gm)}-${gy}`;
};

export const dateQueryFormatToDate = (date) =>
  parse(date, 'dd/MM/yyyy', new Date());
export const dateToQueryFormat = (date) => formatFns(date, 'dd/MM/yyyy');
export const dateTimeQueryFormatToDate = (date) =>
  parse(date, 'dd/MM/yyyy HH:mm', new Date());
export const dateTimeToQueryFormat = (date) =>
  formatFns(date, 'dd/MM/yyyy HH:mm');

export const duration = (date) =>
  formatDistanceToNow(new Date(date), { locale: locale[language] });

import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {isMobile} from 'react-device-detect';

import i18n from 'translation/i18n';
import {getUserProfile, updateUserProfile} from 'redux/profile/actionCreators';

import {Grid} from '@mui/material';
import LabHeader from 'components/global/LabHeader';
import ProfileForm from 'components/Profile/user';

const UserProfile = () => {
  const dispatch = useDispatch();

  const handleSave = (values) => {
    dispatch(updateUserProfile(values))
      .then(() => {
        getUserProfile();
      });
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <>
      <LabHeader title={i18n.t('user_profile')}/>
      <Grid container spacing={isMobile ? 2 : 4} p={isMobile ? 2 : 4}>
        <Grid item xs={12}>
          <ProfileForm onSave={handleSave}/>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfile;

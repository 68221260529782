const Billing = {
  // *
    
  // A

  // B
  b2b: 'B2B',
  b2c: 'B2C',
  billing_list_title: 'All Billing',
  billing_list_subtitle: 'Overview of all bills',
  billing_search_input_placeholder: 'Enter sample or batch number',

  // C
  client: 'Client',
  clinic_name: 'Clinic Name',

  // D
    
  // E
    
  // F
    
  // G
    
  // H
    
  // I
  invoice_list_title: 'All Invoice',
  invoice_list_subtitle: 'Overview of all invoices',
  invoice_number: 'Invoice Number',
  invoice_date: 'Invoice Date',
  invoice_search_input_placeholder: 'Search by invoice number',
  invoice_type: 'Invoice Type',
    
  // J
    
  // K
    
  // L
    
  // M
    
  // N
  no_of_samples: 'No. of samples',

  // O
  outsourcing: 'Outsourcing',

  // P
  payment_refunded: 'Refunded',
  payment_status: 'Payment Status',
  payment_verified: 'Verified',
  paid_amount: 'Paid Amount',

  // Q
    
  // R
  refunded_amount: 'Refunded Amount',
  refunded_b2b: 'Refunded B2B',
  refunded_b2c: 'Refunded B2C',

  // S
  sample: 'Sample',
  sample_cost: 'Sample cost',

  // T
  total_amount: 'Total Amount',

  // U
    
  // V
  verify_payment: 'Verify payment',
  // W
    
  // X
    
  // Y
    
  // Z
    
};
    
export default Billing;
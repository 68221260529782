import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';

import { Text, Flex, Spacer } from 'atoms';
import { ReactComponent as Logo } from 'assets/icons/logo/labby-logo-eclipse.svg';
import { ReactComponent as Brand } from 'assets/icons/logo/labby-logo-name.svg';
import styles from './LoginTemplate.module.css';

const LoginTemplate = ({
  subTitle,
  children,
  title,
}) => (
  <Flex
    itemsCenter
    justifyCenter
    className={clsx(isMobile ? styles.mobileContainer : styles.container)}
  >
    <Flex flexCol itemsCenter className={clsx(styles.card)}>
      <Flex flexCol itemsCenter className={clsx(styles.content)}>
        <Flex itemsCenter justifyCenter>
          <Logo />
          <Spacer width={16} />
          <Brand />
        </Flex>
        <Spacer height={32} />
        {title && (
          <>
            <Flex flexCol justifyCenter itemsCenter>
              <Text typography="display36">{title}</Text>
              <Spacer height={8} />
              <Text typography="paragraph16" color="var(--brand-text-gray-light)">
                {subTitle}
              </Text>
              <Spacer height={32} />
            </Flex>
          </>
        )}
        {children}
      </Flex>
    </Flex>
  </Flex>
);

LoginTemplate.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
};

export default LoginTemplate;

import { Button, Spacer } from 'atoms';
import { SelectField, TextInputField } from 'components/global/final-form';
import i18n from 'translation/i18n';
import { composeValidators, number, required } from 'utils/FormValidations';
import { PACKAGE_STATUSES, PRIORITIES } from 'utils/options';
import React from 'react';
import { isAdminUser } from 'utils/labUtils';

const CreatePackageStepOne = () => {
  const isAdmin = isAdminUser();
  return (
    <>
      <TextInputField
        name="name"
        placeholder={i18n.t('package_name')}
        label={i18n.t('package_name').toUpperCase()}
        validate={required}
      />
      <Spacer height={12} />
      <TextInputField
        name="description"
        placeholder={i18n.t('description')}
        label={i18n.t('description').toUpperCase()}
        validate={required}
      />
      {!isAdmin && (
        <>
          <Spacer height={12} />
          <TextInputField
            name="discount"
            placeholder={i18n.t('discount')}
            label={i18n.t('discount').toUpperCase()}
            validate={composeValidators(required, number)}
          />
        </>
      )}
      <Spacer height={12} />
      <SelectField
        name="priority"
        placeholder={i18n.t('priority')}
        label={i18n.t('priority').toUpperCase()}
        validate={required}
        options={PRIORITIES}
      />
      <Spacer height={12} />
      <SelectField
        name="status"
        placeholder={i18n.t('status')}
        label={i18n.t('status').toUpperCase()}
        validate={required}
        options={PACKAGE_STATUSES}
      />
      <Spacer height={12} />
      <Button type="submit" label={i18n.t('next')} />
    </>
  );
};

export default CreatePackageStepOne;

/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {usePathname, useQuery} from 'hooks';
import {ORDER_STATUS, PERMISSION, TEST_RESULT} from 'models';
import {
  getMasterReservationDetails,
  getMasterReservationTestDetails,
} from 'redux/lab/actionCreators';
import { sendMasterOrderTestResult } from 'redux/orders/actionCreators';
import i18n from 'translation/i18n';
import { TEST_RESULTS } from 'utils/options';
import { valueGetter } from 'utils/uiHelpers';
import { userHasPermission } from 'utils/permissionUtils';

import { Grid } from '@mui/material';
import { Button, Card } from 'atoms';
import { CardContainer, ModalContent, SectionTitle } from 'templates';
import { SelectField, TextInputField } from 'components/global/final-form';
import LabHeader from 'components/global/LabHeader';
import OrderLogs from './OrderLogs';
import ReservationTestInfo from './ReservationTestInfo';

export const ReservationTestInfoForm = () => {
  const dispatch = useDispatch();
  const { test_id, id } = useParams();
  const { isOverdue } = useQuery();
  const { push } = useHistory();
  const { reservationTestInfo } = useSelector((state) => state.lab);
  const serviceCode = valueGetter(reservationTestInfo, 'service.code');
  const status = valueGetter(reservationTestInfo, 'status');
  const isSingleTest = valueGetter(reservationTestInfo, 'service.service_type') === 'single';
  const isOrderPage = usePathname().includes('orders');
  const showEnterResult =
    isSingleTest &&
    isOrderPage &&
    userHasPermission(PERMISSION.UPDATE_RESULT) &&
    [ORDER_STATUS.PROCESS, ORDER_STATUS.READY].includes(status);

  const loadTestDetails = () => {
    dispatch(getMasterReservationTestDetails(id, test_id));
    dispatch(getMasterReservationDetails(id));
  };

  const onSubmitResult = (values) => {
    const payload = {
      order: {
        result:
          typeof values === 'string'
            ? values
            : valueGetter(values, 'test_detail.result'),
      },
    };
    dispatch(sendMasterOrderTestResult(id, test_id, payload)).then(() =>
      loadTestDetails(),
    );
  };

  React.useEffect(() => {
    loadTestDetails();
  }, []);

  const resultOptions = TEST_RESULTS.filter(
    (option) => option.value !== TEST_RESULT.PENDING,
  );

  const content = (
    <>
      <CardContainer
        title={i18n.t('reservation_info_test_title')}
        cardActions={isOverdue && <Button label={i18n.t('to_master_order')} variant="secondary" onClick={() => push(`/orders/${id}`)} />}
        noPadding
        showBackButton
      >
        <Form onSubmit={() => {}} initialValues={reservationTestInfo}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SectionTitle titleKey="test_information" />
              <ReservationTestInfo reload={loadTestDetails} />
              {showEnterResult && (
                <ModalContent>
                  {serviceCode === 'pcr' ? (
                    <SelectField
                      name="test_detail.result"
                      label={i18n.t('select_result')}
                      placeholder={i18n.t('select_result')}
                      options={resultOptions}
                      onChange={onSubmitResult}
                    />
                  ) : (
                    <Grid container spacing={12}>
                      <Grid item xs={12} lg={4} xl={3}>
                        <TextInputField
                          name="test_detail.result"
                          label={i18n.t('enter_result')}
                          placeholder={i18n.t('enter_result')}
                          endIcon={
                            <Button
                              size="small"
                              style={{ marginTop: '-4px' }}
                              disabled={!valueGetter(values, 'test_detail.result')}
                              label={i18n.t('submit')}
                              onClick={() => onSubmitResult(values)}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </ModalContent>
              )}
            </form>
          )}
        </Form>
        {isOrderPage && <OrderLogs />}
      </CardContainer>
    </>
  );

  return (
    <>
      <LabHeader title="reservations" />
      {isMobile ? content : <Card styles={{ margin: '2rem' }}>{content}</Card>}
    </>
  );
};

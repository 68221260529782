import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { testShow } from 'redux/tests/actionCreators';
import { TashkhesLoader } from 'atoms';
import { RouteLayoutTemplate } from 'templates';
import AdminTestInformationCard from '../DetailCards/AdminTestInformationCard';

const TestInfo = () => {
  const dispatch = useDispatch();
  const { fetching, service_show } = useSelector((state) => state.tests);
  const { id } = useParams();

  const loadData = () => {
    dispatch(testShow(id));
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <RouteLayoutTemplate>
      {isEmpty(service_show) || fetching ? (
        <TashkhesLoader />
      ) : (
        <AdminTestInformationCard serviceTypeId={id} onReload={loadData} />
      )}
    </RouteLayoutTemplate>
  );
};

export default TestInfo;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import switchStyle from './Switch.module.css';

export const Switch = (props) => {
  const {
    className,
    style,
    checked,
    onChange,
    disabled,
    testid,
    'data-testid': dataTestId,
    ...rest
  } = props;

  return (
    <label
      className={clsx(
        switchStyle.switch,
        disabled && switchStyle.disabled,
        className,
      )}
      style={style}
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
        data-testid={dataTestId || testid}
        {...rest}
      />
      <span className={clsx(switchStyle.slider, switchStyle.round)} />
    </label>
  );
};

Switch.defaultProps = {
  onChange: () => {},
};

Switch.propTypes = {
  className: PropTypes.string,
  testid: PropTypes.string,
  'data-testid': PropTypes.string,
  style: PropTypes.shape(),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

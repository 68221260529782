import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SOLUTION } from 'models';
import i18n from 'translation/i18n';
import { newLabStepTitles } from 'utils/labUtils';

import { Modal, Button } from 'atoms';
import { ModalHeader } from 'templates';
import CreateLabForm from './CreateLabForm';

const CreateLabModal = (props) => {
  const {onSubmit, initialValues, reload} = props;
  const {plans} = useSelector((state) => state.admin);
  const [showModal, setShowModal] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);

  const handleNewLabClick = () => {
    setShowModal(!showModal);
  };

  const handleClose = () => {
    setActiveStep(1);
    setShowModal(false);
  };

  const onBackClicked = () => {
    setActiveStep(activeStep - 1);
  };

  const onPlanChoosed = (plan) => {
    if(plan.value !== SOLUTION.FULL_SOLUTION){
      setActiveStep(activeStep + 1);
    }
  };

  const handleOnSubmit = (values) => {
    if(activeStep < 2){
      return setActiveStep(activeStep + 1);
    }
    const plan = plans.find(p => p.id === values.subscription);
    const payload = {
      ...values,
      subscription: {
        name: plan.name,
        id: plan.id
      },
      service_provider: {
        ...values.service_provider,
        service_provider_type: 'lab'
      }
    };
    return onSubmit(payload)
      .then(() => {
        setActiveStep(1);
        setShowModal(false);
        reload();
      });
  };

  return (
    <>
      <Button label={i18n.t('new_lab')} onClick={handleNewLabClick}/>
      <Modal
        minWidth={420}
        isOpen={showModal}
        header={<ModalHeader 
          title={i18n.t(newLabStepTitles[activeStep])} 
          onBack={activeStep > 1 ? () => onBackClicked() : null }
        />}
        onClose={handleClose}
        disableCloseOut
      >
        <CreateLabForm 
          onSubmit={handleOnSubmit} 
          initialValues={initialValues}
          activeStep={activeStep}
          onPlanChoosed={onPlanChoosed}
        />
      </Modal>
    </>
  );
};

CreateLabModal.propTypes = {
  onSubmit: PropTypes.func,
  reload: PropTypes.func,
  initialValues: PropTypes.shape(),
};

export default CreateLabModal;
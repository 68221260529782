import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import i18n from 'translation/i18n';
import {Text} from 'atoms';
import {SearchInput} from 'components/global/filters';
import {CreateNewLab} from './CreateLab';

const LabFilters = ({reload, onSearch}) => {
  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('all_labs')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('lab_list_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <SearchInput
          placeholder={i18n.t('lab_search_input_placeholder')}
          onChange={onSearch}
        />
      </Grid>
      <Grid item xs={3}/>
      <Grid item xs={2}>
        <CreateNewLab reload={reload}/>
      </Grid>
    </Grid>
  );
};

LabFilters.propTypes = {
  reload: PropTypes.func,
  onSearch: PropTypes.func
};

export default LabFilters;

import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { isBrowser, isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

import { getLocations } from 'redux/inventory/actionCreators';
import useHandelPagination from 'hooks/useHandelPagination';

import { Grid } from '@mui/material';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import LocationsFilters from './LocationsFilters';
import LocationsTable from './LocationsTable';
import LocationForm from './LocationForm';

const Locations = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [filters, setFilters] = useState({});

  const loadLocations = (params = {}) => {
    setFilters(params);
    dispatch(getLocations(params));
  };

  const handleFilterChange = (filter) => {
    const params = { search: filter.search };
    loadLocations(params);
  };

  const handleReload = (isReload) => {
    setShowForm(false);
    setIsEdit(false);
    setSelectedLocation({});
    if (isReload) {
      loadLocations(filters);
    }
  };

  const handleCreate = () => {
    setIsEdit(false);
    setSelectedLocation({});
    setTimeout(() => {
      setShowForm(true);
    });
  };

  const handlePagination = useHandelPagination({
    filters,
    onPageChange: loadLocations,
  });

  useEffect(() => {
    setShowForm(false);
    if (isEmpty(selectedLocation)) return;
    setIsEdit(true);
    setTimeout(() => {
      setShowForm(true);
    });
  }, [selectedLocation]);

  const mainContent = (
    <>
      <ListFilters
        Wrapped={LocationsFilters}
        onChange={handleFilterChange}
        onCreate={handleCreate}
      />
      <LocationsTable onEditRow={setSelectedLocation} onPagination={handlePagination} />
    </>
  );

  const sideContent = (
    <LocationForm isEdit={isEdit} location={selectedLocation} onDone={handleReload}/>
  );
  
  return (
    <Grid
      container
      spacing={isBrowser ? 4 : 0}
      px={isBrowser ? 4 : 0}
      paddingTop={0}
    >
      <Grid item xs={12} lg={8}>
        {isMobile ? mainContent : <Card>{mainContent}</Card>}
      </Grid>
      {showForm && (
        <Grid item xs={12} lg={4} mt={isMobile ? 2 : 0} mx={isMobile ? 2 : 0}>
          {isMobile ? sideContent : <Card>{sideContent}</Card>}
        </Grid>
      )}
    </Grid>
  );
};

export default Locations;
import React from 'react';
import i18n from 'translation/i18n';
import {Card, Flex , Spacer , Text } from 'atoms';
import { LOCALE } from 'utils/constants';
import {CardSectionHeader, ModalContent} from 'templates';
import {ReactComponent as Language} from 'assets/icons/common/language-globe.svg';
import LanguageSwitcher from 'components/global/LanguageSwitcher';
import styles from './styles.module.css';

const LanguageCard = () => {
  return(
    <Card>
      <CardSectionHeader title={i18n.t('general')} uppercased/>
      <ModalContent className={styles.sectionContainer}>
        <Flex justifyBetween itemsCenter>
          <Flex itemsCenter>
            <Language/>
            <Spacer width={8}/>
            <Text typography="paragraph14">
              {i18n.t('Language')}
            </Text>
            <Spacer width={8}/>
          </Flex>
          <Text typography="subtitle">
            {i18n.t(`${localStorage.getItem(LOCALE)}_full`)}
          </Text>
          <LanguageSwitcher/>
        </Flex>
      </ModalContent>
    </Card>
  );
};

export default LanguageCard;

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useFormState} from 'react-final-form';

import i18n from 'translation/i18n';

import { Spacer, Button } from 'atoms';
import { ModalContent } from 'templates';
import Step1 from './Step1';
import Step2 from './Step2';
import MultiStep2 from './MultiStep2';

const StepsTemplate = ({ activeStep, editMode }) => {
  const { values } = useFormState();
  const isSingleTest = useMemo(() => values.is_single_test, [values]);

  return (
    <>
      <ModalContent>
        {activeStep === 1 && <Step1 editMode={editMode} />}
        {activeStep !== 1 && isSingleTest && <Step2 editMode={editMode} />}
        {activeStep !== 1 && !isSingleTest && <MultiStep2 />}
      </ModalContent>
      <Spacer height={20} />
      <Spacer
        height={1}
        width="100%"
        style={{ backgroundColor: 'var(--brand-divider-white)' }}
      />
      <ModalContent>
        <Button
          label={
            activeStep === 1
              ? i18n.t('next')
              : i18n.t(editMode ? 'update' : 'create')
          }
          type="submit"
        />
      </ModalContent>
    </>
  );
};

StepsTemplate.propTypes = {
  activeStep: PropTypes.number,
  editMode: PropTypes.bool,
};

export default StepsTemplate;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Text, Spacer } from 'atoms';
import i18n from 'translation/i18n';
import { ReactComponent as OverviewLogo } from 'assets/icons/navs/overview.svg';
import { ReactComponent as ReservationLogo } from 'assets/icons/navs/reservation.svg';
import { ReactComponent as OrdersLogo } from 'assets/icons/navs/orders.svg';
import { ReactComponent as PCRLogo } from 'assets/icons/navs/pcr.svg';
import { ReactComponent as BillingLogo } from 'assets/icons/navs/billing.svg';
import { ReactComponent as InvoicesLogo } from 'assets/icons/navs/invoices.svg';
import { ReactComponent as ReportsLogo } from 'assets/icons/navs/reports.svg';
import { ReactComponent as InventoryLogo } from 'assets/icons/navs/inventory.svg';
import { ReactComponent as UsersLogo } from 'assets/icons/navs/users.svg';
import { ReactComponent as ClinicsLogo } from 'assets/icons/navs/clinics.svg';
import { ReactComponent as TestsLogo } from 'assets/icons/navs/tests.svg';
import { ReactComponent as SettingsLogo } from 'assets/icons/navs/settings.svg';
import { ReactComponent as ShareLinkLogo } from 'assets/icons/navs/share.svg';

import './LabSideNav.scss';
import { replace } from 'lodash';

export const NavItem = ({ nav, onClick }) => {
  function getIcon() {
    switch (nav) {
      case 'overview': return <OverviewLogo />;
      case 'reservations': return <ReservationLogo />;
      case 'orders': return <OrdersLogo />;
      case 'pcr-batches': return <PCRLogo />;
      case 'overdue-tests': return <OrdersLogo />;
      case 'billing': return <BillingLogo />;
      case 'invoices': return <InvoicesLogo />;
      case 'b2b-invoices': return <InvoicesLogo />;
      case 'reports': return <ReportsLogo />;
      case 'inventory': return <InventoryLogo />;
      case 'users': return <UsersLogo />;
      case 'clients': return <ClinicsLogo />;
      case 'tests': return <TestsLogo />;
      case 'settings': return <SettingsLogo />;
      case 'share': return <ShareLinkLogo />;
      case 'serial_settings': return <SettingsLogo />;
      default: return <></>;
    }
  }

  return (
    <NavLink activeClassName="is-active" onClick={onClick} to={`/${nav}`}>
      {getIcon()}
      <Spacer width={24} />
      <Text typography="paragraph14" color="white" as="span" weight="bold">
        {i18n.t(replace(nav, '-', '_'))}
      </Text>
    </NavLink>
  );
};

NavItem.propTypes = {
  nav: PropTypes.string,
  onClick: PropTypes.func
};

import React from 'react';

import i18n from 'translation/i18n';
import { required } from 'utils/FormValidations';

import { Spacer } from 'atoms';
import { TextInputField, TextAreaInputField } from 'components/global/final-form';

const Step4 = () => (
		<>
      <TextInputField
				name="received_by"
				label={i18n.t('received_by')}
				placeholder={i18n.t('received_by')}
				validate={required}
      />
      <Spacer height={4} />
			<TextAreaInputField
				style={{width: '100%'}}
				name="comment"
				label={i18n.t('comment')}
				placeholder={i18n.t('comment')}
      />
    </>
);

export default Step4;
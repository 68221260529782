import React, {useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {required} from 'utils/FormValidations';
import {ACTIVE_STATUSES} from 'utils/options';

import {Button, Modal, Spacer} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';
import {Form} from 'react-final-form';
import {TextInputField, SelectField} from 'components/global/final-form';

const CreateSpecimenTypeModal = ({isEdit, initialValues, onSubmit}) => {
  const [showModal, setShowModal] = useState(isEdit);

  const submit = (values) => {
    onSubmit(values);
    setShowModal(false);
  };

  return (
    <>
      {!isEdit && <Button label={i18n.t('new_specimen_type')} onClick={() => setShowModal(true)}/>}
      <Modal
        isOpen={showModal}
        minWidth={420}
        header={<ModalHeader title={i18n.t('create_specimen_type')}/>}
        onClose={() => setShowModal(false)}
        disableCloseOut
      >
        <Form onSubmit={submit} initialValues={initialValues}>
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <ModalContent>
                <TextInputField
                  name="name"
                  label={i18n.t('specimen_name')}
                  placeholder={i18n.t('specimen_name')}
                  validate={required}
                />
                <Spacer size={16}/>
                <SelectField
                  name="status"
                  label={i18n.t('status')}
                  placeholder={i18n.t('status')}
                  options={ACTIVE_STATUSES}
                  validate={required}
                />
              </ModalContent>
              <ModalContent>
                <Button label={i18n.t('save')} type="submit"/>
              </ModalContent>
            </form>
          )}
        </Form>
      </Modal>
    </>
  );
};

CreateSpecimenTypeModal.defaultProps = {
  isEdit: false
};

CreateSpecimenTypeModal.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape(),
  onSubmit: PropTypes.func,
};

export default CreateSpecimenTypeModal;
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import i18n from 'translation/i18n';
import {PERMISSION} from 'models';
import {valueGetter} from 'utils/uiHelpers';
import {formattedPrice, printPDF} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {getB2BInvoice, refundPayment} from 'redux/billing/actionCreators';
import {userHasPermission} from 'utils/permissionUtils';

import {Box} from '@mui/material';
import {CardContainer, ConfirmModal, InlineInfo} from 'templates';
import {Button, Spacer} from 'atoms';

const InvoiceSummary = ({index, reload}) => {
  const dispatch = useDispatch();
  const [printDisabled, setPrintDisabled] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const {invoices} = useSelector(state => state.billing);
  const selectedInvoice = get(invoices, index, {});
  const rows = [
    {label: 'clinic_name', value: valueGetter(selectedInvoice, 'clinic_name')},
    {label: 'no_of_samples', value: valueGetter(selectedInvoice, 'number_of_sample').toString()},
    {label: 'created_at', value: format(valueGetter(selectedInvoice, 'issue_date'), DISPLAY_DATE_FORMAT)},
    {label: 'created_by', value: valueGetter(selectedInvoice, 'created_by.name_i18n')},
    {label: 'total_amount', value: formattedPrice(valueGetter(selectedInvoice, 'total_amount'), 'saudi_riyal')},
    {
      label: 'refunded_amount',
      value: formattedPrice(valueGetter(selectedInvoice, 'refunded_amount'), 'saudi_riyal'),
      hide: selectedInvoice.payment_status === 'verified'
    },
  ];
  const showPrintBtn = userHasPermission(PERMISSION.PRINT_INVOICE);
  const showRefundBtn = userHasPermission(PERMISSION.ISSUE_REFUND);

  const handlePrint = () => {
    setPrintDisabled(true);
    dispatch(getB2BInvoice(selectedInvoice.id))
      .then(resp => resp.value.data)
      .then(blob => printPDF(blob))
      .finally(() => setPrintDisabled(false));
  };

  const handleRefund = (value) => {
    setShowConfirm(false);
    if (value) {
      dispatch(refundPayment(selectedInvoice.id))
        .then(() => reload());
    }
  };

  return (
    <>
      {index >= 0 && (
        <>
          <CardContainer noPadding title={i18n.t('summary')}>
            <Box p={3}>
              {rows.map((row, i) => !row.hide && (
                <InlineInfo key={i} label={i18n.t(row.label)} value={row.value}/>
              ))}
              {showPrintBtn && (
                <>
                  <Spacer height={32}/>
                  <Button
                    label={i18n.t(printDisabled ? 'printing' : 'print_invoice')}
                    disabled={printDisabled}
                    onClick={handlePrint}
                  />
                </>
              )}
            </Box>
          </CardContainer>
          {showRefundBtn && (
            <Box p={3}>
              <Button
                label={i18n.t('refund')}
                disabled={selectedInvoice.payment_status === 'refunded'}
                variant="secondary"
                onClick={() => setShowConfirm(true)}
              />
            </Box>
          )}
          <ConfirmModal
            showModal={showConfirm}
            title={i18n.t('refund')}
            message={i18n.t('refund_message')}
            onClose={handleRefund}
          />
        </>
      )}
    </>
  );
};

InvoiceSummary.propTypes = {
  index: PropTypes.number,
  reload: PropTypes.func
};

export default InvoiceSummary;
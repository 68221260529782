import React from 'react';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import {
  getMasterOrderDetails,
  indexMasterOrderOrders,
} from 'redux/lab/actionCreators';
import { Card, Spacer } from 'atoms';
import { CardContainer } from 'templates';
import { ReservationTests } from 'components/reservation/masterReservation/ReservationTests';
import { ReservationInfoActions } from 'components/reservation/masterReservation/ReservationInfo';
import { useURLQuery } from 'hooks/useURLQuery';
import { MasterOrderInfoForm } from './masterOrderInfo';

const MasterOrderInfoWrapper = () => {
  const dispatch = useDispatch();
  const { reservationInfo } = useSelector((state) => state.lab);
  const { order_id } = useParams();

  const loadOrderInfo = () => {
    dispatch(getMasterOrderDetails(order_id));
  };

  const loadOrderTests = (params = {}) => {
    dispatch(indexMasterOrderOrders(order_id, params));
  };

  React.useEffect(() => {
    loadOrderInfo();
    loadOrderTests();
  }, []);

  const { setQuery } = useURLQuery();

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      status: filter.status,
      priority: filter.priority,
    };
    setQuery({ ...params });
    loadOrderTests(params);
  };

  const content = (
    <CardContainer
      title={i18n.t('order_info_title')}
      noPadding
      cardActions={<ReservationInfoActions masterOrder={reservationInfo} />}
      showBackButton
    >
      <MasterOrderInfoForm reload={loadOrderInfo} />
      <Spacer height={16} />
      <ReservationTests
        handleFilterChange={handleFilterChange}
        canEditResult
        refetch={loadOrderTests}
      />
    </CardContainer>
  );

  return (
    <>
      {isMobile ? content : <Card styles={{ margin: '2rem' }}>{content}</Card>}
    </>
  );
};

export default MasterOrderInfoWrapper;

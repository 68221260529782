import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getPriceFromPayments} from 'utils/labUtils';

import {Box} from '@mui/material';
import {Spacer} from 'atoms';
import {CardContainer} from 'templates';
import {PaymentInfo, PaymentSummary, PriceSummary, PrintInvoiceButton} from 'templates/OrderSummaryTemplate';

export const ReservationSummary = () => {
  const [price, setPrice] = useState({});
  const [paymentMode, setPaymentMode] = useState('');
  const {orderDetails} = useSelector(state => state.orders);

  const generatePrice = () => {
    const payments = get(orderDetails, 'payments', []);
    setPrice(getPriceFromPayments(payments));
  };

  useEffect(() => {
    generatePrice();
    setPaymentMode(valueGetter(orderDetails, 'payments.0.payment_mode.name'));
  }, [orderDetails]);

  return (
    <CardContainer title={i18n.t('summary')} noPadding>
      <Box p={3}>
        <PriceSummary price={price}/>
        <Spacer height={12}/>
        <PaymentInfo mode={paymentMode}/>
        <PaymentSummary price={price} mode={paymentMode}/>
        <PrintInvoiceButton
          status={valueGetter(orderDetails, 'status')}
          masterOrderId={valueGetter(orderDetails, 'id')}
        />
      </Box>
    </CardContainer>
  );
};

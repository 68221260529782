import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import {Select} from 'atoms';

const SelectField = ({
  disabled,
  labelKey,
  name,
  onChange,
  options,
  validate,
  valueKey,
  testId,
  ...rest
}) => (
  <Field name={name} validate={validate}>
    {({input, meta}) => (
      <Select
        status={meta.touched ? (meta.error && 'error') : undefined}
        statusMessage={meta.error}
        {...rest}
        {...input}
        data-testid={testId}
        value={options.find(option => option[valueKey] === input.value) || null}
        getOptionLabel={data => data && data[labelKey]}
        getOptionValue={data => data && data[valueKey]}
        onChange={(val) => {
          input.onChange(val && val[valueKey]);
          if (onChange) {
            onChange(val && val[valueKey], val);
          }
        }}
        isDisabled={disabled}
        options={options}
      />
    )}
  </Field>
);

SelectField.defaultProps = {
  options: [],
  labelKey: 'label',
  valueKey: 'value',
};

SelectField.propTypes = {
  disabled: PropTypes.bool,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  validate: PropTypes.func,
  valueKey: PropTypes.string,
  testId: PropTypes.string,
};

export default React.memo(SelectField);

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {PERMISSION} from 'models';
import {valueGetter} from 'utils/uiHelpers';
import {formattedPrice, printPDF} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {userHasPermission} from 'utils/permissionUtils';
import {getInvoice, getInvoicePrint} from 'redux/billing/actionCreators';

import {Box} from '@mui/material';
import {CardContainer, InlineInfo} from 'templates';
import {Button, Spacer} from 'atoms';

const InvoiceSummary = ({invoiceId}) => {
  const dispatch = useDispatch();
  const [printDisabled, setPrintDisabled] = useState(false);
  const {invoice} = useSelector(state => state.billing);
  const showPrintBtn = userHasPermission(PERMISSION.PRINT_INVOICE);
  const rows = [
    {label: 'invoice_number', value: valueGetter(invoice, 'invoice_number')},
    {label: 'patient_name', value: valueGetter(invoice, 'patient_name').toString()},
    {label: 'payment_status', value: valueGetter(invoice, 'payment_status')},
    {label: 'created_at', value: format(valueGetter(invoice, 'created_at'), DISPLAY_DATE_FORMAT)},
    {label: 'created_by', value: valueGetter(invoice, 'created_by')},
    {label: 'total_amount', value: formattedPrice(valueGetter(invoice, 'total_amount'), 'saudi_riyal')},
    {
      label: 'refunded_amount',
      value: formattedPrice(valueGetter(invoice, 'refunded_amount'), 'saudi_riyal'),
      hide: invoice?.payment_status?.toLowerCase() === 'verified'
    },
  ];

  const handlePrint = () => {
    setPrintDisabled(true);
    dispatch(getInvoicePrint(invoiceId))
      .then(resp => resp.value.data)
      .then(blob => printPDF(blob))
      .finally(() => setPrintDisabled(false));
  };

  useEffect(() => {
    if (invoiceId) {
      dispatch(getInvoice(invoiceId));
    }
  }, [invoiceId]);

  return (
    <>
      {!!invoiceId && (
        <>
          <CardContainer noPadding title={i18n.t('summary')}>
            <Box p={3}>
              {rows.map((row, i) => !row.hide && (
                <InlineInfo key={i} label={i18n.t(row.label)} value={row.value}/>
              ))}
              {showPrintBtn && (
                <>
                  <Spacer height={32}/>
                  <Button
                    label={i18n.t(printDisabled ? 'printing' : 'print_invoice')}
                    disabled={printDisabled}
                    onClick={handlePrint}
                  />
                </>
              )}
            </Box>
          </CardContainer>
        </>
      )}
    </>
  );
};

InvoiceSummary.propTypes = {
  invoiceId: PropTypes.number
};

export default InvoiceSummary;
import React from 'react';
import { useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { DataTable } from 'atoms';

const NonLogicalReferenceRangesTable = () => {
  const { service_show, fetching } = useSelector((state) => state.tests);

  const referenceRanges =
    valueGetter(service_show, 'reference_range_non_logicals') || [];

  const columns = [
    {
      field: 'description',
      headerName: i18n.t('description'),
      minWidth: 150,
    },
  ];

  return (
    <DataTable columns={columns} rows={referenceRanges} loading={fetching} />
  );
};

export default NonLogicalReferenceRangesTable;

import React from 'react';
import { useURLQuery } from 'hooks/useURLQuery';
import { INVOICE_TYPE } from 'models';
import { INVOICE_TYPES } from 'utils/options';
import { isLabUser } from 'utils/labUtils';
import LabHeader from 'components/global/LabHeader';
import { B2CInvoices } from './B2CInvoices';
import { B2BInvoices } from './B2BInvoices';

const Invoices = () => {
  const { query, setQuery } = useURLQuery();

  const tab = query?.labHeader || INVOICE_TYPE.B2C;
  const handleFilterChange = (value) => {
    setQuery({ labHeader: value });
  };

  return (
    <>
      <LabHeader
        title="invoices"
        selectedOption={tab}
        filterOptions={isLabUser() ? INVOICE_TYPES : []}
        onFilterChange={(value) => handleFilterChange(value)}
      />
      {tab === INVOICE_TYPE.B2C ? <B2CInvoices /> : <B2BInvoices />}
    </>
  );
};

export default Invoices;

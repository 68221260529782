import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { CATEGORIES, PACKAGE_STATUSES, PRIORITIES } from 'utils/options';

import { Grid } from '@mui/material';
import { SearchInput, SelectFilter } from 'components/global/filters';

const ClientTestFilters = ({ generateOtherFilters, generateSelectFilters }) => {
  const selectFilterConfigs = [
    { key: 'status', options: PACKAGE_STATUSES, placeholderKey: 'status' },
    { key: 'priority', options: PRIORITIES, placeholderKey: 'priority' },
    { key: 'category', options: CATEGORIES, placeholderKey: 'category' },
  ];

  return (
    <Grid container py={2}>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <SearchInput
          placeholder={i18n.t('test_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <SelectFilter
          className="ml-8"
          selectFilterConfigs={selectFilterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
    </Grid>
  );
};

ClientTestFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
};

export default ClientTestFilters;

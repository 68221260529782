import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { formattedPrice, getStatusColor } from 'utils/labUtils';
import {valueGetter} from 'utils/uiHelpers';

import { Grid } from '@mui/material';
import { DataTable, Flex, Tag, Text } from 'atoms';

const Header = () => {
  const { totalAmount } = useSelector((state) => state.report);

  return (
    <Grid container px={isMobile ? 2 : 4}>
      <Grid item xs={5} lg={9} />
      <Grid item xs={7} lg={3}>
        <Flex flexRow justifyBetween>
          <Text
            typography="paragraph14"
            color="var(--brand-text-gray)"
            weight="bold"
          >
            {i18n.t('total_amount')}
          </Text>
          <Text
            typography="paragraph14"
            color="var(--brand-text-black)"
            weight="bold"
          >
            {formattedPrice(totalAmount, 'saudi_riyal')}
          </Text>
        </Flex>
      </Grid>
    </Grid>
  );
};

const ReportTable = ({ onPagination }) => {
  const { reports, fetching, pagination } = useSelector((state) => state.report);

  const columns = [
    { field: 'name', headerName: i18n.t('patient'), minWidth: 170, flex: 1 },
    { field: 'mobile', headerName: i18n.t('phone'), minWidth: 140, flex: 1 },
    {
      field: 'gender',
      headerName: i18n.t('gender'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ value }) => i18n.t(value),
    },
    {
      field: 'service_name',
      headerName: i18n.t('test'),
      minWidth: 170,
      flex: 1,
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      renderCell: ({ value }) => i18n.t(value),
    },
    {
      field: 'amount_paid',
      headerName: i18n.t('total_amount'),
      minWidth: 120,
      renderCell: ({ value }) => formattedPrice(i18n.t(value), 'saudi_riyal'),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 120,
      renderCell: ({ value }) => (
        <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)} />
      ),
    }
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={reports}
      loading={fetching}
      components={{ Header }}
      hideFooter={false}
      hideFooterPagination={false}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

ReportTable.propTypes = {
  onPagination: PropTypes.func
};

export default ReportTable;

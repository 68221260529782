import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';

import {MASTER_RESERVATION_STATUS, PERMISSION} from 'models';
import {userHasPermission} from 'utils/permissionUtils';

import {Cancel, CheckCircle} from '@mui/icons-material';
import ReasonModal from './ReasonModal';

const MasterOrderStatusTransition = ({
  status,
  onMenuClick,
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [showReasonModal, setShowReasonModal] = useState(false);
  const showPay = status === MASTER_RESERVATION_STATUS.PENDING &&
    userHasPermission(PERMISSION.MASTER_ORDER_PAY);
  const showCancel = status !== MASTER_RESERVATION_STATUS.CANCELED &&
    userHasPermission(PERMISSION.MASTER_ORDER_CANCEL);
  const showRefund = status !== MASTER_RESERVATION_STATUS.PENDING &&
    userHasPermission(PERMISSION.ISSUE_REFUND);

  const handleMenuClick = (event, option) => {
    event.stopPropagation();
    if ([MASTER_RESERVATION_STATUS.CANCELED].includes(option)) {
      setSelectedOption(option);
      setShowReasonModal(true);
    } else {
      onMenuClick({option});
    }
  };

  const handleReasonClose = (value) => {
    if (value) {
      const data = {
        option: selectedOption,
        reason: value.reason,
        refund: value.refund,
      };
      onMenuClick(data);
    }
    setSelectedOption('');
    setShowReasonModal(false);
  };

  return (
    <>
      {showPay && (
        <IconButton
          color="success"
          onClick={(e) => handleMenuClick(e, MASTER_RESERVATION_STATUS.PAID)}
        >
          <CheckCircle/>
        </IconButton>
      )}
      {showCancel && (
        <IconButton
          color="error"
          onClick={(e) => handleMenuClick(e, MASTER_RESERVATION_STATUS.CANCELED)}
        >
          <Cancel/>
        </IconButton>
      )}
      <ReasonModal
        showModal={showReasonModal}
        status={selectedOption}
        onClose={handleReasonClose}
        showRefundOption={showRefund}
      />
    </>
  );
};

MasterOrderStatusTransition.defaultProps = {
  onMenuClick: () => {},
};

MasterOrderStatusTransition.propTypes = {
  status: PropTypes.string,
  onMenuClick: PropTypes.func,
};

export default MasterOrderStatusTransition;

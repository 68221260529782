import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { OverdueTests } from 'container/labs/OverdueTest';

const ReservationRoutes = () => (
  <Switch>
    <Route path="/overdue-tests" exact component={OverdueTests} />
  </Switch>
);

export default ReservationRoutes;

import React from 'react';

import LabHeader from 'components/global/LabHeader';
import { OverdueTestList } from 'components/overdue-tests';

const OverdueTests = () => {
  return (
    <>
      <LabHeader title="overdue_tests" />
      <OverdueTestList />
    </>
  );
};

export default OverdueTests;

import React from 'react';
import { useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { required } from 'utils/FormValidations';
import { getListForSelect, isAdminUser } from 'utils/labUtils';

import { Flex, RadioBox, Spacer } from 'atoms';
import { SelectField, TextInputField, MultiSelectField } from 'components/global/final-form';

const Step1 = ({editMode}) => {
  const { change } = useForm();
  const { values } = useFormState();
  const [isSingleTest, setIsSingleTest] = React.useState(values.is_single_test);
  const isAdmin = isAdminUser();
  const {
    specimen_types,
    categories,
    test_types
  } = useSelector((state) => state.admin);

  React.useEffect(() => {
    change('is_single_test', isSingleTest);
  }, [isSingleTest]);

  return (
    <>
      {!editMode && false && (
        <Flex itemsCenter>
          <RadioBox
            label={i18n.t('create_single_test')}
            value="true"
            checked={isSingleTest}
            onChange={(value) => setIsSingleTest(JSON.parse(value))}
          />
          <Spacer width={24} />
          <RadioBox
            label={i18n.t('create_multi_test')}
            value="false"
            checked={!isSingleTest}
            onChange={(value) => setIsSingleTest(JSON.parse(value))}
          />
        </Flex>
      )}
      {isSingleTest && editMode && (
        <>
          <Spacer height={12} />
          <TextInputField
            name="analyzer_code"
            label={i18n.t('analyzer_code')}
            placeholder={i18n.t('analyzer_code')}
          />
        </>
      )}
      <Spacer height={12} />
      <TextInputField
        name="lis_code"
        label={i18n.t('lis_code')}
        placeholder={i18n.t('lis_code')}
        validate={required}
      />
      <Spacer height={12} />
      <TextInputField
        name="name"
        label={i18n.t('remisol_name')}
        placeholder={i18n.t('remisol_name')}
        validate={required}
        disabled={!isAdmin}
      />
      <Spacer height={12} />
      <TextInputField
        name="description"
        label={i18n.t('description')}
        placeholder={i18n.t('description')}
        validate={required}
      />
      <Spacer height={12} />
      <SelectField
        name="category_type"
        label={i18n.t('category')}
        placeholder={i18n.t('category')}
        options={getListForSelect(categories)}
        validate={required}
      />
      <Spacer height={12} />
      <MultiSelectField
        name="specimen_types"
        label={i18n.t('specimen_types')}
        placeholder={i18n.t('specimen_types')}
        options={getListForSelect(specimen_types)}
        validate={required}
      />
      <Spacer height={12} />
      <TextInputField
        name="principal"
        label={i18n.t('principal')}
        placeholder={i18n.t('principal')}
        validate={required}
      />
      {isSingleTest && (
        <>
          <Spacer height={12} />
          <SelectField
            name="test_type"
            label={i18n.t('test_type')}
            placeholder={i18n.t('test_type')}
            options={getListForSelect(test_types)}
            validate={required}
          />
          <Spacer height={12} />
          <TextInputField
            name="unit"
            label={i18n.t('unit')}
            placeholder={i18n.t('unit')}
            validate={required}
          />
          <Spacer height={12} />
          <TextInputField
            name="no_decimal"
            label={i18n.t('precision')}
            placeholder={i18n.t('precision')}
            validate={required}
          />
        </>
      )}
    </>
  );
};

Step1.propTypes = {
  editMode: PropTypes.bool,
};

export default Step1;

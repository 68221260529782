export const REPORT_URL = 'REPORT_URL';

export const REPORTS = 'REPORTS';
export const REPORTS_PENDING = 'REPORTS_PENDING';
export const REPORTS_FULFILLED = 'REPORTS_FULFILLED';
export const REPORTS_REJECTED = 'REPORTS_REJECTED';

export const REPORT_DATA = 'REPORT_DATA';
export const REPORT_DATA_PENDING = 'REPORT_DATA_PENDING';
export const REPORT_DATA_FULFILLED = 'REPORT_DATA_FULFILLED';
export const REPORT_DATA_REJECTED = 'REPORT_DATA_REJECTED';

export const PRINT_REPORT_DATA = 'PRINT_REPORT_DATA';
export const PRINT_REPORT_DATA_PENDING = 'PRINT_REPORT_DATA_PENDING';
export const PRINT_REPORT_DATA_FULFILLED = 'PRINT_REPORT_DATA_FULFILLED';
export const PRINT_REPORT_DATA_REJECTED = 'PRINT_REPORT_DATA_REJECTED';

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_PENDING = 'DOWNLOAD_REPORT_PENDING';
export const DOWNLOAD_REPORT_FULFILLED = 'DOWNLOAD_REPORT_FULFILLED';
export const DOWNLOAD_REPORT_REJECTED = 'DOWNLOAD_REPORT_REJECTED';

export const GET_REPORT_CHART_DATA = 'GET_REPORT_CHART_DATA';
export const GET_REPORT_CHART_DATA_PENDING = 'GET_REPORT_CHART_DATA_PENDING';
export const GET_REPORT_CHART_DATA_FULFILLED = 'GET_REPORT_CHART_DATA_FULFILLED';
export const GET_REPORT_CHART_DATA_REJECTED = 'GET_REPORT_CHART_DATA_REJECTED';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {createBatchOrder, getBatchOrders, verifySampleId} from 'redux/orders/actionCreators';

import {Grid, IconButton} from '@mui/material';
import {Button, Flex, Spacer, Text} from 'atoms';
import {SearchInput} from 'components/global/filters';
import {ReactComponent as Close} from 'assets/icons/common/close.svg';
import './styles.scss';

const CreateBatchForm = ({onDone}) => {
  const dispatch = useDispatch();
  const [sampleId, setSampleId] = useState('');
  const [orders, setOrders] = useState([]);

  const verifyAndAdd = () => {
    const params = {sample_id: sampleId};
    dispatch(verifySampleId(params))
      .then(resp => resp.value.data)
      .then(({order}) => {
        const isAdded = !!orders.find(o => o.id === order.id);
        if (!isAdded) {
          setOrders([...orders, order]);
        }
      });
  };
  
  const removeOrder = (orderId) => {
    const data = [...orders];
    const index = orders.findIndex(o => o.id === orderId);
    data.splice(index, 1);
    setOrders(data);
  };
  
  const createBatch = () => {
    const orderIds = orders.map(o => o.id);
    dispatch(createBatchOrder(orderIds))
      .then(() => {
        dispatch(getBatchOrders());
        onDone();
      });
  };

  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={9}>
        <SearchInput onChange={(value) => setSampleId(value)}/>
      </Grid>
      <Grid item xs={3}>
        <Button label={i18n.t('add')} onClick={verifyAndAdd}/>
      </Grid>
      <Grid item xs={12} className="orders-container">
        {orders.map(order => (
          <>
            <Flex key={order.id} justifyBetween itemsCenter>
              <Text typography="paragraph16">{order.sample_id}</Text>
              <IconButton onClick={() => removeOrder(order.id)}>
                <Close/>
              </IconButton>
            </Flex>
            <Spacer height={8}/>
          </>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button label={i18n.t('create')} disabled={!orders.length} onClick={createBatch}/>
        <Spacer height={8}/>
      </Grid>
    </Grid>
  );
};

CreateBatchForm.propTypes = {
  onDone: PropTypes.func
};

export default CreateBatchForm;
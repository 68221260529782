import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Button, Modal, Text} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';

const ConfirmModal = ({showModal, title, message, onClose}) => {
  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={260}
        header={<ModalHeader title={title}/>}
        footer={<Button
          variant="danger"
          label={i18n.t('confirm')}
          onClick={() => onClose(true)}
        />}
        onClose={() => onClose(false)}
      >
        <ModalContent padding="0 1rem">
          <Text typography="paragraph14" color="var(--brand-text-gray-light)">
            {message}
          </Text>
        </ModalContent>
      </Modal>
    )
  );
};

ConfirmModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func
};

export default ConfirmModal;
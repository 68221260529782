import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';

import i18n from 'translation/i18n';
import { PATIENT_SOURCES } from 'utils/options';

import { Grid } from '@mui/material';
import {Flex, Spacer, Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';
import PrintReport from './PrintReport';
import DownloadReport from './DownloadReport';

const ReportFilters = ({
  selectFilters,
  showSourceFilter,
  showSearch,
  reportType,
  filters,
  generateSelectFilters,
  generateOtherFilters,
}) => {
  const selectFilterConfigs = [
    showSourceFilter && {
      key: 'source',
      options: PATIENT_SOURCES,
      placeholderKey: 'source',
    },
    ...selectFilters
  ].filter(Boolean);
  const dateFilterConfigs = [
    { key: 'from_date', placeholderKey: 'from_date' },
    { key: 'to_date', placeholderKey: 'to_date' },
  ];

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={isMobile ? 2 : 3}
      alignItems="center"
    >
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('reports_list_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t(snakeCase(`${reportType}_list_subtitle`))}
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={3}>
          <SelectFilter
            className="w-96"
            showTimeSelect
            selectFilterConfigs={selectFilterConfigs}
            dateFilterConfigs={dateFilterConfigs}
            onApply={generateSelectFilters}
            onCancel={generateSelectFilters}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        {showSearch && (
          <SearchInput
            placeholder={i18n.t(snakeCase(`${reportType}_search_input_placeholder`))}
            onChange={(value) => generateOtherFilters('search', value)}
          />
        )}
      </Grid>
      <Grid item xs={12} lg={4}>
        <Flex flexRow justifyEnd>
          <DownloadReport type={reportType} filters={filters} />
          <Spacer width={16}/>
          <PrintReport type={reportType} filters={filters} />
        </Flex>
      </Grid>
      {isBrowser && (
        <Grid item lg={1}>
          <SelectFilter
            className="ml-8 w-96"
            showTimeSelect
            selectFilterConfigs={selectFilterConfigs}
            dateFilterConfigs={dateFilterConfigs}
            onApply={generateSelectFilters}
            onCancel={generateSelectFilters}
          />
        </Grid>
      )}
    </Grid>
  );
};

ReportFilters.defaultProps = {
  selectFilters: [],
  showSourceFilter: false,
  showSearch: false,
};

ReportFilters.propTypes = {
  selectFilters: PropTypes.arrayOf(PropTypes.shape()),
  showSourceFilter: PropTypes.bool,
  showSearch: PropTypes.bool,
  reportType: PropTypes.string,
  filters: PropTypes.shape(),
  generateSelectFilters: PropTypes.func,
  generateOtherFilters: PropTypes.func,
};

export default ReportFilters;

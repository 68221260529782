import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {DatePickerField, SelectField, TextInputField} from 'components/global/final-form';
import {Switch, Text, TextInput} from 'atoms';
import styles from './templates.module.css';

const FieldInfoCard = (props) => {
  const {
    actionCallback,
    placeholder,
    validate,
    endIcon,
    action,
    label,
    name,
    xs,
    as,
    girdSizes = {},
    ...rest
  } = props;

  const fieldType = {
    field: (
      <>
        <TextInputField
          name={name}
          validate={validate}
          label={label}
          placeholder={placeholder}
          className={styles.fieldInfoCard}
          endIcon={endIcon}
          {...rest}
        />
        {action.onClick && (
          <Text
            typography="caption12"
            onClick={() => action.onClick(actionCallback)}
            className={styles.fieldInfoAction}
            {...rest}
            link
          >
            {action.text}
          </Text>
        )}
      </>
    ),
    dropdown: (
      <SelectField
        name={name}
        validate={validate}
        label={label}
        placeholder={placeholder}
        className={styles.fieldInfoCard}
        onChange={action.onChange}
        {...rest}
      />
    ),
    date: (
      <DatePickerField
        name={name}
        validate={validate}
        label={label}
        placeholder={placeholder}
        className={styles.fieldInfoCard}
        {...rest}
      />
    ),
    switch: (
      <Switch
        name={name}
        validate={validate}
        label={label}
        placeholder={placeholder}
        className={styles.fieldInfoCard}
        {...rest}
      />
    )
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} {...girdSizes}>
      <div className={styles.fieldInfoContainer}>
        {fieldType[as] ?
          fieldType[as]
          :
          <TextInput
            label={label}
            placeholder={placeholder}
            className={styles.fieldInfoCard}
            endIcon={endIcon}
            {...rest}
          />
        }
      </div>
    </Grid>
  );
};

FieldInfoCard.defaultProps = {
  xs: 6,
  as: 'field'
};

FieldInfoCard.propTypes = {
  endIcon: PropTypes.node,
  actionCallback: PropTypes.func,
  validate: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  xs: PropTypes.number,
  as: PropTypes.string,
  action: PropTypes.shape(),
  girdSizes: PropTypes.shape(),
};

export default FieldInfoCard;

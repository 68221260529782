import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useURLQuery } from 'hooks/useURLQuery';
import { REPORT } from 'models';
import { getReportList } from 'redux/report/actionCreators';
import { dateTimeQueryFormatToDate, dateTimeToQueryFormat } from 'utils/date';
import { ListFilters } from 'components/hoc';
import { USER_ROLES } from 'utils/options';
import ReportFilters from '../ReportFilters';
import ReportTable from './ReportTable';

const UserPerformanceReports = () => {
  const dispatch = useDispatch();
  const { query, setQuery } = useURLQuery();

  const selectFilters = [{
    key: 'role',
    options: USER_ROLES,
    placeholderKey: 'user_role',
  }];

  const appliedFilters = {
    from_date: query?.from_date
      ? dateTimeQueryFormatToDate(query?.from_date)
      : null,
    to_date: query?.to_date ? dateTimeQueryFormatToDate(query?.to_date) : null,
    search: query?.search,
    source: query?.source,
    role: query?.role,
  };

  const fetchReports = (params = {}) => {
    dispatch(getReportList(REPORT.USER_PERFORMANCE, params));
  };

  const handleFilterChange = (filter) => {
    const params = {
      from_date: filter.from_date
        ? dateTimeToQueryFormat(filter.from_date)
        : null,
      to_date: filter.to_date ? dateTimeToQueryFormat(filter.to_date) : null,
      search: filter.search,
      source: filter.source,
      role: filter.role,
    };
    setQuery(params);
    fetchReports(params);
  };

  useEffect(() => {
    fetchReports(appliedFilters);
  }, []);

  return (
    <>
      <ListFilters
        Wrapped={ReportFilters}
        reportType={REPORT.USER_PERFORMANCE}
        showSearch
        selectFilters={selectFilters}
        filters={appliedFilters}
        onChange={handleFilterChange}
      />
      <ReportTable />
    </>
  );
};

export default UserPerformanceReports;

import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import {FileInput} from 'atoms';
import styles from './styles.module.css';

const UploaderController = ({type, alt, ...props}) => (
  type === 'avatar' ?  <Avatar {...props}/> : <img alt={alt || 'ALT'} {...props}/>
);

UploaderController.propTypes = {
  type: PropTypes.string,
  alt: PropTypes.string,
};

const ImageUploader = (props) => {
  const {
    onDone,
    multiple, 
    disabled, 
    maxWidth,
    maxHeight,
    uploaderType,
    width,
    height,
    style,
    src,
    alt,
    ...rest
  } = props;
    
  return (
    <>
      <UploaderController
        style={{width, height, maxWidth, maxHeight, fontSize: 28, cursor: 'pointer', ...style}}
        type={uploaderType}
        src={src || '#'}
        alt={alt || 'ALT'}
      />
      <FileInput
        onDone={onDone}
        multiple={multiple}
        disabled={disabled}
        className={styles.hidden}
        {...rest}
      />
    </>
  );
};

ImageUploader.defaultProps = {
  width: 32,
  height: 32,
  multiple: false,
  disabled: false,
  uploaderType: 'avatar',
};

ImageUploader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  alt: PropTypes.string,
  src: PropTypes.string,
  uploaderType: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
};

export default ImageUploader;

import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Grid} from '@mui/material';
import {Text} from 'atoms';
import {SearchInput} from 'components/global/filters';
import {CreateSpecimenType} from './CreateSpecimenType';

const SpecimenTypeFilters = ({onSearch, reload}) => {
  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('specimen_type_title')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('specimen_type_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <SearchInput
          placeholder={i18n.t('specimen_type_input_placeholder')}
          onChange={onSearch}
        />
      </Grid>
      <Grid item xs={3}/>
      <Grid item xs={2}>
        <CreateSpecimenType reload={reload}/>
      </Grid>
    </Grid>
  );
};

SpecimenTypeFilters.propTypes = {
  onSearch: PropTypes.func,
  reload: PropTypes.func,
};

export default SpecimenTypeFilters;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Button, Modal, TextInput} from 'atoms';
import {ModalContent, ModalHeader} from 'templates';

const PriceModal = ({titleKey, showModal, initialPrice, onClose}) => {
  const [price, setPrice] = useState('');

  const handleClose = (value) => {
    onClose(value);
  };

  useEffect(() => {
    setPrice(initialPrice);
  }, [initialPrice]);

  return (
    showModal && (
      <Modal
        disableCloseOut
        isOpen={showModal}
        width={260}
        header={<ModalHeader title={i18n.t(titleKey)}/>}
        footer={<Button
          variant="secondary"
          label={i18n.t('submit')}
          onClick={() => handleClose(price)}
        />}
        onClose={handleClose}
      >
        <ModalContent padding="0 1rem">
          <TextInput
            type="number"
            value={price}
            placeholder={i18n.t(titleKey)}
            style={{margin: '0.5rem 0 1rem'}}
            onChange={(e) => setPrice(e.target.value)}
          />
        </ModalContent>
      </Modal>
    )
  );
};

PriceModal.propTypes = {
  titleKey: PropTypes.string,
  showModal: PropTypes.bool,
  initialPrice: PropTypes.string,
  onClose: PropTypes.func
};

export default PriceModal;
// import { ReservationTests } from '../ReservationTests';
import { Grid } from '@mui/material';
import { Spacer } from 'atoms';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  packageDelete,
  packageShow,
  packageUpdate,
} from 'redux/packages/actionCreators';
import { SectionTitle } from 'templates';
import i18n from 'translation/i18n';
import GeneralPackageInfo from './GeneralPackageInfo';
import { PackageTests } from './PackageTests';

export const PackageInfoForm = () => {
  const dispatch = useDispatch();
  const { onePackage } = useSelector((state) => state.packages);
  const [editing, setEditing] = React.useState(false);
  const { push } = useHistory();

  const onEditClick = (values) => {
    if (editing) {
      const payload = {
        name: values.name,
        priority: values.priority,
        description: values.description,
      };
      dispatch(packageUpdate(values.id, payload)).then(() => setEditing(false));
    } else {
      setEditing(true);
    }
  };

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      status: filter.status,
      priority: filter.priority,
    };
    dispatch(packageShow(onePackage.id, params));
  };
  const handleDeletePackage = () => {
    dispatch(packageDelete(onePackage.id));
    return push('/tests');
  };

  return (
    <Form onSubmit={() => {}} initialValues={onePackage}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <SectionTitle
            titleKey={i18n.t('package_info_title')}
            showEdit
            editKey={i18n.t(editing ? 'save' : 'edit_info')}
            onEdit={() => onEditClick(values)}
            showDelete={onePackage?.created_by !== 'admin'}
            deleteKey={i18n.t('delete')}
            onDelete={() => handleDeletePackage()}
          />
          <Spacer height={16} />
          <Grid container>
            <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
              <GeneralPackageInfo editing={editing} />
            </Grid>
          </Grid>
          <Spacer height={16} />
          <PackageTests handleFilterChange={handleFilterChange} />
        </form>
      )}
    </Form>
  );
};

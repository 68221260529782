import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Share from 'container/labs/Share';

const ShareRoutes = () => (
  <Switch>
    <Route path="/share"  component={Share}/>
  </Switch>
);

export default ShareRoutes;

import {lazy} from 'react';

const RETRY_INTERVAL = 500;
const forceRefreshKey = 'page-force-refreshed';

const retry = (fn, retriesLeft = 2) => new Promise(async (resolve, reject) => {
  const isForceRefreshed = JSON.parse(window.localStorage.getItem(forceRefreshKey) || 'false');
  try {
    const component = await fn();
    // successfully imported component
    window.localStorage.setItem(forceRefreshKey, 'false');
    return resolve(component);
  } catch (error) {
    if (retriesLeft) {
      // Retry twice for network errors
      return setTimeout(() => retry(fn, retriesLeft - 1).then(resolve, reject), RETRY_INTERVAL);
    }
    if (!isForceRefreshed) {
      // Refresh for missing static files caused by new build
      window.localStorage.setItem(forceRefreshKey, 'true');
      return resolve(window.location.reload());
    }
    // throw error if import still doesnt resolve
    return reject(error);
  }
});

export const retryImport = fn => lazy(() => retry(fn));

import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {useHistory, useLocation} from 'react-router-dom';

import {REPORTS} from 'utils/options';
import {isLabUser} from 'utils/labUtils';
import {valueGetter} from 'utils/uiHelpers';
import {reportPermission} from 'utils/permissionUtils';
import {getClinics, getCollectionCenters} from 'redux/lab/actionCreators';

import {Card} from 'atoms';
import LabHeader from 'components/global/LabHeader';
import CurrentReport from 'components/reports/CurrentReport';

const Reports = () => {
  const dispatch = useDispatch();
  const isLab = isLabUser();
  const {push} = useHistory();
  const {pathname} = useLocation();
  const options = REPORTS.filter(option => reportPermission(option.value));
  const optionFromPath = options.find(option => pathname.includes(option.value));
  const selectedOption = valueGetter(optionFromPath, 'value') || valueGetter(options, '0.value');

  const handleFilterChange = value => {
    push(`/reports/${value}`);
  };

  useEffect(() => {
    if (isLab) {
      dispatch(getClinics());
      dispatch(getCollectionCenters());
    }
  }, []);

  const content = (<CurrentReport selectedReport={selectedOption}/>);

  return (
    <>
      <LabHeader
        title="reports"
        selectedOption={selectedOption}
        filterOptions={isLab ? options : []}
        onFilterChange={handleFilterChange}
      />
      {isMobile ? content : (
        <Card styles={{margin: '2rem'}}>
          {content}
        </Card>
      )}
    </>
  );
};

export default Reports;
import i18n from 'translation/i18n';

export const plans = [
  {
    value: 'PCR_SOLUTION',
    title: i18n.t('pcr_plan'),
    price: 300,
    note: i18n.t('day_free_trial_no_payments_required'),
    description: i18n.t(
      'flexible_pricing_without_monthly_fees_and_a_5_fee_per_transaction',
    ),
    features: [
      i18n.t('reservation_management'),
      i18n.t('user_management'),
      i18n.t('clinic_management'),
      i18n.t('send_results_sms'),
      i18n.t('email_notifications'),
    ],
    accessed: false,
    supported: true,
    gradientBorder: true,
  },
  {
    value: 'FULL_SOLUTION',
    title: i18n.t('full_solution'),
    price: 800,
    description: i18n.t(
      'flexible_pricing_without_monthly_fees_and_a_5_fee_per_transaction',
    ),
    features: [
      i18n.t('all_pcr_features'),
      i18n.t('test_management'),
      i18n.t('advanced_reports'),
      i18n.t('custom_link'),
    ],
    accessed: true,
    supported: false,
    gradientBorder: false,
  },
];

export const getCountDownsec = (count) => {
  const secs = count.split(':');
  return Number(secs[1]);
};

const Lab = {
  // *

  // A
  add_new: 'Add New',
  all_subscriptions: 'All subscriptions',
  all_labs: 'All Labs',
  all_patients: 'All Patients',
  app_title: 'Labby',
  active: 'Active',
  activated: 'Activated',
  activation: 'Activation',
  activate_deactivate: 'Activate / Deactivate',
  accept_reject: 'Accept / Reject',
  accepted: 'Accepted',
  amount: 'Amount',
  additional_street_name: 'Additional street name',
  are_you_adding_a_new_patient: 'Are you adding a new patient ?',
  availability: 'Availability',
  add_location: 'Add Location',
  analyzer_count: 'Analyzer Count',
  activate_patient_portal: 'Activate Patient Portal',

  // B
  batch: 'Batch {{batchNumber}}',
  batch_created: 'Batch Created',
  batch_order_list_title: 'Batches',
  batch_order_list_subtitle: 'Overview of all samples reserved.',
  batch_order_search_input_placeholder: 'Search by batch or created by',
  billing: 'Billing',
  booked_orders_count: 'Booked',
  b2b_invoices: 'B2B Invoices',
  b2c_invoices: 'B2C Invoices',
  building_number: 'Building number',
  batch_orders: 'Batch Orders',
  billing_not_verified: 'Billing not verified',

  // C
  change_status: 'Change Status',
  choose_role: 'Choose role',
  clinic_activation: 'Clinic Activation',
  clinic_info_title: 'Clinic Details',
  collection_center_info_title: 'Collection Center Details',
  collected: 'Collected',
  created: 'Created',
  confirmed: 'Confirmed',
  processing: 'Processing',
  ready: 'Ready',
  cancelled: 'Cancelled',
  recollected: 'Recollected',
  clinic_information: 'Clinic Information',
  clinic_list_title: 'All Clinics',
  collection_center_list_title: 'All Collection Centers',
  clinic_list_subtitle: 'Manage associated clinics to lab.',
  collection_center_list_subtitle:
    'Manage associated collection center to lab.',
  collection_center_patients: 'Collection Center Patients',
  clinic_patients: 'Clinic Patients',
  lab_clients_search_input_placeholder: 'Search by name, email or phone',
  clinics: 'Clinics',
  completed_orders_count: 'Done',
  collected_orders_count: 'Collected',
  collection_time: 'Collection Time',
  confirmed_orders_count: 'Confirmed',
  count: 'Count',
  create_batch: 'Create Batch',
  create_clinic: 'Create Clinic',
  create_collection_center: 'Create Collection Center',
  create_specimen_type: 'Create Specimen Type',
  create_user: 'Create User',
  created_at: 'Created At',
  created_by: 'Created By',
  create_lab_1: 'Create lab (1)',
  create_lab_2: 'Create lab (2)',
  choose_plan: 'Choose plan',
  confirmed_orders: 'Confirmed Orders',
  isolation_place: 'Isolation Place',
  current_subscription: 'Current subscription',
  custom_dates: 'Custom Dates',
  country: 'Country',
  city_name: 'City name',
  create_stock: 'Create Stock',
  client_count: 'Client Count',
  change_subscription_plan: 'Change Subscription Plan',

  // D
  deactivated: 'Deactivated',
  delete_user: 'Delete User',
  delete_specimen_type_message: 'Are you sure you want to delete {{name}}?',
  directorate: 'Directorate',
  discount_for_clinic: 'Discount for Clinic',
  duration: 'Duration',

  // E
  edit_clinic: 'Edit Clinic',
  edit_patient: 'Edit Patient',
  edit_user: 'Edit User',
  export_csv: 'Export CSV',
  export_as_pdf: 'Export as PDF',
  expand_free_trial_days: 'Expand the free trial days',
  expire_date: 'Expire date',
  exist: 'Exist',
  expiry_date: 'Expiry Date',
  expiration: 'Expiration',
  edit_stock: 'Edit Stock',
  edit_location: 'Edit Location',
  edit_quantity: 'Edit Quantity',
  enter_quantity: 'Enter Quantity',
  entered_by: 'Entered By',

  // F
  from_date: 'From Date',

  // G
  go_to_batches: 'Go to batches',
  general_reservation_information: 'General reservation information',

  // H
  hospital: 'Hospital',

  // I
  inactive: 'Inactive',
  invite_clinic_to_lab: 'Invite clinic to lab',
  invite_collection_center_to_lab: 'Invite collection center to lab',
  invite_patient_to_lab: 'Invite patient to lab',
  invoices: 'Invoices',
  invoices_list_subtitle: 'Overview of all invoices',
  invoices_search_input_placeholder: 'Search by invoice number or patient name',
  is_pregnant: 'Is pregnant ?',
  item_name: 'Item name',
  item_info: 'Item info',

  // J

  // K

  // L
  lab: 'Lab',
  labs: 'Labs',
  lab_name: 'Lab name',
  lab_accountant: 'Lab Accountant',
  lab_accountant_manager: 'Lab Accountant Manager',
  lab_admin: 'Lab Admin',
  lab_doctor: 'Lab Doctor',
  lab_operator: 'Lab Operator',
  lab_patients: 'Lab Patients',
  lab_supervisor: 'Lab Supervisor',
  lab_technician: 'Lab Technician',
  lab_technologist: 'Lab Technologist',
  lab_store_keeper: 'Lab Store Keeper',
  lab_list_subtitle: 'Lab list subtitle',
  lab_login_url: 'Lab login URL',
  lab_statistics: 'Lab statistics',
  lab_search_input_placeholder: 'Search by name, email or mobile',
  locations: 'Locations',
  locations_table_title: 'All locations',
  locations_table_subtitle: 'Overview of all locations',
  locations_search_input_placeholder: 'Search by name',
  lot_number: 'Lot Number',

  // M
  master_reservation_list_title: 'Reservations',
  master_order_list_subtitle: 'Overview of all samples reserved.',
  master_order_list_title: 'Master orders',
  manufacturer: 'Manufacturer',
  medical_verification: 'Medical Verification',

  // N
  nasopharyngeal_swab: 'Nasopharyngeal Swab',
  nationality: 'Nationality',
  new_clinic: 'New Clinic',
  new_collection_center: 'New Collection Center',
  new_specimen_type: 'New Specimen Type',
  new_user: 'New User',
  normal: 'Normal',
  no_of_orders: 'No. of Orders',
  no_of_patients: 'No. of Patients',
  no_of_positive_results: 'No. of Positive Results',
  no_of_negative_results: 'No. of Negative Results',
  new_lab: 'New Lab',
  no_decimal: 'No. Decimal',
  new: 'New',
  note: 'Note',
  not_collected_by_client: 'Not collected by client',

  // O
  order_id: 'Order ID',
  order_info_title: 'Order Details',
  patient_order_info_title: 'Test Details',
  order_list_title: 'All Orders',
  order_list_subtitle: 'Overview of all samples reserved.',
  order_search_input_placeholder:
    'Search by patient, phone, test or reservation ID',
  order_tracking: 'Order Tracking',
  order_type_single: 'Single',
  order_type_batch: 'Batch',
  orders: 'Orders',
  oropharyngeal_swab: 'Oropharyngeal Swab',
  overall_result: 'Interpretation',
  overdue: 'Overdue',
  overview: 'Overview',
  overview_invoices_title: 'Total Invoices',
  overview_invoices_subtitle: 'Total amount of payments last {{duration}}',
  overview_overdue_title: 'Overdue Tests',
  overview_overdue_subtitle: 'Tests have been overdued in different phases.',
  overview_payments_title: 'Total Payments',
  overview_payments_subtitle: 'Total amount of payments last {{duration}}',
  overview_requests_title: 'New Requests',
  overview_requests_subtitle: 'Latest tests requests and its states.',
  overview_stats_title: 'All Tests',
  // eslint-disable-next-line quotes
  overview_stats_subtitle: "Overview of this month's numbers.",
  orders_search_input_placeholder:
    'Search by patient, phone, order or order ID',
  original_quantity: 'Original Qty.',
  original_quantity_edit_label: 'Enter quantity to add',

  // P
  passport_number: 'Passport Number',
  password: 'Password',
  patient: 'Patient',
  patient_information: 'Patient Information',
  patient_order_list_title: 'All Tests',
  patient_order_list_subtitle: 'Overview of all samples reserved.',
  patient_order_search_input_placeholder:
    'Search by patient, test or reservation ID',
  patient_source: 'Patient Source',
  payment_type: 'Payment Type',
  pcr_batches: 'PCR Batches',
  position: 'Position',
  print_barcode: 'Print Barcode',
  print_invoice: 'Print Invoice',
  print_result: 'Print Result',
  printing: 'Printing...',
  processing_orders_count: 'Processing',
  processed: 'Processed',
  plan: 'Plan',
  personal_information: 'Personal Information',
  print: 'print',
  postal_zone: 'Postal zone',
  pregnancy_week: 'Pregnancy week',
  paid_subscription: 'Paid Subscription',
  purchase_date: 'Purchase Date',
  purchase_info: 'Purchase Info',
  patient_portal_deactivate: 'Request Patient Portal Deactivation',
  patient_portal_activate: 'Request Patient Portal Activation',

  // Q

  // R
  reason: 'Reason',
  reason_for_cancelled: 'Reason for cancellation',
  reason_for_canceled: 'Reason for cancellation',
  reason_for_rejected: 'Reason for rejection',
  reason_placeholder: 'Type...',
  refund: 'Refund',
  refund_message: 'Confirm your refund request',
  refunded_b2b_invoices: 'Refunded B2B Invoices',
  refunded_b2c_invoices: 'Refunded B2C Invoices',
  remove_from_batch: 'Remove from batch',
  report_income_finance: 'Income Finance',
  report_invoice_finance: 'Invoice Finance',
  report_b2b_finance: 'B2B Finance',
  report_invoices: 'Invoices',
  report_results: 'Results',
  report_sales: 'Sales',
  report_source_reports: 'Source Reports',
  report_total_amounts: 'Total Amounts',
  report_user_performance: 'User Performance',
  reports: 'Reports',
  reports_list_title: 'All Reports',
  resend_reservation_detail: 'Resend Reservation Detail',
  reservations: 'Reservations',
  reservation_date: 'Reservation Date',
  reservation_id: 'Reservation ID',
  reservation_info_title: 'Reservation Details',
  reservation_list_title: 'Reservation Patients',
  reservation_list_subtitle: 'Overview of all current patients.',
  reservation_search_input_placeholder:
    'Search by patient, phone or reservation ID',
  reservation_test_list_search_input_placeholder:
    'Search by test name or LIS Code',
  result: 'Result',
  result_entered: 'Result Entered',
  result_negative: 'Negative (-)',
  result_pending: 'Pending',
  result_positive: 'Positive (+)',
  results: 'Results',
  results_list_subtitle: 'Overview of all results',
  results_search_input_placeholder: 'Search by clinic, lab or patient',
  role: 'Role',
  rejected: 'Rejected',
  reserved_orders: 'Reserved Orders',
  renew: 'Renew',
  renew_subscription: 'Renew Subscription',
  reservation_info_test_title: 'Order Test Details',
  reservation_info_packages_title: 'Order Package Details',
  remaining_quantity: 'Remaining Qty.',
  received_by: 'Received By',
  reset_stock: 'Reset Stock',
  reset_stock_message: 'Are you sure you want to reset the stock quantity?',
  remaining_quantity_edit_label: 'Enter used count',
  roles: 'Roles',
  request_analyzer_update: 'Request Analyzer Update',
  request_user_update: 'Request User Update',
  request_client_update: 'Request Client Update',

  // S
  sample_id: 'Sample ID',
  sales_list_subtitle: 'Overview of all sales',
  sales_search_input_placeholder: 'Search by patient name, phone or email',
  search_phone_email: 'Search by phone or email',
  send_invitation: 'Send Invitation',
  send_result: 'Send Result',
  settings: 'Settings',
  share: 'Share Link',
  source: 'Source',
  source_reports_list_subtitle: 'Overview of all reports',
  specimen_name: 'Specimen Name',
  specimen_types: 'Specimen Types',
  specimen_type_title: 'Specimen Type',
  specimen_type_subtitle: 'Overview of all specimen types',
  specimen_type_input_placeholder: 'Search by name',
  stocks: 'Stocks',
  super_admin: 'Super Admin',
  summary: 'Summary',
  subscription_monthly: 'Monthly',
  subscription_quarterly: 'Quarterly',
  subscription_half_yearly: 'Half Yearly',
  subscription_yearly: 'Yearly',
  subscription_trial: 'Trial',
  subscriptions: 'Subscriptions',
  subscriptions_list_subtitle: 'Subscriptions list subtitle',
  street_name: 'Street name',
  single_orders: 'Single Orders',
  subscription_type: 'Subscription Type',
  stocks_table_title: 'All Stocks',
  stocks_table_subtitle: 'Overview of all stocks',
  stocks_search_input_placeholder: 'Search by name, serial number, lot number, manufacturer',
  serial_number: 'Serial Number',
  supplier_name: 'Supplier Name',
  supplier_phone: 'Supplier Phone',
  supplier_email: 'Supplier Email',
  stock_detail_title: 'Stock Details',
  submitted: 'Submitted',
  serial: 'Analyzers',
  serial_settings: 'Analyzers settings',
  subscription_plan: 'Subscription Plan',

  // T
  test_result: 'Test Result',
  test: 'Test',
  packages: 'Packages',
  tests: 'Tests',
  this_week: 'This Week',
  this_month: 'This Month',
  this_year: 'This Year',
  to_date: 'To Date',
  total_income: 'Total Income',
  total_paid_amount: 'Total Paid Amount',
  total_canceled_amount: 'Total Cancelled Amount',
  total_canceled_and_refunded_amount: 'Total Canceled & Refunded Amount',
  total_amounts_list_subtitle: 'Overview of all results',
  total_amounts_search_input_placeholder: 'Search by clinic, lab or patient',
  total_reserved_orders: 'No. of patients to the lab',
  total_tests: 'Total Tests',
  test_list_info: 'Test list info',
  trial_subscription: 'Trial Subscription',
  tech_verification: 'Technical Verification',

  // U
  update_results: 'Update Results',
  urgent: 'Urgent',
  user: 'User',
  user_activation: 'User Activation',
  user_list_title: 'All Users',
  user_list_subtitle: 'Manage the admins and users.',
  user_name_en: 'User name [en]',
  user_name_ar: 'User name [ar]',
  user_performance_list_subtitle: 'Overview of all user performances',
  user_performance_search_input_placeholder: 'Search by name, phone or email',
  user_search_input_placeholder: 'Search by name, phone, or email',
  users: 'Users',
  user_role: 'User Role',
  update_config: 'Update Config',
  update_subscription_plan: 'Update Subscription Plan',
  user_count: 'User Count',

  // V
  view_unpaid_only: 'View Unpaid Only',
  value: 'Value',
  verify_all: 'Verify All',

  // W

  // X

  // Y

  // Z
};

export default Lab;

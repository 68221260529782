import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as CheckedIcon } from 'assets/icons/atoms/RadioBtnIconChecked.svg';
import { ReactComponent as UnCheckedIcon } from 'assets/icons/atoms/RadioBtnIconUnChecked.svg';
import {
  Spacer,
  Flex,
  Text,
} from 'atoms';
import styles from './styles.module.css';

const variantStyles = {
  large: styles.largeOption,
};

const variantCheckedStyles = {
  large: styles.largeOptionSelected,
};

const RadioBox = ({
  name,
  id,
  value,
  label,
  locale,
  className,
  style,
  checked,
  disabled,
  onChange,
  variant,
  startIcon,
  actionButton,
  ...rest
}) => (
  <div
    className={clsx(
      styles.radioBox,
      disabled && styles.disabled,
      variant && variantStyles[variant],
      variant && (checked ? variantCheckedStyles[variant] : undefined),
      className,
    )}
    style={style}
  >
    <input
      {...rest}
      type="radio"
      onChange={(e) => onChange(value, e)}
      checked={checked}
      disabled={disabled}
      name={name}
      id={id}
      value={value}
    /> 
    {variant === 'large' ? (
      <Flex justifyBetween itemsCenter>
        <Flex itemsCenter>
          {startIcon && startIcon}
          <Spacer width={14} />
          <Text
            typography="paragraph14"
            htmlFor={id}
            className={styles.label}
            as="label"
            color="var(--color-natural-black-default)"
          >
            {label}
          </Text>
        </Flex>
 
        <span className={clsx(styles.iconContainer)}>
          {(actionButton && checked) && (
            <>
              <Text
                typography="paragraph14" 
                weight="bold"
                color="var(--brand-text-dark-gray)"
              >
                {actionButton.value}
              </Text>
              <Spacer width={8}/>
              <Text
                typography="paragraph14" 
                weight="bold"
                onClick={actionButton.onClick}
                color="var(--brand-text-primary)"
                style={{zIndex: 1000}}
              >
                {actionButton.label}
              </Text>
              <Spacer width={17}/>
            </>
          )}
          {checked ? <CheckedIcon /> : <UnCheckedIcon />}
        </span>
      </Flex>
    )
      :
      (
        <Flex itemsCenter>
          <span className={clsx(styles.iconContainer)}>
            {checked ? <CheckedIcon /> : <UnCheckedIcon />}
          </span>
          <Spacer width={14} />
          <Text
            typography="paragraph14"
            htmlFor={id}
            className={styles.label}
            as="label"
            color="var(--color-natural-black-default)"
          >
            {label}
          </Text>         
        </Flex>  
      )}
  </div>
);

RadioBox.defaultProps = {
  label: '',
  value: '',
  name: '',
  onChange: () => {},
};

RadioBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  locale: PropTypes.oneOf(['ar', 'en']),
  className: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape(),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  startIcon: PropTypes.node,
  actionButton: PropTypes.shape(),
};

export default RadioBox;

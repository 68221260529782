import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { formattedPrice } from 'utils/labUtils';

import { DataTable } from 'atoms';
import { valueGetter } from 'utils/uiHelpers';
import { useTablePagination } from 'hooks';

const InvoiceTable = ({ onRowClick, onPagination }) => {
  const { fetching, invoices, pagination } = useSelector(
    (state) => state.billing,
  );

  const rows = useTablePagination({ pagination, dataList: invoices });

  const handleRowClick = ({ id }) => {
    onRowClick(id);
  };

  const columns = [
    {
      field: 'invoice_number',
      headerName: i18n.t('invoice_number'),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'total_amount',
      headerName: i18n.t('total_amount'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row }) => {
        const path =
          row.payment_status.toLowerCase() === 'verified'
            ? 'total_amount'
            : 'refunded_amount';
        return valueGetter(row, path);
      },
      renderCell: ({ value }) => formattedPrice(value, 'saudi_riyal'),
    },
    {
      field: 'patient_name',
      headerName: i18n.t('patient_name'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'payment_status',
      headerName: i18n.t('status'),
      minWidth: 80,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ value }) => i18n.t(`payment_${value.toLowerCase()}`),
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={rows}
      loading={fetching}
      onRowClick={handleRowClick}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

InvoiceTable.propTypes = {
  onRowClick: PropTypes.func,
  onPagination: PropTypes.func,
};

export default InvoiceTable;

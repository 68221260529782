import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TestList from 'container/labs/Tests/List';
import TestDetails from 'container/labs/Tests/Detials';

const TestRoutes = () => (
  <Switch>
    <Route exact path="/tests/:id" component={TestDetails} />
    <Route exact path="/tests" component={TestList} />
  </Switch>
);

export default TestRoutes;

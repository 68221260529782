import PackageInfo from 'components/tests/testPackages/PackageInfo';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { packageShow } from 'redux/packages/actionCreators';

const PackageDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const loadPackageInfo = () => {
    dispatch(packageShow(id));
  };

  React.useEffect(() => {
    loadPackageInfo();
  }, []);

  return (
    <>
      <PackageInfo />
    </>
  );
};

export default PackageDetails;

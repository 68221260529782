import React from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import PropTypes from 'prop-types';

import {valueGetter} from 'utils/uiHelpers';
import {MasterOrderInfoContent} from './MasterOrderInfoContent';

export const MasterOrderInfoForm = ({reload}) => {
  const {reservationInfo} = useSelector(state => state.lab);
  const initialValues = {
    ...reservationInfo,
    patient_detail: {
      ...reservationInfo.patient_detail,
      nationality_id: valueGetter(reservationInfo, 'patient_detail.nationality.id'),
      directorate_id: valueGetter(reservationInfo, 'patient_detail.directorate.id'),
      isolotion_place_id: valueGetter(reservationInfo, 'patient_detail.isolotion_place.id'),
    }
  };

  return (
    <Form onSubmit={() => {}} initialValues={initialValues}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <MasterOrderInfoContent reload={reload}/>
        </form>
      )}
    </Form>
  );
};

MasterOrderInfoForm.propTypes = {
  reload: PropTypes.func
};

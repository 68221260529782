import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Popover } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

const ImageViewer = ({ imageUrl }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  const open = Boolean(anchorEl);
  const id = open ? 'view-image-popover' : undefined;
  const originPosition = { vertical: 'center', horizontal: 'center' };

  React.useEffect(() => {
    setPosition({ top: window.innerHeight / 2, left: window.innerWidth / 2 });
  }, []);

  return (
    <>
      <IconButton aria-labelledby={id} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <ImageIcon style={{ color: 'black' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={position}
        anchorOrigin={originPosition}
        transformOrigin={originPosition}
        onClose={() => setAnchorEl(null)}
      >
        <img
          src={imageUrl}
          alt="result_image"
          width={position.left}
          height={position.top}
          style={{ objectFit: 'contain' }}
        />
      </Popover>
    </>
  );
};

ImageViewer.propTypes = {
  imageUrl: PropTypes.string
};

export default ImageViewer;
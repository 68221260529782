const Clinic = {
  // *

  // A
  add_clinic: 'اضافة عيادة',
  add_collection_center: 'اضافة مركز تحصيل',

  // B

  // C
  clients: 'العملاء',
  collection_center: 'مركز تجميع',
  collection_centers: 'مراكز التحصيل',
  collection_center_activation: 'تفعيل مركز التحصيل',
  collection_center_information: 'معلومات مركز التحصيل',
  collection_center_to_lab_pricing: 'مركز التحصيل لتسعير المختبر',

  // D

  // E

  // F

  // G

  // H

  // I

  // J

  // K

  // L

  // M

  // N

  // O

  // P

  // Q

  // R
  register_clinic_title: 'الاشتراك كعيادة',
  register_clinic_subtitle: 'الاشتراك كعيادة لـ {{lab}}',
  register_collection_center_title: 'الاشتراك كمركز تحصيل',
  register_collection_center_subtitle: 'الاشتراك كمركز تحصيل لـ {{lab}}',

  
  // S

  // T

  // U

  // V

  // W

  // X

  // Y

  // Z

};

export default Clinic;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { STOCK_STATUS } from 'models';
import { useTablePagination } from 'hooks';
import {getStockDetails, updateStock} from 'redux/inventory/actionCreators';
import { valueGetter } from 'utils/uiHelpers';
import {getExpirationColor, getStatusColor} from 'utils/labUtils';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';

import { DataTable, Flex, Spacer, Tag } from 'atoms';
import { IconButton } from '@mui/material';
import { CheckCircle, Edit } from '@mui/icons-material';
import StockModal from './updateOrCreate/StockModal';

const StocksTable = ({ reload, onPagination }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { fetching, stocks, pagination } = useSelector((state) => state.inventory);
  const rows = useTablePagination({ pagination, dataList: stocks });

  const [showModal, setShowModal] = React.useState(false);

  const handleStatusChange = (stockId) => {
    dispatch(updateStock(stockId, {status: STOCK_STATUS.IN_USE})).then(reload);
  };

  const toggleShowModal = (stockId, isShow) => {
    if (!isShow) {
      setShowModal(isShow);
      return;
    }
    dispatch(getStockDetails(stockId)).then(() => {
      setShowModal(isShow);
    });
  };

  const columns = [
    {
      field: 'item_name',
      headerName: i18n.t('name'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'original_quantity',
      headerName: i18n.t('original_quantity'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'remaining_quantity',
      headerName: i18n.t('remaining_quantity'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'availability',
      headerName: i18n.t('availability'),
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'expiry_date',
      headerName: i18n.t('expiry_date'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => format(value, DISPLAY_DATE_FORMAT),
    },
    {
      field: 'expiration',
      headerName: i18n.t('expiration'),
      minWidth: 200,
      flex: 1,
      renderCell: ({ value  }) => <Tag text={value} color={getExpirationColor(value)}/>,
    },
    {
      field: 'location.name',
      headerName: i18n.t('location'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'status_text',
      headerName: i18n.t('status'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ row, value  }) => <Tag text={value} color={getStatusColor(valueGetter(row, 'status'))} />,
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'right',
      sortable: false,
      minWidth: 50,
      flex: 1,
      renderCell: ({ row }) => {
        const stockId = row.id;
        const status = valueGetter(row, 'status');
        return (
          <Flex itemsStart>
            {status === STOCK_STATUS.NEW && (
              <>
                <IconButton
                  style={{fontSize: '1rem', marginTop: '-0.5rem'}}
                  color="success"
                  onClick={() => handleStatusChange(stockId)}
                >
                  <CheckCircle/>
                </IconButton>
                <Spacer width={8} />
              </>
            )}
            <IconButton
              style={{fontSize: '1rem', marginTop: '-0.5rem'}}
              color="primary"
              disabled={fetching}
              onClick={() => toggleShowModal(stockId, true)}
            >
              <Edit/>
            </IconButton>
          </Flex>
        );
      }
    }
  ];

  const handleCellClick = ({ field }, event) => {
    if (field === 'actions') {
      event.stopPropagation();
    }
  };

  const handleRowClick = ({id}) => {
    push(`/inventory/stocks/${id}`);
  };

  return (
    <>
      <DataTable
        columns={columns}
        rows={rows}
        loading={fetching}
        rowCount={Number(valueGetter(pagination, 'total_entries'))}
        onCellClick={handleCellClick}
        onRowClick={handleRowClick}
        onPageChange={(page) => onPagination(page + 1)}
      />
      {showModal && (
        <StockModal
          isEdit
          reloadList
          showModal={showModal}
          onClose={() => toggleShowModal(null, false)}
        />
      )}
    </>
  );
};

StocksTable.propTypes = {
  reload: PropTypes.func,
  onPagination: PropTypes.func
};

export default StocksTable;

import i18n from 'translation/i18n';

export const MASTER_RESERVATION_STATUSES = [
  { value: 'pending', label: i18n.t('pending') },
  { value: 'paid', label: i18n.t('paid') },
  { value: 'done', label: i18n.t('done') },
  { value: 'canceled', label: i18n.t('canceled') },
];

export const MASTER_RESERVATION_RESULTS = [
  { value: 'positive', label: i18n.t('positive') },
  { value: 'negative', label: i18n.t('negative') },
  { value: 'normal', label: i18n.t('normal') },
  { value: 'high', label: i18n.t('high') },
  { value: 'low', label: i18n.t('low') },
];

export const MASTER_RESERVATION_TEST_STATUSES = [
  { value: 'created', label: i18n.t('created') },
  { value: 'confirmed', label: i18n.t('confirmed') },
  { value: 'collected', label: i18n.t('collected') },
  { value: 'processing', label: i18n.t('processing') },
  { value: 'recollected', label: i18n.t('recollected') },
  { value: 'ready', label: i18n.t('ready') },
  { value: 'cancelled', label: i18n.t('cancelled') },
];

export const DURATIONS = [
  {
    value: 'trial',
    label: i18n.t('subscription_trial'),
  },
  {
    value: 'monthly',
    label: i18n.t('subscription_monthly'),
  },
  {
    value: 'quarterly',
    label: i18n.t('subscription_quarterly'),
  },
  {
    value: 'half_yearly',
    label: i18n.t('subscription_half_yearly'),
  },
  {
    value: 'yearly',
    label: i18n.t('subscription_yearly'),
  },
];

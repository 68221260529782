import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { deleteUser, getUsers, updateUser } from 'redux/lab/actionCreators';
import { USER_STATUS, ACTIVE_STATUS, USER_ROLE } from 'models';
import PropTypes from 'prop-types';

import { DataTable, Switch } from 'atoms';
// eslint-disable-next-line import/no-named-as-default-member
import useTablePagination from 'hooks/useTablePagination';
import { MENU_OPTIONS, UserRowMenu } from './UserRowMenu';
import { EditUser } from './NewUser/EditUser';

export const UserTable = ({ onPagination }) => {
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const { users, pagination } = useSelector((state) => state.lab);
  const rows = useTablePagination({ pagination, dataList: users });

  const handleStatusChange = (userId, value) => {
    const payload = {
      status: value ? USER_STATUS.ACTIVATED : USER_STATUS.DEACTIVATED,
    };
    dispatch(updateUser(userId, payload)).then(() => {
      dispatch(getUsers());
    });
  };

  const handleMenuClick = (userId, option) => {
    if (option === MENU_OPTIONS.DELETE) {
      dispatch(deleteUser(userId)).then(() => {
        dispatch(getUsers());
      });
    } else {
      setSelectedUserId(userId);
      setShowEditModal(true);
    }
  };

  const handleEditModalClose = () => {
    setSelectedUserId('');
    setShowEditModal(false);
  };

  const columns = [
    {
      field: 'name_i18n',
      headerName: i18n.t('user'),
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'email',
      headerName: i18n.t('email'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'role_label',
      headerName: i18n.t('role'),
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => i18n.t(value),
    },
    {
      field: 'status_label',
      headerName: i18n.t('activation'),
      minWidth: 120,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ row, value }) => {
        const isActive = value === ACTIVE_STATUS.ACTIVATED;
        return (
          <Switch
            checked={isActive}
            disabled={row.role_label === USER_ROLE.LAB_ADMIN}
            onChange={(status) => handleStatusChange(row.id, status)}
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({ row }) => {
        if (row.role_label === USER_ROLE.LAB_ADMIN) return <></>;
        return (
          <UserRowMenu
            onMenuClick={(option) => handleMenuClick(row.id, option)}
          />
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        mobileTableHeight={15}
        columns={columns}
        rows={rows}
        rowCount={Number(valueGetter(pagination, 'total_entries'))}
        onPageChange={(page) => onPagination(page + 1)}
      />
      {showEditModal && (
        <EditUser userId={selectedUserId} onClose={handleEditModalClose} />
      )}
    </>
  );
};

UserTable.propTypes = {
  onPagination: PropTypes.func,
};

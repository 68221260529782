import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu } from '@mui/material';
import clsx from 'clsx';
import i18n from 'translation/i18n';
import { DatePicker, Flex, Select } from 'atoms';
import { ReactComponent as FilterIcon } from 'assets/icons/common/filter.svg';
import './style.scss';
import { useURLQuery } from 'hooks/useURLQuery';

export const SelectFilter = ({
  className,
  flexClassName,
  selectFilterConfigs,
  dateFilterConfigs,
  showTimeSelect,
  onApply,
  onCancel,
  onChange,
  disabledSelectFilters,
}) => {
  const { getQueryByConfigObject, removeManyQuery } = useURLQuery();

  const initialFilters = useMemo(
    () =>
      getQueryByConfigObject([...selectFilterConfigs, ...dateFilterConfigs]),
    [selectFilterConfigs, dateFilterConfigs],
  );
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setFilters({ ...filters, ...initialFilters });
  }, [initialFilters]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleFilterChange = (filter, value) => {
    const newFilters = { ...filters, [filter]: value };
    setFilters(newFilters);
    onChange(newFilters);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilters({});
    setAnchorEl(null);
    onCancel({});
    removeManyQuery(
      Object.entries(filters).flatMap(([key]) => [key, `${key}_id`]),
    );
  };

  const handleApply = () => {
    let appliedFilters = {};
    Object.keys(filters).forEach((key) => {
      appliedFilters = {
        ...appliedFilters,
        [key]: filters[key].value || filters[key],
      };
    });
    onApply(appliedFilters);
    setAnchorEl(null);
  };

  return (
    <Flex itemsCenter justifyAround className={flexClassName}>
      <Button
        id="select-filter"
        startIcon={<FilterIcon />}
        disableRipple
        aria-haspopup="true"
        aria-controls={open ? 'select-filter-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {i18n.t('filter')}
      </Button>
      <Menu
        id="select-filter-menu"
        className={clsx(className)}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ 'aria-labelledby': 'select-filter' }}
      >
        {selectFilterConfigs.map(({ key, options, placeholderKey }) => (
          <Select
            key={key}
            value={filters[key]}
            options={options}
            placeholder={i18n.t(placeholderKey)}
            onChange={(value) => handleFilterChange(key, value)}
            disabled={disabledSelectFilters.includes(key)}
          />
        ))}
        {dateFilterConfigs.map(({ key, placeholderKey }) => (
          <DatePicker
            showIcon
            key={key}
            value={filters[key]}
            dateFormat={`dd/MM/yyyy${showTimeSelect ? ' hh:mm a' : ''}`}
            displayFormat={`dd MMM yyyy${showTimeSelect ? ' hh:mm a' : ''}`}
            showTimeSelect={showTimeSelect}
            placeholderText={i18n.t(placeholderKey)}
            onChange={(value) => handleFilterChange(key, value)}
          />
        ))}
        <Flex justifyEnd>
          <Button disableRipple onClick={handleClose}>
            {i18n.t('clear')}
          </Button>
          <Button disableRipple onClick={handleApply}>
            {i18n.t('apply')}
          </Button>
        </Flex>
      </Menu>
    </Flex>
  );
};

SelectFilter.defaultProps = {
  selectFilterConfigs: [],
  dateFilterConfigs: [],
  disabledSelectFilters: [],
  showTimeSelect: false,
  onApply: () => {},
  onCancel: () => {},
  onChange: () => {},
};

SelectFilter.propTypes = {
  className: PropTypes.string,
  flexClassName: PropTypes.string,
  selectFilterConfigs: PropTypes.arrayOf(PropTypes.shape()),
  disabledSelectFilters: PropTypes.arrayOf(PropTypes.string),
  dateFilterConfigs: PropTypes.arrayOf(PropTypes.shape()),
  showTimeSelect: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
};

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getRoleDetail} from 'redux/lab/actionCreators';

import {MultiSelect, Text, Spacer} from 'atoms';
import {useDispatch, useSelector} from 'react-redux';

const Step2 = ({form, values}) => {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = React.useState([]);
  const { roles } = useSelector(state => state.lab);
  const selectedRoleId = roles.find(role => role.name_value === values.role).id;

  const handleChange = (data) => {
    form.change('permissions', data);
  };

  const createPermissionOptions = p => ({
    value: p.permission_id,
    label: p.permission_label
  });

  useEffect(() => {
    dispatch(getRoleDetail(selectedRoleId)).then(({value})=> {
      const allPermissions = valueGetter(value, 'data.role') || [];
      const data = allPermissions.map(createPermissionOptions);
      setPermissions(data);
      if (values.existing_permissions && values.existing_permissions.length) {
        const availableForUser = values.existing_permissions.map(p => p.id);
        form.change('existing_permissions', []);
        handleChange(data.filter(p => availableForUser.includes(p.value)));
        return;
      }
      if (!values.permissions) {
        const availablePermissions = allPermissions
          .filter(p => p.available)
          .map(createPermissionOptions);
        handleChange(availablePermissions);
      }
    });
  }, [selectedRoleId]);
  
  return (
    <>
      <Text
        typography="caption12"
        as="label"
        weight="bold"
        color="var(--brand-text-gray-light)"
      >
        {i18n.t('permissions').toUpperCase()}
      </Text>
      <Spacer height={8} />
      <MultiSelect
        noOptionsMessage={() => 'All permission added'}
        value={values.permissions}
        onChange={handleChange}
        options={permissions}
      />
    </>
  );
};

Step2.propTypes = {
  form: PropTypes.shape(),
  values: PropTypes.shape()
};

export default Step2;
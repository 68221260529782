import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {Field} from 'react-final-form';
import {MultiSelect} from 'atoms';

const MultiSelectField = ({
  isDisabled,
  name,
  onChange,
  options,
  validate,
  ...rest
}) => (
  <Field name={name} validate={validate}>
    {({input, meta}) => {
      return (
        <MultiSelect
          status={meta.touched ? (meta.error && 'error') : undefined}
          {...input}
          {...rest}
          isDisabled={isDisabled}
          options={options}
          onChange={(val) => {
            input.onChange(val);
            if (onChange) {
              onChange(val);
            }
          }}
          value={input.value || []}
        />
      );
    }}
  </Field>
);

MultiSelectField.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  validate: PropTypes.func,
};

export default memo(MultiSelectField);
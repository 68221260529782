import { ReactComponent as AddIcon } from 'assets/icons/common/add-square.svg';
import { Button, Flex, Modal } from 'atoms';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { packageCreate, packagesLoad } from 'redux/packages/actionCreators';
import { ModalHeader } from 'templates';
import i18n from 'translation/i18n';
import { isPathIncludes } from 'utils/uiHelpers';
import CreatePackageStepOne from './NewPackageSteps/CreatePackageStepOne';
import CreatePackageStepTwo from './NewPackageSteps/CreatePackageStepTwo';

const CurrentStep = ({ activeStep, onBack, clientLabel }) => {
  const step = {
    1: <CreatePackageStepOne />,
    2: <CreatePackageStepTwo onBack={onBack} clientLabel={clientLabel} />,
  };

  return step[activeStep];
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number,
  onBack: PropTypes.func,
  clientLabel: PropTypes.string,
};

const NewPackage = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState();
  const [activeStep, setActiveStep] = React.useState(1);

  const handleFormSubmit = (data) => {
    if (activeStep === 1) {
      return setActiveStep(activeStep + 1);
    }
    const payload = {
      package: {
        ...data,
        discount: +data.discount,
      },
    };
    return dispatch(packageCreate(payload)).then(() => {
      dispatch(packagesLoad());
      setShowModal(false);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveStep(1);
  };

  return (
    <Flex justifyEnd>
      <Button
        label={i18n.t('package_add')}
        startIcon={<AddIcon style={{ minWidth: '16px' }} />}
        onClick={() => setShowModal(true)}
        fitContent
      />
      <Modal
        header={
          <ModalHeader
            title={i18n.t('package_add')}
            onBack={
              activeStep === 1 ? null : () => setActiveStep(activeStep - 1)
            }
          />
        }
        isOpen={showModal}
        onClose={() => handleCloseModal()}
        disableCloseOut
        width={isMobile ? 350 : '90%'}
        maxWidth={600}
      >
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ padding: 8 }}>
                <CurrentStep
                  activeStep={activeStep}
                  onBack={() => setActiveStep(activeStep - 1)}
                  clientLabel={
                    isPathIncludes('clinics') ? 'clinic' : 'collection_center'
                  }
                />
              </div>
            </form>
          )}
        </Form>
      </Modal>
    </Flex>
  );
};

export default NewPackage;

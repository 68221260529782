import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { USER_ROLE } from 'models';
import { getRoles } from 'redux/lab/actionCreators';

import { TashkhesLoader } from 'atoms';
import RoleDetail from './RoleDetail';

const Roles = () => {
  const dispatch = useDispatch();
  const { roles } = useSelector(state => state.lab);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(getRoles()).then(() => setLoading(false));
  }, []);

  if (loading) return <TashkhesLoader/>;

  return (
    <>
      {roles.filter(role => role.name_value !== USER_ROLE.LAB_ADMIN).map(role => (
        <RoleDetail key={role.id} id={role.id} name={role.name_labal} />
      ))}
    </>
  );
};

export default Roles;
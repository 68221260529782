import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {valueGetter, isPathIncludes} from 'utils/uiHelpers';

import {Grid} from '@mui/material';
import {InfoCards, SectionTitle} from 'templates';
import EditClinic from './manageClients/EditClient';

const ClientInfo = () => {
  const {clientInfo} = useSelector(state => state.lab);
  const [showEditModal, setShowEditModal] = useState(false);

  const items = [{
    key: 'name_en',
    value: valueGetter(clientInfo, 'secondary.name_en')
  }, {
    key: 'name_ar',
    value: valueGetter(clientInfo, 'secondary.name_ar')
  }, {
    key: 'address',
    value: valueGetter(clientInfo, 'secondary.address')
  }, {
    key: 'email',
    value: valueGetter(clientInfo, 'secondary.admin_service_provider.0.email')
  }, {
    key: 'phone',
    value: valueGetter(clientInfo, 'secondary.admin_service_provider.0.mobile')
  }, {
    key: 'vat_number',
    value: valueGetter(clientInfo, 'secondary.vat_number')
  }, {
    key: 'cr_number',
    value: valueGetter(clientInfo, 'secondary.cr_number')
  }, {
    key: 'iban',
    value: valueGetter(clientInfo, 'secondary.iban_number')
  }];

  const toggleShowModal = () => {
    setShowEditModal(!showEditModal);
  };

  const sectionTitles = {
    true: 'clinic_information',
    false: 'collection_center_information',
  };

  return (
    <>
      <SectionTitle titleKey={sectionTitles[isPathIncludes('clinics')]} showEdit onEdit={toggleShowModal}/>
      <Grid container px={3} py={2} spacing={3}>
        <InfoCards items={items}/>
      </Grid>
      {showEditModal && (
        <EditClinic
          showModal={showEditModal}
          onClose={toggleShowModal}
        />
      )}
    </>
  );
};

export default ClientInfo;
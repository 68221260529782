import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {PAYMENT_MODE} from 'models';
import {isPatient, getLastChars } from 'utils/labUtils';

import {ReactComponent as CashMoneySelected} from 'assets/icons/reservations/cash-money-selected.svg';
import {ReactComponent as CashMoney} from 'assets/icons/reservations/cash-money.svg';
import {ReactComponent as CardPosSelected} from 'assets/icons/reservations/card-pos-selected.svg';
import {ReactComponent as CardPos} from 'assets/icons/reservations/card-pos.svg';
import {ReactComponent as MadaCard} from 'assets/icons/reservations/mada-card.svg';
import {ReactComponent as ApplePay} from 'assets/icons/reservations/apple-pay-card.svg';
import {ReactComponent as FilledCircle} from 'assets/icons/reservations/filled-circle.svg';
import {RadioBox, Spacer, Flex, Text} from 'atoms';
import AddCardModal from 'components/reservation/NewPatient/steps/AddCardModal';

const PaymentSelection = (props) => {
  const {onChange, defaultMode, title, methods} = props;
  const [payMethod, setPayMethod] = useState(defaultMode);
  const [openModal, setOpenModal] = useState(false);

  const onMethodChanged = (vals) => {
    const payment = {...defaultMode.payment, ...vals.payment};
    onChange(payment);
    setOpenModal(false);
  };

  useEffect(() => {
    onChange(payMethod);
  }, [payMethod]);

  return (
    <>
      <Text typography="caption12" color="var(--brand-text-gray-light)" textCase="uppercase">
        {title}
      </Text>
      <Spacer height={6}/>
      {methods.includes('cash') && (
        <RadioBox
          name="payment.type"
          label={i18n.t(PAYMENT_MODE.CASH)}
          variant="large"
          checked={payMethod === PAYMENT_MODE.CASH}
          startIcon={payMethod === 'cash' ? <CashMoneySelected/> : <CashMoney/>}
          onChange={() => setPayMethod('cash')}
        />
      )}
      {methods.includes('transaction') && (
        <>
          <Spacer height={8}/>
          <RadioBox
            name="payment.type"
            label={i18n.t(PAYMENT_MODE.CARD)}
            variant="large"
            checked={payMethod === PAYMENT_MODE.CARD}
            startIcon={payMethod === 'transaction' ? <CardPosSelected/> : <CardPos/>}
            onChange={() => setPayMethod('transaction')}
            actionButton={isPatient() ? {
              label: i18n.t(defaultMode.payment?.card_number ? 'change' : 'add_card'),
              value: defaultMode.payment?.card_number
                ? 
                <Flex itemsCenter>
                  <FilledCircle/>
                  <Spacer width={4}/>
                  <FilledCircle/>
                  <Spacer width={4}/>
                  {getLastChars(defaultMode.payment?.card_number)}
                </Flex> 
                : '',
              onClick: () =>  setOpenModal(true),
            } : {}}
          />
        </>
      )}

      {methods.includes('mada') && (
        <>
          <Spacer height={8}/>
          <RadioBox
            name="payment.type"
            label={i18n.t(PAYMENT_MODE.MADA)}
            variant="large"
            checked={payMethod === PAYMENT_MODE.MADA}
            startIcon={<MadaCard/>}
            onChange={() => setPayMethod('mada')}
          />
        </>
      )}

      {methods.includes('apple_pay') && (
        <>
          <Spacer height={8}/>
          <RadioBox
            name="payment.type"
            label={i18n.t(PAYMENT_MODE.APPLE_PAY)}
            variant="large"
            checked={payMethod === PAYMENT_MODE.APPLE_PAY}
            startIcon={<ApplePay/>}
            onChange={() => setPayMethod('apple_pay')}
          />
        </>
      )}
      <AddCardModal
        open={openModal} 
        onClose={() => setOpenModal(false)} 
        initialValues={{payment: {...defaultMode.payment}}}
        onSubmit={onMethodChanged}
      />
    </>
  );
};

PaymentSelection.defaultProps = {
  defaultMode: PAYMENT_MODE.CASH
};

PaymentSelection.propTypes = {
  title: PropTypes.string,
  methods: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  defaultMode: PropTypes.string,
};
  
export default PaymentSelection;
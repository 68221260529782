import React from 'react';
import { ReactComponent as DangerAlert } from 'assets/icons/atoms/danger-alert.svg';
import styles from './Alert.module.css';

export const alertStyles = {
  primary: styles.primaryAlert,
  secondary: styles.secondaryAlert,
  success: styles.successAlert,
  warning: styles.warningAlert,
  danger: styles.dangerAlert,
};

export const alertPlacement = {
  top: styles.topCenterAlert,
  bottom: styles.bottomCenterAlert,
};

export const defaultIcons = {
  secondary: <span />,
  success: <span />,
  warning: <span />,
  danger: <DangerAlert />,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MASTER_RESERVATION_STATUS } from 'models';
import {
  cancelMasterOrder,
  getMasterReservations,
  updateMasterOrderDetails,
} from 'redux/lab/actionCreators';
import i18n from 'translation/i18n';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';
import { getStatusColor, userModule } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';
import { DataTable, Tag } from 'atoms';
import MasterOrderStatusTransition from 'components/global/MasterOrderStatusTransition';
import { useTablePagination } from 'hooks';

export const MasterReservationTable = ({ onPagination }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const {
    reservations,
    fetching,
    reservationsPagination: pagination,
  } = useSelector((state) => state.lab);
  const module = userModule();

  const handleMenuClick = (masterOrderId, { option, reason, refund }) => {
    if (option === MASTER_RESERVATION_STATUS.CANCELED) {
      const payload = { reason, refund };
      dispatch(cancelMasterOrder(masterOrderId, payload)).then(() =>
        dispatch(getMasterReservations()),
      );
    } else {
      const payload = { master_order: { status: option } };
      dispatch(updateMasterOrderDetails(masterOrderId, payload)).then(() =>
        dispatch(getMasterReservations()),
      );
    }
  };

  const handleRowClick = ({ id }) => {
    push(`/reservations/${id}/`);
  };

  const rows = useTablePagination({ pagination, dataList: reservations });

  const columns = [
    {
      field: 'patient_name',
      headerName: i18n.t('patient'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'patient_mobile',
      headerName: i18n.t('phone'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'date',
      headerName: i18n.t('date'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ value }) => format(value, DISPLAY_DATE_FORMAT),
    },
    {
      field: 'count_orders',
      headerName: i18n.t('total_tests'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ value }) => i18n.t(value),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => (
        <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)} />
      ),
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({ row }) => {
        const source = valueGetter(row, 'source');
        if (module !== source) {
          return <></>;
        }
        return (
          <MasterOrderStatusTransition
            status={row.status}
            onMenuClick={(payload) => handleMenuClick(row.id, payload)}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={rows}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
      onRowClick={handleRowClick}
    />
  );
};

MasterReservationTable.propTypes = {
  onPagination: PropTypes.func,
};

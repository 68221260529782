import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import { Grid } from '@mui/material';
import {Button, Text} from 'atoms';
import { SearchInput } from 'components/global/filters';

const LocationsFilters = ({ onCreate, generateOtherFilters }) => {
  const createButton = (
    <Grid item xs={3} lg={2}>
      <Button label={i18n.t('create')} onClick={onCreate}/>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={isBrowser ? 3 : 2}
      alignItems="center"
    >
      <Grid item xs={9} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('locations_table_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t('locations_table_subtitle')}
        </Text>
      </Grid>
      {isMobile && createButton}
      <Grid item xs={12} lg={7}>
        <SearchInput
          placeholder={i18n.t('locations_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {isBrowser && createButton}
    </Grid>
  );
};

LocationsFilters.propTypes = {
  onCreate: PropTypes.func,
  generateOtherFilters: PropTypes.func,
};

export default LocationsFilters;

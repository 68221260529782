import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { formattedPrice } from 'utils/labUtils';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';

import { Grid } from '@mui/material';
import {InfoCard, SectionTitle} from 'templates';
import { Spacer } from 'atoms';

const PurchaseInfo = ({ onEdit }) => {
  const { stock, fetching } = useSelector(state => state.inventory);

  const details = [
    { label: 'purchase_date', value: format(stock.purshase_date, DISPLAY_DATE_FORMAT) },
    { label: 'total_amount', value: formattedPrice(stock.total_amount, 'saudi_riyal') },
    { label: 'location', value: valueGetter(stock, 'location.name') },
    { label: 'supplier_name', value: stock.supplier_vendor_name },
    { label: 'supplier_phone', value: stock.supplier_vendor_phone },
    { label: 'supplier_email', value: stock.supplier_contact_email },
    { label: 'received_by', value: stock.received_by },
    { label: 'entered_by', value: valueGetter(stock, 'entered_by.name_i18n') },
    { label: 'comments', value: stock.comment },
  ];

  return (
    <>
      <SectionTitle
        titleKey="purchase_info"
        showEdit
        editKey={i18n.t('edit_info')}
        onEdit={onEdit}
      />
      <Spacer height={16} />
      <Grid container spacing="20px">
        {details.map((detail, index) => (
          <Grid item key={index} xs={6} md={4} xl={3}>
            <InfoCard titleKey={detail.label} value={fetching || detail.value} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

PurchaseInfo.propTypes = {
  onEdit: PropTypes.func
};

export default PurchaseInfo;
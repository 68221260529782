import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { valueGetter } from 'utils/uiHelpers';
import { DataTable, Switch } from 'atoms';
import { useTablePagination } from 'hooks';
import { updatePlan } from 'redux/admin/actionCreators';
import { ACTIVE_STATUS } from 'models';

const SubscriptionTable = (props) => {
  const { fetching, pagination } = useSelector((state) => state.admin);
  const { list, onRowClick, onPagination } = props;

  const rows = useTablePagination({ pagination, dataList: list });
  const dispatch = useDispatch();

  const handleStatusUpdate = (id, isActive = true) =>
    dispatch(updatePlan(id, { status: isActive ? 'active' : 'inactive' }));

  const columns = [
    {
      field: 'name_text',
      headerName: i18n.t('name'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'duration',
      headerName: i18n.t('duration'),
      minWidth: 50,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
      renderCell: ({ value }) => i18n.t(`subscription_${value}`),
    },
    {
      field: 'amount',
      headerName: i18n.t('amount'),
      minWidth: 50,
      flex: 1,
      valueGetter: ({ row, field }) => i18n.t(valueGetter(row, field)),
    },
    {
      field: 'subscription_type',
      headerName: i18n.t('subscription_type'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row, field }) => i18n.t(valueGetter(row, field)),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 30,
      flex: 1,
      renderCell: ({ row, value }) => {
        const isActive = value === ACTIVE_STATUS.ACTIVE;
        return (
          <Switch
            defaultChecked={isActive}
            onChange={(checked) => handleStatusUpdate(row.id, checked)}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

SubscriptionTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
  onPagination: PropTypes.func,
};

export default SubscriptionTable;

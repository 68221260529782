import {
  handleAPI,
  API_VERSION_ONE,
  removeEmptyParams,
} from 'utils/apiUtils';

import * as actions from './actions';

export function getLocations(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/locations`;
  const method = 'GET';

  return {
    type: actions.LOCATIONS,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function createLocation(payload) {
  const url = `/${API_VERSION_ONE}/lab/locations`;
  const method = 'POST';

  return {
    type: actions.SAVE_LOCATION,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function updateLocation(id, payload) {
  const url = `/${API_VERSION_ONE}/lab/locations/${id}`;
  const method = 'PUT';

  return {
    type: actions.SAVE_LOCATION,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function getStockOptions() {
  const url = `/${API_VERSION_ONE}/lab/stocks/options`;
  const method = 'GET';

  return {
    type: actions.STOCK_OPTIONS,
    payload: handleAPI(url, {}, method),
  };
}

export function getStocks(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/stocks`;
  const method = 'GET';

  return {
    type: actions.STOCKS,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function createStock(payload) {
  const url = `/${API_VERSION_ONE}/lab/stocks`;
  const method = 'POST';

  return {
    type: actions.SAVE_STOCK,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function updateStock(id, payload) {
  const url = `/${API_VERSION_ONE}/lab/stocks/${id}`;
  const method = 'PUT';

  return {
    type: actions.SAVE_STOCK,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function getStockDetails(id) {
  const url = `/${API_VERSION_ONE}/lab/stocks/${id}`;
  const method = 'GET';

  return {
    type: actions.STOCK_DETAILS,
    payload: handleAPI(url, {}, method),
  };
}

export function updateStockOriginalQuantity(id, payload) {
  const url = `/${API_VERSION_ONE}/lab/stocks/${id}/update_quantity`;
  const method = 'PUT';

  return {
    type: actions.SAVE_STOCK,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function updateStockRemainingQuantity(id, payload) {
  const url = `/${API_VERSION_ONE}/lab/stocks/${id}/movements`;
  const method = 'POST';

  return {
    type: actions.SAVE_STOCK,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function resetStockQuantity(id) {
  const url = `/${API_VERSION_ONE}/lab/stocks/${id}/reset_quantity`;
  const method = 'PUT';

  return {
    type: actions.SAVE_STOCK,
    payload: handleAPI(url, {}, method),
  };
}

export function downloadReport(type) {
  const url = `/${API_VERSION_ONE}/reports/inventory.${type}`;
  const method = 'GET';

  return {
    type: actions.DOWNLOAD_REPORT,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' }),
  };
}


import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import clsx from 'clsx';
import defineComponentName from 'utils/defineComponentName';
import { AR_LABEL } from 'utils/constants';
import Spacer from '../Spacer';
import Text from '../Text';
import Flex from '../Flex';
import { Control, Input, Option } from './controls';
import { mainSelectStyles, statusColors, statusIcons } from './helper';
import styles from './styles.module.css';

const {language} = i18n;

const Select = React.forwardRef((
  {
    value,
    className,
    id,
    variant,
    options,
    style,
    disabled,
    locale,
    label,
    onChange,
    maxMenuHeight,
    optionalLabel,
    isClearable,
    startIcon,
    statusMessage,
    status,
    avatarKey,
    isLanguageSelector,
    ...rest
  },
  ref,
) => {
  const inputStatus = React.useMemo(() => (['warning', 'success', 'error'].includes(status) ? status : ''), [status]);

  return (
    <div
      className={clsx(
        className, 
        language === AR_LABEL ? styles.containerRtl : styles.containerLtr
      )}
      style={style}
    >
      {label && (
        <>
          <Flex justifyBetween={!!optionalLabel} justifyStart={!optionalLabel}>
            <Text
              typography="caption12"
              as="label"
              weight="bold"
              color="var(--brand-text-gray-light)"
            >
              {label.toUpperCase()}
            </Text>
            {optionalLabel && (
              <Text
                typography="caption12"
                as="small"
                weight="medium"
                color="var(--brand-text-gray-light)"
              >
                {optionalLabel}
              </Text>
            )}
          </Flex>
          <Spacer height="8px" />
        </>
      )}

      <ReactSelect
        ref={ref}
        startIcon={startIcon}
        isClearable={isClearable}
        hideSelectedOptions={false}
        tabSelectsValue={false}
        inputId={id}
        variant={variant}
        name="test_this_name"
        classNamePrefix="select"
        onChange={onChange}
        locale={locale}
        maxMenuHeight={maxMenuHeight}
        components={{ Control, Input, Option }}
        styles={mainSelectStyles({ status, maxMenuHeight, isLanguageSelector })}
        value={value}
        options={options}
        placeholder=""
        isDisabled={disabled}
        menuPlacement="auto"
        menuPosition="fixed"
        avatarKey={avatarKey}
        {...rest}
        isMulti={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: (statusColors[status] || 'var(--brand-text-primary)'),
            primary25: '#e9e8fc',
          },
        })}
      />
      {inputStatus && (
        <>
          <Spacer height="8px" />
          <Flex itemsStart justifyStart flexNoWrap>
            <span style={{ flexShrink: 0 }}>{statusIcons[inputStatus]}</span>
            <Spacer width="8px" />
            <Text
              typography="caption12"
              as="small"
              weight="medium"
              color="var(--color-natural-black-light)"
              style={{ flex: 1 }}
            >
              {statusMessage}
            </Text>
          </Flex>
        </>
      )}
    </div>
  );
});

defineComponentName(Select, 'Select');

Select.defaultProps = {
  locale: 'en',
  variant: 'simple',
  isClearable: false,
  avatarKey: 'avatar',
  status: '',
};

Select.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.shape(),
  style: PropTypes.shape(),
  className: PropTypes.string,
  variant: PropTypes.oneOf(['simple', 'avatar']),
  maxMenuHeight: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  label: PropTypes.string,
  optionalLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  locale: PropTypes.oneOf(['ar', 'en']),
  status: PropTypes.string,
  startIcon: PropTypes.node,
  isClearable: PropTypes.bool,
  isLanguageSelector: PropTypes.bool,
  statusMessage: PropTypes.string,
  avatarKey: PropTypes.string,
};

export default Select;

import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Button, Flex, Spacer} from 'atoms';
import { ModalContent } from 'templates';

const Step0 = (props) => {
  const {
    onClick,
  } = props;

  return (
    <Flex flexCol justifyCenter style={{height: '270px'}}>
      {/* <ModalContent>
        <Text typography="title">{i18n.t('are_you_adding_a_new_patient')}</Text>
      </ModalContent> */}
      <ModalContent>
        <Button label={i18n.t('new')} size="large" onClick={() => onClick('new')}/>
      </ModalContent>
      <Spacer height={8}/>
      <ModalContent>
        <Button label={i18n.t('exist')} size="large" onClick={() => onClick('exist')}/>
      </ModalContent>
    </Flex>
  );
};

Step0.propTypes = {
  onClick: PropTypes.func,
};

export default Step0;

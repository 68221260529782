import React from 'react';

import {ShareLink} from 'templates';

const LabShare = () => (
  <>
    <ShareLink titleKey="invite_clinic_to_lab" link={`http://${window.location.host}/clinic/login`}/>
    <ShareLink titleKey="invite_collection_center_to_lab" link={`http://${window.location.host}/collection-center/login`}/>
    <ShareLink titleKey="invite_patient_to_lab" link={`http://${window.location.host}/patient/login`}/>
  </>
);

export default LabShare;
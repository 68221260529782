import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { components } from 'react-select';
import { ReactComponent as CheckOutline } from 'assets/icons/atoms/CheckOutline.svg';
import Text from '../Text';
import Flex from '../Flex';
import Spacer from '../Spacer';

import styles from './styles.module.css';

export const Control = ({ children, ...rest }) => {
  const {
    startIcon, variant, avatarKey, value,
  } = rest.selectProps;
  const data = rest.getValue();
  const avatarSrc = avatarKey || 'avatar';
  const showIconOrAvatar = value && (startIcon || variant === 'avatar');
  return (
    <components.Control {...rest}>
      {showIconOrAvatar && (
        <div className={styles.selectStartIcon}>
          {(startIcon && (variant !== 'avatar')) && startIcon}
          {(variant === 'avatar' && data[0]) && (
            <Avatar
              alt="fail to load image"
              style={{ width: 20, height: 20, borderRadius: '50%' }}
              src={data[0][avatarSrc]}
            />
          )}
        </div>
      )}
      {
        !showIconOrAvatar && <Spacer width={8} />
      }
      {children}
    </components.Control>
  );
};

Control.propTypes = {
  children: PropTypes.node,
  startIcon: PropTypes.node,
};

export const Input = ({
  selectProps: { 'data-testid': testId },
  ...rest
}) => (
  <components.Input {...rest} data-testid={testId} className={styles.textInput} />
);

Input.propTypes = {
  selectProps: PropTypes.shape(),
};

export const Option = (props) => {
  const { selectProps, children, isSelected, data } = props;
  const {
    startOptionIcon, selectedOptionIcon, endOptionIcon, variant, subLabelKey,
  } = selectProps;

  function handleContent() {
    const avatarSrc = selectProps.avatarKey || 'avatar';

    return (
      <Flex itemsCenter justifyBetween className={styles.selectOptionClass}>
        <Flex itemsCenter>
          {(startOptionIcon || (variant === 'avatar')) && (
            <>
              {(variant === 'avatar') && (
                <Avatar
                  alt="fail to load image"
                  style={{ width: 20, height: 20, borderRadius: '50%' }}
                  src={data[avatarSrc]}
                />
              ) || startOptionIcon}
              <Spacer width="8px" />
            </>
          )}
          <Text
            typography="paragraph14"
            as="span"
            weight="medium"
            color="var(--color-natural-black-default)"
            data-testid={selectProps['data-testid']}
          >
            {children}
            {(subLabelKey && data[subLabelKey]) && (
              <Text
                as="small"
                weight="medium"
                typography="caption12"
                color="var(--color-natural-gray-lighter-0"
                style={{ margin: '0 5px' }}
              >
                {data[subLabelKey]}
              </Text>
            )}
          </Text>
        </Flex>

        {(endOptionIcon && !isSelected) && endOptionIcon}
        {isSelected && (selectedOptionIcon || <CheckOutline style={{ width: 16, height: 16 }} />)}
      </Flex>
    );
  }
  return (
    <components.Option
      {...props}
    >
      {handleContent()}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  selectProps: PropTypes.shape(),
  data: PropTypes.shape(),
};

import { DataTable, Switch } from 'atoms';
import { ACTIVE_STATUS, USER_STATUS } from 'models';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'translation/i18n';
import { formattedPrice } from 'utils/labUtils';
import { valueGetter } from 'utils/uiHelpers';
import React from 'react';
import { packagesLoad, packageUpdate } from 'redux/packages/actionCreators';
import { useTablePagination } from 'hooks';

const PackageTable = (props) => {
  const dispatch = useDispatch();
  const { fetching, pagination } = useSelector((state) => state.packages);
  const { list, onRowClick, onPagination } = props;

  const rows = useTablePagination({ pagination, dataList: list });

  const handleCellClick = ({ field }, event) => {
    if (field === 'status') {
      event.stopPropagation();
    }
  };

  const handleStatusChange = (id, value) => {
    const payload = {
      status: value ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE,
    };
    dispatch(packageUpdate(id, payload)).then(() => {
      dispatch(packagesLoad());
    });
  };

  const columns = [
    {
      field: 'name',
      headerName: i18n.t('name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'total_price',
      headerName: i18n.t('price'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => formattedPrice(valueGetter(row, field)),
    },
    {
      field: 'discount',
      headerName: i18n.t('discount'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) =>
        `${formattedPrice(valueGetter(row, field))}%`,
    },
    {
      field: 'total_services',
      headerName: i18n.t('total_tests'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row, field }) => valueGetter(row, field),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      renderCell: ({ row, value }) => {
        const isActive = value === ACTIVE_STATUS.ACTIVE;
        return (
          <Switch
            checked={isActive}
            onChange={(status) => handleStatusChange(row.id, status)}
          />
        );
      },
    },
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
      onCellClick={handleCellClick}
      loading={fetching}
      rowCount={Number(valueGetter(pagination, 'total_entries'))}
      onPageChange={(page) => onPagination(page + 1)}
    />
  );
};

PackageTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
  onPagination: PropTypes.func,
};

export default PackageTable;

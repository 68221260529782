import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { dateQueryFormatToDate, dateToQueryFormat } from 'utils/date';
import { getBatchOrders } from 'redux/orders/actionCreators';
import { Card } from 'atoms';
import { ListFilters } from 'components/hoc';
import useHandelPagination from 'hooks/useHandelPagination';
import { useURLQuery } from 'hooks/useURLQuery';
import BatchOrderListFilters from './BatchOrderListFilters';
// eslint-disable-next-line import/no-named-as-default-member
import BatchOrderListTable from './BatchOrderListTable';

const BatchOrderList = () => {
  const dispatch = useDispatch();
  const { setQuery, query } = useURLQuery();

  const load = (params = {}) => dispatch(getBatchOrders(params));
  useEffect(() => {
    const params = {
      search: query?.search,
      status: query?.status,
      created_at: query?.date ? dateQueryFormatToDate(query?.date) : null,
    };
    load(params);
  }, []);

  const handleFilterChange = (filter) => {
    const params = {
      search: filter.search,
      status: filter.status,
    };
    setQuery({
      ...params,
      date: filter.date ? dateToQueryFormat(filter.date) : null,
    });
    load({
      ...params,
      created_at: filter.date ? dateToQueryFormat(filter.date) : null,
    });
  };

  const handlePagination = useHandelPagination({
    filterParams: query,
    onPageChange: load,
  });

  const content = (
    <>
      <ListFilters
        Wrapped={BatchOrderListFilters}
        onChange={handleFilterChange}
      />
      <BatchOrderListTable handlePagination={handlePagination} />
    </>
  );

  return isMobile ? (
    content
  ) : (
    <Card styles={{ margin: '2rem' }}>{content}</Card>
  );
};

export default BatchOrderList;

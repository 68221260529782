import { get } from 'lodash';

import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  master_orders: [],
  patientOrders: [],
  singleOrders: [],
  batchOrders: [],
  orderDetails: {},
  batchDetails: {
    id: '',
    orders: [],
  },
  pagination: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.PATIENT_ORDER_LIST_PENDING:
    case actions.MASRTER_ORDER_LIST_PENDING:
    case actions.GET_BATCH_ORDER_PENDING:
    case actions.REMOVE_ORDER_FROM_BATCH_PENDING:
    case actions.UPDATE_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
      };

    case actions.BATCH_ORDER_LIST_PENDING:
      return {
        ...state,
        batchOrders: [],
        fetching: true,
      };

    case actions.GET_ORDER_PENDING:
      return {
        ...state,
        orderDetails: {},
        fetching: true,
      };

    case actions.RESEND_HESN_PENDING:
    case actions.EXPORT_PATCH_AS_PDF_PENDING:
    case actions.VERIFY_ORDER_PENDING:
    case actions.CREATE_BATCH_ORDER_PENDING:
      return {
        ...state,
        submitting: true,
      };

    case actions.SINGLE_ORDER_LIST_PENDING:
      return {
        ...state,
        singleOrders: [],
        fetching: true,
      };

    case actions.PATIENT_ORDER_LIST_FULFILLED:
      return {
        ...state,
        patientOrders: get(action, 'payload.data.master_orders', []),
        fetching: false,
      };

    case actions.MASRTER_ORDER_LIST_FULFILLED:
      return {
        ...state,
        master_orders: get(action, 'payload.data.master_orders', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.SINGLE_ORDER_LIST_FULFILLED:
      return {
        ...state,
        singleOrders: get(action, 'payload.data.orders', []),
        fetching: false,
      };

    case actions.BATCH_ORDER_LIST_FULFILLED:
      return {
        ...state,
        batchOrders: get(action, 'payload.data.batches', []),
        pagination: get(action, 'payload.data.pagination', {}),
        fetching: false,
      };

    case actions.GET_ORDER_FULFILLED:
      return {
        ...state,
        orderDetails: get(action, 'payload.data.master_order', {}),
        fetching: false,
      };

    case actions.GET_BATCH_ORDER_FULFILLED:
      return {
        ...state,
        batchDetails: get(action, 'payload.data.batch', {}),
        fetching: false,
      };

    case actions.REMOVE_ORDER_FROM_BATCH_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        batchDetails: get(action, 'payload.data.batch', {}),
        fetching: false,
      };

    case actions.CREATE_BATCH_ORDER_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        fetching: false,
      };

    case actions.UPDATE_ORDER_FULFILLED:
      Toastr.success(action.payload.data.message);
      return {
        ...state,
        fetching: false,
      };

    case actions.RESEND_HESN_FULFILLED:
    case actions.EXPORT_PATCH_AS_PDF_FULFILLED:
    case actions.VERIFY_ORDER_FULFILLED:
      return {
        ...state,
        submitting: false,
      };

    case actions.MASRTER_ORDER_LIST_REJECTED:
    case actions.PATIENT_ORDER_LIST_REJECTED:
    case actions.SINGLE_ORDER_LIST_REJECTED:
    case actions.BATCH_ORDER_LIST_REJECTED:
    case actions.GET_ORDER_REJECTED:
    case actions.GET_BATCH_ORDER_REJECTED:
    case actions.REMOVE_ORDER_FROM_BATCH_REJECTED:
    case actions.UPDATE_ORDER_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        fetching: false,
      };

    case actions.RESEND_HESN_REJECTED:
    case actions.EXPORT_PATCH_AS_PDF_REJECTED:
    case actions.VERIFY_ORDER_REJECTED:
    case actions.CREATE_BATCH_ORDER_REJECTED:
      Toastr.error(handleResponseErr(action.payload));
      return {
        ...state,
        submitting: false,
      };

    default:
      return state;
  }
}

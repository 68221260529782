import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import RootRouter from 'routers/RootRouter';
import './App.css';
import 'styles/styleTokens.css';

const App = () => {
  return (
    <BrowserRouter>
      <RootRouter/>
    </BrowserRouter>
  );
};

export default App;

const Common = {
  // *

  // A
  about: 'عنا',
  about_title: 'العنوان',
  about_description: 'الوصف',
  all: 'الكل',
  add: 'اضافة',
  add_test: 'اضافة فحص',
  add_package: 'اضافة حزمة',
  ar: 'AR',
  ar_full: 'العربية',
  apply: 'تطبيق',
  accept: 'قبول',
  activate: 'تنشيط',
  actions: 'عمليات',

  // B
  back: 'رجوع',

  // C
  cancel_order: 'إلغاد الطلب',
  canceled: 'تم الإلغاء',
  card: 'بطاقة',
  cash: 'نقداً',
  change: 'تغيير',
  choose: 'اختيار',
  clear: 'تصفيه',
  clinic: 'عيادة',
  clinic_profile: 'ملف العيادة',
  create: 'انشاء',
  create_new_account: 'انشاء حساب جديد',
  collection_center_profile: 'ملف مركز التجميع',
  comments: 'Comments',
  copy_link: 'نسخ الرابط',
  copy_success: 'تم النسخ بنجاح!',
  contact_us_form_name_placeholder: 'أحمد اشرف',
  contact_us_form_email_placeholder: 'البريد الالكتروني',
  contact_us_form_mobile_placeholder: '687-5892(999)+',
  contact_us_form_lab_name_placeholder: 'مثال: معمل ألف',
  contact_us_form_location_placeholder: 'مثال: الرياض، السعودية',
  contact_us_form_message_placeholder: 'إذا كان لديك أي معلومات إضافية، يرجى كتابتها هنا...',

  // D
  delete: 'حذف',
  deactivate: 'إلغاء التفعيل',
  done: 'تم الإنتهاء',
  download_csv: 'تنزيل CSV',
  download_pdf: 'تنزيل PDF',
  downloading: 'تنزيل...',
  drop_the_files_here: 'قم بإسقاط الملفات هنا',
  duplicated: 'مكرر',

  // E
  en: 'EN',
  en_full: 'English',
  edit: 'تعديل',
  edit_info: 'تعديل البيانات',
  email_msg: 'يجب أن يكون النص بتنسيق بريد إلكتروني',
  enable_phl_logo: 'تفعيل شعار PHL',

  // F
  female: 'انثى',
  filter: 'فلتر',
  from: 'From',

  // G
  general: 'عام',
  gender: 'الجنس',

  // H
  hesn_result: 'HESN نتيجة',
  hesn_status: 'حالة حصن',
  high: 'High',

  // I
  internet_error_message: 'يبدو ان لديك مشكلة في الاتصال بالانترنت!',
  issue_date: 'تاريخ الاصدار',
  inventory: 'المخزون',

  // J

  // K

  // L
  lab_profile: 'ملف المختبر',
  Language: 'اللغة',
  logout: 'تسجيل خروج',
  low: 'Low',

  // M
  male: 'ذكر',
  mark_as: 'Mark As',
  min_length: 'أقل طول {{min}}',
  max_length: 'أقصى طول {{max}}',
  must_be_more_than: 'يجب أن يكون أكثر من {{min}}',
  must_be_less_than: 'يجب أن يكون أقل من {{max}}',
  mobile: 'الهاتف',
  my_details: 'التفاصيل',

  // N
  negative: 'سلبي',
  no_further_changes: 'لا توجد تغييرات أخرى',
  number_msg: 'يجب أن يحتوي على أرقام فقط',
  number_ar_msg: 'المدخل لا يمكن ان يحتوي على الارقام العربية',
  number: 'رقم',
  no: 'No',
  normal: 'Normal',
  no_active_tests_tootip_title:
    'لا توجد أي فحوصات نشطة !، يرجى تعيين فحص واحد على الأقل ليكون نشطًا للسماح لك بإضافة مريض.',
  not_ready: 'ليس بعد',
  not_ready_yet: 'ليس بعد',
  not_sent_yet_to_hesn: 'لم ترسل بعد إلى حصن!',

  // O
  ok: 'تم',

  // P
  paid: 'مدفوع',
  payment_mode_text: 'تم الدفع بـ {{mode}}',
  pending: 'قيد الإنتظار',
  phone_or_email_msg: 'يجب أن يكون رقم الهاتف أو البريد الإلكتروني',
  pick_date: 'اختر تاريخاً',
  positive: 'إيجابي',
  priority: 'الأولويه',
  print: 'طباعة',
  print_pdf: 'طباعة PDF',
  print_results: 'طباعة النتائج',
  push: 'ارسال',
  permissions: 'Permissions',

  // Q

  // R
  required: 'اجباري',
  reject: 'رفض',
  resend: 'إعادة إرسال',
  retry_to_send_hesn_again: 'أعد المحاولة لإرسال حصن مرة أخرى',
  error_message: 'رسالة خطأ',

  // S
  soon: 'قريباً',
  sar_month: 'ريال / شهر',
  saudi_riyal: 'ريال سعودي',
  save: 'حفظ',
  set_discount: 'تحديد الخصم',
  set_price: 'تحديد السعر',
  sort: 'فرز',
  status: 'الحالة',
  status_accepted: 'مقبول',
  status_cancelled: 'تم الإلغاء',
  status_canceled: 'تم الإلغاء',
  status_collected: 'تم الجمع',
  status_confirmed: 'تم التأكيد',
  status_created: 'غير مدفوعة',
  status_done: 'تم الإنتهاء',
  status_paid: 'تم الدفع',
  status_processing: 'تحت المعالجة',
  status_pending: 'معلقة',
  status_ready: 'جاهز',
  status_recollect: 'اعادة تجميع',
  status_rejected: 'تم الرفض',
  status_submitted: 'تم الإرسال',
  select_date: 'اختر التاريخ',
  should_start_with: 'يجب أن يبدأ بـ {{start}}',
  submit: 'تأكيد وإرسال',
  swab_id: 'Swab ID',

  // T
  terms_and_conditions: 'الشروط والأحكام',
  to: 'To',

  // U
  update: 'تحديث',
  user_profile: 'ملف المستخدم',
  updateTestResult: 'حفظ نتيجة الفحص',
  // V
  view_all: 'عرض الكل',
  view_details: 'عرض التفاصيل',

  // W

  // X

  // Y
  yes: 'نعم',

  // Z

  // MAIN PAGE
  slogan: 'استكشف إدارة المختبر بسلاسة مع ',
  slogan_bold: 'لابي',
  slogan_description:
    'تسهيل عمليات المختبر الخاص بك، وتعزيز رعاية المرضى، ورفع مستوى ممارستك إلى آفاق جديدة.',
  contact_us: 'تواصل الان',
  home: 'الرئيسية',
  pricing: 'قائمة الاسعار',
  faq: 'الاسئلة الشائعة',
  login: 'تسجيل الدخول',
  main_section_1_title: 'لوحة تحكم المختبر',
  main_section_1_description: 'استمتع براحة التحكم الفريدة من نوعها مع لابي من خلال لوحة التحكم البديهية. التحليلات في الوقت الحقيقي والمؤشرات الرئيسية للأداء متاحة بسهولة، مما يعزز الكفاءة في إدارة المختبر. استمتع بسهولة إدارة المواعيد وسجلات المرضى والفوترة في مركز واحد.',
  main_section_1_cta: 'إدارة طلبات المرضى',
  main_section_2_title: 'إدارة المخزون',
  main_section_2_description: 'استمتع بالسيطرة الكاملة على المخزون مع لابي. إدارة فعّالة للإمدادات، توقع دقيق، وتقليل الفاقد يضمنان تسير عمليات المختبر بسلاسة.',
  main_section_2_cta: 'تحكم في عمليات مختبرك',
  main_section_3_title: 'إدارة الاختبارات المتكاملة وإدارة العينات مع واجهات التحليل',
  main_section_3_description: 'لابي يعيد تعريف عمليات المختبر من خلال دمج الاختبارات الروتينية، الاختبارات الجزيئية، إدارة العينات، وواجهات التحليل. يدعم نظام لابي مجموعة واسعة من الاختبارات للحصول على نتائج سريعة ودقيقة، ويتخصص في اختبارات الجزيئات المفصلة، كما يسهل تتبع العينات من جمعها إلى التحليل. يقدم النظام أيضًا تكاملًا سلسًا مع مختلف أجهزة التحليل، مما يتيح نقل البيانات تلقائيًا وتحسين تزامن سير العمل.',
  main_section_3_cta: 'استكشف المزايا المتكاملة',
  main_section_4_title: 'باقات المختبر قابلة للتخصيص',
  main_section_4_description: 'فاعلية العملاء باستخدام باقات المختبر القابلة للتخصيص من لابي للمناسبات الخاصة مثل اليوم الوطني وعيد الأم، مما يخلق عروضًا فريدة وملائمة.',
  main_section_4_cta: 'قم بتخصيص الباقات',
  main_section_5_title: 'تعزيز التفاعل مع المرضى وتكامل العيادات',
  main_section_5_description: 'يحسّن بوابة المريض في لابي التفاعل مع المرضى من خلال توفير وصول سهل إلى نتائج الاختبار، وجدولة المواعيد، والتواصل المباشر. في الوقت نفسه، يبسط تكامل العيادات ومراكز التحاليل في شبكتك، معززًا تدفق البيانات والتعاون.',
  main_section_5_cta: 'تفاعل مع عملائك',
  main_section_6_title: 'تقارير مفيدة',
  main_section_6_description: 'احصل بسهولة على رؤى قيمة باستخدام أدوات التقارير القوية في لابي. تتوفر تحليلات مفصلة لمؤشرات الأداء والبيانات المالية لتمكين اتخاذ قرارات مستنيرة واستراتيجية.',
  main_section_6_cta: 'قم باتخاذ قرارات استراتيجية',
  faqs: 'الأسئلة الشائعة',
  faq_1_title: 'لماذا يعتبر لابي أفضل أداة؟',
  faq_1_description: 'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع انترنت …',
  faq_2_title: 'متى تم تأسيس لابي',
  faq_2_description: 'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع انترنت …',
  trusted_clients: '6.3k ألف عميل موثوق به',
  get_in: 'تواصل',
  touch: 'معنا',
  get_in_touch_description: 'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه',
  full_name: 'الاسم بالكامل',
  address: 'العنوان',
  footer_description: 'تسهّل عمليات مختبرك، وتعزز الرعاية الصحية للمرضى، وترفع مستوى ممارستك إلى قمم جديدة',
  support: 'الدعم',
  copy_right: 'حقوق النشر © 2024 لابي',
  all_rights_reserved: 'كل الحقوق محفوظة',
  privacy_policy: 'سياسة الخصوصية',
  send: 'ارسال',
  contact_us_title: 'ادخل بياناتك، سنتواصل معك في اقرب وقت',
  contact_us_description: 'سواء كنت تمتلك أسئلة حول خدماتنا، تحتاج إلى مساعدة في تحديد موعد، أو ترغب ببساطة في مشاركة ملاحظاتك، نحن هنا للإستماع.',
  your_location: 'عنوانك',
  lab_name: 'اسم المعمل',
  your_message: 'رسالتك',
  application_sent: 'تم إرسال الطلب!',
  pricingTitle: 'لائحة الاسعار',
  package_labby: 'لابي',
  package_labby_price: '4000 ريال',
  pricingDescription: 'اختر الخطة المثالية لاحتياجات عملك',
  feature: 'خصائص',
  packageFeature1: ' رابط URL خاص بالمختبر',
  packageFeature2: '3000 إدارة المرضى',
  packageFeature3: 'ادارة الحجوزات',
  packageFeature4: 'الفواتير',
  packageFeature5: 'التقارير الطبية والمالية',
  packageFeature6: '10 مستخدمين',
  packageFeature7: 'ربط أجهزة المختبر بالنظام (3 أجهزة)',
  packageFeature23: '5 العيادات ومراكز التجميع',
  packageFeature8: 'إدارة قائمة الاختبارات',
  packageFeature9: 'ادارة اولويه الاختبارات',
  packageFeature10: 'التكامل مع حصن +',
  packageFeature11: 'اشعارات بالرسائل النصية',
  packageFeature12: 'ادارة الفواتير B2B',
  packageFeature13: 'التحقق من صحة النتائج',
  packageFeature14: 'دُفعات PCR',
  package_labby_plus: 'لابي +',
  package_labby_plus_price: '8000 ريال',
  package_labby_plus_feature_description: 'كل شيء مُدرج في لابي، بالإضافة إلى...',
  packageFeature15: '20 مستخدم',
  packageFeature16: 'إدارة المخزون',
  packageFeature17: 'ربط أجهزة المختبر بالنظام (5 أجهزة)',
  packageFeature18: '15 العيادات ومراكز التجميع',
  packageFeature19: 'بوابة المريض (يمكن للمريض حجز الاختبار بنفسه)',
  packageFeature20: 'اشعارات بالبريد الالكتروني',
  packageFeature21: 'ادراة الباقات',
  packageFeature22: 'تخصيص الصفحة الرئيسية',
  packageFeature24: '5000 إدارة المرضى',
  package_infinity: 'لابي غير محدود',
  package_infinity_description: 'باقة قابلة للتخصيص بالكامل، بدون قيود على جميع ميزات النظام والتكاملات.',
  subscribe: 'اشترك',
  subscribe_contact: 'تواصل معنا',
  month: 'شهر',
  adds_on: 'إضافات على الباقة',
  adds_on_description: 'تحكم في باقتك الخاصة',
  main_page: 'الرئيسية',
  enter_your_slug: 'أدخل اسم المختبر',
  enter_your_slug_description: 'أدخل اسم المختبر الخاص بك للوصول إلى لوحة التحكم الخاصة بك',
  slug_name: 'اسم المختبر',
  continue: 'استمر',
  arabic_lang: 'اللغة العربية',
  english_lang: 'English',
  additional_user_management: 'مستخدم إضافي',
  additional_analyzer_integration: 'ربط جهاز إضافي',
  patient_portal: 'بوابة المريض',
  clinic_and_cc: 'العيادات ومراكز التجميع',
  email_notification: 'تنبيهات البريد الإلكتروني',
  unlimited: 'غير محدود',
  free: 'مجانا',
  price_per_user: '100 ريال للمستخدم',
  price_per_machine: '4000 ريال للجهاز',
  sar_amount: '{{_}} ريال',
};

export default Common;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SUBSCRIPTION_STATUS } from 'models';
import i18n from 'translation/i18n';
import { DISPLAY_DATE_FORMAT, format } from 'utils/date';
import { Button, Divider, Spacer, Text } from 'atoms';
import { CardContainer, InlineInfo, ModalContent } from 'templates';
import SubscriptionRenewModal from './SubscriptionRenewModal';

const LabSubscriptionInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const { service_provider } = useSelector((state) => state.admin);
  const lab_subscription_info = service_provider.current_active_subscription
    ? service_provider.current_active_subscription[0]
    : {};

  return (
    <>
      <CardContainer
        className="reservation-info-container"
        noPadding
        customHeader={
          <Text
            typography="subtitle"
            weight="bold"
            color="var(--brand-text-black)"
            textCase="capitalize"
          >
            {i18n.t('subscription')}
          </Text>
        }
      >
        <ModalContent padding={0}>
          <Divider />
          <Spacer height={24} />
          <InlineInfo
            label={i18n.t('name')}
            value={service_provider?.name_en}
          />
          <Spacer height={8} />
          <InlineInfo
            label={i18n.t('status')}
            value={lab_subscription_info?.status}
          />
          <Spacer height={8} />
          <InlineInfo
            label={i18n.t('duration')}
            value={i18n.t(
              `subscription_${lab_subscription_info?.subscription?.duration}`,
            )}
          />
          <Spacer height={8} />
          <InlineInfo
            label={i18n.t('expire_date')}
            value={format(lab_subscription_info?.expiry, DISPLAY_DATE_FORMAT)}
          />
          {[SUBSCRIPTION_STATUS.INACTIVE, SUBSCRIPTION_STATUS.EXPIRED].includes(
            lab_subscription_info?.status,
          ) && (
            <>
              <Spacer height={16} />
              <Divider />
              <Spacer height={24} />
              <Button
                id="renew_subscription"
                label={i18n.t('renew')}
                variant="secondary"
                size="small"
                onClick={() => setShowModal(true)}
              />
            </>
          )}
        </ModalContent>
      </CardContainer>
      {showModal && (
        <SubscriptionRenewModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default LabSubscriptionInfo;

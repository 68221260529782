import { Grid, Stack } from '@mui/material';
import { Switch, Text } from 'atoms';
import { SearchInput, SelectFilter } from 'components/global/filters';
// eslint-disable-next-line import/no-named-as-default-member
import NewPatient from 'components/reservation/NewPatient/NewPatient';
import { MASTER_RESERVATION_STATUSES, ORDER_STATUS, PERMISSION, MASTER_RESERVATION_RESULTS } from 'models';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterReservations } from 'redux/lab/actionCreators';
import i18n from 'translation/i18n';
import { isLabUser } from 'utils/labUtils';
import { userHasPermission } from 'utils/permissionUtils';
import { valueGetter } from 'utils/uiHelpers';

export const MasterReservationFilters = ({
  generateOtherFilters,
  generateSelectFilters,
}) => {
  const dispatch = useDispatch();
  const [clinicOptions, setClinicOptions] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [selectFilterConfigs, setSelectFilterConfigs] = useState([]);
  const { clinics, collectionCenters } = useSelector((state) => state.lab);
  const isLab = isLabUser();

  const dateFilterConfigs = [
    { key: 'from_date', placeholderKey: 'from_date' },
    { key: 'to_date', placeholderKey: 'to_date' },
  ];

  const handleUnpaidSwitch = (value) => {
    const status = value ? ORDER_STATUS.CREATED : null;
    generateOtherFilters('status', status);
  };

  const loadReservations = (params = {}) => {
    dispatch(getMasterReservations(params));
  };

  useEffect(() => {
    const configs = [
      {
        key: 'status',
        options: MASTER_RESERVATION_STATUSES,
        placeholderKey: 'status',
      },
      {
        key: 'result',
        options: MASTER_RESERVATION_RESULTS,
        placeholderKey: 'result',
      }
    ];
    if (isLab) {
      configs.push({
        key: 'clinic',
        options: clinicOptions,
        placeholderKey: 'clinic',
      });
      configs.push({
        key: 'collection_center',
        options: centerOptions,
        placeholderKey: 'collection_center',
      });
    }
    setSelectFilterConfigs(configs);
  }, [clinicOptions, centerOptions]);

  useEffect(() => {
    const options = clinics.map((clinic) => ({
      value: valueGetter(clinic, 'secondary.id'),
      label: valueGetter(clinic, 'secondary.name_i18n'),
    }));
    setClinicOptions(options);
  }, [clinics]);

  useEffect(() => {
    const options = collectionCenters.map((center) => ({
      value: valueGetter(center, 'secondary.id'),
      label: valueGetter(center, 'secondary.name_i18n'),
    }));
    setCenterOptions(options);
  }, [collectionCenters]);

  const [disabledSelectFilter, setDisabledSelectFilter] = useState([]);
  const handleFilterChange = (values) => {
    if (Object.keys(values).includes('clinic')) {
      setDisabledSelectFilter(['collection_center']);
    }
    if (Object.keys(values).includes('collection_center')) {
      setDisabledSelectFilter(['clinic']);
    }
  };

  return (
    <Grid
      container
      spacing={isMobile ? 2 : 4}
      py={2}
      px={2}
      alignItems="center"
    >
      <Grid item xs={8} lg={3}>
        <Text typography={isMobile ? 'caption13' : 'subtitle'} weight="bold">
          {i18n.t('master_reservation_list_title')}
        </Text>
        <Text
          typography={isMobile ? 'caption12' : 'caption13'}
          style={{ opacity: 0.4 }}
        >
          {i18n.t('reservation_list_subtitle')}
        </Text>
      </Grid>
      {isMobile && (
        <Grid item xs={4}>
          {userHasPermission(PERMISSION.ADD_PATIENT) && (
            <NewPatient onDone={loadReservations} />
          )}
        </Grid>
      )}
      <Grid item xs={12} lg={4}>
        <SearchInput
          placeholder={i18n.t('reservation_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      <Grid item xs={9} lg={2}>
        <Stack direction="row" alignItems="center">
          <Text typography="paragraph14" style={{ marginRight: '0.75rem' }}>
            {i18n.t('view_unpaid_only')}
          </Text>
          <Switch onChange={handleUnpaidSwitch} />
        </Stack>
      </Grid>
      <Grid item xs={3} lg={1}>
        <SelectFilter
          selectFilterConfigs={selectFilterConfigs}
          dateFilterConfigs={dateFilterConfigs}
          onApply={generateSelectFilters}
          onCancel={(e) => {
            generateSelectFilters(e);
            setDisabledSelectFilter([]);
          }}
          disabledSelectFilters={disabledSelectFilter}
          onChange={handleFilterChange}
        />
      </Grid>
      {isBrowser && (
        <Grid item xs={2}>
          {userHasPermission(PERMISSION.ADD_PATIENT) && (
            <NewPatient onDone={loadReservations} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

MasterReservationFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';
import {kebabCase} from 'lodash';

import FieldInfoCard from './FieldInfoCard';

const FieldInfoCardList = ({list, actionCallback, as, ...rest}) => {

  return (
    list.map((li, index) => {
      const dropdownOptions = li.options ? {
        options: li.options
      } : { };

      if (li.custom) return li.custom;

      return (
        <FieldInfoCard
          key={kebabCase(`${li.name}-${index}`)}
          name={li.name} 
          label={li.label}
          placeholder={li.placeholder} 
          action={li.action || {}}
          disabled={li.disabled}
          endIcon={li.endIcon}
          validate={li.validate}
          maxLength={li.maxLength}
          type={li.type ? li.type : 'text'}
          actionCallback={actionCallback}
          value={li.value}
          as={li.as || as}
          {...dropdownOptions}
          {...rest}
        />
      );
    })
  );
};

FieldInfoCardList.propTypes = {
  list: PropTypes.arrayOf(Object),
  actionCallback: PropTypes.func,
  as: PropTypes.string,
};

export default FieldInfoCardList;

import React from 'react';

import i18n from 'translation/i18n';

import { DataTable } from 'atoms';
import { useSelector } from 'react-redux';

const ReportTable = () => {
  const { reports, fetching } = useSelector((state) => state.report);

  const columns = [
    { field: 'user_name', headerName: i18n.t('name'), minWidth: 170, flex: 1 },
    { field: 'mobile', headerName: i18n.t('phone'), minWidth: 120, flex: 1 },
    {
      field: 'role',
      headerName: i18n.t('role'),
      minWidth: 120,
      flex: 1,
      renderCell: ({ value }) => i18n.t(value),
    },
    { field: 'email', headerName: i18n.t('email'), minWidth: 200, flex: 1 },
    {
      field: 'collected_specimens',
      headerName: i18n.t('collected'),
      minWidth: 120,
    },
    {
      field: 'procesing_specimens',
      headerName: i18n.t('processed'),
      minWidth: 120,
    },
    { field: 'paid_specimens', headerName: i18n.t('paid'), minWidth: 120 },
    {
      field: 'ready_specimens',
      headerName: i18n.t('result_entered'),
      minWidth: 120,
    },
  ];

  return (
    <DataTable
      mobileTableHeight={14}
      columns={columns}
      rows={reports}
      loading={fetching}
    />
  );
};

export default ReportTable;
